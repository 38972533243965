import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} StockLocationListFilterExtType
 * @property {boolean|null} status - false-Inativo, true-Ativo, null-Todos
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & StockLocationListFilterExtType)} StockLocationListFilterType
 */
export const StockLocationListFilterType = {};

/**
 * Inicializar StockLocationListFilterType
 *
 * @returns {StockLocationListFilterType}
 */
export const initStockLocationListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: [{ column: "stock_locations.id" }],
  custom_search: {
    columns: ["stock_locations.name"],
    values: [],
  },
  status: true,
});
