<template>
  <div>
    <modal backgroundColor="rgb(96, 114, 129)" size="md-2" @close="close" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title p-0 m-0"><img width="25" src="/img/icons/icons8/ios/graph-report.png"/>
          {{ modal.title }}</h5>
      </template>
      <div class="container pb-5">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <div class="row d-flex align-items-center m-0">
            <div class="col-1">
              <img src="/img/icons/icons8/folder-puzzle.png" width="25px" height="25px">
            </div>
            <div class="col-3">
              <h4 class="m-0">MODELO</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0 mt-n2">
            <div class="col-md-4">
              <label class="pb-0 mb-1 col-form-label new-default-black-font">
                AGRUPAMENTO<span class="text-danger"> *</span>
              </label>
            </div>
            <div class="col-md">
              <validation-provider rules="required" v-slot="{errors}">
                <PuzlSelect
                  clearable
                  v-model="filter.grouping_type"
                  :items="groupingTypeEnum"
                  :disabled="loading"/>
              </validation-provider>
            </div>
          </div>
          <!--        FILTROS -->
          <div class="row d-flex align-items-center m-0">
            <div class="col-1">
              <img src="/img/icons/icons8/ios/filter.png" width="25">
            </div>
            <div class="col-3">
              <h4 class="m-0">FILTROS</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0">
            <div class="col-md-4">
              <label class="new-default-black-font">Data</label>
            </div>
            <div class="col-md-8">
              <input-date-picker @handleFilterDate="handleFilterDate" :filterable="4" :defaultRange="defaultRange" v-model="filter.range" size="small"/>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0">
            <div class="col-md-4">
              <label class="pb-0 mb-1 col-form-label new-default-black-font">
                Central
              </label>
            </div>
            <div class="col-md pt-1">
              <base-input input-classes="form-control">
                <PuzlSelect
                  multiple
                  v-model="filter.company_plant_id"
                  :items="$_plants"
                  :loading="loadingPlant"
                  :disabled="loading"/>
              </base-input>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-4">
              <label class="pb-0 mb-1 col-form-label new-default-black-font">
                Vendedor
              </label>
            </div>
            <div class="col-md">
              <PuzlSelect :loading="loadingSeller"
                          :disabled="loading"
                          v-model="filter.sellers"
                          placeholder="Vendedor"
                          multiple
                          :items="$_sellers"
              >
              </PuzlSelect>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0">
            <div class="col-1">
              <img src="/img/icons/icons8/ios/contract-job-primary.png" width="25px" height="25px">
            </div>
            <div class="col-3">
              <h4 class="m-0">CLIENTE</h4>
            </div>
            <hr class="bg-dark flex-grow-1 mr-3">
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-4">
              <label class="pb-0 mb-1 col-form-label new-default-black-font">
                Grupo Econômico
              </label>
            </div>
            <div class="col-md">
              <PuzlSelect
                :items="[]"
                placeholder="GRUPO ECONÔMICO"
              />
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-4">
              <label class="pb-0 mb-1 col-form-label new-default-black-font">
                CLIENTE
              </label>
            </div>
            <div class="col-md">
              <PuzlSelectSearch
                :value="['all']"
                @search="handleFilterEntity"
                :loading="loadingEntity"
                v-model="filter.entity_id"
                clearable
                multiple
                @change="getContractProposals"
                placeholder="CLIENTE"
                :items="entities"
              >
              </PuzlSelectSearch>
            </div>
          </div>
          <div class="row d-flex align-items-center m-0 ">
            <div class="col-md-4">
              <label class="pb-0 mb-1 col-form-label new-default-black-font">
                CONTRATO
              </label>
            </div>
            <div class="col-md">
              <base-input input-classes="form-control">
                <PuzlSelect
                  label="$code$ | $construction_name$ | $customer_name$"
                  :labelMask="true"
                  :loading="loadingContractProposal"
                  :disabled="loading || !filter.entity_id.length || filter.entity_id.includes('all')"
                  :items="$_contract_proposals"
                  v-model="filter.contract_proposal_ids"
                  :multiple="true"
                  placeholder="Contratos"
                />
              </base-input>
            </div>
          </div>
          <div class="row float-right mt-4 pr-3">
            <div class="col-md-12" style="display: flex; align-items: center;">
              <base-button
                @click.prevent="close"
                type="secondary"
                class="text-capitalize"
              >
                Cancelar
              </base-button>
              <base-button
                @click.prevent="submitAndClose"
                type="primary"
                native-type="submit"
                :loading="loading"
              >
                <span v-if="!loading" class="btn-label"><i class="fa-solid fa-cloud-arrow-down"></i></span>
                Exportar
              </base-button>
            </div>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import InputDatePicker from "@/components/InputDatePicker";
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import PuzlSelectSearch from "@/components/PuzlSelectSearch.vue";
import moment from "moment";
import {debounce} from "@/plugins";

const {formatErrorValidation} = require("@/plugins");

const groupingTypeEnum = Object.freeze({
  DAY: {
    id: 1,
    name: 'DIA',
  },
  COMPANY_PLANT: {
    id: 2,
    name: 'CENTRAL',
  },
  ENTITY: {
    id: 3,
    name: 'CLIENTE',
  },
});
export default {
  name: "ModalReport",
  components: {
    PuzlSelectSearch,
    PuzlSelect,
    InputDatePicker
  },
  data() {
    return {
      modal: {
        title: 'EXPORTAR RELATÓRIO EM ABERTO',
        create: false,
      },
      defaultRange: {
        start: new Date(moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')),
        end: new Date(moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'))
      },
      loadingPlant: false,
      loadingSeller: false,
      loadingContractProposal: false,
      loadingEntity: false,
      loading: false,
      filter: {
        range: {
          start: null,
          end: null,
        },
        status: [],
        company_plant_id: [],
        sellers: [],
        grouping_type: 1,
        entity_id: 1,
      },
      entities: [{id: 'all', label: 'TODOS'}],
      groupingTypeEnum: Object.values(groupingTypeEnum),
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/getPlantIdWithAll",
      $_sellers: "user/getSellerWithAll",
      $_contract_proposals: "contractProposal/fetch",
    }),
  },
  methods: {
    handleFilterDate(filter) {
      this.filter.range = filter
    },
    close() {
      this.modal.create = false;
    },
    handleFilterEntity: debounce(function (e) {
      if (!e) return
      this.loadingEntity = true
      this.$store.dispatch('entity/getSearch', {
        search: e
      }).then((response) => {
        let merged = [...response.data, ...this.entities]
        merged = [...new Map(merged.map((item) => [item['id'], item])).values()]
        this.$set(this, "entities", merged);
        this.loadingEntity = false
      })
    }, 300),
    openModal() {
      this.load()
      this.loading = true
      this.modal.create = true
      this.filter.range = {
        start: new Date(moment().format('YYYY-MM-DD 00:00:00')),
        end: new Date(moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'))
      }
      this.filter.contract_proposal_ids = []
      this.filter.company_plant_id = ['all']
      this.filter.sellers = ['all']
      this.filter.entity_id = ['all']
      this.loading = false
    },
    /**
     * Busca as propostas por cliente
     */
    getContractProposals() {
      if (!this.filter.entity_id.length) return
      this.loadingContractProposal = true
      this.$store.dispatch("contractProposal/getAllByEntities", {entity_ids: this.filter.entity_id}).then(() => {
        this.loadingContractProposal = false;
      });
    },
    submitAndClose() {
      this.loading = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      const filter = {}
      for (let key in this.filter) {
        if (!(this.filter[key] instanceof Array)) {
          filter[key] = this.filter[key];
          continue;
        }
        if (!this.filter[key].some(value => value === 'all')) {
          filter[key] = Object.assign([], this.filter[key]);
        }
      }
      this.$store.dispatch('billingInvoice/getReportOpenedPDF', filter)
        .then((response) => {
          this.close();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso! O relatório será exportado em segundo plano.",
          });
        }).catch(error => {
        const errors = error && error.response && error.response.status === 422
          ? formatErrorValidation(error.response.data.data)
          : error.response.data.message;
        this.$notify({type: "danger", message: errors});
      }).finally(() => {
        this.loading = false;
      })
    },
    load() {
      this.loadingPlant = true
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.loadingPlant = false
      })
      this.loadingSeller = true
      this.$store.dispatch("user/fetchItemsFilterable", {
        filter: {
          is_seller: 1
        }
      }).then(() => {
        this.loadingSeller = false;
      });
    },
  },
  mounted() {
    this.$watch(function () {
      return this.filter
    }, function (items, old_items) {
      // se estiver carregando, interrompe o script
      if (this.loading) return
      // percorre os filtros
      for (let key in items) {
        let item = items[key]
        // se o filtro do PuzlSelect possuir mais de um dado selecionado e tiver a opção "TODOS", faz:
        if (item.length > 1 && item.includes('all')) {
          // se não possuia a opção "TODOS", o filtro será apenas "TODOS"
          if (!old_items[key].includes('all')) {
            this.filter[key] = ['all']
          }
          // se não, remove a opção "TODOS"
          else {
            let index = this.filter[key].find((item) => item === 'all')
            this.filter[key].splice(index, 1)
          }
        }

      }
    }, {deep: true, sync: true});
  },
};
</script>

<style scoped></style>
