var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-body mt-n2 p-0"},[_c('div',{staticClass:"row"},[_c('ShadedTable',{attrs:{"length":_vm.$_concretes.length,"headers":_vm.headers,"loading":_vm.loadingSkeleton}},[_vm._l((_vm.concretesWithAccumulated),function(item,index){return _c('template',{slot:index},[_c('ShadedCol',{attrs:{"align":"center"}},[_c('h5',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1"},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('ShadedCol',{attrs:{"align":"center"}},[_c('h5',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1"},[_vm._v(" "+_vm._s(_vm._f("parseDate")(item.finished_at,"HH:mm"))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleTravelCycle(item)}}},[_c('i',{staticClass:"fa fa-clock text-success"})])])]),_c('ShadedCol',[_c('div',[_c('h5',{staticClass:"ml-3"},[_c('span',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1",style:(`color: ${item.status === _vm.ScheduleTravelEnum.CANCELED ? 'red' : 'black'} !important`)},[_vm._v(" "+_vm._s(item.sequencial_number_plant_number)+" ")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('downloadProof', item.uuid)}}},[_c('img',{attrs:{"width":"28px","src":"/img/icons/os.png"}})])])])]),_c('ShadedCol',{attrs:{"align":"center"}},[(item.xml_travel_id)?_c('div',{staticClass:"text-center",on:{"click":function($event){$event.preventDefault();return _vm.$emit(
                'downloadDanfe',
                item.xml_travel_status,
                item.xml_travel_key,
                item.uuid,
              )}}},[_c('h5',[_c('span',{staticClass:"float-left font-weight-400 text-dark",staticStyle:{"font-size":"12.5px"}},[_vm._v(" "+_vm._s(item.xml_travel_number)+" ")]),_c('span',{staticClass:"ml-2"},[(item.status !== _vm.ScheduleTravelEnum.CANCELED)?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"19px","src":"/img/nfe.png"}}):_vm._e(),(
                    item.status === _vm.ScheduleTravelEnum.CANCELED &&
                    item.xml_travel_status !== _vm.XmlTravelEnum.NFE_IN_CANCELLATION_PROCESS
                  )?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"19px","src":"/img/nfe%20cancelada%20icon.svg"}}):_vm._e(),(
                    item.status === _vm.ScheduleTravelEnum.CANCELED &&
                    item.xml_travel_status === _vm.XmlTravelEnum.NFE_IN_CANCELLATION_PROCESS
                  )?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"19px","src":"/img/nfe_in_cancelation_progress.png"}}):_vm._e()])])]):_vm._e()]),_c('ShadedCol',[_c('div',[_c('span',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1",attrs:{"title":item.entity_name}},[_vm._v(" "+_vm._s(_vm.truncateString(item.entity_name, 20))+" ")])])]),_c('ShadedCol',[_c('h5',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1",attrs:{"title":item.construction_name}},[_c('span',{staticClass:"btn btn-sm btn-primary p-1 mr-2",on:{"click":function($event){$event.preventDefault();return _vm.$emit('copyContractProposal', item.contract_proposal_code)}}},[_vm._v(" "+_vm._s(item.contract_proposal_code)+" ")]),_vm._v(" "+_vm._s(_vm.truncateString(item.construction_name, 15))+" ")])]),_c('ShadedCol',[_c('h5',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1"},[_vm._v(" "+_vm._s(item.contract_proposal_formulation_title)+" ")])]),_c('ShadedCol',[_c('h5',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1"},[_vm._v(" "+_vm._s(item.equipment_code)+" ")])]),_c('ShadedCol',{attrs:{"align":"center"}},[_c('h5',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1"},[_vm._v(" "+_vm._s(item.schedule_travel_volume)+" ")])]),_c('ShadedCol',{attrs:{"align":"center"}},[_c('h5',{staticClass:"new-default-black-font font-weight-normal m-0 mt-1"},[_vm._v(" "+_vm._s(item.accumulated)+" ")])])],1)})],2)],1)]),_c('ModalTravelCycle',{ref:"travelCycle",on:{"updatedCycle":function($event){return _vm.$parent.init({})}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }