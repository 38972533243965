import { render, staticRenderFns } from "./_ModalReference.vue?vue&type=template&id=2bfcc515&scoped=true"
import script from "./_ModalReference.vue?vue&type=script&lang=js"
export * from "./_ModalReference.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bfcc515",
  null
  
)

export default component.exports