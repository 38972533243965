<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <PuzlBreadcrumb/>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card>
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 p-0 pt-0 mt-n4 text-center">
                  <img width="100"
                       src="/img/brand/deploy.png"/>
                </div>
                <div class="col-md-10 table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center">Ações</th>
                      <th class="">Identificação</th>
                      <th class="">PMC</th>
                      <th class="">Responsável</th>
                      <th class="text-center">Disponibilidade</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 1" v-show="loadingSkeleton">
                      <th colspan="6">
                        <skeleton-puzl type="button"/>
                      </th>
                    </tr>
                    <tr v-for="(item, index) in $_items">
                      <td class="text-center">
                        <a v-if="item.status === 0" href="#"
                           @click.prevent="showDeployOptions = !showDeployOptions;clearRepositories();setDeployItem(item)">
                          <i class="fa-solid fa-rocket"></i>
                        </a>
                        <a v-else-if="item.status === 2 && $_user.id === item.user_id" href="#"
                           @click.prevent="unlink(item, index)">
                          <i class="fa-solid fa-rocket text-danger"></i>
                        </a>
                        <a v-else href="#">
                          <i class="text-muted fa-solid fa-rocket"></i>
                        </a>
                      </td>
                      <td class=" numeric">{{ item.description }}</td>
                      <td v-model="item.task" @input="onInput($event, index)" class="numeric" contenteditable="true">
                        {{ item.task }}
                      </td>
                      <td disabled readonly class="numeric">
                        {{item.user_name}}
                      </td>
                      <td class="text-center">
                        <badge class="numeric" style="font-size: 0.7rem" :type="status[item.status].class">
                          {{ status[item.status].text }}
                        </badge>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <transition name="fade">
                    <card v-if="showDeployOptions" class="mt-2 p-0">
                      <div class="card-body"
                           style="padding: 0px !important;margin-top: -12px;margin-bottom: -18px !important">
                        <div class="row">
                          <div class="col-md text-center">
                            <base-input>
                              <label style="font-size: 0.8rem !important;" class="numeric mr-2">API</label>
                              <input v-model="deploy_options.repositories.api" tabindex="-1" type="checkbox"/>
                            </base-input>
                          </div>
                          <div class="col-md text-center">
                            <base-input>
                              <label style="font-size: 0.8rem !important;" class="numeric mr-2">APP</label>
                              <input v-model="deploy_options.repositories.app" tabindex="-1" type="checkbox"/>
                            </base-input>
                          </div>
                          <div class="col-md text-left text-center">
                            <base-input>
                              <label style="font-size: 0.8rem !important;" class="numeric mr-2">MICROSERVIÇO</label>
                              <input v-model="deploy_options.repositories['microservice-v.1']" tabindex="-1"
                                     type="checkbox"/>
                            </base-input>
                          </div>
                          <div class="col-md-4">
                            <div class="text-right float-right">
                              <base-button @click.prevent="showDeployOptions = false;clearRepositories()" size="sm"
                                           outline type="light">
                                Cancelar
                              </base-button>
                              <base-button
                                :disabled="!deploy_options.repositories.api && !deploy_options.repositories['microservice-v.1'] && !deploy_options.repositories.app"
                                @click.prevent="handleStartDeploy" size="sm" outline type="success">
                                Iniciar deploy
                              </base-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </card>
                  </transition>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import {mapGetters} from "vuex";
import SkeletonPuzl from "../../../../components/SkeletonPuzl";

export default {
  name: "Index",
  components: {
    SkeletonPuzl,
    PuzlBreadcrumb
  },
  data() {
    return {
      loadingSkeleton: false,
      showDeployOptions: false,
      interval_id: null,
      deploy_options: {
        sandbox: false,
        index: null,
        repositories: {
          api: false,
          app: false,
          'microservice-v.1': false,
        },
      },
      status: {
        0: {
          text: 'Ativo',
          class: 'success',
        },
        1: {
          text: 'Deploy em andamento',
          class: 'warning'
        },
        2: {
          text: 'Em uso',
          class: 'primary'
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      $_items: "deploy/fetch",
      $_user: 'auth/getUser',
    }),
  },
  mounted() {
    this.init()
    this.interval_id = setInterval(function () {
      this.$store.dispatch('deploy/updateItems').then(response => {
        for (var i in response.data) {
          this.$set(this.$_items[i], 'status', response.data[i].status)
          this.$set(this.$_items[i], 'task', response.data[i].task)
          this.$set(this.$_items[i], 'user_name', response.data[i].user_name)
        }
      })
    }.bind(this), 3000);
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval_id)
    next();
  },
  methods: {
    unlink(item, index) {
      this.$store.dispatch('deploy/unlink', item).then(() => {
        this.$set(this.$_items[index], 'status', 0)
        this.$set(this.$_items[index], 'task', null)
        this.$set(this.$_items[index], 'user_name', null)
        this.$set(this.$_items[index], 'user_id', null)
        return this.$notify({
          type: "success",
          message: "Sandbox desassociado com sucesso!"
        });
      })
    },
    setDeployItem(item) {
      this.deploy_options.sandbox = 0
      this.deploy_options.index = null
      if (item.description.includes('Sandbox')) {
        let sandbox = item.description.split(" ")[1]
        this.deploy_options.id = item.id
        this.deploy_options.sandbox = 1
        this.deploy_options.index = sandbox
      }
    },
    onInput(event, index) {
      this.deploy_options.task = event.target.innerText
    },
    clearRepositories() {
      this.deploy_options.repositories.api = false
      this.deploy_options.repositories.app = false
      this.deploy_options.repositories['microservice-v.1'] = false
    },
    init() {
      this.loadingSkeleton = true
      this.$store.dispatch('deploy/fetch').then(response => {
        this.loadingSkeleton = false
      })
    },
    /**
     * Inicia o processo de deploy
     */
    handleStartDeploy() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      if (! this.deploy_options.task && this.deploy_options.sandbox == 1) {
       return this.$notify({
          type: "danger",
          message: "Não foi possível continuar. Tarefa não associada ao sandbox!"
        });
      }
      let loader = this.$loading.show()
      this.$store.dispatch('deploy/start', this.deploy_options).then(response => {
        loader.hide()
        this.$notify({
          type: "success",
          message: "Processo de deploy iniciado com sucesso!"
        });
        this.showDeployOptions = false
        this.clearRepositories()
      }).catch((error) => {
        loader.hide()
        this.showDeployOptions = false
        this.clearRepositories()
        this.$notify({
          type: "danger",
          message: error.data.message,
        });
      })
    }
  }
}
</script>

<style scoped>

</style>
