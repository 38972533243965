import { BaseListFilterType, initBaseListFilterType } from "../../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} IssuerDocumentListFilterExtType
 * @property {null|0|1} status - [null..0..1] Todos: null Inativos: 0 Ativos: 1
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & IssuerDocumentListFilterExtType)} IssuerDocumentListFilterType
 */
export const IssuerDocumentListFilterType = {};

/**
 * Inicializar IssuerDocumentListFilterType
 *
 * @returns {IssuerDocumentListFilterType}
 */
export const initIssuerDocumentListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: [{ column: "company_plant_issuers.id" }],
  custom_search: {
    columns: [
      "company_plant_issuers.name",
      "company_plant_issuers.document",
      "company_plant_issuers.ein",
      "company_plant_issuer_addresses.state",
      "company_plant_issuer_addresses.address",
    ],
    values: [],
  },
  status: null,
});