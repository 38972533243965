<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>

      <div v-if="!isLoading">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(submitAndClose)" autocomplete="off">
            <div class="row mb-2 m-0 p-0">
              <div class="col-md-3 mb-1">
                <base-button @click.prevent="tabIndex = 0" :class="tabIndex === 0 ? 'active' : ''" block outline type="primary"
                  ><i class="fa-solid fa-file-invoice"></i>Serviços
                </base-button>
              </div>
              <div class="col-md-3 mb-1">
                <base-button @click.prevent="tabIndex = 1" :class="tabIndex === 1 ? 'active' : ''" block outline type="primary"
                  ><i class="fa-solid fa-user"></i>Tomador
                </base-button>
              </div>
              <div class="col-md-3">
                <base-button @click.prevent="tabIndex = 2" :class="tabIndex === 2 ? 'active' : ''" block outline type="primary"
                  ><i class="fa-solid fa-id-card"></i>Emissor
                </base-button>
              </div>
              <div class="col-md-3">
                <base-button @click.prevent="tabIndex = 3" :class="tabIndex === 3 ? 'active' : ''" block outline type="primary"
                  ><i class="fa-solid fa-message"></i> Observações
                </base-button>
              </div>
            </div>

            <!-- Aba Serviço -->
            <div class="container" v-show="tabIndex === 0">
              <!-- Município de Incidência do ISS -->
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Município de Incidência do ISS</h5>
              </div>
              <div>
                <div class="container">
                  <div class="row">
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <div class="checklist-item">
                            <div class="col-md-6">
                              <div class="checklist-info text-uppercase">{{ construction.default_address.state }}</div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-6">
                              <div class="checklist-info">
                                <strong> {{ construction.default_address.city }}</strong>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div 
                      v-if="current_invoice.operation_source_puzl_default === false"
                      class="col-md-12 mb-3"
                    >
                      <label class="col-form-label form-control-label"> 
                        <span class="text-danger">&nbsp;* </span>
                        Regime Especial de Tributação
                      </label>
                      <base-input>
                        <input
                          :value="current_invoice.nfse.operation_source_name"
                          type="text"
                          class="form-control form-control-sm"
                          :disabled="true"
                        />                      
                      </base-input>
                    </div>
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <div class="checklist-item checklist-item-danger">
                            <div class="col-md-6">
                              <div class="checklist-info">Dedução</div>
                            </div>
                            <div class="col-md-6 text-right">{{ current_taxes.issqn.deduct | number }}%</div>
                          </div>
                          <div class="checklist-item checklist-item-danger">
                            <div class="col-md-6">
                              <div class="checklist-info">Base de cálculo</div>
                            </div>
                            <div class="col-md-6 text-right">{{ current_taxes.issqn.base | number }}%</div>
                          </div>
                          <div class="checklist-item checklist-item-danger">
                            <div class="col-md-6">
                              <div class="checklist-info">Alíquota total</div>
                            </div>
                            <div class="col-md-6 text-right">{{ current_taxes.issqn.total_rate | number }}%</div>
                          </div>
                          <div class="checklist-item checklist-item-danger">
                            <div class="col-md-6">
                              <div class="checklist-info">Alíquota retida</div>
                            </div>
                            <div class="col-md-6 text-right">{{ current_taxes.issqn.retained_rate | number }}%</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Valores -->
              <div class="card-header border-header-success-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Valores</h5>
              </div>
              <div>
                <div class="container">
                  <div class="row">
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <!-- Valor Total -->
                          <div class="checklist-item checklist-item-success">
                            <div class="col-md-6">
                              <div class="checklist-info">
                                <strong>Valor total</strong>
                              </div>
                            </div>
                            <div class="col-md-6 text-right">
                              <strong> {{ current_invoice.total | currency }} </strong>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- MCC Real e MCC Teórico -->
                <div class="container">
                  <ul class="list-group list-group-flush" data-toggle="checklist">
                    <li class="list-group-item px-0">
                      <!-- MCC Real -->
                      <div class="checklist-item checklist-item-danger">
                        <div class="col-md-6">
                          <div class="checklist-info">MCC (real)</div>
                        </div>
                        <div class="col-md-6 text-right">
                          {{ current_invoice.mcc_value | currency }} ({{
                            $helper.percentageFrom(current_invoice.total, current_invoice.mcc_value)
                          }}%)
                        </div>
                      </div>
                      <!-- MCC Teórico -->
                      <div class="checklist-item checklist-item-danger">
                        <div class="col-md-6">
                          <div class="checklist-info">MCC (teórico)</div>
                        </div>
                        <div class="col-md-6 text-right">
                          {{ current_invoice.mcc_theorical_value | currency }} ({{
                            $helper.percentageFrom(current_invoice.total, current_invoice.mcc_theorical_value)
                          }}%)
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Regras de Dedução do issqn -->
              <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                <h5 class="h4 mb-0" slot="title">Regras de Dedução</h5>
              </div>

              <!-- Campo Deduzir custo de MCC do traço -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Deduzir custo de MCC do traço </label>
                <div class="col-md-8 pt-1">
                  <base-switch
                    v-model="current_invoice.nfse.deduction_rules_nfse.deduct_mcc_cost"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>

              <!-- Campo Considerar reuso -->
              <div class="form-group row m-0 p-0 mb-1" v-if="current_invoice.nfse.deduction_rules_nfse.deduct_mcc_cost">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label">
                  Considerar reuso
                  <span
                    v-if="current_invoice.nfse.deduction_rules_nfse.consider_reuse"
                    class="badge mr-1 ml-1"
                    style="background-color: #d3d3d3"
                    >(MCC Real)</span
                  >
                  <span
                    v-if="!current_invoice.nfse.deduction_rules_nfse.consider_reuse"
                    class="badge mr-1 ml-1"
                    style="background-color: #d3d3d3"
                    >(MCC Teórico)</span
                  >
                </label>
                <div class="col-md-8 pt-1">
                  <base-switch
                    v-model="current_invoice.nfse.deduction_rules_nfse.consider_reuse"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    :disabled="true"
                  >
                  </base-switch>
                </div>
              </div>

              <!-- Campo Condição -->
              <div class="form-group row m-0 p-0 mb-1" v-if="current_invoice.nfse.deduction_rules_nfse.deduct_mcc_cost">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Condição </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <el-select
                      filterable
                      placeholder="Selecione"
                      v-model="current_invoice.nfse.deduction_rules_nfse.condition.id"
                      :disabled="true"
                    >
                      <el-option
                        v-for="condition in current_invoice.nfse.deduction_rules_nfse.condition.options"
                        :key="condition.id"
                        :label="condition.text"
                        :value="condition.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <!-- Grupo de Campos - ISS -->
              <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                <h5 class="h4 mb-0" slot="title">ISS</h5>
              </div>

              <!-- Campo Dedução -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Dedução </label>
                <div class="col-md-4 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.iss_nfse.deduct).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-4 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.iss_nfse.deduct_perc).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <!-- Campo Base de cálculo -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Base de cálculo </label>
                <div class="col-md-4 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.iss_nfse.base).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-4 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.iss_nfse.base_perc).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <!-- Campo ISS Retido -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> ISS retido </label>
                <div class="col-md-8 pt-1">
                  <base-switch
                    v-model="current_invoice.nfse.iss_nfse.retained_rate_flag"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>

              <!-- Campo Alíquota ISS retida -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Alíquota retida </label>
                <div class="col-md-4 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.iss_nfse.retained_rate).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <!-- Campo Valor ISS -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Valor ISS </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.iss_nfse.iss_value).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <!-- Grupo de Campos - Federais -->
              <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                <h5 class="h4 mb-0" slot="title">Retenção de tributos federais</h5>
              </div>

              <!-- Campo cofins -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> COFINS </label>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.cofins_rate).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.cofins_value).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-2 pt-1">
                  <base-switch
                    v-model="current_invoice.nfse.federal_taxes_nfse.cofins_retained_flag"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>

              <!-- Campo csll -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> CSLL </label>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.csll_rate).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.csll_value).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-2 pt-1">
                  <base-switch
                    v-model="current_invoice.nfse.federal_taxes_nfse.csll_retained_flag"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>

              <!-- Campo inss -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> INSS </label>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.inss_rate).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.inss_value).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-2 pt-1">
                  <base-switch
                    v-model="current_invoice.nfse.federal_taxes_nfse.inss_retained_flag"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>

              <!-- Campo ir -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> IR </label>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.ir_rate).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.ir_value).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-2 pt-1">
                  <base-switch
                    v-model="current_invoice.nfse.federal_taxes_nfse.ir_retained_flag"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>

              <!-- Campo pis -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> PIS </label>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.pis_rate).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-3 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="$helper.strToNum(current_invoice.nfse.federal_taxes_nfse.pis_value).toFixed(2)"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-2 pt-1">
                  <base-switch
                    v-model="current_invoice.nfse.federal_taxes_nfse.pis_retained_flag"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    :disabled="true"
                  ></base-switch>
                </div>
              </div>
            </div>

            <!-- Aba Tomador (Cliente) -->
            <div class="container" v-show="tabIndex === 1">
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Tomador / Cliente</h5>
              </div>
              <div>
                <div class="container">
                  <div class="row">
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <div class="checklist-item">
                            <div class="col-md-6">
                              <div class="checklist-info text-uppercase">{{ entity.entity_name }}</div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-6">
                              <div class="checklist-info">
                                <strong> {{ entity.document }} </strong>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Campo E-mail -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> E-mail </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="entity.email" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Campo CNO -->
              <div class="form-group row m-0 p-0 mb-1" v-if="current_invoice.nfse.contract_proposal_cno">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> CNO </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="current_invoice.nfse.contract_proposal_cno" type="text" class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

              <!-- Campo Insc. Municipal -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Insc. municipal </label>
                <div class="col-md-8 pt-1">
                  <base-input v-if="entity_document_is_cnpj" input-group-classes="input-group-sm">
                    <input
                      v-model="entity.legal[0].municipal_registration"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                  <base-input v-else input-group-classes="input-group-sm">
                    <input
                      value="NÃO INFORMADA"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo Insc. Estadual -->
              <div  class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Insc. estadual </label>
                <div class="col-md-8 pt-1">
                  <base-input v-if="entity.legal[0]" input-group-classes="input-group-sm">
                    <input v-model="entity.legal[0].contributing_number" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                  <base-input v-else input-group-classes="input-group-sm">
                    <input value="NÃO INFORMADA" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Grupo de Campos - Endereço -->
              <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                <h5 class="h4 mb-0" slot="title">Endereço</h5>
              </div>

              <!-- Campo UF -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> UF </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="entity.address.state" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Campo Cidade -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Cidade </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="entity.address.city" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Campo Logradouro -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Logradouro </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="entity.address.address" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Campo Numero -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Número </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="entity.address.number" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Campo Complemento -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Complemento </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="entity.address.complement" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Campo Bairro -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Bairro </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="entity.address.district" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Campo Cep -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Cep </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="entity.address.postal_code" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>
            </div>

            <!-- Aba Emissor -->
            <div class="container" v-if="company_plant_issuer" v-show="tabIndex === 2">
              <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                <h5 class="h4 mb-0" slot="title">Emissor</h5>
              </div>
              <div>
                <div class="container">
                  <div class="row">
                    <div class="container">
                      <ul class="list-group list-group-flush" data-toggle="checklist">
                        <li class="list-group-item px-0">
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info text-uppercase">
                                {{ company_plant_issuer.business_name }}
                              </div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info">{{ company_plant_issuer.ein }} - {{ company_plant_issuer.tax_regime }}</div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info">
                                {{ company_plant_issuer.state }}
                              </div>
                            </div>
                          </div>
                          <div class="checklist-item">
                            <div class="col-md-12">
                              <div class="checklist-info">
                                <strong>
                                  {{ company_plant_issuer.city }}
                                </strong>
                              </div>
                            </div>
                          </div>                          
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Grupo de Campos - Servico -->
              <div class="card-header border-header-danger-bottom mt-3 mb-3 p-2">
                <h5 class="h4 mb-0" slot="title">Serviço</h5>
              </div>

              <!-- Campo Código do Serviço -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Código do Serviço </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="company_plant_issuer.municipal_service_code"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo Descrição do Serviço -->
              <div class="form-group row m-0 p-0 mb-1" v-if="current_invoice.nfse.service_description">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Descrição do Serviço </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <textarea
                      v-model="current_invoice.nfse.service_description"
                      type="text"
                      rows="2"
                      class="form-control form-control-sm"
                      :disabled="true"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>

              <!-- Campo Item da Lista -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Item da lista de serviços </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="company_plant_issuer.service_list_item"
                      type="text"
                      class="form-control form-control-sm"
                      :disabled="true"
                    />
                  </base-input>
                </div>
              </div>

              <!-- Campo CNAE -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> CNAE </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input v-model="company_plant_issuer.cnae" type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>

              <!-- Campo Natureza da Operação -->
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-1 pb-0 col-form-label form-control-label"> Natureza da operação </label>
                <div class="col-md-8 pt-1">
                  <base-input input-group-classes="input-group-sm">
                    <input type="text" class="form-control form-control-sm" :disabled="true" />
                  </base-input>
                </div>
              </div>
            </div>
            <div class="container" v-show="tabIndex === 3">
              <div class="row" v-if="current_invoice.nfse.observation">
                <div class="col-md-12">
                  <base-input input-group-classes="input-group">
                    <textarea
                      v-model="current_invoice.nfse.observation"
                      type="text"
                      rows="6"
                      class="form-control form-control-sm"
                      :disabled="true"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>
            </div>
            <!-- MODAL FOOTER -->
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Fechar</base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalNfseSent",
  components: {},
  data() {
    return {
      modal: {
        title: "Nota fiscal Enviada (NFS-e)",
        create: false,
      },
      isLoading: true,
      tabIndex: 0,
      current_invoice: null,
      company_plant_issuer: null,
      entity: null,
      entity_document_is_cnpj: false,
      construction: null,
      current_taxes: {},
      current_taxes_types: ["issqn", "cofins", "csll", "inss", "ir", "pis"],
      current_taxes_default: {
        base: "0,00",
        condition_id: 1,
        condition: "",
        consider_reuse: false,
        deduct: "0,00",
        deduct_mcc_cost: false,
        retained_rate: "0,00",
        total_rate: "0,00",
        full_coverage: false,
        allow_issqn_retention_of_legal_person: 0,
        allow_issqn_retention_of_natural_person: 0,
        allow_issuance_without_customer_municipal_registration: 0,
      },
      deduction_rules_nfse: {
        deduct_mcc_cost: false,
        consider_reuse: false,
        condition: {
          id: 1,
          options: [
            { id: 1, text: "Adotar sempre o MCC" },
            { id: 2, text: "Adotar sempre maior dedução" },
            { id: 3, text: "Adotar sempre menor dedução" },
          ],
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
      this.$emit("close");
    },
    async handleCreateModal(item) {
      await this.loadFormData(item);
      this.tabIndex = 0;
      this.modal.create = true;
      this.isLoading = false;
    },
    async loadFormData(item) {
      this.$Progress.start();

      await this.getCompanyPlantIssuer(item.company_plant_issuer.id);
      this.entity = item.entity;
      this.entity_document_is_cnpj = this.$helper.onlyNumbers(item.entity.document).length > 11;
      this.construction = item.contract_proposal.construction;
      this.current_invoice = item;
      await this.getAllCurrentTaxes(this.construction.default_address.city, this.company_plant_issuer.tax_regime);

      this.$Progress.finish();
    },
    async getCompanyPlantIssuer(id) {
      await this.$store
        .dispatch("companyPlantIssuer/show", id)
        .then((response) => {
          this.company_plant_issuer = response.data;
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
    async getAllCurrentTaxes(constructionCity, issuerRegime) {
      let params = {
        constructionCity: constructionCity,
        issuerRegime: issuerRegime,
      };
      await this.$store
        .dispatch("deductionRules/getAllCurrentTaxes", params)
        .then((response) => {
          this.current_taxes_types.map(function (obj, index) {
            this.current_taxes[obj] = response.data.filter((item) => item.name.toLowerCase() === obj)[0] ?? this.current_taxes_default;
            const conditionFound = this.deduction_rules_nfse.condition.options.find(
              (element) => element.text === this.current_taxes[obj].condition
            );
            this.current_taxes[obj].condition_id = conditionFound ? conditionFound.id : 1;
          }, this);
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        });
    },
  },
};
</script>

<style scoped></style>
