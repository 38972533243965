import { StockLocationCreateType } from "./StockLocationCreateType";
import { initStockLocationCreateType } from "./StockLocationCreateType";

/**
 * Type para atualizar
 *
 * @typedef {(
 *   StockLocationCreateType
 * )} StockLocationUpdateType
 */
export const StockLocationUpdateType = {};

/**
 * Inicializar StockLocationUpdateType
 *
 * @returns {StockLocationUpdateType}
 */
export const initStockLocationUpdateType = () => ({
  ...initStockLocationCreateType(),
});

