<template>
  <div>
    <div class="row">
      <div v-if="$_pieChart.loading === false" class="col-md-4 technology-pie-chart">
        <PieChart :theme="theme" :option="$_pieChart" />
      </div>
      <div v-if="$_variationGaugeChart.loading === false" class="col-md-4 technology-gauge-chart">
        <GaugeChart :theme="theme" :option="$_variationGaugeChart">
          <div class="col-12 fix-technology-gauge-chart" />
        </GaugeChart>
      </div>
      <div v-if="$_coefficientGaugeChart.loading === false" class="col-md-4 technology-gauge-chart">
        <GaugeChart :theme="theme" :option="$_coefficientGaugeChart">
          <div class="col-12 fix-technology-gauge-chart" />
        </GaugeChart>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "@/components/Echarts/PieChart.vue";
import GaugeChart from "@/components/Echarts/GaugeChart.vue";
import { mapGetters } from "vuex";
export default {
  name: "SimpleChart",
  components: {
    PieChart,
    GaugeChart,
  },
  computed: {
    ...mapGetters({
      $_pieChart: "DashboardTechnology/pieChart",
      $_variationGaugeChart: "DashboardTechnology/variationGaugeChart",
      $_coefficientGaugeChart: "DashboardTechnology/coefficientGaugeChart",
    }),
  },
  props: {
    theme: {
      type: Boolean,
      default: false,
      description: "Tema",
    },
  },
};
</script>

<style>
@media (min-width: 980px) {
  .technology-pie-chart .chart {
    height: 250px !important;
  }
  .technology-pie-chart  {
    height: 280px !important;
  }
  .technology-gauge-chart {
    height: 300px !important;
  }
  .technology-gauge-chart .chart {
    height: 320px !important;
  }
  .fix-technology-gauge-chart {
    margin-top: -4.3rem;
  }
}
</style>
