<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form class="needs-validation" autocomplete="off">
            <div class="form-group row m-0 p-0">
              <div class="col-md-12 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Financeiro
                </div>
                <base-switch
                  v-model="$_entityContact.is_financial"
                  type="success"
                  offText="inativo"
                  onText="ativo"
                  class="success"
                ></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-6 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="$_entityContact.name"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Cargo
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$_entityContact.office"
                />
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-6 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Email
                  <span class="text-danger" v-if="$_entityContact.is_financial">
                    &nbsp;*
                  </span>
                </div>
                <validation-provider
                  :rules="$_entityContact.is_financial ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    v-model="$_entityContact.email"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Celular
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="tel"
                    class="form-control form-control-sm"
                    v-model="$_entityContact.phone"
                    v-mask="['(##) # ####-####']"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
                @click.prevent="updateContact"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const { formatErrorValidation } = require("@/plugins");

export default {
  name: "EditEntityContact",
  components: {},
  data() {
    return {
      modal: {
        title: "Editar Contato",
        create: false,
      },
      contacts: [],
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      $_entityContact: "entityContact/fetch",
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {number} entityContactId
     */
    openModal(entityContactId) {
      this.$store
        .dispatch("entityContact/show", { id: entityContactId })
        .then((response) => {
          this.modal.create = true;
        });
    },
    updateContact() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("entityContact/update", this.$_entityContact)
        .then((response) => {
          this.modal.create = false;
          this.$emit("editedEntityContact", response.data);
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
  },
};
</script>

<style scoped></style>
