/**
 * Type para filtro base de listagem
 *
 * @typedef {Object} BaseListFilterType
 * @property {0|1|2} paginate_type - Tipo de paginação (0=CURSOR, 1=SIMPLE, 2=NORMAL)
 * @property {number} limit - Limite de registros
 * @property {number|string} page - Número da página ou Hash do cursor
 * @property {string} next_page - Depreciado! Apenas para manter compatibilidade
 * @property {Object[]} order_by - Objeto de ordenação
 * @property {string} order_by[].column - Coluna para ordenar
 * @property {boolean} order_by[].is_desc - Se a ordenação é decrescente
 * @property {(number|string)[]} primary_keys - Chaves primárias
 * @property {Object} custom_search - Objeto de pesquisa personalizada
 * @property {string[]} custom_search.columns - Colunas para pesquisar
 * @property {string[]} custom_search.values - Valores para pesquisar
 */
export const BaseListFilterType = {};

/**
 * Inicializar BaseListFilterType
 * 
 * @returns {BaseListFilterType} BaseListFilterType
 */
export const initBaseListFilterType = () => {
  return {
    paginate_type: 0,
    limit: 15,
    page: "",
    next_page: "", // Depreciado! Apenas para manter compatibilidade
    order_by: [],
    primary_keys: [],
    custom_search: {},
  };
}