import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";

const endPoint = '/financial/xml-issuedes'
export default {
  namespaced: true,
  state: {
    items: [],
    widgets: {},
    broken_sequence: [],
  },
  getters: {
    fetch: state => state.items,
    fetchWidgets: state => state.widgets,
    fetchBrokenSequence: state => state.broken_sequence,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
    [types.SET_BROKEN_SEQUENCE](state, payload) {
      state.broken_sequence = payload
    },
  },
  actions: {
    fetch({commit, state}, params) {
      return createAxios().get(`${endPoint}/widgets`,
        {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getBrokenSequence({commit, state}, params) {
      return createAxios().get(`${endPoint}/broken-sequence`,
        {params: params})
        .then(({data}) => {
          commit(types.SET_BROKEN_SEQUENCE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchWidgets({commit, state}, params) {
      return createAxios().get(endPoint,
        {params: params})
        .then(({data}) => {
          commit(types.SET, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postReSendXml({commit, state}, key) {
      return createAxios().post(`/financial/nfe/resend/${key}+microservice`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postCancelNfeByKey({commit, state}, params) {
      return createAxios().post(`/financial/nfe/cancel/${params.key}+microservice`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postUnusable({commit, state}, params) {
      return createAxios().post(`/financial/nfe/unusable+microservice`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    // TODO: Código ruim e temporário. Será removido nos próximos dias
    getDanfeFixed({commit, state}, params) {
      return createAxios().post(`generate-danfe+microservice`, params);
    },
  }
}
