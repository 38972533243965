<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb/>
      <div v-if="!loadingSkeleton" class="row align-items-center">
        <!--        <div class="col-md-3 col-sm-6 p-1">-->
        <!--          <div class="mb-3 card">-->
        <!--            <div class="p-3 card-body">-->
        <!--              <div class="row">-->
        <!--                <div class="col-8" style="margin-top: -10px">-->
        <!--                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small class="font-weight-bold">-->
        <!--                    AUTORIZADAS</small></p>-->
        <!--                </div>-->
        <!--                <div class="col-4" style="margin-top: -5px">-->
        <!--                  <div class="text-center shadow icon icon-shape bg-green rounded-circle float-right"><i-->
        <!--                    class="text-lg opacity-10 ni ni-money-coins text-white " aria-hidden="true"></i></div>-->
        <!--                </div>-->
        <!--                <div class="col-md-12" style="margin-top: -25px">-->
        <!--                  <h3 class="font-weight-semibold mb-0 pl-2">-->
        <!--                    {{ $_widgets.authorizeds || 0 }}-->
        <!--                  </h3>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-md-3 pr-1 pl-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col text-center">
                  <i style="font-size: 1.8rem;margin-bottom: 5%"
                     class="fa-2x fa-solid fa-circle-check text-green"></i><h5
                  class="card-title text-uppercase text-muted mb-0 text-center">Notas autorizadas</h5><span
                  class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                >
                   <span style="font-family: FreeMono, monospace;font-size: 17px" type="secondary">
                          {{ $_widgets.authorizeds || 0 }}
                        </span>
                      </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets col-md-3 pr-1 pl-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col text-center" @click.prevent="showBrokenSequence()">
                  <i style="font-size: 1.8rem;margin-bottom: 5%"
                     class="fa-2x fas fa-ellipsis-h text-warning"></i><h5
                  class="card-title text-uppercase text-muted mb-0 text-center">Quebra de sequência</h5><span
                  class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                >
                   <span style="font-family: FreeMono, monospace;font-size: 17px" type="secondary">
                          {{ $_widgets.broken_sequence || 0 }}
                        </span>
                      </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets col-md-3 pr-1 pl-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col text-center">
                  <i style="font-size: 1.8rem;margin-bottom: 5%"
                     class="fa-2x fa-solid fa-list-ol text-danger"></i><h5
                  class="card-title text-uppercase text-muted mb-0 text-center">Números inutilizados</h5><span
                  class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                >
                    <span style="font-family: FreeMono, monospace;font-size: 17px" type="secondary">
                          {{ $_widgets.unusables || 0 }}
                        </span>
                      </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets col-md-3 pr-1 pl-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col text-center">
                  <i style="font-size: 1.8rem;margin-bottom: 5%"
                     class="fa-2x fa-solid fa-ban text-danger"></i><h5
                  class="card-title text-uppercase text-muted mb-0 text-center">Notas canceladas</h5><span
                  class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                >
                      <span style="font-family: FreeMono, monospace;font-size: 17px" type="secondary">
                          {{ $_widgets.canceleds || 0 }}
                        </span>
                      </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row align-items-center mb-2">
        <div v-for="item in 4" class="col-md-3 col-sm-6 p-1">
          <div class="mb-3 card" style="height: 80px">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -10px">
                  <skeleton-puzl type="small"/>
                </div>
                <div class="col-2 text-right float-right" style="margin-top: -15px">
                  <skeleton-puzl type="circle"/>
                </div>
                <div class="col-5" style="margin-top: -22px">
                  <skeleton-puzl type="small"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-0" style="margin-top: -25px !important;">
        <div class="col-md-12 p-1">
          <multi-filter-v2 @fetch="load" :filter="filter" :status="multi_filter">
            <div class="col-md-4 p-2">
              <label>&nbsp;Data de emissão </label>
              <input-date-picker :filterable="1"
                                 ref="date-picker" size="small" @handleFilterDate="handleFilterDate" :default-range="range"/>
            </div>
            <div class="col-md-4 p-2">
              <label>Central</label>
              <base-input input-classes="form-control">
                <puzl-select
                  class="select-lg"
                  v-model="filter.company_plant_id"
                  :items="$_plants"
                  @input="init()"
                  :loading="loadingPlant"
                  :disabled="loadingPlant"/>
              </base-input>
            </div>
            <div class="col-md-4 p-2">
              <label>CNPJ Emissor</label>
              <base-input input-classes="form-control">
                <puzl-select
                  class="select-lg"
                  v-model="filter.company_plant_issuer_id"
                  :items="$_company_plant_issuers"
                  @input="init()"
                  label="ein"
                  :loading="loadingCompanyPlantIssuers"
                  :disabled="loadingCompanyPlantIssuers"/>
              </base-input>
            </div>
            <div class="col-md-8"/>
            <div class="col-md-2 col-6 p-2 ">
              <base-input input-classes="form-control">
                <base-button outline block @click.prevent="handleShowModalExport()"  class="select-lg"
                             type="default">
                  <i class="fa-solid fa-cloud-arrow-down"></i> Exportar
                </base-button>
              </base-input>
            </div>
            <div class="col-md-2 col-6 p-2 ">
              <base-input input-classes="form-control">
                <base-button outline block @click.prevent="showModalFiles()"  class="select-lg" type="warning">
                  <i class="fa-solid fa-folder-open"></i> Arquivos
                </base-button>
              </base-input>
            </div>
          </multi-filter-v2>
        </div>
      </div>
      <div class="row pt-0" style="margin-top: -1.4rem">
        <div class="col-md-12 p-1">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12" style="overflow-y: scroll; max-height: 90vh">
                  <table class="table table-sm table-bordered">
                    <thead>
                    <tr class="fixed">
                      <th class="text-center" style="width: 5px !important;">Ação</th>
                      <th class="text-center">Chave</th>
                      <th class="text-center" style="width: 5px !important;">Número</th>
                      <th class="text-center">Status</th>
                      <th class="text-center" style="width: 15px">Data</th>
                      <th class="text-center" style="width: 15px">O.S</th>
                      <th class="text-center">Código do contrato</th>
                      <th class="">Cliente</th>
                      <th class="text-center" style="width: 5px !important;">Data de emissão</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in 10" v-show="loadingSkeleton">
                      <th colspan="10">
                        <skeleton-puzl type="button"/>
                      </th>
                    </tr>
                    <tr v-show="!loadingSkeleton && ($_xml_issuedes.data && $_xml_issuedes.data.length)"
                        v-for="item in $_xml_issuedes.data"
                    >
                      <th>
                        <base-dropdown>
                          <!--                          <base-button-->
                          <!--                            slot="title-container"-->
                          <!--                            block-->
                          <!--                            type="default"-->
                          <!--                            class="dropdown-toggle text-uppercase"-->
                          <!--                          >-->
                          <!--                            <i class="fa-solid fa-file-excel"></i> Selecione-->
                          <!--                          </base-button>-->
                          <a
                            style="text-transform: none"
                            slot="title-container"
                            class="text-default dropdown-toggle font-weight-300"
                          >
                            Selecione
                          </a>
                          <div>
                            <a @click.prevent="downloadDanfe(item.schedule_travel_uuid, item.key)">
                              <span class="dropdown-item text-left">
                                <i class="fa-solid fa-file-pdf text-danger" style="margin-right: 2px"></i>
                                PDF
                              </span>
                            </a>
                            <a @click.prevent="downloadPdfWithForcedUpdate(item.pdf_file_path, item.key, 'pdf')">
                              <span class="dropdown-item text-left">
                                <i class="fa-solid fa-file-pdf text-danger" style="margin-right: 2px"></i>
                                ATUALIZAR PDF
                              </span>
                            </a>
                            <div class="dropdown-divider p-0 m-0"></div>
                            <a @click.prevent="download(item.xml_file_path, item.key, 'xml')">
                              <span class="dropdown-item text-left">
                                <img width="15px" style="margin-right: 4px"src="/img/icons/nfe_xml.png">
                                XML
                              </span>
                            </a>
                          </div>
                        </base-dropdown>
                      </th>
                      <th style="width: 5px !important;" class="text-center">
                        <a href="#" @click.prevent="copy(item.key)">
                          <i class="fa-solid fa-clipboard text-muted" style="font-size: 1.25rem"></i>
                        </a>
                      </th>
                      <th style="width: 5px !important;" class="text-left">
                        {{ item.number }} - {{ item.series }}
                      </th>
                      <th v-if="item.status == schedule_travel_types.nfe_in_progress || item.status == schedule_travel_types.nfe_sending" style="width: 50px" class="text-left text-yellow">
                        Emitindo
                      </th>
                      <th
                        v-if="item.status == schedule_travel_types.nfe_completed" style="width: 50px;cursor: pointer" @click.prevent="cancelNfe(item)"
                        class="text-left text-success"
                      >
                        Autorizada
                      </th>
                      <th
                        v-if="item.status == schedule_travel_types.nfe_rejected" style="width: 50px;cursor: pointer" @click.prevent="reSendXml(item)"
                        class="text-left text-darkred"
                      >
                        Rejeitada
                      </th>
                      <th v-if="item.status == schedule_travel_types.nfe_in_cancelation_process" style="width: 50px" class="text-left text-danger">Cancelando</th>
                      <th v-if="item.status == schedule_travel_types.nfe_duplicated" style="width: 50px;cursor:pointer;" @click.prevent="reSendXml(item)"
                          class="text-left text-primary">Duplicada
                      </th>
                      <th v-if="item.status == schedule_travel_types.nfe_in_cancelation_process" style="width: 50px" class="text-left text-warning">Cancelando</th>
                      <th v-if="item.status == schedule_travel_types.nfe_canceled" style="width: 50px" class="text-left text-danger">Cancelada</th>
                      <th
                        v-if="item.status == schedule_travel_types.nfe_denegada" style="width: 50px;cursor: pointer" @click.prevent="reSendXml(item)"
                        class="text-left text-darkred"
                      >
                        Denegada
                      </th>
                      <th class="text-left">
                        <span class="font-weight-normal">{{ item.date_at | parseDate('DD/MM/YYYY') }}</span>
                      </th>
                      <th class="text-left">
                        <span style="font-weight: normal;width: 10px">{{ item.os }}</span>
                      </th>
                      <th class="text-center">
                        <span style="font-weight: normal">{{ item.code }}</span>
                      </th>
                      <th class="text-left text-truncate">
                        <a href="#" @click.prevent="openModalEditEntity(item.entity_uuid)">
                          <img style="width: 22px" src="/img/icons/create-new.png" />
                        </a>
                        <span style="font-weight: normal" class="px-2">{{ item.entity_name }}</span>
                      </th>
                      <th class="text-center">
                        <span class="font-weight-normal">{{ item.created_at | parseDate('DD/MM/YYYY') }}</span>
                      </th>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <puzl-empty-data
                v-if="!loadingSkeleton && ($_xml_issuedes.data && !$_xml_issuedes.data.length) ||
                !loadingSkeleton && !$_xml_issuedes.data" :with-card="false"
              />
              <pagination v-show="$_xml_issuedes.data" @navegate="navegate" :source="$_xml_issuedes"
                          :loading="loadingSkeleton"/>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <modal-re-send
      :show="showModalReSend"
      :selected="selected"
      @close="showModalReSend = false"/>
    <modal-broken-sequence
      :show="showModalBrokenSequence"
      @close="showModalBrokenSequence = false"
    />
    <modal-files ref="ModalFiles"/>
    <modal-cancel-nfe
      :selected="selected"
      :show="showModalCancelNfe"
      @close="showModalCancelNfe = false"
    />
    <modal-exporter
      @openProcessList="showModalFiles()"
      ref="ModalExporter"
    />
    <ModalEditEntity ref="modalEditEntity" />
  </div>
</template>

<script>
import MultiFilterV2 from "@/components/Utils/MultiFilterV2";
import {SkeletonPuzl} from "@/components";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import {mapGetters} from "vuex";
import Pagination from "../../../../../components/Utils/Pagination";
import ModalReSend from "./Shared/_ModalReSend"
import ModalCancelNfe from "./Shared/_ModalCancelNfe"
import ModalBrokenSequence from "./Shared/_ModalBrokenSequence"
import globalFilter from "@/mixins/globalFilter";
import PuzlEmptyData from "../../../../../components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import {base_url_ms} from '@/plugins'
import ModalFiles from "./Shared/_ModalFiles";
import ModalExporter from "./Shared/_ModalExporter"
import InputDatePicker from "../../../../../components/InputDatePicker";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import { hasPermission } from "@/plugins/permissions";

export default {
  components: {
    InputDatePicker,
    ModalFiles,
    PuzlEmptyData,
    Pagination,
    PuzlBreadcrumb,
    ModalExporter,
    MultiFilterV2,
    SkeletonPuzl,
    ModalReSend,
    ModalBrokenSequence,
    ModalCancelNfe,
    PuzlSelect,
    ModalEditEntity,
  },
  name: "Index",
  mixins: [globalFilter],
  data() {
    return {
      loadingPlant: false,
      loadingCompanyPlantIssuers: false,
      base_url_ms: base_url_ms(),
      showModalReSend: false,
      showModalCancelNfe: false,
      showModalBrokenSequence: false,
      selected: false,
      filter: {
        company_plant_id: null,
        status: null,
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        99: {
          name: "Emitindo",
          type: "warning"
        },
        1: {
          name: "Autorizada",
          type: "success"
        },
        2: {
          name: "Rejeitada",
          type: "danger"
        },
        4: {
          name: "Cancelada",
          type: "danger"
        },
        5: {
          name: "Duplicada",
          type: "primary"
        },
        6: {
          name: "Denegada",
          type: "warning"
        },
      },
      range: {
        start: new Date(),
        end: new Date(),
        default_filterable: false,
      },
      params: {
        page: 1,
        per_page: 3,
      },
      loadingSkeleton: false,
      loadingSkeletonWidgets: false,
      has_permission: hasPermission,
      schedule_travel_types: {
        nfe_in_progress: 0,
        nfe_completed: 1,
        nfe_rejected: 2,
        nfe_in_cancelation_process: 3,
        nfe_canceled: 4,
        nfe_duplicated: 5,
        nfe_sending: 7,
        nfe_denegada: 6,
        nfe_internal_error: 97,
        nfe_event_error: 99,
      }
    }
  },
  computed: {
    ...mapGetters({
      $_xml_issuedes: "xmlIssuedes/fetch",
      $_widgets: "xmlIssuedes/fetchWidgets",
      $_plants: 'plant/activeItems',
      $_company_plant_issuers: "companyPlantIssuer/fetch",
    })
  },
  methods: {
    navegate(page) {
      this.init(page)
    },
    load(filter) {
      this.filter.global = filter.global
      this.init()
    },
    showBrokenSequence() {
      let loader = this.$loading.show()
      this.$store.dispatch('xmlIssuedes/getBrokenSequence', {
        filter: this.filter
      })
        .then(() => {
          this.showModalBrokenSequence = true
          loader.hide()
        }).catch(() => {
        loader.hide()
      })
    },
    handleFilterDate(filter) {
      this.filter.range = filter
      this.init({})
    },
    showModalFiles() {
      this.$refs.ModalFiles.openModal()
    },
    handleShowModalExport() {
      this.$refs.ModalExporter.openModal()
    },
    async downloadDanfe(schedule_travel_uuid, key) {
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${key}.pdf`);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },

    async download(s3_path, key, format) {
// Inicia o download
      const url = this.base_url_ms + 'download-s3?url=' + s3_path
      this.$store.dispatch('exports/download', url)
        .then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${key}.${format}`); // Added Line
          await link.click();
        });
    },
    // TODO: Código ruim e temporário. Será removido nos próximos dias
    async downloadPdfWithForcedUpdate(s3_path, key, format) {
      let loader = this.$loading.show();
      try {
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
        await this.$store.dispatch('xmlIssuedes/getDanfeFixed', {key: key});
        const url = this.base_url_ms + 'download-s3?url=' + s3_path;
        this.$store.dispatch('exports/download', url).then(async response => {
            let blob = new Blob([response], {type: "application/pdf"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", `${key}.${format}`);
            await link.click();
          });
      } finally {
        loader.hide();
      }
    },
    init(page = 1) {
      this.params.page = page
      this.loadingSkeleton = true
      this.params.filter = this.filter
      this.$store.dispatch('xmlIssuedes/fetch', this.params)
        .then(() => {
          this.loadingSkeleton = false
        }).catch(() => {
        this.loadingSkeleton = false
      })
      this.loadingSkeletonWidgets = true
      this.$store.dispatch('xmlIssuedes/fetchWidgets', this.params)
        .then(() => {
          this.loadingSkeletonWidgets = false
        }).catch(() => {
        this.loadingSkeletonWidgets = false
      })
    },
    copy(key) {
      this.$helper.copy(key)
      this.$notify({type: 'success', message: 'Chave de acesso copiada com sucesso!'})
    },
    /**
     * @param {object} item | $_xml_issuedes
     */
    cancelNfe(item) {
      if (this.has_permission('additional_permissions.cancellation-of-nfe')) {
        const emissionDateTime = new Date(item.xml_travel_updated_at);
        const now = new Date();
        const timeDifferenceInHours = (now - emissionDateTime) / (1000 * 60 * 60);
        if (timeDifferenceInHours <= 24) {
          this.selected = item;
          this.showModalCancelNfe = true;
        } else {
          this.$notify({
            type: 'danger',
            message: 'Não é permitido cancelar a NFe após 24 horas da data de emissão.'
          });
        }
      } else {
        this.$notify({
          type: 'danger',
          message: 'Usuário sem permissão para cancelamento de NFe.'
        });
      }
    },
    reSendXml(item) {
      if (item.status !== this.schedule_travel_types.nfe_rejected && item.status !== this.schedule_travel_types.nfe_duplicated && item.status !== this.schedule_travel_types.nfe_denegada) {
        return
      }
      this.selected = item
      this.showModalReSend = true
    },
    /**
     * Abre modal de editar clientes/fornecedores
     *
     * @param {string} uuid
     */
    openModalEditEntity(uuid) {
      this.$refs.modalEditEntity.openModal(true, uuid);
    },
  },
  beforeMount() {
    this.loadingPlant = true
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingPlant = false
    })
    this.loadingCompanyPlantIssuers = true
    this.$store
      .dispatch("companyPlantIssuer/fetchItems").then(() => {
      this.loadingCompanyPlantIssuers = false
    })
    this.filter.company_plant_id = null
    this.init()
  },
  mounted() {
    this.load()
  }
}
</script>

<style scoped>

</style>
