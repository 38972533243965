<template>
  <div class="row card-wrapper" v-if="requests.length">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(index) in requests" :key="index">
      <div class="card main-card">
       <div class="px-3 pt-3 pb-1 d-flex justify-content-between">
          <div>
            <div class="mb-2">
              <div>
                <div style="font-size: 12px; font-weight: 400; color: #606062;">PUZL-003</div>
                <div></div>
              </div>
              <div style="font-size: 12px; font-weight: 400; color: #606062;">7 ITENS</div>
            </div>
            <div class="d-flex">
              <span style="font-size: 18px ;font-weight: 600; color: #2B2D32;">R$ 4.018,37</span>
              <div>
                <div class="align-self-center card-status" style="background-color: #fdf7e7; color: #f5c865;">
                  Pendente
                </div>
              </div>
            </div>
            <div class="mt-1">
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">17 JAN 2024</span>
            </div>
          </div>
          <div class="position-relative">
            <!-- Menu engrenagem -->
            <base-dropdown menuOnRight class="d-flex justify-content-end" style="height: fit-content">
              <div slot="title-container" class="dropdown-toggle rounded">
                <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div>
                <a class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new"/>
                  Editar
                </a>
              </div>
              <div>
                <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                <a class="dropdown-item d-flex"
                  style="font-size: 12px;">
                  <img width="18" height="18" src="/img/icons/order-history.png">
                  Transferir
                </a>
              </div>
              <div>
                <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                <a class="dropdown-item d-flex"
                  style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/delete_danger.png">
                  Excluir
                </a>
              </div>
            </base-dropdown>
            <!-- Icones -->
            <div style="" class="d-flex pointer position-absolute bottom-0 right-0">
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div style="font-size: 12px">xxxxxxx</div>
                  <span slot="reference">
                    <img src="/img/icons/paperclip-green.png" width="22">
                  </span>
                </el-popover>
              </div>
              <div class="mr-2" @click="handleShowModal({ ref: 'modalListItemsApproval', data: null })">
                  <span slot="reference">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </span>
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div style="font-size: 12px">xxxxxxx</div>

                  <span slot="reference">
                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                  </span>
                </el-popover>
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div style="font-size: 12px">xxxxxxx</div>
                  <span slot="reference">
                    <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22"/>
                  </span>
                </el-popover>
              </div>
            </div>
          </div>
       </div>
       <div class="dropdown-divider mx-3" />
        <div class="ml-3">
          <span style="font-size: 14px ;font-weight: 400; color: #2B2D32;">Manutenção</span>
        </div>
        <div class="ml-3 mb-3">
          <span style="font-size: 12px ;font-weight: 400; color: #606062;">08.732.993/0001-06</span>
        </div>
        <!-- Botoes de aprovacao -->
        <div class="d-flex mb-3 text-center justify-content-around">
          <div class="ml-2 mb-1 aprovation-button">
            <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumb-up_primary.png">
            Aprovar
          </div>
          <div class="mr-2 mb-1 aprovation-button">
            <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumbs-down_danger.png">
            Negar
          </div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval @addContact="listContacts" ref="modalListItemsApproval"/>
  </div>
</template>
<script>

import ModalListItemsApproval from "./Shared/_ModalListItemsApproval";

export default {
  name: "PurchasesOrderCards",
  components: {
    ModalListItemsApproval
  },
  props: {
    requests: Array,
  },
  methods: {
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
  }
};
</script>

<style scoped>
.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}
.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 1.6rem !important;
}
.card-status {
  border-radius: 5px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.2rem 0.6rem;
  margin-left: 0.7rem;
  margin-top: 0.1rem;
}
.aprovation-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 45%;
  height: 2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
}
.aprovation-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}
</style>
