<template>
  <div v-if="billet">
    <modal @close="close" size="sm" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div class="row">
        <div class="col-md-12 mt-2">
          <div class="timeline timeline-one-side">
            <div class="timeline-block"><span class="timeline-step badge-primary">
              <i class="fa-solid fa-arrow-rotate-left"></i>
            </span>
              <pre>{{ index }}/{{ totalInstallments }}</pre>
              <div class="timeline-content">
                <small class="text-muted font-weight-bold" style="font-family: Gilroy,sans-serif !important">({{index}}/{{totalInstallments}})</small>
                <h4 class=" mb-0" style="font-family: Gilroy,sans-serif !important">{{ billet.amount | currency() }}</h4>
                <div class="row mr-1">
                  <div class="col-md-12">
                    <hr style="margin-bottom: -2px;margin-top: 10px">
                    <label style="font-family: Gilroy,sans-serif !important;font-size: 0.8rem" class="col-form-label form-control-label">
                      Data de vencimento <span class="text-danger">&nbsp;*</span></label
                    >
                    <div class="w-100 pr-3 pr-md-0">
                      <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker
                        :disableDate="new Date()"
                        :mode="'date'"
                        v-model="billet.expire_at"
                      />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Cancelar
        </base-button>
        <base-button :disabled="!billet.expire_at" type="success" @click="update()">
          Atualizar
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import InputDateTimePicker from "@/components/InputDateTimePicker";
const {formatErrorValidation} = require("@/plugins")
export default {
  name: "ModalUpdateBillet",
  components: {
    InputDateTimePicker
  },
  data() {
    return {
      title: 'Alterar Boleto',
      show: false,
      billet: null,
      index: null,
      totalInstallments: null,
    }
  },
  methods: {
    close() {
      this.show = false;
    },
    update() {
      let loader = this.$loading.show()
      this.$store.dispatch("bankBillet/update", this.billet)
        .then((response) => {
          this.$notify({type: response.error_type, message: response.message});
          this.$emit('close');
          this.close();
        }).catch((error) => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          loader.hide();
        });
    },
    openModal(billet, index, totalInstallments) {
      this.billet = {...billet};
      this.index = index;
      this.totalInstallments = totalInstallments;
      this.billet.expire_at = null;
      this.show = true;
    }
  },
};
</script>

<style>
</style>
