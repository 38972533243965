<template>
  <v-select
    @input="handleInput"
    autocomplete="on"
    v-if="enabled"
    v-click-outside="handleOnSelectChange"
    ref="selectedEl"
    :reduce="(item) => item.id"
    :multiple="multiple"
    :value="loading && multiple ? [] : loading ? null : value"
    :clearable="clearable"
    :closeOnSelect="!multiple"
    :loading="loading"
    @search="fetchOptions"
    :disabled="disabled"
    :placeholder="placeholder"
    :selectable="selectable"
    class="uppercase-select"
    :class="disableBoxShadow && 'no-box-shadow'"
    :options="
      items.map(function (item) {
          return {
            label: labelMask ? applyMask(item) : item[label],
            id: recursiveKey ? this.setByArray(item, recursiveKey) : item[customKey],
            extra_field : this.extraField ? item[this.extraField] : null,
          };
      }, this)
    "
  >
    <span slot="no-options">Não há dados</span>
  </v-select>
</template>
<script>
export default {
  name: 'PuzlSelect',
  data() {
    return {
      enabled: true,
    }
  },
  methods: {
    /**
     * Ao pesquisar dentro do vue-select, irá chamar este método
     * @param search
     * @param loading
     */
    fetchOptions (search, loading) {
      this.emit('search', search);
    },
    emit(event, e) {
      return new Promise((resolve, reject) => {
        this.$emit(event, e)
        this.$nextTick(resolve)
      })
    },
    /**
     * Recria o componente.
     * @param e
     */
    handleOnSelectChange: function (e) {
      if ((!e || e.isTrusted) && !this.$refs.selectedEl.value) {
        this.enabled = false
        this.$nextTick(function () {
          this.enabled = true
        })
      }
    },
    async handleInput(e) {
      await this.emit('input', e)
      this.$emit("change", e);
      this.handleOnSelectChange(e)
    },
    setByArray(arr, items) {
      let title = arr
      items.map(function (a) {
        title = title[a]
      })
      return title
    },
    applyMask(item) {
      if (typeof this.label === 'object') {
        return this.setByArray(item, this.label)
      }
      return this.label.split("$").reduce(function (string, to_sum) {
        return string + (typeof item[to_sum] !== 'undefined' ? item[to_sum] : to_sum);
      }, "");
    },
  },
  props: {
    value: {
      type: [Array, String, Number],
      default: null,
      description: "Dados selecionados",
    },
    recursiveKey: {
      type: Array,
      required: false,
      description: "Chave primária recursiva",
    },
    disableBoxShadow: {
      type: Boolean,
      default: false,
      description: "Adiciona estilos para desabilitar o box shadow e aumentar border-radius, conforme o novo padrão."
    },
    clearable: {
      type: Boolean,
      default: true,
      description: "Limpar select após seleção",
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    customKey: {
      type: String,
      default: "id",
      description: "Chave primária",
    },
    label: {
      type: [Array, String],
      default: "name",
      description: "Label",
    },
    loading: {
      type: Boolean,
      default: false,
      description: "Loading",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Desabilitar",
    },
    labelMask: {
      type: Boolean,
      default: false,
      description: "Ativa máscara para label",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Permitir múltiplos dados",
    },
    items: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      description: "Dados a selecionar",
    },
    selectable: {
      type: Function,
      default: (option) => true,
      description: "Função para definir se item é selecionável",
    },
    extraField: {
      type: [String,Boolean],
      default: false,
      description: "Campo extra para ser adicionado em array principal",
    },
    disableBoxShadow: {
      type: Boolean,
      default: false,
      description: "Adiciona estilos para desabilitar o box shadow, conforme o novo padrão."
    }
  },
};
</script>
<style type="text/css">
.vs__selected-options > span, input {
  font-size: 0.9rem !important;
}

:root {
  --vs-state-disabled-bg: #e9ecef !important;
}

.uppercase-select .vs__dropdown-option {
  text-transform: uppercase !important;
}

.disable-box-shadow-select {
  .vs__dropdown-toggle {
    box-shadow: none !important;
    border-top: 0.5px solid #E8E8E8 !important;
    border-right: 0.5px solid #E8E8E8 !important;
    border-bottom: 0.5px solid #E8E8E8 !important;
    border-left: 0.25px solid #E8E8E8 !important;
    border-radius: 4px !important;
  }
}

div.no-box-shadow .vs__dropdown-toggle {
  box-shadow: none !important;
  border-radius: 8px !important;
  border: 1px solid #ECECEC;
}
</style>
