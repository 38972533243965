<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form class="needs-validation" autocomplete="off">
            <div class="form-group row m-0 p-0">
              <div class="col-md-12 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Financeiro
                </div>
                <base-switch
                  v-model="entityContact.is_financial"
                  type="success"
                  offText="inativo"
                  onText="ativo"
                  class="success"
                ></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-6 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="entityContact.name"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Cargo
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="entityContact.office"
                />
              </div>
            </div>

            <div class="form-group row m-0 p-0">
              <div class="col-md-6 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Email
                  <span class="text-danger" v-if="entityContact.is_financial">
                    &nbsp;*
                  </span>
                </div>
                <validation-provider
                  :rules="entityContact.is_financial ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    v-model="entityContact.email"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label">
                  Celular
                  <span class="text-danger">&nbsp;*</span>
                </div>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="tel"
                    class="form-control form-control-sm"
                    v-model="entityContact.phone"
                    v-mask="['(##) # ####-####']"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
                @click.prevent="addContact(entityContact)"
              >
                Adicionar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const { formatErrorValidation } = require("@/plugins");

export default {
  name: "CreateEntityContact",
  components: {},
  data() {
    return {
      modal: {
        title: "Cadastrar Contato",
        create: false,
      },
      entityContact: {
        is_financial: false,
        name: "",
        office: "",
        email: "",
        phone: "",
        is_new_registry: true,
      },
      contacts: [],
      loadingStore: false,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {array} contacts
     */
    openModal(contacts = null) {
      this.clearEntityAccount();
      if (contacts && contacts.length) {
        this.contacts = contacts;
      }
      this.modal.create = true;
    },
    /**
     * Adiciona contato no array.
     * @param {object} contact
     */
    addContact(contact) {
      this.contacts.push(contact);
      this.$emit("storedEntityContact", this.contacts);
      this.contacts = [];
      this.closeModal();
    },
    clearEntityAccount() {
      this.entityContact = {
        is_financial: false,
        name: "",
        office: "",
        email: "",
        phone: "",
        is_new_registry: true,
      };
    },
  },
};
</script>

<style scoped></style>
