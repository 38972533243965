<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-4">
          <h2 style="font-size: 1.2rem !important;" class="text-primary">
            <img height="30px" src="/img/icons/check.png" class="mr-3" />
            FATURAS/NFS-E
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end">
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button
                @click.prevent="showModalNfseAllotments = true"
                type="primary"
                block
              >
                <img height="19" src="/img/icons/icons8/ios/note.png" alt="note" class="mr-1"/>
                NFS-E
              </base-button>
            </div>
            <div class="mb-2 mb-md-0 col-md-3 px-1">
              <base-button
                @click.prevent="handleShowModalPendingBankBillet()"
                type="warning"
                block
              >
                <img height="19" src="/img/icons/icons8/ios/barcode.png" alt="barcode" class="mr-1"/>
                BOLETOS
              </base-button>
            </div>
              <base-dropdown class="col-md-3">
                <base-button
                  slot="title-container"
                  block
                  type="default"
                  class="dropdown-toggle text-uppercase"
                >
                  <img height="21" src="/img/icons/icons8/ios/graph-report.png" />
                  Relatórios
                </base-button>
                <div>
                  <a href="#" @click.prevent="handleShowModalReport()">
                    <span class="dropdown-item">
                      <img style="width: 23px" src="/img/icons/pdf-v2.png" class="ml-n1 ml-1" />
                      Relatório de faturas
                    </span>
                  </a>
                </div>
                <div>
                  <a href="#" @click.prevent="showModalNfseExcelReport = true">
                    <span class="dropdown-item">
                      <img style="width: 23px" src="/img/icons/excel.png" />
                      Resumo de Faturas e NFS-E
                    </span>
                  </a>
                </div>
                <div>
                  <a href="#" @click.prevent="showModalResumeFinishedTravelsReport = true">
                    <span class="dropdown-item">
                      <img style="width: 23px" src="/img/icons/excel.png" />
                      Resumo Geral
                    </span>
                  </a>
                </div>
              </base-dropdown>
          </div>
        </div>
      </div>
<!--        <div class="col-lg-12 col-12">-->
<!--          <h6 class="h2 text-gray d-inline-block mb-0">{{ this.$route.meta.breadcrumbs[1].title }}</h6>-->
<!--          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">-->
<!--            <ol class="breadcrumb">-->
<!--              <li class="breadcrumb-item"><a href="#"><i :class="this.$route.meta.icon"></i></a></li>-->
<!--              <li class="breadcrumb-item"><a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>-->
<!--              <li class="breadcrumb-item" aria-current="page">{{ this.$route.meta.breadcrumbs[1].title }}</li>-->
<!--            </ol>-->
<!--          </nav>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mb-3">-->
<!--        <div class="col-md-2 mb-2">-->
<!--          <router-link :to="{ path:'/billing/opened'}">-->
<!--            <base-button block size="lg"-->
<!--                         type="success" class="text-uppercase">-->
<!--              <i class="fa-solid fa-laptop-file"></i> Em aberto-->
<!--            </base-button>-->
<!--          </router-link>-->
<!--        </div>-->
<!--        <div class="col-md-2 mb-2">-->
<!--          <router-link :to="{-->
<!--            name:'billing.single',-->
<!--            path:'/billing/single'-->
<!--          }">-->
<!--            <base-button block size="lg"-->
<!--                         type="indigo" class="text-uppercase">-->
<!--              <i class="fa-regular fa-square-plus"></i> Avulso-->
<!--            </base-button>-->
<!--          </router-link>-->
<!--        </div>-->
<!--        <div class="col-md-2 mb-2">-->
<!--            <base-button block size="lg" @click.prevent="showModalNfseAllotments = true"-->
<!--                         type="primary" class="text-uppercase">-->
<!--              <i class="fa-regular fa-file"></i> NFS-e-->
<!--            </base-button>-->
<!--        </div>-->
<!--        <div class="col-md-2 mb-2">-->
<!--            <base-button block size="lg" @click.prevent="handleShowModalPendingBankBillet()"-->
<!--                         type="warning" class="text-uppercase">-->
<!--              <i class="fa-solid fa-barcode"></i> Boleto-->
<!--            </base-button>-->
<!--        </div>-->
<!--        <div class="col-md-2 mb-2">-->
<!--          <base-button block size="lg" @click.prevent="handleShowModalReport()"-->
<!--                       type="info" class="text-uppercase">-->
<!--            <i class="fa-solid fa-chalkboard"></i>-->
<!--            RELATÓRIO-->
<!--          </base-button>-->
<!--        </div>-->
<!--        <base-dropdown class="col-md-2 mb-2">-->
<!--          <base-button-->
<!--            slot="title-container"-->
<!--            block-->
<!--            size="lg"-->
<!--            type="default"-->
<!--            class="dropdown-toggle text-uppercase"-->
<!--          >-->
<!--            <i class="fa-solid fa-cloud-arrow-down"></i> Exportar-->
<!--          </base-button>-->
<!--          <div>-->
<!--            <a href="#" @click.prevent="showModalNfseExcelReport = true">-->
<!--              <span class="dropdown-item">NFS-e (Excel)</span>-->
<!--            </a>-->
<!--          </div>-->
<!--          <div>-->
<!--            <a href="#" @click.prevent="showModalResumeFinishedTravelsReport = true">-->
<!--              <span class="dropdown-item">Resumo Geral (Excel)</span>-->
<!--            </a>-->
<!--          </div>-->
<!--        </base-dropdown>-->
<!--&lt;!&ndash;        <base-dropdown class="col-md-3 mb-2">&ndash;&gt;-->
<!--&lt;!&ndash;          <base-button&ndash;&gt;-->
<!--&lt;!&ndash;            slot="title-container"&ndash;&gt;-->
<!--&lt;!&ndash;            block&ndash;&gt;-->
<!--&lt;!&ndash;            size="lg"&ndash;&gt;-->
<!--&lt;!&ndash;            type="primary"&ndash;&gt;-->
<!--&lt;!&ndash;            class="dropdown-toggle text-uppercase"&ndash;&gt;-->
<!--&lt;!&ndash;          >&ndash;&gt;-->
<!--&lt;!&ndash;            <i class="fa-solid fa-folder-tree"></i> Emissão em lote&ndash;&gt;-->
<!--&lt;!&ndash;          </base-button>&ndash;&gt;-->
<!--&lt;!&ndash;          <div>&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash;            <a href="#" @click.prevent="showModalAllotments = true">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash;              <span class="dropdown-item">FATURAMENTO EM LOTE</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash;            </a>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            <router-link :to="{ path:'/financial/billing/invoice'}">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="dropdown-item">FATURAMENTO</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="#" @click.prevent="showModalNfseAllotments = true">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="dropdown-item">NFS-E</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="#" @click.prevent="handleShowModalPendingBankBillet()">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="dropdown-item">BOLETO</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </base-dropdown>&ndash;&gt;-->
<!--      </div>-->
    </base-header>
    <modal-search-contract @openBilling="openBilling" ref="modalSearchContract"/>
    <modal-report ref="modalReport"/>
    <modal-allotments ref="modalAllotments" :show="showModalAllotments"
                      @close="showModalAllotments = false"/>
    <modal-nfse-allotments
      ref="modalNfseAllotments"
      :show="showModalNfseAllotments"
      @close="modalNfseAllotmentsOnClose"
    />
    <modal-nfse-excel-report
      ref="modalNfseExcelReport"
      :show="showModalNfseExcelReport"
      @close="showModalNfseExcelReport = false"
    />
    <modal-resume-finished-travels-report
      ref="modalResumeFinishedTravelsReport"
      :show="showModalResumeFinishedTravelsReport"
      @close="showModalResumeFinishedTravelsReport = false"
    />
    <modal-generate-pending-bank-billet
      :show="showModalGeneratePendingBankBillet"
      @close="modalGeneratePendingBankBilletOnClose"
    />
    <list ref="listInvoices"/>
  </div>
</template>

<script>
import ModalSearchContract from "./Shared/_ModalSearchContract";
import List from './Shared/_List'
import ModalReport from './Shared/_ModalReport'
import ModalAllotments from "@/views/Modules/Financial/Billing/Shared/_ModalAllotments";
import ModalNfseAllotments from "@/views/Modules/Financial/Billing/Shared/_ModalNfseAllotments";
import ModalNfseExcelReport from "@/views/Modules/Financial/Billing/Shared/_ModalNfseExcelReport";
import ModalResumeFinishedTravelsReport
  from "@/views/Modules/Financial/Billing/Shared/_ModalResumeFinishedTravelsExcelReport";
import ModalGeneratePendingBankBillet from "./Shared/_ModalGeneratePendingBankBillet";

export default {
  name: "Index",
  components: {
    ModalGeneratePendingBankBillet,
    ModalAllotments,
    ModalNfseAllotments,
    ModalNfseExcelReport,
    ModalResumeFinishedTravelsReport,
    ModalSearchContract,
    List,
    ModalReport
  },
  data() {
    return {
      showModalAllotments: false,
      showModalNfseAllotments: false,
      showModalNfseExcelReport: false,
      showModalResumeFinishedTravelsReport: false,
      showModalGeneratePendingBankBillet: false,
    }
  },
  methods: {
    init() {
    },
    handleShowModalPendingBankBillet() {
      let loader = this.$loading.show()
      const filter = {
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
        }
      }
      this.$store.dispatch('billingInvoice/getPendingBankBillets', {
        filter: filter
      }).then(() => {
        loader.hide()
        this.showModalGeneratePendingBankBillet = true
      }).catch(() => {
        loader.hide()
      })
    },
    openBilling(params) {
      this.$router.push({
        name: 'financial.billing.proposal',
        params: {
          contract_proposal_uuid: params.uuid
        }
      })
    },
    async handleShowModalReport() {
      await this.$refs.modalReport.openModal()
      await this.$refs.modalReport.$refs.modalSearchContract.openModal(false)
    },
    modalNfseAllotmentsOnClose(modalResult) {
      this.showModalNfseAllotments = false
      if (modalResult === true) this.$refs.listInvoices.init()
    },
    modalGeneratePendingBankBilletOnClose(modalResult) {
      this.showModalGeneratePendingBankBillet = false
      if (modalResult === true) this.$refs.listInvoices.init()
    }
  }
}
</script>

<style scoped>

</style>