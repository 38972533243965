 <template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-success">
            <img height="30px" src="/img/icons/client-company.png" class="mr-2"/>
            Preço de concreto
          </h2>
        </div>
        <div class="col-md-8 mt-n1">
          <div class="row d-flex justify-content-end show-md">
            <div class="col-md-3 px-1">
              <base-button type="primary" block style="border-radius: 4px !important;"
                @click.prevent="$router.push({ path: '/commercial/concrete-price/parameter' })">
                <img height="19px" src="https://img.icons8.com/ios/100/FFFFFF/vertical-settings-mixer--v1.png" class="mr-1 mt-n1"/> Parâmetros
              </base-button>
            </div>
            <div class="col-md-3 px-1">
              <base-button class="new-default-black" block style="border-radius: 4px !important;">
                <img height="19px" src="https://img.icons8.com/ios/100/FFFFFF/graph-report.png" class="mr-1 mt-n1"/> Relatórios
              </base-button>
            </div>
          </div>
          <div class="row d-flex justify-content-end show-mobile">
            <div class="col-md-2 mb-2">
              <base-button type="primary" block
                @click.prevent="$router.push({ path: '/commercial/concrete-price/parameter' })">
                <img height="19px" src="https://img.icons8.com/ios/100/FFFFFF/vertical-settings-mixer--v1.png"/> Parâmetros
              </base-button>
            </div>
            <div class="col-md-2 mb-2">
              <base-button class="new-default-black" block>
                <img height="19px" src="https://img.icons8.com/ios/100/FFFFFF/graph-report.png"/> Relatórios
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <!-- Barra de busca -->

    <div class="container-fluid">
      <div class="mt-4 mb-n5 ml-2">
        <i :class="listType !== 'cards' && 'opacity-25'" @click.prevent="setViewType('cards')"
          class="pointer fa-regular fa-hard-drive fa-2x"></i>
        <i :class="listType !== 'table' && 'opacity-25'" @click.prevent="setViewType('table')"
          class="pointer fa-solid fa-table-list fa-2x ml-1"></i>
      </div>
      <div class="mt-5">
        <multi-filter
          :showSlotDates="false"
          :showSlotBookmark="false"
          :type="3"
          @clearFilter="clearFilter()" @fetch="fetch">
          <template slot="data">
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">CENTRAL</label>
              <puzl-select
                v-model="selectedCompanyPlants"
                :items="$_plants"
                :multiple="true"
                class="select-xl new-default-black-font"
                :loading="loadingCompanyPlants"
                :disabled="loadingCompanyPlants"
                @input="handleFilterCompanyPlants"
                placeholder="Central"
              />
            </div>
            <div class="col-md-12 mt-2 text-left">
              <label class="form-control-label">DIMENSÃO MÁXIMA</label>
              <puzl-select
                v-model="gravel_filter"
                :items="$_maximum_sizes"
                :multiple="true"
                class="select-xl new-default-black-font"
                :loading="loadingMaximumSize"
                label="description"
                :disabled="loadingMaximumSize"
                @input="handleFilterGravels"
                placeholder="Selecione"
              />
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">SLUMP/FLOW</label>
              <puzl-select
                  v-model="slump_filter"
                  :items="$_slump_flows"
                  :multiple="true"
                  class="select-xl new-default-black-font"
                  :loading="loadingSlumpFlow"
                  label="nomenclature"
                  :disabled="loadingSlumpFlow"
                  @input="handleFilterSlumps"
                  placeholder="Selecione"
                />
            </div>
            <div class="col-md-12 mt-3 text-left">
              <label class="form-control-label">TIPO</label>
                <puzl-select
                  v-model="mix_types_filter"
                  :items="$_mix_types"
                  :multiple="true"
                  class="select-xl new-default-black-font"
                  :loading="loadingMixTypes"
                  label="technical_description"
                  :disabled="loadingMixTypes"
                  @input="handleFilterMixTypes"
                  placeholder="Selecione"
                />
            </div>
          </template>
          <template slot="status">
            <div class="col-12">
              <base-button-hoverable @click="handleFilterAdvancedStatus(1)" :active="filter.status_active" type="success" size="sm" icon="recieve" platform="ios">
                ATIVO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1 mb-5">
              <base-button-hoverable @click="handleFilterAdvancedStatus(2)" :active="filter.status_commercial" type="primary" size="sm" icon="hourglass" platform="ios">
                COMERCIAL
              </base-button-hoverable>
            </div>
          </template>
          <template slot="bookmarks">
          </template>
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <h6 ><a href="#" class="font-weight-400" style="font-size: 12px">
                <i class="text-primary fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i> PREÇO MÍNIMO: DO MAIS ALTO PARA O MAIS BAIXO</a> </h6>
              <hr class="mb-1 mt-n2">
              <h6 ><a href="#" class="font-weight-400" style="font-size: 12px">
                <i class="text-primary fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i> PREÇO MÍNIMO: DO MAIS BAIXO PARA O MAIS ALTO</a> </h6>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        </multi-filter>
      </div>
      <div class="row card-wrapper" v-show="loading">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <!-- Listagem em cards -->
      <Cards v-show="listType == 'cards'"
        :loading="loading"
        :concrete_prices="concrete_prices"
        :calculateGrossMargin="calcGrossMargin"
        :showParametters="showParametters"
        :handleModalPuzlAdjust="handleModalPuzlAdjust"
      />
      <Table v-show="listType == 'table'"
        :loading="loading"
        :concrete_prices="concrete_prices"
        :calculateGrossMargin="calcGrossMargin"
        :showParametters="showParametters"
        :handleModalPuzlAdjust="handleModalPuzlAdjust"
      />
      <PuzlEmptyData v-if="!loading && !concrete_prices.length"></PuzlEmptyData>
      <ScrollPagination :config="pagination" @nextPage="fetch" />
    </div>
    <ModalPuzlAdjust ref="modalPuzlAdjust" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ScrollPagination from "@/components/ScrollPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import globalFilter from "@/mixins/globalFilter";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlSelect from "@/components/PuzlSelect";
import ModalPuzlAdjust from "./Shared/_ModalPuzlAdjust";
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import {setPreferredViewMode, getPreferredViewMode} from '@/plugins/session';

const filters = {
  global: [],
  order_by: null,
};
export default {
  name: "IndexConcretePrice",
  mixins: [globalFilter],
  components: {
    ScrollPagination,
    SkeletonPuzlGrid,
    MultiFilter,
    PuzlEmptyData,
    BaseButtonHoverable,
    PuzlSelect,
    ModalPuzlAdjust,
    Cards,
    Table
  },
  data() {
    return {
      global: [],
      loading: true,
      active: true,
      once: true,
      itens_per_page: 9,
      listType: 'cards',
      pagination: {
        loading: false,
        has_more: null,
        scrolled_current: false,
        items_length: 0,
      },
      filter: {},
      loadingSlumpFlow: false,
      loadingMaximumSize: false,
      loadingMixTypes: false,
      loadingSkeleton: false,
      loadingCompanyPlants: false,
      selectedCompanyPlants: [],
      filtered: null,
      // conferir se precisa mesmo
      slump_filter: [],
      gravel_filter: [],
      mix_types_filter: [],
    };
  },
  mounted() {
    this.listType = getPreferredViewMode() ?? 'cards';
    this.loadingSlumpFlow = true;
    this.loadingMaximumSize = true;
    this.loadingCompanyPlants = true;
    this.loadingMixTypes = true;
    this.$store.dispatch('mixSlumpFlow/fetchItems').then(() => {
      this.loadingSlumpFlow = false
    });
    this.$store.dispatch('mixMaximumSize/fetchItems').then(() => {
      this.loadingMaximumSize = false
    });
    this.$store.dispatch('plant/fetchItems').then(() => {
      this.loadingCompanyPlants = false;
    });
    this.$store.dispatch('mixType/fetchItems').then(() => {
      this.loadingMixTypes = false;
    });
    this.once = true;
    this.filter = {...filters};
    this.filter.status = null;
    this.filter.order_by = null;
    this.filter.global = [];
    // Variáveis usadas no hoverable button
    this.filter.status_active = false;
    this.filter.status_commercial = false;
    this.init();
  },
  computed: {
    ...mapGetters({
      concrete_prices: "concretePrice/fetch",
      $_slump_flows: 'mixSlumpFlow/activeItems',
      $_maximum_sizes: 'mixMaximumSize/activeItems',
      $_plants: "plant/activeItems",
      $_mix_types: 'mixType/fetch',
    }),
  },
  watch: {
    concrete_prices() {
      this.pagination.items_length = this.concrete_prices.length;
    },
  },
  methods: {
    changeStatus(uuid) {
      let concrete_prices = this.concrete_prices;
      let [concrete] = concrete_prices.filter((item) => item.uuid === uuid);

      if (concrete.status === 1 || concrete.status === 3) {
        concrete.status = 2;
      } else {
        concrete.status = 1;
      }
    },
    setViewType(type){
      this.listType = type;
      setPreferredViewMode(type);
    },
    calcGrossMargin: function(minimumPrice, mcc, tax = 0){
      const price = minimumPrice.toString().replaceAll(".", "").replace(",", ".").replaceAll("R$ ", "")
      const cmc = mcc.toString().replaceAll(".", "").replace(",", ".").replaceAll("R$ ", "")
      if (price > 0) {
        if (Number(tax) > 0) {
          return price - (price * (Number(tax) / 100)) - cmc
        } else {
          return price - cmc
        }
      }
      return 0
    },
    handleModalPuzlAdjust(item)
    {
      this.$refs.modalPuzlAdjust.openModal(item, this.calcGrossMargin(item.price.minimum_price, item.price_parameter['mcc_price'].price));
    },
    showParametters(index){
      this.$el.querySelector('.parametro-' + index).classList.toggle('parametter-disabled');
    },
    handleFilterCompanyPlants(filter) {
      this.filter.selectedCompanyPlants = filter;
      this.selectedCompanyPlants = filter;
      this.init({});
    },
    handleFilterMixTypes(filter) {
      this.filter.mixTypes = filter;
      this.mix_types_filter = filter;
      this.init({});
    },
    handleFilterSlumps(filter) {
      this.filter.slumps = filter;
      this.slump_filter = filter;
      this.init({});
    },
    handleFilterGravels(filter) {
      this.filter.gravels = filter;
      this.gravel_filter = filter;
      this.init({});
    },
    handleFilterAdvancedStatus(filter) {
      if(this.filter.status === filter)
      {
        this.filter.status = null;
        this.filter.status_active = false;
        this.filter.status_commercial = false;
      } else {
        this.filter.status = filter;
        if(filter == 1){
          this.filter.status_active = true;
          this.filter.status_commercial = false;
        } else {
          this.filter.status_active = false;
          this.filter.status_commercial = true;
        }
      }

      this.init({})
    },
    handleGeneralFilterClick() {
      this.fetch(this.filter);
    },
    clearFilter() {
      this.filter = {
        global: [],
        range: this.defaultRange,
        status: null,
        order_by: null,
        status_active: false,
        status_commercial: false,
      };
      this.init({});
    },
    init() {
      this.fetch(this.filter);
    },
    fetch(filters = [], has_pagination = false) {
      this.loadingControl(has_pagination, 0);
      this.$store
        .dispatch("concretePrice/fetchItems", this.getFilters(filters, has_pagination))
        .then((response) => {
          this.pagination.has_more = response.has_more;
          this.loadingControl(has_pagination, 1);
          if (this.once) {
            this.fetch([], true)
            this.once = false
          }
        })
        .catch((error) => {
          this.loadingControl(has_pagination, 1);
        });
    },
    /**
     * Função que irá realizar o controle das variáveis de requisição.
     *
     * @param mixed filters
     * @param boolean has_pagination
     *
     * @return object
     */
    getFilters(filters, has_pagination) {
      return {
        paginate: this.itens_per_page,
        filters: filters,
        has_pagination: has_pagination,
      };
    },

    changeState() {
      this.active = !this.active;
    },

    /**
     * Função que irá realizar o controle dos loadings o qual desejas trabalhar.
     * type 0 indica o início da requisição.
     *
     * @param boolean has_pagination
     * @param int type
     *
     * @return void
     */
    loadingControl(has_pagination, type = 0) {
      if (type === 0) {
        this.loading = has_pagination ? false : true;
        this.pagination.scrolled_current = has_pagination ? true : false;
        this.pagination.loading = has_pagination ? true : false;
        this.$Progress.start();
        return;
      }

      this.loading = false;
      this.pagination.scrolled_current = false;
      this.pagination.loading = false;
      this.$Progress.finish();
    },
  },
};
</script>

<style>
  .new-default-black {
    background-color: #2B2D32 !important;
    background: #2B2D32 !important;
  }
  .new-default-blue-font {
    color: #1A70B7 !important;
  }
  .new-default-black-font {
    color: #2B2D32 !important;
  }
</style>
