<template>
  <modal size="lg" :show.sync="modal" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img src="/img/icons/icons8/ios/menu-squared-2-white.png" height="25" class="mr-2" />
        ADICIONAR CATEGORIAS DE COMPRAS
      </h5>
    </template>
    <div class="p-3">
      <!-- INFORMAÇOES -->
      <div class="d-flex align-items-center">
        <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
        <span style="font-size: 18px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">IDENTIFICAÇÃO</span>
        <div class="gray-divider mb-2" />
      </div>

      <div class="form-group row mt-4 mb-3 mx-1 p-0 d-flex justify-content-between align-items-center">
        <label class="">
          Nova categoria de compras
        </label>
        <div class="col-md-7 pr-0">
          <input type="text" v-model="newPurchaseCategory.description" maxlength="150"
            placeholder="CATEGORIA DE COMPRAS" class="form-control" rows="3" />
        </div>
      </div>
      <div v-if="newPurchaseCategory.description" class="justify-content-end d-flex mb-4">
        <base-button size="sm" type="danger" @click="newPurchaseCategory.description = ''" style="width: 150px;">
          <img src="/img/icons/cancel-white.png" width="20px" height="20px" class="mr-1" />
          Cancelar
        </base-button>
        <base-button size="sm" style="width: 150px;" type="success" @click="add()">
          <img src="/img/icons/save.png" width="20px" height="20px" class="mr-1" />
          Salvar
        </base-button>
      </div>
      <div class="gray-divider mb-3 mt-4" />
      <div class="d-flex align-items-center">
        <img class="mr-1" width="25" height="25" src="/img/icons/icons8/ios/hierarchy_danger.png" alt="info-squared" />
        <span style="font-size: 18px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3 text-nowrap">CATEGORIAS
          CADASTRADOS</span>
        <div class="gray-divider mb-2" />
      </div>
      <div v-for="item in categories" class="category-list-item mt-3 d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <!-- STATUS -->
          <div @click="updateStatus(item)" class="status-button pointer"
            :class="item.status ? 'active-status' : 'disabled-status'">
            <div>
              <img :src="item.status ? '/img/icons/ok--v1.png' : '/img/icons/icons8/ios/ok--v1_danger.png'" class="mr-1"
                width="18">

              {{ item.status ? 'ATIVO' : 'INATIVO' }}
            </div>
          </div>
          <div>{{ item.description }}</div>
        </div>
        <div class="pointer d-flex align-items-center">
          <!-- EDITAR -->
          <base-dropdown ref="dropdown" :closeOnClick="false" menuOnRight style="margin-left: 3.7rem;">
            <div slot="title-container" class="dropdown-toggle rounded green-button px-3">
              <img height="22px" src="/img/icons/create-new.png" />
            </div>
            <div class="p-3" style="min-width: 21rem !important; padding-bottom: 0.6rem !important;">
              <div class="d-flex align-items-center mb-3">
                <img width="26" src="/img/icons/hand-with-pen-primary.png" alt="hand-with-pen" />
                <span class="text-nowrap">Editar categoria</span>
              </div>
              <base-input class="col-12 pr-1 mb-1 input-name-email-signatory" name="Name" v-model="newDescription"
                :placeholder="item.description" :required="true" />
              <div class="col-12 d-flex justify-content-end pr-0">
                <base-button @click="updateDescritpion(item)" type="success" native-type="submit"
                  class="mt-2 align-self-end" size="sm">
                  <img width="22" src="/img/icons/save.png" alt="info-squared" class="mr-1" />
                  Salvar
                </base-button>
              </div>
            </div>
          </base-dropdown>
          <!-- EXCLUIR -->
          <img @click="destroy(item.id)" height="22px" src="/img/icons/icons8/ios/trash_can.png">
        </div>
      </div>
      <div v-if="!categories.length">
        <div class="text-center text-muted mt-3">Nenhuma categoria encontrada</div>
      </div>
    </div>
    <div class="modal-footer">
      <base-button type="danger" @click="closeModal()">
        <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
        Sair
      </base-button>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalPurchaseCategory",
  data() {
    return {
      modal: false,
      cardOpenId: false,
      newPurchaseCategory: {
        description: '',
      },
      newDescription: '',
      categories: [],
      items: [],
    }
  },
  watch: {
    itemType() {
      this.items = [];
    },
  },
  methods: {
    // Modal
    handleCreateModal() {
      this.listCategories();
    },
    closeModal() {
      this.modal = false
    },
    listCategories() {
      let loader = this.$loading.show()
      this.$store.dispatch('purchaseCategory/fetchItemsPaginate', { order_by: [{ column: 'purchase_categories.id' }] })
        .then((response) => {
          this.categories = response.data.items;
          loader.hide()
          this.modal = true;
        }).catch(() => {
          loader.hide()
        });
    },
    add() {
      const existingCategory = this.categories.find(
        category => category.description.toLowerCase() === this.newPurchaseCategory.description.toLowerCase()
      );
      if (existingCategory) {
        this.$notify({ type: 'warning', message: 'Essa categoria já existe!' });
        return;
      }

      let loader = this.$loading.show();
      this.$store.dispatch('purchaseCategory/add', {
        description: this.newPurchaseCategory.description,
        status: 1,
      }).then(() => {
        loader.hide();
        this.listCategories();
        this.$notify({ type: 'success', message: 'Categoria adicionada com sucesso!' });
        this.$emit('fetchCategories');
      });
    },
    updateDescritpion(purchaseCategory) {
      const existingCategory = this.categories.find(
        category => category.description.toLowerCase() === this.newDescription.toLowerCase()
      );

      if (existingCategory) {
        this.$notify({ type: 'warning', message: 'Descrição já existe!' });
        return;
      }

      let loader = this.$loading.show();
      purchaseCategory.description = this.newDescription;
      this.$store.dispatch('purchaseCategory/update', purchaseCategory)
        .then(() => {
          this.listCategories();
          loader.hide();
          this.$notify({ type: 'success', message: 'Descrição atualizada!' });
          this.$emit('fetchCategories');
        })
        .catch(() => {
          loader.hide();
          this.$notify({ type: 'error', message: 'Erro ao atualizar descrição!' });
        });
    },
    updateStatus(purchaseCategory) {
      let loader = this.$loading.show()
      purchaseCategory.status = purchaseCategory.status ? 0 : 1;
      this.$store.dispatch('purchaseCategory/update', purchaseCategory)
        .then(() => {
          this.listCategories();
          loader.hide();
          this.$notify({ type: 'success', message: 'Estatus atualizado!' });
          this.$emit('fetchCategories');
        });
    },
    destroy(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            this.$store.dispatch('purchaseCategory/destroy', id).then(() => {
              this.listCategories();
              loader.hide();
              this.$notify({ type: 'success', message: 'Categoria removida!' });
              this.$emit('fetchCategories');
            })
          }
        }
      })
    },
  },
  beforeCreate() {
    this.listCategories(true);
  }
}
</script>

<style scoped>
.category-list-item {
  box-shadow: 0px 5px 10px 0px #0000000D;
  border: 0.5px solid #E8E8E8;
  border-radius: 5px;
  padding: 8px 12px;
}

.status-button {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;

}

.active-status {
  background-color: #f2f7f3;
  color: #149E57;
}

.disabled-status {
  background-color: #fcf3f3;
  color: #DB4539;
}

.close-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 20%;
  height: 2.3rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  color: #ffff
}

.close-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  height: 1px;
  width: 100%;
}
</style>
