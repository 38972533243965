<template>
  <div>
    <modal :show.sync="modal" size="md" data-class="programming-weighing">
      <template slot="header">
        <h5 class="modal-title new-default-black-font"><img src="/img/icons/icons8/ios/plus--v1_success.png" width="25"
                                                            class="mt-n1">
          {{ title }}
        </h5>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window"
             width="17px">
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div class="form-row">
              <div class="col-md-12">
                <multi-filter
                  ref="multiFilter"
                  @fetch="fetch"
                  :filter="filter"
                  without_filter
                  without_bar_filter
                >
                  <div class="col-md-12 mt-n2 mb-2 new-default-black-font ml-n2 font-weight-500">
                    <img src="/img/icons/icons8/ios/filter.png" height="20"/> Filtros
                  </div>
                  <div class="col-md-4 px-2 ">
                    <label style="font-size: 12px">Tipo </label>
                    <PuzlSelect
                      class="mt-n2"
                      :clearable="true"
                      v-model="filter.type"
                      label="technical_description"
                      :items="$_types"
                      @change="handleSearchSubtype"
                      @input="handleSearchMixDesign"
                      :loading="loadingTypes"
                      :disabled="loadingTypes || loading"/>
                  </div>
                  <div class="col-md-4 px-2">
                    <label style="font-size: 12px">Slump/Flow </label>
                    <base-input input-classes="form-control" class="mt-n2">
                      <PuzlSelect
                        :clearable="true"
                        v-model="filter.slump_flow"
                        :items="slump_flows"
                        label="nomenclature"
                        customKey="nomenclature"
                        @input="handleSearchMixDesign"
                        :loading="loadingSlumpFlow"
                        :disabled="loadingSlumpFlow || loading"/>
                    </base-input>
                  </div>
                  <div class="col-md-4 px-2">
                    <label style="font-size: 12px">Dimensão Máxima </label>
                    <base-input input-classes="form-control" class="mt-n2">
                      <PuzlSelect
                        :clearable="true"
                        v-model="filter.maximum_size"
                        :items="maximum_sizes"
                        label="description"
                        customKey="description"
                        @input="handleSearchMixDesign"
                        :loading="loadingMaximumSize"
                        :disabled="loadingMaximumSize || loading"/>
                    </base-input>
                  </div>
                  <div class="col-md-4 px-2 mt-n3">
                    <label>&nbsp;</label>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @input="handleSearchMixDesignCode()"
                        v-model="filter.mix_design_code"
                        inputmode="numeric"
                        v-mask="'#########'"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          <span style="text-transform: none">TR</span>
                        </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-md-8 px-2 mb-n3 mt-n3">
                    <label>&nbsp;</label>
                    <!--                    <div class="d-flex w-100 mt-n2" style="height: 1.95rem;"><input placeholder="Pesquisar"-->
                    <!--                                                                                    class="form-control"-->
                    <!--                                                                                    style="height: 1.95rem; border-radius: 4px !important; box-shadow: none !important; border: 1px solid rgb(232, 232, 232);">-->
                    <!--                      <img-->
                    <!--                        src="/img/icons/icons8/ios/search.png" height="18" class="default-search zoom pointer"-->
                    <!--                        style="margin-top: 5px;"></div>-->

                    <div class="form-group m-0" style="width: 100%;">
                      <div class="input-group"><input placeholder="Pesquisar"
                                                      @keyup.enter="handleSearchMixDesign"
                                                      v-model="filter.global"
                                                      class="form-control"
                                                      style="height: 1.95rem;border-radius: 4px !important;box-shadow: none !important;border: 1px solid rgb(232, 232, 232);border-right: none;">
                        <div class="input-group-append" style="border-left: none !important;"><span
                          class="input-group-text" style="height: 1.95rem !important;box-shadow: none !important;"><span
                          style="width: 30px !important;"></span>
                          <img
                            @click="handleSearchMixDesign"
                          src="/img/icons/icons8/ios/search.png"
                          class="default-search zoom pointer"
                          style="height: 16px;margin-top: 0px;"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </multi-filter>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="col-md-12 mt-n1 mb-2 new-default-black-font ml-n2 font-weight-500">
                  <img width="20" src="/img/icons/test-tube.png"/> Traços
                </div>
              </div>
            </div>
            <div v-if="loadingSkeleton">
              <div v-for="item in 3">
                <div class="row m-0 p-0">
                  <div class="col-md-9">
                    <skeleton-puzl type="text"></skeleton-puzl>
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-md-9">
                    <skeleton-puzl type="text"></skeleton-puzl>
                  </div>
                </div>
                <div class="row m-0 p-0">
                  <div class="col-md-7">
                    <skeleton-puzl type="text"></skeleton-puzl>
                  </div>
                </div>
                <br>
              </div>
            </div>
            <div v-if="!loadingSkeleton && mix_designs.data && !mix_designs.data.length &&
(filter.plant)">
              <puzl-empty-data
                msg="Não existem traços cadastrados a partir dos parâmetros selecionados, entre em contato com o setor de tecnologia."
              />
            </div>
            <div v-else>
              <div class="row" v-if="!loadingSkeleton">
                <div class="col-lg-12">
                  <ul id="list-group-product" class="list-group list-group-flush table-responsive"
                      style="max-height: 42vh;overflow-y: auto;overflow-x: hidden">
                    <li
                      style="height: 105px !important;border: 1px solid #7574741a !important;border-radius: 8px !important;background: #7574741a;"
                      v-for="(mix_design, indexMixDesign) in mix_designs.data" class="list-group-item px-0 mb-2">
                      <div class="row align-items-center">
                        <div class="col ml-2">
                          <h4 class="m-0 p-0" style="font-size: 1rem;">
                            <div class="row">
                              <div class="col d-flex">
                                <button @click.prevent="copy(`${mix_design.code}`)" type="button"
                                        class="btn base-button fs-11 new-btn-light new-default-black-font btn-light btn-sm"
                                        style="background: #1b52bad1 !important;color: white !important;border: 1px solid #dcd5d5;margin-top: -5px">
                                  {{ mix_design.code }}
                                </button>
                                <div class=" mb-0 mt-0"
                                     v-for="feature in mix_design.features"
                                     :key="feature.id">
                                  <div class="">
                                    <h5 class="mb-0">
                                      {{ feature.short_description }}
                                      {{ format_mixe(feature.period, feature.period_type) }}
                                      {{ feature.item_description }}
                                      {{ feature.letter }}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <span style="font-size: 11px" class="font-weight-400">
                                  {{ mix_design.type.technical_description }}
                                  |
                                  {{ mix_design.subtype && mix_design.subtype.technical_description + " |" }}
                                  {{
                                    mix_design.classification && mix_design.classification.technical_description + " |"
                                  }}
                                  {{ mix_design.slump_flow.nomenclature }}
                                  |
                                  {{ mix_design.maximum_size.description }}
                                </span>
                              </div>
                            </div>
                            <div class="row mt-n1">
                              <div class="col">
                                <img class="mr-1" src="/img/icons/icons8/ios/wheelbarrow_dark.png" height="19px"> <span
                                style="font-size: 11px"
                                v-for="(group, index) in mix_design.content_groups">
                                <span style="font-size: 11px" class="font-weight-400">{{
                                    group.name
                                  }} ({{ group.percentage }})
                                  <template v-if="mix_design.content_groups[index + 1]"> + </template> </span></span>
                              </div>
                            </div>
                          </h4>
                        </div>
                        <div class="col-12 mt-n1">
                          <div class="offset-6 col-6">
                            <div class="card-blue-bottom text-center pointer" style="
    margin-right: -16px;min-width: 130px !important;">
                              <a href="#"
                                 @click.prevent="handleSetMixDesign(mix_design)"
                                 style="height: 32px;display: inline-block;"><h3
                                style="text-transform: none;padding: 3px">
                            <span class="mr-1">
                              <img
                                src="/img/icons/icons8/ios/right-squared.png"
                                width="20px"></span><small
                                class="mr-1 text-white"
                                style="font-size: 12px;">
                                SELECIONAR </small></h3></a>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <pagination v-show="mix_designs.data && mix_designs.data.length" @navegate="navegate"
                        :loading="loadingSkeleton" :source="mix_designs"/>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import SkeletonPuzl from "../../../../../../components/SkeletonPuzl";
import Pagination from '@/components/Utils/Pagination'
import MultiFilter from "@/components/Utils/MultiFilterV2";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import {debounce} from "@/plugins";

export default {
  components: {PuzlEmptyData, MultiFilter, SkeletonPuzl, Pagination, PuzlSelect},
  name: "ModalAddMixe",
  data() {
    return {
      format_mixe: period_types,
      modal: false,
      title: 'Incluir traço',
      loadingSkeleton: false,
      validated: false,
      loadingPlant: true,
      loadingSave: false,
      loadingSlumpFlow: false,
      loadingMaximumSize: false,
      selectedSlumpFlow: null,
      selectedMaximumSize: null,
      loading: false,
      invalid: false,
      loadingTypes: false,
      loadingSubtypes: false,
      loadingClassification: false,
      filter: {
        plant: null,
        global: '',
        mix_design_code: null,
        type: null,
        subtype: null,
        additional_classification: null,
      },
      params: {
        page: 1,
        per_page: 3,
      },
      mixe: null,
    }
  },
  computed: {
    ...mapGetters({
      $_general_settings: "generalSetting/show",
      'plants': 'plant/activeItems',
      'mix_designs': 'formulations/getMixDesigns',
      'slump_flows': 'mixSlumpFlow/activeItems',
      'maximum_sizes': 'mixMaximumSize/activeItems',
      '$_subtypes': 'mixSubtype/fetchByType',
      '$_additional_classifications': 'mixAdditionalClassifications/fetchBySubtype',
      '$_types': 'mixType/activeItems',
      contract_proposal: "contractProposal/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    copy(val) {
      this.$helper.copy(val)
      this.$notify({type: 'success', message: 'Código do traço copiado com sucesso!'})
    },
    navegate(page) {
      this.params.page = page
      this.fetch()
    },
    handleSearchMixDesignCode: debounce(function () {
      this.handleSearchMixDesign()
    }, 600),
    handleSearchSubtype() {
      this.filter.subtype = null
      const type = this.$_types.find(item => item.id === this.filter.type)
      if (type) {
        this.loadingSubtypes = true
        this.$store.dispatch('mixSubtype/fetchItemsByUuidType', type.uuid).then(() => {
          this.loadingSubtypes = false
        })
      }
    },
    handleSearchAdditionalClassification() {
      this.filter.additional_classification = null
      const subtype = this.$_subtypes.find(item => item.id === this.filter.subtype)
      this.$store.dispatch('mixAdditionalClassifications/fetchItemsByUuidSubtype', subtype.uuid)
        .then(() => {
          this.loadingClassification = false
        })
    },
    handleSetMixDesign(mix_design) {
      this.$emit('set_mix_design', mix_design)
      this.modal = false
    },
    handleSearchMixDesign() {
      if (!(this.filter.plant)) {
        return
      }
      this.loadingSkeleton = true
      this.$Progress.start()
      this.loading = true
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.params.page = 1
      this.fetch()
    },
    fetch(filter = {}) {
      this.loading = true
      this.loadingSkeleton = true
      let param_filter = {...this.filter}
      param_filter.global = param_filter.global.split(" ")
      param_filter.proposal = this.contract_proposal.entity ? 0 : 1
      this.params.filter = {...filter, ...param_filter}
      this.$store.dispatch('formulations/fetchMixDesigns', this.params).then(response => {
        this.$Progress.finish()
        this.loading = false
        this.loadingSkeleton = false
        this.$notify({type: response.error_type, message: response.message})
      }).catch(error => {
        this.$Progress.finish()
        this.loading = false
        this.loadingSkeleton = false
        this.loadingSave = false
      })
    },
    openModal(plant) {
      this.loading = true
      this.$store.state.formulations.mix_designs.data = []
      this.filter.slump_flow = null
      this.filter.maximum_size = null
      this.loadingSlumpFlow = true
      this.loadingMaximumSize = true
      this.loadingTypes = true
      this.$store.dispatch('generalSetting/show')
      this.$store.dispatch('plant/fetchItemsActive').then(() => {
        this.filter.plant = plant
        this.$store.dispatch('mixSlumpFlow/fetchItems', {
          filter: {
            company_plant_id: plant,
            exists_in_mix: true
          }
        }).then(() => {
          this.loadingSlumpFlow = false
        })
        this.$store.dispatch('mixMaximumSize/fetchItems', {
          filter: {
            company_plant_id: plant,
            exists_in_mix: true
          }
        }).then(() => {
          this.loadingMaximumSize = false
        })
        this.$store.dispatch('mixType/fetchItems', {
          filter: {
            company_plant_id: plant,
            exists_in_mix: true
          }
        }).then(() => {
          this.loadingTypes = false
        })
        this.handleSearchMixDesign()
        this.modal = true
        this.loadingPlant = false
        this.loading = false
      })
      this.mixe = {
        comapny_plant_ids: null,
      }
    },
  },
};
</script>

<style scoped>
/deep/ div[data-class="programming-weighing"] div.modal-header.pb-1 {
  background: #eef1ec !important;
}

/deep/ div[data-class="programming-weighing"] div.modal-header.pb-1 > button i {
  border: 1px solid #000 !important;
  color: #000;
  padding: 3%;
  font-size: 14px !important;
}
</style>
