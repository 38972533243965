<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div >
        <div class="row table-responsive" style="max-height: 80vh">
          <div class="col-md-12">
            <template v-for="item in history">
              <div class="border-left border-3 p-1 mb-2" :class="`border-${classes[item.status].class}`">
                <h4 :class="`text-${classes[item.status].class}`">
                  {{classes[item.status].name}}
                </h4>
                <div class="row">
                  <div v-if="item.comment !== null" class="col-8 text-left h3 mt-n2 small">
                    {{ item.comment }}
                  </div>
                  <div class="col-8 text-left h3 mt-n2 small">
                    {{ item.user_name ? item.user_name + ' | ' : '' }}{{ item.created_at | parseDate('DD/MM/YYYY') }}
                  </div>
                  <div v-if="item.data.nfse && item.data.nfse.nfse_number" class="col-4 text-left h3 mt-n3 small text-right">
                    <img style="width: 30px; cursor: pointer;" src="/img/icons/nfe_blue.png" @click="openDanfseOnNewTab(item.data.nfse.link_pdf)"/>
                    <small style="font-size: 12px;text-transform: none" class="ml-1 text-dark">
                      N° {{ formatNfse(item.data.nfse) }}
                    </small>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import {base_url_ms} from '@/plugins'

const classes = {
  0: {
    name: 'Pendente',
    class: 'yellow',
  },
  1: {
    name: 'NFS-E Emitida',
    class: 'success',
  },
  2: {
    name: 'NFS-E Autorizada',
    class: 'success',
  },
  3: {
    name: 'NFS-E Negada',
    class: 'danger',
  },
  4: {
    name: 'NFS-E Cancelada',
    class: 'danger',
  },
  5: {
    name: 'Cancelamento de NFS-E Negado',
    class: 'warning',
  },
  6: {
    name: 'Emissão da Fatura',
    class: 'indigo',
  },
  7: {
    name: "Mensagem para o whatsapp enviada",
    class: 'success'
  },
  8: {
    name: "Divergência da fatura constatada",
    class: 'danger'
  },
  9: {
    name: "Emissão da nfse autorizada",
    class: 'success'
  },
  10: {
     name: 'Estorno de fatura',
     class: 'danger',
  },
  11: {
     name: 'NFS-E Importada',
     class: 'success',
  },
  12: {
     name: 'NFS-E Substituída',
     class: 'success',
  },
  13: {
     name: 'NFS-E Cancelada Importada',
     class: 'danger',
  },
}
export default {
  components: {},
  name: "ModalInvoiceHistory",
  data() {
    return {
      modal: false,
      title: 'Histórico de alterações',
      history: null,
      classes: classes,
      base_url_ms: base_url_ms(),
    }
  },
  methods: {
    close() {
      this.modal = false;
    },
    openModal(invoiceId) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("billingInvoice/getInvoiceHistory", invoiceId)
        .then((response) => {
          this.history = response.data;
          this.$notify({type: response.error_type, message: response.message});
          this.modal = true;
        })
        .catch(error => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          this.$Progress.finish();
          loader.hide();
        });
    },
    formatNfse(nfse) {
      // Supondo que $treatedNfseRow seja um objeto em JavaScript
      const nfseNumber = nfse.nfse_number.toString();
      const lastFiveDigits = nfseNumber.slice(-5);
      const formatedNumber = lastFiveDigits.padStart(5, '0');

      // Supondo que $treatedNfseRow.authorization_date seja uma data válida em JavaScript
      const currentDate = new Date(nfse.authorization_date);
      const currentYear = currentDate.getFullYear();
      return `${currentYear}${formatedNumber}`;
    },
    async openDanfseOnNewTab(pathFile) {
      let loader = this.$loading.show();
      const urlFromAmazon = pathFile.includes('s3');
      if (urlFromAmazon) {
        const url = this.base_url_ms + 'download-s3?url=' + pathFile;
        this.$store.dispatch('exports/download', url).then(async response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", 'key.pdf');
          await link.click();
        });
      } else {
        await window.open(pathFile, '_parent');
      }
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
      loader.hide();
    },
  },
};
</script>

<style>
</style>
