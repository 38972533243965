<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter">
        <!--        <div class="col-md-4 p-2">-->
        <!--          <base-button :class="filter.stockLocation && 'active'"-->
        <!--            @click="setFilter()" outline block type="primary">-->
        <!--            <i class="fa-solid fa-boxes-stacked"></i> LOCAL DE ESTOQUE-->
        <!--          </base-button>-->
        <!--        </div>-->
        <div class="col-md-3 p-2 custom-dashboard ">
          <puzl-select
            v-model="selectedCompanyPlants"
            :items="$_plants"
            customKey="id"
            :multiple="true"
            class="select-xl"
            :loading="loadingCompanyPlants"
            :disabled="loadingCompanyPlants"
            @input="handleFilterCompanyPlants"
            placeholder="Central"
          />
        </div>
      </multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!$_stocksBalance.length && !loadingSkeleton"></PuzlEmptyData>
      <div class="row card-wrapper" v-if="$_stocksBalance.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(stock, index) in $_stocksBalance" :key="index">
          <card>
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-8">
                <b class="text-dark"> {{ stock.company_plant_name }}</b>
              </div>
            </div>
            <!-- Card body -->
            <div class="row mt-n2 align-items-center">
              <div class="col-12 ">
                <b>{{ stock.product_name }}</b>
              </div>
              <div class="col-12">
                {{ stock.cmc_category_name }}
              </div>
              <div class="col-12 mt-2">
                <b>{{ stock.supplier_name }}</b>
              </div>
              <div class="col-8">
                {{ stock.origin_name }}
              </div>
              <!--              <div v-if="stock.dosage_location_name && stock.code" class="col-12 text-center mt-3">-->
              <!--                <b class="text-dark">{{ stock.dosage_location_name }} {{ stock.code }}</b>-->
              <!--              </div>-->
              <h2 class="col-12 numeric text-center"
                  :class="stock.total_adjustments > 0 ? 'text-success' : 'text-danger'">
                {{ Math.round(stock.total_adjustments).toLocaleString('pt-BR') }} Kg
              </h2>

              <div class="col-md-12 mt-n2 text-center" style="margin-bottom: -12px">
                <h3 style="text-transform: none">
                  <small>
                    <badge style="font-size: 0.8rem" class="numeric">ESTOQUE:
                      {{ stock.date_stock | parseDate('DD/MM/YYYY H:mm') }}
                    </badge>
                  </small>
                </h3>
              </div>

              <div class="col-12">
                <collapse class="border rounded p-0 mt-3">
                  <collapse-item
                    name="1"
                    class="header-gray card-body-molde"
                    back-ground="border-header-primary-bottom"
                  >
                    <h5 slot="title" class="mb-0">Resumo (Kg)</h5>
                    <div class="row align-items-center pb-0 mb-3">
                      <div class="col-7 mt-2">
                        <i class="fa-lg fas fa-box-open ml-n1 text-primary"></i> Estoque inicial
                      </div>
                      <div class="col-5 numeric text-dark">
                        {{ Math.round(stock.final_stock_kg).toLocaleString('pt-BR') }}
                      </div>
                      <div class="col-7 mt-2">
                        <i class="fa-solid fa-circle-arrow-left text-danger"></i> Entrada
                      </div>
                      <div class="col-5 numeric text-dark">
                        {{ Math.round(stock.total_entry_launch_quantity || 0).toLocaleString('pt-BR') }}

                        <span v-if="stock.has_humidity" class="ml-2"><i data-v-7a87787a="" class="fa-solid fa-droplet text-info"></i> <b
                          class="text-primary">{{stock.average_humidity}} %</b>
                        </span>
                      </div>
                      <div class="col-7 mt-3">
                        <i class="fa-solid fa-screwdriver-wrench text-warning"></i> Ajuste de entrada
                      </div>
                      <div class="col-5 numeric text-dark">
                        {{ Math.round(stock.adjustments_entry).toLocaleString('pt-BR') }}
                      </div>

                      <div class="col-7 mt-3">
                        <i class="fa-solid fa-circle-arrow-right text-success"></i> Saída
                      </div>
                      <div class="col-5 numeric text-dark">
                        {{ Math.round(stock.theorical_individual).toLocaleString('pt-BR') }}
                      </div>

                      <div class="col-7 mt-3">
                        <i class="fa-solid fa-screwdriver-wrench text-primary"></i> Ajuste de saída
                      </div>
                      <div class="col-5 numeric text-dark">
                        {{ Math.round(stock.adjustments_output).toLocaleString('pt-BR') }}
                      </div>
                    </div>
                  </collapse-item>
                </collapse>
              </div>
            </div>
          </card>
        </div>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  components: {
    MultiFilter,
    LoadingPagination,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    PuzlSelect,
  },
  name: "List",
  mixins: [cursorPaginate],
  data() {
    return {
      filter: {
        stockLocation: null,
      },
      selectedCompanyPlants: [],
      loadingSkeleton: false,
      loadingCompanyPlants: true,
    }
  },
  computed: {
    ...mapGetters({
      $_stocksBalance: "stockBalance/fetch",
      $_plants: "plant/activeItems",
    }),
  },
  mounted() {
    this.$store.dispatch('plant/fetchItems').then(() => {
      this.loadingCompanyPlants = false;
    });
    this.fetchItemsPaginate();
  },
  methods: {
    fetchItemsPaginate() {
      this.init({});
    },
    init(filter = null) {
      this.$Progress.start();
      this.filter.range = this.range;
      this.filter.company_plants = this.selectedCompanyPlants;
      this.startCursor(filter);
      this.$store
        .dispatch("stockBalance/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.resolveCursor(response);
          this.$Progress.finish();
        })
        .catch(error => {
          this.resolveCursor();
          this.$Progress.finish();
        });
    },
    setFilter() {
      this.filter.stockLocation = this.filter.stockLocation === null ? 1 : null;
      this.init({});
    },
    handleFilterCompanyPlants(filter) {
      this.selectedCompanyPlants = filter;
      this.init({});
    },
  }
}
</script>

<style scoped>

</style>
