<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/briefcase.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <multi-filter
            ref="contract_filter"
            @fetch="fetch"
            :filter="filter"
            :type="4"
            :showSlotOrderBy="false"
            :status="null"
          />
          <div class="container-fluid">
            <div class="row card-wrapper" v-show="loadingSkeleton">
              <SkeletonPuzlGrid v-for="index in 3" :key="index" />
            </div>
            <PuzlEmptyData v-if="!contract_proposals.length && !loadingSkeleton" />
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(save)"
              autocomplete="off"
            >
              <div class="card-with-box-shadow mb-3"
                v-if="contract_proposals.length && !loadingSkeleton"
                v-for="(contract, indexContract) in contract_proposals"
                :key="indexContract">
                <div class="px-4 py-0 mb-1 mt-3">
                  <div class="row">
                    <div class="col-8">
                      <div class="row mt-1 align-items-center">
                        <div class="col-12" style="display: flex; align-items: center;">
                          <h4 class="m-0 p-0 mr-3" style="font-size: 1rem;">
                            {{ contract.code }}
                          </h4>
                          <div :class="types[contract.status]" style="display: inline-flex;">
                            <h4 class="font-weight-normal m-0 p-0">
                              {{types_description[contract.status]}}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-1 align-items-center">
                        <div class="col-12 new-default-gray-font font-weight-400" style="font-size: 14px">
                          {{ contract.construction.construction_name }}
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row float-right mt-1 align-items-center">
                        <el-popover trigger="click" placement="left">
                          <span class="row" style="width: 200px;">
                            <div class="col-12 mb-2" style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1_success.png" width="22">
                              <h4 class="p-0 m-0">Vendedor</h4>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal" style="font-size: 12px;">{{ contract.seller_name }}</h5>
                            </div>
                          </span>
                          <base-button outline slot="reference" size="sm" type="secundary"
                                  class="p-0 m-0 mr-2 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/user-male-circle--v1_success.png" width="25">
                          </base-button>
                        </el-popover>
                        <el-popover trigger="click" placement="top">
                          <span class="row ml-1" style="width: 140px;">
                            <div class="col-12 mb-2" style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/chemical-plant-v1.png" width="22">
                              <h4 class="p-0 m-0">Central</h4>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal" style="font-size: 12px;">{{ contract.plant.name }}</h5>
                            </div>
                          </span>
                          <base-button outline slot="reference" size="sm" type="secundary"
                                  class="p-0 m-0 mr-2 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/chemical-plant-v1.png" width="25">
                          </base-button>
                        </el-popover>
                        <el-popover trigger="click" placement="left">
                          <span class="row" style="width: 310px;">
                            <div class="col-12 mb-3" style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/contract-job-primary.png" width="22">
                              <h4 class="p-0 m-0">Dados do cliente</h4>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal" style="font-size: 12px;"><b style="font-size: 13px;" class="mr-1">Nome:</b>
                                {{ contract.construction.customer_construction.customer_name}}
                              </h5>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal" style="font-size: 12px;"><b style="font-size: 13px;" class="mr-1">CNPJ/CPF:</b>{{ contract.service_receiver.document || "" }}</h5>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal" style="font-size: 12px;word-break: break-word;"><b style="font-size: 13px;" class="mr-1">Endereço:</b>
                              {{ contract.service_receiver.address.address }}
                              {{ contract.service_receiver.address.number || "" }}
                              {{ contract.service_receiver.address.district || "" }},
                              CEP {{ contract.service_receiver.address.postal_code || ""}},
                              {{ contract.service_receiver.address.city || "" }}
                              - {{ contract.service_receiver.address.state || "" }}
                              </h5>
                            </div>
                          </span>
                          <hr class="mb-3 mt-2">
                          <span class="row" style="width: 310px;">
                            <div class="col-12 mb-3" style="display: flex; align-items: center;">
                              <img class="mr-2" src="/img/icons/icons8/ios/brick-wall_warning.png" width="22">
                              <h4 class="p-0 m-0">Dados da obra</h4>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal" style="font-size: 12px;"><b style="font-size: 13px;" class="mr-1">Nome:</b>{{ contract.construction.construction_name }}</h5>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal" style="font-size: 12px;"><b style="font-size: 13px;" class="mr-1">Volume Previsto:</b>
                                {{ contract.construction.customer_construction.estimated_volume ? contract.construction.customer_construction.estimated_volume : "" }}
                              </h5>
                            </div>
                            <div class="col-12">
                              <h5 class="font-weight-normal" style="font-size: 12px;word-break: break-word;"><b style="font-size: 13px;" class="mr-1">Endereço:</b>
                              {{ contract.construction.default_address.address }}
                              {{ contract.construction.default_address.number || "" }}
                              {{ contract.construction.default_address.district || "" }},
                              CEP {{ contract.construction.default_address.postal_code || "" }},
                              {{ contract.construction.default_address.city || "" }}
                              - {{ contract.construction.default_address.state || "" }}
                              </h5>
                            </div>
                          </span>
                          <base-button outline slot="reference" size="sm" type="secundary"
                                  class="p-0 m-0 mr-2 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/info-circle_warning.png" width="25">
                          </base-button>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-n1">
                    <div class="col-12">
                      <div class="row mt-1 align-items-center">
                        <div class="col-12 new-default-gray-font font-weight-400" style="font-size: 14px">
                          {{ contract.construction.customer_construction.customer_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-n4">
                  <div class="offset-6 col-6">
                    <div class="card-blue-bottom text-center pointer" @click="handleSelect(contract.uuid)">
                      <a style="height: 35px; display: inline-block;"
                        href="#">
                        <h3 style="text-transform: none;padding: 5px 3px 5px 5px;">
                          <span class="mr-1">
                            <img src="/img/icons/icons8/ios/right-squared.png" width="20px">
                          </span>
                          <small class="mr-1 text-white" style="font-size: 12px">
                            CONTINUAR
                          </small>
                        </h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <pagination @navegate="navegate" :source="source"/>
          </div>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilter.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import Pagination from "@/components/Utils/Pagination.vue";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";

export default {
  components: {
    MultiFilter,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    Pagination,
    ContractProposalEnum: ContractProposalEnum,
  },
  name: "ModalSearchContract",
  data() {
    return {
      modal: false,
      title: "Buscar Contrato",
      validated: false,
      loadingSave: false,
      invalid: false,
      loadingSkeleton: false,
      types: ["mini-card-red", "mini-card-green", "mini-card-yellow", "mini-card-yellow", "mini-card-blue"],
      types_description: ["Inativo", "Ativo", "Pendente Revisão", "Pendente Aprovação", "Rascunho", "Concluído"],
      filter: {
        status: null
      },
      contract_proposals: [],
      source: null,
      params: {
        page: 1,
        per_page: 3,
      }
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems"
    })
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    async openModal() {
      this.filter = {
        status: ContractProposalEnum.ACTIVE
      };
      this.params = {
        page: 1,
        per_page: 3,
      }
      this.$refs.contract_filter.clearFilters();
      this.contract_proposals = [];
      this.modal = true;
    },
    fetch(filter = null) {
      if (filter) {
        this.filter = filter;
        this.filter.status = [
          ContractProposalEnum.ACTIVE,
          ContractProposalEnum.PENDING_REVIEW,
          ContractProposalEnum.PENDING_APPROVAL,
        ];
        this.params.page = 1
      }
      this.$Progress.start();
      this.loadingSkeleton = true;
      this.params.filter = this.filter
      this.params.light = 1
      this.$store
        .dispatch("contractProposal/fetchItems", this.params)
        .then(response => {
          this.contract_proposals = response.data;
          this.source = response;
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    navegate(page) {
      this.params.page = page
      this.fetch()
    },
    handleSelect(uuid) {
      this.modal = false;
      this.$router.push({
        path: "/operational/schedule/create",
        name: "operational.schedule.create",
        params: {
          contract_proposal_uuid: uuid
        }
      });
    },
    save() {
    }
  },
};
</script>

<style>
.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 15px !important;
}

.card-blue-bottom {
  border-top-left-radius: 35px;
  background-color: #1b6eba;
  color: white;
  max-width: 190px;
  min-width: 190px;
  float: right;
}

.new-default-gray-font {
  color: #606062;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-gray {
  background-color: #646e7d2e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #5d636a52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-gray h4 {
  color: #66696c;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4, .mini-card-gray h4 {
  font-size: 12px;
}
</style>
