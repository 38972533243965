<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-success">
            <img height="30px" src="/img/icons/client-company.png" class="mr-2"/>
            CONTAS A PAGAR
          </h2>
        </div>
        <div class="col-md-8 mt-n1">
          <div class="row d-flex justify-content-end">
            <!-- Desabilitado temporariamente -->
            <div v-if="false" class="col-md-3 px-1">
              <base-button type="primary" block style="border-radius: 4px !important;"
                @click.prevent="handleCreateBillPay">
                <img height="19px" src="/img/icons/icons8/ios/plus--v1.png" class="mr-1 mt-n1"/> NOVO
              </base-button>
            </div>
            <div class="mb-md-0 mb-2 col-md-3 px-1"
                   v-if="$hasPermission('bill_pay.pay_installment') || $hasPermission('bill_pay.bill_pay.approve_installment')">
              <base-button v-show="listType == 'cards'" @click.prevent="setPaidAllotments()"
                           type="success"
                           block
                           :class="paid_allotment && 'active'"
                           class="button-text"
              >
                <img height="19" src="/img/icons/icons8/ios/choose.png" class="mr-1 mt-n1"/>
                PAGAMENTO EM LOTE
              </base-button>
            </div>
            <base-dropdown class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button
                slot="title-container"
                block
                style="font-size: 0.8rem;"
                class="new-default-black dropdown-toggle text-uppercase"
              >
                <img height="19" src="/img/icons/icons8/ios/graph-report.png" class="mr-1 mt-n1"/>
                Relatórios
              </base-button>
              <div>
                <a class="dropdown-item" href="#" @click.prevent="handleNewBillPayReport">
                  <span class="new-default-black-font text-uppercase">
                    <img height="19" src="/img/icons/icons8/ios/profit-report_primary.png" class="mr-1 mt-n1"/>
                    Contas a pagar
                  </span>
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" href="#" @click.prevent="showModalEntryLaunchExcelReport(false)">
                  <span class="new-default-black-font text-uppercase">
                    <img height="19" src="/img/icons/excel.png" class="mr-1 mt-n1"/>
                    Despesas
                  </span>
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" href="#" @click.prevent="showModalEntryLaunchExcelReport(true)">
                  <span class="new-default-black-font text-uppercase">
                    <img height="19" src="/img/icons/excel.png" class="mr-1 mt-n1"/>
                    Entradas
                  </span>
                </a>
                <!-- desabilitar por hora -->
                <!-- <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" href="#" @click.prevent="handleModalEquipmentApportionmentReport()">
                  <span class="new-default-black-font text-uppercase">
                    <img height="19" src="/img/icons/excel.png" class="mr-1 mt-n1"/>
                    Rateio de equipamento
                  </span>
                </a> -->
                <div class="dropdown-divider p-0 m-0"></div>
                <a class="dropdown-item" href="#" @click.prevent="handleModalResume">
                  <span class="new-default-black-font text-uppercase">
                    <img height="19" src="/img/icons/icons8/ios/assignment-turned-in.png" class="mr-1 mt-n1"/>
                    Resumo
                  </span>
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="mt-4 mb-n5 ml-2">
        <i :class="listType !== 'cards' && 'opacity-25'" @click.prevent="setViewType('cards')"
          class="pointer fa-regular fa-hard-drive fa-2x"></i>
        <i :class="listType !== 'table' && 'opacity-25'" @click.prevent="setViewType('table')"
          class="pointer fa-solid fa-table-list fa-2x ml-1"></i>
      </div>
      <div class="mt-5">
<!--         <multi-filter ref="multi-filter" @clearFilters="clearFilters" @fetch="init" :filter="filter"
                      :status="multi_filter">
          <template slot="beforeMainBarTop">
            <div class="row pl-2">
              <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0 mr-2">
                <span> A filtragem <b> padrão </b> buscará a data de pagamento em parcelas pagas <br>e a data de vencimento para as demais parcelas.</span>
                <base-button outline slot="reference" size="sm" type="secundary"
                             class="p-0 m-0 shadow-none  text-primary">
                  <i style="padding-top: 0.1rem;" class="fas fa-calendar"></i>
                </base-button>
              </el-popover>
              <div class="form-group mb-0 d-flex align-items-center mr-3">
                <small class="font-weight-bold mb-0 mr-2" for="search">Padrão</small>
                <input @change="init({})" v-model="filter_by_due_date" :value="false" style="padding-top: 0.1rem;"
                       type="radio"/>
              </div>
              <div class="form-group mb-0 d-flex align-items-center">
                <small class="font-weight-bold mb-0 mr-2" for="search">Vencimento</small>
                <input @change="init({})" v-model="filter_by_due_date" :value="true" style="padding-top: 0.1rem;"
                       type="radio"/>
              </div>
            </div>
          </template>
          <div class="col-md-3 p-2">
            <input-date-picker :filterable="4" ref="date-picker" @handleFilterDate="handleFilterDate"/>
          </div>
          <div class="col-md-3 p-2 custom-dashboard">
            <el-select
              @change="handleAdvancedBankAccountFilter"
              v-model.lazy="bank_accounts"
              class="select-lg w-100"
              collapse-tags
              placeholder="Contas"
              multiple
              filterable
              :disabled="loadingBankAccounts || loading"
            >
              <el-option
                v-for="bank_account in $_bank_accounts"
                :key="bank_account.id"
                :label="bank_account.name"
                :value="bank_account.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-md-3 p-2">
            <base-button :disabled="loadingSkeleton" :class="filter.advanced === 1 && 'active'"
                         @click="handleFilterAdvancedStatus(1)" outline block type="success"
            ><i class="fas fa-dollar-sign"></i> SALDO DISPONÍVEL
            </base-button>
          </div>
          <div class="col-md-3 p-2">
            <base-button :disabled="loadingSkeleton" :class="filter.advanced === 2 && 'active'"
                         @click="handleFilterAdvancedStatus(2)" outline block type="primary"
            ><i class="fas fa-layer-group"></i> AGRUPADAS
            </base-button>
          </div>
          <div slot="after">
            <div class="row" style="margin-left: -22px">
              <div class="col-md-3 mt-1"
                   v-if="$hasPermission('bill_pay.pay_installment') || $hasPermission('bill_pay.bill_pay.approve_installment')">
                <base-button v-show="listType == 'cards'" @click.prevent="setPaidAllotments()"
                             type="success"
                             outline
                             block
                             :class="paid_allotment && 'active'"
                             class="button-text"
                >
                  <i class="fa-solid fa-check-double"></i> PAGAMENTO EM LOTE
                </base-button>
              </div>
              <div class="col-6"/>
              <div class="col-md-3 pr-2">
                <puzl-select
                  class="custom-v-select"
                  v-model="filter.order_by"
                  :items="order_types"
                  customKey="value"
                  @change="init({})"
                  :loading="loadingSkeleton"
                  :disabled="loadingSkeleton"
                  :clearable="false"
                />
              </div>
            </div>
          </div>
        </multi-filter> -->
        <multi-filter ref="multi-filter"
          :type="5"
          @clearFilter="clearFilter()"
          @handleFilterDate="handleFilterDefault"
          @fetch="init" :filter="filter">
          <template slot="dropdown-instead-input">
            <el-select
                @change="handleAdvancedBankAccountFilter"
                v-model.lazy="bank_accounts"
                class="select-lg w-100"
                collapse-tags
                placeholder="Contas"
                multiple
                filterable
                :disabled="loadingBankAccounts || loading"
              >
                <el-option
                  v-for="bank_account in $_bank_accounts"
                  :key="bank_account.id"
                  :label="bank_account.name"
                  :value="bank_account.id"
                >
                </el-option>
              </el-select>
          </template>
          <template slot="dates">
            <div class="col-md-12 text-left">
              <label class="form-control-label">
                VENCIMENTO
              </label>
              <input-date-picker @handleFilterDate="handleFilterDueDate" visibility="focus"/>
            </div>
            <div class="col-md-12 mt-4 text-left">
              <label class="form-control-label">
               DÉBITO
              </label>
              <input-date-picker @handleFilterDate="handleFilterDefault" visibility="focus"/>
            </div>
          </template>
          <template slot="data">
            <div class="row pr-3 mt-1 pl-3">
             <div class="col-12 text-left">
                <label class="form-control-label">
                  VALOR DO PAGAMENTO
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R$</span>
                      </div>
                      <input v-model="lowerValue" @input="setValueRange" placeholder="de" class="form-control btn-first-child"/>
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R$</span>
                      </div>
                      <input v-model="highValue" @input="setValueRange" placeholder="até" class="form-control btn-first-child"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pr-3 mt-3 pl-3">
             <div class="col-12 text-left">
                <label class="form-control-label">
                  NÚMERO DO DOCUMENTO
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input placeholder="de" v-model="lowerDocument" @input="setDocumentRange" class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                    <input placeholder="até" v-model="highDocument" @input="setDocumentRange" class="form-control btn-first-child" />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template slot="status">
            <div class="col-12">
              <base-button-hoverable @click="filterByStatus(1)"
                :active="verifyIfActive('Pago', filter.status)"
                size="sm" type="success" icon="check-dollar" platform="ios">
                PAGO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable
                :active="verifyIfActive('Aprovado', filter.status)"
                @click="filterByStatus(8)" size="sm" type="primary"  icon="ok--v1" platform="ios">
                APROVADO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable
                :active="verifyIfActive('Pendente', filter.status)"
                @click="filterByStatus(0)" size="sm" type="warning"  icon="hourglass" platform="ios">
                PENDENTE
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable
                :active="verifyIfActive('Atrasado', filter.status)"
                @click="filterByStatus(2)" size="sm" type="danger"  icon="present" platform="ios">
                ATRASADO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable
                :active="verifyIfActive('Cancelado', filter.status)"
                @click="filterByStatus(3)" size="sm" type="danger"  icon="cancel" platform="ios">
                CANCELADO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable
                :active="verifyIfActive('Protestado', filter.status)"
                @click="filterByStatus(6)" size="sm" type="warning"  icon="strike" platform="ios">
                PROTESTADO
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable
                :active="verifyIfActive('Serasa', filter.status)"
                @click="filterByStatus(4)" size="sm" type="primary"  icon="evidence" platform="ios">
                SERASA
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1 mb-1">
              <base-button-hoverable
                :active="verifyIfActive('Jurídico', filter.status)"
                @click="filterByStatus(7)" size="sm" type="primary"  icon="law" platform="ios">
                JURÍDICO
              </base-button-hoverable>
            </div>
          </template>
          <template slot="bookmarks">
           <div class="col-12 mt-1 mb-6">
              <base-button-hoverable :disabled="loadingSkeleton" size="md" type="primary"  icon="bursts" platform="ios"
                :active="filter.advanced === 2"
                @click="handleFilterAdvancedStatus(2)">
                  AGRUPADAS
              </base-button-hoverable>
            </div>
          </template>
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <div class="card" style="margin: -14px; border-radius: 4px !important;">
                <div :class="filter.order_by === 'bill_pay_installments.created.desc' && 'text-primary'"
                    class="pointer px-3 pb-2 pt-3 new-default-black-font font-weight-500" style="font-size: 11px;"
                    @click.prevent="handleFilterOrderBy('bill_pay_installments.created.desc')">
                    <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                    CRIAÇÃO DO CARD: DO MAIS NOVO PARA O MAIS VELHO
                </div>
                <hr class="gray-divider p-0">
                <div :class="filter.order_by === 'bill_pay_installments.created.asc' && 'text-primary'"
                    class="pointer px-3 py-2 new-default-black-font font-weight-500" style="font-size: 11px;"
                    @click.prevent="handleFilterOrderBy('bill_pay_installments.created.asc')">
                    <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                    CRIAÇÃO DO CARD: DO MAIS VELHO PARA O MAIS NOVO
                </div>
                <hr class="gray-divider p-0">
                <div :class="filter.order_by === 'bill_pay_installments.due_date.desc' && 'text-primary'"
                    class="pointer px-3 py-2 new-default-black-font font-weight-500" style="font-size: 11px;"
                    @click.prevent="handleFilterOrderBy('bill_pay_installments.due_date.desc')">
                    <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                    DATA DO VENCIMENTO: DA MAIS NOVA PARA A MAIS VELHA
                </div>
                <hr class="gray-divider p-0">
                <div :class="filter.order_by === 'bill_pay_installments.due_date.asc' && 'text-primary'"
                    class="pointer px-3 py-2 new-default-black-font font-weight-500" style="font-size: 11px;"
                    @click.prevent="handleFilterOrderBy('bill_pay_installments.due_date.asc')">
                    <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                    DATA DO VENCIMENTO: DA MAIS VELHA PARA A MAIS NOVA
                </div>
                <hr class="gray-divider p-0">
                <div :class="filter.order_by === 'bill_pay_installments.total_value.desc' && 'text-primary'"
                    class="pointer px-3 py-2 new-default-black-font font-weight-500" style="font-size: 11px;"
                    @click.prevent="handleFilterOrderBy('bill_pay_installments.total_value.desc')">
                    <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                    VALOR: DO MAIOR PARA O MENOR
                </div>
                <hr class="gray-divider p-0">
                <div :class="filter.order_by === 'bill_pay_installments.total_value.asc' && 'text-primary'"
                    class="pointer px-3 pb-3 pt-2 new-default-black-font font-weight-500" style="font-size: 11px;"
                    @click.prevent="handleFilterOrderBy('bill_pay_installments.total_value.asc')">
                    <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                    VALOR: DO MENOR PARA O MAIOR
                </div>
              </div>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        </multi-filter>
      </div>
      <!-- Dados vazios -->
      <PuzlEmptyData v-if="!bills_items.length && !loadingSkeleton"/>
    </div>
    <Cards v-show="listType == 'cards'"
      :loading="loading"
      :bills_items="bills_items"
      :paid_allotment="paid_allotment"
      :paid_allotments="paid_allotments"
      :loadingSkeleton="loadingSkeleton"
      :getInstallmentStatusIcon="getInstallmentStatusIcon"
      :availableStatuses="availableStatuses"
      :bill_pay_installment_status="bill_pay_installment_status"
      :handleHistory="handleHistory"
      :handleOpenModalPaidAllotments="handleOpenModalPaidAllotments"
      :handleEditBillPay="handleEditBillPay"
      :handleDeleteBillPayInstallment="handleDeleteBillPayInstallment"
      :handleModalShareInstallment="handleModalShareInstallment"
      :handleModalInfo="handleModalInfo"
      :handleAgroup="handleAgroup"
      :removeAgroup="removeAgroup"
      :handleModalListEntriesLaunches="handleModalListEntriesLaunches"
      :handleModalAttachment="handleModalAttachment"
      :getPaymentAuthorizationProof="getPaymentAuthorizationProof"
      :handleEntityAccount="handleEntityAccount"
      :changeInstallmentStatus="changeInstallmentStatus"
      :addSetPaidAllotments="addSetPaidAllotments"
      :getOccurrence="getOccurrence"
      :handleModalBillPayInstallmentAttachment="handleModalBillPayInstallmentAttachment"
    />
    <Table v-show="listType == 'table'"
        :loading="loading"
        :bills_items="bills_items"
        :paid_allotment="paid_allotment"
        :paid_allotments="paid_allotments"
        :loadingSkeleton="loadingSkeleton"
        :getInstallmentStatusIcon="getInstallmentStatusIcon"
        :availableStatuses="availableStatuses"
        :bill_pay_installment_status="bill_pay_installment_status"
        :handleHistory="handleHistory"
        :handleOpenModalPaidAllotments="handleOpenModalPaidAllotments"
        :handleEditBillPay="handleEditBillPay"
        :handleDeleteBillPayInstallment="handleDeleteBillPayInstallment"
        :handleModalShareInstallment="handleModalShareInstallment"
        :handleModalInfo="handleModalInfo"
        :handleAgroup="handleAgroup"
        :removeAgroup="removeAgroup"
        :handleModalListEntriesLaunches="handleModalListEntriesLaunches"
        :handleModalAttachment="handleModalAttachment"
        :getPaymentAuthorizationProof="getPaymentAuthorizationProof"
        :handleEntityAccount="handleEntityAccount"
        :changeInstallmentStatus="changeInstallmentStatus"
        :verifyPaidAllotments="verifyPaidAllotments"
        :selectAll="selectAll"
        :selectedAll="selectedAll"
        :getOccurrence="getOccurrence"
    />
    <div class="scrolling-box bg-white floating-button">
      <div class="card-with-box-shadow"
        v-show="paid_allotment && paid_allotments.length">
        <div class="row">
          <div class="col-6 pl-4 py-2 text-center">
            <h4 class="pl-1 new-default-black-font">Total</h4>
            <h6 class="pl-1 new-default-black-font">{{ totalValueSelected | currency() }}</h6>
          </div>
          <div style="border-left: 1px solid rgb(225, 225, 225);" class="col-6 pr-4 py-2 text-center">
            <h4 class="pr-1 new-default-black-font">Final</h4>
            <h6 class="pr-1 new-default-black-font">{{ finalValueSelected | currency() }}</h6>
          </div>
        </div>
      </div>
      <base-button style="border-radius: 0px !important;"
        :disabled="paid_allotments.length === 0"
        @click.prevent="handleOpenModalPaidAllotments()"
        v-show="paid_allotment"
        class="paid-allotment-float bg-success border-0">
        <span style="border-radius: 0px;" class="btn-label"><i class="fa-solid fa-check-double"></i></span>
        <span style="font-size: 0.75rem">PAGAR</span> <span
        class="badge badge-light text-success">{{ paid_allotments.length }} / {{
          bills_items.length
        }}</span>
      </base-button>
    </div>
    <ModalCreateBillPay ref="modalCreateBillPay"/>
    <ModalEntryLaunchReport ref="modalEntryLaunchReport" />
    <ModalResume ref="modalResume" />
    <ModalEditBillPay ref="modalEditBillPay"/>
    <ModalBillPayDocument ref="modalBillBayDocument"/>
    <ModalBillPayInstallmentHistory ref="modalBillPayInstallmentHistory"/>
    <ModalBillPayAgroup @grouped="init({})" ref="modalBillPayAgroup"/>
    <ModalChangeStatus ref="modalChangeStatus"/>
    <ModalEntityAccount ref="entityAccount"/>
    <modal-paid-allotments @fetch="clearSelecteds()" ref="modalPaidAllotments"/>
    <ModalAttachmentView ref="modalAttachmentView" />
    <ModalInfo ref="modalInfo" />
    <ModalShareInstallment @updated="init({})" ref="modalShareInstallment" />
    <ModalEquipmentApportionmentReport ref="modalEquipmentApportionmentReport" />
    <ModalNewBillPayReport ref="modalNewBillPayReport" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import Cards from "./Shared/_Cards.vue";
import Table from "./Shared/_Table.vue";
import ModalCreateBillPay from "./Shared/_ModalCreate.vue";
import ModalEntryLaunchReport from "./Shared/_ModalEntryLaunchReport";
import ModalEquipmentApportionmentReport from "./Shared/_ModalEquipmentApportionmentReport";
import ModalResume from "./Shared/_ModalResume";
import ModalEditBillPay from "./Shared/_ModalEdit";
import ModalBillPayInstallmentHistory from "./Shared/_ModalHistory";
import ModalBillPayDocument from "./Shared/_ModalDocument";
import ModalBillPayAgroup from "./Shared/_ModalAgroup";
import ModalChangeStatus from "./Shared/_ModalChangeStatus";
import ModalEntityAccount from "@/views/Modules/Configuration/Entity/EntityAccount/Index";
import ModalPaidAllotments from "./Shared/_ModalPaidAllotments";
import ModalInfo from "./Shared/_ModalInfo";
import ModalAttachmentView from "@/views/Modules/Configuration/EntryLaunch/Shared/_ModalAttachmentView";
import ModalShareInstallment from "./Shared/_ModalShareInstallment";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import ModalNewBillPayReport from "./Shared/_ModalNewBillPayReport";

export default {
  name: "IndexBillPay",
  mixins: [cursorPaginate],
  components: {
    PuzlBreadcrumb,
    Cards,
    ModalCreateBillPay,
    SkeletonPuzlGrid,
    ModalEntryLaunchReport,
    ModalResume,
    ModalEditBillPay,
    ModalPaidAllotments,
    ModalBillPayDocument,
    ModalBillPayInstallmentHistory,
    ModalBillPayAgroup,
    ModalChangeStatus,
    ModalEntityAccount,
    ModalAttachmentView,
    ModalInfo,
    ModalShareInstallment,
    MultiFilter,
    PuzlEmptyData,
    InputDatePicker,
    PuzlSelect,
    Table,
    BaseButtonHoverable,
    ModalEquipmentApportionmentReport,
    ModalNewBillPayReport,
  },
  data() {
    return {
      listType: 'cards',
      loadingSkeleton: true,
      loadingInstallment: false,
      loadingBankAccounts: true,
      paid_allotment: false,
      paid_allotments: [],
      lowerValue: null,
      highValue: null,
      lowerDocument: null,
      totalValueSelected: 0,
      finalValueSelected: 0,
      highDocument: null,
      selectedAll: false,
      filter: {
        order_by: "default",
        valueRange: {},
        status: null,
        documentRange: {},
        filter_date_type: 'default',
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      bank_accounts: [],
      bill_pay_status: [
        {text: "Pendente", value: 0},
        {text: "Pago", value: 1},
        {text: "Cancelado", value: 3},
        {text: "Serasa", value: 4},
        {text: "Cartório", value: 5},
        {text: "Protestado", value: 6},
        {text: "Jurídico", value: 7},
        {text: "Aprovado", value: 8},
      ],
      occurrences: [
        {
          label: "Única",
          value: 0,
        },
        {
          label: "Semanal",
          value: 7,
        },
        {
          label: "Mensal",
          value: 30,
        },
        {
          label: "Personalizado",
          value: "8",
        },
      ],
      bill_pay_installment_status: {
        '0': "Pendente",
        '1': 'Pago',
        '2': "Atrasado",
        '3': "Cancelado",
        '4': "Serasa",
        '5': "Cartório",
        '6': "Protestado",
        '7': "Jurídico",
        '8': "Aprovado",
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        1: {
          name: "Pago",
          type: "success",
        },
        0: {
          name: "Pendente",
          type: "warning",
        },
        2: {
          name: "Atrasado",
          type: "danger"
        },
        3: {
          name: "Cancelado",
          type: "danger",
        },
        8: {
          name: "Aprovado",
          type: "approved",
        },
        9: {
          name: "Outros",
          type: "info"
        }
      },
      order_types: [
        { name : 'Padrão', value: 'default'},
        { name : 'A > Z', value: 'entity_name'},
        { name : 'Menor valor', value: 'lower_value'},
        { name : 'Maior valor', value: 'highest_value'}
      ]
    };
  },
  computed: {
    ...mapGetters({
      bills_items: "billPay/fetch",
      $_bank_accounts: "bankAccount/fetch",
    }),
  },
  methods: {
    clearFilter(){
      this.filter = {
        order_by: "default",
        valueRange: {},
        status: null,
        documentRange: {},
        filter_date_type: 'default',
      },
      this.init(this.filter);
    },
    verifyIfActive(status, actualStatus)
    {
      const convertedActualStatus = `${actualStatus}`;
      if(convertedActualStatus) return [status].includes(this.bill_pay_installment_status[convertedActualStatus]);

      return false;
    },
    handleFilterOrderBy(orderBy){
      this.filter.order_by = orderBy;
      this.init(this.filter);
    },
    filterByStatus(filter){
      if(this.filter.status == filter){
        this.filter.status = null;
      } else {
        this.filter.status = filter;
      }
      this.init(this.filter);
    },
    handleCreateBillPay() {
      this.$refs.modalCreateBillPay.openModal();
    },
    setViewType(type){
      this.paid_allotments = [];
      this.paid_allotment = false;
      this.selectedAll = false;
      this.listType = type;
    },

    handleNewBillPayReport() {
      this.$refs.modalNewBillPayReport.openModal();
    },
    /**
     * @param {bool} isGroupedByDocument - Relatório de entradas agrupado por documento do emissor da central
     */
    showModalEntryLaunchExcelReport(isGroupedByDocument = false) {
      this.$refs.modalEntryLaunchReport.openModal(isGroupedByDocument);
    },
    handleModalResume() {
      this.$refs.modalResume.openModal();
    },
    setPaidAllotments() {
      this.paid_allotment = !this.paid_allotment
      if (!this.paid_allotment) {
        this.paid_allotments = []
      }
    },
    selectAll(){
      this.selectedAll = !this.selectedAll;

      if(this.selectedAll){
        this.totalValueSelected = 0;
        this.finalValueSelected = 0;
        this.paid_allotments = [];
      }

      this.bills_items.map((bill_item, index) => {
        if(bill_item.status.status !== 1 && bill_item.status.status !== 3){
          if(this.selectedAll) this.addSetPaidAllotments(bill_item.id, index);
          else this.addSetPaidAllotments(bill_item.id);
        }
      });

      if(!this.selectedAll){
        this.totalValueSelected = 0;
        this.finalValueSelected = 0;
        this.paid_allotments = [];
      }
      this.paid_allotment = this.selectedAll;
    },
    verifyPaidAllotments(id, index = false)
    {
      this.addSetPaidAllotments(id, index);

      if(this.paid_allotments.length > 0) this.paid_allotment = true;
      else this.paid_allotment = false;
    },
    handleOpenModalPaidAllotments() {
      this.$refs.modalPaidAllotments.openModal(this.paid_allotments)
    },
    addSetPaidAllotments(id, index = false) {
      /* Se pagamento já existe no array, será removido */
      if (index === false) {
        index = this.paid_allotments.findIndex(item => item === id);
        const billPay = this.bills_items.find(item => item.id === id);

        this.totalValueSelected -= Number(billPay.total_value);
        this.finalValueSelected -= Number(billPay.final_value);

        this.paid_allotments.splice(index, 1);

        if(!this.paid_allotments.length){
          this.selectedAll = false;
        }
      } else {
        /* Adiciona a array de pagamentos */
        this.totalValueSelected += Number(this.bills_items[index].total_value);
        this.finalValueSelected += Number(this.bills_items[index].final_value);
        this.paid_allotments.push(id)
      }
    },
    clearSelecteds() {
      this.paid_allotments = [];
      this.paid_allotment = false;
      this.totalValueSelected = 0;
      this.finalValueSelected = 0;
      this.init({})
    },
    setValueRange()
    {
      this.filter.valueRange.start = this.lowerValue
      this.filter.valueRange.end = this.highValue;
      this.init(this.filter);
    },
    setDocumentRange()
    {
      this.filter.documentRange.start = this.lowerDocument;
      this.filter.documentRange.end = this.highDocument;
      this.init(this.filter);
    },
    init(filter = null) {
      this.startCursor(filter);
      this.filter.range = this.range;
      if (this.bank_accounts.length) this.filter.bank_accounts = this.bank_accounts;
      else delete this.filter.bank_accounts;
      this.$store
        .dispatch("billPay/fetch", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
        });
    },
    availableStatuses(current_status) {
      if (current_status === 1) {
        return [{text: "Estornar", value: 9}]
      }
      let list_status = this.$helper.cloneObject(this.bill_pay_status)
      if (!this.$hasPermission('bill_pay.approve_installment')) {
        const index = list_status.findIndex((item) => item.value === 8)
        delete list_status[index]
      }
      if (!this.$hasPermission('bill_pay.cancel_installment')) {
        const index = list_status.findIndex((item) => item.value === 3)
        delete list_status[index]
      }
      if (!this.$hasPermission('bill_pay.pay_installment')) {
        const index = list_status.findIndex((item) => item.value === 1)
        delete list_status[index]
      }
      return list_status.filter((item) => item.value !== current_status && !(item.value === 0 && current_status === 2));
    },
    getOccurrence(value) {
      switch (value) {
        case 7:
          return "Semanal";
        case 30:
          return "Mensal";
        default:
          return `${value} dias`;
      }
    },
    changeInstallmentStatus(id, status) {
      if (['Aprovado', 'Pago'].includes(this.bill_pay_installment_status[status])) {
        this.$refs.modalPaidAllotments.openModal([id])
      } else {
        this.$refs.modalChangeStatus.openModal(id, status)
      }
    },
    handleDeleteBillPayInstallment(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store.dispatch("billPay/destroy", id).then((response) => {
            this.$notify({type: response.error_type, message: response.message});
            this.$Progress.finish();
          }).catch(error => {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
          });
        }
      });
    },
    handleEditBillPay(id, index) {
      this.$refs.modalEditBillPay.openModal(id, index);
    },
    handleModalListEntriesLaunches(bill_pay_id) {
      this.$refs.modalBillBayDocument.openModal(bill_pay_id);
    },
    handleFilterDueDate(filter) {
      this.range = filter;
      this.filter.filter_date_type = 'due_date';
      this.init({});
    },
    handleFilterDefault(filter){
      this.range = filter;
      this.filter.filter_date_type = 'default';
      this.init({});
    },
    handleFilterDebit(filter){
      this.range = filter;
      this.filter.filter_date_type = 'debit';
      this.init({});
    },
    handleAdvancedBankAccountFilter() {
      this.init({});
    },
    handleHistory(id) {
      this.$refs.modalBillPayInstallmentHistory.openModal(id);
    },
    handleAgroup(bill_pay_id, entity_id) {
      this.$refs.modalBillPayAgroup.openModal(bill_pay_id, entity_id);
    },
    handleFilterAdvancedStatus(filter) {
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null
      };
      this.filter.advanced = this.filter.advanced === filter ? null : filter;
      this.init({});
    },
    handleEntityAccount(entity_id) {
      this.$refs.entityAccount.handleCreateModal(entity_id);
    },

    /**
     * Abre modal de anexos
     *
     * @param {object} item
     * @returns void
     */
    handleModalAttachment(item) {
      this.$refs.modalAttachmentView.openModal(item);
    },

    /**
     * Abre modal de informações
     *
     * @param {object} item
     * @returns void
     */
     handleModalInfo(item) {
      this.$refs.modalInfo.openModal(item);
    },

    /**
     * Retorna ícone de acordo com status
     *
     * @param {string} status
     * @returns string
     */
    getInstallmentStatusIcon(status) {
      switch(status) {
        case 'Aprovado':
          return 'thumb-up--v1-white.png';
        case 'Atrasado':
          return 'present-white.png';
        case 'Cancelado':
          return 'cancel-white.png';
        case 'Pendente':
          return 'hourglass-white.png';
        default:
          return null;
      }
    },

    /**
     * Retorna autorização de pagamento
     *
     * @param {number} bill_pay_installment_id
     * @returns void
     */
    getPaymentAuthorizationProof(bill_pay_installment_id) {
      const loader = this.$loading.show();
      this.$store.dispatch('billPay/getPaymentAuthorizationProof',
        bill_pay_installment_id
      ).then(response => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", 'authorization.pdf');
        window.open(link, '_blank');
      }).finally(() => {
        loader.hide();
      });
    },

    /**
     * Abre modal de anexo de parcela
     *
     * @param {object} item
     *
     * @returns void
     */
    handleModalBillPayInstallmentAttachment(item) {
      const attachment = {
        name: item.attachment_name,
        url: item.attachment_url,
        type: item.attachment_type,
        ext: item.attachment_ext,
        size: item.attachment_size,
      };
      this.$refs.modalAttachmentView.openModal(JSON.stringify(attachment));
    },

    /**
     * Remove agrupamento
     *
     * @param {integer} id
     *
     * @returns void
     */
    removeAgroup(id) {
      const loader = this.$loading.show();
      this.$store.dispatch("billPay/removeAgroup", id)
        .then((response) => {
          this.$notify({type: response.error_type, message: response.message});
          this.init({});
        })
        .catch(error => {
          this.$notify({
            type: "danger",
            message: error.data.message
        });
      }).finally(() => {
        loader.hide();
      });
    },

    /**
     * Abre modal de divisão de parcela
     *
     * @param {object} item
     * @returns void
     */
     handleModalShareInstallment(item) {
      this.$refs.modalShareInstallment.openModal(item);
    },
    /**
     * Abre modal de relatório para rateio de equipamentos
     *
     * @returns void
     */
     handleModalEquipmentApportionmentReport() {
      this.$refs.modalEquipmentApportionmentReport.openModal();
    },
  },
  mounted() {
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then((response) => {
      this.loadingBankAccounts = false;
    });
    this.init();
  },
};
</script>

<style scoped>
.custom-dashboard .el-select__tags-text {
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.floating-button {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  bottom: 130px;
  right: 38px;
  border-radius: 10px;
  color: #000000;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}

.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
