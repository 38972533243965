/** 
 * Definição das chaves únicas usadas para armazenar o estado.
 * 
 * Cada chave deve ser única em todo o projeto. 
 * Não deve haver duplicação de chaves em nenhum lugar do código.
 */
export const stockLocationStateKeys = Object.freeze({
  ITEM: "STOCK_LOCATION_ITEM",
  LISTED: "STOCK_LOCATION_LISTED",
  IS_LISTING: "STOCK_LOCATION_IS_LISTING",
  IS_FINDING: "STOCK_LOCATION_IS_FINDING",
  IS_SAVING: "STOCK_LOCATION_IS_SAVING",
  IS_REMOVING: "STOCK_LOCATION_IS_REMOVING",
  PAGE_STATES: "STOCK_LOCATION_PAGE_STATE",
});
