<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <template v-if="contract_proposal">
        <div class="checklist-item checklist-item-primary mb-2">
          <div class="checklist-info">
            <h4 class="checklist-title mb-0">
              Obra:
              <span class="small">
                                {{
                  contract_proposal.construction
                  && contract_proposal.construction.construction_name
                }}
                            </span>
            </h4>
            <h4 class="checklist-title mb-0">
              Cliente:
              <span class="small">
                                {{
                  contract_proposal.construction
                  && contract_proposal.construction.customer_construction.customer_name
                }}
                            </span>
            </h4>
            <h4 class="checklist-title mb-0">
              Cód. Contrato:
              <span class="small">{{ contract_proposal.code }}</span>
            </h4>
          </div>
        </div>
      </template>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <div class="row">
          <div class="col-md-12">
            <base-button
              class="mb-1"
              size="sm"
              @click.prevent="filter.view_canceled = +!filter.view_canceled"
              outline
              :class="filter.view_canceled && 'active'"
              type="danger"
            >
              <i class="fa-solid fa-clipboard-check"></i>
              Exibir cancelados
            </base-button>
            <InputDatePicker
              v-if="contract_proposal"
              visibility="focus"
              :default-range="{start: filter.start, end: filter.end}"
              ref="datePicker"
              @handleFilterDate="handleSetRange"
            />
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
              >Cancelar
              </base-button
              >
              <base-button
                type="primary"
                native-type="submit"
                :disabled="invalid"
                @click.prevent="save"
              ><i class="fa-regular fa-eye"></i> Visualizar
              </base-button>
            </div>
          </div>
        </div>
      </validation-observer>
      <modal-search-contract :not_in="[]"
                             @close="closeModal" @setContractProposal="setContractProposal" ref="modalSearchContract"/>
    </modal>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import ModalSearchContract from "../../Billing/Shared/_ModalSearchContract";
import InputDatePicker from "@/components/InputDatePicker";
import { date } from "../../../../../helpers";

export default {
  components: {InputDatePicker, ModalSearchContract},
  name: "ModalReport",
  data() {
    return {
      modal: false,
      title: 'Gerar relatório',
      contract_proposal: null,
      loading: false,
      filter: {
        contract_proposal_id: null,
        view_canceled: 0,
        start_at: null,
        end_at: null,
      },
      defaultRange: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      }
    };
  },
  computed: {
    ...mapGetters({
      contract_proposals: "contractProposal/fetch",
    })
  },
  methods: {
    closeModal() {
      this.contract_proposal = null
      this.modal = false;
    },
    async openModal() {
      await new Promise((resolve, reject) => {
        this.modal = true
        this.filter.start_at = date.make().format(date.FORMAT.FULL_DATE)
        this.filter.end_at = date.make().format(date.FORMAT.FULL_DATE)
        this.filter.view_canceled = 0
        this.loading = true
        this.$Progress.start()
        this.$nextTick(resolve)
      })
    },
    handleSetRange(range) {
      this.filter.start_at = date.make(range.start).format(date.FORMAT.FULL_DATE);
      this.filter.end_at = date.make(range.end).format(date.FORMAT.FULL_DATE);
    },
    setContractProposal(params) {
      this.contract_proposal = params.contract_proposal
      this.filter.start_at = date.make(this.contract_proposal.created_at).format(date.FORMAT.FULL_DATE)
      this.filter.end_at = date.make().format(date.FORMAT.FULL_DATE)
      this.defaultRange.start = new Date(this.contract_proposal.created_at)
      this.defaultRange.end = new Date()
    },
    save() {
      this.filter.contract_proposal_id = this.contract_proposal.id
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      this.$Progress.finish()
      this.loadingSave = true
      this.$store.dispatch('billingInvoice/download',
        {filter: this.filter}).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank')
        this.loadingSave = false
        this.$Progress.finish()
      })
      this.loadingSave = false
      this.$Progress.finish()
    }
  },
};
</script>

<style></style>
