<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-2">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div class="form-group row m-0 p-0 mb-1 mt-3">
              <label
                class="col-md-3 pb-0 pt-1 col-form-label form-control-label my-auto"
              >
                Motivo
                <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
              <div class="col-md-9 pr-4">
                <base-input input-group-classes="input-group-sm">
                  <validation-provider rules="required">
                    <textarea
                      v-model="service.cancellation_reason"
                      type="text"
                      rows="2"
                      class="form-control form-control-sm"
                    >
                    </textarea>
                  </validation-provider>
                </base-input>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :loading="loading"
                :disabled="invalid"
              >
                <span v-show="!loading" class="btn-label"><i class="fas fa-save"></i></span>
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  components: {},
  name: "ModalServiceCancellation",
  data() {
    return {
      modal: false,
      title: "Cancelamento de Serviço",
      loading: false,
      service: {
        uuid: null,
        cancellation_reason: null
      },
      type: null
    };
  },
  computed: {
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
    })
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    async handleCreate(uuid, type = 0) {
      // TODO: Verificar se vale a pena inserir permissão operacional no store de usuário...
      // Checa permissão de cancelamento de serviço
      const loader = this.$loading.show();
      await this.$store.dispatch('operationalPermission/getByUserId', this.$user.id);
      loader.hide();
      if(!this.$_operational_permission || !this.$_operational_permission.service_cancellation) {
       return  this.$notify({
          type: "danger",
          message: "Você não possui permissão para cancelar serviços."
        });
      }

      this.type = type;
      this.service.cancellation_reason = null;
      this.service.uuid = uuid;
      this.modal = true;
    },
    save() {
      this.$Progress.start();
      this.loading = true;
      if (this.type === 0) {
        this.$store
          .dispatch("scheduleService/cancelServiceOrder", this.service)
          .then(response => {
            this.$notify({
              type: response.error_type,
              message: response.message
            });
            this.$Progress.finish();
            this.loading = false;
            this.$emit("updatedService", response.data);
            this.closeModal();
          })
          .catch(error => {
            if (error.status == 200) {
              this.$notify({
                type: "danger",
                message: error.data.message
              });
              this.loading = false;
              this.$Progress.finish();
            }
          });
      } else {
        this.$store
          .dispatch("scheduleService/cancelService", this.service)
          .then(response => {
            this.$notify({
              type: response.error_type,
              message: response.message
            });
            this.closeModal();
            this.$Progress.finish();
            this.$emit("updatedService", response.data);
            this.loading = false;
          });
      }
    }
  },
  created() {},
  mounted() {}
};
</script>

<style></style>
