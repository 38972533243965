import * as types from "./mutation_types";
import { createAxios } from "@/plugins/axios";;

const endPoint = "/administrative/reuse-control";
export default {
  namespaced: true,
  state: {
    items: [],
    canceleds: [],
    widgets: {},
  },
  getters: {
    fetch: (state) => state.items,
    fetchCanceleds: (state) => state.canceleds,
    getWidgets: (state) => state.widgets,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload;
    },
    [types.SET_CANCELEDS](state, payload) {
      state.canceleds = payload;
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return createAxios()
        .patch(`${endPoint}`, params)
        .then(({data}) => {
          commit(types.SET, data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getCanceleds({commit}, params) {
        return createAxios()
          .patch(`${endPoint}/canceleds`, params)
          .then(({data}) => {
            commit(types.SET_CANCELEDS, data)
            return Promise.resolve(data);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
    getWidgets({commit}, params) {
      return createAxios()
        .get(`${endPoint}/widgets`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
