<template>
  <div>
    <AppFixedPageTitle
      title="Operações Financeiras"
      icon="/img/icons/icons8/ios/exchange.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
              name="status"
              icon="/img/icons/icons8/ios/progress-indicator_grey.png"
            >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.in_status"
                :items="status"
                :disableBoxShadow="true"
                placeholder="Status"
                :multiple="true"
                class="select-xl col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
              >
                Conta bancária
              </label>
              <PuzlSelect
                style="width: 100%;"
                :disableBoxShadow="true"
                v-model.lazy="filter.bank_account_id"
                :items="$_bank_accounts"
                placeholder="Contas"
                :multiple="false"
                class="select-xl"
                :loading="loadingBankAccounts"
                :disabled="loadingBankAccounts"
              />
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
              >
                Tipo de operação
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.finance_type_id"
                :items="$_financeTypes"
                :disableBoxShadow="true"
                placeholder="Tipo de operação"
                :multiple="false"
                class="select-xl col-md-12 px-0 new-default-black-font"
                :loading="loadingOperationTypes"
                :disabled="loadingOperationTypes"
              />
            </div>
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-12 new-default-black-font font-weight-400"
              >
                Método de pagamento
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.payment_method_id"
                :items="$_payment_methods"
                :disableBoxShadow="true"
                placeholder="Método de pagamento"
                :multiple="false"
                class="select-xl col-md-12 px-0 new-default-black-font"
                :loading="loadingPaymentMethods"
                :disabled="loadingPaymentMethods"
              />
            </div>
            <div class="row pr-3 mt-1 pl-3">
              <div class="col-12 px-0 text-left">
                <label
                  class="form-control-label fs-12 new-default-black-font font-weight-400"
                >
                  Valor
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                        v-model="filter.range_amount.min"
                        placeholder="de"
                      />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                        v-model="filter.range_amount.max"
                        placeholder="até"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Marcadores"
            icon="/img/icons/icons8/ios/push-pin_gray.png"
          >
            <div class="col-12 px-0 mt-1">
              <BaseButtonHoverable
                :active="filter.in_finance_type_mode.includes(modeEnum.PAYMENT)"
                @click="handleFilterMode(modeEnum.PAYMENT)"
                size="sm"
                type="danger"
                icon="initiate-money-transfer"
                platform="ios"
                style="height: 32px;"
              >
                Pagamento
              </BaseButtonHoverable>
            </div>
            <div class="col-12 px-0 mt-2 mb-1">
              <BaseButtonHoverable
                :active="filter.in_finance_type_mode.includes(modeEnum.RECEIPT)"
                @click="handleFilterMode(modeEnum.RECEIPT)"
                size="sm"
                type="success"
                icon="get-cash"
                platform="ios"
                style="height: 32px;"
              >
                Recebimento
              </BaseButtonHoverable>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click="handleSearchEntity"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <AppPageHeaderActionsButton
            link="/financial/finance-types"
            text="tipos"
            icon="/img/icons/icons8/ios/exchange-dollar.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        placeholder="ORDENAR"
        variant="text-only"
      />
    </AppTabSelect>

    <div class="container-fluid mt-4">
      <Cards
        v-if="listType === 'cards'"
        :finances="$_finance_installments"
        :loadingSkeleton="loadingSkeleton"
        :getStatus="getStatus"
        @handleEditFinance="handleEditFinance"
        @handleDeleteFinance="handleDeleteFinance"
        @handleChangeStatus="handleChangeStatus"
        @handleHistoryOperationsModal="handleHistoryOperationsModal"
      />
      <Table
        v-if="listType === 'table'"
        :finances="$_finance_installments"
        :loadingSkeleton="loadingSkeleton"
        :getStatus="getStatus"
        @handleEditFinance="handleEditFinance"
        @handleDeleteFinance="handleDeleteFinance"
        @handleChangeStatus="handleChangeStatus"
        @handleHistoryOperationsModal="handleHistoryOperationsModal"
      />
      <AppViewTrigger v-if="$_finance_installments.length" @onIntersected="listItems(true)"/>
      <PuzlEmptyData v-if="!$_finance_installments.length && !loadingSkeleton"/>
      <ModalEditFinance @refresh="listItems" ref="modalEditFinance" />
      <ModalConfirmFinance :updateScreen="listItems" ref="modalConfirmFinance" />
      <ModalSearchEntity @selected="storeGuarantorEntity" ref="modalSearchEntity" />
      <ModalCreateFinance @refresh="listItems" ref="modalCreateFinance" />
      <ModalFinancesHistory ref="modalFinancesHistory" />
      <ModalReverseFinanceStatus :updateScreen="listItems" ref="modalReverseFinanceStatus" />
    </div>
  </div>
</template>
<script>
import {
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppViewTrigger,
  AppSelect,
  AppTabSelect,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  TabSelectItemType,
} from "../../../../components/AppGlobal";
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table.vue";
import ModalEditFinance from "./Shared/_ModalEditFinance";
import ModalReverseFinanceStatus from "./Shared/_ModalReverseFinanceStatus";
import ModalConfirmFinance from "./Shared/_ModalConfirmFinance.vue";
import ModalFinancesHistory from "./Shared/_ModalFinancesHistory.vue";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import ModalCreateFinance from "./Shared/_ModalCreateFinance";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import {mapGetters} from "vuex";
import { STATUS_ENUM } from "./Shared/Enums/StatusEnum";
import { STATUS_ACTION } from "./Shared/Enums/StatusAction";
import { FinanceListFilterType, initFinanceListFilterType } from "./types";
import {FinanceInstallmentStatus} from "../../../../enum/FinanceInstallmentStatusEnum";
import { date } from "../../../../helpers";
import store from "../../../../shared/libraries/store";

const MODE_ENUM = Object.freeze({
  PAYMENT: 1,
  RECEIPT: 0,
});

export default {
  name: "Finances",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    Cards,
    Table,
    ModalSearchEntity,
    ModalCreateFinance,
    BaseButtonHoverable,
    ModalEditFinance,
    ModalConfirmFinance,
    ModalReverseFinanceStatus,
    ModalFinancesHistory,
    PuzlSelect,
    AppSearchBar,
    AppTabSelect,
    AppSearchBarFilterSection,
    AppViewTrigger,
    AppSelect,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppPageHeader,
  },
  data() {
    return {
      finances: ["","","",""],
      loadingSkeleton: true,
      loadingBankAccounts: true,
      loadingPaymentMethods: true,
      loadingOperationTypes: true,
      FinanceInstallmentStatus: FinanceInstallmentStatus,
      modeEnum: MODE_ENUM,
      statusEnum: STATUS_ENUM,
      statusAction: STATUS_ACTION,
      searchBarFilter: initSearchBarFilterType(),
      filter: initFinanceListFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      status: [
        {id: FinanceInstallmentStatus.PENDING, name: 'Pendente'},
        {id: FinanceInstallmentStatus.FINISHED, name: 'Recebido/Pago'},
        {id: FinanceInstallmentStatus.CANCELED, name: 'Cancelado'},
      ],
      orderBy: {
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [{
              column: "finance_installments.id",
              is_desc: false,
            }],
          },
          {
            id: 1,
            name: "DO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "finance_installments.expire_at",
              is_desc: true,
            }],
          },
          {
            id: 2,
            name: "DO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "finance_installments.expire_at",
              is_desc: false,
            }],
          },
        ],
        selected: 0,
      },
      listType: 'cards',
      pageStateKey: 'FINANCE_PAGE_STATE',
    }
  },
  computed: {
    ...mapGetters({
      $_finance_installments: "financeInstallments/fetch",
      $_finances: "finances/fetch",
      $_bank_accounts: "bankAccount/fetch",
      $_payment_methods: "paymentMethod/fetch",
      $_financeTypes: "financeTypes/fetch",
      $_short_plants: "plant/activeShortItems",
    }),
    tabSelectItems() {
      return [
        {
          id: null,
          name: 'Todos',
          selected: this.filter.in_status.length === 0,
        },
        {
          id: FinanceInstallmentStatus.PENDING,
          name: 'Pendentes',
          selected: this.filter.in_status.includes(FinanceInstallmentStatus.PENDING),
        },
        {
          id: FinanceInstallmentStatus.FINISHED,
          name: 'Pago/Recebido',
          selected: this.filter.in_status.includes(FinanceInstallmentStatus.FINISHED),
        },
        {
          id: FinanceInstallmentStatus.CANCELED,
          name: 'Cancelado',
          selected: this.filter.in_status.includes(FinanceInstallmentStatus.CANCELED),
        },
      ];
    },
  },
  methods: {
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      const isAlreadyFiltered = this.filter.in_status.length === 1 && this.filter.in_status.includes(item.id);
      if (isAlreadyFiltered) {
        return;
      };
      this.filter.in_status = item.id === null ? [] : [item.id];
      this.listItems();
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
     listItems(isAccumulateItems = false) {
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        return;
      }
      this.prepareFilter();
      this.$store
        .dispatch("financeInstallments/fetchItems", this.filter)
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => this.loadingSkeleton = false);
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.company_plant_id = this.searchBarFilter.company_plant.selected;
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.range_expire_or_discharge_at.start = this.searchBarFilter.range.start;
      this.filter.range_expire_or_discharge_at.end = this.searchBarFilter.range.end;
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
     * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
     */
    clearFilter(isRefreshList = true, withStoreFilters = false) {
      // Inicializa filtros com os valores padrão
      let searchBarFilterValue = this.defaultSearchBarFilter();
      let filterValue = this.defaultFilter();
      let orderByValue = this.orderBy;
      let listTypeValue = this.listType;
      
      // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
      const storeFilters = withStoreFilters ? store.getState(this.pageStateKey) : null;
      if (storeFilters) {
        searchBarFilterValue = storeFilters.searchBarFilter;
        filterValue = storeFilters.filter;
        orderByValue = storeFilters.orderBy;
        listTypeValue = storeFilters.listType;
      }

      // Aplicar os filtros
      Object.assign(this.searchBarFilter, searchBarFilterValue);
      Object.assign(this.filter, filterValue);
      Object.assign(this.orderBy, orderByValue);
      this.listType = listTypeValue;
      
      // Listar itens
      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        company_plant: {
          items: this.$_short_plants,
          selected: this.searchBarFilter.company_plant.selected,
        },
        range: {
          items: [
            // { id: 1, name: "Baixa", selected_name: "Baixa" },
            // { id: 2, name: "Vencimento", selected_name: "Venc." }
          ],
          selected: null,
          start: this.filter.range_expire_or_discharge_at.start ?? this.currentDate,
          end: this.filter.range_expire_or_discharge_at.end ?? this.currentDate,
        },
        custom_search_values: [],
      };
    },
    /**
     * Padrão do filtro principal
     * @returns {FinanceListFilterType}
     */
    defaultFilter() {
      return {
        ...initFinanceListFilterType(),
        range_expire_or_discharge_at: {
          start: this.filter.range_expire_or_discharge_at.start ?? this.currentDate,
          end: this.filter.range_expire_or_discharge_at.end ?? this.currentDate,
        },
      };
    },
    getStatus(status, financeTypeMode){
      switch(status){
        case this.statusEnum.PENDING:
          return {
            text: "Pendente",
            dropdown: "/img/icons/expand-arrow--v2_warning.png",
            icon: "/img/icons/icons8/ios/hourglass_warning.png"
          };
        case this.statusEnum.FINISHED:
          return financeTypeMode ? {
            text: "Pago",
            dropdown: "/img/icons/expand-arrow--v2_success.png",
            icon: "/img/icons/icons8/ios/check-dollar_success.png"
          } : {
            text: "Recebido",
            dropdown: "/img/icons/expand-arrow--v2_success.png",
            icon: "/img/icons/icons8/ios/receive-dollar_success.png"
          };
        case this.statusEnum.CANCELED:
          return {
            text: "Cancelado",
            dropdown: "/img/icons/expand-arrow--v2_danger.png",
            icon: "/img/icons/icons8/ios/cancel_danger.png"
          }
        default: return "";
      }
    },
    handleViewTypeChange(type) {
      this.listType = type;
    },
    handleFilterMode(mode){
      this.filter.in_finance_type_mode = this.filter.in_finance_type_mode.includes(mode) ? [] : [mode];
    },
    handleChangeStatus(finance, action) {
      if(action == this.statusAction.REVERT || action == this.statusAction.CANCEL){
        this.$refs.modalReverseFinanceStatus.openModal(finance, action);
      } else {
        this.$refs.modalConfirmFinance.openModal(finance.finance_id, finance.id, action);
      }
    },
    showDeleteConfirmation(id){
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$store.dispatch("finances/destroy", id)
            .then(() => {
              this.listItems();
            }).catch(error => {
              if(error.response){
                const errors = error && error.response && error.status === 422
                    ? formatErrorValidation(error.response.data.data)
                    : error.response.data.message;
                this.$notify({ type: "danger", message: errors });
              }
            });
            this.$Progress.finish();
            this.listItems();
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleDeleteFinance(id){
      this.$store.dispatch("financeInstallments/getHistory", id)
      .then(response => {
        const financeHistory = response.data;

        if(financeHistory.length > 1) {
          this.$notify({
            type: 'danger',
            message: `Não é possível excluir registro com mais de uma movimentação.`
          });
        } else {
          this.showDeleteConfirmation(id);
        }
      });
      this.$Progress.finish();
    },
    handleHistoryOperationsModal(item){
      this.$store.dispatch("financeInstallments/getHistory", item.finance_id)
      .then(response => {
        this.$refs.modalFinancesHistory.openModal(response.data, item);
      });
      this.$Progress.finish();
    },
    handleSearchEntity() {
      this.$refs.modalSearchEntity.openModal(null, false);
    },
    storeGuarantorEntity(entity){
      this.$refs.modalCreateFinance.openModal(entity);
    },
    handleEditFinance(id){
      this.$refs.modalEditFinance.openModal(id);
    },
  },
  async mounted() {
    if (!this.$_short_plants.length) {
      await this.$store.dispatch("plant/fetchItemsActive");
    }
    this.$store
      .dispatch("bankAccount/fetchItems", {
        filter: { status: true }
      })
      .then(() => this.loadingBankAccounts = false);

    this.$store
      .dispatch("paymentMethod/fetchItems")
      .then(() => this.loadingPaymentMethods = false);

    this.$store
      .dispatch("financeTypes/fetchItems", {
        order_by: [{ column: "finance_types.id" }],
      })
      .then(() => this.loadingOperationTypes = false)
      .catch(() => this.loadingOperationTypes = false);

    this.clearFilter(true, true);
  },
  destroyed() {
    /** Salvar estado da página */
    this.searchBarFilter.custom_search_values = [];
    store.commit(this.pageStateKey, { 
      filter: this.filter, 
      searchBarFilter: this.searchBarFilter,
      orderBy: this.orderBy,
      listType: this.listType, 
    });
  },
};
</script>

<style scoped>
.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.input-custom-group {
	display: flex;
	align-items: center;
	border-radius: 8px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  height: 40px;
}

.input-custom-group input {
	box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-left: 1px solid #E8E8E8;
  border-left-color: #E8E8E8;
}

.input-custom-group div {
	padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
	display: inline-flex;
	height: 41px;
	background: white;
	align-items: center;
	font-size: 12px;
}

.input-custom-group div {
	color: #606062;
}

.input-custom-group input {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
	outline: none;
}

.order-types-button {
  display: inline-flex;
  margin-right: 20px;
}

.order-types-button h5 {
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 400 !important;
  padding-bottom: 0.25rem;
}

.order-types-button h5 i {
  font-size: 9px;
  margin-left: 7px;
}
</style>
