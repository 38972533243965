<template>
  <div class="row card-wrapper" v-if="requests.length">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in requests" :key="index">
      <div class="card main-card">
       <div class="d-flex justify-content-between">
          <div class="pl-3 pt-3">
            <div class="mb-2">
              <span style="font-size: 12px; font-weight: 400; color: #606062;">{{ item.input_company_plant_name }}</span>
            </div>
            <div class="d-flex">
              <span style="font-size: 18px ;font-weight: 600; color: #2B2D32;">{{ item.code }}</span>
              <div>
                <div class="align-self-center card-status" style="background-color: #fdf7e7; color: #f5c865;">
                  Pendente
                </div>
              </div>
            </div>
          </div>
          <div class="pr-3 pt-3 position-relative">
            <div style="margin-bottom: 2rem !important;">
              <base-dropdown menuOnRight class="d-flex justify-content-center" style="height: fit-content">
                <div slot="title-container" class="dropdown-toggle rounded">
                  <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
                </div>
                <div>
                  <a  @click.prevent="$router.push('/purchase/request/edit/'+item.id)" class="dropdown-item d-flex" style="font-size: 12px;">
                    <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new" />
                  Editar
                  </a>
                </div>
              </base-dropdown>
              <!-- ICONES -->
              <div style="bottom: 0; right: 0; position: absolute;" class="mb-1 mr-3 d-flex pointer">
                <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                  <img src="/img/icons/paperclip-green.png" width="22">
                </div>
                <div class="mr-2">
                  <el-popover trigger="click" placement="bottom" class="p-0">
                    <span class="font-weight-500">Solicitante:</span>
                    <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                    <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                    <span slot="reference">
                      <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                    </span>
                  </el-popover>
                </div>
                <div class="mr-2">
                  <el-popover trigger="click" placement="bottom" class="p-0">
                    <div class="p-0" style="max-width: 14rem;">
                      <span style="font-size: 14px; font-weight: 500;">Observações</span>
                      <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8"/>
                      <div class="d-block mt-2">
                        <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                        <div style="font-size: 12px;" class="mt-1">{{ item.note }}</div>
                      </div>
                      <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8"/>
                      <div class="d-block mt-2">
                        <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                        <div style="font-size: 12px;" class="mt-1">{{ item.purchase_justification }}</div>
                      </div>
                    </div>
                    <span slot="reference">
                      <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22"/>
                    </span>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
       </div>
       <div class="dropdown-divider mx-3" />
       <div class="d-flex justify-content-between">
        <div class="pl-3 pb-3">
          <div>
              <span style="font-size: 14px ;font-weight: 400; color: #606062;">{{ item.purchase_category_description }}</span>
            </div>
            <div>
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">PRAZO: {{ item.deadline | parseDate("DD MMM YYYY") }}</span>
            </div>
        </div>
        <!-- Botao footer -->
        <div class="footer-button"
          @click="redirectToCreate(item.purchase_request_id)"
          :style="item.has_active_entity_budget ? 'background-color: #1a70b7' : ''"
          >
          <img height="18px" src="/img/icons/cart.png" class="ml-4 mr-2"/>
          <span style="font-size: 13px ;font-weight: 500;" class="mr-4">
            {{ item.has_active_entity_budget ? 'Em cotação' : 'Cotar' }}
          </span>
        </div>
       </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "CardsViewConcretePrice",
  props: {
    requests: Array,
  },
  data() {
    return {
    }
  },
  methods: {
    redirectToCreate(id) {
      this.$router.push('/purchase/budget/create/' + id)
    },
  }
};
</script>

<style scoped>
  .main-card {
    border-radius: 10px;
    border: 1px solid #E8E8E8;
    box-shadow: none !important;
  }
  .card {
    border-radius: 10px !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
    margin-bottom: 1.6rem !important;
  }
  .card-status {
    border-radius: 5px;
    font-size: 0.8rem;
    text-align: center;
    font-weight: 500 !important;
    padding: 0.2rem 0.6rem;
    margin-left: 0.7rem;
    margin-top: 0.1rem;
  }
  .footer-button {
    background-color: #F2B532D9;
    border-radius: 30px 0 10px 0;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: end;
    align-self: end;
    color: #ffffff;
    cursor: pointer;
  }
</style>
