<template>
  <div>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable
        :length="$_concretes.length"
        :headers="headers"
        :loading="loadingSkeleton"
      >
        <template v-for="(item, index) in concretesWithAccumulated" :slot="index">
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ index + 1 }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.finished_at | parseDate("HH:mm") }}
              <a
                href="#"
                @click.prevent="handleTravelCycle(item)"
              >
                <i class="fa fa-clock text-success"></i>
              </a>
            </h5>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="ml-3">
                <span 
                  class="new-default-black-font font-weight-normal m-0 mt-1"
                  :style="`color: ${item.status === ScheduleTravelEnum.CANCELED ? 'red' : 'black'} !important`"
                >
                  {{ item.sequencial_number_plant_number }}
                </span>
                <a
                  href="#"
                  @click.prevent="$emit('downloadProof', item.uuid)"
                >
                  <img width="28px" src="/img/icons/os.png" />
                </a>
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <div
              @click.prevent="
                $emit(
                  'downloadDanfe',
                  item.xml_travel_status,
                  item.xml_travel_key,
                  item.uuid,
                )
              "
              class="text-center"
              v-if="item.xml_travel_id"
            >
              <h5>
                <span class="float-left font-weight-400 text-dark" style="font-size: 12.5px">
                  {{ item.xml_travel_number }}
                </span>
                <span class="ml-2">
                  <img
                    style="cursor: pointer"
                    v-if="item.status !== ScheduleTravelEnum.CANCELED"
                    width="19px"
                    src="/img/nfe.png"
                  />
                  <img
                    style="cursor: pointer"
                    v-if="
                      item.status === ScheduleTravelEnum.CANCELED &&
                      item.xml_travel_status !== XmlTravelEnum.NFE_IN_CANCELLATION_PROCESS
                    "
                    width="19px"
                    src="/img/nfe%20cancelada%20icon.svg"
                  />
                  <img
                    style="cursor: pointer"
                    v-if="
                      item.status === ScheduleTravelEnum.CANCELED &&
                      item.xml_travel_status === XmlTravelEnum.NFE_IN_CANCELLATION_PROCESS
                    "
                    width="19px"
                    src="/img/nfe_in_cancelation_progress.png"
                  />
                </span>
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <span
                :title="item.entity_name"
                class="new-default-black-font font-weight-normal m-0 mt-1"
              >
                {{ truncateString(item.entity_name, 20) }}
              </span>
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5
              :title="item.construction_name"
              class="new-default-black-font font-weight-normal m-0 mt-1"
            >
              <span
                class="btn btn-sm btn-primary p-1 mr-2"
                @click.prevent="$emit('copyContractProposal', item.contract_proposal_code)"
              >
                {{ item.contract_proposal_code }}
              </span>
              {{ truncateString(item.construction_name, 15) }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.contract_proposal_formulation_title }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.equipment_code }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.schedule_travel_volume }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center">
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.accumulated }}
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
    <ModalTravelCycle @updatedCycle="$parent.init({})" ref="travelCycle" />
  </div>
</template>

<script>
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { mapGetters } from "vuex";
import { floatToHuman, truncateString } from "@/helpers";
import { ScheduleTravelEnum } from "@/enum/ScheduleTravelEnum";
import { XmlTravelEnum } from "@/enum/XmlTravelEnum";
import ModalTravelCycle from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";

export default {
  name: "TableConcretesResume",
  components: {
    ModalTravelCycle,
    SkeletonPuzl,
    ShadedTable,
    ShadedCol
  },
  data() {
    return {
      floatToHuman: floatToHuman,
      truncateString: truncateString,
      ScheduleTravelEnum: ScheduleTravelEnum,
      XmlTravelEnum: XmlTravelEnum,
      headers: [
        "N",
        "Emissão",
        "O.S.",
        "NFE",
        "Cliente",
        "Obra",
        "Traço",
        "Equipamento",
        "Volume",
        "Acumulado",
      ],
    };
  },
  props: {
    loadingSkeleton: Boolean,
    activateGroupingByPlant: Boolean,
    hasDriver: Boolean,
  },
  computed: {
    ...mapGetters({
      $_concretes: "chargeResume/fetch",
    }),
    /**
     * Adiciona coluna 'acumulado' aos dados existentes.
     */
    concretesWithAccumulated() {
      let accumulated = 0;
      return this.$_concretes.map(item => {
        accumulated += parseFloat(item.schedule_travel_volume); // Atualiza o valor acumulado.
        return { ...item, accumulated: accumulated.toFixed(1) }; // Retorna o novo objeto.
      });
    },
  },
  methods: {
    handleTravelCycle(schedule_travel) {
      this.$refs.travelCycle.openModal(
        schedule_travel.uuid,
        schedule_travel.address_construction_uuid
      );
    },
  }
};
</script>
