<template>
  <div>
    <div>
      <base-header class="bg-gray-content">
        <div class="row align-items-center py-4">
          <div class="col-lg-12 col-12">
            <h6 class="h2 text-gray d-inline-block mb-0">
              {{ this.$route.meta.breadcrumbs[1].title }}
            </h6>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#"><i :class="this.$route.meta.icon"></i></a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ this.$route.meta.breadcrumbs[1].title }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </base-header>
    </div>
    <div class="container-fluid">
      <multi-filter :divider="true" :without_filter="true" @fetch="init" :filter="{}">
        <filter-dropdown class="nav-item" tag="li" title-classes="nav-link" title-tag="a"
                         menu-classes="py-0 overflow-hidden">
          <!--                  <div class="px-3 py-3">-->
          <!--                    <h6 class="text-sm text-muted"><i class="fa-solid fa-filter"></i> Filtros avançados</h6>-->
          <!--                    <hr/>-->
          <!--                    <div class="row">-->
          <!--                      <div class="col-md-12 mb-1">-->
          <!--                        <label>Ponto de carga</label>-->
          <!--                        <base-input input-classes="form-control-sm">-->
          <!--                          <el-select size="mini" filterable placeholder="Selecione">-->
          <!--                            <el-option-->
          <!--                              v-for="charge_point in $_charge_points"-->
          <!--                              :key="'A' + charge_point.id"-->
          <!--                              :label="charge_point.name"-->
          <!--                              :value="charge_point.uuid"-->
          <!--                            >-->
          <!--                            </el-option>-->
          <!--                          </el-select>-->
          <!--                        </base-input>-->
          <!--                      </div>-->
          <!--                      <div class="col-md-6 mb-1">-->
          <!--                        <label>Cliente</label>-->
          <!--                        <base-input input-classes="form-control-sm">-->
          <!--                          <el-select size="mini" filterable placeholder="Selecione">-->
          <!--                            <el-option v-for="item in $_customer_constructions" :key="item.id" :label="item.customer_name"-->
          <!--                                       :value="item.id">-->
          <!--                            </el-option>-->
          <!--                          </el-select>-->
          <!--                        </base-input>-->
          <!--                      </div>-->
          <!--                      <div class="col-md-6 mb-1">-->
          <!--                        <label>Obra</label>-->
          <!--                        <base-input input-classes="form-control-sm">-->
          <!--                          <el-select size="mini" filterable placeholder="Selecione">-->
          <!--                            <el-option-->
          <!--                              v-for="charge_point in $_charge_points"-->
          <!--                              :key="'A' + charge_point.id"-->
          <!--                              :label="charge_point.name"-->
          <!--                              :value="charge_point.uuid"-->
          <!--                            >-->
          <!--                            </el-option>-->
          <!--                          </el-select>-->
          <!--                        </base-input>-->
          <!--                      </div>-->
          <!--                      <label class="col-md-12 pb-0 text-center col-form-label form-control-label"> Preparo de base </label>-->
          <!--                      <br/>-->
          <!--                      <div class="col-md-6 mb-1 text-center">-->
          <!--                        <label>Base inferior</label>-->
          <!--                        <base-input input-classes="form-control-sm">-->
          <!--                          <el-select size="mini" multiple filterable placeholder="Selecione">-->
          <!--                            <el-option v-for="base in $_base_preparations" :key="base.id" :label="base.description"-->
          <!--                                       :value="base.id"></el-option>-->
          <!--                          </el-select>-->
          <!--                        </base-input>-->
          <!--                      </div>-->
          <!--                      <div class="col-md-6 mb-1 text-center">-->
          <!--                        <label>Base superior</label>-->
          <!--                        <base-input input-classes="form-control-sm">-->
          <!--                          <el-select size="mini" multiple filterable placeholder="Selecione">-->
          <!--                            <el-option v-for="base in $_base_preparations" :key="base.id" :label="base.description"-->
          <!--                                       :value="base.id"></el-option>-->
          <!--                          </el-select>-->
          <!--                        </base-input>-->
          <!--                      </div>-->
          <!--                      &lt;!&ndash; inserido parar parar os erros, remover depois. &ndash;&gt;-->
          <!--                      <div v-if="molds" class="col-md-6 mb-1">-->
          <!--                        <label>Molde</label>-->
          <!--                        <base-input input-classes="form-control-sm">-->
          <!--                          <el-select size="mini" filterable placeholder="Selecione">-->
          <!--                            <el-option v-for="(health, key) in molds" :key="key" :label="health" :value="key"></el-option>-->
          <!--                          </el-select>-->
          <!--                        </base-input>-->
          <!--                      </div>-->
          <!--                      <div class="col-md-6 mb-1">-->
          <!--                        <label>Cura</label>-->
          <!--                        <base-input input-classes="form-control-sm">-->
          <!--                          <el-select size="mini" filterable placeholder="Selecione">-->
          <!--                            <el-option v-for="(health, key) in healths" :key="key" :label="health" :value="key"></el-option>-->
          <!--                          </el-select>-->
          <!--                        </base-input>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
        </filter-dropdown>
        <div class="col-lg-12">
          <div class="row -m-50">
            <div class="col-md-3 p-2">
              <label class="form-control-label">Data de emissão</label>
              <input-date-picker :defaultRange="filter.range" @handleFilterDate="handleFilterDate" size="sm"/>
            </div>
            <div class="col-3 p-2">
              <base-input label="Ensaio" input-classes="form-control-sm">
                <puzl-select
                  @change="init"
                  v-model="filter.hardened_state_id"
                  :items="$_hardened_states"
                  label="short_description"
                  :loading="loadingFilters"
                  :disabled="loadingFilters"/>
              </base-input>
            </div>
            <div class="col-3 p-2">
              <base-input label="Responsabilidade" input-classes="form-control-sm">
                <puzl-select
                  @input="init"
                  @change="setFeatureSelected()"
                  class="normal-case"
                  v-model="filter.feature_id"
                  :items="listFeature.filter((item) => item.period)"
                  :loading="loadingFilters"
                  :disabled="loadingFilters"/>
              </base-input>
            </div>
            <div class="col-3 p-2">
              <base-input label="Itens" input-classes="form-control-sm">
                <puzl-select
                  @input="init"
                  @change="setFeatureSelected()"
                  class="normal-case"
                  :label="`$description$ ${selectedFeature.measured_unit_name}`"
                  :labelMask="true"
                  multiple
                  v-model="filter.items_ids"
                  :items="selectedFeature ? selectedFeature.itens : []"
                  :loading="!selectedFeature"
                  :disabled="!selectedFeature"/>
              </base-input>
            </div>
            <div class="col-md-3 p-2">
              <base-input label="Central" input-classes="form-control form-control-sm">
                <puzl-select
                  @change="handleAdvancedFilter()"
                  :items="$_plants"
                  v-model="filter.company_plant_uuids"
                  multiple
                  customKey="uuid"
                  :disabled="loadingFilters"
                  :loading="loadingFilters"/>
              </base-input>
            </div>
            <div class="col-md-3 p-2">
              <base-input label="Tipo" input-classes="form-control">
                <puzl-select
                  v-model="filter.type_ids"
                  label="technical_description"
                  multiple
                  :items="$_types"
                  @change="handleAdvancedFilter()"
                  :loading="loadingFilters"
                  :disabled="loadingFilters"/>
              </base-input>
            </div>
            <div class="col-md-3 p-2">
              <base-input label="Slump Flow" input-classes="form-control">
                <puzl-select
                  v-model="filter.slump_flow_ids"
                  label="nomenclature"
                  multiple
                  :items="$_slump_flows"
                  @change="handleAdvancedFilter()"
                  :loading="loadingFilters"
                  :disabled="loadingFilters"/>
              </base-input>
            </div>
            <div class="col-md-3 p-2">
              <base-input label="Dimensão máxima" input-classes="form-control">
                <puzl-select
                  v-model="filter.maximum_size_ids"
                  label="description"
                  multiple
                  :items="$_maximum_sizes"
                  @change="handleAdvancedFilter()"
                  :loading="loadingFilters"
                  :disabled="loadingFilters"/>
              </base-input>
            </div>
            <!--            <div class="col-md-3 p-2">-->
            <!--              <base-input label="Slump" size="sm"/>-->
            <!--            </div>-->
            <!--            <div class="col-md-3 p-2">-->
            <!--              <base-input label="Dimensão máxima" size="sm"/>-->
            <!--            </div>-->
          </div>
        </div>
      </multi-filter>
      <!--      /** cards **/-->
      <div class="row">
        <div class="col-md-4">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Temperatura ambiente</h5>
                  <span v-if="!loading_avg" class="card-stats-title mb-0"
                  >{{
                      $_show.avg_temperature ? parseFloat($_show.avg_temperature).toFixed(1) : 0
                    }} ° C</span
                  >
                  <span v-else class="h3 font-weight-bold mb-0 text-muted">0 ° C</span>
                </div>
                <div class="col-auto">
                  <div
                    class="shadow"
                  >
                    <i class="text-blue fa-2x fa-solid fa-temperature-empty"></i>
                  </div>
                </div>
                <div class="col-12  " style="margin-top: 3rem">
                  <slider
                    @change="getByRange()"
                    :disabled="loading || ambient_temperature[0] == ambient_temperature[1]"
                    :tooltip-formatter="formatter1"
                    :min="ambient_temperature[0]"
                    :max="ambient_temperature[1]"
                    v-model="filter.temperature_range"
                    :use-keyboard="false"
                    :interval="0.1"
                    :tooltip="!loading ? 'always' : 'none'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Ciclo do concreto</h5>
                  <span v-if="!loading_avg" class="card-stats-title mb-0">{{
                      $helper.secondsToTime($_show.avg_performed_travel_cycle * 60)
                    }}</span>
                  <span v-else class="h3 font-weight-bold mb-0 text-muted">0 m</span>
                </div>
                <div class="col-auto">
                  <div
                    class="shadow"
                  >
                    <i class="fa-2x fa-solid fa-route" style="color: mediumseagreen"></i></div>
                </div>
                <div class="col-12  " style="margin-top: 3rem">
                  <slider
                    @change="handleInputConcreteCycle"
                    :data="concrete_cycle_slider_data"
                    v-model="filter.concrete_cycle_range"
                    :disabled="loading || concrete_cycle[0] === concrete_cycle[1]"
                    :use-keyboard="false"
                    :tooltip="!loading ? 'always' : 'none'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Volume carregado</h5>
                  <span v-if="!loading_avg" class="card-stats-title mb-0"
                  >{{ $_show.avg_volume ? parseFloat($_show.avg_volume).toFixed(1) : 0 }} <span style="text-transform: none">m</span><sup>3</sup></span
                  >
                  <span v-else class="h3 font-weight-bold mb-0 text-muted">0 m<sup>3</sup></span>
                </div>
                <div class="col-auto">
                  <div
                    class="shadow"
                  >
                    <i class="fa-2x fa-solid fa-truck-ramp-box" style="color: #00ABE7"></i>
                  </div>
                </div>
                <div class="col-12  " style="margin-top: 3rem">
                  <slider
                    @change="getByRange()"
                    :disabled="loading || charged_volume[0] === charged_volume[1]"
                    :tooltip-formatter="formatter3"
                    :min="charged_volume[0]"
                    :max="charged_volume[1]"
                    v-model="filter.volume_range"
                    :use-keyboard="false"
                    :interval="0.5"
                    :tooltip="!loading ? 'always' : 'none'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <div class="row">
              <div class="col-md-3">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center">
                        <i class="fa-2x fa-solid fa-list-check text-success"></i>
                        <h5 class="card-title text-uppercase text-muted mb-0 text-center">Volume realizado</h5>
                        <span class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                              :class="loading && 'text-muted'"
                        >{{ loading ? 0 : $_show.total_volume.toString().replace('.', ',') || 0 }} <span style="text-transform: none">m</span><sup>3</sup></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center">
                        <i class="fa-2x fa-brands fa-buffer text-primary"></i>
                        <h5 class="card-title text-uppercase text-muted mb-0 text-center">Volume de ensaio</h5>
                        <span class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                              :class="loading && 'text-muted'"
                        >{{ loading ? 0 : $_show.molded_volume.toString().replace('.', ',') }} <span style="text-transform: none">m</span><sup>3</sup></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 ">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center">
                        <i class="fa-2x fa-solid fa-arrow-right-arrow-left" style="color: #8898aa"></i>
                        <h5 class="card-title text-uppercase mb-0 text-center">Séries</h5>
                        <span class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                              :class="loading && 'text-muted'">{{
                            loading ? 0 : $_show.series
                          }}</span>
                      </div>
                      <div class="col text-center">
                        <i class="fa-2x fa-brands fa-perbyte" style="color: firebrick"></i>
                        <h5 class="card-title text-uppercase text-muted mb-0 text-center">Índice / série</h5>
                        <span class="dashboard-text card-stats-title h3 font-weight-bold mb-0 text-center"
                              :class="loading && 'text-muted'"
                        >
                        {{
                            loading ? 0 : $_show.total_volume && $_show.total_volume > 0 && $_show.series > 0 ? (Number($_show.total_volume) / Number($_show.series)).toFixed(1) : 0
                          }}
                        <span style="text-transform: none">m</span><sup>3</sup></span
                        ><b></b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-center">
                        <i class="fa-solid fa-coins fa-2x" style="color: orangered"></i>
                        <h5 class="card-title text-uppercase text-muted mb-0 text-center">Ganho técnico</h5>
                        <span class="dashboard-text h3 font-weight-bold mb-0 text-muted">AINDA NÃO REALIZADO</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Gráficos -->
      <SimpleChart :theme="config.dark_mode"/>
      <!--      CARD ESTATISTICO, REAL, TEÓRICO E MCC-->
      <div class="row" v-if="selectedFeature">
        <!--        real-->
        <div class="col-md-4 ">
          <div class="card card-stats" style="height: 157px">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Real {{ selectedFeature.period_formated }}</h5>
                  <div class="text-center row mt-2">
                    <div class="col-md-12">
                  <span class="card-stats-title mb-0 font-weight-bold" :class="'text-'+ $_real_statistical.class"
                  >
                   {{ $_real_statistical.result }} <span class="card-stats-title font-weight-500">{{
                      selectedFeature.measured_unit_name
                    }}</span> <span class="card-stats-title font-weight-500">(f')</span>
                    <br>
                  </span>
                      <span class="card-stats-title mb-0 font-weight-bold" :class="'text-'+ $_real_statistical.class"
                      >
                   {{ $_real_statistical.diff }} <span class="card-stats-title font-weight-500">{{
                          selectedFeature.measured_unit_name
                        }}</span>
                    <br>
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        teórico-->
        <template v-if="!loadingSkeletonTheoricalStatistical">
          <div class="col-md-4">
            <div class="card card-stats" style="height: 157px">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Teórico
                      {{ selectedFeature.period_formated || '' }}</h5>
                    <div class="text-center row mt-2">
                      <div class="col-md-12">
                  <span v-if="$_theorical_statistical" class="card-stats-title mb-0 font-weight-bold"
                  >
                   {{
                      ($_theorical_statistical.fr && $_theorical_statistical.fr.toFixed(1).toString().replace('.', ',')) || '0,0'
                    }} {{

                    }} <span class="card-stats-title font-weight-500">{{
                      selectedFeature.measured_unit_name
                    }} (fr)</span>
                    <br>
                  </span>
                      </div>
                      <div class="col-md-12">
                    <span class="" style="text-transform: none">
                      z = {{
                        $_theorical_statistical.z.toString().replace('.', ',')
                      }} ({{ $_theorical_statistical.reliability }}%)
                    </span>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-12 text-left">
                    <span class="font-weight-300" style="text-transform: none">
                     {{ Math.round($_theorical_statistical.avg_binder) }} <span style="text-transform: none">kg/m</span><sup>3</sup>
                    </span>
                      </div>
                      <div class="col-md-12 text-left">
                    <span class="font-weight-300" style="text-transform: none">
                     {{
                        $_theorical_statistical.kg_per_unit.toFixed(1).toString().replace('.', ',')
                      }} kg/{{ selectedFeature.measured_unit_name }}
                    </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div style="cursor:pointer;" @click.prevent="handleShowModalCreateStatisticalReference()"
                         class="shadow"
                    >
                      <i class="fa-2x fa-solid fa-sliders" style="color: #1a70b7"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-md-4">
            <div class="card card-stats" style="height: 157px">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="text-center row mt-2">
                      <div class="col-md-12">
                  <span class="card-stats-title mb-0 font-weight-bold"
                  >
          <skeleton-puzl type="text"></skeleton-puzl>
                  </span>
                      </div>
                      <div class="col-md-12">
                    <span class="">
          <skeleton-puzl type="text"></skeleton-puzl>
                    </span>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-8 text-left">
                    <span class="font-weight-300" style="text-transform: none">
          <skeleton-puzl type="text"></skeleton-puzl>
                    </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="col-md-4">
          <div class="card card-stats" style="height: 157px">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">MCC</h5>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <span style="font-family: FreeMono, monospace;font-size: 17px" class="font-weight-600" type="secondary">
                          {{ 10000.0 || 0 | currency() }}
                        <span class="font-weight-400" style="font-size: 0.9rem;text-transform: none">(real)</span>
                        </span>
                    </div>
                    <div class="col-md-12 mt-1">
                      <span style="font-family: FreeMono, monospace;font-size: 17px" class="font-weight-600" type="secondary">
                          {{ 10000.0 || 0 | currency() }}
                         <span class="font-weight-400" style="font-size: 0.9rem;text-transform: none">(teórico)</span>
                        </span>
                    </div>
                    <div class="col-md-12 mt-1">
                      <span style="font-family: FreeMono, monospace;font-size: 17px" class="font-weight-600" type="secondary">
                          {{ 10000.0 || 0 | currency() }}
                        <span class="font-weight-400" style="font-size: 0.9rem;text-transform: none">(previsto)</span>
                        </span>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <div
                    class="shadow text-warning"
                  >
                    <i class="fa-2x fa-solid fa-circle-info"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Gráficos -->
    <BarChart :theme="config.dark_mode"/>
    <modal-create-statistical-reference
      ref="modalCreateStatisticalReference"
      :filter="filter"
      :show="showModalCreateStatisticalReference"
      @close="handleCloseModalCreateStatisticalReference()"/>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import {mapGetters} from "vuex";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import InputDatePicker from "@/components/InputDatePicker";
import FilterDropdown from "@/components/FilterDropdown";
import PieChart from "@/components/Echarts/PieChart";
import Slider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import {debounce} from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import SimpleChart from "./Shared/_SimpleChart.vue";
import BarChart from "./Shared/_BarChart.vue";
import ModalCreateStatisticalReference from './Shared/_ModalCreateStatisticalReference'
import SkeletonPuzl from "@/components/SkeletonPuzl";
import moment from 'moment'

export default {
  name: "Index",
  components: {
    SkeletonPuzl,
    Slider,
    ModalCreateStatisticalReference,
    PieChart,
    InputDatePicker,
    MultiFilter,
    FilterDropdown,
    PuzlSelect,
    SimpleChart,
    BarChart,
  },
  data() {
    return {
      format_mixe: period_types,
      loadingFilters: false,
      showModalCreateStatisticalReference: false,
      loadingSkeletonTheoricalStatistical: false,
      loadingSkeletonRealStatistical: false,
      loading: false,
      filter: {
        hardened_state_id: 1,
        items_ids: [],
        company_plant_uuids: [],
        type_ids: [],
        slump_flow_ids: [],
        maximum_size_ids: [],
        feature_id: null,
        ambient_temperature: null,
        volume_range: null,
        concrete_cycle_range: null,
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          end: new Date(),
        },
      },
      formatter1: "{value} ° C",
      formatter3: "{value} m³",
      loading_avg: false,
      concrete_cycle_slider_data: [],
      charged_volume: [0, 0],
      concrete_cycle: [0, 0],
      selectedFeature: null,
      ambient_temperature: [0, 0],
      healths: ["Total", "Parcial", "Nenhuma"],
      config: {
        dark_mode: false,
      },
      dashboard_data: [],
      molds: false,
    };
  },
  computed: {
    ...mapGetters({
      $_hardened_states: "testHardenedState/fetch",
      $_features: "mixFeature/activeItems",
      $_plants: "plant/fetch",
      $_hardened_state_molds: "",
      $_base_preparations: "basePreparation/fetch",
      $_charge_points: "plantChargePoint/fetch",
      $_customer_constructions: "customerConstruction/fetch",
      $_show: "DashboardTechnology/show",
      $_theorical_statistical: "DashboardTechnology/getTheoricalStatistical",
      $_real_statistical: "DashboardTechnology/getRealStatistical",
      $_standard_deviation: "DashboardTechnology/getStandardDeviation",
      $_average: "DashboardTechnology/average",
      $_types: 'mixType/activeItems',
      $_slump_flows: 'mixSlumpFlow/activeItems',
      $_maximum_sizes: 'mixMaximumSize/fetch',
    }),
    listFeature() {
      return this.$_features.map(feature => {
        feature.period_formated = this.format_mixe(feature.period, feature.period_type)
        feature.measured_unit_name = this.letter_unit(feature.measured_unit.initials)
        feature.name = (feature.period && feature.short_description + ' ' + this.format_mixe(feature.period, feature.period_type)).toUpperCase() + ' ' + this.letter_unit(feature.measured_unit.initials) || feature.short_description
        return feature
      }, this)
    }
  },
  methods: {
    handleCloseModalCreateStatisticalReference() {
      this.showModalCreateStatisticalReference = false
      this.getBarChart()
    },
    setFeatureSelected() {
      this.selectedFeature = this.$_features.find(item => item.id === this.filter.feature_id)
    },
    handleInputConcreteCycle() {
      const start = this.filter.concrete_cycle_range[0]
      const end = this.filter.concrete_cycle_range[1]
      let min_minutes = this.$helper.dateToMinutes(start)
      let max_minutes = this.$helper.dateToMinutes(end)
    },
    getByRange: debounce(function () {
      let loader = this.$loading.show();
      this.$store
        .dispatch("DashboardTechnology/fetch", {
          filter: this.filter,
        })
        .then(() => {
          this.getCharts()
          loader.hide();
        })
        .catch(() => loader.hide());
    }, 200),
    letter_unit(letter) {
      if (letter === "nenhum") {
        return "";
      }
      return letter;
    },
    handleShowModalCreateStatisticalReference() {
      let loader = this.$loading.show()
      this.$store
        .dispatch("DashboardTechnology/getStatisticalReference", {
          filter: this.filter,
        }).then(response => {
        loader.hide()
        const mixFeature = this.$_features.find(item => item.id === this.filter.feature_id)
        const hardenedState = this.$_hardened_states.find(item => item.id === this.filter.hardened_state_id)
        this.$refs.modalCreateStatisticalReference.setHeader({
          hardened_state: hardenedState,
          mix_feature: mixFeature,
          statistical_reference: response.data
        })
        this.showModalCreateStatisticalReference = true
      });
    },
    filters() {
      this.$store.dispatch("basePreparation/fetchItems");
      this.$store.dispatch("plantChargePoint/fetchAll");
      this.$store.dispatch("customerConstruction/fetchItems");
      this.loadingFilters = true;
      Promise.all([
        this.$store.dispatch("mixFeature/fetchItems"),
        this.$store.dispatch('mixSlumpFlow/fetchItems'),
        this.$store.dispatch('mixMaximumSize/fetchItems'),
        this.$store.dispatch("testHardenedState/fetchItems"),
        this.$store.dispatch("plant/fetchItems"),
        this.$store.dispatch('mixType/fetchItems'),
      ])
        .then(() => {
          this.setFeatureSelected()
          this.loadingFilters = false
        })
        .catch(() => (this.loadingFilters = false));
    },
    handleFilterDate(filter) {
      this.filter.range = filter
      this.init()
    },
    handleAdvancedFilter: debounce(function () {
      this.init()
    }, 100),
    getStandardDeviation() {
      this.loadingStandardDeviation = false
      this.$store
        .dispatch("DashboardTechnology/getStandardDeviation", {
          filter: this.filter,
        }).then(() => this.loadingStandardDeviation = false);
    },
    getBarChart() {
      this.$store
        .dispatch("DashboardTechnology/getBarChart", {
          filter: this.filter,
        });
    },
    fetch() {
      this.$store
        .dispatch("DashboardTechnology/fetch", {
          filter: this.filter,
        })
        .then((response) => {
          this.$store.dispatch("DashboardTechnology/getPieChart", {
            dont_have_accordance: this.$_show.dont_have_accordance,
            dont_have_accordance_series: this.$_show.dont_have_accordance_series,
            accordance_series: this.$_show.accordance_series,
            has_accordance: this.$_show.has_accordance,
          });
          this.loading = false;
        });
    },
    setCycle() {
      const cycle = [...this.concrete_cycle];
      let first = cycle[0];
      const data = [first * 60 + 'min']
      while (first < cycle[1] * 60) {
        first += 15;
        data.push(this.$helper.minutesToTime(first));
      }
      this.filter.concrete_cycle_range = [data[0], data[data.length - 1]];
      this.concrete_cycle_slider_data = data;
    },
    getStatisticalCards() {
      this.loadingSkeletonTheoricalStatistical = true
      this.$store.dispatch("DashboardTechnology/getTheoricalStatistical", {
        filter: this.filter,
      }).then(() => {
        this.loadingSkeletonTheoricalStatistical = false
      })
      this.loadingSkeletonRealStatistical = true
      this.$store.dispatch("DashboardTechnology/getRealStatistical", {
        filter: this.filter,
      }).then(() => {
        this.loadingSkeletonRealStatistical = false
      })
    },
    init() {
      this.filter.volume_range = null
      this.filter.concrete_cycle_range = null
      this.filter.temperature_range = null
      this.filter.ambient_temperature = null
      this.loading = true;
      this.fetch();
      this.getStatisticalCards()
      this.getBarChart()
      this.getCharts();
      this.getStandardDeviation()
      this.loading_avg = true;
      this.$store.dispatch("DashboardTechnology/fetchAverage", {
        filter: this.filter,
      }).then(() => {
        this.ambient_temperature = [Number(this.$_average.min_temperature), Number(this.$_average.max_temperature)];
        this.charged_volume = [Number(this.$_average.min_volume), Number(this.$_average.max_volume)];
        this.concrete_cycle = [
          Number(this.$_average.min_performed_travel_cycle / 60),
          Number(this.$_average.max_performed_travel_cycle / 60),
        ];
        this.filter.temperature_range = [...this.ambient_temperature];
        this.filter.volume_range = [...this.charged_volume];
        this.setCycle();
        this.loading_avg = false;
      });
    },
    getCharts() {
      // this.$store.dispatch("DashboardTechnology/getVariationGaugeChart", this.fakeVariationGaugeChartData);
      // this.$store.dispatch("DashboardTechnology/getCoefficientGaugeChart", this.fakeCoefficientGaugeChartData);
      // this.$store.dispatch("DashboardTechnology/getResponsibilityBarChart", this.fakeResponsibilityBarChartData);
      // this.$store.dispatch("DashboardTechnology/getOsBarChart", this.fakeOsBarChartData);
      this.$store.dispatch('DashboardTechnology/fetchFeatureChart', {
        filter: this.filter,
      })
    },
    changeTheme() {
      this.config.dark_mode = !this.config.dark_mode;
    },
  },
  created() {
    // Ensaio : compressão
    // Responsabilidade : Fck 28 dias
    this.filter.range = {
      start: new Date(moment().subtract(60, 'days').format('YYYY-MM-DD')),
      end: new Date(),
    }
    this.filter.feature_id = 1;
    this.filter.hardened_state_id = 1;
    this.filters();
    this.init();
  },
};
</script>

<style>
.card-stats-title {
  /*font-family: FreeMono, monospace !important;*/
  font-size: 1.2rem !important;
  text-transform: none;
}

.-m-50 {
  margin-top: -20px !important;
}

.bg-gradient-orange-dashboard {
  background: linear-gradient(100deg, #f2b532 0, #f27932 100%) !important;
}

.bg-gradient-grey {
  background: linear-gradient(100deg, #4e555b 0, #999999 100%) !important;
}

.bg-gradient-loading {
  background: #8898aa;
}

.dashboard-text {
  text-transform: none;
  /*font-family: FreeMono, monospace;*/
}
</style>
