<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important" class="text-warning">
            <img height="30px" src="/img/icons/chemical-plant.png" class="mr-2 mb-1" />
            Programações
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <router-link :to="{ path: '/technology/cmc/create' }">
                <base-button type="success" @click.prevent="handleSearchContract" block>
                  <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1" />
                  NOVO
                </base-button>
              </router-link>
            </div>
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button type="dark" @click.prevent="$router.push('/operational/schedule/in_course')" block>
                <img height="25" src="/img/icons/icons8/ios/concrete-mixer-white.png" class="mr-1 mt-n1" />
                Entregas
              </base-button>
            </div>
            <base-dropdown class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button block slot="title-container" type="warning" class="dropdown-toggle text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/product-documents.png" class="mr-1 mt-n1" />
                Cadastros
              </base-button>

              <div>
                <router-link :to="{ name: 'Corte de Água', path: '/configuration/general/water' }">
                  <span class="dropdown-item">Corte de Água</span>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link
                  :to="{
                    name: 'Temperatura Ambiente',
                    path: '/configuration/general/temperature',
                  }"
                >
                  <span class="dropdown-item">Temp. Ambiente</span>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link
                  :to="{
                    name: 'Umidade',
                    path: '/configuration/general/moisture',
                  }"
                >
                  <span class="dropdown-item">Umidade</span>
                </router-link>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="mb-md-0 mb-3 mt-4 ml-2">
        <div style="display: flex; align-items: center">
          <i
            :class="listType !== 'cards' && 'opacity-25'"
            
            class="pointer fa-regular fa-hard-drive fa-2x"
          ></i>
          <i
            :class="listType !== 'table' && 'opacity-25'"
            
            class="pointer fa-solid fa-table-list fa-2x ml-1"
          ></i>
<!--                       @click="handleFilterAdvancedStatus('cancelled')"
            :class="{ active: filter.advanced === 'cancelled' }" -->
          <button style="margin: 0;width: 135px; justify-content: center; min-height: 28px; padding: 0; font-size: 11px; display: flex; align-items: center;"
            @click="handleFilterAdvancedStatus(16)"
            :class="filter.status === 16 && 'active'"
            slot="title-container"
            class="ml-2 btn text-uppercase btn-outline-warning colorize-btn-img"
            type="button">
            <img v-bind:src="filter.status == 16 ? '/img/icons/icons8/ios/hourglass.png' : '/img/icons/icons8/ios/hourglass_warning.png'" width="16px" class="mr-1" />
            Pendente
          </button>
          <button style="margin: 0;width: 135px; justify-content: center; min-height: 28px; padding: 0; font-size: 11px; display: flex; align-items: center;"
            @click="handleFilterAdvancedStatus(10)"
            :class="filter.status === 10 && 'active'"
            slot="title-container"
            class="ml-2 btn text-uppercase btn-outline-dark colorize-btn-img"
            type="button">
            <img v-bind:src="filter.status == 10 ? '/img/icons/icons8/ios/circled-right_white.png' : '/img/icons/icons8/ios/circled-right.png'" width="16px" class="mr-1" />
            Em andamento
          </button>
        </div>
      </div>
      <div class="mt-5 mt-md-0">
        <multi-filter
          :filterable_type="3"
          @clearFilter="clearFilter()"
          @handleFilterDate="handleFilterDate"
          :showSlotData="false"
          :showSlotBookmark="false"
          :type="1"
          @fetch="init"
          :filter="filter"
          :status="multi_filter">
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <div class="card" style="margin: -14px; border-radius: 4px !important;">
                <div class="pointer px-3 pb-2 pt-3 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                  DO MAIS NOVO PARA O MAIS VELHO
                </div>  
                <hr class="gray-divider p-0">    
                <div class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                  DO MAIS VELHO PARA O MAIS NOVO
                </div>
                <hr class="gray-divider p-0">    
                <div class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                  VOLUME PREVISTO DO MENOR PARA O MAIOR
                </div>
                <hr class="gray-divider p-0">    
                <div class="pointer px-3 py-2 new-default-black-font font-weight-500"
                  style="font-size: 11px;">
                  <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                  VOLUME PREVISTO DO MAIOR PARA O MENOR
                </div>
              </div>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        </multi-filter>
      </div>
      <!-- Cards aqui -->
      <Cards :schedules="schedules"
        :init="init"
        :updateScreen="updateScreen"
        :loadingSkeleton="loadingSkeleton"
        :handleDeleteProgramming="handleDeleteProgramming"
      />
      <PuzlEmptyData v-if="!schedules.length && !loadingSkeleton"></PuzlEmptyData>
      <ModalSearchContract ref="searchContract"/>
    </div>
  </div>
</template>

<script>
import Cards from "./Shared/_Cards";
import ModalSearchContract from "./Shared/_ModalSearchContract";
import ModalEditService from "./Service/_Edit.vue";
import ModalScheduleListService from "./Shared/_ModalScheduleListService";
import ModalServiceCancellation from "./Service/_ModalServiceCancellation";
import {mapGetters} from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import cursorPaginate from "@/mixins/cursorPaginate";
import {formatErrorValidation} from "@/plugins";
import ModalListContacts from "./Weighing/Shared/_ModalListContacts"
import PuzlSelect from "@/components/PuzlSelect.vue";
import moment from "moment";

export default {
  name: "IndexSchedule",
  mixins: [cursorPaginate],
  components: {
    Cards,
    ModalSearchContract,
    PuzlSelect,
    ModalEditService,
    PuzlEmptyData,
    MultiFilter,
    ModalServiceCancellation,
    ModalScheduleListService,
    ModalListContacts,
  },
  data() {
    return {
      listType: "cards",
      loadingSkeleton: true,
      filter: {
        status: 10,
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary"
        },
        1: {
          name: "Aguardando liberação",
          type: "success"
        },
        0: {
          name: "Cancelado",
          type: "danger"
        },
        13: {
          name: "Liberado",
          type: "success-light"
        },
        15: {
          name: "Não realizado",
          type: "danger"
        },
        1: {
          name: "Reagendado",
          type: "danger"
        }
      },
    };
  },
  computed: {
    ...mapGetters({
      schedules: "schedule/fetch"
    })
  },
  mounted(){
    this.init();
  },
  methods: {
    handleDeleteProgramming(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("schedule/destroy", uuid)
              .then(response => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                this.init();
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                  this.$Progress.finish();
                  this.init();
                }
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    updateScreen(){
      this.init(this.filter);
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("schedule/fetchItems", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.loadingSkeleton = false;
          this.resolveCursor(response);
        })
        .catch(error => {
          this.resolveCursor();
          this.loadingSkeleton = false;
        });
    },
    handleFilterAdvancedStatus(status) {
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null
      };
      this.filter.status = this.filter.status === status ? null : status;
      this.init();
    },
    handleFilterDate(filter) {
      this.range = filter;
      if (filter) {
        const isSameDate = moment(filter.end).isSame(filter.start, 'day');
        const isToday = moment().isSame(filter.end, 'day');
        this.filter.status = isSameDate && isToday && this.filter.status === null ? 10 : null;
      }
      this.init({});
    },
    clearFilter() {
      this.loadingSkeleton = true;
      const defaultRange = {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date()
      };
      this.filter = {
        status: 10
      };
      this.range = defaultRange;
      this.$emit("setToday")
      this.init(this.filter);
    },
    setViewType(type) {
      this.loadingSkeleton = true;
      this.paginate.nextUrl = null;
      this.init(this.filter);
    },
    handleSearchContract() {
      this.$refs.searchContract.openModal();
    },
    handleGantt() {
      this.$router.push("/operational/schedule/gantt?sidebar=0");
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>