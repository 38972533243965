<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div v-show="cycle.distance == 0 || cycle.send_duration == 0 || cycle.return_duration == 0" class="mb-2 container text-dark">
          <i class="fa-solid fa-triangle-exclamation text-danger mx-1"></i>
          <b style="font-size: 13px;color: #e25757">Distância e/ou tempos de trajeto não podem ser iguais a zero</b>
        </div>
        <div class="border-left border-3 border-primary p-1">
          <h4>{{ cycle.plant.plant_name }}</h4>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Volume médio por BT
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-mask="['#.#', '##.#', '#']"
                        v-model="cycle.average_volume_per_bt"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          m<sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pb-0 pt-1 col-form-label form-control-label"
                >
                  Distância
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-1 text-right">
                  <i
                    v-if="cycle.distance == 0"
                    class="fa-solid fa-triangle-exclamation text-danger ml-3 my-2">
                  </i>
                </div>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="10"
                        inputmode="numeric"
                        v-model.lazy="cycle.distance"
                        type="text"
                        v-mask="'##########'"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          km
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pb-0 pt-1 col-form-label form-control-label"
                >
                  Tempo (ida)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-1 text-right">
                  <i
                    v-if="cycle.send_duration == 0"
                    class="fa-solid fa-triangle-exclamation text-danger ml-3 my-2">
                  </i>
                </div>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.send_duration"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Acréscimo trajeto (ida)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.send_addition"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Tempo de carregamento
                  <span class="text-danger"></span>
                </label>
                <div class="col-md-5">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model.number="cycle.load_truck_time"
                      v-mask="['#############']"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        minutos
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Tempo de mistura/dosagem
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.new_time_mixing"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Permanência em obra
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.time_construction_stay"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Intervalo entre descargas
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.interval_releases"
                        v-mask="['#############']"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pb-0 pt-1 col-form-label form-control-label"
                >
                  Trajeto (volta)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-1 text-right">
                  <i
                    v-if="cycle.return_duration == 0"
                    class="fa-solid fa-triangle-exclamation text-danger ml-3 my-2">
                  </i>
                </div>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="10"
                        inputmode="numeric"
                        v-mask="'##########'"
                        v-model.lazy="cycle.return_duration"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Acréscimo trajeto (volta)
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.return_addition"
                        type="number"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Tempo de limpeza/Lastro
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model.number="cycle.time_clear"
                        type="number"
                        class="form-control form-control-sm"
                        disabled
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          minutos
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1 mt-4">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label"
                >
                  Ciclo total:
                </label>
                <div class="col-md-5">{{ cycle.total_cycle }} minutos</div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pb-0 pt-1 col-form-label form-control-label text-warning"
                >
                  Adicional de ciclo:
                </label>
                <div class="col-md-5 text-warning">
                  {{ cycle.additional_cycle }} minutos
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1 mt-4">
                <div class="col-md-12">
                  <!--                  <span class="text-body small">Usuário - data - hora</span>-->
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
              >Cancelar
              </base-button
              >
              <base-button
                type="success"
                native-type="submit"
                :disabled="invalid"
                :loading="loading"
              >Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  debounce,
} from "@/plugins";
export default {
  components: {},
  name: "ModalEditCycle",
  data() {
    return {
      modal: false,
      title: "Editar ciclo de viagem",
      validated: false,
      loading: false,
      once: true,
      oldcycle: {},
      cycle: {
        average_volume_per_bt: null,
        cycle_additional: null,
        new_time_mixing: 0,
        distance: null,
        send_duration: null,
        send_addition: null,
        return_addition: null,
        return_duration: null,
        time_clear: null,
        time_mixing: null,
        time_construction_stay: null,
        interval_releases: null,
        load_truck_time: null,
        plant: {
          id: "",
          name: null
        },
        total_cycle: 0,
        additional_cycle: 0
      }
    };
  },
  methods: {
    closeModal() {
      this.cycle = {...this.oldcycle};
      this.modal = false;
    },
    openModal() {
      this.modal = true;
    },
    mountData(cycle) {
      this.once = true;
      this.cycle = {...cycle};
      const newMixing = parseInt(this.cycle.time_mixing) * parseFloat(this.cycle.average_volume_per_bt)
      this.cycle.new_time_mixing = Math.round(newMixing)
    },
    teste2: debounce(function () {
      this.$notify({type: 'danger', message: `O Volume médio por BT não pode ser superior a 49,9.`})
      this.$forceUpdate();
    }, 200),
    save() {
      this.modal = false;
      this.$emit("addCycle", this.cycle);
    }
  },
  mounted() {
    this.$watch("cycle.average_volume_per_bt", function (after, before){
      if (!after) {
        return
      }
      if (after > 49.9) {
        this.teste2()
        const newMixing = parseInt(this.cycle.time_mixing) * parseFloat(before || 0)
        this.cycle.new_time_mixing = Math.round(newMixing)
        this.cycle.average_volume_per_bt = before
      } else {
        const newMixing = parseInt(this.cycle.time_mixing) * parseFloat(after || 0)
        this.cycle.new_time_mixing = Math.round(newMixing)
        this.cycle.average_volume_per_bt = after
      }
    })
    this.$watch('cycle.send_duration', function (val) {
      if (! val) {
        val = 0
      }
      this.cycle.return_duration = val
    })
    this.$watch("cycle.send_duration", function (val){
        this.cycle.send_addition = Math.round(val * this.cycle.increse_route_truck_full_send / 100)
    })
    this.$watch("cycle.return_duration", function (val){
        this.cycle.return_addition = Math.round(val * this.cycle.increse_route_truck_empty_return / 100)
    })
    this.$watch(
      "cycle",
      function (newVal) {
        let notIn = [
          "plant",
          "has_minimum_cost",
          "plant_id",
          "increse_route_truck_empty_return",
          "distance",
          "average_volume_per_bt",
          "increse_route_truck_full_send",
          "total_cycle",
          "cycle_additional",
          "cycle_additional_cost",
          "transport_maximum_distance",
          "transport_additional_cost",
          "time_mixing",
          "interval_releases",
          "additional_cycle"
        ];
        let totalCycle = 0;
        for (var item in newVal) {
          if (notIn.includes(item)) {
            continue;
          }
          if (!newVal[item]) {
            continue;
          }
          totalCycle += parseFloat(newVal[item]);
        }
        this.cycle.total_cycle = parseInt(totalCycle);
        if (this.cycle.total_cycle < newVal.cycle_additional) {
          this.cycle.additional_cycle = 0;
        } else {
          this.cycle.additional_cycle =
            parseInt(totalCycle) - newVal.cycle_additional;
        }
        if (this.once) {
          this.oldcycle = {...this.cycle};
          this.$emit("addCycle", this.oldcycle);
          this.once = false;
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  }
};
</script>

<style></style>
