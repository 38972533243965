import { stockLocationUrls } from '.';
import httpClient from '../../../../shared/libraries/httpClient';
import { handleUnexpectedStatusCode } from '../../../../shared/services';
import { HttpStatusEnum } from '../../../../shared/enums';
import { StockLocationFilterType, StockLocationPagerType } from '../types';

/**
 * Listar
 *
 * @param {?StockLocationFilterType} filter
 * @returns {Promise<StockLocationPagerType}
 */
const execute = async (filter = null) => {
  const res = await httpClient.get(stockLocationUrls.BASE, { params: filter });

  switch (res.status) {
    case HttpStatusEnum.HTTP_OK: return res.data.data;
    default: handleUnexpectedStatusCode(res);
  }
};

export default { execute };
