<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[2].title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[1].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[2].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <router-link
            :to="{
              path: '/commercial/contract-proposal/formulation',
              name: 'commercial.contract-proposal.formulation',
              params: { contract_proposal_uuid: contract_proposal.uuid }
            }"
          >
            <base-button block size="lg" type="light" class="text-uppercase">
              voltar
            </base-button>
          </router-link>
        </div>
      </div>

      <div>
        <div class="row align-items-center pb-0 mb-3">
          <div class="col-md-12">
            <div class="checklist-item checklist-item-primary">
              <div class="checklist-info">
                <h2 class="checklist-title mb-0">
                  Proposta/Contrato:
                  <span class="small">{{ contract_proposal.code }}</span>
                </h2>
                <h2 class="checklist-title mb-0">
                  Cliente:
                  <span class="small">
                    {{
                      contract_proposal &&
                      contract_proposal.construction &&
                      contract_proposal.construction.customer_construction.customer_name
                    }}
                  </span>
                </h2>
                <h2 class="checklist-title mb-0">
                  Obra:
                  <span class="small">
                    {{
                      contract_proposal && contract_proposal.construction && contract_proposal.construction.construction_name
                    }}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <!-- Start Card Choose Mixe -->
      <card v-if="(selected && selected.puzl_additions.length) || !selected">
        <!-- Card header -->
        <div class="row align-items-center mb-3 card-body-molde">
          <div class="col-12 checklist-item border-primary p-2" v-if="!selected">
            <base-button type="primary" @click.prevent="addMixe" :disabled="loading">
              incluir traço
            </base-button>
          </div>
          <div class="col-12 checklist-item border-indigo p-2 mt-3" v-if="selected && selected.puzl_additions.length">
            <base-button type="indigo" outline @click.prevent="addPuzlAdmixture">
              Puzl Admix
            </base-button>
          </div>
        </div>
        <!-- Card body -->
        <div></div>
      </card>
      <!-- End Card  Choose Mixe -->

      <!-- Start Card Set Price Minimum -->
      <card v-if="selected">
        <!-- Card header -->
        <div class="row align-items-center mb-3 card-body-molde">
          <div class="col-12">
            <!-- Title -->
          </div>
        </div>
        <!-- Card body -->
        <div>
          <!-- Start Insert Mixe Price Minimum -->
          <div>
            <div v-if="selected" class="row border-left border-3 border-primary p-2 ml-1">
              <div class="col-md-6">
                <h5 class="h3 mb-1">
                  {{ selected.plant.plant_name }}
                </h5>
                <div class="row" v-for="feature in selected.features" :key="feature.id">
                  <div class="col-md-12">
                    <h5 class="h mb-0">
                      {{ feature.short_description }}
                      {{ format_mixe(feature.period, feature.period_type) }}
                      {{ feature.item_description }}
                      {{ feature.letter }}
                    </h5>
                  </div>
                </div>
                <div class="">
                  <span class="p small">
                    {{ selected.type.technical_description }}
                    {{ selected.subtype ? " | " + selected.subtype.technical_description : "" }}
                    {{ selected.classification ? " | " + selected.classification.technical_description : "" }}
                    |
                    {{ selected.slump_flow.nomenclature }}
                    |
                    {{ selected.maximum_size.description }}
                  </span>
                </div>
                <div class="row" v-if="getSlumpFlow().length">
                  <label class="col-md-12 pb-0 pt-1 col-form-label form-control-label">
                    Slump final <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-12 mb-2">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        clearable
                        @change="setSlump"
                        label="nomenclature"
                        v-model="formulation.minimum_price.slump_flow_id"
                        :items="getSlumpFlow()"
                        :loading="loadingSlumpFlow"
                      />
                      <!--                          <el-select-->
                      <!--                            @change="changeConcretedPiece"-->
                      <!--                            v-model="formulation.minimum_price.concreted_piece_ids"-->
                      <!--                            filterable-->
                      <!--                            multiple-->
                      <!--                            placeholder="Selecione"-->
                      <!--                            :loading="loadingConcretePiece"-->
                      <!--                            :disabled="loadingConcretePiece"-->
                      <!--                          >-->
                      <!--                            <el-option-->
                      <!--                              v-for="concrete_piece in concrete_pieces"-->
                      <!--                              :key="concrete_piece.id"-->
                      <!--                              :label="concrete_piece.description"-->
                      <!--                              :value="concrete_piece.id"-->
                      <!--                            >-->
                      <!--                            </el-option>-->
                      <!--                          </el-select>-->
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="col-md-5 mb-2  text-center">
                <span class="h3">
                  {{
                    minimum_price.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}
                  /
                  {{ selected.price.unit_letter }}
                </span>
              </div>

              <div class="col-md-1 show-md col-sm-12">
                <base-button
                  @click.prevent="handleRemoveSelected"
                  size="sm"
                  outline
                  type="danger">
                  <i class="fa-regular fa-circle-xmark"></i>
                </base-button>
              </div>

              <div class="col-md-1 show-mobile col-sm-12">
                <base-button
                  @click.prevent="handleRemoveSelected"
                  size="sm"
                  outline
                  block
                  type="danger">
                  <i class="fa-regular fa-circle-xmark"></i>
                </base-button>
              </div>
            </div>
            <br>
            <!-- Addition Puzl Price -->
            <div v-if="selected && puzl_additions.length" class="row border-left border-3 border-indigo p-2 ml-1">
              <div class="col-md-6">
                <div class="row" v-for="(addition, indexAddition) in puzl_additions" :key="indexAddition">
                  <div class="col-md-12">
                    <h5 class=" mb-0 text-indigo">
                      {{ addition.name }}
                      ({{ addition.volume }} kg/<span style="text-transform: none">m</span><sup>3</sup>)
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-md-5 d-flex justify-content-center align-items-center">
                <span class="h3 text-indigo">
                  {{
                    puzl_addition_price.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}
                  / m³
                </span>
              </div>

              <div class="col-md-1 col-sm-12 d-flex justify-content-center align-items-center">
                <base-button block size="sm" type="indigo" @click.prevent="addPuzlAdmixture">
                  <i class="fa fa-edit text-white"></i>
                </base-button>
              </div>
            </div>
          </div>
          <div v-if="selected && $_general_settings && $_general_settings.show_coarse_aggregate"
               class="row border-left border-3 border-warning p-2 ml-1">
            <div class="col-12" style="font-size: 12px">
              <img class="mr-1" src="/img/icons/icons8/ios/wheelbarrow_dark.png" height="19px"> <span
              style="font-size: 12px"
              v-for="(group, index) in selected.content_groups"> {{
                group.name
              }} ({{ group.percentage }})
                                  <template v-if="selected.content_groups[index + 1]"> + </template></span>
            </div>
          </div>
          <!-- End Insert Mixe Price Minimum -->

          <!-- Start Edit Mixe Price Minimum -->
          <div class="row mt-4" v-if="selected">
            <div class="col-sm-2">
              <base-button :disabled="loadingCycle" block size="sm" type="warning" @click.prevent="editCycle">
                Editar Ciclo
                <div>
                </div>
              </base-button>
              <small v-show="!haveCycle && !loadingCycle"><i class="fas fa-exclamation-triangle text-danger mr-2"></i>
                <strong class="" style="color: #7e7e7e">Ciclo incompleto!</strong>
              </small>
            </div>
          </div>
          <!-- End Edit Mixe Price Minimum -->

          <!-- Start Insert Prices Cicle -->
          <div class="mt-4" v-if="selected">
            <div class="border-left border-3 border-warning p-2"
                 v-if="transportMaximumDiff.contains || cycleMaximumDiff.contains || concretedPieceDiff.contains || Number(slumpDiff.percentage) !== 0 || Number(featureLevelDiff.percentage) !== 0">
              <div class="row mt-n2 mb-2">
                <div class="col-md-12">
                  <h4 class="text-warning">
                    <img style="height: 25px" src="/img/icons/icons8/windows/credit-card-interest.png"/>
                    CUSTOS VARIÁVEIS
                  </h4>
                </div>
              </div>
              <div class="row mt-n2" v-if="cycleMaximumDiff.contains">
                <template>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">
                          ciclo ({{ cycleMaximumDiff.diff }}
                          minutos)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 d-flex justify-content-center align-items-center">
                    <span class="h4">
                      {{
                        cycleMaximumDiff.value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL"
                        })
                      }}
                      /m3
                    </span>
                  </div>
                  <div class="col-md-1">
                  <span class="h4 text-danger">
                    &nbsp;
                  </span>
                  </div>
                </template>
              </div>
              <div class="row mt-n2" v-if="transportMaximumDiff.contains">
                <template>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">
                          distância ({{
                            parseFloat(transportMaximumDiff.diff).toFixed(2).toString().replace(".", ",")
                          }}
                          Km)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 d-flex justify-content-center align-items-center">
                    <span class="h4">
                      {{
                        transportMaximumDiff.value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL"
                        })
                      }}
                      /m3
                    </span>
                  </div>

                  <div class="col-md-1">
                    <span class="h4 text-danger">
                      &nbsp;
                    </span>
                  </div>
                </template>
              </div>
              <div class="row mt-n2"
                   v-if="concreted_piece && concreted_piece.mix_concreted_piece && concreted_piece.percentage > 0">
                <template>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">peça a concretar -
                        {{ concreted_piece.mix_concreted_piece.description }}
                        ({{ concreted_piece.percentage }}%)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4   ">
                    <span class="h4">
                   {{
                        parseFloat(concretedPieceDiff.value).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2
                        }) + " /m3"
                      }}
                    </span>
                  </div>
                </template>
              </div>
              <div class="row "
                   :class="!(concreted_piece && concreted_piece.mix_concreted_piece && concreted_piece.percentage !== 0) && 'mt-n2'"
                   v-if="Number(slumpDiff.percentage) !== 0">
                <template>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">Slump final -
                        {{ slump_flow_selected.nomenclature }}
                        ({{ slump_flow_selected.percentage.percentage }}%)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 d-flex justify-content-center align-items-center">
                    <span class="h4">
                   {{
                        parseFloat(slumpDiff.value).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2
                        }) + " /m3"
                      }}
                    </span>
                  </div>

                  <div class="col-md-1">
                    <span class="h4 text-danger">
                      &nbsp;
                    </span>
                  </div>
                </template>
              </div>
              <div class="row "
                   :class="!(concreted_piece && concreted_piece.mix_concreted_piece && concreted_piece.percentage !== 0) && 'mt-n2'"
                   v-if="Number(featureLevelDiff.percentage) !== 0">
                <template>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">Nível de aplicação -
                        {{ feature_level_selected.label }}
                        ({{ feature_level_selected.percentage }}%)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 d-flex justify-content-center align-items-center">
                    <span class="h4">
                   {{
                        parseFloat(featureLevelDiff.value).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2
                        }) + " /m3"
                      }}
                    </span>
                  </div>
                  <div class="col-md-1">
                    <span class="h4 text-danger">
                      &nbsp;
                    </span>
                  </div>
                </template>
              </div>
            </div>

            <!-- Addition Puzl Price -->
            <div class="border-left border-3 border-danger p-2 mt-2">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12">
                      <span class="h5 mb-0 text-danger">
                        Preço mínimo de venda
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <span class="h4 text-danger">
                    {{ $helper.toMoney(formulation.minimum_price.minimum_price) }}
                    /m3
                  </span>
                </div>

                <div class="col-md-1">
                  <span class="h4 text-danger">
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- End Insert Prices Cicle -->

          <!-- Start Create Price -->
          <div>
            <validation-observer>
              <div v-if="selected" class="row d-flex justify-content-center mt-2">
                <div class="col-12">
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Status
                    </label>
                    <div class="col-md-8 d-flex justify-content-between">
                      <base-button
                        @click.prevent="formulation.minimum_price.status = 1"
                        block
                        outline
                        size="sm"
                        type="success"
                        class="text-capitalize mt-2"
                        :class="formulation.minimum_price.status == 1 && 'active'"
                      >
                        ativo
                      </base-button>
                      <base-button
                        @click.prevent="formulation.minimum_price.status = 0"
                        block
                        outline
                        size="sm"
                        type="danger"
                        class="text-capitalize"
                        :class="formulation.minimum_price.status == 0 && 'active'"
                      >
                        inativo
                      </base-button>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Data
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <el-date-picker
                            :disabled="true"
                            v-model="formulation.minimum_price.date_at"
                            size="mini"
                            type="date"
                            format="dd/MM/yyyy"
                            lang="pt-BR"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            block
                          >
                          </el-date-picker>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Peças a concretar
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <v-select
                            :closeOnSelect="false"
                            @input="changeConcretedPiece"
                            :reduce="piece => piece.code"
                            multiple
                            v-model="formulation.minimum_price.concreted_piece_ids"
                            :options="getConcretePieces().map(function (item) {
                              return {
                                label: item.description,
                                code: item.id
                              }
                            })">
                            <span slot="no-options">Nenhuma peça encontrada</span>
                          </v-select>
                          <!--                          <el-select-->
                          <!--                            @change="changeConcretedPiece"-->
                          <!--                            v-model="formulation.minimum_price.concreted_piece_ids"-->
                          <!--                            filterable-->
                          <!--                            multiple-->
                          <!--                            placeholder="Selecione"-->
                          <!--                            :loading="loadingConcretePiece"-->
                          <!--                            :disabled="loadingConcretePiece"-->
                          <!--                          >-->
                          <!--                            <el-option-->
                          <!--                              v-for="concrete_piece in concrete_pieces"-->
                          <!--                              :key="concrete_piece.id"-->
                          <!--                              :label="concrete_piece.description"-->
                          <!--                              :value="concrete_piece.id"-->
                          <!--                            >-->
                          <!--                            </el-option>-->
                          <!--                          </el-select>-->
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div v-if="!selected.block_level_feature_adjustment" class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Nível de aplicação
                      <el-popover
                        v-if="$_application_level_guideline"
                        trigger="hover" placement="bottom" class="p-0 pr-0"
                      >
                        <div>
                          <b class="text-dark">Baixa:</b>
                          {{ $_application_level_guideline.description_low }}
                        </div>
                        <div>
                          <b class="text-dark">Média:</b>
                          {{ $_application_level_guideline.description_medium }}
                        </div>
                        <div>
                          <b class="text-dark">Alta:</b>
                          {{ $_application_level_guideline.description_high }}
                        </div>
                        <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                          <i style="font-size: 19px" class="pointer ml-1 text-warning fa-solid fa-circle-info"></i>
                        </base-button>
                      </el-popover>
                    </label>
                    <div class="col-md-8">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          :disabled="selected.block_level_feature_adjustment"
                          @change="setLevelResponsibility"
                          v-model="formulation.minimum_price.feature_level"
                          :items="feature_levels"
                          customKey="value"
                          label="label"/>
                      </base-input>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Volume previsto
                      <span class="text-danger">&nbsp;*</span>
                      <!--                      <base-button-->
                      <!--                        size="sm"-->
                      <!--                        type="success"-->
                      <!--                      >-->
                      <!--                        <i class="fa fa-plus text-white"></i>-->
                      <!--                      </base-button>-->
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            v-model="formulation.minimum_price.expected_volume"
                            inputmode="numeric"
                            v-mask="['#', '#,#', '##,#', '###,#', '#.###,#', '##.###,#', '###.###,#', '#.###.###,#', '##.###.###,#']"
                            class="form-control form-control-sm"
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0"> <span
                              style="text-transform: none">m</span><sup>3</sup> </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Preço de venda
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            maxlength="10"
                            inputmode="numeric"
                            v-money="money"
                            :class="isBiggerPrice"
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            v-model.lazy="formulation.minimum_price.value"
                            type="text"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0"> /<span
                              style="text-transform: none">m</span><sup>3</sup> </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                    <!--                    <div class="offset-4 col-4 mt-1" v-if="formulation.minimum_price.value == '0,00'">-->
                    <!--                      <base-input input-group-classes="input-group-sm">-->
                    <!--                        <input type="checkbox" class="mr-1 custom-checkbox" id="donation-checkbox"-->
                    <!--                               v-model="is_confirm_donation"/>-->
                    <!--                        <label for="donation-checkbox" class="text-danger"><b>Confirmar como doação</b></label>-->
                    <!--                      </base-input>-->
                    <!--                    </div>-->
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Tipo de entrega
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8" v-show="formulation.minimum_price.value == '0,00'">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select label="label" customKey="id"
                                       v-model="formulation.minimum_price.delivery_type"
                                       :items="[
          {
            label: 'Doação',
            id: 2,
          },
          {
            label: 'TRANSFERÊNCIA',
            id: 3,
          },
          {
            label: 'Uso interno',
            id: 4,
          },
          {
            label: 'Reparo de concretagem',
            id: 5,
          },
        ]">

                          </puzl-select>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-8" v-show="formulation.minimum_price.value != '0,00'">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select label="label" customKey="id"
                                       v-model="formulation.minimum_price.delivery_type"
                                       :items="[{label: 'Venda de concreto',id: 1}]">

                          </puzl-select>
                        </base-input>
                      </validation-provider>
                    </div>
                    <!--                    <div class="offset-4 col-4 mt-1" v-if="formulation.minimum_price.value == '0,00'">-->
                    <!--                      <base-input input-group-classes="input-group-sm">-->
                    <!--                        <input type="checkbox" class="mr-1 custom-checkbox" id="donation-checkbox"-->
                    <!--                               v-model="is_confirm_donation"/>-->
                    <!--                        <label for="donation-checkbox" class="text-danger"><b>Confirmar como doação</b></label>-->
                    <!--                      </base-input>-->
                    <!--                    </div>-->
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Retira de terceiros?
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8 mt-1">
                      <validation-provider rules="required">
                        <base-switch v-model="formulation.minimum_price.concrete_removal" type="success" offText="NÃO"
                                     onText="SIM" class="success"
                        >
                        </base-switch>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Total
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            maxlength="10"
                            inputmode="numeric"
                            :disabled="true"
                            v-money="money"
                            :value="getTotalPrice"
                            type="text"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Observações
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-8">
                      <base-input input-group-classes="input-group-sm">
                        <textarea type="text" v-model="formulation.minimum_price.observation" rows="2"
                                  class="form-control form-control-sm">
                        </textarea>
                      </base-input>
                    </div>
                  </div>
                  <div class="mt-3 d-flex justify-content-center">
                    <!--                <div class="modal-footer">-->
                    <router-link
                      :to="{
                        path: '/commercial/contract-proposal/formulation',
                        name: 'commercial.contract-proposal.formulation',
                        params: { contract_proposal_uuid: contract_proposal.uuid }
                      }"
                    >
                      <base-button type="secondary">Cancelar</base-button>
                    </router-link>
                    <base-button
                      type="success"
                      native-type="submit"
                      :disabled="
                        invalid ||
                        !haveCycle ||
                        !formulation.minimum_price.concreted_piece_ids.length ||
                        (getSlumpFlow().length && !formulation.minimum_price.slump_flow_id) || (!formulation.minimum_price.feature_level && !selected.block_level_feature_adjustment)
                      "
                      :loading="loadingSave"
                      @click.native="handleSaveFormulation"
                    >
                      Salvar
                    </base-button>
                  </div>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </card>
      <!-- End Card Set Price Minimum -->
    </div>
    <ModalEditCycle @addCycle="setCycle" ref="editCycle"/>
    <ModalAddMixe @set_mix_design="handleSetMixDesign" ref="addMixe"/>
    <ModalAddPuzlAdmixture @savePuzlAdditions="savePuzlAdditions" ref="addPuzlAdmixture"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {debounce, toFixed} from "@/plugins";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalEditCycle from "./_ModalEditCycle";
import ModalAddMixe from "./_ModalAddMixe";
import ModalAddPuzlAdmixture from "./_ModalAddPuzlAdmixtures";
import {formatErrorValidation} from "@/plugins";
import {VMoney} from "v-money";
import PuzlSelect from '@/components/PuzlSelect.vue'

export default {
  name: "CreateContractProposalFormulation",
  components: {
    ModalEditCycle,
    ModalAddMixe,
    ModalAddPuzlAdmixture,
    PuzlSelect
  },
  data() {
    return {
      format_mixe: period_types,
      loadingPlant: true,
      loadingSave: false,
      loading: true,
      invalid: false,
      total: null,
      valid_incomplet_cycle: false,
      loadingConcretePiece: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      slump_flow_selected: null,
      slump_flows: [],
      loadingSlumpFlow: false,
      haveCycle: false,
      formulation: {
        minimum_price: {
          minimum_price: 0,
          delivery_type: null,
          concrete_removal: 0,
          slump_flow_id: null,
          status: 1,
          expected_volume: "",
          value: null,
          observation: "",
          date_at: "",
          concreted_piece_ids: []
        }
      },
      cycleMaximumDiff: {
        diff: 0,
        value: 0,
        contains: false
      },
      transportMaximumDiff: {
        diff: 0,
        value: 0,
        contains: false
      },
      cycleMinimum: 0,
      minimum_price: 0,
      puzl_additions: [],
      puzl_addition_price: 0,
      concretedPieceDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      },
      slumpDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      },
      featureLevelDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      },
      loadingFeatureLevels: false,
      isBiggerPrice: null,
      loadingCycle: true,
      selected: null,
      feature_level_selected: null,
      is_confirm_donation: false,
    };
  },
  directives: {money: VMoney},
  computed: {
    ...mapGetters({
      $_general_settings: "generalSetting/show",
      concrete_pieces: "mixConcretePiece/activeItems",
      concreted_piece: "formulations/getConcretedPiece",
      cycle_values: "formulations/getCycleValues",
      contract_proposal: "contractProposal/show",
      $_application_level_guideline: "applicationLevelGuideline/fetch",
    }),
    feature_levels() {
      let $_items = this.$store.state.mixConcretePiece.feature_levels
      return $_items.filter((item) => this.selected.level_features.includes(item.value))
    },
    getTotalPrice() {
      let value = this.formulation.minimum_price.value ?? '0.00'
      const volume = (this.formulation.minimum_price.expected_volume || 1).toString().replaceAll('.', '').replace(",", ".")
      const price = (value).toString().replaceAll('.', '').replace(",", ".")
      return (price * volume).toFixed(2)
    }
  },
  methods: {
    setDeliveryType() {
      const price = Number((this.formulation.minimum_price.value || '0.00').toString().replaceAll('.', '').replace(",", "."))
      if (price > 0 && this.formulation.minimum_price.delivery_type !== 1) {
        return this.formulation.minimum_price.delivery_type = 1
      }
      if (price < 0.01 && this.formulation.minimum_price.delivery_type === 1) {
        return this.formulation.minimum_price.delivery_type = null
      }
    },
    async handleSearchAdjustment() {
      let loader = this.$loading.show()
      await this.$store.dispatch('concretePrice/getPriceByPuzlAdjustment', {
        mix_design_mixe_id: this.selected.id,
        adjustments: {
          concrete_piece_adjustments: null,
          slump_flow_id: this.formulation.minimum_price.slump_flow_id,
          company_plant_id: this.contract_proposal.company_plant_id,
          level_feature: this.formulation.minimum_price.feature_level,
          distance: null,
          cycle: null,
        }
      }).then(async ({data}) => {
        this.minimum_price = data.minimum_price
        this.cycleMinimum = this.parseValue(this.transportMaximumDiff.value) + this.parseValue(this.cycleMaximumDiff.value) + data.minimum_price;
        this.selected.mcc_atual = data.cmc
        this.selected.concrete_price.mcc_price = data.cmc
        this.selected.concrete_price.minimum_price = data.minimum_price
        const slump_flow_selected = this.slump_flows.find((item) => item.id === this.formulation.minimum_price.slump_flow_id)
        if (slump_flow_selected) {
          const percentage = Number(slump_flow_selected.percentage && slump_flow_selected.percentage.percentage)
          if (percentage !== 0) {
            this.slumpDiff.contains = true
          }
          this.percentageSlump(this.minimum_price, percentage, slump_flow_selected.nomenclature);
        }
        const feature_level_selected = this.feature_levels.find((item) => item.value === this.formulation.minimum_price.feature_level)
        if (feature_level_selected) {
          const percentage = Number(feature_level_selected.percentage)
          if (percentage !== 0) {
            this.featureLevelDiff.contains = true
          }
          this.percentageLevelResponsibility(this.minimum_price, percentage, feature_level_selected.label);
        }
        let params = {
          concreted_piece_ids: this.formulation.minimum_price.concreted_piece_ids
        };
        await this.$store.dispatch("formulations/getConcretedPieces", params).then(async response => {
          if (response.data) {
            this.concretedPieceDiff.contains = true
            this.percentage(this.minimum_price, response.data.percentage, response.data.mix_concreted_piece.description);
          }
        });
        loader.hide()
      })
    },
    /**
     * Atualiza o preço minimo
     */
    setMinimumPrice(msg = true) {
      this.formulation.minimum_price.minimum_price =
        parseFloat(this.slumpDiff.value) +
        parseFloat(this.featureLevelDiff.value) +
        parseFloat(this.concretedPieceDiff.value) + parseFloat(this.cycleMinimum) + parseFloat(this.puzl_addition_price);
      if (this.selected && msg) {
        this.$snotify.info('O PREÇO MÍNIMO FOI ATUALIZADO', {
          timeout: 1000,
          icon: false,
          position: "centerTop",
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false
        });
      }
    },
    async setSlump() {
      await this.handleSearchAdjustment()
      const slump_flow_selected = this.slump_flows.find((item) => item.id === this.formulation.minimum_price.slump_flow_id)
      if (!slump_flow_selected) {
        this.slumpDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        }
        return this.setMinimumPrice()
      }
      this.slump_flow_selected = slump_flow_selected
      const percentage = Number(slump_flow_selected.percentage && slump_flow_selected.percentage.percentage)
      if (percentage !== 0) {
        this.slumpDiff.contains = true
      }
      this.percentageSlump(this.minimum_price, percentage, slump_flow_selected.nomenclature);
      this.setMinimumPrice()
    },
    async setLevelResponsibility() {
      await this.handleSearchAdjustment()
      const feature_level_selected = this.feature_levels.find((item) => item.value === this.formulation.minimum_price.feature_level)
      if (!feature_level_selected) {
        this.featureLevelDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        }
        return this.setMinimumPrice()
      }
      this.feature_level_selected = feature_level_selected
      const percentage = Number(feature_level_selected.percentage)
      if (percentage !== 0) {
        this.featureLevelDiff.contains = true
      }
      this.percentageLevelResponsibility(this.minimum_price, percentage, feature_level_selected.label);
      return this.setMinimumPrice()
    },
    /**
     * Retorna apenas peças a concretar vinculadas a formulação
     * @returns {*}
     */
    getConcretePieces() {
      if (this.selected.block_concreted_piece_ids && this.selected.block_concreted_piece_ids.length) {
        return this.concrete_pieces.filter(item => {
          return !this.selected.block_concreted_piece_ids.includes(item.id)
        })
      }
      if (this.selected.concrete_pieces && this.selected.concrete_pieces.length) {
        return this.concrete_pieces.filter(item => {
          return this.selected.concrete_pieces.includes(item.id)
        })
      }
      return this.concrete_pieces
    },
    validIsBiggerPrice() {
      if (!this.formulation.minimum_price.value) {
        return true;
      }
      const value = this.formulation.minimum_price.value.replace(',', '.').replace('.', '')
      if (value > 0) {
        return false
      }
      return true
      // if (! value > 0) {
      //   return true
      // }
      // return false
    },
    changeConcretedPiece: debounce(async function () {
      let params = {
        concreted_piece_ids: this.formulation.minimum_price.concreted_piece_ids
      };
      this.concretedPieceDiff = {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      };
      this.$Progress.start();
      this.$store.dispatch("formulations/getConcretedPieces", params).then(async response => {
        this.$Progress.finish();
        this.concretedPieceDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        };
        await this.handleSearchAdjustment()
        if (response.data) {
          this.concretedPieceDiff.contains = true
          this.percentage(this.minimum_price, response.data.percentage, response.data.mix_concreted_piece.description);
        }
        this.setMinimumPrice()
      });
    }, 500),
    getSlumpFlow() {
      return this.slump_flows.filter((item) => this.selected.slump_flow_ids.includes(item.id))
    },
    handleSaveFormulation() {
      let title = ''
      let body = ''
      let footer = ''
      let puzl_additions_text = ''
      // add title
      // dados do traço
      for (var feature of this.selected.features) {
        feature.item_description = feature.item_description.replace('.', ',')
        title = title.concat(feature.short_description)
        title = title.concat(' ' + this.format_mixe(feature.period, feature.period_type))
        title = title.concat(' ' + feature.item_description)
        /**
         * Regra essencial, não remover validação de null, undefined
         */
        if (feature.letter && feature.letter != null && feature.letter != undefined) {
          title = title.concat(' ' + feature.letter || '')
        }
        if (this.selected.features[this.selected.features.length - 1] != feature) {
          title = title.concat(' + ')
        }
      }
      body = body.concat(this.selected.type.technical_description || '')
      body = body.concat(this.selected.subtype ? " | " + this.selected.subtype.technical_description || '' : "")
      body = body.concat(this.selected.classification ? " | " + this.selected.classification.technical_description || '' : "")
      body = body.concat(' | ')
      body = body.concat(this.selected.slump_flow.nomenclature || '')
      body = body.concat(' | ')
      body = body.concat(this.selected.maximum_size.description || '')
      if (this.slump_flow_selected) {
        footer = footer.concat('Slump final: ' + this.slump_flow_selected.nomenclature)
      }
      for (var puzl_addition of this.puzl_additions) {
        puzl_additions_text = puzl_additions_text.concat(puzl_addition.name)
        puzl_additions_text = puzl_additions_text.concat(' (' + puzl_addition.volume + ' kg/m<sup>3</sup>)')
        if (this.puzl_additions[this.puzl_additions.length - 1] !== puzl_addition) {
          puzl_additions_text = puzl_additions_text.concat(' + ')
        }
      }
      let formulation = {
        ...this.formulation
      };
      formulation.cycle = {
        ...this.cycle
      };
      formulation.title = title
      formulation.body = body
      formulation.footer = footer
      formulation.slump_flow_id = this.slump_flow_selected ? this.slump_flow_selected.id : null
      formulation.puzl_additions_text = puzl_additions_text
      formulation.formulation_uuid = this.selected.formulation_uuid;
      formulation.cycle = this.prepareCycle(formulation.cycle);
      formulation.mix_design_mixe_id = this.selected.id;
      formulation.additionals = {
        cycle_maximum: this.cycleMaximumDiff,
        transport_maximum: this.transportMaximumDiff
      };
      formulation.minimum_price.value = formulation.minimum_price.value.replaceAll(".", "").replace(",", ".");
      formulation.concrete_price = this.selected.concrete_price;
      formulation.minimum_price.puzl_additions = this.puzl_additions;
      formulation.minimum_price.mixe_minimum_price = this.selected.price.value.toString().replace(",", ".");
      formulation.minimum_price.minimum_price = formulation.minimum_price.minimum_price.toString().replace(",", ".");
      formulation.minimum_price.addition_price = 0;
      formulation.contract_proposal_uuid = this.$route.params.contract_proposal_uuid;
      if (this.concretedPieceDiff.percentage) {
        formulation.additionals.concretedPiece = this.concretedPieceDiff;
      }
      if (Number(this.slumpDiff.percentage) !== 0) {
        formulation.additionals.slumpFinal = this.slumpDiff;
      }
      if (Number(this.featureLevelDiff.percentage) !== 0) {
        formulation.additionals.featureLevel = this.featureLevelDiff;
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
        .dispatch("formulations/add", formulation)
        .then(response => {
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          if (response && response.data && response.data.hasOwnProperty('confirm_service')) {
            return this.$Swal.confirmAction('Deseja ser redirecionado a tela de serviços?', ['Sim', 'Não'])
              .then((result) => {
                if (result.isConfirmed) {
                  this.$router.push("/commercial/contract-proposal/services/create/" + this.$route.params.contract_proposal_uuid);
                } else {
                  this.$router.push("/commercial/contract-proposal/formulation/" + this.$route.params.contract_proposal_uuid);
                }
              }).catch(() => this.$Progress.finish())
          }
          return this.$router.push("/commercial/contract-proposal/formulation/" + this.$route.params.contract_proposal_uuid);
        })
        .catch(error => {
          this.loadingSave = false;
          this.$Progress.finish();
          if (error && error.response && error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: message
            });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          }
        });
    },
    prepareCycle(cycle) {
      delete cycle.transport_additional_cost;
      delete cycle.transport_maximum_distance;
      cycle.plant_id = cycle.plant.id;
      delete cycle.plant;
      delete cycle.cycle_additional_cost;
      delete cycle.cycle_additional;
      return cycle;
    },
    async setCycle(cycle) {
      this.cycle = cycle;
      this.haveCycle = true;
      for (var index in cycle) {
        const conditions = [
          "total_cycle",
          "cycle_additional",
          "plant",
          "transport_additional_cost",
          "transport_maximum_distance",
          "increse_route_truck_empty_return",
          "additional_cycle",
          "return_addition",
          "send_addition"];
        const contains = conditions.some(function (el) {
          return index.includes(el)
        });
        if (contains) {
          continue;
        }
        if (Number(cycle[index]) <= 0) {
          this.haveCycle = false;
        }
      }
      this.cycleMaximumDiff.contains = false;
      this.cycleMaximumDiff.value = 0;
      this.cycleMaximumDiff.diff = 0;
      this.transportMaximumDiff.contains = false;
      this.transportMaximumDiff.value = 0;
      this.transportMaximumDiff.diff = 0;
      if (this.valid_incomplet_cycle && !this.haveCycle) {
        this.editCycle()
      }
      this.valid_incomplet_cycle = false
      if (this.cycle.total_cycle > this.cycle.cycle_additional) {
        let diff = this.cycle.total_cycle - this.cycle.cycle_additional;
        this.cycleMaximumDiff.diff = diff;
        this.cycleMaximumDiff.value = diff * this.cycle.cycle_additional_cost;
        this.cycleMaximumDiff.contains = true;
      }
      if (this.cycle.distance > this.cycle.transport_maximum_distance) {
        let diff = this.cycle.distance - this.cycle.transport_maximum_distance;
        this.transportMaximumDiff.diff = diff;
        this.transportMaximumDiff.value = diff * this.cycle.transport_additional_cost;
        this.transportMaximumDiff.contains = true;
      }
      if (this.selected) {
        await this.handleSearchAdjustment()
      }
      let selected_price = this.minimum_price
      this.cycleMinimum = this.parseValue(this.transportMaximumDiff.value) + this.parseValue(this.cycleMaximumDiff.value) + selected_price;
      this.setMinimumPrice()
    },
    parseValue(val) {
      val = val.toString().replaceAll(",", ".");
      return parseFloat(val);
    },
    percentageSlump(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2)
      this.slumpDiff.percentage = to;
      this.slumpDiff.value = val;
      this.slumpDiff.name = description;
    },
    percentageLevelResponsibility(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2)
      this.featureLevelDiff.percentage = to;
      this.featureLevelDiff.value = val;
      this.featureLevelDiff.name = description;
    },
    percentage(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2)
      this.concretedPieceDiff.percentage = to;
      this.concretedPieceDiff.value = val;
      this.concretedPieceDiff.name = description;
    },
    editCycle() {
      this.$refs.editCycle.openModal();
    },
    addMixe() {
      this.$refs.addMixe.openModal(this.contract_proposal.company_plant_id);
    },
    addPuzlAdmixture() {
      this.$refs.addPuzlAdmixture.openModal(this.selected);
    },
    savePuzlAdditions(additions) {
      const toMoney = value => {
        value = value.toString().replace(",", ".");
        return Number(value);
      };
      this.puzl_additions = additions;
      this.puzl_addition_price = 0;
      for (var item of additions) {
        this.puzl_addition_price += toMoney(item.price);
      }
      this.formulation.minimum_price.minimum_price =
        parseFloat(this.slumpDiff.value) + parseFloat(this.featureLevelDiff.value) + parseFloat(this.concretedPieceDiff.value) + parseFloat(this.cycleMinimum) + parseFloat(this.puzl_addition_price);
      this.$notify({
        type: "success",
        message: "Alteração realizada com sucesso!"
      });
    },
    async handleSetMixDesign(mix_design) {
      let loader = this.$loading.show();
      this.selected = mix_design;
      await this.handleSearchAdjustment()
      let filters = {
        company_plant_id: this.selected.plant.id,
        construction_id: this.contract_proposal.construction.id,
        contract_proposal_uuid: this.contract_proposal.uuid,
      };
      this.loadingCycle = true;
      await this.$store
        .dispatch("formulations/getCycleValues", {
          filter: filters
        })
        .then(async response => {
          let cycle = {
            ...this.cycle_values
          };
          const has_minimum_cost = response.data.has_minimum_cost
          if (!has_minimum_cost) {
            this.$notify({
              type: "warning",
              message: "A central selecionada não possui parâmetros de preço, entre em contato com o gestor da sua empresa."
            });
          }
          this.valid_incomplet_cycle = true
          cycle["plant"] = this.selected.plant;
          cycle["average_volume_per_bt"] = this.contract_proposal.average_volume_per_bt;
          this.$refs.editCycle.mountData(cycle)
          this.loadingCycle = false;
          this.$notify({
            type: "success",
            message: "Traço vinculado com sucesso"
          });
          this.cycleMaximumDiff = {
            diff: 0,
            value: 0,
            contains: false
          };
          this.transportMaximumDiff = {
            diff: 0,
            value: 0,
            contains: false
          };
          let date = new Date();
          let dateAt = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
          this.formulation.minimum_price.date_at = dateAt;
          this.formulation = {
            minimum_price: {
              slump_flow_id: null,
              delivery_type: null,
              concrete_removal: 0,
              minimum_price: 0,
              status: 1,
              expected_volume: "",
              value: null,
              observation: "",
              date_at: dateAt,
              concreted_piece_ids: []
            }
          };
          this.concretedPieceDiff = {
            percentage: null,
            value: 0,
            contains: false,
            name: ""
          };
          this.$store.state.formulations.concreted_piece = {};
          this.puzl_additions = [];
          this.setMinimumPrice(false)
          loader.hide();
        }).catch(error => {
          this.loadingCycle = false;
          loader.hide();
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.selected = null
        });
    },
    handleRemoveSelected() {
      this.selected = null;
      this.$notify({
        type: "success",
        message: "Solicitação realizada com sucesso"
      });
    }
  },
  mounted() {
    this.haveCycle = false;
    this.$store.dispatch('generalSetting/show')
    this.$forceUpdate();
    this.$store.dispatch("mixConcretePiece/fetchItems").then(() => {
      this.loadingConcretePiece = false;
    });
    this.loadingFeatureLevels = true
    this.$store.dispatch("mixConcretePiece/getFeatureLevels").then(() => {
      this.loadingFeatureLevels = false;
    });
    this.loadingSlumpFlow = true
    this.$store.dispatch('mixSlumpFlow/fetchItems').then(response => {
      /**
       * não utilizado store, para não ocorrer intereferencia. Método já está sendo usado no modal de adição do traço.
       */
      this.slump_flows = response.data
      this.loadingSlumpFlow = false
    })
    this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(() => {
      this.loading = false;
      this.$refs.addMixe.openModal(this.contract_proposal.company_plant_id);
    });
    let data = new Date();
    let dateAt = data.getFullYear() + "-" + (data.getMonth() + 1) + "-" + data.getDate();
    this.formulation.minimum_price.date_at = dateAt;
    const self = this;
    this.$watch("formulation.minimum_price.value", function (val) {
      self.setDeliveryType()
      if (val == "0,00") {
        return (self.isBiggerPrice = null);
      }
      val = val.toString().replaceAll(".", "");
      val = val.toString().replace(",", ".");
      if (Number(val) >= self.formulation.minimum_price.minimum_price) {
        self.isBiggerPrice = "border-success";
      } else {
        self.isBiggerPrice = "border-danger";
      }
    });
    this.$watch("formulation.minimum_price.minimum_price", function (val) {
      if (self.formulation.minimum_price.value === "0,00") {
        return (self.isBiggerPrice = null);
      }
      let value = self.formulation.minimum_price.value;
      value = value.toString().replaceAll(".", "");
      value = value.toString().replace(",", ".");
      if (parseFloat(val) <= parseFloat(value)) {
        self.isBiggerPrice = "border-success";
      } else {
        self.isBiggerPrice = "border-danger";
      }
    });
    this.$store.dispatch('applicationLevelGuideline/fetchItems');
  }
};
</script>
