<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter"
                    @clearFilter="clearFilter()"
                    @handleFilterDate="handleFilterDate"
                    @fetch="init"
                    :filter="filter"
                    :showSlotDates="false"
                    :showSlotBookmark="false"
      >
        <!-- Filtros de ordenacao -->
        <template slot="order-by">
          <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
            <h6><a @click.prevent="handleFilterOrderBy('created.desc')" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="filter.order_by === 'created.desc' && 'text-primary'" class="fa-solid fa-angles-down"
                 style="font-size: 12px;margin-right: 5px"></i>
              DO MAIS NOVO PARA O MAIS VELHO</a></h6>
            <hr class="mb-1 mt-n2">
            <h6><a @click.prevent="handleFilterOrderBy('created.asc')" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="filter.order_by === 'created.asc' && 'text-primary'" class="fa-solid fa-angles-up"
                 style="font-size: 12px;margin-right: 5px"></i> DO MAIS VELHO PARA O MAIS NOVO</a></h6>
            <hr class="mb-1 mt-n2">
            <h6><a @click.prevent="handleFilterOrderBy('date_at.asc')" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="filter.order_by === 'date_at.asc' && 'text-primary'" class="fa-solid fa-angles-down"
                 style="font-size: 12px;margin-right: 5px"></i> DATA DE COMPETÊNCIA: DA MAIS NOVA PARA A MAIS VELHA</a>
            </h6>
            <hr class="mb-1 mt-n2">
            <h6><a @click.prevent="handleFilterOrderBy('date_at.desc')" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="filter.order_by === 'date_at.desc' && 'text-primary'" class="fa-solid fa-angles-up"
                 style="font-size: 12px;margin-right: 5px"></i> DATA DE COMPETÊNCIA: DA MAIS VELHA PARA A MAIS NOVA</a>
            </h6>
            <hr class="mb-1 mt-n2">
            <h6><a @click.prevent="handleFilterOrderBy('value.desc')" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="filter.order_by === 'value.desc' && 'text-primary'" class="fa-solid fa-angles-down"
                 style="font-size: 12px;margin-right: 5px"></i> VALOR: DO MAIOR PARA O MENOS</a>
            </h6>
            <hr class="mb-1 mt-n2">
            <h6><a @click.prevent="handleFilterOrderBy('value.asc')" href="#" class="font-weight-400"
                   style="font-size: 12px">
              <i :class="filter.order_by === 'value.asc' && 'text-primary'" class="fa-solid fa-angles-up"
                 style="font-size: 12px;margin-right: 5px"></i> VALOR: DO MENOS PARA O MAIOR</a>
            </h6>
            <a href="#" slot="reference">
              <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
            </a>
          </el-popover>
        </template>
        <!-- Filtro avançado VALOR/STATUS -->
        <template slot="data">
          <div class="row pr-3 mt-2 pl-3">
            <div class="col-12 text-left">
              <label class="form-control-label">
                Valor do pagamento
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <base-input v-mask="['#,##', '##,##', '###,##', '#,###.##', '##,###.##', '###,###.##']"
                              inputmode="numeric"
                              @change="setRange()" v-model="filter.range_value.min"
                              input-group-classes="input-group-sm" placeholder="de" size="sm">
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-6 pl-1">
                  <base-input v-mask="['#,##', '##,##', '###,##', '#,###.##', '##,###.##', '###,###.##']"
                              inputmode="numeric" @change="setRange()" v-model="filter.range_value.max"
                              input-group-classes="input-group-sm" placeholder="até" size="sm">
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="status">
          <div class="col-12">
            <base-button-hoverable size="sm" :active="filter.is_billed" @click="getBilleds()"
                                   type="success" icon="check" platform="ios">
              Faturado
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1 mb-4">
            <base-button-hoverable size="sm" :active="filter.is_pending" @click="getPendings()"
                                   type="warning" icon="hourglass" platform="ios">
              Em aberto
            </base-button-hoverable>
          </div>
        </template>
      </multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!$_detacheds.length && !loadingSkeleton"></PuzlEmptyData>
      <div class="row card-wrapper" v-if="$_detacheds.length && !loadingSkeleton" style="margin-top: 65px;">
        <!-- CARDS -->
        <div class="col-lg-4 col-md-6" v-for="(item, index) in $_detacheds" :key="index"
             style="padding: 0 10px !important">
          <div class="row align-items-center card mx-1">
            <div class="w-100 d-flex justify-content-between">
              <div style="color: #2B2D32">
                <div class="ml-4 mb-1 mt-3 pointer"
                     v-if="!item.billed"
                     @click.prevent="handleApprove(item)"
                     style="width: 120px;border-radius: 5px; font-size: 0.8rem; text-align: center; font-weight: 500 !important; padding: 0.2rem 0.3rem"
                     :style="item.status ? 'color: rgb(72 176 122); background: rgb(171 210 167 / 20%)' : 'color: #F2B532; background: hsl(41, 88%, 57%, 20%)'">
                  {{ item.status ? 'APROVADO' : 'PENDENTE' }}
                </div>
                <div
                  style="font-weight: 500 !important; font-size: 14px; line-height: 16px; color: hsl(223, 8%, 18%, 75%);"
                  class="font-weight-bold ml-4 mt-3">
                  {{ item.description }}
                </div>
                <div class="d-flex">
                  <div style="font-weight: 500 !important; font-size: 30px; line-height: 24px; color: #2B2D32;"
                       class="font-weight-bold ml-4 mb-2 mt-3">
                    {{ (item.value * item.quantity) | currency }}
                  </div>
                  <div class="ml-2 mb-1 mt-3 align-self-center"
                       style="border-radius: 5px; font-size: 0.8rem; text-align: center; font-weight: 500 !important; padding: 0.2rem 0.3rem"
                       :style="item.billed ? 'color: #1A70B7; background: hsl(207, 75%, 41%, 20%)' : 'color: rgb(128 126 121); background: rgb(157 151 138 / 20%)'">
                    <i v-if="item.billed" class="fa-regular fa-circle-check"></i>
                    <i v-else class="fa-solid fa-circle-notch"></i>
                    {{ item.billed ? 'Faturado' : 'Em aberto' }}
                  </div>
                </div>
                <div class="w-100 d-flex justify-content-between">
                  <div class="ml-4" style="font-size: 14px; font-weight: 400 !important;" v-if="item.user_name">
                    {{ item.date_at | parseDate("DD MMM YYYY") }}
                  </div>
                </div>
              </div>
              <div>
                <!-- Menu engrenagem -->
                <base-dropdown menuOnRight v-if="!item.billed" style="margin-left: 3.7rem;">
                  <div slot="title-container"
                       class="dropdown-toggle rounded mr-4 mt-3">
                    <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
                  </div>
                  <!-- <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);">
                    <a class="dropdown-item d-flex" @click.prevent="handleDelete(item.id)" style="font-size: 12px;">
                      <img width="15" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new"/>
                      Editar
                    </a>
                  </div> -->
                  <div>
                    <a class="dropdown-item d-flex" @click.prevent="handleDelete(item.id)" style="font-size: 12px;">
                      <img width="15" src="https://img.icons8.com/ios/100/db4539/delete--v1.png" alt="delete--v1"/>Deletar
                    </a>
                  </div>
                </base-dropdown>
                <!-- Icones informativos -->
                <div class="mr-4 d-flex" :style="item.billed ? 'margin-top: 4.8rem !important' : ''"
                     style="margin-top: 27px">
                  <div>
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div>
                        <div style="font-weight: 500;" class="mb-2">
                          <img class="mr-2" src="/img/icons/price-tag-usd-green.png" width="20px" height="20px"/>
                          <span style="font-size: 12px">INFO PREÇO</span>
                        </div>
                        <div style="font-size: 12px">
                          QUANTIDADE: {{ item.quantity }}
                        </div>
                        <div style="font-size: 12px">
                          VALOR POR UNIDADE: {{ item.value | currency }}
                        </div>
                      </div>
                      <div slot="reference">
                        <img class="icon-img" width="25px" src="/img/icons/info.png"/>
                      </div>
                    </el-popover>
                  </div>
                  <div style="margin: 0 11px">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div>
                        <div style="font-size: 12px">Usuario: {{ item.user_name }}</div>
                        <div style="font-size: 12px" class="mt-1">Data: {{ item.created_at | parseDate }}</div>
                      </div>
                      <div slot="reference">
                        <img class="icon-img" width="25" src="/img/icons/icons8/ios/user-male-circle--v1.png">
                      </div>
                    </el-popover>
                  </div>
                  <div>
                    <el-popover trigger="click" placement="bottom" class="p-0" style="font-size: 12px !important;">
                      <div>
                        <div>
                          <b style="font-size: 12px">CENTRAL: </b>
                          <span style="font-size: 12px">{{ item.plant_name }}</span>
                        </div>
                      </div>
                      <div slot="reference">
                        <img class="icon-img" width="25px" src="/img/icons/icons8/ios/chemical-plant-v1.png"/>
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 pr-4 mb-0 pl-4 mt-2">
              <div style="border-top: 1px solid hsl(240, 1%, 38%, 15%); margin-bottom: 5px;"/>
            </div>
            <div class="w-100 d-flex justify-content-start mt-1" style="color: #2B2D32">
              <div>
                <div class="ml-4 mt-1" style="font-size: 16px; font-weight: 600 !important;">
                  {{ item.code }}
                </div>
                <div>
                  <div class="ml-4 mt-1" style="font-weight: 400 !important; font-size: 14px">
                    {{ item.customer_name }}
                  </div>
                </div>
                <div>
                  <div class="ml-4 mb-4 mt-1" style="font-weight: 400 !important; font-size: 12px">
                    {{ item.construction_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import {debounce} from "@/plugins";
import moment from "moment";

export default {
  name: "ListTutorial",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    BaseButtonHoverable,
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {
        range_value: {
          min: null,
          max: null
        },
      },
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: moment(new Date()).format('YYYY-MM-DD'),
        end: moment(new Date()).format('YYYY-MM-DD'),
      },
    };
  },
  computed: {
    ...mapGetters({
      $_detacheds: "detached/fetch",
      $_financial_limit: "permissionFinancialLimit/show",
    }),
  },
  async mounted() {
    this.$refs['multi-filter']._self.$forceUpdate();
    this.range = this.defaultRange;
    await this.fetchFinancialLimits(this.$user.id)
    this.init({});
  },
  methods: {
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
    /**
     * @param {object} filter
     */
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start()
      this.filter.range = this.range;
      this.$store
        .dispatch("detached/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.$Progress.finish()
          this.resolveCursor(response)
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish()
        });
    },
    /**
     * @param {object} filter
     */
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    handleFilterOrderBy(type) {
      this.filter.order_by = type
      this.init({})
    },
    getBilleds() {
      if (this.filter.is_pending) {
        this.filter.is_pending = null;
      }
      this.filter.is_billed = !this.filter.is_billed ? 1 : null;
      this.init({});
    },
    getPendings() {
      if (this.filter.is_billed) {
        this.filter.is_billed = null;
      }
      this.filter.is_pending = !this.filter.is_pending ? 1 : null;
      this.init({});
    },
    handleApprove(item) {
      if (!this.$_financial_limit.invoice_can_add_single) {
        return this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para aprovar avulso'
        });
      }
      this.$Swal.confirmAction('Deseja realmente aprovar o avulso?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            let loading = this.$loading.show()
            this.$store.dispatch('detached/approve', {id: item.id, status: !item.status}).then(response => {
              item.status = !item.status
              this.$notify({
                type: 'success',
                message: response.message
              });
            }).finally(() => loading.hide())
          }
        }).catch(() => this.$Progress.finish())
    },
    clearFilter() {
      this.range = this.filter.range;
      this.filter = {range_value: {min: null, max: null}}
      this.init({});
    },
    setRange: debounce(function () {
      this.init({})
    }, 100),
    /**
     * @param {number} id
     */
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('detached/destroy', id)
            .then((response) => {
              this.$notify({
                type: 'success',
                message: response.message
              });
            })
            .catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
            })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish());
    }
  },
};
</script>

<style>
.icon-img {
  transition: transform 0.3s;
}

.icon-img:hover {
  cursor: pointer;
  transform: translateY(-3px);
}
</style>
