<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important; color: #606062 !important" class="text-primary">
            <img height="30px" src="/img/icons/settings.png" class="mr-2 mb-1" />
            Usuários
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button @click.prevent="handleCreateModal" type="success" block>
                <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1" />
                NOVO
              </base-button>
            </div>
            <div class="mb-md-0 mb-2 col-md-3 px-1" :class="{'d-none': !$hasPermission('configuration.user-permissions.index.view')}">
              <base-button @click.prevent="$router.push('/configuration/user/permission')"  type="primary" block>
                <img src="/img/icons/security-shield.png" width="22px" height="22px" />
                Permissões
              </base-button>
            </div>
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button @click.prevent="$router.push('/configuration/user/user-alert')"  type="danger" block>
                <img src="/img/icons/icons8/ios/error.png" width="22px" height="22px" />
                Alertas
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <ListUser v-bind:added="this.added" />
    <ModalCreateUser ref="modalCreateUser" />
  </div>
</template>

<script>
import ListUser from './Shared/_List';
import ModalCreateUser from './Shared/_ModalCreate';

export default {
  name: "IndexUser",
  components: {
    ListUser,
    ModalCreateUser,
  },
  data() {
    return {
      added: false,
    }
  },
  methods: {
    handleCreateModal() {
      this.$refs.modalCreateUser.handleCreateModal();
    },
  },
};
</script>

<style scoped>

</style>
