/*jshint esversion: 9 */
import globalFilter from "./globalFilter";
const cursorPaginate = {
  mixins: [globalFilter],
  data() {
    return {
      paginate: {
        hasMore: false,
        nextUrl: null,
      },
      loading: false,
    }
  },
  methods: {
    /** inicia o cursor paginate **/
    startCursor(filter = null, isAccumulateItems = null) {
      if (isAccumulateItems === null) {
        if (filter === {}) {
          this.loadingSkeleton = true
        }
        /** inicia o loader para nao entrar em loop **/
        this.loading = true
        /** caso tenha filtro, ele ignora a proxima página e volta pra primeira pagina **/
        if (filter) {
          this.filter = {...this.filter, ...filter}
          this.loadingSkeleton = true
          this.paginate.nextUrl = null
          if (this.range) {
            if (!this.range.hasOwnProperty('default_filterable')) {
              this.range.default_filterable = true
            }
            if (this.range.default_filterable) {
              this.filter.range = this.range
            } else {
              delete this.filter.range
            }
          }
        }  
        return true;
      }
      filter.page = isAccumulateItems ? filter.page : "";
      filter.next_page = filter.page;
      const isEndOfPages = isAccumulateItems && !filter.page;
      return !isEndOfPages;
    },
    /** pega a resposta da requisicao e atualiza a proxima pagina **/
    resolveCursor(res = null, filter = null) {
      if (filter === null) {
        if (!res) {
          this.loadingSkeleton = false
        }
        this.loading = false
        this.loadingSkeleton = false
        this.paginate.hasMore = Boolean(res.next_page_url) || Boolean(res.has_more) || Boolean(res.data?.has_next_page)
        this.paginate.nextUrl = res.next_page_url || res.next_page || res.data?.next_page
        this.checkHeightCompatibility();
        return;
      }
      filter.page = res.data.next_page || res.next_page_url || res.next_page || res.data?.next_page;
    },
    onScroll() {
      if (this.loading || !this.paginate.hasMore) {
        return
      }
      /** valida se o usuario está rolando o scroll e busca mais dados na api **/
      const screenHeight = window.innerHeight;
      const scrolled = window.pageYOffset;
      const scrollHeight = document.body.scrollHeight;
      let limit = 600
      /** let limit é definido para realizar a consulta quando o scroll chega ao meio da tela**/
      if (
        scrollHeight - (screenHeight + scrolled) < limit
      ) {
        this.init()
      }
    },
    checkHeightCompatibility() {
      if (this.loading || !this.paginate.hasMore) {
        return
      }
      let self = this;
      setTimeout(function(){
        let screenHeight = window.innerHeight;
        let scrollHeight = document.body.scrollHeight;
        /** Caso ainda tenha espaço na tela, em caso de telas grandes. */
        if(screenHeight > scrollHeight) {
          self.init();
        }
      }, 100);
    }
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
}
export default cursorPaginate
