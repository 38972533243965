<template>
  <div>
    <modal @close="close" size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div>
        <div class="row" v-if="show">
          <div class="col-md-12">
            <h1 class="text-center">
              <i class="fas fa-exclamation-circle fa-2x text-orange"></i>
            </h1>
            <h5 class="text-center">
              <small>{{ selected.log }}</small>
            </h5>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="close()">
          Cancelar
        </base-button>
        <base-button
          @click.prevent="save()"
          type="success"
          native-type="submit"
          :loading="isLoading"
        >
          Emitir
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ModalAlterStatus",
  props: ['show', 'selected'],
  data() {
    return {
      title: 'Reemitir nota fiscal eletrônica',
      isLoading: false,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      this.$Progress.start();
      this.isLoading = true
      this.$Swal.confirmAction('Deseja realmente reemitir a Nota selecionada?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$store.dispatch('xmlIssuedes/postReSendXml', this.selected.key)
              .then(() => {
                this.isLoading = false
                if (this.$parent.updateItem) {
                  this.$parent.updateItem(this.selected.id)
                } else {
                  this.$parent.init()
                }
                this.$notify({type: 'success', message: 'Solicitação de emissão enviada com sucesso!'});
                this.close()
              }).catch((error) => {
              this.$notify({type: error.data.error_type, message: error.data.message});
              this.isLoading = false
            })
          } else {
            this.isLoading = false
            this.$Progress.finish()
          }
        }).catch(() => {
        this.isLoading = false
        this.$Progress.finish()
      })
    }
  },
};
</script>

<style>
</style>
