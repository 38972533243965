import store from "../../../../shared/libraries/store";
import { stockLocationStateKeys } from "./stockLocationStoreStateKeys";
import { StockLocationListFilterType, StockLocationListPagerType, StockLocationViewType } from "../types";
import { SearchBarFilterType } from "../../../../components/AppGlobal";

/**
 * @param {StockLocationViewType} item
 * @returns {StockLocationViewType}
 */
const setItem = (item) => store.commit(stockLocationStateKeys.ITEM, item);

/**
 * @param {StockLocationListPagerType} listed
 * @returns {StockLocationListPagerType}
 */
const setListed = (listed) => store.commit(stockLocationStateKeys.LISTED, listed);

/**
 * @param {boolean} isListing
 * @returns {boolean}
 */
const setIsListing = (isListing) => store.commit(stockLocationStateKeys.IS_LISTING, isListing);

/**
 * @param {boolean} isFinding
 * @returns {boolean}
 */
const setIsFinding = (isFinding) => store.commit(stockLocationStateKeys.IS_FINDING, isFinding);

/**
 * @param {boolean} isSaving
 * @returns {boolean}
 */
const setIsSaving = (isSaving) => store.commit(stockLocationStateKeys.IS_SAVING, isSaving);

/**
 * @param {boolean} isRemoving
 * @returns {boolean}
 */
const setIsRemoving = (isRemoving) => store.commit(stockLocationStateKeys.IS_REMOVING, isRemoving);

/**
 * @param {StockLocationViewType} created
 * @returns {StockLocationViewType}
 */
const setNewItemInList = (created) => store.commitNewItemToList(stockLocationStateKeys, created);

/**
 * @param {StockLocationListPagerType} listed
 * @returns {StockLocationListPagerType}
 */
const setListMerge = (listed) => store.commitListMerge(stockLocationStateKeys, listed);

/**
 * @param {number|string} id
 */
const setRemovedItem = (id) => store.commitRemovedItem(stockLocationStateKeys, id);

/**
 * @param {StockLocationViewType} updated
 * @returns {StockLocationViewType}
 */
const setUpdatedItem = (updated) => store.commitUpdatedItem(stockLocationStateKeys, updated);

/** 
 * @param {{ 
*   searchBarFilter: SearchBarFilterType, 
*   filter: StockLocationListFilterType
* }} filters
*/
const setPageState = (filters) => store.commit(stockLocationStateKeys.PAGE_STATES, filters);

/** Resetar chaves de estado */
const resetStates = () => {
  store.resetStates(Object.values(stockLocationStateKeys));
}

export default {
  setItem,
  setListed,
  setIsListing,
  setIsFinding,
  setIsSaving,
  setIsRemoving,
  setNewItemInList,
  setListMerge,
  setRemovedItem,
  setUpdatedItem,
  setPageState,
  resetStates,
};
