<template>
  <modal size="lg" :show.sync="modal" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img src="/img/icons/icons8/ios/menu-squared-2-white.png" height="25" class="mr-2" />
        Lista de itens
      </h5>
    </template>
    <div class="p-3">
      <!-- INFORMAÇOES -->
      <div class="d-flex align-items-center">
          <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared"/>
          <span style="font-size: 18px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">Informação</span>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div class="">
          <div style="font-size: 18px ;font-weight: 500; color: #2B2D32;">puzl-003</div>
          <div style="font-size: 14px ;font-weight: 400; color: #2B2D32;">manutenção</div>
          <div class="mt-1" style="font-size: 12px ;font-weight: 400; color: #606062;">Produtos</div>
          <div class="mt-2" style="font-size: 12px ;font-weight: 400; color: #606062;">7 Itens</div>
        </div>
        <div class="d-flex">
          <div class="mr-3">
            <el-popover trigger="click" placement="bottom" class="p-0">
              <div style="font-size: 12px">xxxxxxx</div>
              <span slot="reference">
                <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26">
              </span>
            </el-popover>
          </div>
          <div class="mr-2">
            <el-popover trigger="click" placement="bottom" class="p-0">
              <div style="font-size: 12px">xxxxxxx</div>
              <span slot="reference">
                <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="26"/>
              </span>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="dropdown-divider my-4" style="width: -webkit-fill-available;" />
      <div class="d-flex align-items-center">
        <img width="25" src="/img/icons/big-parcel-primary.png" class="mr-2"/>
        <span  style="font-size: 16px ;font-weight: 500; color: #2B2D32;">Itens</span>
      </div>

      <!-- TABELA -->
      <div class="mt-3 d-flex" style="overflow-x: scroll" >
        <div>
          <table class="table resume-table table-custom-shaded mb-1">
            <thead>
              <tr>
                <th class="text-left new-default-black-font">Descrição Do Produto</th>
                <th class="text-left new-default-black-font">Qto</th>
                <th class="text-left new-default-black-font">Qto</th>
                <th class="text-left new-default-black-font">Última Compra</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <!-- Status -->
                <td class="">{{ item.status }}</td>
                <td class="font-weight-500">{{ item.quantidade }}</td>
                <td class="">{{ item.unidade }}</td>
                <td  class="">
                  <span style="font-size: 14px !important; color: #2B2D32; font-weight: 500">
                    R$ {{ item.preco }}
                  </span>
                  <el-popover class="p-0" placement="right" trigger="click">
                    <div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="20">
                          <span class="font-weight-500">Última Compra</span>
                        </div>
                        <div class="d-flex align-items-center" style="font-size: 11px;">
                          <img
                            src="/img/icons/calendar-black.png"
                            width="15px"
                            class="mr-2"
                          />
                          {{ item.dataCompra }}
                        </div>
                      </div>
                    </div>
                    <div class="my-2" style="display: grid !important">
                      <span>{{ item.fornecedor }}</span>
                      <span>{{ item.cnpj }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div style="display: grid !important">
                        <span style="font-size: 12px;" class="fs-1">Quantidade</span>
                        <span style="font-size: 18px; line-height: 12px;" class="font-weight-500">{{ item.quantidadeDetalhes }}</span>
                      </div>
                      <div style="display: grid !important">
                        <span style="font-size: 12px;" class="fs-1">Preço Unit.</span>
                        <span style="font-size: 18px; line-height: 12px;" class="font-weight-500">{{ item.precoDetalhes }}</span>
                      </div>
                    </div>
                    <base-button
                      outline slot="reference" size="sm" type="secundary"
                      class="p-0 m-0 shadow-none text-indigo"
                    >
                    <img src="/img/icons/icons8/ios/info-circle_warning.png" width="18">
                    </base-button>
                  </el-popover>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between p-1 px-3 font-weight-400 mb-2"
            style="background-color: #c6dbed; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; margin: 0 1.5px; color: #2B2D32;">
            <div>Total</div>
          </div>
          <div class="mb-2"
            style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040; margin: 0 1px; color: #2B2D32;">
            <div class="p-1 px-3">Desconto</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Ipi</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Icms</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Valor Líquido</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Condições De Pagamento</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Prazo De Entrega</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Validade Da Cotação</div>
          </div>
        </div>

        <div style="width: -webkit-fill-available;">
          <table class="table table-custom-shaded mb-1">
            <thead>
              <tr>
                <th class="text-left new-default-black-font">Preço Unit.</th>
                <th class="text-left new-default-black-font">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <!-- Status -->
                <td style="color: #606062">
                  <img class="mr-1" width="15" src="/img/icons/icons8/ios/arrow-down-success.png" alt="copy-2" />
                  {{ item.precoDetalhes }}
                </td>
                <td style="color: #2b2d32; font-weight: 700 !important">
                  {{ item.precoDetalhes }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between p-1 px-3 font-weight-500"
            style="background-color: #c6dbed; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; margin: 0 1.5px; color: #2b2d32;">
            <div>R$ 7.593,40</div>
          </div>
          <div class="my-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040;
                background-color: #f2f2f2; color: #2B2D32; margin: 0 1px;">
                <div class="p-1 px-3">R$ 0,00</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">R$ 0,00</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">R$ 0,00</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">R$ 9.128,08</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">30/45/60</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Imediato</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">7 Dias</div>
              </div>
        </div>
      </div>
      <!-- Botoes de aprovação -->
      <div class="d-flex justify-content-end mb-2 mt-3 text-center">
        <div class="aprovation-button bg-primary mr-2">
          <img class="mr-2" width="20" src="/img/icons/thumb-up--v1-white.png">
          Aprovar
        </div>
        <div class="aprovation-button bg-danger mr-0">
          <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumbs-down.png">
          Negar
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ModalListItemsApproval",
  components: {
  },
  data() {
    return {
      modal: false,
      items: [
        { status: 'PAPEL A4', quantidade: '100', unidade: 'UN', preco: '15,65', dataCompra: '25 SET 2023', fornecedor: 'D P BARBOSA MAQ E FERRAMENTAS LTDA', cnpj: '08.732.993/0001-06', quantidadeDetalhes: '15,000 UN', precoDetalhes: 'R$ 7,50' },
        { status: 'PAPEL B5', quantidade: '50', unidade: 'CX', preco: '25,00', dataCompra: '28 SET 2023', fornecedor: 'COSTA E FERNANDES LTDA', cnpj: '01.234.567/0001-89', quantidadeDetalhes: '10,000 CX', precoDetalhes: 'R$ 2,50' },
        { status: 'CANETA AZUL', quantidade: '200', unidade: 'UN', preco: '0,50', dataCompra: '29 SET 2023', fornecedor: 'SILVA E SOUSA COMERCIO LTDA', cnpj: '98.765.432/0001-21', quantidadeDetalhes: '20,000 UN', precoDetalhes: 'R$ 0,25' },
        { status: 'LAPIS HB', quantidade: '300', unidade: 'UN', preco: '0,30', dataCompra: '30 SET 2023', fornecedor: 'FERREIRA E FERREIRA LTDA', cnpj: '23.456.789/0001-32', quantidadeDetalhes: '30,000 UN', precoDetalhes: 'R$ 0,15' },
        { status: 'BORRACHA BRANCA', quantidade: '150', unidade: 'UN', preco: '1,20', dataCompra: '01 OUT 2023', fornecedor: 'ROCHA E ROCHA LTDA', cnpj: '45.678.912/0001-43', quantidadeDetalhes: '15,000 UN', precoDetalhes: 'R$ 0,60' },
        { status: 'CADERNO GRANDE', quantidade: '8', unidade: 'UN', preco: '10,00', dataCompra: '07 OUT 2023', fornecedor: 'CARDOSO E CARDOSO LTDA', cnpj: '67.891.234/0001-09', quantidadeDetalhes: '2,000 UN', precoDetalhes: 'R$ 5,00' },
        { status: 'ESTOJO ESCOLAR', quantidade: '15', unidade: 'UN', preco: '3,00', dataCompra: '08 OUT 2023', fornecedor: 'MENDES E MENDES LTDA', cnpj: '78.912.345/0001-10', quantidadeDetalhes: '3,000 UN', precoDetalhes: 'R$ 1,00' },
      ],
    }
  },
  methods: {
  // Modal
    handleCreateModal(data) {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
  },
}
</script>

<style scoped>
.table-custom-shaded td {
  padding: 0.6rem !important;
  font-weight: 500 !important;
  color: #2B2D32;
}
.resume-table td:last-child {
  padding: 0.55rem !important;
}
.resume-table th:last-child {
  background-color: #fceccc !important;
}
.resume-table td:last-child {
  background-color: #fceccc !important;
  color: #2B2D32 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Botoes de aprovação */
.aprovation-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 20%;
  height: 2.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  color: #ffff
}
.aprovation-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}
</style>
