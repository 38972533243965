<template>
  <div>
    <div class="container-fluid">
      <multi-filter
        :type="2"
        @clearFilter="clearFilter()"
        @handleFilterDate="handleFilterDate"
        :defaultRange="filter.range"
        @fetch="init"
      >
        <template slot="dropdown-instead-input">
          <PuzlSelect
            @change="init({})"
            :disableBoxShadow="true"
            v-model.lazy="filter.bank_account_id"
            :items="$_bank_accounts"
            class="select-xl new-default-black-font w-100"
            collapse-tags
            placeholder="Contas"
            multiple
            filterable
            :disabled="loadingBankAccounts"
          />
        </template>
        <template slot="only-status-instead-filters">
          <base-dropdown style="width: 100%" menuOnRight>
            <base-button size="sm" slot="title-container"
                type="white"
                class="text-uppercase"
                style="box-shadow: none; border: 1px solid #E8E8E8;height: 2.7rem;border-radius: 8px !important;" block>
                <img height="19" style="margin-top: -4px" src="https://img.icons8.com/ios/a7a9b5/filter--v1.png"
                    alt="filter--v1"/>
                Status
            </base-button>
            <a class="dropdown-item" @click="handleFilterAdvancedStatus(1)" href="#">
              <div>
                <img height="19" src="https://img.icons8.com/ios/100/1a70b7/double-tick.png" alt="double-tick"/>
                CONCLUÍDO
              </div>
            </a>
            <a class="dropdown-item" @click="handleFilterAdvancedStatus(0)" href="#">
              <div>
                <img height="19" src="https://img.icons8.com/ios/100/f2b532/hourglass.png" alt="hourglass"/>
                PENDENTE
              </div>
            </a>
            <a class="dropdown-item" @click="handleFilterAdvancedStatus(null)" href="#">
              <img height="19" src="https://img.icons8.com/ios/100/149e57/infinity.png" alt="infinity"/>
              TODOS
            </a>
          </base-dropdown>
        </template>
        <template slot="dates">
        </template>
        <template slot="data">
        </template>
        <template slot="status">
        </template>
        <template slot="bookmarks">
        </template>
        <template slot="order-by">
        </template>
      </multi-filter>
      <div
        class="row card-wrapper"
        v-show="loadingSkeleton"
      >
        <!-- Skeleton de Carregamento -->
        <SkeletonPuzlGrid
          v-for="(index) in 3"
          :key="index"
        ></SkeletonPuzlGrid>
      </div>

      <!-- Nenhuma informação encontrada -->
      <PuzlEmptyData v-if="!$_bank_reconciliations.length && !loadingSkeleton"></PuzlEmptyData>

      <!-- Lista de Cards -->
      <div
        class="row card-wrapper" style="margin-top: 35px;"
        v-if="$_bank_reconciliations.length && !loadingSkeleton"
      >
        <div
          class="col-lg-4"
          v-for="(item, index) in $_bank_reconciliations"
          :key="index"
        >
          <div class="card main-card">
            <div class="card-header pointer" style="padding: 0px !important;">
              <base-dropdown style="width: 100%" menuOnRight>
                <base-button size="sm" slot="title-container"
                  class="btn-header dropdown-toggle text-white text-uppercase"
                  :style="Number(item.status) == 1 ? 'background: #149E57' : 'background: #F2B532'"
                  style="opacity: 92%;color: #4f40ff block;width: 100%;border: none;">
                  {{ Number(item.status) == 0 ? "Pendente" : "Concluído" }}
                </base-button>
                <a class="dropdown-item text-uppercase">
                    <div>
                        <a v-if="Number(item.status) == 1" @click="changeStatus(item)" class="dropdown-item text-uppercase">
                          Pendente
                        </a>
                    </div>
                    <div>
                        <a v-if="Number(item.status) == 0" @click="changeStatus(item)" class="dropdown-item text-uppercase">
                          Concluído
                        </a>
                    </div>
                </a>
              </base-dropdown>
            </div>
            <div class="pt-4 pr-4 pl-4 pb-3">
              <div class="row align-items-center mb-1 mt-n2">
                <!-- Nome da Conta  -->
                <div class="col-7">
                  <div class="mb-2" style="font-size: 18px;font-weight: 500;align-items: center;display: flex;">
                    <img class="mr-2" style="width: 30px" :src="'/img/icons/banks/'+item.bank_code+ '.png'"/>
                    {{ item.account_name }}
                  </div>
                  <h3 style="line-height: 18px;" class="numeric">
                    <small class="numeric">Agência: {{ item.agency }}-{{ item.agency_digit || '#' }}</small><br>
                    <small class="numeric">Conta: {{ item.account }}-{{ item.account_digit || '#'}}</small>
                  </h3>
                </div>
                <!-- Botão de Editar e Deletar -->
                <div class="col-5 text-right float-right mt-n4">
                  <a href="#" @click="openTransaction(item)">
                    <img width="25" src="https://img.icons8.com/ios/100/2b2d32/fine-print--v1.png" alt="fine-print--v1"/>
                  </a>
                  <a
                    v-if="!(Number(item.bills_to_pay_reconciled_count) > 0 || Number(item.bills_to_receive_reconciled_count))"
                    href="#"
                    class="px-1"
                    @click.prevent="handleDelete(item)"
                  >
                    <img width="25" src="https://img.icons8.com/ios/100/db4539/delete--v1.png" alt="delete--v1"/>
                  </a>
                </div>
              </div>
              <hr class="blue-divider mt-2 mb-2">
              <!-- Banco -->
              <div class="row align-items-center mt-n1">
                <div class="col-12">
                  <span style="font-size: 12px; font-weight: 500;">
                    {{ item.start_at | parseDate('DD/MM/YYYY') }} até {{ item.end_at | parseDate('DD/MM/YYYY') }}
                  </span>
                </div>
              </div>

              <div class="row align-items-center mt-3 mb-n3">
                <!-- A Receber -->
                <div class="col-md-6 text-primary text-center" style="padding-right: 7px;">
                  <div class="card">
                    <div class="col-12">
                      <h5 class="h3 mb-0 pt-2 new-default-bankconciliation-gnumber text-success">
                        {{ item.total_receipts | currency() }}
                      </h5>
                    </div>
                    <div class="col-12 mb-2 new-default-bankconciliation-text numeric">
                      <i style="font-style: normal;" class="mr-4">Recebimentos</i>
                      <i style="font-style: normal;">{{ item.bills_to_receive_reconciled_count }}</i>/<i style="font-style: normal;" class="text-success">{{ item.bills_to_receive_count }} </i>
                    </div>
                  </div>
                </div>
                <!-- A Pagar -->
                <div class="col-md-6 text-primary text-center" style="padding-left: 7px;">
                  <div class="card">
                    <div class="col-12">
                      <h5 class="h3 pt-2 new-default-bankconciliation-gnumber mb-0 text-danger">
                        {{ item.total_payments | currency() }}
                      </h5>
                    </div>
                    <div class="col-12 mb-2 new-default-bankconciliation-text numeric">
                      <i style="font-style: normal;" class="mr-4">Pagamentos</i>
                      <i style="font-style: normal;">{{ item.bills_to_pay_reconciled_count }}</i>/<i style="font-style: normal;" class="text-danger">{{ item.bills_to_pay_count }} </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid"
import PuzlEmptyData from "@/components/PuzlEmptyData"
import MultiFilter from "@/components/Utils/MultiFilterV3";
import PuzlSelect from "@/components/PuzlSelect";
import InputDatePicker from "@/components/InputDatePicker";

const filters = {
  global: [],
  order_by: null,
}

export default {
  name: "List",
  components: {
    InputDatePicker,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    MultiFilter,
    PuzlSelect
  },
  data() {
    return {
      loadingSkeleton: true,
      loadingBankAccounts: false,
      filter: {},
      defaultRange: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
    }
  },
  beforeMount() {
    this.loadingBankAccounts = false;
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then((response) => {
      this.loadingBankAccounts = false;
    });

    if(this.$route.params.filters){
      this.filter = this.$route.params.filters;
    } else {
      this.filter = {...filters};
      this.filter.range = this.defaultRange
      this.filter.status = null;
    }
    this.init()
  },
  computed: {
    ...mapGetters({
      '$_bank_reconciliations': 'bankReconciliation/fetch',
      $_bank_accounts: "bankAccount/fetch",
    }),
  },
  methods: {
    handleFilterDate(filter) {
      this.filter.range = filter
      this.init({})
    },
    handleFilterAdvancedStatus(filter) {
      this.filter.status = filter
      this.init({})
    },
    clearFilter() {
      this.filter = {
        global: [],
        range: this.defaultRange,
        status: null,
        order_by: null,
      }
      this.init({})
    },
    init() {
      this.$Progress.start()
      this.loadingSkeleton = true
      this.$store.dispatch('bankReconciliation/fetchItems', {
        filter: this.filter
      })
        .then(() => {
          this.$Progress.finish()
          this.loadingSkeleton = false
        })
        .catch(() => {
          this.$Progress.finish()
          this.loadingSkeleton = false
        })
    },
    handleDelete(item) {
      if (Number(item.bills_to_pay_reconciled_count) > 0
        || Number(item.bills_to_receive_reconciled_count)) {
        return this.$notify({
          type: 'warning',
          message: 'Não é possível remover esse extrato! há transações conciliadas.'
        })
      }
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start()
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          })
          this.$store.dispatch("bankReconciliation/destroy", item.id).then((response) => {
            this.$notify({
              type: response.error_type,
              message: response.message
            })
            this.$Progress.finish()
          }).catch((error) => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          })
        }
      }).catch(() => this.$Progress.finish())
    },
    openTransaction(item) {
      this.$router.push({
        name: 'cash_flow.bank_reconciliation.transaction.view',
        params: {
          bank_reconciliation_id: item.id,
          filters: this.filter
        },
      })
    },
    changeStatus(item){
      this.$Progress.start()
      let loader = this.$loading.show()
      const params = {
        id: item.id,
        status: Number(item.status) === 0
          ? 1
          : 0,
      }
      this.$store.dispatch('bankReconciliation/changeStatus', params)
        .then((response) => {
          item.status = params.status;
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          loader.hide()
          this.$Progress.finish()
        }).catch(error => {
          let errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
          loader.hide()
          this.$Progress.finish()
        })
    },
  },
}
</script>

<style scoped>
.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}
.new-default-bankconciliation-text {
  white-space: nowrap;
  color: #2B2D32;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.new-default-bankconciliation-text i {
  font-size: 11px !important;
}

.new-default-bankconciliation-gnumber{
  font-weight: 700 !important;
  font-size: 15px;
  font-family: 'Quicksand', sans-serif
}
.new-default-black {
  color: #2B2D32;
}

.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}
.card {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.dropdown {
  width: 100%;
  display: inline-block;
  position: relative;
}
.dropdown-content {
  z-index: 1;
  background-color: white;
  display: block;
  position: absolute;
  width: 100%;
  overflow: auto;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
  overflow: hidden;
  border-radius: 10px;
}

.dropdown-content a {
  display: block;
  color: #000000;
  padding: 5px;
  text-decoration: none;
  text-align: left;
  padding-left: 15px;
  font-weight: 450;
  border-bottom: 0.09px solid #e3e3e3;
}
.dropdown-content a img {
  margin-right: 10px;
}
.dropdown-content a:hover {
  background-color: #eeeeee;
}

.dropdown-ordenar {
  display: inline-block;
  position: relative;
  min-width: 340px;
}

.dropdown-ordenar button {
  height: auto;
  background: transparent;
  box-shadow: none;
  border: none;
  text-align: right;
  padding-right: 0;
  float: right;
  width: auto;
}

.dropdown-ordenar button:active {
  background-color: transparent !important;
  box-shadow: none !important;
}
.dropdown-ordenar-content {
  z-index: 1;
  background-color: white;
  display: none;
  position: absolute;
  width: 100%;
  overflow: auto;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
  overflow: hidden;
  border-radius: 10px;
}
.dropdown-ordenar button:hover + .dropdown-ordenar-content {
  display: block;
}
.dropdown-ordenar-content:hover {
  display: block;
}
.dropdown-ordenar-content a {
  display: block;
  color: #000000;
  padding: 10px;
  text-decoration: none;
  text-align: left;
  padding-left: 15px;
  font-weight: 450;
  border-bottom: 0.09px solid #e3e3e3;
}

.dropdown-ordenar-content a div {
  font-size: 11px;
}
.dropdown-ordenar-content a img {
  margin-right: 10px;
}
.dropdown-ordenar-content a:hover {
  background-color: #eeeeee;
}
</style>
