import DateBase from "../shared/libraries/date/Base/DateBase";
import { DATE_ENUM, FORMAT, DURATION_INPUT, UNIT_OF_TIME } from "../shared/libraries/date/Enums";
import DateFactory from "../shared/libraries/date/Factory/DateFactory";

/**
 * Fábrica para manipular datas.
 *
 * @param {Date|string|number|null} date - A data a ser utilizada pelo adaptador.
 * @param {number} adapter - O adaptador a ser utilizado.
 * @returns {DateBase} A instância do adaptador selecionado.
 */
const make = (date = null, adapter = DATE_ENUM.MOMENT) => {
  return DateFactory.make(date, adapter);
}

export default {
  make,
  DATE_ENUM,
  FORMAT,
  UNIT_OF_TIME,
  DURATION_INPUT,
};
