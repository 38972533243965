<template>
  <div>
    <AppFixedPageTitle
      title="Filiais"
      icon="/img/icons/icons8/ios/settings-3--v1.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click="handleModalCreate"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick" />
    <div class="mt-2">
      <div class="container-fluid">
        <div class="row card-wrapper " v-show="loadingSkeleton">
          <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
        </div>
        <list-issuer-document ref="listIssuerDocument"/>
      </div>
      <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
  </div>
</template>

<script>
import {
  AppSearchBar,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppTabSelect,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  TabSelectItemType,
  AppViewTrigger
} from "../../../../../components/AppGlobal";
import BackButton from "@/components/Utils/BackButtonV2";
import ListIssuerDocument from './Shared/_ListIssuerDocument'
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import { PageHeader, PageHeaderButton } from "@/components";
import { IssuerDocumentListFilterType, initIssuerDocumentListFilterType } from "./types";
import { IssuerDocumentStatusEnum } from "./Shared/Enums/IssuerDocumentStatusEnum";
import { mapGetters } from "vuex";

export default {
  name: "Index",
  mixins: [cursorPaginate],
  components: {
    BackButton,
    ListIssuerDocument,
    LoadingPagination,
    SkeletonPuzlGrid,
    PageHeader,
    PageHeaderButton,
    AppFixedPageTitle,
    AppPageHeader,
    AppSearchBar,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppTabSelect,
    AppViewTrigger,
  },
  data() {
    return {
      loading: true,
      loadingSkeleton: false,
      searchBarFilter: initSearchBarFilterType(),
      filter: initIssuerDocumentListFilterType(),
      IssuerDocumentStatusEnum: IssuerDocumentStatusEnum
    }
  },
  computed: {
    ...mapGetters({
      company_plant_issuers: "companyPlantIssuer/fetch"
    }),
    tabSelectItems() {
      return [
        {
          id: null, 
          name: 'Todos', 
          selected: this.filter.status === null,
        },
        {
          id: IssuerDocumentStatusEnum.ACTIVE, 
          name: 'Ativos', 
          selected: this.filter.status === IssuerDocumentStatusEnum.ACTIVE,
        },
        {
          id: IssuerDocumentStatusEnum.INACTIVE, 
          name: 'Inativos', 
          selected: this.filter.status === IssuerDocumentStatusEnum.INACTIVE,
        },
      ];
    },
  },
  methods: {
    handleModalCreate() {
      this.$router.push(`/configuration/plant/document/issuer-document/create`);
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
    },
    /**
     * Padrão do filtro principal
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType()
      };
    },
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * Padrão do filtro principal
     * @returns {IssuerDocumentListFilterType}
     */
    defaultFilter() {
      return {
        ...initIssuerDocumentListFilterType()
      };
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
    listItems(isAccumulateItems = false) {
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        return;
      }
      this.prepareFilter();
      this.loadingSkeleton = true;
      this.$store
        .dispatch("companyPlantIssuer/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.filter.nextUrl
        })
        .then((res) => this.resolveCursor(res))
        .finally(() => {
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      const isAlreadyFiltered = this.filter.status === item.id ? true : false;
      if (isAlreadyFiltered) {
        return;
      };
      this.filter.status = item.id;
      this.listItems();
    },
  },
  mounted() {
    this.clearFilter();
  },
}
</script>

<style scoped>

</style>
