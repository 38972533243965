<template>
  <div v-if="!isDropdown" @click="handleClick" class="new-puzl-default">
    <base-button v-if="!isDropdown" :type="type" block class="new-puzl-default"
      style="border-radius: 30px !important;">
      <img :src="icon" width="16">
      {{ text }}
    </base-button>
  </div>
  <base-dropdown v-else class="new-puzl-default" menuClasses="custom-dropdown-bubblehash260820241522">
    <base-button block slot="title-container" :type="type" class="dropdown-toggle text-uppercase new-puzl-default"
      style="border-radius: 30px !important;">
      <img :src="icon" width="16">
      {{ text }}
    </base-button>
    <div style="background: rgb(232, 232, 232);">
      <slot>

      </slot>
    </div>
  </base-dropdown>
</template>

<script>
export default {
  name: 'page-header-button',
  props: {
    text: {
      type: String,
      default: 'novo',
      description: 'Texto que fica dentro do botão do cabeçalho',
    },
    link: {
      type: String,
      default: '',
      description: 'Ação ou link que será executada pelo botão',
    },
    type: {
      type: String,
      default: 'primary',
      description: 'Define a cor do botão que será exibido no cabeçalho',
    },
    isDropdown: {
      type: Boolean,
      default: false,
      description: 'Transforma o botão em um dropdown com vários itens.',
    },
    icon: {
      type: String,
      default: '/img/icons/plus-math--v1-white.png',
      description: 'Define o ícone que será exibido ao lado do texto do botão',
    }
  },
  methods: {
    handleClick(evt) {
      if(!this.isDropdown){
        if(!this.link) this.$emit('click', evt);
        else this.$router.push(this.link);
      }
    }
  },
};
</script>
<style>
.custom-dropdown-bubblehash260820241522 {
  border-radius: 18px !important;
  overflow: hidden;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>

<style scoped>
div.new-puzl-default {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  display: block;
}

.new-puzl-default {
  font-family: Fredoka;
  font-weight: 400 !important;
  font-size: 12px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  width: 100%;
}

button.new-puzl-default {
  display: flex;
  align-items: center;
}

.new-puzl-default img {
  margin-right: 8px;
}

@media (min-width: 1200px) {
  div.new-puzl-default {
    margin-left: 0px;
    margin-top: 0px;
  }

  .new-puzl-default {
    min-width: 205px;
    max-width: 205px;
    display: flex;
  }
}
</style>
