<template>
  <div>
    <modal
      :show.sync="modal.create"
      size="xl"
    >
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div v-if="modal.create" class="row align-items-center">
        <div class="col-md-12 mt-n2">
          <card>
            <div class="row">
              <div class="col-md-4 mt-n2" style="margin-bottom: -20px">
                <h5>
                 <span class="font-weight-700"
                       style="text-transform: uppercase;font-size: 18px !important;">{{
                     contract_proposal.code
                   }}</span>
                </h5>
                <h5 class="mt-n2">
                  CLIENTE: {{ params.customer_name }} <button  type="button" class="btn base-button fs-10 new-default-black-font fs-9 new-mini-card-default  btn-light btn-sm new-btn-light">
                  <img src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png" width="14">
                  <span class="fs-10"> SALDO: {{params.balance | currency()}} </span></button>
                </h5>
                <h5 class="mt-n2">
                  OBRA: {{ params.construction_name }}
                </h5>
                <div style="border-top: 1px solid #1A70B7;margin-bottom: 5px"/>
                <h5 class="font-weight-normal">
                  CENTRAL: {{ params.company_plant }}
                </h5>
                <h5 class="font-weight-normal mt-n2">
                  CNPJ: {{ params.company_plant_issuer }}
                </h5>
              </div>
              <div class="col-md-8 mt-n1" style="margin-bottom: -20px">
                <div class="row show-md mt-n2">
                  <div class="col-md-3 px-1 text-center">
                    <card class="card-info">
                      <div style="margin-bottom: -20px">
                        <h4 class="font-weight-600 mt-n2 text-primary">CONCRETO</h4>
                        <h4 class="font-weight-600 numeric mt-n2">{{ sumConcrete | currency() }}</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-3 px-1 text-center">
                    <card class="card-info">
                      <div style="margin-bottom: -20px">
                        <h4 class="font-weight-600 mt-n2 text-grey2">SERVIÇOS</h4>
                        <h4 class="font-weight-600 numeric mt-n2">{{ sumServices | currency() }}</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-3 px-1 text-center">
                    <card class="card-info">
                      <div style="margin-bottom: -20px">
                        <h4 class="font-weight-600 mt-n2 text-warning">ADICIONAIS</h4>
                        <h4 class="font-weight-600 numeric mt-n2">{{ sumAdditionals | currency() }}</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-3 px-1 text-center">
                    <card class="card-info">
                      <div style="margin-bottom: -20px">
                        <h4 class="font-weight-600 mt-n2 text-indigo">AVULSOS</h4>
                        <h4 class="font-weight-600 numeric mt-n2">{{ sumSingle | currency() }}</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-3 px-1 mt-n4">
                    <h6 style="font-size: 11px !important;">
                      Volume de viagens:
                    </h6>
                    <h5 class="text-primary font-weight-600 mt-n2" style="font-size: 14px;text-transform: none;">
                      {{ sumVolume }} m <sup>3</sup></h5>
                  </div>
                  <div class="col-md-3 px-1 mt-n4">
                    <h6 style="font-size: 11px !important;">
                      Volume de serviços:
                    </h6>
                    <h5 class="text-primary font-weight-600 mt-n2" style="font-size: 14px;text-transform: none;">
                      {{ sumVolumeServices }} m <sup>3</sup></h5>
                  </div>
                  <div class="col-3"></div>
                  <div class="col-3 mt-n4">
                    <base-button
                      @click.prevent="showModalCreateSingle()"
                      class="text-uppercase mt-2 mb-1"
                      type="indigo"
                      block
                      outline
                    >
                      <i class="fas fa-plus"></i> Avulso
                    </base-button>
                  </div>
                </div>
                <div class="row show-mobile mt-4">
                  <div class="col-md-3 px-1 text-center">
                    <card class="card-info">
                      <div style="margin-bottom: -20px">
                        <h4 class="font-weight-600 mt-n2 text-primary">CONCRETO</h4>
                        <h4 class="font-weight-600 numeric mt-n2">{{ sumConcrete | currency() }}</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-3 mt-n4 px-1 text-center">
                    <card class="card-info">
                      <div style="margin-bottom: -20px">
                        <h4 class="font-weight-600 mt-n2 text-grey2">SERVIÇOS</h4>
                        <h4 class="font-weight-600 numeric mt-n2">{{ sumServices | currency() }}</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-3 mt-n4 px-1 text-center">
                    <card class="card-info">
                      <div style="margin-bottom: -20px">
                        <h4 class="font-weight-600 mt-n2 text-warning">ADICIONAIS</h4>
                        <h4 class="font-weight-600 numeric mt-n2">{{ sumAdditionals | currency() }}</h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-3 mt-n4 px-1 text-center">
                    <card class="card-info">
                      <div style="margin-bottom: -20px">
                        <h4 class="font-weight-600 mt-n2 text-indigo">AVULSOS</h4>
                        <h4 class="font-weight-600 numeric mt-n2"> {{ sumSingle | currency() }}
                        </h4>
                      </div>
                    </card>
                  </div>
                  <div class="col-md-3 px-1 mt-n4">
                    <div class="row">
                      <div class="col-6">
                        <h6 style="font-size: 11px !important;">
                          Volume de viagens:
                        </h6>
                        <h5 class="text-primary font-weight-700 mt-n2" style="font-size: 14px;text-transform: none;">
                          {{ sumVolume }} m <sup>3</sup></h5>
                      </div>
                      <div class="col-6">
                        <h6 style="font-size: 11px !important;">
                          Volume de serviços:
                        </h6>
                        <h5 class="text-primary font-weight-700 mt-n2" style="font-size: 14px;text-transform: none;">
                          {{ sumVolumeServices }} m <sup>3</sup></h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
          <card style="overflow: auto;margin-top: -20px">
            <div class="row" v-if="!loadingSkeleton" style="margin-top: 15px;max-height: 40vh">
              <div class="col-md-4 p-1 card-row" v-for="(item, index) in $_invoices" :key="index">
                <card style="min-height: 158px">
                  <template v-if="item.type == 1"> <!-- Concreto -->
                    <div class="row">
                      <div class="col-5 pl-0 mt-n2 mb-3">
                        <base-button
                          size="sm"
                          @click.prevent="push(item.id, 1)"
                          :class="concrete_selecteds.includes(item.id) && 'active'"
                          outline type="primary">
                          CONCRETO
                          <i class="fa-regular fa-circle-check"></i>
                        </base-button>
                      </div>
                      <div class="col-3 mt-n3 text-right">
                        <h4 v-show="item.nfe">
                          <small class="text-info">
                            <b style="font-size: 14px;margin-left: -5px !important">
                              <small class="text-default">
                                <b>
                                  <img width="30px" class="mt-1" src="/img/icons/nfe.png">
                                </b>
                              </small>
                              {{ item.nfe }}
                            </b>
                          </small>
                        </h4>
                      </div>
                      <div class="col-4 mt-n3 text-right">
                        <h4>
                          <small class="text-info">
                            <b style="font-size: 14px;margin-left: -5px !important">
                              <small class="text-default">
                                <b>
                                  <a
                                    href="#"
                                    @click.prevent="downloadProof(item.sequencial_number_plant_schedule_travel_uuid)"
                                  >
                                    <img width="50px" src="/img/icons/os.png">
                                  </a>
                                </b>
                              </small>
                              {{ item.number }}
                              <badge v-if="item.status === 6" type="danger">CANCELADA</badge>
                            </b>
                          </small>
                        </h4>
                      </div>
                      <div :style="item.status === 6 ? 'margin-top: -45px' : 'margin-top: -25px'"
                           class="show-md col-md-12 p-1">
                        <h4 class="numeric">{{ item.volume.toString().replace('.', ',') }}
                          <span style="text-transform: none">m</span><sup>3</sup></h4>
                        <h4 class="mt-n2 numeric font-weight-400 ">{{ item.formulation_value | currency() }}
                          <span style="text-transform: none" class="font-weight-400">/m</span><sup>3</sup></h4>
                      </div>
                      <div class="show-mobile col-md-12 mt-n2 p-1">
                        <h4 class="numeric">{{ item.volume.toString().replace('.', ',') }}
                          <span style="text-transform: none">m</span><sup>3</sup></h4>
                        <h4 class="mt-n2 numeric font-weight-400 ">{{ item.formulation_value | currency() }}
                          <span style="text-transform: none" class="font-weight-400">/m</span><sup>3</sup></h4>
                      </div>
                    </div>
                    <div class="mt-n3">
                      <div class="row">
                        <div class="col-md-12 p-1">
                          <span class="h4 mb-0">
                              {{ item.title }}
                          </span>
                          <span>
                            {{ item.body }}
                          </span>
                          <br v-if="item.puzl_additions.length">
                          <div
                            v-for="(puzl_addtion, index) in item.puzl_additions"
                            :key="puzl_addtion.id"
                          >
                            <h5 class="text-indigo">
                              {{ puzl_addtion.name }}
                              ({{ puzl_addtion.volume }} kg/m<sup>3</sup>)
                              <span
                                class="text-indigo"
                                v-if="index != item.puzl_additions.length -1"
                              >
                                +
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-n1" style="margin-bottom: -25px">
                      <div class="col-6 p-1">
                        <h3>
                          <small class="numeric">
                            {{ item.date_at | parseDate() }}
                          </small>
                        </h3>
                      </div>
                      <div class="col-6 p-1 text-right" v-if="item.type === 1 && Number(item.max_versions) > 1">
                        <base-dropdown menuOnRight>
                          <base-button
                            slot="title-container"
                            type="primary"
                            class="dropdown-toggle p-2 rounded m-0"
                          >
                            V.{{ item.version }}
                          </base-button>
                          <a v-if="indexVersion !== Number(item.version)"
                             @click.prevent="handleUpdateVersion(item.id, indexVersion, index)" class="dropdown-item"
                             v-for="indexVersion in Number(item.max_versions)">
                            <span class="text-right">V.{{ indexVersion }}</span>
                          </a>
                        </base-dropdown>
                      </div>
                      <div class="col-6 p-1 text-right"
                           v-if="(item.type === 1 && !item.max_versions) || (item.type === 1 &&  Number(item.max_versions) === 1)">
                        <base-dropdown menuOnRight>
                          <base-button
                            disabled
                            style="width: 51px"
                            slot="title-container"
                            type="primary"
                            class="p-2 rounded m-0"
                          >
                            V.{{ item.version }}
                          </base-button>
                        </base-dropdown>
                      </div>
                      <div style="margin-top: -2rem" class="col-6 p-1 show-md">
                        <h1>
                          <small class="numeric font-weight-600">
                            {{ item.value | currency() }}
                          </small>
                        </h1>
                      </div>
                      <div class="col-6 mt-n4 p-1 text-left show-mobile">
                        <h1>
                          <small class="numeric font-weight-600">
                            {{ item.value | currency() }}
                          </small>
                        </h1>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.type == 2"> <!-- Serviço -->
                    <div class="row">
                      <div class="col-6 pl-0 mt-n2 mb-3">
                        <base-button
                          size="sm"
                          @click.prevent="push(item.id, 2)"
                          :class="services_selecteds.includes(item.id) && 'active'"
                          class="text-uppercase"
                          outline type="primary">
                          Serviço
                          <i class="fa-regular fa-circle-check"></i>
                        </base-button>
                      </div>
                      <div class="col-6 mt-n3 text-right">
                        <h4>
                          <small class="text-info">
                            <b style="font-size: 14px;margin-left: -5px !important">
                              <small class="text-default">
                                <b>
                                  <a href="#" @click.prevent="getReport(item.schedule_service_order_uuid)">
                                    <img width="50px" src="/img/icons/os.png">
                                  </a>
                                </b>
                              </small>
                              {{ item.number }}
                              <badge v-if="item.status === 3" type="danger">CANCELADA</badge>
                            </b>
                          </small>
                        </h4>
                      </div>
                      <div class="col-md-6 mt-n4 p-1 mb-2">
                        <template v-if="item.price_key === 'price_fixed'">
                          <h5 class="h4 mb-0 small font-weight-bold numeric">{{ item.volume_performed }} <span
                            style="text-transform: none">m³</span></h5>
                          <h5 class="h4 mb-0 small mt-2 mb-2">Taxa mínima</h5>
                        </template>
                        <template v-if="item.price_key === 'price_meters'">
                          <h5 class="h4 mb-0 small font-weight-bold numeric">{{ item.volume_performed }} <span
                            style="text-transform: none">m³</span></h5>
                          <h5 class="h4 mb-0 small mt-1">{{ item.proposal_by_meters | currency() }} <span
                            style="text-transform: none">/m³</span><br></h5>
                        </template>
                        <template v-if="item.price_key === 'price_hour'">
                          <h5 class="h4 mb-0 small font-weight-bold numeric">{{ item.volume_performed }} <span
                            style="text-transform: none">m³</span></h5>
                          <h5 class="h4 mb-0 small font-weight-bold numeric">
                            {{ $helper.secondsToTime(item.hour_performed * 3600, true) }}</h5>
                          <h5 class="h4 mb-0 small mt-1"> {{ item.proposal_by_hours | currency() }} <span
                            style="text-transform: none">/hr</span><br></h5>
                        </template>
                      </div>
                      <div class="col-md-12 p-1 mt-n3">
                        <h3>
                          <small class="font-weight-500">
                            {{ item.service_name }}
                          </small>
                        </h3>
                        <h3 class="mt-n3">
                          <small>
                            {{ item.service_description }}
                          </small>
                        </h3>
                      </div>
                    </div>
                    <div class="row mt-n3 mb-0" style="margin-bottom: -1rem !important;">
                      <div class="col-6 p-1">
                        <h3 class="mb-0">
                          <small class="numeric">
                            {{ item.date_at | parseDate() }}
                          </small>
                        </h3>
                        <h1 style="line-height: 14px;" class="mb-2 pb-2 mt-1 mt-md-0">
                          <small class="numeric font-weight-600">
                            {{ item.value | currency() }}
                          </small>
                        </h1>
                      </div>
                      <div style="margin-top: -12px"
                           class="col-6 p-1 text-right align-middle d-flex align-items-center">
                        <div class="w-100">
                          <base-button
                            @click.prevent="handleModalChangeServiceValue(item)"
                            outline
                            slot="reference"
                            size="sm"
                            type="secundary"
                            class="p-0 m-0 shadow-none"
                          >
                            <i style="font-size:32px" class="fas fa-edit text-warning"></i>
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.type == 3"> <!-- Adicional -->
                    <div class="row">
                      <div class="col-md-5 pl-0 mt-n2 mb-2">
                        <base-button
                          size="sm"
                          @click.prevent="push(item.id, 3)"
                          :class="additionals_selecteds.includes(item.id) && 'active'"
                          class="text-uppercase"
                          outline type="warning">
                          Adicional
                          <i class="fa-regular fa-circle-check"></i>
                        </base-button>
                      </div>
                      <div class="col-3 mt-n3 text-right">
                        <h4>
                          <small class="text-info">
                            <b style="font-size: 14px;margin-left: -5px !important">
                              <small class="text-default">
                                <b>
                                  <img width="30px" class="mt-1" src="/img/icons/nfe.png">
                                </b>
                              </small>
                              {{ item.nfe }}
                            </b>
                          </small>
                        </h4>
                      </div>
                      <div class="show-md col-md-4 mt-n3 text-right">
                        <h4>
                          <small class="text-info">
                            <b style="font-size: 14px;margin-left: -5px !important">
                              <small class="text-default">
                                <b>
                                  <img width="50px" src="/img/icons/os.png">
                                </b>
                              </small>
                              {{ item.number }}
                              <el-popover trigger="hover" placement="top" class="p-0">
                                <span>{{ item.justification || 'Justificativa não informada' }}</span>
                                <badge v-if="item.status === 3" slot="reference" type="danger">
                                  CANCELADA
                                </badge>
                              </el-popover>
                            </b>
                          </small>
                        </h4>
                      </div>
                      <div class="show-mobile col-md-4 mt-n2">
                        <h4>
                          <small class="text-info">
                            <b style="font-size: 14px;margin-left: -5px !important">
                              <small class="text-default">
                                <b>
                                  <img width="50px" src="/img/icons/os.png">
                                </b>
                              </small>
                              {{ item.number }}
                              <badge v-if="item.status === 3" type="danger">CANCELADA</badge>
                            </b>
                          </small>
                        </h4>
                      </div>
                      <div :style="item.status === 3 ? 'margin-top: -45px' : 'margin-top: -25px'"
                           class="show-md col-md-6 p-1">
                        <h3><small class="font-weight-400">{{ item.short_description }}</small></h3>
                        <h3 class="mt-n3"><small class="font-weight-400">{{ item.reason || '-' }}</small></h3>
                        <h3 class="mt-n3" style="text-transform: none" v-if="item.billing_unit_id !== 0">
                          <small class="font-weight-400 numeric">{{ format(item.calculation_base) || '-' }}</small>
                        </h3>
                        <h3 v-else class="mt-n3" style="text-transform: none">
                          <small class="font-weight-400 numeric">{{ item.calculation_base | currency() }}</small>
                        </h3>
                        <h3 class="mt-n3">
                          <small class="font-weight-500 numeric" style="text-transform: none">
                            {{ item.standard_value }} {{ item.billing_unit }}
                          </small>
                        </h3>

                      </div>
                      <div class="show-mobile mt-n2 col-md-6 p-1">
                        <h3><small class="font-weight-400">{{ item.short_description }}</small></h3>
                        <h3 class="mt-n3"><small class="font-weight-400">{{ item.reason || '-' }}</small></h3>
                        <h3 class="mt-n3" style="text-transform: none" v-if="item.billing_unit_id !== 0">
                          <small class="font-weight-400 numeric">{{ format(item.calculation_base) || '-' }}</small>
                        </h3>
                        <h3 v-else class="mt-n3" style="text-transform: none">
                          <small class="font-weight-400 numeric">{{ item.calculation_base | currency() }}</small>
                        </h3>
                        <h3 class="mt-n3">
                          <small class="font-weight-500 numeric" style="text-transform: none">
                            {{ item.standard_value }} {{ item.billing_unit }}
                          </small>
                        </h3>

                      </div>
                    </div>
                    <div class="row mt-n2 mb-0 pb-1" style="margin-bottom: -1rem !important;">
                      <div class="col-8 p-1">
                        <h3 class="mb-0">
                          <small class="numeric">
                            {{ item.date_at | parseDate() }}
                          </small>
                        </h3>
                        <h1 style="line-height: 14px;" class="mb-2 pb-1 mt-1 mt-md-0">
                          <small class="numeric font-weight-600">
                            {{ item.value | currency() }}
                          </small>
                        </h1>
                      </div>
                      <div style="margin-top: -12px"
                           class="col-4 p-1 text-right align-middle d-flex align-items-center">
                        <div class="w-100">
                          <base-button
                            @click.prevent="handleModalChangeAdditionalValue(item)"
                            outline
                            slot="reference"
                            size="sm"
                            type="secundary"
                            class="p-0 m-0 shadow-none"
                          >
                            <i style="font-size:32px" class="fas fa-edit text-warning"></i>
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.type == 4"> <!-- Avulso -->
                    <div class="row">
                      <div class="col-5 pl-0 pr-1 mt-n2 mb-2">
                        <base-button
                          size="sm"
                          @click.prevent="push(item.id, 4)"
                          :class="single_selecteds.includes(item.id) && 'active'"
                          class="text-uppercase"
                          block
                          outline type="indigo">
                          Avulso
                          <i class="fa-regular fa-circle-check"></i>
                        </base-button>
                      </div>
                      <div class="col-5 mt-n2 pl-1 mb-2">
                        <div class="pointer"
                             @click.prevent="handleApprove(item)"
                             style="border-radius: 5px; font-size: 0.8rem; text-align: center; font-weight: 500 !important; padding: 0.2rem 0.3rem"
                             :style="item.approved ? 'color: rgb(72 176 122); background: rgb(171 210 167 / 20%)' : 'color: #F2B532; background: hsl(41, 88%, 57%, 20%)'">
                          {{ item.approved ? 'APROVADO' : 'PENDENTE' }}
                        </div>
                      </div>
                      <div class="col-2 pl-0 mt-n2 mb-2 text-right">
                        <a href="#" @click.prevent="removeSingle(item.id)"
                           class="ml-1 avatar avatar-sm bg-danger">
                          <i class="fa-solid fa-trash text-white"></i>
                        </a>
                      </div>
                      <div class="col-md-12 p-1 mt-n2">
                        <h3><small class="font-weight-400">{{ item.description }}</small></h3>
                        <h3 class="mt-n3"><small class="font-weight-400 numeric">{{ item.quantity }}
                          (Quantidade)</small></h3>
                        <h3 class="mt-n3"><small
                          class="font-weight-400 numeric">{{ (item.value / item.quantity) | currency() }} /unid.</small>
                        </h3>
                      </div>
                    </div>
                    <div class="row  mb-0" style="margin-bottom: -1rem !important;">
                      <div class="col-md-6 p-1">
                        <h3>
                          <small class="numeric">
                            {{ item.date_at | parseDate('DD MMM YYYY') }}
                          </small>
                        </h3>
                      </div>
                      <div style="margin-top: -12px" class="col-md-6 p-1 text-right show-md">
                        <h1>
                          <small class="numeric font-weight-600">
                            {{ item.value | currency() }}
                          </small>
                        </h1>
                      </div>
                      <div class="col-md-6 mt-n4 p-1 text-left show-mobile">
                        <h1>
                          <small class="numeric font-weight-600">
                            {{ item.value | currency() }}
                          </small>
                        </h1>
                      </div>
                    </div>
                  </template>
                  <!--                  <div class="row pr-0 pt-0 mt-0">-->
                  <!--                    <div class="col-6 pl-0 mt-n2">-->
                  <!--                      <base-button-->
                  <!--                        outline type="primary">-->
                  <!--                        CONCRETO-->
                  <!--                        <i class="fa-regular fa-circle-check"></i>-->
                  <!--                      </base-button>-->
                  <!--                    </div>-->
                  <!--                    <div class="col-6"/>-->
                  <!--                    <div class="col-md-12">-->

                  <!--                    </div>-->
                  <!--                    <div class="col-md-12 pl-1 mt-n2">-->
                  <!--                      <h2>-->
                  <!--                        <small-->
                  <!--                          class="numeric font-weight-600 text-warning">-->
                  <!--                          {{ 1| currency }} <span style="font-size: 0.9rem"-->
                  <!--                                                  class="font-weight-300 numeric text-grey2">({{-->
                  <!--                            1-->
                  <!--                          }}/{{ 1 }})</span>-->
                  <!--                        </small>-->
                  <!--                      </h2>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </card>
              </div>
            </div>
            <div class="row p-0" v-show="loadingSkeleton" style="max-height: 300px">
              <skeleton-puzl-grid v-for="item in 3" classCard="col-md-4 p-1 card-row"/>
            </div>
            <!--            <div class="row p-0" v-if="!loadingSkeleton && !$_to_associate.length">-->
            <!--              <div class="col-md-12">-->
            <!--                <puzl-empty-data :with-card="false"/>-->
            <!--              </div>-->
            <!--            </div>-->
          </card>
          <card class="mt-n4">
            <div class="row mt-n2" style="margin-bottom: -20px">
              <div class="col-md-4 ">
                <h5>MÉTODO: {{ params.payment_method }}</h5>
                <h5 class="mt-n2">CONDIÇÃO: {{ params.payment_term }}</h5>
                <h5 class="mt-n2">MCC TEÓRICO: {{ sumTheoricalAdjusted | currency() }}</h5>
                <h5 class="mt-n2">MCC REAL: {{ sumRealMcc | currency() }}</h5>
              </div>
              <div class="col-md-4">
                <h5>DEDUÇÃO: {{ (deduct || 0) | currency() }}</h5>
                <h5 class="mt-n2">BASE: {{ (base || 0) | currency() }}</h5>
                <h5 class="text-danger mt-n2">ISS: {{ (sumISS || 0) | currency() }}</h5>
                <h5 class="text-danger mt-n2">Retenções Federais: {{ (sumFederalTaxes || 0) | currency() }}</h5>
              </div>
              <div class="col-md-4 ">
                <h5 class="text-dark">
                  Período faturado:
                  {{
                    params.filter.range.start | parseDate('DD/MM/YYYY')
                  }} até {{ params.filter.range.end | parseDate('DD/MM/YYYY') }}
                </h5>
                <h5 v-show="reidi_percentage && contract_proposal.reidi" class="mt-n2 text-danger">REIDI: {{ this.reidi | currency() }} ({{this.reidi_percentage}}%)</h5>
                <h5 class="mt-n2 text-dark">VALOR BRUTO: {{ getTotalValue() | currency() }}</h5>
                <h5 class="mt-n2 text-dark">VALOR LÍQUIDO:
                  <span class="text-success font-weight-500">
                   {{ getFinalValue() | currency() }}
                  </span>
                </h5>
              </div>
            </div>
          </card>
          <div class="row mt-n2">
            <div class="col-md-12 text-right">
              <base-button @click.prevent="close" type="secondary">
                Fechar
              </base-button>
              <base-button v-if="invoice"
                :disabled="!(concrete_selecteds.length || services_selecteds.length || additionals_selecteds.length
                            || single_selecteds.length) || getFinalValue() < 0"
                @click.prevent="save"
                :loading="loading"
                type="primary"
                native-type="submit"
              >
                Faturar
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="row mt-n3 show-md pl-3">-->
      <!--        <div class="col-md p-0 pl-2">-->
      <!--          <h3 class="mt-n2">-->
      <!--            <small class="text-muted numeric">MCC (Teórico): <span-->
      <!--              class="numeric"> {{ sumTheoricalAdjusted | currency() }}</span></small>-->
      <!--          </h3>-->
      <!--          <h3 class="mt-n2">-->
      <!--            <small class="numeric">Dedução: <span class="numeric"> {{ deduct | currency() }}</span></small>-->
      <!--          </h3>-->
      <!--          <h3 class="mt-n2">-->
      <!--            <small class="numeric">Base: <span class="numeric"> {{ base | currency() }}</span></small>-->
      <!--          </h3>-->
      <!--          <h3 class="mt-n2">-->
      <!--            <small class="text-darkred numeric">ISS: <span class="numeric"> {{ sumISS | currency() }}</span></small>-->
      <!--          </h3>-->
      <!--        </div>-->
      <!--        <div class="col-md p-0 text-center mt-n2">-->
      <!--          <h2>-->
      <!--            <small class="font-weight-500" style="text-transform: none">-->
      <!--              VALOR FINAL-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--          <h2 class="mt-n3 text-center">-->
      <!--            <small class="numeric text-success font-weight-600" style="font-size: 1.5rem">-->
      <!--              {{ getFinalValue() | currency() }}-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--          <h2 class="mt-n3 text-center">-->
      <!--            <small class="numeric ">-->
      <!--              {{ params.payment_method }}-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--          <h2 class="mt-n3 text-center">-->
      <!--            <small class="numeric ">-->
      <!--              {{ params.payment_term }}-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--        </div>-->
      <!--        <div class="col-md-2 p-0 text-center mt-n2">-->
      <!--          <h4>-->
      <!--            <small class="font-weight-500" style="text-transform: none">-->
      <!--              VOLUME DE VIAGENS-->
      <!--            </small>-->
      <!--          </h4>-->
      <!--          <h4 class="mt-n2 text-center">-->
      <!--            <small class="numeric text-primary font-weight-600" style="font-size: 1.5rem">-->
      <!--              ({{ sumVolume }} <span style="text-transform: none">m</span><sup>3</sup>)-->
      <!--            </small>-->
      <!--          </h4>-->
      <!--        </div>-->
      <!--        <div class="col-md-2 p-0 m-0 text-center mt-n2">-->
      <!--          <h4>-->
      <!--            <small class="font-weight-500" style="text-transform: none">-->
      <!--              VOLUME DE SERVIÇOS-->
      <!--            </small>-->
      <!--          </h4>-->
      <!--          <h4 class="mt-n2 text-center">-->
      <!--            <small class="numeric text-primary font-weight-600" style="font-size: 1.5rem">-->
      <!--              ({{ sumVolumeServices }} <span style="text-transform: none">m</span><sup>3</sup>)-->
      <!--            </small>-->
      <!--          </h4>-->
      <!--        </div>-->
      <!--        <div class="col-md text-right" style="padding-top: 50px">-->
      <!--          <base-button @click.prevent="close" type="secondary">-->
      <!--            Fechar-->
      <!--          </base-button>-->
      <!--          <base-button-->
      <!--            :disabled="!(concrete_selecteds.length-->
      <!--            || services_selecteds.length-->
      <!--             || additionals_selecteds.length-->
      <!--              || single_selecteds.length)"-->
      <!--            @click.prevent="save"-->
      <!--            :loading="loading"-->
      <!--            type="primary"-->
      <!--            native-type="submit"-->
      <!--          >-->
      <!--            Faturar-->
      <!--          </base-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="row mt-n3 show-mobile pl-3">-->
      <!--        <div class="col-md-4 mt-n2">-->
      <!--          <h2>-->
      <!--            <small class="font-weight-500" style="text-transform: none">-->
      <!--              Valor final-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--          <h2 class="mt-n3 ">-->
      <!--            <small class="numeric text-success font-weight-600" style="font-size: 1.5rem">-->
      <!--              {{ getFinalValue() | currency() }}-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--          <h2 class="mt-n3 ">-->
      <!--            <small class="numeric ">-->
      <!--              {{ params.payment_method }}-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--          <h2 class="mt-n3 ">-->
      <!--            <small class="numeric ">-->
      <!--              {{ params.payment_term }}-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--        </div>-->
      <!--        <div class="col-md-4 mt-n2">-->
      <!--          <br class="m-1">-->
      <!--          <h2 class="mt-n4">-->
      <!--            <small class="font-weight-500">-->
      <!--              Volume de viagens-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--          <h2 class="mt-n3 ">-->
      <!--            <small class="numeric text-primary font-weight-600" style="font-size: 1.5rem">-->
      <!--              ({{ sumVolume }} <span style="text-transform: none">m</span><sup>3</sup>)-->
      <!--            </small>-->
      <!--          </h2>-->
      <!--        </div>-->
      <!--        <div class="col-md-3">-->
      <!--          <br class="m-1">-->
      <!--          <h3 class="mt-n4">-->
      <!--            <small class="text-muted numeric">MCC (Teórico): <span-->
      <!--              class="numeric"> {{ sumTheoricalAdjusted | currency() }}</span></small>-->
      <!--          </h3>-->
      <!--          <h3 class="mt-n2">-->
      <!--            <small class="numeric">Dedução: <span class="numeric"> {{ deduct | currency() }}</span></small>-->
      <!--          </h3>-->
      <!--          <h3 class="mt-n2">-->
      <!--            <small class="numeric">Base: <span class="numeric"> {{ base | currency() }}</span></small>-->
      <!--          </h3>-->
      <!--          <h3 class="mt-n2">-->
      <!--            <small class="text-darkred numeric">ISS: <span class="numeric"> {{ sumISS | currency() }}</span></small>-->
      <!--          </h3>-->
      <!--        </div>-->
      <!--        <hr>-->
      <!--        <div class="col-md-5 text-right">-->
      <!--          <base-button @click.prevent="close" type="secondary">-->
      <!--            Fechar-->
      <!--          </base-button>-->
      <!--          <base-button-->
      <!--            :disabled="!(concrete_selecteds.length-->
      <!--            || services_selecteds.length-->
      <!--             || additionals_selecteds.length-->
      <!--              || single_selecteds.length)"-->
      <!--            @click.prevent="save"-->
      <!--            :loading="loading"-->
      <!--            type="primary"-->
      <!--            native-type="submit"-->
      <!--          >-->
      <!--            Faturar-->
      <!--          </base-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <create-single @fetch="init" ref="createSingle"/>
    </modal>
    <ModalChangeServiceValue @updated="init" ref="modalChangeServiceValue"/>
    <ModalChangeAdditionalValue @updated="init" ref="modalChangeAdditionalValue"/>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import {mapGetters} from "vuex";
import CreateSingle from '../../../Billing/BillingProposal/Shared/_CreateSingle'
import ModalChangeServiceValue from "../../../Billing/Shared/_ModalChangeServiceValue"
import ModalChangeAdditionalValue from "../../../Billing/Shared/_ModalChangeAdditionalValue"
import {strToNum, roundWithDecimal, parseDate} from "@/helpers";

export default {
  name: "ModalIndividual",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    PuzlSelect,
    CreateSingle,
    ModalChangeServiceValue,
    ModalChangeAdditionalValue,
  },
  data() {
    return {
      modal: {
        title: 'Faturamento individual',
        create: false,
      },
      invoice: true,
      base: 0,
      deduct: 0,
      reidi: 0,
      reidi_percentage: 0,
      concrete_selecteds: [],
      services_selecteds: [],
      additionals_selecteds: [],
      single_selecteds: [],
      loadingSkeleton: false,
      loading: false,
      params: null,
    };
  },
  computed: {
    ...mapGetters({
      $_invoices: "billingInvoice/getListBills",
      contract_proposal: "contractProposal/show",
      $_financial_limit: "permissionFinancialLimit/show",
    }),
    sumISS: function () {
      if (!this.params) {
        return 0
      }
      const total = this.getTotalValue()
      let deduct = total * Number(this.params.deduction.deduct) / 100;

      if (this.params.deduction.condition) {
        let mcc = 0
        if (this.params.deduction.consider_reuse) {
          mcc = this.sumRealMcc
        } else {
          mcc = this.sumTheoricalAdjusted
        }
        let deduct_mcc = total * ((mcc / total) * 100) / 100
        if (this.params.deduction.condition === 'Adotar sempre o MCC') {
          deduct = deduct_mcc
        }
        if (this.params.deduction.condition === 'Adotar sempre maior dedução') {
          if (deduct_mcc > deduct) {
            deduct = deduct_mcc
          }
        }
        if (this.params.deduction.condition === 'Adotar sempre menor dedução') {
          if (deduct_mcc < deduct) {
            deduct = deduct_mcc
          }
        }
      }
      const base = total - deduct;
      this.base = base
      this.deduct = deduct
      return roundWithDecimal((base * Number(this.params.deduction.retained_rate) / 100).toFixed(2), 2)
    },
    sumFederalTaxes: function () {
      if (!this.params) {
        return 0
      }
      const total = this.getTotalValue();
      let federalTaxes = 0;
      if (strToNum(this.params.cofins_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.cofins_rate) / 100);
      }
      if (strToNum(this.params.csll_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.csll_rate) / 100);
      }
      if (strToNum(this.params.inss_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.inss_rate) / 100);
      }
      if (strToNum(this.params.ir_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.ir_rate) / 100);
      }
      if (strToNum(this.params.pis_rate) > 0) {
        federalTaxes += total * (strToNum(this.params.pis_rate) / 100);
      }
      return federalTaxes;
    },
    sumVolume: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.volume) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumVolumeServices: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 2 && this.services_selecteds.includes(b.id) && b.status === 1 ? Number(b.volume_performed) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumConcrete: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.value) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumTheoricalAdjusted: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.theorical_adjusted) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumRealMcc: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? Number(b.real_mcc) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumServices: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 2 && this.services_selecteds.includes(b.id) && b.status === 1 ? Number(b.value) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumAdditionals: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 3 && this.additionals_selecteds.includes(b.id) && b.status === 1 ? Number(b.value) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
    sumSingle: function () {
      let initial = 0
      let sum = this.$_invoices.reduce((a, b) => {
        let value = b.type === 4 && this.single_selecteds.includes(b.id) ? Number(b.value) : 0
        return (Number(a) + Number(value))
      }, initial)
      return roundWithDecimal(sum.toFixed(2), 2)
    },
  },
  methods: {
    handleApprove(item) {
      if (!this.$_financial_limit.invoice_can_add_single) {
        return this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para aprovar avulso'
        });
      }
      this.$Swal.confirmAction('Deseja realmente aprovar o avulso?', ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            let loading = this.$loading.show()
            this.$store.dispatch('detached/approve', {id: item.id, status: !item.approved}).then(response => {
              item.approved = !item.approved
              this.$notify({
                type: 'success',
                message: response.message
              });
              this.push(item.id, 4)
            }).finally(() => loading.hide())
          }
        }).catch(() => this.$Progress.finish())
    },
    getFinalValue() {
      let total = roundWithDecimal((Number(this.sumConcrete) + Number(this.sumServices) + Number(this.sumAdditionals) + Number(this.sumSingle)) - this.reidi, 2);
      if (this.params.iss_retain) {
        total -= this.sumISS;
      }
      total -= this.sumFederalTaxes;
      return total;
    },
    getTotalValue() {
      return roundWithDecimal((Number(this.sumConcrete) + Number(this.sumServices) + Number(this.sumAdditionals) + Number(this.sumSingle) - this.reidi), 2)
    },
    handleUpdateVersion(id, version, index) {
      if (! this.$_financial_limit.invoice_can_change_concrete_price) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para alterar a versão de preço',
        })
      }
      let loader = this.$loading.show()
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$store
        .dispatch("travels/updateVersion", {
          id: id,
          version: version
        }).then(response => {
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.init()
        loader.hide()
      })
    },
    removeSingle(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start()
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
          this.$store.dispatch('billingInvoice/destroySingle', id).then(response => {
            this.$notify({type: response.error_type, message: response.message})
            this.$Progress.finish()
          }).catch(error => {
            this.$notify({type: error.error_type, message: error.message})
            this.$Progress.finish()
          })
        }
      })
    },
    push(id, type) {
      switch (type) {
        case 1:
          this.concrete_selecteds = this.$helper.Array.selecteds(this.concrete_selecteds, id)
          break;
        case 2:
          this.services_selecteds = this.$helper.Array.selecteds(this.services_selecteds, id)
          break;
        case 3:
          this.additionals_selecteds = this.$helper.Array.selecteds(this.additionals_selecteds, id)
          break;
        case 4:
          let item = this.$_invoices.find((item) => item.id == id)
          if (! item.approved) {
            return this.$notify({type: 'danger', message: 'O avulso deve ser aprovado para ser faturado'})
          }
          this.single_selecteds = this.$helper.Array.selecteds(this.single_selecteds, id)
          break;
      }
      let total_value = roundWithDecimal(Number(this.sumConcrete) + Number(this.sumServices) + Number(this.sumAdditionals) + Number(this.sumSingle), 2)
      if (this.reidi_percentage && this.contract_proposal.reidi) {
        this.reidi = roundWithDecimal(total_value / 100 * this.reidi_percentage, 2)
      }
    },
    format(value) {
      if (!isNaN(value)) {
        return this.$helper.secondsToTime(value * 60, true)
      }
      return value
    },
    close() {
      this.modal.create = false
    },
    showModalCreateSingle() {
      this.$refs.createSingle.openModal()
      this.$refs.createSingle.single.date_at = this.params.filter.range.end
    },
    init() {
      this.loadingSkeleton = true
      this.$store.dispatch('billingInvoice/getBills',
        {
          filter: this.params.filter,
          contract_proposal_id: this.contract_proposal.id
        }).then(response => {
        this.loadingSkeleton = false
      }).catch(error => {
        this.loadingSkeleton = false
      })
    },
    save() {
      if (this.params.filter && this.params.filter.agrouped) {
        return this.$emit('agrouped', {
          concreteds: this.concrete_selecteds,
          services: this.services_selecteds,
          additionals: this.additionals_selecteds,
          single: this.single_selecteds,
        })
      }
      this.$Swal.confirmAction('Deseja realmente faturar os dados selecionados?', ['Sim', 'Não']).then((result) => {
        if (result.isConfirmed) {
          // this.loading = true
          const selecteds = [
            {
              id: this.params.id,
              company_plant_id: this.params.company_plant_id,
              iss_retain: this.params.iss_retain,
              cofins_rate: strToNum(this.params.cofins_rate),
              csll_rate: strToNum(this.params.csll_rate),
              inss_rate: strToNum(this.params.inss_rate),
              ir_rate: strToNum(this.params.ir_rate),
              pis_rate: strToNum(this.params.pis_rate),
              payment_term_id: this.params.payment_term_id,
              payment_method_id: this.params.payment_method_id,
              payment_intermediary_id: this.params.payment_intermediary_id,
              bank_account_id: this.params.bank_account_id,
              company_plant_issuer_id: this.params.plant_issuer_id,
              considere_prepayment: this.params.considere_prepayment,
              use_anticipation_balance: this.params.use_anticipation_balance,
              concreteds: this.concrete_selecteds,
              services: this.services_selecteds,
              additionals: this.additionals_selecteds,
              single: this.single_selecteds,
            }
          ]
          const date = {
            start: parseDate(this.params.filter.range.start, 'YYYY-MM-DD'),
            end: parseDate(this.params.filter.range.end, 'yyyy-MM-DD'),
          }
          this.$store.dispatch('billingInvoice/generateInvoices', {
            items: selecteds,
            range: date
          }).then(response => {
            this.$notify({type: response.error_type, message: response.message});
            this.close()
            this.$emit('closeAndUpdateInvoiced', this.params.key)
            this.loading = false
          }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
            this.loading = false
          })
        }
      });
    },
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
    async handleCreate(params, invoice = true) {
      this.invoice = invoice
      this.prepareFederalTaxes(params);
      let param_taxes = {
        issuerRegime: params.tax_regime,
        commercialTax: 'REIDI'
      };
      this.concrete_selecteds = []
      this.services_selecteds = []
      this.additionals_selecteds = []
      this.single_selecteds = []
      this.params = params
      await this.fetchFinancialLimits(this.$user.id)
      this.modal.create = true
      this.loadingSkeleton = true
      await Promise.all([
        this.$store.dispatch('companyPlantIssuer/fetchItems'),
        this.$store.dispatch('billingInvoice/getBills',
          {
            filter: this.params.filter,
            contract_proposal_id: params.id
          }),
      ]).then(() => {
        this.$_invoices.forEach(function (item) {
          switch (item.type) {
            case 1:
              this.concrete_selecteds = this.$helper.Array.selecteds(this.concrete_selecteds, item.id)
              break;
            case 2:
              this.services_selecteds = this.$helper.Array.selecteds(this.services_selecteds, item.id)
              break;
            case 3:
              this.additionals_selecteds = this.$helper.Array.selecteds(this.additionals_selecteds, item.id)
              break;
            case 4:
              if (item.approved == 1) {
                this.single_selecteds = this.$helper.Array.selecteds(this.single_selecteds, item.id)
              }
              break;
          }
        }, this)
        this.loadingSkeleton = false
      }).catch(error => {
        this.loadingSkeleton = false
      })
      await this.$store
        .dispatch("deductionRules/getCurrentTaxes", param_taxes).then(response => {
          let commercial_tax = response.data
          let total_value = roundWithDecimal(Number(this.sumConcrete) + Number(this.sumServices) + Number(this.sumAdditionals) + Number(this.sumSingle), 2)
          if (commercial_tax.total_rate && this.contract_proposal.reidi) {
            this.reidi = roundWithDecimal(total_value / 100 * commercial_tax.total_rate, 2)
            this.reidi_percentage = commercial_tax.total_rate
          }
          else {
            this.reidi = 0
            this.reidi_percentage = null
          }
        })
    },
    prepareFederalTaxes(params) {
      params.cofins_rate = params.cofins_retained_flag && strToNum(params.cofins_rate) > 0
        ? strToNum(params.cofins_rate)
        : 0;
      params.csll_rate = params.csll_retained_flag && strToNum(params.csll_rate) > 0
        ? strToNum(params.csll_rate)
        : 0;
      params.inss_rate = params.inss_retained_flag && strToNum(params.inss_rate) > 0
        ? strToNum(params.inss_rate)
        : 0;
      params.ir_rate = params.ir_retained_flag && strToNum(params.ir_rate) > 0
        ? strToNum(params.ir_rate)
        : 0;
      params.pis_rate = params.pis_retained_flag && strToNum(params.pis_rate) > 0
        ? strToNum(params.pis_rate)
        : 0;
    },
    /**
     * @param {string} schedule_travel_uuid
     */
    downloadProof(schedule_travel_uuid) {
      let params = {
        uuid: schedule_travel_uuid,
        type: "proof"
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf"); // Added Line
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },
    /**
     * @param {string} service_order_uuid
     */
    getReport(service_order_uuid) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("scheduleService/generateReport", service_order_uuid)
        .then(response => {
          let blob = new Blob([response], {
            type: "application/pdf"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },

    /**
     * Abre modal de alteração de valor de serviço
     *
     * @param {object} item
     * @returns void
     */
    handleModalChangeServiceValue(item) {
      if (!this.$user.financial_limit?.invoice_can_change_service_value) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para alterar o preço do serviço ',
        })
      }
      this.$refs.modalChangeServiceValue.openModal(item.id)
    },
    /**
     * Abre modal de alteração de valor de adicionais
     *
     * @param {object} item
     * @returns void
     */
    handleModalChangeAdditionalValue(item) {
      if (!this.$user.financial_limit?.invoice_can_change_additional_value) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Usuário não possui permissão para alterar o preço do aditional ',
        })
      }
      this.$refs.modalChangeAdditionalValue.openModal(item)
    },
  },
}
</script>

<style scoped>
.card-info {
  border-radius: 10px !important;
}
</style>
