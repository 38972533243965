<template>
  <div>
    <modal size="md" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div class="row mt-1 mb-2">
          <div class="col pr-0">
            <base-button
              @click.prevent="selected = 0"
              :class="selected === 0 && 'active'"
              class="button-border-left border-black"
              outline
              type="primary"
              block
            >
              <span
                class="text-dark"
                :class="selected === 0 ? 'text-white' : 'text-dark'"
                style="font-size: 12px"
              >
                Identificação
              </span>
            </base-button>
          </div>
          <div class="col px-0">
            <base-button
              @click.prevent="selected = 1"
              :class="selected === 1 && 'active'"
              style="border-radius: 0% !important"
              type="primary"
              class="btn-left-none border-black"
              outline
              block
            >
              <span
                class="text-dark"
                :class="selected === 1 ? 'text-white' : 'text-dark'"
                style="font-size: 12px"
              >
                Operacional
              </span>
            </base-button>
          </div>
          <div class="col pl-0">
            <base-button
              @click.prevent="selected = 2"
              :class="selected === 2 && 'active'"
              class="button-border-right btn-left-none border-black"
              outline
              type="primary"
              block
            >
              <span
                class="text-dark"
                :class="selected === 2 ? 'text-white' : 'text-dark'"
                style="font-size: 12px"
              >
                Comercial
              </span>
            </base-button>
          </div>
        </div>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(store)"
            autocomplete="off"
          >
            <!-- IDENTIFICAÇÃO -->
            <div v-show="selected === 0">
              <div class="form-group row m-0 p-0 mb-2">
                <div class="col-md-3 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Status
                  </div>
                  <base-switch
                    v-model="user.status"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>

                <div class="col-md-3 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Usuário
                  </div>
                  <base-switch
                    v-model="user.is_user"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  ></base-switch>
                </div>

                <div class="col-md-3 mb-1 pr-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Intermediador
                  </div>
                  <base-switch
                    v-model="user.is_intermediary"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                  />
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    Nome completo
                    <span class="text-danger">&nbsp;*</span>
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input input-classes="form-control-sm">
                      <input
                        v-model="user.name"
                        type="text"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    E-mail
                    <span class="text-danger" v-show="user.is_user"
                      >&nbsp;*</span
                    >
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <validation-provider
                    :rules="user.is_user ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <base-input input-classes="form-control-sm">
                      <input
                        v-model="user.email"
                        type="email"
                        class="form-control form-control-sm"
                        :class="
                          user.is_user &&
                          (errors[0] ? 'is-invalid' : 'is-valid')
                        "
                        :disabled="!user.is_user"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    Senha
                    <span class="text-danger" v-show="user.is_user"
                      >&nbsp;*</span
                    >
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <validation-provider
                    :rules="user.is_user ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <base-input input-classes="form-control-sm">
                      <input
                        v-model="user.password"
                        type="password"
                        class="form-control form-control-sm"
                        :class="
                          user.is_user &&
                          (errors[0] ? 'is-invalid' : 'is-valid')
                        "
                        autocomplete="off"
                        :disabled="!user.is_user"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    Confirmar senha
                    <span class="text-danger" v-show="user.is_user"
                      >&nbsp;*</span
                    >
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <validation-provider
                    :rules="user.is_user ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <base-input input-classes="form-control-sm">
                      <input
                        v-model="user.confirm_password"
                        type="password"
                        class="form-control form-control-sm"
                        :class="
                          user.is_user &&
                          (errors[0] ? 'is-invalid' : 'is-valid')
                        "
                        autocomplete="off"
                        :disabled="!user.is_user"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    Celular (trabalho)
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <input
                    class="form-control form-control-sm"
                    v-model="user.phone"
                    inputmode="numeric"
                    v-mask="['(##) # ####-####']"
                  />
                </div>
              </div>
            </div>

            <!-- OPERACIONAL -->
            <div v-show="selected === 1">
              <div class="form-group row m-0 p-0 mb-3">
                <div class="col px-1">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Motorista ?
                  </div>
                  <base-switch
                    v-model="user.driver"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                    @input="checkDriverStatus()"
                  ></base-switch>
                </div>

                <div class="col px-1">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Concreto
                  </div>
                  <base-switch
                    v-model="user.is_concrete"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                    :disabled="!user.driver"
                  ></base-switch>
                </div>

                <div class="col px-1">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Serviço
                  </div>
                  <base-switch
                    v-model="user.is_service"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                    :disabled="!user.driver"
                  ></base-switch>
                </div>

                <div class="col px-1">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Terceiro
                  </div>
                  <base-switch
                    v-model="user.is_external_service"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                    :disabled="!user.driver"
                  ></base-switch>
                </div>

                <div class="col px-1">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Insumo
                  </div>
                  <base-switch
                    v-model="user.is_entry_driver"
                    type="success"
                    offText="não"
                    onText="sim"
                    class="success"
                    :disabled="!user.driver"
                  ></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    Categoria de equipamento
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.equipment_category_id"
                      :items="$_equipmentCategorys"
                      label="category_name"
                      :loading="loadingEquipmentsCategorys"
                      :disabled="loadingEquipmentsCategorys || !user.driver"
                      @input="getEquipmentByCategory"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    Equipamento padrão
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="user.equipment_default"
                      :items="$_equipmentsByCategory"
                      customKey="uuid"
                      :labelMask="true"
                      :loading="loadingEquipmentsCategorys"
                      :disabled="
                        loadingEquipmentsCategorys ||
                        !user.equipment_category_id
                      "
                      label="code$ | $equipment_types_description"
                    />
                  </base-input>
                </div>
              </div>
            </div>

            <!-- COMERCIAL -->
            <div v-show="selected === 2">
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-12">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Vendedor / Representante comercial
                  </div>
                  <base-switch
                    v-model="user.is_seller"
                    type="success"
                    offText="inativo"
                    onText="ativo"
                    class="success"
                    @input="checkSellerStatus()"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-12">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Receber E-mails de faturamento
                  </div>
                  <base-switch
                    v-model="user.is_invoicing_emails"
                    type="success"
                    offText="Não"
                    onText="Sim"
                    class="success"
                    :disabled="!user.is_seller"
                  >
                  </base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5 mb-1 pr-4">
                  <div class="pb-0 mb-1 form-control-label">
                    Regra de Comissão
                  </div>
                </div>
                <div class="col-md-7 mb-2 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="others_commission.commision"
                      inputmode="numeric"
                      v-mask="['##']"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <hr class="mt-3 mb-3 border-settings" />

              <div class="row align-items-center mb-3 mt-1">
                <div class="col-9">
                  <h5 class="h4 m-0">Comissão sobre outros colaboradores</h5>
                </div>
                <div class="col-3">
                  <div class="pb-1 col-form-label form-control-label">
                    <span
                      class="btn btn-sm btn-primary p-1 ml-1"
                      @click.prevent="addOtherCommission()"
                    >
                      <img
                        src="/img/icons/plus-2.png"
                        width="15px"
                        height="15px"
                      />
                      adicionar
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-5 pb-1 col-form-label form-control-label">
                  Usuário
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="others_commission.user_uuid_commission"
                      :items="users"
                      customKey="uuid"
                      :loading="loadingUsers"
                      :disabled="loadingUsers"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label
                  class="col-md-5 pb-0 mb-1 col-form-label form-control-label"
                >
                  Comissão
                </label>
                <div class="col-md-7 mb-1 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="others_commission.commision"
                      inputmode="numeric"
                      v-mask="['##']"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0"> % </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div
                class="m-2 mt-3"
                v-for="(itemOtherCommision, index) in user.others_commissions"
                :key="index"
              >
                <card class="mb-1 border card--hover card-body-molde">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <h4>{{ itemOtherCommision.user_name }}</h4>
                    </div>
                    <div class="col">
                      <h4>{{ itemOtherCommision.commision | percent(0) }}</h4>
                    </div>
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-sm rounded-circle btn-danger"
                        @click="removeOtherCommission(index)"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </card>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                <img src="/img/icons/save.png" width="18px" height="18px" />
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalEditUser ref="modalEditUser" />
    <ModalUserPermission ref="modalUserPermission" />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import ModalEditUser from "./_ModalEdit.vue";
import ModalUserPermission from './_ModalUserPermission';
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")
import { seller as $_totvs }  from "@/plugins/microservices/totvs";

export default {
  name: "ModalCreateUser",
  components: {
      PuzlSelect,
      ModalEditUser,
      ModalUserPermission,
  },
  data() {
    return {
      modal: {
          title: 'Novo Usuário',
          create: false,
      },
      selected: 0,
      loadingPlant: true,
      loadingSave: false,
      invalid: false,
      pickerOptions: {
          disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
          }
      },
      user: {
        status: true,
        is_user: true,
        is_intermediary: false,
        is_seller: false,
        is_concrete: false,
        is_service: false,
        is_invoicing_emails: false,
        name: null,
        short_name: null,
        email: null,
        password: null,
        confirm_password: null,
        document: null,
        phone: null,
        national_id_card: null,
        birth_date: null,
        marital_status: null,
        registration: null,
        job_description_uuid: null,
        contract_regime_uuid: null,
        admission_date: null,
        cost_center_uuid: null,
        chart_account_uuid: null,
        chart_account__uuid: null,
        company_plant_uuid: null,
        company_plant_issuer_id: null,
        driver: false,
        equipment_category_id: null,
        equipment_default: null,
        driver_type: null,
        driver_number: null,
        driver_validity: null,
        commission_uuid: null,
        others_commissions: []
      },
      others_commission: {
        user_name: null,
        user_uuid_commission: null,
        commision: null,
      },
      loadingStore: false,
      loadingUsers: true,
      loadingCompanyIssuers: true,
      loadingEquipmentsTypes: true,
      loadingEquipmentsCategorys: true,
      loadingEquipmentDefault: false,
      company_issuers: [],
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      users: "user/fetchForModalList",
      commissions: "commission/fetch",
      $_equipmentCategorys: "equipmentCategory/fetch",
      $_equipmentsByCategory: "equipment/fetchEquipmentsByCategory",
      $_authenticatedUser: 'auth/getUser',
    }),
  },
  watch: {
    'user.company_plant_uuid': function () {
      this.changePlantIssuer(this.user.company_plant_uuid)
    },
  },
  methods: {
    changePlantIssuer(company_plant_uuid) {
      this.loadingCompanyIssuers = true
      let [issuer] = this.plants.filter((item) => item.uuid === company_plant_uuid)
      this.company_issuers = issuer.company_issuers
      this.loadingCompanyIssuers = false
    },
    addOtherCommission() {
      let user_uuid_commission = this.others_commission.user_uuid_commission
      let others_commissions = this.user.others_commissions

      let commissions = {...this.others_commission}
      let [user] = this.users.filter((item) => item.uuid === user_uuid_commission)
      commissions.user_name = user.name
      commissions.user_uuid_commission = user_uuid_commission
      commissions.commision = this.others_commission.commision

      if(others_commissions.length > 0){
        let [check_user] = others_commissions.filter((item) => item.user_uuid_commission === user_uuid_commission)
        if(check_user && check_user.user_uuid_commission === user_uuid_commission){
          this.$notify({type: 'warning', message: 'Este usuário já foi selecionado!'})
          return
        } 
      }
      this.user.others_commissions.push(commissions)
    },
    removeOtherCommission(index) {
      this.user.others_commissions.splice(index, 1);
    },
    store() {
      this.$Progress.start();
      this.loadingStore = true;
	    this.user.short_name = this.user.name;
      this.$store
        .dispatch("user/add", this.user).then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.user.is_seller && $_totvs.store({...this.user, ...{id : response.data.id}});
          this.handleUserPermission(response.data.id, response.data.uuid)
		  		this.closeModal();
        })
        .catch(error => {
          if (error.status) {
            this.$Progress.finish()
            this.loadingStore = false
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors)
              this.$notify({
                type: 'danger',
                message: message
              })
              this.$Progress.finish()
              this.loadingStore = false
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
              this.$Progress.finish()
              this.loadingStore = false
            }
          }
        });
    },
    fileChange(e) {
      if (e.length) {
        if (e[0].size > 1000000) {
          this.$notify({
            type: "danger",
            message: "Não são aceitas fotos com tamanho superior a 1 MB!"
          });
          this.resetInput();
          return false;
        }
        this.image = e[0];
      }
    },
    resetInput() {
      this.imageInput = false;
      this.$nextTick(() => {
        this.imageInput = true;
      });
    },
    getEquipmentByCategory() {
      this.loadingEquipmentDefault = true
      this.$store.dispatch("equipment/getEquipmentByCategory", this.user.equipment_category_id).then(() => {
        this.loadingEquipmentDefault = false
      })
      this.user.equipment_default = null
    },
    checkDriverStatus() {
      if (!this.user.driver) {
        this.user.equipment_category_id = null;
        this.user.is_concrete = false;
        this.user.is_service = false;
        this.user.is_external_service = false;
        this.user.is_entry_driver = false;
      } else {
        this.user.is_concrete = true;
        this.user.is_service = false;
        this.user.is_external_service = false;
        this.user.is_entry_driver = false;
      }
      if (!this.user.equipment_category_id) {
        this.user.equipment_default = null;
      }
    },
    checkSellerStatus() {
      if (!this.user.is_seller) {
        this.user.is_invoicing_emails = false;
      }
    },
    /**
     * Abre modal de permissões do usuário.
     * @param {integer} userId
     * @param {string} userUuid
     */
    handleUserPermission(userId, userUuid) {
      this.$refs.modalUserPermission.openModal(userId, userUuid, this.$_authenticatedUser.id);
    },
    closeModal() {
        this.modal.create = false;
    },
    handleCreateModal() {
      this.user = {
        status: true,
        is_user: true, 
        is_intermediary: false,
        is_seller: false,
        is_concrete: false,
        is_service: false,
        is_external_service: false,
        is_entry_driver: false,
        is_invoicing_emails: false,
        name: null,
        short_name: null,
        email: null,
        password: null,
        confirm_password: null,
        document: null,
        phone: null,
        national_id_card: null,
        birth_date: null,
        marital_status: null,
        registration: null,
        job_description_uuid: null,
        contract_regime_uuid: null,
        admission_date: null,
        cost_center_uuid: null,
        chart_account_uuid: null,
        chart_account__uuid: null,
        company_plant_uuid: null,
        company_plant_issuer_id: null,
        driver: false,
        equipment_category_id: null,
        equipment_default: null,
        driver_type: null,
        driver_number: null,
        driver_validity: null,
        commission_uuid: null,
        others_commissions: [],
      };
      this.loadData();
      this.modal.create = true;
    },
    loadData() {
      this.loadingPlant = true;
      this.$store.dispatch("plant/fetchItemsActive", 0).then(() => {
        this.loadingPlant = false;
      });
      this.loadingUsers = true;
      this.$store.dispatch("user/fetchItemsForModalList").then(() => {
        this.loadingUsers = false;
      });
      this.loadingCommissions = true;
      this.$store.dispatch("commission/fetchItems").then(() => {
        this.loadingCommissions = false;
      });
      this.loadingEquipmentsCategorys = true;
      this.$store.dispatch("equipment/fetchItems").then(() => {
        this.loadingEquipmentsCategorys = false;
      });
      this.loadingEquipmentsCategorys = true;
      this.$store.dispatch("equipmentCategory/fetchItems").then(() => {
        this.loadingEquipmentsCategorys = false;
      });
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  }
};
</script>

<style scoped>
.border-black {
  border-color: black !important;
}

@media only screen and (max-width: 991px) {
  .base-button {
    font-size: 6px;
  }
}

.button-border-left {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-border-right {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-left-none {
  border-left: 0;
}

.btn-left-none:hover {
  transform: none !important;
}

.base-button:hover .text-dark {
  color: white !important;
}
</style>
