<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div v-if="group" class="col-12 border-left border-3 border-primary">
        <div class="row">
          <div class="col-12">
              <span class="h3 mt-1 mb-0 d-block">
            {{ group.product || '-' }}
                <br>
         <span class="mb-0 text-body text-muted">
           {{ group.supplier || '-' }}
           ({{ group.supplier_origin || '-' }})
              </span>
         </span>
          </div>
          <div class="col-12">
        <span class="h3 mt-1 mb-0 d-block">
        {{ group.dosage.text || '-' }}
          <span class="mb-0 text-body text-muted">
               {{ group.group_name || '-' }}
                 </span>
        </span>
          </div>
        </div>
      </div>
      <div v-if="group" class="col-12 mt-4">
        <form @submit.prevent="save">
          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
            <div class="row">
              <div class="col-7" v-if="group.charging.hasOwnProperty('wet_weight')">
                Teórico individual:
              </div>
              <div class="col-5" v-if="group.charging.hasOwnProperty('wet_weight')">
              <span class="h2" v-if="!group.charging.hasOwnProperty('accumulated_wet_weight')">
                <b style="font-size: 20px">
<!--                    {{ Number(decimals(group.category_id, group.charging.wet_weight)).toLocaleString() }}-->
               <a href="#" @click.prevent="group.manual_dosage = decimals(group.category_id, group.charging.wet_weight)">
                  <i style="font-size: 20px" class="fa-regular fa-copy text-primary"></i>
               </a>
                  {{ decimals(group.category_id, group.charging.wet_weight) }}
                  {{ group.dosage.unit.letter }}
              </b>
              </span>
                <span style="font-size: 20px" class="h3 text-muted" v-else>
               {{ decimals(group.category_id, group.charging.wet_weight) }} {{ group.dosage.unit.letter }}
              </span>
              </div>
              <div class="col-7" v-if="group.reuse != 0">
                Reuso:
              </div>
              <div class="col-5" v-if="group.reuse != 0">
                {{ decimals(group.category_id, group.reuse) }} {{ group.dosage.unit.letter }}
              </div>
              <div class="col-7" v-if="group.charging.hasOwnProperty('accumulated_wet_weight')">
                Teórico acumulado:
              </div>
              <div class="col-5" v-if="group.charging.hasOwnProperty('accumulated_wet_weight')">
               <span class="h2">
                    <a href="#" @click.prevent="group.manual_dosage = decimals(group.category_id, group.charging.accumulated_wet_weight)">
                  <i style="font-size: 20px" class="fa-regular fa-copy text-primary"></i>
               </a>
                <b style="font-size: 20px">
              {{ decimals(group.category_id, group.charging.accumulated_wet_weight) }} {{ group.dosage.unit.letter }}
              </b>
              </span>
              </div>
              <div class="col-7" v-if="group.automation !== null">
                Automação:
              </div>
              <div class="col-5" v-if="group.automation !== null">
                <span class="h2 text-primary">
                <b>
                {{ group.automation }} kg
              </b>
                </span>
              </div>
              <div class="col-6 mt-2" v-if="group.automation === null"><label>
                Carregamento manual:
              </label></div>
              <div class="col-6 mt-2" v-if="group.automation === null">
                <validation-provider rules="required">
                  <div v-if="category" class="input-group input-group-merge">
                    <input :disabled="group.has_acummulated_after || group.automation" v-if="modal" v-focus ref="input"
                           inputmode="numeric"
                           v-mask=mask()
                           v-model="group.manual_dosage"
                           style="max-height: 35px" type="numeric" class="form-control">
                    <div class="input-group-append">
                  <span style="max-height: 35px" class="input-group-text text-muted">{{
                      group.dosage.unit.letter
                    }}</span>
                    </div>
                  </div>
                </validation-provider>
              </div>
              <div class="col-6 mt-2"><label>
                Ajuste (Individual):
              </label></div>
              <div class="col-6 mt-2">
                <div v-if="category" class="input-group input-group-merge">
                  <input inputmode="numeric" v-mask=mask() v-model="group.adjustment"
                         style="max-height: 35px" type="numeric" class="form-control">
                  <div class="input-group-append">
                  <span style="max-height: 35px" class="input-group-text text-muted">{{
                      group.dosage.unit.letter
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-4 ml-4">
                <span>
                  Desvio : <span class="variant" :class="!validLimit() && 'text-warning'"><strong>
                 {{ setVariation() }} kg
                  <span>
                  ( {{
                      !isNaN((setVariation() / decimals(group.category_id, group.charging.wet_weight) * 100)) ? (setVariation() / decimals(group.category_id, group.charging.wet_weight) * 100).toFixed(2) : 0
                    }} % )
                  </span>
                </strong>
                </span>
                </span>
                <br>
                <span class="text-muted" v-show="!validLimit()">
                  <i class="text-warning fas fa-exclamation-triangle"></i>
                  Desvio fora do limite estabelecido.</span>
              </div>
            </div>
            <div class="float-right mt-4">
              <base-button type="secondary" @click.prevent="close" class="text-capitalize">
                cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                :loading="loading"
                :disabled="invalid || dontHaveMinLength"
              >
                Salvar
              </base-button>
            </div>
          </validation-observer>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
  components: {},
  name: "ModalManualDosage",
  data() {
    return {
      modal: false,
      title: 'Carregamento',
      group: null,
      adjustment: 0,
      indexes: {},
      category: null,
      travel: null,
      minLength: 0,
      dontHaveMinLength: true,
      loading: false,
      limit: 0,
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      },
    }
  },
  computed: {
    ...mapGetters({
      chargePoints: 'plantChargePoint/fetch',
    }),
  },
  mounted() {
    this.$watch('group.manual_dosage', function (val) {
      this.validLength(val)
    })
  },
  methods: {
    validLimit() {
      let percentage = Math.abs(this.setVariation() / this.group.charging.wet_weight * 100)
      percentage = isNaN(percentage) ? 0 : percentage
      if (this.limit > percentage && -this.limit < percentage) {
        return true
      }
      return false
    },
    validLength(val) {
      if (val == 0) {
        return this.dontHaveMinLength = false
      }
      if (!val) {
        return
      }
      val = val.toString()
      if (val && val.length >= this.minLength) {
        return this.dontHaveMinLength = false
      }
      return this.dontHaveMinLength = true
    },
    setVariationLimit() {
      const chargePoints = this.chargePoints
      const variation = chargePoints.find(item =>
        item.uuid == this.$parent.travel.charge_point_uuid).variation
      if (!variation) {
        return this.limit = 0
      }
      switch (this.category.id) {
        case 1:
          this.limit = variation.binder
          break;
        case 2:
          this.limit = variation.small_aggregate
          break;
        case 3:
          this.limit = variation.large_aggregate
          break;
        case 4:
          this.limit = variation.additive
          break;
        case 5:
          this.limit = variation.adiction
          break;
        case 6:
          this.limit = variation.water
          break;
      }
      this.limit = parseFloat(this.limit)
    },
    openModal(group, indexes, category, travel) {
      this.loading = false
      this.travel = travel
      this.indexes = indexes
      this.modal = true
      this.category = category
      switch (this.category.id) {
        case 4:
        case 5: {
          this.minLength = 4
          break;
        }
        default: {
          this.minLength = 1
          break;
        }
      }
      this.group = {...group}
      if (this.group.manual_dosage) {
        this.group.manual_dosage -= this.group.adjustment || 0
        this.group.manual_dosage = this.decimals(this.category.id, this.group.manual_dosage)
      }
      this.setVariationLimit()
      if (group.automation) {
        this.group.automation = this.decimals(this.category.id, this.group.automation)
        this.group.manual_dosage = 0
      }
    },
    close: function () {
      this.modal = false
    },
    setVariation() {
      /** pega o ajuste **/
      const adjustment = this.group.adjustment ? Number(this.group.adjustment) : 0
      const is_individual = this.group.charging.accumulated_wet_weight ? 0 : 1
      if (!is_individual) {
        const real_value = (parseFloat(Number(this.group.manual_dosage) + Number(this.group.automation))
          - this.group.previous_value) - adjustment
        let variant = real_value - this.group.charging.wet_weight
        variant = this.decimals(this.category.id, variant)
        if (variant > -1 && variant < 1) {
          return 0;
        }
        return variant
      }
      const variant = (Number(this.group.manual_dosage) + Number(this.group.automation)
        + adjustment) - this.group.charging.wet_weight
      return this.decimals(this.category.id, variant)
    },
    mask() {
      switch (this.category.id) {
        case 1: {
          return ['#', '#.#', '##.#', '###.#', '####.#', '#####.#']
        }
        case 4:
        case 5: {
          return ['#.##', '##.##', '###.##', '####.##']
        }
        default: {
          return ['######']
        }
      }
    },
    decimals(category, value) {
      if (value == 0) {
        return 0
      }
      value = parseFloat(value)
      switch (category) {
        case 2:
        case 3:
        case 6:
          return parseInt(value)
        case 1:
          return value.toFixed(1)
        default:
          return value.toFixed(2)
      }
    },
    handleSubmit() {
      // this.loading = true
      let group = {...this.group}
      // // CARREGAMENTO - AJUSTE
      if (group.manual_dosage) {
        group.manual_dosage = parseFloat(group.manual_dosage) + parseFloat(group.adjustment || 0)
      }
      if (group.automation) {
        group.automation = parseFloat(group.automation) + parseFloat(group.adjustment || 0)
      }
      if (!this.group.is_individual && Number(group.group_before_manual_dosage) > Number(group.manual_dosage)) {
        return this.$notify({
          type: 'danger',
          message: 'O carregamento manual acumulado não pode ser inferior ao carregamento do grupo anterior.'
        })
      }
      let percentage = (this.setVariation() / group.charging.wet_weight * 100) != 0 ?
        (this.setVariation() / group.charging.wet_weight * 100).toFixed(2) : 0
      if (isNaN(percentage)) {
        percentage = 0
      }
      percentage = this.decimals(group.category_id, percentage)
      group.variation = `${this.setVariation()} (${percentage}%)`
      group.variation_value = this.setVariation()
      group.variation_percentage = `(${percentage}%)`
      // COLOCA OS DECIMAIS CORRETAMENTE
      group.manual_dosage = this.decimals(group.category_id, group.manual_dosage)
      group.is_individual = group.charging.accumulated_wet_weight ? 0 : 1
      if (!group.previous_manual_dosage && group.previous_value) {
        group.previous_manual_dosage = group.previous_value
      }
      group.charging_wet_weight = this.decimals(group.category_id, group.charging.wet_weight)
      this.$emit('setManualDosage', {
        group: group,
        indexCategory: this.indexes.indexCategory,
        indexGroup: this.indexes.indexGroup,
      })
    },
    save() {
      if (!this.validLimit()) {
        return Swal.fire({
          title: 'O desvio está fora do limite permitido! Deseja continuar?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Tenho certeza e desejo continuar',
          cancelButtonText: 'Fechar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.handleSubmit()
          }
        })
      }
      return this.handleSubmit()
    }
  },
};
</script>

<style>
.variant {
  font-size: 22px;
}
</style>
