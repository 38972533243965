<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title pl-2 ml-1">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <breadcrumb
                list-classes="bg-success border-header-primary-bottom"
              >
                Programação e Pesagem
              </breadcrumb>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Emissão de nota fiscal
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-switch v-model="generalSettings.nfe" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Exigir preenchimento de "CNO" em propostas/contratos  
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-switch 
                      v-model="generalSettings.is_cno_required_prop_contracts" 
                      type="primary" 
                      offText="Não" 
                      onText="Sim" 
                      class="success" />
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Exigir preenchimento de "Cód. de obra da prefeitura" em propostas/contratos
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-switch 
                      v-model="generalSettings.is_municipal_work_code_required_prop_contracts" 
                      type="primary" 
                      offText="Não" 
                      onText="Sim" 
                      class="success" />
                  </validation-provider>
                </div>
              </div>
              
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Exigir preenchimento de art/encapsulamento em propostas/contratos
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-switch 
                      v-model="generalSettings.is_art_encapsulation_required" 
                      type="primary" 
                      offText="Não" 
                      onText="Sim" 
                      class="success" />
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Permite realizar carregamento simultâneos
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-4 pr-4 mb-2">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-switch 
                      v-model="generalSettings.is_concurrent_loading_allowed" 
                      type="primary" 
                      offText="Não" 
                      onText="Sim" 
                      class="success" />
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Prazo para início de carregamento
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-5 pr-4 mb-2">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="
                          generalSettings.schedule_start_load_time = formatString(
                            $event.target.value,
                            '0',
                            '99',
                            ''
                          )
                        "
                        v-model="generalSettings.schedule_start_load_time"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Limite de edição do horário de entrega por viagem
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-5 pr-4 mb-2">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="
                          generalSettings.schedule_travel_edit_time = formatString(
                            $event.target.value,
                            '0',
                            '99',
                            ''
                          )
                        "
                        v-model="generalSettings.schedule_travel_edit_time"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Limite máximo permitido para exclusão de programação
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-5 pr-4 mb-2">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="
                          generalSettings.schedule_delete_time = formatString(
                            $event.target.value,
                            '0',
                            '99',
                            ''
                          )
                        "
                        v-model="generalSettings.schedule_delete_time"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-7 pt-0 pb-0 col-form-label form-control-label pl-3 mb-2"
                >
                  <div class="pt-1">
                    Prazo para emissão e/ou exclusão da O.S (ordem de serviço)
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </div>
                </label>
                <div class="col-md-5 pr-4 mb-2">
                  <validation-provider rules="required|min_value:1" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="
                          generalSettings.order_service_time = formatString(
                            $event.target.value,
                            '0',
                            '999',
                            ''
                          )
                        "
                        v-model="generalSettings.order_service_time"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          horas
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal()">
                  Cancelar
                </base-button>
                <base-button
                  type="success"
                  native-type="submit"
                  v-bind:disabled="invalid"
                  :loading="loadingSave"
                >
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";

export default {
  name: "ModalGeneralSettings",
  data() {
    return {
      title: "Configurações gerais",
      modal: false,
      loadingSave: false,
      generalSettings: {
        schedule_start_load_time: null,
        schedule_delete_time: null,
        schedule_travel_edit_time: null,
        company_plant_uuid: null,
        order_service_time: null,
        nfe: true,
        is_cno_required_prop_contracts: false,
        is_municipal_work_code_required_prop_contracts: false,
        is_art_encapsulation_required: false,
        is_concurrent_loading_allowed: false,
      }
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal(uuid) {
      this.$Progress.start();
      this.$store
        .dispatch("plant/getGeneralSettings", uuid)
        .then(response => {
          this.generalSettings = {
            schedule_start_load_time:
              response.data.schedule_start_load_time ?? null,
            schedule_delete_time: response.data.schedule_delete_time ?? null,
            order_service_time: response.data.order_service_time ?? null,
            nfe: response.data.nfe ?? true,
            is_cno_required_prop_contracts: response.data.is_cno_required_prop_contracts ?? false,
            is_municipal_work_code_required_prop_contracts: response.data.is_municipal_work_code_required_prop_contracts ?? false,
            is_art_encapsulation_required: response.data.is_art_encapsulation_required ?? false,
            is_concurrent_loading_allowed: response.data.is_concurrent_loading_allowed ?? false,
            schedule_travel_edit_time:
              response.data.schedule_travel_edit_time ?? null,
            company_plant_uuid: response.data.company_plant_uuid ?? uuid
          };
          this.$Progress.finish();
          this.modal = true;
        })
        .catch(error => {
          this.generalSettings = {
            schedule_start_load_time: null,
            schedule_delete_time: null,
            schedule_travel_edit_time: null,
            nfe: true,
            is_cno_required_prop_contracts: false,
            is_municipal_work_code_required_prop_contracts: false,
            is_art_encapsulation_required: false,
            is_concurrent_loading_allowed: false,
            company_plant_uuid: uuid
          };
          this.modal = true;
          this.$Progress.finish();
        });
    },
    save() {
      this.$Progress.start();
      this.loadingSave = true;
      this.generalSettings.schedule_delete_time = parseInt(
        this.generalSettings.schedule_delete_time
      );
      this.generalSettings.schedule_start_load_time = parseInt(
        this.generalSettings.schedule_start_load_time
      );
      this.generalSettings.schedule_travel_edit_time = parseInt(
        this.generalSettings.schedule_travel_edit_time
      );
      this.$store
        .dispatch("plant/addGeneralSettings", this.generalSettings)
        .then(response => {
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.closeModal();
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingSave = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    }
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
};
</script>

<style scoped></style>
