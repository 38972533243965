import { StockLocationCreateType, StockLocationListFilterType, StockLocationListPagerType, StockLocationUpdateType, StockLocationViewType } from "../types";
import { stockLocationCreateService, stockLocationDeleteService, stockLocationFindService, stockLocationListService, stockLocationUpdateService } from "../services";
import mutations from "./stockLocationStoreMutations";
import getters from "./stockLocationStoreGetters";
import { listItems } from "../../../../helpers";

/**
 * Incluir
 *
 * @param {StockLocationCreateType} data
 * @returns {Promise<?StockLocationViewType>}
 */
const create = async (data) => {
  try {
    mutations.setIsSaving(true);

    const created = await stockLocationCreateService.execute(data);
    return mutations.setNewItemInList(created);
  } finally {
    mutations.setIsSaving(false);
  }
};

/**
 * Localizar por ID
 *
 * @param {number|string} id
 * @returns {Promise<?StockLocationViewType>}
 */
const find = async (id) => {
  try {
    mutations.setIsFinding(true);
    
    const found = await stockLocationFindService.execute(id);
    return mutations.setItem(found);
  } finally {
    mutations.setIsFinding(false);
  }
};

/**
 * Listar
 *
 * @param {StockLocationListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @returns {Promise<?StockLocationListPagerType>} - Retorno paginado
 */
const list = async (filter, isAccumulateItems = false) => {
  try {
    mutations.setIsListing(true);

    // Retorna a lista requisitada
    if (!isAccumulateItems) {
      const listed = await stockLocationListService.execute(filter);
      return mutations.setListed(listed);
    }

    // Retorna null quando não há próxima página disponível
    const existingListed = getters.getListed();
    if (!existingListed?.has_next_page) {
      return null;
    }

    // Retorna a lista existente combinada com a lista requisitada
    filter.page = existingListed.next_page;
    const newListed = await stockLocationListService.execute(filter);
    return mutations.setListMerge(newListed);
  } finally {
    mutations.setIsListing(false);
  }
};

/**
 * Deletar por ID
 *
 * @param {number} id
 * @returns {Promise<void>}
 */
const remove = async (id) => {
  try {
    mutations.setIsRemoving(true);
    
    await stockLocationDeleteService.execute(id);
    mutations.setRemovedItem(id);
  } finally {
    mutations.setIsRemoving(false);
  }
};

/**
 * Atualizar por ID
 *
 * @param {StockLocationUpdateType} data
 * @param {number} id
 * @returns {Promise<?StockLocationViewType>}
 */
const update = async (data, id) => {
  try {
    mutations.setIsSaving(true);
    
    const updated = await stockLocationUpdateService.execute(data, id);
    return mutations.setUpdatedItem(updated);
  } finally {
    mutations.setIsSaving(false);
  }
};

export default {
  create,
  find,
  list,
  remove,
  update,
};
