<template>
  <div>
    <PuzlEmptyData v-if="!company_plant_issuers.length && !$parent.loading" />
    <div class="row card-wrapper" v-if="company_plant_issuers.length">
      <div class="col-lg-4" v-for="(item, indexCompany) in company_plant_issuers" :key="indexCompany">
        <div class="card" style="box-shadow: none !important; border-radius: 8px !important; border: 0.5px solid #E8E8E8;">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <section class="top-section d-flex align-items-center mb-1">
                <span v-if="item.status" class="status-badge-success d-flex align-items-center">
                  <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                  Ativo
                </span>
                <span v-else class="status-badge-danger d-flex align-items-center">
                  <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                  Inativo
                </span>
                <div class="row m-0 ml-2">
                    <span class="mx-1">
                      <el-popover placement="bottom" trigger="hover">
                        <div style="width: 300px;">
                          <span style="font-weight: 500;color: #2B2D32;font-size: 12px;">
                            INFORMAÇÕES
                          </span>
                          <hr class="popover-hr">
                          <div class="w-100 d-flex align-items-start mt-3">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Inscrição Municipal</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.municipal_registration || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Inscrição Estadual</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.state_registration || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Código IBGE</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.ibge_code || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Cod. Serviço do Município</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.municipal_service_code || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Item da lista de serviço LC116</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.service_list_item || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">CNAE</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.cnae || "-" }}</span>
                          </div>
                        </div>
                        <base-button
                          slot="reference"
                          class="shadow-none p-0 m-0"
                          outiline
                          size="sm"
                          type="secondary"
                        >
                          <img class="pointer" style="width: 20px" src="/img/icons/icons8/ios/info-circle_warning.png" />
                        </base-button>
                      </el-popover>
                    </span>
                  </div>
              </section>
              <div class="ml-auto">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="empty" size="sm" class="dropdown-toggle shadow-none mt--2 mr--2" style="border-radius: 4px !important;">
                    <img height="30px" width="30px" src="/img/icons/icons8/ios/settings--v1_primary.png" class="mr-1 mt-n1"/>
                  </base-button>
                  <span class="dropdown-item mt-n1" @click.prevent="editIssuerDocument(item.id)">
                    <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                    EDITAR
                  </span>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <span class="dropdown-item" :class="!$hasPrivilege(1) ? 'mb-n2' : ''" @click="handleRetentionModal(item)">
                    <img src="/img/icons/ledger.png" width="19px" height="19px" class="imgBaseButton">
                    RETENÇÕES
                  </span>
                  <div v-if="$hasPrivilege(1)" class="dropdown-divider p-0 m-0"></div>
                  <span v-if="$hasPrivilege(1)" class="dropdown-item mb-n2" @click.prevent="removeIssuerDocument(item.id)">
                    <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px" class="imgBaseButton">
                    EXCLUIR
                  </span>
                </base-dropdown>
              </div>
            </div>
            <section class="mid-section mt-2 d-flex flex-column">
              <span class="company-plant-name">
                  {{ item.alias_name }}
                </span>
                <span class="company-plant-document">
                  {{ item.ein }}
                </span>
            </section>
            <section class="bottom-section mt-3 mb-n2">
              <div v-if="item.certificate_exists && item.expired" class="d-flex align-items-center certificate-badge expired-certificate-badge py-1">
                <span class="d-flex align-items-center justify-content-center w-100" style="gap: 5px;">
                  <img src="/img/icons/icons8/ios/grand-master-key_gray.png" alt="grand-master-key_gray" width="16">
                  Certificado vencido: {{ item.expired_at | parseDate("DD MMM YYYY") }}
                </span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <ModalCreateRetentions ref="ModalCreateRetentions"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCreateRetentions from "./_RetentionCreate.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";

export default {
  name: "ListIssuerDocument",
  components: {
    ModalCreateRetentions,
    PuzlEmptyData,
  },
  data() {
    return {
      showModalEdit: false
    };
  },
  computed: {
    ...mapGetters({
      company_plant_issuers: "companyPlantIssuer/fetch"
    })
  },
  methods: {
    load() {},
    handleRetentionModal(entity){
      this.$refs['ModalCreateRetentions'].handleCreateRetentionModal(entity);
    },
    editIssuerDocument(id) {
      this.$router.push({path: `/configuration/plant/document/issuer-document/edit/${id}`});
    },
    removeIssuerDocument(id) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("companyPlantIssuer/destroy", id)
        .then(response => {
          this.$notify({ type: response.error_type, message: response.message });
          this.$Progress.finish();
          loader.hide();
        })
        .catch(error => {
          this.$notify({ type: error.data.error_type, message: error.data.message });
          this.$Progress.finish();
          loader.hide();
        });
    }
  }
};
</script>

<style scoped>
.status-badge-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-badge-danger {
  background-color: #FCF3F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.company-plant-name {
  font-size: 16px;
  font-weight: 500;
  color: #2B2D32;
}
.company-plant-document {
  font-size: 14px;
  font-weight: 400;
  color: #2B2D32;
}
.certificate-badge {
  border-radius: 16px;
}
.certificate-badge span {
  font-size: 12px;
  font-weight: 400;
  color: #606062;
}
.valid-certificate-badge {
  background-color: #F2F4F9;
}
.expired-certificate-badge {
  background-color: #FCF3F3;
}
.close-expiration-certificate-badge {
  background-color: #FEF9F2;
}
.popover-hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 108%;
  margin-left: -11px;
}
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 10%;
}
</style>
