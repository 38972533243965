import { render, staticRenderFns } from "./_ModalAllLimitsSettingCreate.vue?vue&type=template&id=27f87533&scoped=true"
import script from "./_ModalAllLimitsSettingCreate.vue?vue&type=script&lang=js"
export * from "./_ModalAllLimitsSettingCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f87533",
  null
  
)

export default component.exports