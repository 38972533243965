<template>
  <div
    class="card-body mt-n2 p-0"
  >
    <div
      class="row"
    >
      <ShadedTable
        :length="bill_receives.length"
        :headers="headers"
        :loading="loadingSkeleton"
      >
        <template
          v-for="(bill_receive, index) in bill_receives"
          :slot="index"
        >
          <ShadedCol
            align="center"
            customStyle="display: flex;"
          >
            <EntityPossibleActions
              :PossibleActionsDropdownConstructor="buildDropdownPossibleActions(bill_receive)"
              @requestChangeInstallmentStatus="requestChangeInstallmentStatus"
              listType="table"
              :index="index"
            />
          </ShadedCol>
          <ShadedCol>
            <div>
              <el-popover
                trigger="click"
                placement="right"
                v-if="bill_receive.entity_id || bill_receive.type === 1"
              >
                <span
                  style="width: 230px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                  class="row"
                >
                  <div
                    class="col-12 mb-2"
                    style="display: flex;
                    align-items: center;"
                  >
                    <h4
                      style="font-size: 14px;"
                      class="p-0 m-0 font-weight-500 new-default-black-font"
                    >
                      CPF/CNPJ
                    </h4>
                  </div>
                  <div
                    class="col-12 mb-2 px-0"
                  >
                    <hr class="gray-divider m-0">
                  </div>
                  <div
                    class="col-12"
                  >
                    <h3
                      class="m-0 mb-1 font-weight-400 new-default-black-font"
                    >
                      {{ bill_receive.document }}
                    </h3>
                  </div>
                </span>
                <h5
                  slot="reference"
                  class="m-0 new-default-black-font font-weight-normal pointer"
                  style="display: inline-flex;"
                >
                  {{ bill_receive.entity_name }}
                </h5>
              </el-popover>
              <h5
                v-else
                class="m-0 new-default-black-font font-weight-normal"
                style="display: inline-flex;"
              >
                DEPÓSITO NÃO IDENTIFICADO
              </h5>
              <div style="float: right;">
                <base-button
                  v-show="Boolean(bill_receive.exists_attachments)"
                  @click.prevent="$emit('handleShowAttachments', bill_receive, index)"
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 mr-2 shadow-none text-indigo"
                >
                  <img
                    src="/img/icons/attach-green.png"
                    width="25"
                  >
                </base-button>
                <el-popover
                  trigger="click"
                  placement="bottom"
                >
                  <span
                    style="width: 170px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                    class="row"
                  >
                    <div
                      class="col-12 mb-2"
                      style="display: flex;
                      align-items: center;"
                    >
                      <h4
                        style="font-size: 14px;"
                        class="p-0 m-0 font-weight-500 new-default-black-font"
                      >
                        Conta Bancária
                      </h4>
                    </div>
                    <div
                      class="col-12 mb-2 px-0"
                    >
                      <hr class="gray-divider m-0">
                    </div>
                    <div
                      class="col-12"
                    >
                      <h5
                        class="font-weight-normal new-default-black-font m-0 mb-1"
                      >
                        {{ bill_receive.bank_account_name }}
                      </h5>
                      <h5
                        class="font-weight-normal new-default-black-font m-0"
                      >
                        {{ bill_receive.document }}
                      </h5>
                    </div>
                  </span>
                  <base-button
                    outline
                    slot="reference"
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 mr-2 shadow-none text-indigo"
                  >
                    <img
                      src="/img/icons/merchant-account.png"
                      width="25"
                    >
                  </base-button>
                </el-popover>
                <base-button
                  @click.prevent="$emit(
                    'handleModalEntityHistory',
                    bill_receive.entity_id,
                    bill_receive.entity_uuid,
                    bill_receive.document,
                    bill_receive.entity_name
                  )"
                  outline
                  slot="reference"
                  size="sm"
                  type="secundary"
                  class="p-0 m-0 mr-2 shadow-none text-indigo"
                >
                  <img
                    src="/img/icons/wallet.png"
                    width="25"
                  >
                </base-button>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5
                class="m-0 mt-1 new-default-black-font font-weight-normal"
                v-if="bill_receive.payment_method_name"
              >
                {{ bill_receive.payment_method_name }}
              </h5>
            </div>
            <div>
              <i
                v-if="
                  bill_receive.last_billet !== false ||
                  (
                    bill_receive.last_billet === false
                    && bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5'
                    && parseInt(bill_receive.status) !== 3
                    && parseInt(bill_receive.status) !== 1
                  )
                "
                @click.prevent="
                  bill_receive.last_billet !== false
                  ? $emit('downloadBilletBatch', bill_receive.bank_billet_batch_id, bill_receive.last_billet)
                  : $emit('showModalGenerateBillet', bill_receive.id, bill_receive.total_index, bill_receive.type)
                "
                :class="bill_receive.last_billet !== false ? backgroundBillet(bill_receive.last_billet) : 'new-default-black-font'"
                class="fa-solid fa-barcode mt-1 pointer"
                style="font-size: 18px;"
              ></i>
            </div>
          </ShadedCol>
          <ShadedCol align="center" style="width: 15px;">
            <h5
              v-if="bill_receive.entity_bill_receive_competence"
              class="m-0 mt-1 new-default-black-font font-weight-normal"
            >
              {{ bill_receive.entity_bill_receive_competence | parseDate('DD/MM/YYYY') }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center" style="width: 15px;">
            <h5
              v-if="bill_receive.due_date"
              class="m-0 mt-1 new-default-black-font font-weight-normal"
            >
              {{ bill_receive.due_date | parseDate('DD/MM/YYYY') }}
            </h5>
          </ShadedCol>
          <ShadedCol align="center" style="width: 15px;">
            <h5
              v-if="bill_receive.paid_at"
              class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ bill_receive.paid_at | parseDate('DD/MM/YYYY') }}
            </h5>
            <div v-else-if="!bill_receive.paid_at && showCompensationButtons(bill_receive)">
              <base-button
                v-if="$hasPermission('additional_permissions.financial_approvation') &&
                $helper.strToNum(bill_receive.anticipation_balance) > 0"
                @click.prevent="$emit('showModalEntityPayment', bill_receive.contract_proposal_payment_uuid, bill_receive.installment_id, bill_receive.contract_proposal_uuid)"
                outline size="sm" type="secundary"
                title="Compensação Parcial"
                class="p-0 m-0 ml-1 shadow-none text-indigo">
                <img class="mr-1" width="24" src="/img/icons/icons8/ios/refund-2_primary.png"/>
              </base-button>
              <base-button
                v-if="bill_receive.type === 1 &&
                compareBalance(bill_receive.anticipation_balance, bill_receive.total_value) &&
                $hasPermission('bill_receive.receive_installment')" outline size="sm" type="secundary"
                @click.prevent="$emit('handlePayOffPayment', bill_receive)"
                title="Compensação Total"
                class="p-0 m-0 ml-1 shadow-none text-indigo">
                <img class="mr-1" width="24" src="/img/icons/icons8/ios/us-dollar-circled--v1.png"/>
              </base-button>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div
              style="align-items: center;"
            >
              <h5
                class="m-0 mt-1 new-default-black-font font-weight-normal"
                style="display: inline-flex;"
              >
              {{ bill_receive.value  | currency() }}
              </h5>
              <div
                class="no-shadow-card-blue"
                style="float: right;margin-top: 5px;"
              >
                <h4
                  class="m-0 p-0"
                >
                  {{ bill_receive.index + "/" + bill_receive.total_index }}
                </h4>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <SettingOptions
              :bill_receive="bill_receive"
              @handleEditBillReceive="handleEditBillReceive"
              @handleOpenModalInfo="handleOpenModalInfo"
              @handleShowHistory="handleShowHistory"
              @handleIdentifyEntity="handleIdentifyEntity"
              @showModalGenerateBillet="showModalGenerateBillet"
              @showModalGenerateBilletHasGenerated="showModalGenerateBilletHasGenerated"
              @postBatchUpdate="postBatchUpdate"
              @handleModalEntityBillReceiveIntegrationInfo="handleModalEntityBillReceiveIntegrationInfo"
              @handleDeleteBillReceive="handleDeleteBillReceive"
              @showModalEditGenerateBilletHasGenerated="showModalEditGenerateBilletHasGenerated"
              @showModalChangeDueDate="showModalChangeDueDate"
              listType="table"
            />
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>
<script>
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import EntityPossibleActions from "../Common/_EntityPossibleActions.vue";
import SettingOptions from "../Common/_SettingOptions.vue";

export default {
  name: "TableBillReceive",
  props: {
    bill_receives: Array,
    loadingSkeleton: Boolean,
    showCompensationButtons: { type: Function },
    buildDropdownPossibleActions: { type: Function },
    compareBalance: { type: Function },
  },
  components: {
    ShadedTable,
    ShadedCol,
    EntityPossibleActions,
    SettingOptions
  },
  data() {
    return {
      headers: ["Status", "Cliente", "Método", "Venda", "Venc.", "Crédito", "Valor", "Ação"]
    };
  },
  methods: {
    logIt(obj){
      console.log(obj);
    },
    showModalChangeDueDate(bill_receive){
      this.$emit('showModalChangeDueDate', bill_receive);
    },
    showModalEditGenerateBilletHasGenerated(bill_receive){
      this.$emit('showModalEditGenerateBilletHasGenerated', bill_receive.id, bill_receive.total_index, bill_receive.type, bill_receive.index, bill_receive.index);
    },
    handleEditBillReceive(bill_receive){
      this.$emit('handleEditBillReceive', bill_receive.id);
    },
    handleOpenModalInfo(bill_receive){
      this.$emit('handleOpenModalInfo', bill_receive);
    },
    handleShowHistory(bill_receive){
      this.$emit('handleShowHistory', bill_receive);
    },
    handleIdentifyEntity(bill_receive){
      this.$emit('handleIdentifyEntity', bill_receive);
    },
    showModalGenerateBillet(bill_receive){
      this.$emit('showModalGenerateBillet', bill_receive.id, bill_receive.total_index, bill_receive.type);
    },
    showModalGenerateBilletHasGenerated(bill_receive){
      this.$emit('showModalGenerateBilletHasGenerated', bill_receive.id, bill_receive.total_index, bill_receive.type);
    },
    postBatchUpdate(bill_receive){
      this.$emit('postBatchUpdate', bill_receive.bank_billet_batch_id);
    },
    handleModalEntityBillReceiveIntegrationInfo(bill_receive){
      this.$emit('handleModalEntityBillReceiveIntegrationInfo', bill_receive.entity_bill_receive_id);
    },
    handleDeleteBillReceive(bill_receive){
      this.$emit('handleDeleteBillReceive', bill_receive.id);
    },
    requestChangeInstallmentStatus(newStatus, index){
      this.$emit('changeInstallmentStatus', this.bill_receives[index].installment_id, this.bill_receives[index].type, newStatus, index);
    },
    // Transformar em Enum
    backgroundBillet(bank_billet) {
      switch (bank_billet.status) {
        case 0:
          return 'text-primary'
        case 1:
          return 'text-warning'
        case 2:
          return 'text-danger'
        case 3:
          return 'text-success'
        case 4:
          return 'text-darkred'
        case 5:
        case 6:
          return 'text-dark'
      }
    },
  },
};
</script>

<style scoped>
.mini-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 1rem !important;
}

.colorize-on-hover:hover {
  background-color: #1b6eba;
}

.colorize-on-hover:hover small, .colorize-on-hover:hover img {
  filter: brightness(0) invert(1);
}


.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.no-shadow-card-blue {
  border-radius: 5px !important;
  background-color: #7498C7;
  padding: 0px 5px;
  /* box-shadow: 0 0 2px 0 #0b7bfd52; */
}

.no-shadow-card-blue h4 {
  color: white;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4 {
  font-size: 12px;
}
</style>


