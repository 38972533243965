<template>
  <modal size="md" :show.sync="modal" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img height="25" src="/img/icons/autograph.png" alt="autograph" />
        {{ title }}
      </h5>
    </template>
    <MultiFilter
      class="filter"
      @fetch="fetch"
      :type="4"
      :showSlotOrderBy="false"
      :status="null"
    />
    <div class="px-3 pb-3">
      <div v-for="(plant, index) in plants">
        <label class="mb-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
            <circle cx="2.5" cy="2.5" r="2.25" stroke="#1A70B7" stroke-width="0.5"/>
          </svg>
          <b class="ml-1">{{ plant.name }}</b>
        </label>
        <div class="dropdown-divider" style="width: -webkit-fill-available;" />
        <div class="row">
          <div class="col-6" v-for="(item, i) in Array(2)" :key="i">
            <span>Assinatura {{ i + 1 }}</span>
            <base-input
              input-group-classes="input-group-sm mb-2"
              class="mb-2"
              name="Name"
              v-model="defaultSignatory[plant.id][i].name"
              placeholder="Nome"
            />
            <base-input
              input-group-classes="input-group-sm mb-3"
              class="mb-3"
              name="Email"
              v-model="defaultSignatory[plant.id][i].email"
              placeholder="Email"
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-1 w-100 mx-3">
            <label style="font-size: 12px;">
              Vincular vendedor do contrato como padrão
            </label>
            <div>
              <base-switch
                v-model="defaultSignatorySeller[plant.id]"
                type="primary"
                offText="Não"
                onText="Sim"
                class="success"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pt-4">
        <base-button type="danger" @click="closeModal()">
          <img width="22" src="/img/icons/cancel.png" alt="info-squared" class="mr-1" />
          Cancelar
        </base-button>
        <base-button @click.prevent="save()" type="success" native-type="submit" :loading="loading">
          <img width="22" src="/img/icons/save.png" alt="info-squared" class="mr-1" />
          Salvar
        </base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import MultiFilter from "@/components/Utils/MultiFilter.vue";

export default {
  name: "ModalDefaultSignatoryCompanyPlant",
  components: { MultiFilter },
  data() {
    return {
      modal: false,
      loading: false,
      title: 'Configuração por central',
      contract_proposal: null,
      documents: null,
      defaultSignatory: {},
      defaultSignatorySeller: {},
      plants: [],
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    })
  },
  methods: {
    // Modal
    handleCreateModal() {
      this.modal = true;
      // Cria array de assinatura padrão para cada planta
      this.$_plants.forEach(plant => {
        this.$set(this.defaultSignatory, plant.id, [{ name: '', email: '' }, { name: '', email: '' }]);
      });
      // Busca assinaturas padrão
      this.$store.dispatch('digitalSignatureConfiguration/showDefaultSignatory').then((response) => {
        if (response.data.defaultSignatory || response.data.defaultSignatorySeller) {
          let signatories = {};
          // Signatarios padroes
          if (response.data.defaultSignatory && response.data.defaultSignatory.length > 0) {
            response.data.defaultSignatory.forEach(signatory => {
              if (this.defaultSignatory[signatory.company_plant_id]) {
                if (!signatories[signatory.company_plant_id]) {
                  signatories[signatory.company_plant_id] = [];
                }
                signatories[signatory.company_plant_id].push({
                  name: signatory.name || '',
                  email: signatory.email || '',
                });
              }
            });  
          }
          // Vendedor padrão
          if (response.data.defaultSignatorySeller && response.data.defaultSignatorySeller.length > 0) {
            response.data.defaultSignatorySeller.forEach(seller => {
              this.$set(this.defaultSignatorySeller, seller.company_plant_id, seller.use_seller_data);
            });
          }
          // Adiciona dados de assinatura padrão no array
          for (const companyPlantId in this.defaultSignatory) {
            if (signatories[companyPlantId]) {
              this.defaultSignatory[companyPlantId].forEach((item, index) => {
                if (signatories[companyPlantId][index]) {
                  item.name = signatories[companyPlantId][index].name;
                  item.email = signatories[companyPlantId][index].email;
                }
              });
            }
          }
          this.loading = false;
        }
      });
    },
    closeModal() {
      this.modal = false;
    },
    // filter
    fetch(data) {
      this.plants = [];
      this.$_plants.forEach(plant => {
        this.plants.push(plant);
      });
      if (data.global.length === 0) {
        return;
      }
      const filter = { nameOrPlantName: data.global };
      this.$store.dispatch('digitalSignatureConfiguration/showDefaultSignatory', { filter })
        .then((response) => {
          const data = response.data.map(item => item.company_plant_id);
          this.plants = this.plants.filter(item => data.includes(item.id));
        });
    },
    save() {
      this.loading = true;
      this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' });
      const payload = {
        defaultSignatory: this.defaultSignatory,
        defaultSignatorySeller: this.defaultSignatorySeller
      };
      this.$store.dispatch('digitalSignatureConfiguration/updateDefaultSignatory', payload)
        .then((response) => {
          this.closeModal();
          this.loading = false;
          this.$notify({ type: response.error_type, message: response.message });
        });
    },
  },
  mounted() {
    this.$_plants.forEach(plant => {
      this.plants.push(plant);
    });
  },
};
</script>


<style>
.filter >>> .container-fluid {
  padding: 0 !important;
}
.filter .card {
  margin-bottom: 0.5rem !important;
  -webkit-box-shadow: unset !important;
  border: unset !important;
}
.filter .card-body {
  padding: 0rem 0.4rem !important;
}
</style>
