import * as types from './mutation_types'
import {
  destroy,
  update,
  cursorPaginate,
  setUrlWithNextPage,
  formatResponseData,
} from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";
const endPoint = '/entity'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    entity_accounts: [],
    option_types: [
      {
        value: "2",
        label: "Peças e Equipamentos",
      },
    ],
    option_situations: [
      {
        value: "Ativa",
        label: "Ativa",
      },
      {
        value: "Inativa",
        label: "Inativa",
      },
    ],
    option_tax_regimes: [
      {
        value: "Lucro Real",
        label: "Lucro Real",
      },
      {
        value: "Lucro Presumido",
        label: "Lucro Presumido",
      },
      {
        value: "Simples Nacional",
        label: "Simples Nacional",
      },
      {
        value: "MEI",
        label: "MEI",
      },
    ],
    option_state_registrations: [
      {
        value: 1,
        label: "Contribuinte",
      },
      {
        value: 2,
        label: "Contribuinte Isento",
      },
      {
        value: 9,
        label: "Não Contribuinte",
      },
    ],
    settings: {},
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
    option_types: (state) => state.option_types,
    option_situations: (state) => state.option_situations,
    option_tax_regimes: (state) => state.option_tax_regimes,
    option_state_registrations: (state) => state.option_state_registrations,
    fetchNextPage: (state) => state.items,
    getEntityAccounts: (state) => state.entity_accounts,
    settings: (state) => state.settings,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.SET_ENTITY_ACCOUNT](state, payload) {
      state.entity_accounts = payload;
    },
    [types.SET_SETTINGS](state, payload) {
      state.settings = payload;
    },
  },
  actions: {
    fetchItems({commit,state}, payload = {}) {
      return createAxios().get(setUrlWithNextPage(endPoint, payload),
      {params: payload})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: payload.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsNew({ state }, params) {
      return createAxios()
        .get('/configuration' + endPoint, { params: params })
        .then(({ data }) => {
          cursorPaginate(state, {
            data: formatResponseData(data).data,
            next_page: params.page,
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchItemsSearch({ commit, state }, params) {
      return createAxios()
        .post(`${endPoint}/search`, params)
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit, state }, payload) {
      return createAxios()
        .post(`${endPoint}`, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      return createAxios()
        .put(`${endPoint}/${payload.uuid}`, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit, dispatch }, uuid) {
      return createAxios()
        .get(`${endPoint}/${uuid}`)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit, dispatch }, uuid) {
      return createAxios()
        .delete(`${endPoint}/${uuid}`)
        .then(({ data }) => {
          commit(types.DESTROY, uuid);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getTaxes({ commit, dispatch }, entityId) {
      return createAxios()
        .get(`/configuration/entity/get-taxes-by-entity/${entityId}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    saveOrUpdateTaxes({ commit, state }, payload) {
      return createAxios()
        .post(
          `/configuration/entity/save-or-update-taxes-for-entity/${payload.entityId}`,
          payload.taxes
        )
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getAccountsByEntityId({ commit, dispatch }, entityId) {
      return createAxios()
        .get(`/configuration/entity-accounts/${entityId}`)
        .then(({ data }) => {
          commit(types.SET_ENTITY_ACCOUNT, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getEntityByDocument({ commit }, payload) {
      return createAxios()
        .get(`/configuration/entity-by-document`, { params: payload })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getByFuelTank({ commit }, payload) {
      return createAxios()
        .get(`/configuration/entity/fuel-tank/${payload.fuel_tank_id}`, {
          params: payload,
        })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getSettings({ commit }, id) {
      return createAxios()
        .get(`/configuration/entity/${id}/setting`)
        .then(({ data }) => {
          commit(types.SET_SETTINGS, data.data || {});
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    storeSettings({}, payload) {
      return createAxios()
        .post(`/configuration/entity/${payload.id}/setting`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getDetailsEntity({ commit }, payload) {
      return createAxios()
        .get(`/configuration/entity/details/${payload.id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getTotalAttachmentsByEntityId({}, id) {
      return createAxios()
        .get(`/configuration/entity/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getTotalRegistriesByEntityId({}, id) {
      return createAxios()
        .get(`/configuration/entity/total-registries/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateNameEntity({}, payload) {
      return createAxios()
        .put(`/configuration/entity/update-name/${payload.id}`, {
          customerConstructionName: payload.customerConstructionName,
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getAnticipationBalanceById({ _ }, id) {
      return createAxios()
        .get(`/configuration/entity/anticipation-balance/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getSearch({ commit }, params) {
      return createAxios()
        .get(`/configuration/entity/search/filterable`, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
