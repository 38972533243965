<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">Imprimir/enviar</h5>
      </template>
      <validation-observer
        v-slot="{ invalid }"
        ref="formValidator"
      >
        <div class="row m-0 p-0">
          <div class="col-md-6">
            <base-button
              :class="{active: type === 0}"
              block
              type="primary"
              @click.passive="type = 0"
              outline
            >
              <i class="fa-regular fa-handshake"></i>
              PROPOSTA
            </base-button>
          </div>
          <div
            v-if="contract_proposal.entity"
            class="col-md-6"
          >
            <base-button
              :class="{active: type === 1}"
              @click.passive="type = 1"
              block
              type="light"
              outline>
              <i class="fa-solid fa-file-signature"></i>
              CONTRATO
            </base-button>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <label class="col-md-5 col-form-label form-control-label">
            Envio por e-mail
          </label>
          <div class="col-md-7 pt-2">
            <base-switch
              v-model="email.send_to_email"
              type="success"
              offText="Não"
              onText="Sim"
              class="primary"
            ></base-switch>
          </div>
        </div>
        <div class="form-group row m-0 p-0">
          <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
            Destinatário
            <span class="text-danger">&nbsp;*</span>
            <span
              class="btn btn-sm btn-success p-1 ml-1"
              @click.prevent="handleCreateContacts(customer_construction.uuid)"
            >
              <i class="fas fa-plus"></i> Novo
            </span>
          </label>
          <div class="col-md-7">
            <validation-provider rules="required">
              <el-select
                :disabled="loadingContacts"
                size="mini"
                v-model="contact"
                placeholder="Selecione"
                filterable
              >
                <el-option label="Selecione" value=""></el-option>
                <el-option v-for="contact in customer_construction.contact" :key="contact.id"
                           :label="contact.name"
                           :value="contact.uuid"
                >
                </el-option>
              </el-select>
            </validation-provider>
          </div>
        </div>
        <transition name="fade">
          <div class="form-group row m-0 p-0">
            <label class="col-md-5 col-form-label form-control-label">
              Exibir apenas vigência atual
            </label>
            <div class="col-md-7 pt-2">
              <base-switch
                v-model="atual_validity"
                type="primary"
                offText="Não"
                onText="Sim"
                class="success"
              ></base-switch>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="form-group row m-0 p-0" v-if="type === 0">
            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
              Validade
            </label>
            <div class="col-md-7">
              <base-input input-group-classes="input-group-sm">
                <input
                  v-mask="'###'"
                  inputmode="numeric"
                  type="text"
                  v-model.number="validity"
                  class="form-control form-control-sm"
                />
                <template slot="append">
                  <small class="input-group-sm p-0 m-0">
                    dias
                  </small>
                </template>
              </base-input>
            </div>
          </div>
        </transition>
        <template v-if="email.send_to_email">
          <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left">
            <h5 class="mb-0" slot="title">Envio por e-mail</h5>
          </div>
          <div class="form-group row m-0 p-0">
            <label class="col-md-5 col-form-label form-control-label">
              Cópia ao vendedor
            </label>
            <div class="col-md-7 pt-2">
              <base-switch
                v-model="email.copy_to_seller"
                type="success"
                offText="Não"
                onText="Sim"
                class="success"
              ></base-switch>
            </div>
          </div>
          <div class="form-group row m-0 p-0">
            <label class="col-md-5 col-form-label form-control-label">
              Quero receber
            </label>
            <div class="col-md-7 pt-2">
              <base-switch
                v-model="email.to_user"
                type="success"
                offText="Não"
                onText="Sim"
                class="success"
              ></base-switch>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  E-mails
                </label>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input>
                      <el-select :disabled="loadingContacts" v-model="email.emails" size="mini" filterable multiple
                                 placeholder="Selecione">
                        <el-option v-for="item in contacts" :key="item.uuid" :label="item.email"
                                   :value="item.uuid">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-1">
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Observações
                </label>
                <div class="col-md-7">
                  <textarea v-model="email.description" maxlength="150" class="form-control" rows="3" placeholder="">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="modal-footer">
          <base-button type="secondary" @click="closeModal()">
            Cancelar
          </base-button>
          <base-button type="primary" :disabled="invalid || (type === undefined)" @click="download" ative-type="submit">
            <span><i class="fa-solid fa-circle-check"></i> Concluir </span>
          </base-button>
        </div>
      </validation-observer>
    </modal>
    <ModalCreateContact @addContact="listContacts" ref="createContact"  />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalCreateContact from '@/views/Modules/Commercial/CustomerConstruction/Constructions/Contact/Shared/_Create';
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";

export default {
  components: {
    ModalCreateContact,
  },
  name: "ModalPrintOut",
  data() {
    return {
      modal: false,
      contract_proposal_uuid: null,
      loadingContacts: false,
      validity: null,
      contract_proposal: null,
      contact: null,
      contacts: [],
      type: undefined,
      email: {
        send_to_email: 0,
        emails: [],
        copy_to_seller: false,
        to_user: false,
        description: null,
      },
      atual_validity: true,
      proposal: true,
      future_consider_billing: true,
      contract: false,
      ContractProposalEnum: ContractProposalEnum,
    }
  },
  computed: {
    ...mapGetters({
      customer_construction: "customerConstructionConstructions/show",
    })
  },
  methods: {
    listContacts() {
      this.loadingContacts = true
      this.$store.dispatch('customerConstructionConstructions/show', this.customer_construction.uuid)
        .then(response => {
          this.contacts = response.data.contact.filter(item => item.email)
          this.loadingContacts = false
        })
        .catch(error => {
          this.loadingContacts = false
          this.$notify({
            type: error.data.error_type, message: error.data.message
          })
        })
    },
    closeModal() {
      this.modal = false
    },
    openModal(contractProposal) {
      this.email.send_to_email = 0
      this.email.emails = []
      this.atual_validity = true
      this.email.copy_to_seller = false
      this.email.to_user = false
      this.email.description = null
      this.validity = null;
      this.contact = null;
      this.type = undefined
      let loader = this.$loading.show()
      this.contract_proposal_uuid = contractProposal.uuid
      this.contract_proposal = contractProposal
      if (!this.contract_proposal.entity) {
        this.type = 0; // botão proposta selecionado
      }
      this.$store.dispatch('customerConstructionConstructions/show', contractProposal.construction.uuid)
        .then(response => {
          loader.hide()
          this.listContacts()
          this.modal = true
        }).catch(error => {
        this.$notify({type: error.data.error_type, message: error.data.message})
        loader.hide()
      })
    },
    download() {
      let baseURL = process.env.VUE_APP_BASE_URI_MS;
      if (!baseURL) {
        let host = window.location.host
        let subdomain = host.split('.')[0]
        baseURL = `https://${subdomain}.puzl.place/api/v1/`;
      }
      if (this.contract && !this.proposal) {
        return window.open(baseURL + `contract/${this.contract_proposal.uuid}`)
      }
      let loader = this.$loading.show()
      const self = this
      const emails = this.contacts.filter(function (item) {
        if (self.email.emails.includes(item.uuid)) {
          return item
        }
      }).map(function (obj) {
        return obj.email;
      })
      const email = {...this.email}
      email.emails = emails
      email.send_to_email = email.send_to_email
      let params = {
        uuid: this.contract_proposal_uuid,
        validity: this.validity,
        atual_validity: Number(this.atual_validity),
        type: this.type,
        contact: this.customer_construction.contact.find(item => item.uuid = this.contact),
        email: email
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('contractProposal/download', params)
        .then(response => {
          if (this.email.send_to_email) {
            loader.hide()
            this.modal = false
            return this.$notify({
              type: 'success',
              message: 'Solicitação concluída com sucesso. O e-mail será enviado em instantes.'
            });
          }
          if (this.contract) {
            window.open(baseURL + `contract/${this.contract_proposal.uuid}`)

          }
          let blob = new Blob([response],
            {type: 'application/pdf'})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.setAttribute("download", "Contrato.pdf");
          window.open(link, '_blank')
          loader.hide()
        }).catch(() => {
        loader.hide()
      })
    },
    handleCreateContacts(constructionUuid) {
      this.$refs.createContact.handleCreateModal(constructionUuid);
    },
  },
};
</script>

<style>
.border-3 {
  border-width: 3px !important;
}
</style>
