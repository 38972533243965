<template>
  <div style="min-height: 2000px;">
    <base-header class="mb-4">
      <div class="row align-items-center justify-content-between align-items-center py-4">
        <div class="ml-4 d-flex align-items-center">
          <img height="30px" src="/img/icons/cart-warning.png" class="mr-2"/>
          <h2 style="font-size: 1.2rem !important;color: #F2B532;" class="nowrap mb-0 mt-1">
            Ordem de compra
          </h2>
        </div>
        <!-- <div class="header-button">
          <router-link :to="{ path: '/commercial/purchase/request/create' }">
            <div class="mb-md-0 mt-2 mt-md-0 mb-2 col-md-3 px-1 mx-3  d-none d-sm-block">
                <base-button type="success" block style="border-radius: 4px !important;">
                  <img src="/img/icons/plus-math--v1-white.png" width="19">
                  novo
                </base-button>
            </div>
            Mobile
            <div class="rounded green-button px-3 d-md-none mr-4">
              <i class="fas fa-plus mr-1"></i> Novo
            </div>
          </router-link>
        </div> -->
      </div>
      <div class="d-flex">
        <div class="mt-1 ml-2 mr-3">
        <div style="display: flex; align-items: center">
          <i :class="listType !== 'cards' && 'opacity-25'" @click.prevent="setViewType('cards')"
            class="pointer fa-regular fa-hard-drive fa-2x"></i>
          <i :class="listType !== 'table' && 'opacity-25'" @click.prevent="setViewType('table')"
            class="pointer fa-solid fa-table-list fa-2x ml-1"></i>
        </div>
      </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <MultiFilter
        :default-range="range"
        @handleFilterDate="handleFilterDate"
        ref="multi-filter"
        @fetch="init"
        :disable-max-date="new Date()"
        :showSlotBookmark="false"
        :filter="filter">
        <template slot="order-by">
          <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
            <h6><a href="#" class="font-weight-400" style="font-size: 12px">
              <i class="fa-solid fa-angles-down"
                 style="font-size: 12px;margin-right: 5px"></i>
              Do mais novo para o mais velho</a></h6>
            <hr class="mb-1 mt-n2">
            <h6><a href="#" class="font-weight-400" style="font-size: 12px">
              <i class="fa-solid fa-angles-up"
                 style="font-size: 12px;margin-right: 5px"></i> Do mais velho para o mais novo</a></h6>
            <a href="#" slot="reference">
              <h5 slot="reference" class="pt-1">Ordenar<i class="fa-solid fa-chevron-down"></i></h5>
            </a>
          </el-popover>
        </template>
        <template slot="dates">
          <div class="col-md-12 text-left">
            <label class="form-control-label">
              Prazo de compra
            </label>
            <InputDatePicker @handleFilterDate="handleFilterDueDate" size="small" visibility="focus"/>
          </div>
        </template>
        <template slot="data">
          <div class="col-12 text-left mt-2">
            <label class="form-control-label">
              Solicitante
            </label>
            <PuzlSelect
                :items="types"
                v-model="filter.type_ids"
                :multiple="true"
                label="technical_description"
                class="new-default-black-font"
                @input="handleFilterMixTypes"
              />
          </div>
          <div class="col-md-12 mt-2 text-left">
              <label class="form-control-label">Tipo</label>
              <PuzlSelect
                :items="types"
                v-model="filter.type_ids"
                :multiple="true"
                label="technical_description"
                class="new-default-black-font"
                @input="handleFilterMixTypes"
              />
            </div>
          <div class="row pr-3 mt-2 pl-3">
            <div class="col-12 text-left">
              <label class="form-control-label">
                Número de itens
              </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input
                    @change="setRange()"
                    v-model="filter.range_installment_number"
                    placeholder="de"
                    class="form-control form-control-sm btn-first-child"
                  />
                </div>
                <div class="col-6 pl-1">
                  <input
                    @change="setRange()"
                    v-model="filter.range_installment_number"
                    placeholder="até"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="status">
          <div id="customButton" class="col-12 mb-1">
              <button
                :class="{'btn-active btn-primary':filter.status == 8}"
                @click="filter.status == 8 ? filter.status = null : filter.status = 8; init({})"
                :active="filter.status == 8"
                style="color: #4b4b50;border: 1px solid #DCDFE6; background-color: white"
                class="text-uppercase col-12 btn-sm shadow d-flex justify-content-center align-items-center"
              >
                <img style="margin-right: 4px" ref="icon" height="19" :src="'/img/icons/icons8/ios/thumb-up' + (filter.status == 8 ? '.png' : '_primary.png')"/>
                 Aprovado
              </button>
            </div>
            <div class="col-12 mb-1"
              @click="filter.status == 7 ? filter.status = null : filter.status = 7; init({})">
              <BaseButtonHoverable
                size="sm" type="warning" :active="filter.status == 9" icon="hourglass" platform="ios">
                pendente
              </BaseButtonHoverable>
            </div>
            <div class="col-12 mb-1"
              @click="filter.status == 7 ? filter.status = null : filter.status = 7; init({})">
              <BaseButtonHoverable
                size="sm" type="danger" :active="filter.status == 9" icon="thumbs-down" platform="ios">
                Negado
              </BaseButtonHoverable>
            </div>
          </template>
      </MultiFilter>
      <!-- CARDS -->
      <Cards v-if="listType === 'cards'" :requests="['a']" />
      <!-- TABELA -->
      <Table v-if="listType === 'table'" :contractProposalSignatures="['a']" />
      <!-- <LoadingPagination :show="loading"/> -->
      <!-- <PuzlEmptyDatav-if="!loading && $_contractProposalSignatures.length <= 0" /> -->
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/Utils/BackButton';
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Table from './Shared/_Table';
import Cards from './Shared/_Cards'
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlSelect from "@/components/PuzlSelect";
import InputDatePicker from "@/components/InputDatePicker";


export default {
  name: "PurchasesOrderIndex",
  mixins: [cursorPaginate],
  components: {
    BackButton,
    MultiFilter,
    LoadingPagination,
    SkeletonPuzlGrid,
    cursorPaginate,
    PuzlEmptyData,
    Cards,
    Table,
    BaseButtonHoverable,
    PuzlSelect,
    InputDatePicker
  },
  data() {
    return {
      loadingSkeleton: false,
      listType: 'cards',
      filter: {
        range_value: {
          min: null,
          max: null
        },
        status: null,
        api: null,
        range_document_number: {
          min: null,
          max: null
        },
        range_competence: null,
      },
      statusButtons: [
        {
          text: 'pendentes', count: 0, color: '#F2B532', border: '0.5px solid #F2B532',
          status: 0, icon: '/img/icons/icons8/ios/hourglass_warning.png'
        },
      ],
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 29),
        end: new Date(),
      },
    }
  },
  methods: {
    init() {
      return null;
    },
    setViewType(type){
      this.listType = type;
    },
    handleFilterOrderBy(order_by) {
      this.filter.order_by = this.filter.order_by == order_by ? null : order_by;
      this.init({});
    },
    filterByStatus(filter) {
      if (this.filter.status == filter) {
        this.filter.status = null;
      } else {
        this.filter.status = filter;
      }
      this.init(this.filter);
    },
    handleSetRange(range) {
      this.filter.start_at = moment(range.start).format("YYYY-MM-DD")
      this.filter.end_at = moment(range.end).format("YYYY-MM-DD")
    },
  },
};
</script>
<style scoped>
.status-button {
  border-radius: 8px;
  height: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 10rem;
  margin-right: 1rem;
  background-color: #ffff;
  font-weight: 500;
}
.header-button .base-button {
  width: 200px !important;
  height: 6px;
}
#customButton button {
  color: #4b4b50;border: 1px solid #DCDFE6;
  background-color: white;
}
#customButton .btn-active {
  color: white!important;
}
.green-button {
  border-radius: 10px;
  border: 1px solid #149E57;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  color: #149E57;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
