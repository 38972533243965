import stockLocationStoreActions from './stockLocationStoreActions';
import stockLocationStoreGetters from './stockLocationStoreGetters';
import stockLocationStoreMutations from './stockLocationStoreMutations';
import { stockLocationStateKeys } from "./stockLocationStoreStateKeys";

const stockLocationStore = {
  ...stockLocationStoreActions,
  ...stockLocationStoreGetters,
  ...stockLocationStoreMutations,
  stockLocationStateKeys,
};

export default stockLocationStore;