<template>
  <div class="items-container w-100 mt-4">
    <!-- HEADER -->
    <div class="header-items-container">
      <div class="d-flex align-items-center">
        <img width="22" src="/img/icons/big-parcel-primary.png" class="mr-2"/>
        <span style="font-weight: 600; font-size: 14px;">Itens</span>
      </div>
      <div class="d-flex">
        <div @click="handleShowModal({ ref: 'modalAddItems', data: null })"
          class="header-button" style="border-color: #149E57; color: #149E57">
          <img height="18" src="/img/icons/icons8/ios/add-success.png" class="mr-1"/> Incluir
        </div>
        <div v-if="productServices.length" @click="removelAllProductServices" class="header-button ml-3" style="border-color:#DB4539; color: #DB4539">
          <img height="16" src="/img/icons/clean-warning.png" class="mr-1"/> Limpar
        </div>
      </div>
    </div>
    <!-- BODY -->
    <div class="body-items-container" style="overflow-y: scroll;">
      <div v-if="productServices.length" class="row mx-1">
        <table class="table table-custom-shaded table-sm mb-9 pb-3">
          <thead>
            <tr class="fixed">
              <th class="text-left new-default-black-font">Item</th>
              <th class="text-left new-default-black-font">Marca</th>
              <th class="text-left new-default-black-font">Quantidade</th>
              <th class="text-left new-default-black-font">Unidade</th>
              <th class="text-left new-default-black-font">Ação</th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in productServices" :key="index" >
            <tr>
              <!-- Status -->
              <td style="width: 20%;">
                {{ item.description || item.product_service_description }}
              </td>
              <td style="width: 20%;">
                {{ item.brand || "-" }}
              </td>
              <td style="width: 20%;">
                {{ item.quantity || "-" }}
              </td>
              <td style="width: 20%;">
                {{ item.input_unit || item.product_service_input_unit }}
              </td>
              <!-- Menu engrenagem -->
              <td style="width: 10%;">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0">
                    <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <a class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"  @click="removeProductService(index)">
                    <img width="23" height="23" src="/img/icons/icons8/ios/delete--v1_danger.png" />
                    Remover
                  </a>
                  <!-- <div class="dropdown-divider p-0 m-0"></div> -->
                </base-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="d-flex justify-content-center w-100">
        <span>Clique em incluir para adicionar</span>
      </div>
    </div>
    <!-- FOOTER -->
    <div class="footer-items-container">
      {{ productServices.length }} Ite{{ productServices.length <= 1 ? 'm' : 'ns' }}
    </div>
    <ModalAddItems :itemType="productServiceTypeId" @addProductService="addProductService" ref="modalAddItems"/>
  </div>
</template>

<script>
import ModalAddItems from "./_ModalAddItems";
// import ModalAddItems from "./../../../../../Configuration/ProductService/Shared/_ModalCreate";

export default {
  name: "PurchasesRequestCreateItems",
  components: {
    ModalAddItems,
  },
  props: {
    productServiceTypeId: Number,
    requestItem: Object,
  },
  data() {
    return {
      filter: {},
      productServices: [],
    }
  },
  watch: {
    productServices(newVal) {
      this.$emit('purchaseRequestItem', { items: newVal });
    },
    productServiceTypeId() {
      this.productServices = [];
    },
    requestItem(newVal) {   
      if (this.productServices.length === 0) {
        this.productServices = newVal;
      }
    },
  },
  methods: {
    handleShowModal({ ref, data }) {
      this.$refs[ref].handleCreateModal(data);
    },
    addProductService(data) {
      const index = this.productServices.findIndex(item => item.id === data.id);
      // Se o item ja existe apenas atualiza
      if (index !== -1) {
        this.productServices[index].quantity = data.quantity;
        this.productServices[index].brand = data.brand || '';
      } else {
        this.productServices.push(data);
      }
    
      this.$emit('purchaseRequestItem', { items: this.productServices });
    },
    removeProductService(index) {

      this.productServices.splice(index, 1);
    },
    removelAllProductServices() {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Tem certeza que deseja limpar TODOS os itens?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            this.productServices = [];
          } else {
            this.modal.create = false;
          }
        });
    }
  }
};
</script>
<style scoped>
/* HEADER */
.items-container .header-items-container {
  width: 1189px;
  min-height: 50px;
  top: 271px;
  left: 218px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.items-container .header-items-container .header-button {
  width: 130px;
  height: 25px;
  top: 234px;
  left: 1114px;
  border-radius: 5px;
  border: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  border: 0.5px solid;
  font-size: 13px;
  padding: 0.5rem 1rem !important;
}

/* BODY */
.body-items-container {
  border-left: 1px solid #DCDFE6;
  border-right: 1px solid #DCDFE6;
  padding: 1rem;
  border-radius: 0 !important;
}
.body-items-container .table-custom-shaded thead th {
background-color: #CCCCCC !important;
padding-left: 1rem;
}
.body-items-container .table-custom-shaded td:last-child, .table-custom-shaded th:last-child {
  text-align: center !important;
  padding-left: 0.5rem !important;
}
.body-items-container .table-custom-shaded td {
  padding-left: 1rem !important;
}

/* FOOTER */
.items-container .footer-items-container {
  border-radius: 0 0 10px 10px;
  background-color: #c6dbed;
  padding: 1rem;
  text-align: end;
  color: #1A70B7;
  font-weight: 600;
}
</style>

