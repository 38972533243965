<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div class="row mt-1 mb-3">
          <div class="col pr-0">
            <base-button @click.prevent="selected = 0" :class="selected === 0 && 'active'"
                          class="button-border-left border-black" outline type="primary" block>
              <span :class="selected === 0 ? 'text-white' : 'text-dark'" style="font-size: 11px;">GERAL</span>
            </base-button>
          </div>
          <div class="col px-0">
            <base-button @click.prevent="selected = 1" :class="selected === 1 && 'active'"
                          style="border-radius: 0% !important;" type="primary" class="btn-left-none border-black"
                          outline
                          block>
              <span :class="selected === 1 ? 'text-white' : 'text-dark'" style="font-size: 11px;">FINANCEIRO</span>
            </base-button>
          </div>
          <div class="col px-0">
            <base-button @click.prevent="selected = 2" :class="selected === 2 && 'active'"
                          style="border-radius: 0% !important;" type="primary" class="btn-left-none border-black"
                          outline
                          block>
              <span :class="selected === 2 ? 'text-white' : 'text-dark'" style="font-size: 11px;">COMERCIAL</span>
            </base-button>
          </div>
          <div class="col px-0">
            <base-button @click.prevent="selected = 3" :class="selected === 3 && 'active'"
                          style="border-radius: 0% !important;" type="primary" class="btn-left-none border-black"
                          outline
                          block>
              <span :class="selected === 3 ? 'text-white' : 'text-dark'" style="font-size: 11px;">OPERACIONAL</span>
            </base-button>
          </div>
          <div class="col pl-0">
            <base-button @click.prevent="selected = 4" :class="selected === 4 && 'active'"
                          class="button-border-right btn-left-none border-black" outline type="primary" block>
              <span :class="selected === 4 ? 'text-white' : 'text-dark'" style="font-size: 11px;">TÉCNICO</span>
            </base-button>
          </div>
        </div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <!-- GERAL -->
            <div v-if="selected === 0">
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title"> Geral </h5>
              </div>
              <div class="form-group row m-0 p-o  d-flex align-items-center">
                <label class="col-md-5 col-form-label form-control-label">
                  Aplicação de imposto geral em margem?
                </label>
                <div class='col-md-5' style="flex-direction: row-reverse;">
                  <template v-if="$_setting.geral_tax_application_in_margin == 1">
                    <input-limit
                      :start_value="$_setting.general_tax_margin_percent"
                      :options="{
                        min: -50,
                        max: 100,
                        decimals: 0
                      }"
                      v-model="$_setting.general_tax_margin_percent"
                      @value="$_setting.general_tax_margin_percent = $event"
                    >
                      <small>%</small>
                    </input-limit>
                  </template>
                </div>
                <div class="col-md-2 pt-2 d-flex justify-content-center">
                  <base-switch
                    v-model="$_setting.geral_tax_application_in_margin"
                    type="primary"
                    offText="Não"
                    onText="Sim"
                    class="success" />
                </div>
              </div>
              <!-- CONFIGURAÇÕES DE WHATSAPP -->
              <div v-if="selected === 0">
                <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                  <h5 class="mb-0" slot="title"> CONFIGURAÇÕES DE WHATSAPP </h5>
                </div>
                <div class="form-group row m-0 p-o  d-flex align-items-center">
                  <label class="col-md-5 col-form-label form-control-label">
                    HABILITAR API DO WHATSAPP
                  </label>
                  <div class="col"></div>
                  <div class="col-md-2 pt-2 d-flex justify-content-center">
                    <base-switch
                      v-model="$_setting.is_enable_whatsapp_api"
                      type="primary"
                      offText="Não"
                      onText="Sim"
                      class="success" />
                  </div>
                </div>
                <div class="form-group row m-0 p-o  d-flex align-items-center">
                  <label class="col-md-5 col-form-label form-control-label">
                    TOKEN
                  </label>
                  <div class="col"></div>
                  <div class="col-md-5 pt-2 d-flex justify-content-center">
                    <input type="text" v-model="$_setting.whatsapp_api_token" class="form-control form-control-sm"/>
                  </div>
                </div>
                <div class="form-group row m-0 p-o  d-flex align-items-center">
                  <label class="col-md-5 col-form-label form-control-label">
                    IDENTIFICAÇÃO DA CONTA DO WHATSAPP BUSINESS
                  </label>
                  <div class="col"></div>
                  <div class="col-md-5 pt-2 d-flex justify-content-center">
                    <input type="text" v-model="$_setting.whatsapp_api_business_id" class="form-control form-control-sm"/>
                  </div>
                </div>
              </div>
              <!-- REGUA DE COBRANÇA -->
              <div class="mt-3">
                <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                  <h5 class="mb-0" slot="title">Configurações de régua de cobrança</h5>
                </div>
                <div class="form-group row m-0 p-o d-flex justify-content-between">
                  <label class="col-md-5 col-form-label form-control-label">
                    Habilitar régua de cobrança
                  </label>
                  <div class="col-md-2 pt-2 d-flex justify-content-center">
                    <base-switch
                      v-model="$_setting.billing_rule"
                      type="primary"
                      offText="Não"
                      onText="Sim"
                      class="success" />
                  </div>
                </div>
                <div v-if="$_setting.billing_rule" class="my-3" style="border-bottom: 1px solid #dee2e6; width: 95%; margin: auto;"/>
                <div v-if="$_setting.billing_rule" class="form-group row m-0 p-o d-flex justify-content-between">
                  <label class="col-md-5 col-form-label form-control-label">
                    Lembretes de parcelas a vencer
                  </label>
                  <div class="col-md-2 d-flex justify-content-center">
                    <base-switch
                      v-model="$_setting.overdue_installment_reminders"
                      type="primary"
                      offText="Não"
                      onText="Sim"
                      class="success" />
                  </div>
                </div>
                <div v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders" class="form-group row m-0 p-o d-flex justify-content-between">
                  <label class="col-md-5 col-form-label form-control-label">
                    Dias antes do vencimento
                  </label>
                  <div class="col-md-2 d-flex justify-content-center align-items-center">
                    <template>
                      <input-limit
                        :start_value="$_setting.days_before_due_date"
                        :options="{
                            min: 0,
                            max: 99,
                            decimals: 0
                          }"
                        v-model="$_setting.days_before_due_date"
                        @value="$_setting.days_before_due_date = $event"
                      >
                        <small>DIAS</small>
                      </input-limit>
                    </template>
                  </div>
                </div>
                <div v-if="$_setting.billing_rule" class="my-3" style="border-bottom: 1px solid #dee2e6; width: 95%; margin: auto;"/>
                <div v-if="$_setting.billing_rule" class="form-group row m-0 p-o d-flex justify-content-between">
                  <label class="col-md-5 col-form-label form-control-label">
                    Lembretes de parcelas vencidas
                  </label>
                  <div class="col-md-2 d-flex justify-content-center">
                    <base-switch
                      v-model="$_setting.overdue_installment_reminders_expired"
                      type="primary"
                      offText="Não"
                      onText="Sim"
                      class="success" />
                  </div>
                </div>
                <div v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders_expired" class="form-group row m-0 p-o d-flex justify-content-between">
                  <label class="col-md-10 col-form-label form-control-label te">
                    RECORRÊNCIA DE COBRANÇA DAS PARCELAS VENCIDAS. ENVIAR EMAIL A CADA:
                  </label>
                  <div class="col-md-2 d-flex justify-content-center align-items-center">
                    <template>
                      <input-limit
                        :start_value="$_setting.overdue_billing_recurring"
                        :options="{
                            min: 0,
                            max: 99,
                            decimals: 0
                          }"
                        v-model="$_setting.overdue_billing_recurring"
                        @value="$_setting.overdue_billing_recurring = $event"
                      >
                        <small>DIAS</small>
                      </input-limit>
                    </template>
                  </div>
                </div>
                <div v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders_expired" class="form-group row m-0 p-o d-flex justify-content-between">
                  <label class="col-md-10 col-form-label form-control-label">
                    NÃO COBRAR PARCELA VENCIDA APÓS QUANTIDADE DE DIAS CONFIGURADA
                  </label>
                  <div class="col-md-2 d-flex justify-content-center align-items-center">
                    <template>
                      <input-limit
                        :start_value="$_setting.maximum_collection_time"
                        :options="{
                            min: 0,
                            max: 99,
                            decimals: 0
                          }"
                        v-model="$_setting.maximum_collection_time"
                        @value="$_setting.maximum_collection_time = $event"
                      >
                        <small>DIAS</small>
                      </input-limit>
                    </template>
                  </div>
                </div>
                <div v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders_expired" class="form-group row m-0 p-o d-flex justify-content-between">
                  <label class="col-md-10 col-form-label form-control-label">
                    NÚMERO DE VEZES QUE CADA PARCELA SERÁ COBRADA (Limite: 4)<br>
                    Serão realizadas "{{ billingRecurrenceLimit }}" cobranças de cada parcela
                    a cada "{{ $_setting.overdue_billing_recurring }}" dias.
                  </label>
                  <div class="col-md-2 d-flex justify-content-center align-items-center">
                    <template>
                      <base-input
                        input-classes="form-control-sm"
                        autocomplete="off"
                        v-model="billingRecurrenceLimit"
                        disabled
                      />
                    </template>
                  </div>
                </div>
                <label
                  v-if="$_setting.billing_rule && $_setting.overdue_installment_reminders_expired && billingRecurrenceLimit > BILLING_RECURRENCE_LIMIT"
                  class="m-0 col-md-12 col-form-label form-control-label text-right text-danger"
                >
                  LIMITE DE RECORRÊNCIA EXCEDIDO (MÁX. DE 4)
                </label>
              </div>

              <!-- Configurações de integração -->
              <div class="mt-3">
                <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                  <h5 class="mb-0" slot="title">Configurações de integração</h5>
                </div>
                <!-- Rastrin -->
                <div>
                  <!-- Habilitar -->
                  <div class="form-group row m-0 p-o d-flex justify-content-between">
                    <label class="col-md-5 col-form-label form-control-label">
                      Habilitar integração com Rastrin
                    </label>
                    <div class="col-md-2 pt-2 d-flex justify-content-center">
                      <base-switch
                        v-model="$_setting.is_active_rastrin_integration"
                        type="primary"
                        offText="Não"
                        onText="Sim"
                        class="success" />
                    </div>
                  </div>
                  <div v-if="$_setting.is_active_rastrin_integration">
                    <!-- Url -->
                    <div class="form-group row m-0 p-o d-flex justify-content-between">
                      <label class="col-md-7 col-form-label form-control-label">
                        Url
                      </label>
                      <div class="col-md-5">
                        <input maxlength="255" type="text" v-model="$_setting.rastrin_integration_url" class="form-control form-control-sm"/>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-o d-flex justify-content-between">
                      <label class="col-md-5 col-form-label form-control-label">
                        E-mail
                      </label>
                      <div class="col-md-5">
                        <input maxlength="255" type="text" v-model="$_setting.rastrin_integration_email" class="form-control form-control-sm"/>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-o d-flex justify-content-between">
                      <label class="col-md-7 col-form-label form-control-label">
                        Senha
                      </label>
                      <div class="col-md-5">
                        <input maxlength="60" type="password" v-model="$_setting.rastrin_integration_password" class="form-control form-control-sm"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FINANCEIRO -->
            <div v-if="selected === 1">
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Faturamento automático</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Padrão em propostas/contratos?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.contract_proposal" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Habilitar faturamento automático?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.automatic_billing" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Faturar com saldo parcial?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.partial_balance" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Faturamento/manual</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Padrão de crédito de antecipação sempre igual a sim?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.partial_balance_manual_billing" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Permitir incluir anexo de boleto manual?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.allow_bank_billet_as_attachment" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Permitir entregas de meses diferentes na mesma fatura?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.allow_delivery_different_month" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Emissões automáticas</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Envio automático de e-mail?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.automatic_email" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Emissão automática de Nfse?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.automatic_nfse" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Emissão automática de boleto?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.automatic_billet" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
            </div>
            <template>
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Nota fiscal de carregamento</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Ocultar número de NF-e em relatórios?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.hide_nfe_report" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
            </template>
            <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
              <h5 class="mb-0" slot="title">Nota fiscal de serviço</h5>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-9 col-form-label form-control-label">
                Ocultar valores unitários em descrição?
              </label>
              <div class="col-md-3 pt-2">
                <base-switch
                  v-model="$_setting.is_hide_nfse_value_in_description"
                  type="primary"
                  offText="Não"
                  onText="Sim"
                  class="success"
                />
              </div>
            </div>

            <!-- COMERCIAL -->
            <div v-if="selected === 2">
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title"> Propostas/contratos</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Permitir Imprimir propostas com pendências?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.pending_proposals" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Permite alterar o endereço da obra?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.construction_address " type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Exigir anexo de obra para salvar nova proposta/contrato?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.is_attachment_required_for_new_proposal_contract " type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Exigir nível de aplicação?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.demand_level_responsibility " type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Exigir informe de bomba externa e convencional?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.is_external_and_conventional_pump_report_required " type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Exibir teor de agregados graúdos na inclusão de traços?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.show_coarse_aggregate" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Cobrar adicional de carregamentos de "retira"
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.is_charge_extra_loads" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

            </div>

            <!-- OPERACIONAL -->
            <div v-if="selected === 3">
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Carregamento</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Permitir carregamento retroativo?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.allow_backloading" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Permitir emitir a "O.S." de concreto antes de concluir o carregamento?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.is_concrete_os_issued_before_loading_completion" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Permitir emitir corte manual de aditivo e água
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.is_manual_cut_additive_water" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Ao imprimir NF-e, incluir O.S na segunda página?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.add_os_to_danfe" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Travar carregamento quando não houver anexo na penúltima carga?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.disable_charging_without_attachment" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Concluir viagem automaticamente após a conclusão do carregamento?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.finish_travel_after_charging" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
            </div>
            <!-- TÉCNICO -->
            <div v-if="selected === 4">
              <div class="card-header border-header-default-bottom p-2 grid-col--fixed-left mb-2">
                <h5 class="mb-0" slot="title">Técnico</h5>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Aprovar "redirecionamento" automaticamente?
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.automatically_approve_redirect" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-9 col-form-label form-control-label">
                  Exigir que MCC esteja no mapa de ponto de carga para alteração de referência comercial
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.is_mcc_on_map_for_commercial_pref_change" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
              </div>
              <div class="form-group row m-0 p-o  d-flex align-items-center">
                <label class="col-md-9 col-form-label form-control-label">
                 Bloquear carregamentos com desvio
                </label>
                <div class="col-md-3 pt-2">
                  <base-switch v-model="$_setting.is_block_loads_with_deviation" type="primary" offText="Não" onText="Sim" class="success"></base-switch>
                </div>
                <div class="form-group row m-0 p-o d-flex align-items-center w-100">
                  <div class='col-md-10' style="flex-direction: row-reverse;">
                    <template v-if="$_setting.is_block_loads_with_deviation == 1">
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4">Aglomerante</label>
                          <input-limit
                            :start_value="$_setting.block_loads_binder_percentage_value"
                            :options="{
                              min: -50,
                              max: 99,
                              decimals: 0
                            }"
                            v-model="$_setting.block_loads_binder_percentage_value"
                            @value="$_setting.block_loads_binder_percentage_value = $event"
                          >
                            <small>%</small>
                          </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4">Agregado graúdo</label>
                          <input-limit
                            :start_value="$_setting.block_loads_coarse_aggregate_value"
                            :options="{
                              min: -50,
                              max: 99,
                              decimals: 0
                            }"
                            v-model="$_setting.block_loads_coarse_aggregate_value"
                            @value="$_setting.block_loads_coarse_aggregate_value = $event"
                          >
                            <small>%</small>
                          </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4">Agregado miúdo</label>
                          <input-limit
                            :start_value="$_setting.block_loads_fine_aggregate_value"
                            :options="{
                              min: -50,
                              max: 99,
                              decimals: 0
                            }"
                            v-model="$_setting.block_loads_fine_aggregate_value"
                            @value="$_setting.block_loads_fine_aggregate_value = $event"
                          >
                            <small>%</small>
                          </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4">Aditivo</label>
                          <input-limit
                            :start_value="$_setting.block_loads_additive_value"
                            :options="{
                              min: -50,
                              max: 99,
                              decimals: 0
                            }"
                            v-model="$_setting.block_loads_additive_value"
                            @value="$_setting.block_loads_additive_value = $event"
                          >
                            <small>%</small>
                          </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4">Adição</label>
                          <input-limit
                            :start_value="$_setting.block_loads_addition_value"
                            :options="{
                              min: -50,
                              max: 99,
                              decimals: 0
                            }"
                            v-model="$_setting.block_loads_addition_value"
                            @value="$_setting.block_loads_addition_value = $event"
                          >
                            <small>%</small>
                          </input-limit>
                      </div>
                      <div class="row m-0 p-o d-flex align-items-center w-100">
                        <label class="col-md-4">Água</label>
                          <input-limit
                            :start_value="$_setting.block_loads_water_value"
                            :options="{
                              min: -50,
                              max: 99,
                              decimals: 0
                            }"
                            v-model="$_setting.block_loads_water_value"
                            @value="$_setting.block_loads_water_value = $event"
                          >
                            <small>%</small>
                          </input-limit>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid || ($_setting.billing_rule && $_setting.overdue_installment_reminders_expired && billingRecurrenceLimit > BILLING_RECURRENCE_LIMIT)"
                :loading="loadingStore"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputLimit from "@/components/Utils/InputLimit.vue";
import PuzlSelect from "@/components/PuzlSelect";

const { formatErrorValidation } = require("@/plugins");

export default {
  name: "ModalCreate",
  components: {
    InputLimit,
    PuzlSelect,
  },
  computed: {
    ...mapGetters({
      $_setting: "generalSetting/show",
    }),
    billingRecurrenceLimit() {
      const hasValue = this.$_setting.maximum_collection_time > 0 && this.$_setting.overdue_billing_recurring > 0;
      if (!hasValue) {
        return 0;
      }
      return Math.trunc(this.$_setting.maximum_collection_time/this.$_setting.overdue_billing_recurring);
    },
  },
  data() {
    return {
      modal: {
        title: 'Configurações gerais',
        create: false,
      },
      loadingStore: false,
      selected: 0,
      BILLING_RECURRENCE_LIMIT: 4,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.loadData();
      this.modal.create = true;
    },
    loadData() {
      let loader = this.$loading.show();
      this.$store.dispatch('generalSetting/show').then(() => {
          loader.hide();
      });
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("generalSetting/update", this.$_setting)
        .then(response => {
            let payload = {
              waID: response.data.whatsapp_api_business_id,
              token: response.data.whatsapp_api_token,
              base_url: window.location.origin
            }
            this.$notify({ type: response.error_type, message: response.message });
            this.$store.dispatch("whatsappApi/setupWhatsappApi", payload)
            .then(response => {
                this.$notify({ type: response.error_type, message: response.message });
            }).catch(error =>
              this.$notify({ type: error.error_type, message: "Não foi possível configurar a api do whatsapp. erro: " + error.message
            }))
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({ type: "danger", message: error.data.message });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: errors });
          }
        }).finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
          this.closeModal();
        });
    },
  },
};
</script>

<style scoped>
.border-black {
  border-color: black !important;
}

@media only screen and (max-width: 991px) {
  .base-button {
    font-size: 6px;
  }
}

.button-border-left {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-border-right {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-left-none {
  border-left: 0;
}

.btn-left-none:hover {
  transform: none !important;
}
</style>
