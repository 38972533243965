<template>
  <div>
    <modal size="md"
      :show.sync="modal.create"
      :backgroundColor="'#E8E8E8'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/icons8/ios/settings-3--v1_gray.png" alt="settings-3--v1_gray" width="24px">
            </div>
            <span class="text-title">
              {{ modal.title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/administrative-tools_primary.png" width="20">
                  <h4 class="m-0 mx-2" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      FORNECEDOR DE COMBUSTÍVEL
                  </h4>
                  <hr class="float-right" style="width: 35%;">
                </div>
              </div>
              <div class="form-group mt-3">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                        ABASTECIMENTO
                    </div>
                  </div>
                  <div v-if="settings.is_fuel" class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-success" @click.prevent="toggleFuel(false)">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </span>
                  </div>
                  <div v-else class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-danger" @click.prevent="toggleFuel(true)">
                      <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                      Inativo
                    </span>
                  </div>
                </div>
                <div v-if="settings.is_fuel" class="form-group row m-0 p-0 mt-2">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                        TIPO DE OPERAÇÃO PUZL
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider :rules="{ required: settings.is_fuel === true }">
                      <base-input input-group-classes="input-group-sm">
                        <PuzlSelect
                          v-model="settings.operation_source_id"
                          :items="$_operation_sources"
                          :disableBoxShadow="true"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="modal-footer px-0 mb-n4">
                  <div class="d-flex pr-2" style="gap: 18px;">
                    <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="closeModal">
                      <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                      <span style="font-weight: 500;">
                        Cancelar
                      </span>
                    </div>
                    <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="store">
                      <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                      <span style="font-weight: 500;">
                        Salvar
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";

export default {
  name: "ModalSettings",
  components: { PuzlSelect },
  data() {
    return {
      entity_id: null,
      modal: {
        title: "Configurações",
        create: false,
      },
      loadingStore: false,
      settings: null,
    };
  },
  computed: {
    ...mapGetters({
      $_operation_sources: "operationSource/fetch",
      $_item: "entity/settings",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(id) {
      let loader = this.$loading.show();
      this.entity_id = id;
      this.$store.dispatch("entity/getSettings", this.entity_id).then((response) => {
        loader.hide();
        this.modal.create = true;
        if(response.data != null) {
          this.settings = response.data
        }
        else {
          this.settings = {
            is_fuel: false,
            operation_source_id: null
          };
        }
      });
    },
    store() {
      this.loadingStore = true;
      this.validateSettings();
      this.$store
        .dispatch("entity/storeSettings", { ...this.settings, id: this.entity_id })
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = true;
        });
    },
    toggleFuel(value) {
      if(this.isEmpty(this.$_item)) {
        this.settings = {
          is_fuel: value,
          operation_source_id: null
        };
      }
      else {
        this.settings.operation_source_id = null;
        this.settings.is_fuel = value;
      }
    },
    /**
    * Verifica se um objeto está vazio.
    *
    * @param {Object} obj - O objeto a ser verificado.
    * @return {boolean} Retorna true se o objeto estiver vazio, caso contrário, false.
    */
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    validateSettings() {
      if(this.settings.is_fuel && this.settings.operation_source_id === null) {
         this.$notify({
            type: "danger",
            message: "Selecione um Tipo de Operação PUZL.",
          });

          return ;
      }
    }
  },
  mounted() {
    this.$store.dispatch("operationSource/fetchItems");
  },
};
</script>

<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #F4C7C3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-cancel span {
  font-size: 12px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
.button-save span {
  font-size: 12px;
}
</style>
