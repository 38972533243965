import * as types from './mutation_types'
import {destroy, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = 'financial/detached'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.DESTROY] (state, id){
      destroy(state,id)
    },
  },
  actions: {
    fetchItemsPaginate({state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint + '/single-additional-invoice', params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit}, id) {
      return createAxios().delete('/financial/single/' + id + '+microservice')
        .then(({data}) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit}, payload) {
      return createAxios().post('/financial/single+microservice', payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    approve({commit}, params) {
      return createAxios().put(`/financial/detached/single-additional-invoice/approve/${params.id}`, {status: params.status})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
