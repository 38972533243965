<template>
  <div>
    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <div class="col-12">
          <div class="d-flex align-items-center">
            <img height="23px" src="/img/icons/icons8/ios/info-squared.png" class="mr-3" />
            <b class="text-dark mr-3">Identificação</b>
            <hr class="bg-secondary flex-grow-1 ml-2 mr-2">
          </div>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <div class="form-group row m-0 p-0 mt-n2">
                <div class="mt-n1 col-md-5">
                  <div class="pb-1 col-form-label form-control-label">
                    Nome da Construção
                    <span class="text-danger">*</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm pb-1 mb-1">
                      <input
                        type="text"
                        v-model="construction.construction_name"
                        class="form-control form-control-sm"
                        maxlength="150"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mt-2">
                <div class="col-md-5">
                  <div class="pb-1 col-form-label form-control-label">
                    Central Padrão
                    <span class="text-danger">*</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="construction.company_plant_uuid"
                        :items="plants"
                        customKey="uuid"
                        :loading="loadingCompanyPlant"
                        :disabled="loadingCompanyPlant"
                        @input="selectedPlant"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mt-2">
                <div class="col-md-5">
                  <div class="pb-1 col-form-label form-control-label">
                    Vendedor
                    <span class="text-danger">*</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        v-model="construction.seller_uuid"
                        :items="users"
                        customKey="uuid"
                        :loading="loadingUsers"
                        :disabled="loadingUsers || !selected_plant"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p- mt-2">
                <div class="col-md-5">
                  <div class="pb-1 col-form-label form-control-label">
                    Método de Prospecção
                    <span
                      class="btn btn-sm btn-success p-1"
                      @click.prevent="handleCreateProspectionMethod()"
                    >
                      <i class="fas fa-plus"></i> Novo
                    </span>
                  </div>
                </div>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="construction.prospection_method_construction_id"
                      :items="prospection_methods"
                      label="description"
                      :disabled="loadingProscpection"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5">
                  <div class="pb-1 col-form-label form-control-label">
                    Tipo
                    <span class="btn btn-sm btn-success p-1" @click.prevent="handleCreateType()">
                      <i class="fas fa-plus"></i>
                      Novo
                    </span>
                  </div>
                </div>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm pb-1 mb-1">
                    <PuzlSelect
                      v-model="construction.type_construction_id"
                      :items="types"
                      label="description"
                      :disabled="loadingTypes"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-5">
                  <div class="pt-1 pb-0 mt-1 col-form-label form-control-label">
                    Restrição de acesso
                  </div>
                </div>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="construction.access_restriction"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mt-2" style="margin-top: 13px !important;">
                <div class="col-md-5">
                  <div class="pt-1 pb-0 mt-1 col-form-label form-control-label">
                    Volume total estimado
                  </div>
                </div>
                <div class="col-md-7">
                  <base-input input-group-classes="input-group-sm pb-1 mb-1">
                    <input
                      inputmode="numeric"
                      v-model="construction.estimated_volume"
                      class="form-control form-control-sm"
                      v-mask="['#.#', '##.#', '###.#', '####.#', '#####.#', '######.#', '#######.#', '########.#']"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        m<sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1 mt-2">
                <div class="col-md-5">
                  <div class="pb-1 col-form-label form-control-label">
                    Período previsto
                  </div>
                </div>
                <div class="col-md-7">
                  <base-input v-if="range.end" input-group-classes="input-group-sm">
                    <input-date-picker
                      ref="input-date-picker"
                      @handleFilterDate="handleFilterDate"
                      :defaultRange="range"
                      size="sm"/>
                  </base-input>
                </div>
              </div>

              <div class="mt-3">
                <span class="mr-3 h3">
                  <img height="32px" src="/img/icons/icons8/ios/order-delivered_primary.png" class="mr-2"/>
                  Endereço
                </span>
                <hr class="bg-secondary mt-2 mb-1">
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    CEP<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required|min:9" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="construction.postal_code"
                        class="form-control form-control-sm"
                        v-mask="['#####-###']"
                        v-on:blur="getAddressByCode(construction.postal_code)"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        :disabled="checkAddressConstructionCanBeEdited || allowAddressConstructionEdit"
                      />
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    UF<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <PuzlSelect
                        v-model="construction.state"
                        :items="states"
                        customKey="letter"
                        label="letter"
                        :disabled="checkAddressConstructionCanBeEdited || allowAddressConstructionEdit"
                      />
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <div class="pb-0 mb-1 pr-0 col-form-label form-control-label">
                    Cidade <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <PuzlSelect
                        v-model="construction.city"
                        :items="cities"
                        customKey="title"
                        label="title"
                        :disabled="checkAddressConstructionCanBeEdited || allowAddressConstructionEdit"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0">
                <div class="col-md-12">
                  <div class="pb-0 mb-1 col-form-label form-control-label">
                    Endereço<span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                       <input
                        type="text"
                        v-model="construction.address"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1 mt-1">
                <div class="col-md-4">
                  <div class="pb-0 pr-0 col-form-label form-control-label">
                    N°<span class="text-danger">&nbsp;*</span>
                  </div>
                  <base-input input-group-classes="input-group-sm">
                    <input maxlength="5"
                           v-if="!without_number"
                           v-model="construction.number"
                           v-mask="'#####'"
                           inputmode="numeric"
                           class="form-control form-control-sm"
                    />
                    <input v-else maxlength="5"
                           v-model="construction.number"
                           disabled
                           type="text"
                           inputmode="numeric"
                           class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0" style="text-transform: none">
                        <a href="#" @click.prevent="without_number = !without_number">
                          <i v-if="without_number" class="fa-solid fa-square-check"></i>
                          <i v-else class="fa-regular fa-square"></i>
                        </a> Sem número
                      </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-md-4">
                  <div class="pb-0 col-form-label form-control-label">
                    Complemento
                  </div>
                  <base-input input-group-classes="form-control-sm input-group-sm">
                    <input
                      type="text"
                      v-model="construction.complement"
                      maxlength="20"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>

                <div class="col-md-4">
                  <div class="pb-0  pr-0 col-form-label form-control-label">
                    Bairro <span class="text-danger">&nbsp;*</span>
                  </div>
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="form-control-sm input-group-sm">
                      <input
                        type="text"
                        v-model="construction.district"
                        class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="danger" @click="closeModal()">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
                Cancelar
              </base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalCreateProspectionMethod ref="createProspectionMethod"/>
    <ModalCreateType ref="createType"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

const {formatErrorValidation} = require("@/plugins")
import axios from 'axios'
import ModalCreateProspectionMethod from '../../ProspectionMethod/Shared/_Create'
import ModalCreateType from '../../Type/Shared/_Create'
import InputDatePicker from "@/components/InputDatePicker"
import moment from "moment"
import {viacep} from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEditConstruction",
  components: {
    ModalCreateProspectionMethod,
    ModalCreateType,
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      modal: false,
      title: 'Obra',
      without_number: false,
      checkAddressConstructionCanBeEdited: false,
      allowAddressConstructionEdit: false,
      construction: {
        status: true,
        customer_construction_uuid: '',
        seller_uuid: '',
        construction_name: '',
        access_restriction: '',
        prospection_method_construction_id: '',
        type_construction_id: '',
        estimated_volume: '',
        scheduled_beginning: '',
        scheduled_ending: '',
        total_days: '',
        defatul: true,
        alias: '',
        postal_code: '',
        district: '',
        state: '',
        city: '',
        address: '',
        number: '',
      },
      range: {
        start: '',
        end: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      loadingSave: false,
      loadingProscpection: true,
      loadingTypes: true,
      loadingStates: true,
      loadingCities: true,
      loadingCompanyPlant: true,
      loadingUsers: false,
      selected_plant: false,
      isInputEvent: false,
    }
  },
  computed: {
    ...mapGetters({
      'prospection_methods': 'customerConstructionProspectionMethod/fetch',
      'types': 'customerConstructionType/fetch',
      'states': 'localizations/states',
      'cities': 'localizations/cities',
      'plants': 'plant/activeItems',
      'users': "user/fetch",
      $_setting: "generalSetting/show",
    }),
  },
  watch: {
    'construction.scheduled_beginning': function () {
      this.getMonths()
    },
    'without_number' : function (val) {
      if (val) {
        this.construction.number = 'SN'
      }
      else {
        this.construction.number = ''
      }
    },
    'construction.scheduled_ending': function () {
      this.getMonths()
    },
    'construction.state': function (value) {
      this.getCities(value)
    },
  },
  methods: {
    handleCreateProspectionMethod() {
      this.$refs.createProspectionMethod.handleCreateModal()
    },
    handleCreateType() {
      this.$refs.createType.handleCreateModal()
    },
    closeModal() {
      this.modal = false
    },
    handleEditModal(uuid) {
      this.without_number = false
      this.construction = {
        status: true,
        company_plant_uuid: '',
        seller_uuid: '',
        customer_construction_uuid: '',
        construction_name: '',
        web_site: '',
        access_restriction: '',
        prospection_method_construction_id: '',
        type_construction_id: '',
        estimated_volume: '',
        scheduled_beginning: '',
        scheduled_ending: '',
        total_days: '',
        defatul: true,
        alias: '',
        postal_code: '',
        district: '',
        state: '',
        city: '',
        address: '',
        number: '',
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      let loader = this.$loading.show();
      this.$store.dispatch('customerConstructionConstructions/show', uuid)
        .then((response) => {
          this.construction = {
            uuid: response.data.uuid,
            status: response.data.status || false,
            customer_construction_uuid: response.data.customer_construction.uuid || '',
            construction_name: response.data.construction_name || '',
            access_restriction: response.data.access_restriction || '',
            seller_uuid: response.data.seller && response.data.seller.uuid || '',
            company_plant_uuid: response.data.plant && response.data.plant.uuid || '',
            prospection_method_construction_id: response.data.prospection_method_construction && response.data.prospection_method_construction.id || '',
            type_construction_id: response.data.type_construction && response.data.type_construction.id || '',
            estimated_volume: response.data.estimated_volume || '',
            scheduled_beginning: response.data.scheduled_beginning || '',
            scheduled_ending: response.data.scheduled_ending || '',
            total_days: response.data.total_days || '',
            default: response.data.default_address.default || true,
            address_uuid: response.data.default_address.uuid || '',
            alias: response.data.default_address.alias || '',
            postal_code: response.data.default_address.postal_code || '',
            district: response.data.default_address.district || '',
            state: response.data.default_address.state || '',
            city: response.data.default_address.city || '',
            address: response.data.default_address.address || '',
            number: response.data.default_address.number || '',
            complement: response.data.default_address.complement || '',
          }
          if (this.construction.number === 'SN') {
            this.without_number = true
          }
          if (this.construction.scheduled_beginning && this.construction.scheduled_ending) {
            this.range = {
              start: moment(this.construction.scheduled_beginning).toDate(),
              end: moment(this.construction.scheduled_ending).toDate(),
            };
          } else {
            this.range = {
              start: moment().toDate(),
              end: moment().toDate(),
            };
          }
          Promise.all([
            this.$store.dispatch('contractProposal/getHasInvoice', this.construction.uuid)
          ]).then((response) => {
            this.checkAddressConstructionCanBeEdited = response[1].data ? true : false;
            this.allowAddressConstructionEdit = response[1].data ? true : false;
           /**
            * Caso as configurações gerais permitam alterar o endereço da obra,
            * o usuário poderá editar o endereço independente da regra anterior.
            * Menos para os campos que possuirem allowAddressConstructionEdit na verificação do :disabled
            */
            if (this.$_setting.construction_address) {
              this.checkAddressConstructionCanBeEdited = false;
            }
          }).finally(() => {
            loader.hide();
            this.loadSellerAfterLoadingData();
          });
          this.$refs['input-date-picker'].resetDate();
          this.modal = true
        }).catch((error) => {
        if (error.status === 422) {
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({type: 'danger', message: message})
        } else {
          this.$notify({type: error.data.error_type, message: error.data.message})
        }
        loader.hide();
        this.loadingSave = false
      })

      this.modal = true
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.construction.scheduled_beginning = moment(this.range.start).format("YYYY-MM-DD");
      this.construction.scheduled_ending = moment(this.range.end).format("YYYY-MM-DD");
    },
    save() {
      this.$Progress.start()
      this.loadingSave = true
      this.$store.dispatch('customerConstructionConstructions/update', this.construction)
        .then(response => {
          this.$emit('updatedConstruction', response.data)
          this.$emit('update')
          this.loadingSave = false
          this.modal = false
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
          } else if (error.response && error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: message
            })
          }
          this.$Progress.finish();
          this.loadingSave = false;
        })
    },
    getMonths() {
      let dt1 = new Date(this.construction.scheduled_ending)
      let dt2 = new Date(this.construction.scheduled_beginning)

      if (dt1 !== null && dt2 !== null) {
        let diference = dt1.getTime() - dt2.getTime()
        let diference_in_days = diference / (1000 * 3600 * 24)

        if (diference_in_days) {
          this.construction.total_days = diference_in_days
        }
      }
    },
    getCities(state) {
      this.$Progress.start();

      this.$store.dispatch('localizations/fetchCities', {uf: state})
        .then((response) => {
          this.loadingCities = false
          this.$Progress.finish()
        })
        .catch((error) => {
          this.loadingCities = false
          this.$Progress.finish()
        });
    },
    getAddressByCode(code) {
      this.$Progress.start();
      let postal_code = code.toString().replace(/[^\d]+/g, '')
      if (postal_code) {
        viacep(postal_code).then((response) => {
          this.construction.state = response.data.uf
          this.construction.address = response.data.logradouro
          this.construction.state = response.data.uf
          this.construction.district = response.data.bairro
          this.construction.city = response.data.localidade
          this.$notify({type: 'success', message: 'Dados da empresa carregado com sucesso.'})
          this.$Progress.finish()
        })
      }
    },
    selectedPlant() {
      this.construction.seller_uuid = null;
      if (this.construction.company_plant_uuid) {
        this.selected_plant = true;
        // Carrega vendedores alocados a central selecionada.
        this.loadingUsers = true;
        this.$store.dispatch("user/getSellersAllocatedByPlant", this.construction.company_plant_uuid).then(response => {
          this.loadingUsers = false;
          // Se existir apenas um vendedor no select, ele será selecionado automaticamente.
          if (response.data.length === 1) {
            this.construction.seller_uuid = response.data[0].uuid;
          }
        });
      } else {
        this.selected_plant = false;
      }
    },
    loadSellerAfterLoadingData() {
      if (this.construction.company_plant_uuid) {
        this.selected_plant = true;
        // Carrega vendedores alocados a central selecionada.
        this.$store.dispatch("user/getSellersAllocatedByPlant", this.construction.company_plant_uuid).then(() => {
          this.$forceUpdate();
        });
      } else {
        this.selected_plant = false;
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate()
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingCompanyPlant = false
    })
    this.$store.dispatch('customerConstructionProspectionMethod/fetchItems').then(() => this.loadingProscpection = false)
    this.$store.dispatch('customerConstructionType/fetchItems').then(() => this.loadingTypes = false)
    this.$store.dispatch('localizations/fetchStates').then(() => this.loadingStates = false)
    this.$store.dispatch('generalSetting/show')
  },
  beforeUpdate() {
    // Se existir apenas uma central no select, ela será selecionado automaticamente.
    if (this.plants.length === 1) {
      this.construction.company_plant_uuid = this.plants[0].uuid;
    }
  },
}
</script>

<style scoped>

</style>
