import store from "../../shared/libraries/store/storeVuex";
import auth from "@/store/modules/auth/auth";
import recipe_net from "@/store/modules/configuration/recipe_net";
import localizations from "@/store/modules/configuration/localizations";
import dosageLocation from "@/store/modules/configuration/dosage_locations";
import moldingLocation from "@/store/modules/configuration/molding_location";
import basePreparation from "@/store/modules/configuration/base_preparation";
import category from "@/store/modules/technology/cmc/category";
import moldingCapping from "@/store/modules/technology/test/molding_capping";
import DashboardTechnology from "@/store/modules/technology/dashboard/dashboard"
import automation from '@/store/modules/automation/index';
import xmlIssuedes from '@/store/modules/financial/nfe/issuedes';
import digitalSignatureConfiguration from '@/store/modules/commercial/contract-proposal/digital-signature/digital_signature_configuration'
import digitalSignature from '@/store/modules/commercial/contract-proposal/digital-signature/digital_signature'
import digitalSignatureResume from '@/store/modules/commercial/contract-proposal/digital-signature/digital_signature_resume'
import proposalTerms from '@/store/modules/commercial/contract-proposal/proposal_term'
import nfe from '@/store/modules/general/nfe'
import notification from '@/store/modules/general/notification'
import reuseControl from '@/store/modules/administrative/reuse/reuse'
import technicalDre from '@/store/modules/technology/test/dre'
import entityWallet from '@/store/modules/administrative/entity-wallet/entity-wallet';
import defaultMolding from '@/store/modules/technology/test/default_molding'
import hardenedStateTestEquipment from '@/store/modules/technology/equipment/equipment'
import hardenedStateTestImport from '@/store/modules/technology/equipment/import'
// Start General
import city from "@/store/modules/general/city/city";
import exports from "@/store/modules/general/export";
import deliveryResume from "@/store/modules/administrative/delivery-resume/delivery_resume";
import driverResume from "@/store/modules/administrative/driver-resume/driver_resume";
import equipmentResume from "@/store/modules/administrative/equipment-resume/equipment_resume";
import horaryResume from "@/store/modules/administrative/horary-resume/horary_resume";
// End General

// Start Purchase
import purchaseRequest from '@/store/modules/purchase/purchase_request'
import purchaseBudget from '@/store/modules/purchase/purchase_budgets'
import purchaseCategory from '@/store/modules/purchase/purchase_category'
// End Purchase

// Start Product Service
import productServiceNew from '@/store/modules/configuration/product_service'
// End Purchase

// Start Plant Software
import plant from "@/store/modules/configuration/plant/plant";
import plantChargePoint from "@/store/modules/configuration/plant/charge_point";
import plantChargePointLocation from "@/store/modules/configuration/plant/charge_point_location";
import companyPlantIssuer from "@/store/modules/configuration/plant/company_plant_issuer";
import companyPlantIssuerGroup from "@/store/modules/configuration/plant/company_plant_issuer_group";
import companyPlantBuyer from "@/store/modules/configuration/plant/company_plant_buyer";
import companyPlantBankAccount from "@/store/modules/configuration/plant/company_plant_bank_account";
import companyPlantAddress from "@/store/modules/configuration/plant/address/company_plant_address";
// End Plant Software

// Start Users
import user from "@/store/modules/configuration/user/user";
import userPosition from "@/store/modules/configuration/user/user_position";
import userPlant from "@/store/modules/configuration/user/user_plant";
import userPermission from "@/store/modules/configuration/user/user_permission";
import userComplement from "@/store/modules/configuration/user/user_complement";
import userRole from "@/store/modules/configuration/user/user_role";
import authGroupPermission from "@/store/modules/configuration/group_permission";
import userCredit from "@/store/modules/configuration/user/user_credit";
import permissionFinancialLimit from "@/store/modules/configuration/user/permission_financial_limit";
import commercialPermission from "@/store/modules/configuration/user/commercial_permission";
import operationalPermission from "@/store/modules/configuration/user/operational_permission";
import technicalPermission from "@/store/modules/configuration/user/technical_permission";
import userAlert from "@/store/modules/configuration/user/user_alert";
import alertGroup from "@/store/modules/configuration/user/alert_group";
import permissionChange from "@/store/modules/configuration/user/permission_change";
// End Users

// Start Configuration
import jobDescription from "@/store/modules/configuration/job_description";
import contractRegime from "@/store/modules/configuration/contract_regime";
import attachmentType from "@/store/modules/configuration/attachment_type";
import chargingTypeProfile from "@/store/modules/configuration/charging_type_profile";
import card from "@/store/modules/configuration/card/card";
import task from "@/store/modules/configuration/task/task";
import taskAttachment from "@/store/modules/configuration/task/task_attachment";
import entryLaunch from "@/store/modules/configuration/entry-launch/entry_launch";
import entryLaunchExternal from "@/store/modules/configuration/entry-launch/entry_launch_external";
import entryLaunchItem from "@/store/modules/configuration/entry-launch/entry_launch_item";
import freightModality from "@/store/modules/configuration/freight/freight_modality";
import exitLaunch from "@/store/modules/configuration/exit-launch/exit_launch";
// End Configuration

// Start Module and Menu
import tag from "@/store/modules/configuration/menu/tag";
import menu from "@/store/modules/configuration/menu/menu";
import module from "@/store/modules/configuration/menu/module";
// End Module and Menu

import entity from "@/store/modules/configuration/entity/entity";
import entityCategory from "@/store/modules/configuration/entity/entity_category";
import entityBankAccount from "@/store/modules/configuration/entity/entity_bank_account";
import entityPixAccount from "@/store/modules/configuration/entity/entity_pix_account";
import entityHistory from "@/store/modules/configuration/entity/entity_history";
import entityRegistry from "@/store/modules/configuration/entity/entity_registry";
import entityCredit from "@/store/modules/configuration/entity/entity_credit";
import entityAnticipationBalanceTransfer from "@/store/modules/configuration/entity/entity_anticipation_balance_transfer";
import entityAttachment from "@/store/modules/configuration/entity/entity_attachment";
import entityContact from "@/store/modules/configuration/entity/entity_contact";
import additional from "@/store/modules/configuration/additional/additional";
import measuredunit from "@/store/modules/configuration/measuredunit";
import reliability_level from "@/store/modules/configuration/reliability_level";
import service from "@/store/modules/configuration/service/service";
import temperature from "@/store/modules/configuration/temperature/temperature";
import temperatureSetting from "@/store/modules/configuration/temperature/temperature_setting";
import water from "@/store/modules/configuration/water/water";
import waterSetting from "@/store/modules/configuration/water/water_setting";
import costCenter from "@/store/modules/configuration/cost-center/cost_center";
import commission from "@/store/modules/configuration/commission/commission";
import commissioningType from "@/store/modules/configuration/commission/commissioning_type";
import moisture from "@/store/modules/configuration/moisture/moisture";
import moistureSetting from "@/store/modules/configuration/moisture/moisture_setting";
import chartAccount from "@/store/modules/configuration/chart-account/chart_account";
import managerialChartAccount from "@/store/modules/configuration/managerial-chart-account/managerial_chart_account";

// Start Custormer Constructions
import customerConstruction from "@/store/modules/commercial/customer-construction/customer";
import customerConstructionType from "@/store/modules/commercial/customer-construction/customer_construction_type";
import customerConstructionProspectionMethod from "@/store/modules/commercial/customer-construction/prospection_method";
import customerConstructionConstructions from "@/store/modules/commercial/customer-construction/constructions";
import customerConstructionConstructionsContact from "@/store/modules/commercial/customer-construction/construction_contact";
import addressConstruction from "@/store/modules/commercial/customer-construction/address_construction";
import constructionAttachment from "@/store/modules/commercial/customer-construction/construction_attachment";
// End Custormer Constructions

import MixDesigns from "@/store/modules/technology/mix/mix_design";
import MixDesignMixe from "@/store/modules/technology/mix/mix_design_mixe";
import mixType from "@/store/modules/technology/mix/type";
import mixSubtype from "@/store/modules/technology/mix/subtype";
import mixAdditionalClassifications from "@/store/modules/technology/mix/additionalclassification";
import mixSlumpFlow from "@/store/modules/technology/mix/slumpflow";
import mixConcretePiece from "@/store/modules/technology/mix/concretepiece";
import mixMaximumSize from "@/store/modules/technology/mix/maximumsize";
import mixPuzlAdmixture from "@/store/modules/technology/mix/puzladmixture";
import mixFeature from "@/store/modules/technology/mix/feature";
import mixDesignResume from "@/store/modules/technology/mix/mix_design_resume";
import mixFeatureItem from "@/store/modules/technology/mix/feature_item";
import mixReference from "@/store/modules/technology/mix/mix_reference";

// Start Adjustment
import adjustmentConcretePiece from "@/store/modules/technology/adjustment/concrete_piece";
import adjustmentLimitSetting from "@/store/modules/technology/adjustment/limit_setting";
import adjustmentConcreteCycle from "@/store/modules/technology/adjustment/concrete_cycle";
import adjustmentVolume from "@/store/modules/technology/adjustment/volume";
import adjustmentCmc from "@/store/modules/technology/adjustment/cmc";
import adjustmentStabilizingAdditive from "@/store/modules/technology/adjustment/stabilizing_additive";
import adjustmentStabilizingAdditiveDosage from "@/store/modules/technology/adjustment/dosage";
import adjustmentTemperature from "@/store/modules/technology/adjustment/temperature";
import adjustmentChargePoint from "@/store/modules/technology/adjustment/charge_point";
import adjustmentReuse from "@/store/modules/technology/adjustment/reuse";
import adjustmentLeftOver from "@/store/modules/technology/adjustment/left_over";
import adjustmentContractHour from "@/store/modules/technology/adjustment/contract_hour";
import adjustmentType from "@/store/modules/technology/adjustment/adjustment_type";
import adjustmentLevelResponsibility from "@/store/modules/technology/adjustment/adjustment_level_responsibility";
import adjustmentFinalSlump from "@/store/modules/technology/adjustment/adjustment_final_slump";
import applicationLevelGuideline from "@/store/modules/technology/adjustment/application_level_guideline";
// End Adjustment

import FeesAndTaxes from "@/store/modules/configuration/fees_and_taxes/fees_and_taxes";
import TaxLocality from "@/store/modules/configuration/fees_and_taxes/tax_locality";
import deductionRules from "@/store/modules/configuration/fees_and_taxes/deduction_rules";

// Start Test "ensaios"
import testHardenedState from "@/store/modules/technology/test/hardened_state";
import testReport from "@/store/modules/technology/test/report";
import testHardenedStateMold from "@/store/modules/technology/test/hardened_state_mold";
import testFreshState from "@/store/modules/technology/test/fresh_state";
import anomaly from "@/store/modules/technology/test/anomaly";

// End Test "ensaios"

// Start CMC Software
import cmc from "@/store/modules/technology/cmc/cmc";
import cmcPrice from "@/store/modules/technology/cmc/cmc_price";
import cmcGroup from "@/store/modules/technology/cmc/group";
import cmcProduct from "@/store/modules/technology/cmc/product";
import cmcAdditionalClassification from "@/store/modules/technology/cmc/additional_classification";
import cmcSupplier from "@/store/modules/technology/cmc/supplier";
import cmcSupplierOrigin from "@/store/modules/technology/cmc/supplier_origin";
import cmcSupplierOriginEntity from "@/store/modules/technology/cmc/supplier_origin_entity";
import cmcDivergentPrice from "@/store/modules/technology/cmc/cmc_divergent_price";
// End CMC Software

import concretePrice from "@/store/modules/commercial/concrete-price/concrete_price";

// Start Contract Proposal
import contractProposal from "@/store/modules/commercial/contract-proposal/contract_proposal";
import formulations from "@/store/modules/commercial/contract-proposal/formulations";
import contractSituation from "@/store/modules/commercial/contract-proposal/situation";
import contractCompetitor from "@/store/modules/commercial/contract-proposal/competitor";
import contractService from "@/store/modules/commercial/contract-proposal/services";
import contractProposalServiceResume from "@/store/modules/commercial/contract-proposal/contract_proposal_service_resume";
import contractAdditional from "@/store/modules/commercial/contract-proposal/additional";
import contractContracts from "@/store/modules/commercial/contract-proposal/contract";
import contractProposalProjection from "@/store/modules/commercial/contract-proposal/contract_proposal_projection";
import contractContractsPages from "@/store/modules/commercial/contract-proposal/contract_pages";
import contractProposalCredit from "@/store/modules/commercial/contract-proposal/contract_proposal_credit";
import contractProposalPayment from "@/store/modules/commercial/contract-proposal/contract_proposal_payment";
import reasonCancellation from "@/store/modules/commercial/contract-proposal/reason_cancellation";
import salesResume from "@/store/modules/commercial/sales-resume/sales_resume";
import schedulesReport from "@/store/modules/commercial/schedule-report/schedule_report";
import generalResume from "@/store/modules/commercial/general-resume/general_resume";
import ebitdaAnalysis from "@/store/modules/commercial/ebitda-analysis/ebitda_analysis";
// End Contract Proposal

import commercialDashboard from "@/store/modules/commercial/dashboard/commercial_dashboard";

// Start Operational
import scheduleTravelDistribution from "@/store/modules/operational/schedule/schedule_travel_distribution";
import schedule from "@/store/modules/operational/schedule/schedule";
import travels from "@/store/modules/operational/schedule/travels";
import scheduleGantt from "@/store/modules/operational/schedule/schedule_gantt";
import scheduleResume from "@/store/modules/operational/schedule/schedule_resume";
import newScheduleResume from "@/store/modules/operational/schedule/new_schedule_resume";
import scheduleService from "@/store/modules/operational/schedule/schedule_service";
import scheduleAdditional from "@/store/modules/operational/schedule/schedule_additional";
import travelConfiguration from "@/store/modules/operational/schedule/schedule_travel_configuration";
import travelLeftOver from "@/store/modules/operational/schedule/schedule_travel_left_over";
import operationalDashboard from "@/store/modules/operational/dashboard/operational_dashboard";
import operationalStockResume from "@/store/modules/operational/stock/resume";
import MCCResume from "@/store/modules/operational/stock/mcc_resume";
import inCourse from "@/store/modules/operational/in_course/in_course";
import stock from "@/store/modules/operational/stock/stock";
import stockBalance from "@/store/modules/operational/stock-balance/stock_balance";
import stockAdjustment from "@/store/modules/operational/stock/adjustment/stock_adjustments";
import resumeAdditional from "@/store/modules/operational/resume-additional/resume_additional";
import dailyResume from "@/store/modules/operational/daily-resume/daily_resume";
import chargeResume from "@/store/modules/operational/charge-resume/charge_resume";
import scheduleTravelGantt from "@/store/modules/operational/schedule/schedule_travel_gantt";
// End Operational

// Start Services
import scheduleServiceAgenda from "@/store/modules/service/agenda/schedule_service_agenda";
// End Services

// Start Financial
import paymentMethod from "@/store/modules/financial/bill-receive/payment_method";
import paymentIntermediary from "@/store/modules/financial/bill-receive/payment_intermediary";
import paymentIntermediaryMerchant from "@/store/modules/financial/bill-receive/payment_intermediary_merchant";
import paymentTerm from "@/store/modules/financial/bill-receive/payment_term";
import billingInvoice from "@/store/modules/financial/billing/invoice";
import billReceive from "@/store/modules/financial/bill-receive/bill_receive";
import approval from "@/store/modules/financial/bill-receive/approval";
import bankBillet from "@/store/modules/financial/bank-billet/bank_billet";
import nfse from "@/store/modules/financial/billing/nfse";
import invoiceAttachment from "@/store/modules/financial/billing/invoice_attachment";
import bankAccount from "@/store/modules/financial/bank-account/bank_account";
import bankAccountSetting from "@/store/modules/financial/bank-account/bank_account_setting";
import accountClosingHistory from "@/store/modules/financial/bank-account/account_closing_history";
import bankAccountBilletParam from "@/store/modules/financial/bank-account/bank_account_billet_param";
import bankAccountPixParam from "@/store/modules/financial/bank-account/bank_account_pix_param";
import bankBilletRemittance from "@/store/modules/financial/bank-billet-remittance/bank_billet_remittance";
import bankBilletDischarge from "@/store/modules/financial/bank-billet-discharge/bank_billet_discharge";
import bank from "@/store/modules/financial/bank/bank";
import bankReconciliation from "@/store/modules/financial/bank-reconciliation/bank_reconciliation";
import bankReconciliationTransaction from "@/store/modules/financial/bank-reconciliation/bank_reconciliation_transaction";
import bankReconciliationTransactionAssociation from "@/store/modules/financial/bank-reconciliation/bank_reconciliation_transaction_association";
import documentTemplate from "@/store/modules/financial/document-template/document_template";
import billPay from "@/store/modules/financial/bill-pay/bill_pay";
import financialDashboard from "@/store/modules/financial/dashboard/financial_dashboard";
import bankBalance from "@/store/modules/financial/bank-balance/bank_balance";
import bankTransfer from "@/store/modules/financial/bank-transfer/bank_transfer";
import accountStatement from "@/store/modules/financial/account-statement/account_statement";
import detached from "@/store/modules/financial/billing/detached/detached";
import creditRelease from "@/store/modules/financial/credit-release/credit_release";
import anticipationBalance from "@/store/modules/financial/bill-receive/anticipation-balance";
import financeTypes from "@/store/modules/financial/finance-types/finance-types";
import finances from "@/store/modules/financial/finances/finances";
import financeInstallments from "@/store/modules/financial/finance-installments/finance-installments";
import financeTransactions from "./financial/finance-transactions/finance-transactions";
// End Financial

import operationSource from "@/store/modules/fiscal/operation-source/operation_source";
import issResume from "@/store/modules/fiscal/iss-resume/iss_resume";
import accountingChartAccount from "@/store/modules/fiscal/accounting-chart-account/accounting_chart_account";
import fiscalClosing from "@/store/modules/fiscal/fiscal-closing/fiscal_closing";

// Start Product Service
import productService from "@/store/modules/configuration/product-service/product_service";
import productServiceCategory from "@/store/modules/configuration/product-service/product_service_category";
import productServiceHubPrice from "@/store/modules/configuration/product-service/product_service_hub_price";
import productServiceHubType from "@/store/modules/configuration/product-service/product_service_hub_type";
// End Product Service

// Start Service Description
import serviceDescription from "@/store/modules/commercial/service-price/service-description/service_description";
// End Service Description

// Start Service Price
import servicePrice from "@/store/modules/commercial/service-price/service/service_price";
// End Service Price
import Permission from "@/store/modules/auth/permission"

// Start Tutorial
import tutorial from "@/store/modules/support/tutorial";
// End Tutorial

// Start Company
import company from "@/store/modules/configuration/company/company";
import companyEmailParam from "@/store/modules/configuration/company-email-param/company_email_param";
import generalSetting from "@/store/modules/configuration/company/general-setting/general_setting";
// End Company

// Start Equipment
import equipment from "@/store/modules/equipment/equipment/equipment";
import implement from "@/store/modules/equipment/equipment/implement";
import equipmentCategory from "@/store/modules/equipment/equipment/category";
import equipmentType from "@/store/modules/equipment/equipment/type";
import equipmentSituation from "@/store/modules/equipment/equipment/situation";
import fuelTank from "./equipment/fuel_control/fuel_tank";
import fuelControl from "./equipment/fuel_control/fuel_control";
import fuelResume from "./equipment/fuel_resume/fuel_resume";
import itemCheckList from "@/store/modules/equipment/equipment/item_check_list";
import checklist from "@/store/modules/equipment/equipment/checklist";
// End Equipment

// Start Molding Resume
import moldingResume from "@/store/modules/technology/molding-resume/molding_resume";
// End Molding Resume

// Start Kanban
import kanban from "@/store/modules/operational/schedule/kanban";
import deploy from "@/store/modules/support/deploy";
// End Kanban

// Start Cashier Resume
import cashierResume from "@/store/modules/financial/cashier-resume/cashier_resume";
//End Cashier Resume
import dre from "@/store/modules/administrative/dre/dre";

import stockMovement from "@/store/modules/operational/stock/stock_movement";
import stockClosing from '@/store/modules/operational/stock/stock_closing';

//End Cashier Resume

import electronicBankStatement from "@/store/modules/financial/electronic-bank-statement/electronic_bank_statement";
import MixReferenceHistory from "@/store/modules/technology/mix/mix_reference_histories"

//Whatsapp api
import whatsappApi from "@/store/modules/whatsapp/whatsapp";

import scheduleTravelMolding from '@/store/modules/operational/schedule/schedule_travel_molding'

import scheduleTravelCycleAttachment from '@/store/modules/operational/schedule/schedule_travel_cycle_attachment'

import entityAnticipationBalanceHistory from "@/store/modules/configuration/entity/entity_anticipation_balance_history";

import paymentBrand from '@/store/modules/financial/payment-brand/payment_brand'

export default {
  store,
  adjustmentVolume,
  adjustmentTemperature,
  adjustmentConcreteCycle,
  adjustmentConcretePiece,
  adjustmentStabilizingAdditive,
  adjustmentCmc,
  adjustmentStabilizingAdditiveDosage,
  adjustmentLimitSetting,
  adjustmentChargePoint,
  adjustmentReuse,
  adjustmentLeftOver,
  adjustmentContractHour,
  auth,
  category,
  recipe_net,
  localizations,
  city,
  plant,
  temperature,
  temperatureSetting,
  water,
  waterSetting,
  costCenter,
  entity,
  entityCategory,
  entityBankAccount,
  entityPixAccount,
  entityHistory,
  entityRegistry,
  entityCredit,
  entityAttachment,
  entityAnticipationBalanceTransfer,
  commission,
  commissioningType,
  moisture,
  moistureSetting,
  chartAccount,
  managerialChartAccount,
  user,
  userPosition,
  userCredit,
  tag,
  menu,
  module,
  additional,
  measuredunit,
  reliability_level,
  service,
  schedule,
  travels,
  scheduleGantt,
  scheduleService,
  scheduleResume,
  newScheduleResume,
  customerConstruction,
  customerConstructionType,
  customerConstructionProspectionMethod,
  customerConstructionConstructions,
  customerConstructionConstructionsContact,
  addressConstruction,
  MixDesigns,
  MixDesignMixe,
  mixType,
  mixSubtype,
  mixAdditionalClassifications,
  mixSlumpFlow,
  mixConcretePiece,
  mixMaximumSize,
  mixPuzlAdmixture,
  mixFeature,
  mixDesignResume,
  mixFeatureItem,
  mixReference,
  testHardenedState,
  testHardenedStateMold,
  cmc,
  cmcPrice,
  cmcProduct,
  cmcGroup,
  cmcAdditionalClassification,
  cmcSupplier,
  cmcSupplierOrigin,
  cmcSupplierOriginEntity,
  cmcDivergentPrice,
  plantChargePoint,
  concretePrice,
  scheduleTravelDistribution,
  plantChargePointLocation,
  dosageLocation,
  FeesAndTaxes,
  TaxLocality,
  deductionRules,
  contractSituation,
  contractCompetitor,
  contractProposal,
  formulations,
  jobDescription,
  contractRegime,
  contractService,
  attachmentType,
  userPlant,
  userPermission,
  userComplement,
  userRole,
  authGroupPermission,
  contractAdditional,
  equipment,
  implement,
  equipmentCategory,
  equipmentType,
  equipmentSituation,
  testFreshState,
  moldingLocation,
  basePreparation,
  moldingCapping,
  travelConfiguration,
  contractContracts,
  contractProposalProjection,
  contractContractsPages,
  contractProposalCredit,
  contractProposalServiceResume,
  travelLeftOver,
  scheduleAdditional,
  testReport,
  paymentMethod,
  paymentTerm,
  chargingTypeProfile,
  card,
  task,
  taskAttachment,
  contractProposalPayment,
  reasonCancellation,
  salesResume,
  generalResume,
  schedulesReport,
  billingInvoice,
  billReceive,
  financialDashboard,
  bankBalance,
  bankTransfer,
  operationSource,
  issResume,
  accountingChartAccount,
  accountStatement,
  detached,
  nfse,
  bankAccount,
  bankAccountSetting,
  accountClosingHistory,
  bankAccountBilletParam,
  bankAccountPixParam,
  bankBilletRemittance,
  bankBilletDischarge,
  bank,
  documentTemplate,
  entryLaunch,
  entryLaunchExternal,
  entryLaunchItem,
  exitLaunch,
  bankReconciliation,
  companyPlantIssuer,
  companyPlantIssuerGroup,
  companyPlantBuyer,
  productServiceHubPrice,
  productServiceCategory,
  productService,
  operationalDashboard,
  serviceDescription,
  servicePrice,
  commercialDashboard,
  inCourse,
  stock,
  stockBalance,
  stockAdjustment,
  resumeAdditional,
  freightModality,
  DashboardTechnology,
  automation,
  billPay,
  Permission,
  tutorial,
  productServiceHubType,
  company,
  companyEmailParam,
  generalSetting,
  bankBillet,
  invoiceAttachment,
  paymentIntermediary,
  paymentIntermediaryMerchant,
  xmlIssuedes,
  operationalStockResume,
  companyPlantBankAccount,
  companyPlantAddress,
  fuelTank,
  fuelControl,
  fuelResume,
  exports,
  moldingResume,
  kanban,
  cashierResume,
  bankReconciliationTransaction,
  bankReconciliationTransactionAssociation,
  anomaly,
  approval,
  creditRelease,
  deploy,
  permissionFinancialLimit,
  commercialPermission,
  operationalPermission,
  technicalPermission,
  anticipationBalance,
  financeTypes,
  finances,
  financeInstallments,
  financeTransactions,
  dre,
  ebitdaAnalysis,
  adjustmentType,
  adjustmentLevelResponsibility,
  adjustmentFinalSlump,
  applicationLevelGuideline,
  proposalTerms,
  digitalSignatureConfiguration,
  digitalSignature,
  digitalSignatureResume,
  purchaseRequest,
  purchaseBudget,
  purchaseCategory,
  MCCResume,
  nfe,
  dailyResume,
  chargeResume,
  notification,
  electronicBankStatement,
  reuseControl,
  deliveryResume,
  driverResume,
  equipmentResume,
  technicalDre,
  constructionAttachment,
  entityContact,
  MixReferenceHistory,
  horaryResume,
  whatsappApi,
  stockMovement,
  stockClosing,
  userAlert,
  alertGroup,
  scheduleTravelMolding,
  entityWallet,
  permissionChange,
  defaultMolding,
  scheduleTravelCycleAttachment,
  entityAnticipationBalanceHistory,
  fiscalClosing,
  productServiceNew,
  scheduleTravelGantt,
  scheduleServiceAgenda,
  hardenedStateTestEquipment,
  hardenedStateTestImport,
  paymentBrand,
  itemCheckList,
  checklist
};
