<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important" class="text-primary">
            <img height="30px" src="/img/icons/physics.png" class="mr-2 mb-1"/>
            Agenda de ensaios
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button block
                           @click.prevent="$router.push({name: 'technology.report.index'})"
                           type="primary" class="text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/icons8/ios/impossible-shapes.png" class="mr-1 mt-n1"/>
                Remessas
              </base-button>
            </div>
            <base-dropdown class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button block slot="title-container" type="warning" class="dropdown-toggle text-uppercase"
                           style="font-size: 0.8rem">
                <img height="19" src="/img/icons/product-documents.png" class="mr-1 mt-n1"/>
                Cadastros
              </base-button>
              <div>
                <router-link :to="{
                    path: '/technology/test/fresh-state',
                    name: 'technology.fresh-state.index',
                  }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/vlc.png" class="mr-1 mt-n1"/>
                    Estado Fresco
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>

                <router-link :to="{
                    path: '/technology/test/hardened-state',
                    name: 'technology.hardened-state.index',
                  }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/impossible-shapes_primary.png" class="mr-1 mt-n1"/>
                    Estado endurecido
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>

                <router-link :to="{
                  path: '/technology/test/anomaly',
                  name: 'technology.anomaly.index',
                }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/question--v1.png" class="mr-1 mt-n1"/> Anomalias
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a @click.prevent="handleShowModalDefaultMolding" class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/impossible-shapes_primary.png" class="mr-1 mt-n1"/> Padrão de moldagem
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a @click.prevent="handleShowNewEquipment" class="dropdown-item new-default-black-font">
                    <i class="fa-solid fa-stapler mr-n1 pr-2"></i> Cadastrar equipamento
                  </a>
                </router-link>
              </div>
            </base-dropdown>
            <base-dropdown class="mb-md-0 mb-2 col-md-3 px-1">
              <base-button slot="title-container" block class="new-default-black dropdown-toggle text-uppercase"
                           style="font-size: 0.8rem">
                <img height="19" src="/img/icons/icons8/ios/graph-report.png" class="mr-1 mt-n1"/>
                Relatório
              </base-button>
              <div>
                <a class="dropdown-item new-default-black-font" href="#" @click.prevent="showModalTestReport">
                  <img height="19" src="/img/icons/excel.png" class="mr-1 mt-n1"/> Resultado
                </a>
                <div class="dropdown-divider p-0 m-0"></div>

                <a class="dropdown-item new-default-black-font" href="#"
                   @click.prevent="$refs.modalSearchContract.openModal(false)">
                  <img height="19" src="/img/icons/pdf-v2.png" class="mr-1 mt-n1"/> Desempenho do concreto
                </a>
                <div class="dropdown-divider p-0 m-0"></div>

                <a class="dropdown-item new-default-black-font" href="#"
                   @click.prevent="$refs.ModalCreateImport.handleCreateModal()">
                  <i class="fa-solid fa-upload mr-n1 pr-2"></i> Importar resultados
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="mt-4 ml-2">
        <div style="display: flex; align-items: center">
          <i :class="listType !== 'cards' && 'opacity-25'"
             @click.prevent="setViewType('cards')"
             class="pointer fa-regular fa-hard-drive fa-2x">
          </i>
          <i :class="listType !== 'table' && 'opacity-25'"
             @click.prevent="setViewType('table')"
             class="pointer fa-solid fa-table-list fa-2x ml-1">
          </i>
          <button
            style="margin: 0;width: 135px; justify-content: center; min-height: 28px; padding: 0; font-size: 11px; display: flex; align-items: center;"
            @click="handleFilterPending()"
            :class="filter.pending && 'active'"
            slot="title-container"
            class="ml-2 btn text-uppercase btn-outline-warning colorize-btn-img"
            type="button">
            <img
              v-bind:src="filter.pending ? '/img/icons/icons8/ios/hourglass.png' : '/img/icons/icons8/ios/hourglass_warning.png'"
              width="16px" class="mr-1"/>
            Pendente
          </button>
        </div>
      </div>
      <div>
        <multi-filter
          :showSlotBookmark="false"
          @clearFilter="clearFilter()"
          @handleFilterDate="handleFilterDate"
          @fetch="init"
        >
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <div class="card" style="margin: -14px; border-radius: 4px !important;">
                <div class="pointer px-3 pb-2 pt-3 new-default-black-font font-weight-500"
                     style="font-size: 11px;">
                  <i class="fa-solid fa-angles-down" style="font-size: 12px;margin-right: 5px"></i>
                  Do mais novo para o mais velho
                </div>
                <hr class="gray-divider p-0">
                <div class="pointer px-3 py-2 new-default-black-font font-weight-500"
                     style="font-size: 11px;">
                  <i class="fa-solid fa-angles-up" style="font-size: 12px;margin-right: 5px"></i>
                  Do mais velho para o mais novo
                </div>
              </div>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
          <template slot="dates">
            <div class="col-md-12 text-left">
              <label class="form-control-label">
                Data da concretagem
              </label>
              <input-date-picker @handleFilterDate="handleFilterConcretingDate" visibility="focus"/>
            </div>
          </template>
          <template slot="data">
            <div class="row pr-3 mt-3 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Idade do ensaio
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input @input="updated" v-model="filter.test_age.min" placeholder="de"
                           class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                    <input @input="updated" v-model="filter.test_age.max" placeholder="até"
                           class="form-control btn-first-child"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pr-3 mt-3 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Número da O.S
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input @input="updated" v-model="filter.os_number.min" placeholder="de"
                           class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                    <input @input="updated" v-model="filter.os_number.max" placeholder="até"
                           class="form-control btn-first-child"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pr-3 mt-3 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Número da NF-E
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input @input="updated" v-model="filter.nfe_number.min" placeholder="de"
                           class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                    <input @input="updated" v-model="filter.nfe_number.max" placeholder="até"
                           class="form-control btn-first-child"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pr-3 mt-3 pl-3">
              <div class="col-12 text-left">
                <label class="form-control-label">
                  Número do código
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <input @input="updated" v-model="filter.code_number.min" placeholder="de"
                           class="form-control btn-first-child"/>
                  </div>
                  <div class="col-6 pl-1">
                    <input @input="updated" v-model="filter.code_number.min" placeholder="até"
                           class="form-control btn-first-child"/>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template slot="status">
            <div class="col-12 mt-1">
              <base-button-hoverable :active="filter.molded"
                                     @click="handleFilterMolded()"
                                     size="sm" type="primary" icon="ok--v1" platform="ios">
                Ensaiado
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1">
              <base-button-hoverable :active="filter.pending"
                                     @click="handleFilterPending()"
                                     size="sm" type="warning" icon="hourglass" platform="ios">
                Pendente
              </base-button-hoverable>
            </div>
            <div class="col-12 mt-1 mb-6">
              <base-button-hoverable :active="filter.unmolded"
                                     @click="handleFilterUnmolded()"
                                     size="sm" type="danger" icon="delete-forever--v1" platform="ios">
                Descartado
              </base-button-hoverable>
            </div>
          </template>
        </multi-filter>
      </div>
      <!-- Cards aqui -->
      <Cards v-if="listType === 'cards'"
             :hardened_states="hardened_states"
             :loadingSkeleton="loadingSkeleton"
             :technicalPermissions="technicalPermissions"
             @handleEdit="handleEdit"
      />
      <!-- Table aqui -->
      <Table v-if="listType === 'table'"
             :hardened_states="hardened_states"
             :loadingSkeleton="loadingSkeleton"
             :technicalPermissions="technicalPermissions"
             @handleEdit="handleEdit"
             @updateItem="updateItem"
      />
      <div
        class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="(index) in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <modal-edit :refreshData="refreshData" ref="modalEdit"/>
      <modal-report :show.sync="showModalReport" @close="showModalReport = false"/>
      <modal-search-contract @setContractProposal="showModalReport = true" ref="modalSearchContract"/>
      <ModalTestReport ref="modalTestReport"/>
      <PuzlEmptyData v-if="!hardened_states.length && !loadingSkeleton"></PuzlEmptyData>
      <ModalDefaultMolding ref="ModalDefaultMolding"/>
      <ModalCreateEquipment ref="ModalCreateEquipment"/>
      <ModalCreateImport ref="ModalCreateImport"/>
    </div>
  </div>
</template>

<script>
import ModalSearchContract from "../../Financial/Billing/Shared/_ModalSearchContract";
import {mapGetters} from 'vuex'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalEdit from "./Report/Shared/HardenedState/Shared/ModalEdit";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "../../../../components/PuzlEmptyData";
import ModalReport from './Shared/_ModalReport'
import ModalTestReport from "./_ModalTestReport";
import InputDatePicker from "@/components/InputDatePicker";
import LoadingPagination from "@/components/LoadingPagination";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import {setPreferredViewMode, getPreferredViewMode} from '@/plugins/session';
import {
  debounce,
} from "@/plugins";
import ModalCreateEquipment from './Shared/_ModalCreateEquipment.vue'
import ModalDefaultMolding from './Shared/_ModalDefaultMolding.vue'
import ModalCreateImport from './Shared/_ModalCreateImport.vue'

const default_filter = {
  concreting_date: null,
  test_age: {
    min: null,
    max: null,
  },
  os_number: {
    min: null,
    max: null,
  },
  nfe_number: {
    min: null,
    max: null,
  },
  code_number: {
    min: null,
    max: null,
  },
  pending: null,
  molded: null,
  unmolded: null,
}
export default {
  name: "IndexUser",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    MultiFilter,
    InputDatePicker,
    SkeletonPuzlGrid,
    ModalEdit,
    ModalReport,
    ModalSearchContract,
    ModalTestReport,
    BaseButtonHoverable,
    Cards,
    Table,
    ModalDefaultMolding,
    ModalCreateEquipment,
    ModalCreateImport,
  },
  computed: {
    ...mapGetters({
      'report': 'testReport/show',
      'hardened_states': 'travelConfiguration/showHardenedStates',
      $_user: 'auth/getUser',
    }),
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      filter: {...default_filter},
      technicalPermissions: {},
      showModalReport: false,
      listType: 'cards',
      disruptions: [
        {
          id: 'A',
          text: 'Cônica e cônica afastada 25mm do capeamento'
        },
        {
          id: 'B',
          text: 'Cônica e bipartida e cônica e com mais de uma partição'
        },
        {
          id: 'C',
          text: 'Colunar com formação de cones'
        },
        {
          id: 'D',
          text: 'Cônica e cisalhada'
        },
        {
          id: 'E',
          text: 'Cisalhada'
        },
        {
          id: 'F',
          text: 'Fraturas no topo e/ou na base abaixo do capeamento'
        },
        {
          id: 'G',
          text: 'Similar ao tipo f com fraturas próximas ao topo'
        },
      ],
      healths: [
        'Total',
        'Parcial',
        'Nenhuma'
      ],
      format_mixe: period_types,
      added: false,
      loadingSkeleton: false,
    }
  },
  methods: {
    updateItem(newItem) {
      let params = {...newItem}
      if (params.date_at) {
        params.date_at = this.$helper.parseDate(params.date_at, 'DD-MM-YYYY H:mm:ss')
      }
      params.provided_at = this.$helper.parseDate(params.provided_at, 'DD-MM-YYYY H:mm:ss')
      params.old_period = params.schedule_period
      params.old_result_expected = params.result_expected
      params.period = params.schedule_period
      params.period_type = params.schedule_period_type

      this.$store.dispatch('travelConfiguration/updateHardenedState', params)
        .then(response => {
            this.$notify({type: 'success', message: response.message});
            this.refreshData();
          }
        ).catch((error) => {
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
      });
    },
    handleShowModalDefaultMolding() {
      this.$refs.ModalDefaultMolding.handleCreateModal()
    },
    handleShowNewEquipment() {
      this.$refs.ModalCreateEquipment.handleCreateModal()
    },
    setViewType(type) {
      this.listType = type;
      setPreferredViewMode(type);
    },
    updated: debounce(function () {
      this.init({})
    }, 200),
    /**
     * Responsável por esconder cards que já tiveram resultado real atualizado
     */
    refreshData() {
      if (this.filter.pending) this.init(this.filter);
    },
    fetchTechnicalPermissions(userId) {
      this.$store.dispatch('technicalPermission/getByUserId', userId).then((response) => {
        this.technicalPermissions = response.data
      });
    },
    clearFilter() {
      this.filter = {...default_filter}
      this.init({})
    },
    classResult(item) {
      if (!item.real_period || !item.result) {
        return
      }
      /** idade real **/
      let real_period = item.real_period.replace(/[a-zA-Z]{1,3}/, '')
      real_period = real_period.slice(0, 2)
      real_period = real_period.split(' ')
      real_period = Number(real_period[0])
      /** resultado **/
      const result = Number(item.result)
      /** item da responsabilidade **/
      let item_period = item.item_description.replace(/[a-zA-Z]{1,3}/, '')
      item_period = Number(item_period.trim())
      /** ${item.period} idade da responsabilidade **/
      if (real_period < item.period) {
        // item da responsabilidade
        if (result > item_period) {
          return 'text-primary'
        }
        return null
      }
      if (real_period >= item.period) {
        if (result >= item_period && real_period >= item.period) {
          return 'text-success'
        }
        if (result < item_period && real_period >= item.period) {
          return 'text-danger'
        }
      }
    },
    handleFilterDate(filter) {
      this.filter.concreting_date = 0
      this.range = filter
      this.init({})
    },
    handleFilterConcretingDate(filter) {
      this.filter.concreting_date = 1
      this.range = filter
      this.init({})
    },
    handleFilterMolded() {
      this.filter.molded = this.filter.molded ? null : 1
      if (this.filter.molded) {
        this.filter.pending = null
      }
      this.init({})
    },
    handleFilterPending() {
      this.filter.pending = this.filter.pending ? null : 1
      if (this.filter.pending) {
        this.filter.molded = null
      }
      this.init({})
    },
    handleFilterUnmolded() {
      this.filter.unmolded = Number(!this.filter.unmolded)
      this.init({})
    },
    formatPeriod(period) {
      const date_splited = period.split(' ')
      for (let date of date_splited) {
        if (date.includes('d')) {
          date = date.replace('d', '')
          return Math.round(date) > 1 ? `${date} dias` : `${date} dia`
        }
        if (date.includes('h')) {
          date = date.replace('h', '')
          return date > 1 ? `${date} horas` : `${date} hora`
        }
        if (date.includes('m')) {
          date = date.replace('min', '')
          date = date.replace('m', '')
          date = Math.round(date) / 60
          if (date < 1) {
            date = Math.round(date * 60)
            return `${date} minutos`
          }
          return date > 1 ? `${date} horas` : `${date} hora`
        }
      }
    },
    handleEdit(item) {
      this.$refs.modalEdit.openModal(item, item.schedule.contract_proposal_formulation, false, this.technicalPermissions)
    },
    init(filter = null) {
      this.startCursor(filter)
      this.$Progress.start()
      this.$store.dispatch('travelConfiguration/fetchItemsPaginate',
        {
          schedule_travel_uuid: this.$route.params.travel_uuid,
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.modal = true
          this.$Progress.finish()
          this.resolveCursor(response)
        })
        .catch(error => {
          this.$Progress.finish()
          this.resolveCursor()
        })
    },
    showModalTestReport() {
      this.$refs.modalTestReport.openModal();
    }
  },
  mounted() {
    this.listType = getPreferredViewMode() ?? 'cards';
    this.init({});
    this.fetchTechnicalPermissions(this.$_user.id);
  }
};
</script>

<style scoped>
.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
