<template>
  <div>
    <modal :show.sync="modal.create" size="lg" @close="close">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ modal.title }}</h5>
      </template>
      <div class="row pl-2">
        <div class="col-md-6">
          <label class="form-control-label">Data</label>
        </div>
        <div class="col-md-6">
          <input-date-picker
            v-model="filter.range"
            :defaultRange="defaultRange"
            size="small"
          />
        </div>
        <div class="col-md-6 pt-2"><label class="form-control-label">Central</label></div>
        <div class="col-md-6 pt-2">
          <base-input input-classes="form-control">
            <puzl-select v-model="filter.company_plant_id" :disabled="loading" :items="$_company_plants" />
          </base-input>
        </div>
        <div class="col-md-6 pt-2"><label class="form-control-label">Vendedor</label></div>
        <div class="col-md-6 pt-2">
          <puzl-select
            v-model="filter.seller_ids"
            :disabled="loading"
            :items="$_sellers"
            :loading="loadingSeller"
            multiple
            placeholder="Vendedor"
          >
          </puzl-select>
        </div>
        <div class="col-md-6 pt-2">
          <label class="form-control-label">Cliente </label>
          <a
            class="ml-2 avatar avatar-sm-2 bg-gray-content"
            href="#"
            style="border: 1px solid black"
            @click.prevent="$refs.modalSearchEntity.openModal()"
          >
            <i class="fa-solid fa-magnifying-glass text-primary"></i>
          </a>
        </div>
        <div class="col-md-6 pt-2">
          <base-input v-show="entity" :value="entity && entity.entity_name" disabled size="sm">
            <div slot="append">
              <small class="input-group-sm p-0 m-0">
                <a href="#" @click.prevent="removeEntity()">
                  <i class="text-danger fa-regular fa-circle-xmark" style="font-size: 18px; padding-top: 25%"></i>
                </a>
              </small>
            </div>
          </base-input>
          <base-input v-show="!entity" disabled placeholder="CLIENTE" size="sm" />
        </div>
        <div class="col-md-6 pt-2"><label class="form-control-label">Contrato</label></div>
        <div class="col-md-6 pt-2">
          <base-input input-classes="form-control">
            <puzl-select
              v-model="filter.contract_proposal_ids"
              :disabled="loading || !entity"
              :items="contract_proposals"
              :labelMask="true"
              :loading="loadingContractProposal"
              :multiple="true"
              label="$code$ | $construction_name$ | $customer_name$"
              placeholder="Contratos"
            />
          </base-input>
        </div>
      </div>
      <div class="col-md-12 mt-2 pr-0 text-right">
        <base-button class="text-capitalize" type="secondary" @click.prevent="close"> Cancelar </base-button>
        <base-button :loading="loading" native-type="submit" type="primary" @click.prevent="submitAndClose">
          <span v-if="!loading" class="btn-label"><i class="fa-solid fa-cloud-arrow-down"></i></span>
          Exportar
        </base-button>
      </div>
      <ModalSearchEntity ref="modalSearchEntity" @setEntity="setEntity" />
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import PuzlSelect from "@/components/PuzlSelect";
import InputDatePicker from "@/components/InputDatePicker";
import ModalSearchEntity from "@/views/Modules/Financial/BillReceive/Entity/Shared/Modals/_ModalSearchEntity";

export default {
  components: {
    ModalSearchEntity,
    InputDatePicker,
    PuzlSelect,
  },
  name: "ModalScheduleReport",
  data() {
    return {
      loadingSeller: true,
      loadingContractProposal: false,
      entity: false,
      contract_proposals: [],
      modal: {
        title: "EXPORTAR RELATÓRIO DE ENTREGAS",
        create: false,
      },
      contract_proposal: null,
      loading: false,
      filter: {
        range: null,
        status: [],
        company_plant_id: null,
        contract_proposal_ids: [],
        entity_id: null,
        seller_ids: [],
      },
      defaultRange: {
        start: null,
        end: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_sellers: "user/getAllActiveSellersAllocatedByUserId",
      $_user: 'auth/getUser',
    }),
  },
  mounted() {
    this.$store.dispatch("user/getAllActiveSellersAllocatedByUserId", this.$_user.id).then(() => {
      this.loadingSeller = false;
    });
  },
  methods: {
    /**
     * Insere o cliente selecionado
     * @param {object} data
     */
    setEntity(data) {
      this.$notify({
        type: "success",
        message: "Cliente inserido com sucesso",
      });
      this.filter.entity_id = data.entity.id;
      this.entity = data.entity;
      this.getContractProposals();
    },
    /**
     * remove o cliente selecionado do filtro
     */
    removeEntity() {
      this.$notify({
        type: "success",
        message: "Filtro removido com sucesso",
      });
      this.entity = null;
    },
    close() {
      this.modal.create = false;
    },
    openModal() {
      this.entity = null;
      this.contract_proposals = [];
      this.defaultRange = {
        start: this.$helper.parseDate(moment().startOf("month"), "YYYY-MM-DD 00:00:00"),
        end: this.$helper.parseDate(moment().endOf("month"), "YYYY-MM-DD 00:00:00"),
      };
      this.filter = {
        range: {
          start: this.defaultRange.start,
          end: this.defaultRange.end,
        },
        contract_proposal_ids: [],
        seller_ids: [],
        company_plant_id: null,
        entity_id: null,
      };
      this.modal.create = true;
    },
    /**
     * Busca as propostas por cliente, apenas contratos que possuem os mesmos vendedores alocados ao usuário.
     */
    getContractProposals() {
      this.loadingContractProposal = true;
      this.$store.dispatch("contractProposal/getAllContractsForActiveSellersAllocatedByEntity", this.entity.id)
        .then((response) => {
          this.contract_proposals = response.data;
          this.loadingContractProposal = false;
      });
    },
    setContractProposal(params) {
      this.contract_proposal = params.contract_proposal;
    },
    submitAndClose() {
      this.loading = true;
      let loader = this.$loading.show();
      if(
        moment.duration(moment(this.filter.range.end).diff(moment(this.filter.range.start))).asDays() >= 31 &&
        !this.filter.entity_id
      ) {
        this.loading = false;
        loader.hide();
        return this.$notify({
          type: "danger",
          message: "Relatório fora do limite permitido, para consultas acima de 31 dias selecione um cliente!",
        });
      }
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store.dispatch("schedulesReport/download", { filter: this.filter })
        .then((response) => {
          if (response instanceof Blob) {
            let blob = new Blob([response], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "report.xlsx");
            window.open(link, "_blank");
            this.loading = false;
            this.$notify({
              type: "success",
              message: "Solicitação realizada com sucesso!",
            });
            this.close();
          }
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.error,
          });
        }).finally(() => {
          this.loading = false;
          loader.hide();
        });
    },
  },
};
</script>

<style></style>
