<template>
  <div style="background-color: #ffff;">
    <div class="d-block d-md-flex mt-4">
      <div class="w-100 mr-2">
        <!-- Card identificação -->
        <div class="document-card mb-3">
          <div class="d-flex align-items-center mb-2">
            <img src="/img/icons/icons8/ios/info-squared_warning.png" width="24" class="mr-2">
            <span style="font-size: 14px; font-weight: 600;">Identificação</span>
          </div>
          <div class="dropdown-divider p-0 m-0"></div>
          <div class="d-md-flex justify-content-between align-items-center mt-3">
            <span>
              Prazo de compra
              <span class="text-danger">&nbsp;*</span>
            </span>
            <div class="deadline-input-size w-50 ">
              <base-input input-group-classes="mt-1 mt-sm-0 input-info w-100">
                <InputDateTimePicker :disableMaxDate="false" :disableDate="new Date()"
                  v-model="purchaseRequest.deadline" class="input-info w-100" :mode="'date'" />
              </base-input>
            </div>
          </div>
          <div class="d-md-flex justify-content-between align-items-center mt-2">
            <span class="mr-2">
              Solicitante
              <span class="text-danger">&nbsp;*</span>
            </span>
            <PuzlSelect v-model="purchaseRequest.requester_id" :items="$_users" :disabled="$_users.length === 0"
              class="input-info" placeholder="Selecione" />
          </div>
          <!-- <div class="d-md-flex justify-content-between align-items-center mt-2">
            <span class="mr-2">Categoria</span>
            <PuzlSelect
              v-model="purchaseRequest.product_service_category_id"
              class="input-info"
              :disabled="false"
              placeholder="Selecione"
            />
          </div> -->
          <div class="d-md-flex justify-content-between align-items-center mt-2">
            <span class="mr-2">
              Categoria da requisição
              <span class="text-danger">&nbsp;*</span>
            </span>
            <PuzlSelect label="description"
              :items="purchase_categories"
              v-model="purchaseRequest.purchase_category_id"
              class="input-info"
              placeholder="Selecione" />
          </div>
          <div class="d-md-flex justify-content-between align-items-center mt-2">
            <span class="mr-2">
              Tipo de itens
              <span class="text-danger">&nbsp;*</span>
            </span>
            <PuzlSelect :items="[{ id: 2, name: 'MCC' }, { id: 3, name: 'Produtos e Serviços' }]"
              v-model="purchaseRequest.product_service_hub_type_id" class="input-info" :disabled="false"
              placeholder="Selecione" />
          </div>
        </div>
        <!-- Card Central -->
        <div class="document-card">
          <div class="d-flex align-items-center mb-2">
            <img src="/img/icons/plant.png" width="24" class="mr-2">
            <span style="font-size: 14px; font-weight: 600;">Central</span>
          </div>
          <div class="dropdown-divider p-0 m-0"></div>
          <div class="d-md-flex justify-content-between align-items-center mt-3">
            <span class="mr-2">
              Central de entrada
              <span class="text-danger">&nbsp;*</span>
            </span>
            <PuzlSelect v-model="purchaseRequest.input_company_plant_id" @change="setCostCenter" class="input-info"
              :items="$_company_plants" :clearable="false" :disabled="$_company_plants.length === 0" />
          </div>
          <div class="d-md-flex justify-content-between align-items-center mt-2">
            <span class="mr-2">
              Central de faturamento
              <span class="text-danger">&nbsp;*</span>
            </span>
            <PuzlSelect v-model="purchaseRequest.cost_center_id" @change="setCompanyPlantBuyers" class="input-info"
              :items="$_company_plants" :disabled="$_company_plants.length === 0" placeholder="Selecione" />
          </div>
          <div class="d-md-flex justify-content-between align-items-center mt-2">
            <span class="mr-2">
              Cnpj comprador
              <span class="text-danger">&nbsp;*</span>
            </span>
            <PuzlSelect v-model="purchaseRequest.company_plant_buyer_id" :items="formattedCompanyPlantBuyers()"
              :disabled="company_plant_buyers.length < 2" class="input-info" placeholder="Selecione" />
          </div>
        </div>
      </div>
      <div class="document-card w-100 ml-0 ml-md-2 mt-3 mt-md-0">
        <div class="d-flex align-items-center mb-2">
          <img src="/img/icons/info-ballon-primary.png" width="24" class="mr-2">
          <span style="font-size: 14px; font-weight: 600;">Observações</span>
        </div>
        <div class="dropdown-divider p-0 m-0"></div>
        <div class="row mt-4">
          <div class="col-12">
            <h4 class="new-default-black-font">
              Observações para r.c
            </h4>
          </div>
          <div class="col-12">
            <base-input input-group-classes="input-group-sm">
              <textarea v-model="purchaseRequest.note" type="text" rows="4" class="form-control form-control-sm"
                placeholder=" ...">
              </textarea>
            </base-input>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h4 class="new-default-black-font">
              Justificar necessidade da compras
            </h4>
          </div>
          <div class="col-12">
            <base-input input-group-classes="input-group-sm">
              <textarea v-model="purchaseRequest.purchase_justification" type="text" rows="4"
                class="form-control form-control-sm" placeholder=" ...">
              </textarea>
            </base-input>
          </div>
          <!-- UPLOAD ARQUIVOS -->
          <div class="col-md-12 mt-4">
            <h4 class="new-default-black-font">
              Anexo e imagens
            </h4>
            <base-input input-group-classes="input-group-sm">
              <InputFile @input="getFiles" :maxFiles="5" ref="files" />
            </base-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import PuzlSelect from "@/components/PuzlSelect";
import InputFile from "@/components/Utils/InputFile";

export default {
  name: "PurchaseRequestCreateDocument",
  components: {
    InputDateTimePicker,
    PuzlSelect,
    InputFile,
  },
  props: {
    requestDocument: { type: Object },
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_product_service_hub_types: "productServiceHubType/fetch",
      $_user: "auth/getUser",
      $_users: "user/getAllActiveUsersSimplified",
    }),
    product_service_hub_types() {
      return this.$_product_service_hub_types.filter((item) => !["Não informado", "Serviço"].includes(this.entry_launch_types[item.id]));
    },
  },
  watch: {
    purchaseRequest: {
      handler(newVal) {
        this.$emit('purchaseRequestDocument', newVal);
      },
      deep: true
    },
    // Update
    requestDocument(newVal) {
      this.purchaseRequest = newVal;
      this.purchaseRequest.updated_by_user_id = this.$_user.id;
      this.purchaseRequest.code = newVal.code;
      this.purchaseRequest.attachments = [];
      this.setCompanyPlantBuyers();
    },
  },
  data() {
    return {
      purchaseRequest: {
        deadline: null,
        requester_id: null,
        product_service_category_id: null,
        cost_center_id: null,
        input_company_plant_id: null,
        cost_center_id: null,
        company_plant_buyer_id: null,
        note: '',
        purchase_justification: '',
        purchase_category_id: null,
        product_service_hub_type_id: null,
        updated_by_user_id: null,
        attachments: [],
      },
      company_plant_buyers: [],
      purchase_categories: [],
    };
  },
  methods: {
    // Centrais e Cnpj comprador
    async setCompanyPlantBuyers() {
      await this.$store.dispatch('companyPlantBuyer/getByCompanyPlant', this.purchaseRequest.cost_center_id).then((response) => {
        this.company_plant_buyers = response.data || [];
      });
    },
    formattedCompanyPlantBuyers() {
      let buyers = this.company_plant_buyers.map(buyer => ({
        id: buyer.issuer.id,
        name: buyer.issuer.ein,
      }));
      this.setDefaultCompanyPlantBuyers(buyers);
      return buyers;
    },
    setDefaultCompanyPlantBuyers(buyers) {
      if (buyers.length > 0) this.purchaseRequest.company_plant_buyer_id = buyers[0].id;
      else this.purchaseRequest.company_plant_buyer_id = null;
    },
    // Anexos
    getFiles() {
      if (this.$refs["files"].fileRecords.length) {
        this.$refs["files"].fileRecords.forEach((item, index) => {
          this.purchaseRequest.attachments.push({ file: item.file, index: index });
        });
      }
    },
    setCostCenter() {
      this.purchaseRequest.cost_center_id = this.purchaseRequest.input_company_plant_id;
      this.setCompanyPlantBuyers();
    },
    listCategories() {
      console.log('listCategories')
      this.$store.dispatch('purchaseCategory/fetchItemsPaginate', {
        order_by: [{ column: 'purchase_categories.id' }]})
        .then((response) => {
          this.purchase_categories = response.data.items.filter(item => item.status === true);
        });
    },
  },
  mounted() {
    this.listCategories();
    this.$store.dispatch("productServiceHubType/fetch");
    this.$store.dispatch("user/getAllActiveUsersSimplified").then(() => {
      this.purchaseRequest.requester_id = this.$_user.id;
      this.purchaseRequest.updated_by_user_id = this.$_user.id;
    });
  },
};
</script>
<style scoped>
/* Botao switch */
.switch-button {
  height: 2.5rem;
  border: 0.5px solid #DCDFE6;
  box-shadow: 0px 4px 2px 0px #00000040;
  border-radius: 20px;
  align-items: center;
  width: 24rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 8px !important;
}

.switch-button>div {
  font-size: 12px;
  height: 100%;
  align-items: center;
}

.switch-button>div:first-of-type {
  align-items: center;
  border-radius: 20px 0 0 20px;
}

.switch-button>div:nth-child(2) {
  border-radius: 0 20px 20px 0;
}

.selected-switch-button {
  background-color: #1a70b7;
  color: #ffffff;
}

.document-card .input-info {
  width: 50%
}

@media (max-width: 768px) {
  .document-card .input-info {
    width: 100%
  }

  .deadline-input-size {
    width: 100% !important;
  }
}

/* Documento */
.document-card {
  border: 1px solid #DCDFE6;
  /* height: 256px; */
  top: 218px;
  left: 222px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 1rem;
}

span {
  font-size: 13px;
}
</style>
