import { BaseListFilterType, initBaseListFilterType, RangeValueType, RangeDateType } from "../../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} PurchaseBudgetListFilterExtType
 * @property {number|null} requester_id - ID da requisição
 * @property {RangeValueType|null} amount_items - Quantidade de itens da cotação
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {RangeDateType|null} deadline_range - Intervalo entre datas de prazo de compra
 * @property {0|1|2|3|null} status - PENDING = 0, APPROVED = 1, DENIED = 2, CANCELED = 3
 * @property {number|null} input_company_plant_id - ID da central
 * @property {Array<0|1>} in_status - [0,1] Pendente, Concluído
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & PurchaseBudgetListFilterExtType)} PurchaseBudgetListFilterType
 */
export const PurchaseBudgetListFilterType = {};

/**
 * Inicializar PurchaseBudgetListFilterType
 *
 * @returns {PurchaseBudgetListFilterType}
 */
export const initPurchaseBudgetListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: [{ column: "purchase_budgets.id" }],
  custom_search: {
    columns: [
      "purchase_requests.code",
    ],
    values: [],
  },
  requester_id: null,
  amount_items: {
    min: null,
    max: null,
  },
  range: {
    start: null,
    end: null,
  },
  deadline_range: {
    start: null,
    end: null,
  },
  in_status: [],
  status: null,
  input_company_plant_id: null,
});


