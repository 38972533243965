<template>
  <div>
    <base-header class="bg-gray-content mb-n4">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-success">
            <img height="30px" src="/img/icons/client-company.png" class="mr-2"/>
            CARTEIRA DE CLIENTES
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end">
            <div class="col-md-2">
              <BackButton size="sm"/>
            </div>
          </div>
        </div>
        <div class="col-12 mt-n2">
          <hr>
        </div>
      </div>
    </base-header>
    <List/>
  </div>
</template>
<script>

import BackButton from "@/components/Utils/BackButtonV2.vue";
import List from './Shared/_List.vue'

export default {
  components: {
    BackButton,
    List
  }
}
</script>
<style>

</style>
