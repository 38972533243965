<template>
  <div>
    <div class="container-fluid">
      <div v-if="!loadingTotalUsers" style="margin-bottom: 25px;">
        <i
          class="pointer fa-regular fa-hard-drive fa-2x"
          :class="!listCard && 'opacity-25'"
          @click="listCard = true, listTable = false"
        ></i>
        <i
          class="pointer fa-solid fa-table-list fa-2x ml-1 mr-2"
          :class="!listTable && 'opacity-25'"
          @click="listTable = true, listCard = false"
        ></i>
        <button 
          type="button"
          class="btn btn-half-users"
          :class="{'btn-half-users': !filter.is_user, 'btn-half-users-clicked': filter.is_user}"
          :title="title"
          @mouseover="adjustButtonTitleUsers"
          @click="getUsers()"
        >
          <img
            :src="filter.is_user ? '/img/icons/groups.png' : '/img/icons/groups-black.png'"
            width="19px" height="19px" class="invert-on-hover float-left mr-1 mt-1"
          />
          <span class="float-left button-content mt-1" style="font-size: 12px;">Usuários</span>
            <span class="ml-2 float-right button-content">
              {{ $_totalUsers.users }}
            </span>
        </button>
        <button 
          type="button"
          class="btn btn-half-drivers"
          :class="{'btn-half-drivers': !filter.driver, 'btn-half-drivers-clicked': filter.driver}"
          :title="title"
          @mouseover="adjustButtonTitleDrivers"
          @click="getDrivers()"
        >
          <img 
            :src="filter.driver ? '/img/icons/icons8/ios/in-transit-white.png' : '/img/icons/icons8/ios/in-transit-black.png'"
            width="19px" height="19px" class="invert-on-hover float-left mr-1 mt-1"
          />
          <span class="float-left mt-1" style="font-size: 12px;">Motoristas</span>
          <span class="ml-2 float-right button-content">
            {{ $_totalUsers.drivers }}
          </span>
        </button>
        <button 
          type="button"
          class="btn btn-half-sellers"
          :class="{'btn-half-sellers': !filter.is_seller, 'btn-half-sellers-clicked': filter.is_seller}"
          :title="title"
          @mouseover="adjustButtonTitleSellers"
          @click="getIsSeller()"
        >
          <img 
            :src="filter.is_seller ? '/img/icons/salesman-white.png' : '/img/icons/salesman-dark.png'"
            width="19px" height="19px" class="invert-on-hover float-left mr-1 mt-1"
          />
          <span class="float-left button-content mt-1" style="font-size: 12px;">Vendedores</span>
          <span class="ml-2 float-right button-content">
            {{ $_totalUsers.sellers }}
          </span>
        </button>
        <button 
          type="button"
          class="btn btn-half-inactives"
          :class="{
            'btn-half-inactives': filter.status !== status.inactive.value,
            'btn-half-inactives-clicked': filter.status === status.inactive.value
          }"
          :title="title"
          @mouseover="adjustButtonTitleInactives"
          @click="getIsInactives()"
        >
          <img 
            :src="filter.is_inactive ? '/img/icons/layoff.png' : '/img/icons/layoff-black.png'"
            width="19px" height="19px" class="invert-on-hover float-left mr-1 mt-1"
          />
          <span class="float-left mt-1" style="font-size: 12px;">Inativos</span>
          <span class="ml-2 float-right button-content">
            {{ $_totalUsers.inactives }}
          </span>
        </button>
      </div>
      <div v-else class="row align-items-center">
        <div v-for="item in 4" class="col-md-3 col-sm-6 p-1">
          <div class="mb-3 card" style="height: 80px">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -10px">
                  <SkeletonPuzl type="small"/>
                </div>
                <div class="col-2 text-right float-right" style="margin-top: -15px">
                  <SkeletonPuzl type="circle"/>
                </div>
                <div class="col-5" style="margin-top: -22px">
                  <SkeletonPuzl type="small"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MultiFilter
        :showSlotDates="false"
        :showSlotData="false"
        :showSlotBookmark="false"
        :showSlotStatus="true" 
        :type="3" 
        @fetch="init" 
      >
        <template slot="status">
          <div class="col-12">
          <BaseButtonHoverable
            :active="filter.status == status.active.value"
            @click="filter.status = filter.status === status.active.value ? null : status.active.value, init({})"
            size="sm"
            type="success"
            icon="ok--v1"
            platform="ios"
          >
            ATIVOS
          </BaseButtonHoverable>
        </div>
        <div class="col-12 mt-1 mb-5">
          <BaseButtonHoverable
            size="sm"
            :active="filter.status == status.inactive.value"
            @click="filter.status = filter.status === status.inactive.value ? null : status.inactive.value, init({})"
            type="danger"
            icon="cancel"
            platform="ios"
          >
            INATIVOS
          </BaseButtonHoverable>
        </div>
        </template>
        <template slot="order-by">
          <el-popover
            trigger="click"
            placement="bottom"
            class="p-0 float-right pr-0 ml-3"
          >
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'users.name.asc',
                  init(filter)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                a-z
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'users.name.desc',
                  init(filter)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-down mr-1"
                  style="font-size: 12px"
                />
                z-a
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'users.created_at.desc',
                  init(filter)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                Do mais novo para o mais velho
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'users.created_at.asc',
                  init(filter)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-down mr-1"
                  style="font-size: 12px"
                />
                Do mais velho para o mais novo
              </a>
            </h6>

            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'users.latest_user_log_at.desc',
                  init(filter)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                Últimos acessos
              </a>
            </h6>

            <hr class="mb-1 mt-n2" />
            <h6>
              <a 
                href="#" class="font-weight-400" style="font-size: 12px"
                @click.prevent="
                  filter.order_by = 'users.permission_change_at.desc',
                  init(filter)
                "
              >
                <i
                  class="text-primary fa-solid fa-angles-up mr-1"
                  style="font-size: 12px"
                />
                Últimas alterações em permissões
              </a>
            </h6>

            <a href="#" slot="reference">
              <h5 slot="reference" class="pt-1">
                ORDENAR <i class="fa-solid fa-chevron-down"></i>
              </h5>
            </a>
          </el-popover>
        </template>
      </MultiFilter>

      <div class="row card-wrapper" v-show="loadingSkeleton  && listCard">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>

      <PuzlEmptyData v-if="!users.length && !loadingSkeleton"></PuzlEmptyData>

      <!-- TABELA -->
      <div class="card-body" v-if="listTable">
        <div class="row mt-n4">
          <div class="col-md-12 p-0">
            <div class="card">
              <div class="card-body mt-n2">
                <div class="row">
                  <div class="col-md-12 table-responsive" style="min-height: 280px;">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr class="fixed">
                          <th class="text-center">Status</th>
                          <th class="text-center">Nome</th>
                          <th class="text-center">Ícones</th>
                          <th class="text-center">Permissão</th>
                          <th class="text-center">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in 10" v-show="loadingSkeleton && listTable">
                          <th colspan="12">
                            <SkeletonPuzl type="button"/>
                          </th>
                        </tr>
                        <tr v-for="(user, index) in users" :key="index">
                          <td width="5%" class="text-center">
                            <a href="#" @click.prevent="changeStatus(user)">
                              <badge
                                :style="
                                  user.status ?
                                    'background-color: #daf1e5; color: #149e57;' :
                                    'background-color: #fde1e1; color: #e05a50;'
                                "
                                style="padding: 6px 18px;"
                                class="mt-n1"
                              >
                                <span style="font-size: 10.3px;" v-if="user.status">Ativo</span>
                                <span style="font-size: 10.3px;" v-else>Bloqueado</span>
                              </badge>
                            </a>
                          </td>
                          <td width="50%" class="text-left text-dark" style="font-size: 12px !important;">
                            {{ user.name }}
                          </td>
                          <td width="15%" class="text-left">
                           <el-popover trigger="hover" placement="right" class="p-0 pr-0">
                              <div class="text-dark">
                                <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                                <b> Logs </b>
                                <br>
                                <b> Último acesso: </b>
                                <span v-if="user.latest_user_log_at">
                                  {{ user.latest_user_log_at | parseDate('DD MMM YYYY - HH:mm') }}
                                </span>
                                <br>
                                <b> Última alteração em permissões: </b>
                                <span v-if="user.permission_change_at">
                                  {{ user.changer_name }} -
                                  {{ user.permission_change_at | parseDate('DD MMM YYYY - HH:mm') }}
                                </span>
                              </div>
                              <base-button
                                outline slot="reference" size="sm" type="secundary"
                                class="p-0 m-0 shadow-none text-indigo"
                              >
                                <img
                                  src="/img/icons/icons8/ios/user-male-circle--v1.png"
                                  width="22"
                                  class="ml-n1"
                                  style="filter: brightness(100) invert(1);"
                                />
                              </base-button>
                            </el-popover>
                            <el-popover trigger="hover" placement="bottom" class=" mr-2 p-0 pr-0">
                              <div v-if="user.plant.length">
                                <img style="width: 21px" src="/img/icons/chemical-plant-black.png" />
                                <b class="text-dark"> Centrais </b>
                                <div v-for="(plant, index) in user.plant" :key="index">
                                  {{ plant.name }}
                                </div>
                              </div>
                              <div v-else>
                                <div v-if="user.has_access_to_all_plants">
                                  <img style="width: 21px" src="/img/icons/chemical-plant-black.png" />
                                  <b class="text-dark"> Centrais </b> <br>
                                  Todas
                                </div>
                              </div>
                              <span class="p-0 mr-2 float-left" slot="reference">
                                <a href="javascript:void(0)">
                                  <div>
                                    <img style="width: 21px" src="/img/icons/chemical-plant-black.png" />
                                  </div>
                                </a>
                              </span>
                            </el-popover>
                            <el-popover v-if="user.email" trigger="hover" placement="bottom" class=" mr-2 p-0 pr-0">
                              {{ user.email }}
                              <span class="p-0 mr-2 float-left" slot="reference">
                                <a href="javascript:void(0)">
                                  <div>
                                    <img 
                                      style="width: 21px" 
                                      src="/img/icons/icons8/ios/mail-primary.png" 
                                    />
                                  </div>
                                </a>
                              </span>
                            </el-popover>
                            <el-popover v-if="user.phone" trigger="hover" placement="bottom" class=" mr-2 p-0 pr-0">
                              {{ user.phone }}
                              <span class="p-0 mr-2 float-left" slot="reference">
                                <a href="javascript:void(0)">
                                  <div>
                                    <img 
                                      style="width: 21px" 
                                      src="/img/icons/icons8/ios/phone.png" 
                                    />
                                  </div>
                                </a>
                              </span>
                            </el-popover>
                          </td>
                          <td width="20%" class="text-left">
                            <div v-if="user.role.length !== 0">
                              <div
                                class="mt-2 text-dark"
                                style="white-space: nowrap; font-size: 12px;"
                                v-for="(item, index) in user.role" :key="index"
                              >
                                <a
                                  style="white-space: nowrap; font-size: 12px;"
                                  class="text-dark"
                                  href="javascript:void(0)"
                                  @click.prevent="handleUserPermission(
                                    user.id,
                                    user.uuid,
                                    user.role && user.role[0] ? user.role[0].name : null
                                  )"
                                >
                                  {{ item.name }}
                                </a>
                                <span class="float-right">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="handleUserPermission(
                                      user.id,
                                      user.uuid,
                                      user.role && user.role[0] ? user.role[0].name : null
                                    )"
                                  >
                                  <img src="\img\icons\icons8/ios/security-shield-green_primary.png" width="21px" height="21px" />
                                  </a>
                                </span>
                              </div>
                            </div>
                            <div v-else class="text-dark" style="white-space: nowrap; font-size: 12px;">
                              <a
                                style="white-space: nowrap; font-size: 12px;"
                                class="text-dark"
                                href="javascript:void(0)"
                                @click.prevent="handleUserPermission(
                                  user.id,
                                  user.uuid,
                                  user.role && user.role[0] ? user.role[0].name : null
                                )"
                              >
                                Sem permissões
                              </a>
                              <span class="float-right">
                                <img src="\img\icons\icons8/ios/security-shield-green_primary.png" width="21px" height="21px" />
                              </span>
                            </div>
                          </td>
                          <td width="10%" class="text-center">
                            <base-dropdown menuOnRight>
                              <div 
                                slot="title-container"
                                class="dropdown-toggle rounded m-0"
                              >
                                <img width="21px" src="/img/icons/icons8/ios/settings--v1_primary.png">
                              </div>
                              <a
                                class="dropdown-item"
                                @click="handleEditUser(user.uuid)"
                              >
                                <img width="22" src="/img/icons/edit.png">
                                Editar
                              </a>
                            </base-dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <PuzlEmptyData v-show="users && !users.length && !loadingSkeleton && listTable"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CARDS -->
      <div class="row card-wrapper" v-if="users.length && !loadingSkeleton && listCard">
        <div 
          class="col-lg-4" 
          v-if="user.internal_type === 1 ? $hasPrivilege(1) : true" 
          v-for="(user, index) in users"
          :key="index"
        >
          <div class="card">
            <div class="card-body">
              <div class="row mb-2 align-top header-card">
                <div class="col-5 align-items-start text-left pl-0" style="position: relative">
                  <div class="col-12 pl-0" style="position: absolute; top: 0">
                    <div v-if="user.role.length !== 0">
                      <div>
                        <div
                          class="mt-2"
                          style="white-space: nowrap; font-size: 12px; color: #2B2D32"
                          v-for="(item, index) in user.role" :key="index"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                    <div v-else style="white-space: nowrap; font-size: 12px; color: #2B2D32">
                      SEM PERMISSÕES
                    </div>

                    <h5 class="mb-1" style="white-space: nowrap; margin-top: 9px; font-size: 15px;" :title="user.name">
                      {{ getFirstAndLastName(user) }}
                      <a href="#" @click.prevent="changeStatus(user)">
                        <badge 
                          :style="
                            user.status ?
                              'background-color: #daf1e5; color: #149e57' :
                              'background-color: #fde1e1; color: #e05a50'
                          "
                          style="padding: 6px 18px;"
                          class="mt-n1 ml-1"
                        >
                          <span style="font-size: 10.3px;" v-if="user.status">Ativo</span>
                          <span style="font-size: 10.3px;" v-else>Inativo</span>
                        </badge>
                      </a>
                    </h5>
                    <h6 class="mb-2 small" v-if="user.email" style="white-space: nowrap; color: #606062">
                      {{ user.email }}
                    </h6>
                    <h6 class="mb-2 small" v-if="user.phone" style="white-space: nowrap; color: #606062">
                      {{ user.phone }}
                    </h6>
                  </div>
                </div>
                <div class="col-4 text-right pl-0 ml-auto" style="">
                  <base-dropdown menuOnRight>
                    <div 
                      slot="title-container"
                      class="dropdown-toggle rounded m-0"
                    >
                      <img width="32px" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <a class="dropdown-item" @click.prevent="handleEditUser(user.uuid)">
                      <img width="22" src="/img/icons/edit.png">
                      Editar
                    </a>
                  </base-dropdown>
                </div>
              </div>

              <div class="row">
                <!-- Centrais -->
                <div class="offset-9 col-3 mt-n3 mb-2">
                  <el-popover trigger="hover" placement="right" class="p-0 pr-0">
                    <div class="text-dark">
                      <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                      <b> Logs </b>
                      <br>
                      <b> Último acesso: </b>
                      <span v-if="user.latest_user_log_at">
                        {{ user.latest_user_log_at | parseDate('DD MMM YYYY - HH:mm') }}
                      </span>
                      <br>
                      <b> Última alteração em permissões: </b>
                      <span v-if="user.permission_change_at">
                        {{ user.changer_name }} -
                        {{ user.permission_change_at | parseDate('DD MMM YYYY - HH:mm') }}
                      </span>
                    </div>
                    <base-button
                      outline slot="reference" size="sm" type="secundary"
                      class="p-0 m-0 shadow-none text-indigo"
                    >
                      <img
                        src="/img/icons/icons8/ios/user-male-circle--v1.png"
                        width="22"
                        class="ml-1"
                        style="filter: brightness(100) invert(1);"
                      />
                    </base-button>
                  </el-popover>
                  <el-popover trigger="hover" placement="right" class="p-0 pr-0 ml-1">
                    <div v-if="user.plant.length">
                      <img style="width: 21px" src="/img/icons/chemical-plant-black.png" />
                      <b class="text-dark"> Centrais </b>
                      <div v-for="(plant, index) in user.plant" :key="index">
                        {{ plant.name }}
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="user.has_access_to_all_plants">
                        <img style="width: 21px" src="/img/icons/chemical-plant-black.png" />
                        <b class="text-dark"> Centrais </b> <br>
                        Todas
                      </div>
                    </div>
                    <base-button
                      outline slot="reference" size="sm" type="secundary"
                      class="p-0 m-0 shadow-none text-indigo"
                    >
                      <img src="/img/icons/chemical-plant-black.png" width="27px" height="27px" />
                    </base-button>
                  </el-popover>
                </div>
              </div>

              <div class="row align-items-center" style="margin-bottom: -23px; margin-right: -24px;">
                <div class="offset-6 col-md-6 text-center" style="text-align: right !important; padding: 0px">
                  <div class="card-blue-bottom">
                    <a 
                      style="height: 37px; display: block;"
                      @click.prevent="handleUserPermission(
                        user.id,
                        user.uuid,
                        user.role && user.role[0] ? user.role[0].name : null
                      )"
                      href="#"
                    >
                      <h3 class="mt-n1" style="text-transform: none; padding: 8px; padding-right: 15px;">
                        <span class="mr-1">
                          <img src="\img\icons\security-shield.png" alt="Logo" width="21px" height="21px" />
                        </span>
                        <small class="mr-1 text-white" style="font-size: 11.5px;">
                          PERMISSÃO
                        </small>
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            </collapse>
          </div>
        </div>
      </div>
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton" />
    <ModalUserPermission @storedAfterCenterAllocation="storedAfterCenterAllocation" ref="modalUserPermission" />
    <ModalEditUser ref="modalEditUser"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalUserPermission from "./_ModalUserPermission";
import ModalEditUser from './_ModalEdit';
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzl from "@/components/SkeletonPuzl";

export default {
  name: "ListUser",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    MultiFilter,
    LoadingPagination,
    ModalUserPermission,
    ModalEditUser,
    BaseButtonHoverable,
    PuzlEmptyData,
    SkeletonPuzl,
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {
        status: null,
        is_user: null,
        driver: null,
        is_seller: null,
        is_inactive: null,
      },
      status: {
        inactive: { value: 0, name: "Inativos" },
        active: { value: 1, name: "Ativos" },
      },
      loadingTotalUsers: true,
      listCard: true,
      listTable: false,
      title: '',
    };
  },
  computed: {
    ...mapGetters({
      users: "user/fetch",
      $_totalUsers: "user/showCountUsers",
      $_authenticatedUser: 'auth/getUser',
    }),
  },
  watch: {
    added() {
      this.init();
    },
  },
  props: {
    added: Boolean,
  },
  methods: {
    /**
     * @param {object} filter
     */
    init(filter = null) {
      this.startCursor(filter);
      this.$Progress.start();
      this.$store
        .dispatch("user/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.$Progress.finish();
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.$Progress.finish();
          this.resolveCursor();
        });
    },
    getUsers() {
      this.filter.is_user = this.filter.is_user === null ? 1 : null;
      this.init({});
    },
    getDrivers() {
      this.filter.driver = this.filter.driver === null ? 1 : null;
      this.init({});
    },
    getIsSeller() {
      this.filter.is_seller = this.filter.is_seller === null ? 1 : null;
      this.init({});
    },
    getIsInactives() {
      this.filter.status = this.filter.status === 0 ? null : 0;
      this.init({});
    },
    getCountUsers() {
      this.$store.dispatch("user/getCountUsers").then((response) => {
        this.loadingTotalUsers = false;
      });
    },
    /**
     * @param {number} userId
     * @param {string} userUuid
     * @param {string} groupPermissionName
     */
    handleUserPermission(userId, userUuid, groupPermissionName = null) {
      this.$refs.modalUserPermission.openModal(userId, userUuid, this.$_authenticatedUser.id, groupPermissionName);
    },
    handleCreateModal() {
      this.$refs.modalCreateUser.handleCreateModal();
    },
    /**
     * @param {string} uuid 
     */
    handleEditUser(uuid) {
      this.$refs.modalEditUser.handleCreateModal(uuid);
    },
    /**
     * Atualiza centrais no card após realizar alocação nas permissões do usuário.
     * @param {object} user
     * @param {object} permissionChangeAt
     */
    storedAfterCenterAllocation(user, permissionChange) {
      let foundUser = this.users.find((item) => item.uuid == user.user_uuid);
      if (foundUser) {
        foundUser.plant = user.plants;
        this.$store.dispatch('userRole/show', foundUser.id).then((response) => {
          foundUser.role[0].name = response.data.role[0].name;
        });

       foundUser.permission_change_at = permissionChange.permission_change_at;
       foundUser.changer_name = permissionChange.changer_name;
      } 
    },
    adjustButtonTitleUsers() {
      if (window.innerWidth <= 939) {
        this.title = 'Usuários';
      } else {
        this.title = '';
      }
    },
    adjustButtonTitleDrivers() {
      if (window.innerWidth <= 939) {
        this.title = 'Motoristas';
      } else {
        this.title = '';
      }
    },
    adjustButtonTitleSellers() {
      if (window.innerWidth <= 939) {
        this.title = 'Vendedores';
      } else {
        this.title = '';
      }
    },
    adjustButtonTitleInactives() {
      if (window.innerWidth <= 939) {
        this.title = 'Inativos';
      } else {
        this.title = '';
      }
    },
    /**
     * @param {object} user 
     */
    getFirstAndLastName(user) {
      let fullName = user.name;
      let names = fullName.split(' ');
      return names.length >= 2 ? `${names[0]} ${names[names.length - 1]}` : fullName;
    },
    /**
     * @param {object} user
     */
    changeStatus(user) {
      let newStatus = +!user.status;
      this.$store.dispatch("user/updateStatus", {id: user.id, status: newStatus}).then((response) => {
        if (response.data) {
          user.status = newStatus;
          this.$notify({
            type: "success",
            message: "Status atualizado com sucesso!"
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Erro ao atualizar status!"
          });
        }
      }).catch((error) => {
        this.$notify({
          type: "danger",
          message: "Erro ao atualizar status!"
        });
      });
    },
  },
  mounted() {
    this.filter.status = 1; // Ativos
    //this.$refs["multi-filter"]._self.$forceUpdate();
    this.init({});
    this.getCountUsers();
  },
};
</script>

<style scoped>
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
.btn:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.header-card {
  padding-bottom: 25px;
  margin-left: auto;
  margin-top: -10px;
}

/** Botão permissão */
.card-blue-bottom {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 10px;
  background-color: #1b6eba;
  color: white;
  max-width: 140px;
  min-width: 140px;
  float: right;
}

/** BOTÕES USUÁRIOS */
.btn-half-users {
  background: linear-gradient(to right, white 72%, #17a2b8 28%);
  border-color: #17a2b8;
  color: black;
  height: 45px;
  width: 190px;
}
.btn-half-drivers {
  background: linear-gradient(to right, white 72%, #fd7e14 28%);
  border-color: #fd7e14;
  color: black;
  height: 45px;
  width: 190px;
}
.btn-half-sellers {
  background: linear-gradient(to right, white 72%, #198754 28%);
  border-color: #198754;
  color: black;
  height: 45px;
  width: 190px;
}
.btn-half-inactives {
  background: linear-gradient(to right, white 72%, #dc3545 28%);
  border-color: #dc3545;
  color: black;
  height: 45px;
  width: 190px;
}
/** Quando a tela for menor que 939px */
@media (max-width: 939px) {
  /** Diminui tamanho do botão quando a tela for pequena */
  .btn-half-users {
    background: linear-gradient(to right, white 60%, #17a2b8 40%);
    width: 63px;
  }
  .btn-half-drivers {
    background: linear-gradient(to right, white 60%, #fd7e14 40%);
    width: 63px;
  }
  .btn-half-sellers {
    background: linear-gradient(to right, white 60%, #198754 40%);
    width: 63px;
  }
  .btn-half-inactives {
    background: linear-gradient(to right, white 60%, #dc3545 40%);
    width: 63px;
  }
  /** Remove conteúdo do botão quando a tela for pequena */
  .btn-half-users span.float-left {
    max-height: 0;
    overflow: hidden;
  }
  .btn-half-drivers span.float-left {
    max-height: 0;
    overflow: hidden;
  }
  .btn-half-sellers span.float-left {
    max-height: 0;
    overflow: hidden;
  }
  .btn-half-inactives span.float-left {
    max-height: 0;
    overflow: hidden;
  }
  /** Altera posicionamento do número dentro do botão quando a tela for pequena */
  .btn-half-users span.float-right {
    margin-top: -24px;
    margin-right: -15px;
  }
  .btn-half-drivers span.float-right {
    margin-top: -24px;
    margin-right: -15px;
  }
  .btn-half-sellers span.float-right {
    margin-top: -24px;
    margin-right: -15px;
  }
  .btn-half-inactives span.float-right {
    margin-top: -24px;
    margin-right: -15px;
  }
  /** Altera posicionamento da imagem quando a tela for pequena */
  .btn-half-users img {
    margin-left: -10px;
  }
  .btn-half-drivers img {
    margin-left: -10px;
  }
  .btn-half-sellers img {
    margin-left: -10px;
  }
  .btn-half-inactives img {
    margin-left: -10px;
  }
}
/** Altera cor do conteúdo do botão ao passar o mouse por cima */
.btn-half-users:hover span {
  color: white;
}
.btn-half-drivers:hover span {
  color: white;
}
.btn-half-sellers:hover span {
  color: white;
}
.btn-half-inactives:hover span {
  color: white;
}
/** Altera cor da imagem do botão ao passar o mouse */
.btn-half-users:hover {
  background: #17a2b8;
}
/** Altera cor da imagem do botão ao passar o mouse */
.btn-half-drivers:hover {
  background: #fd7e14;
}
/** Altera cor da imagem do botão ao passar o mouse */
.btn-half-sellers:hover {
  background: #198754;
}
/** Altera cor da imagem do botão ao passar o mouse */
.btn-half-inactives:hover {
  background: #dc3545;
}
/** Quando um dos botões for clicado */
.btn-half-users-clicked {
  background: #17a2b8;
  color: white;
}
.btn-half-drivers-clicked {
  background: #fd7e14;
  color: white;
}
.btn-half-sellers-clicked {
  background: #198754;
  color: white;
}
.btn-half-inactives-clicked {
  background: #dc3545;
  color: white;
}
</style>
