<template>
  <div v-if="!isFinding">
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="isOpen" size="lg" :disabled=isSaving>
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            style="display: inline-flex"
            src="/img/icons/icons8/ios/exchange-dollar.png"
            width="22"
          />
          <h5
            style="display: inline-flex"
            class="modal-title p-0 m-0 ml-3 text-white"
          >
            {{ title }}
          </h5>
        </div>
      </template>

      <StockLocationForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { StockLocationUpdateType, initStockLocationUpdateType } from "../../types";
import stockLocationStore from "../../store";
import { dialogs, progress, delay } from "../../../../../helpers";
import StockLocationForm from "../shared/StockLocationForm.vue";

const isOpen = ref(false);
const title = "Local de estoque";
/** @type {StockLocationUpdateType} */
const formData = reactive(initStockLocationUpdateType());

/** Store Getters */
const isFinding = computed(() => stockLocationStore.getIsFinding());
const isSaving = computed(() => stockLocationStore.getIsSaving());

/**
 * Abrir modal
 * @param {number} id
 */
async function openModal(id) {
  await setFormData(id);
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Localizar registro por id e setar dados no formulário */
async function setFormData(id) {
  const loader = progress.showLoader();
  try {
    Object.assign(formData, await stockLocationStore.find(id));
  } finally {
    loader.hide();
  }
}

/** Atualizar registro */
async function handleSubmitForm() {
  const loader = progress.showLoader();
  try {
    await stockLocationStore.update(formData, formData.id);
  } finally {
    loader.hide();
  }  

  dialogs.notify();
  closeModal();
}

/**
 * @typedef {Object} ModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
</script>

<style scoped></style>
