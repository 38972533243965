import store from "../../../../shared/libraries/store";
import { SearchBarFilterType } from "../../../../components/AppGlobal";
import { StockLocationListFilterType, StockLocationListPagerType, StockLocationViewType, initStockLocationListPagerType } from "../types";
import { stockLocationStateKeys } from "./stockLocationStoreStateKeys";

/** @returns {?StockLocationViewType} */
const getItem = () => store.getState(stockLocationStateKeys.ITEM);

/** @returns {StockLocationListPagerType} */
const getListed = () => store.getState(stockLocationStateKeys.LISTED) ?? initStockLocationListPagerType();

/** @returns {boolean} */
const getIsListing = () => store.getState(stockLocationStateKeys.IS_LISTING) ?? false;

/** @returns {boolean} */
const getIsFinding = () => store.getState(stockLocationStateKeys.IS_FINDING) ?? false;

/** @returns {boolean} */
const getIsSaving = () => store.getState(stockLocationStateKeys.IS_SAVING) ?? false;

/** @returns {boolean} */
const getIsRemoving = () => store.getState(stockLocationStateKeys.IS_REMOVING) ?? false;

/** 
 * @returns {{ 
 *   searchBarFilter: SearchBarFilterType, 
 *   filter: StockLocationListFilterType
 * }}
*/
const getPageState = () => store.getState(stockLocationStateKeys.PAGE_STATES);

export default {
  getItem,
  getListed,
  getIsListing,
  getIsFinding,
  getIsSaving,
  getIsRemoving,
  getPageState,
};
