<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="container-fluid">
        <div class="row mb-2" v-for="item in 2" v-show="loadingSkeleton">
          <div class="col-md-12">
            <skeleton-puzl type="text"></skeleton-puzl>
          </div>
          <div class="col-md-7">
            <skeleton-puzl type="text"></skeleton-puzl>
          </div>
        </div>
        <PuzlEmptyData :withCard="false" v-if="!contacts.length && !loadingSkeleton"></PuzlEmptyData>
        <div v-if="!loadingSkeleton">
          <div v-for="(contact, index) in contacts" :key="index">
            <div class="border-left border-3 mb-2" :class="'border-' + getBorderColor(contact)">
              <div class="row">
                <div class="col-6">
                  <div class="ml-2">
                    {{ contact.name }}
                  </div>
                  <div class="ml-2">
                    {{ contact.sector }}
                  </div>
                  <div class="ml-2">
                    {{ contact.email }}
                  </div>
                  <div class="ml-2">
                    {{ contact.cell_phone }}
                  </div>
                  <div class="ml-2">
                    {{ contact.phone }}
                  </div>
                </div>
                <div class="col-6 text-right">
                  <a href="#" @click="handleEditContact(contact.uuid)">
                    <i class="fa-lg fas fa-edit text-warning"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <base-button type="secondary" class="text-uppercase" @click="closeModal()">Fechar</base-button>
          <base-button type="success" class="text-uppercase" @click="handleCreate(construction_uuid)">
            novo
          </base-button>
        </div>
      </div>
    </modal>
    <modal-create-contact ref="createContact" @addContact="openModal"></modal-create-contact>
    <modal-edit-contact ref="editContact" @editContact="openModal"></modal-edit-contact>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ModalCreateContact from '@/views/Modules/Commercial/CustomerConstruction/Constructions/Contact/Shared/_Create';
import ModalEditContact from '@/views/Modules/Commercial/CustomerConstruction/Constructions/Contact/Shared/_Edit';
import SkeletonPuzl from "@/components/SkeletonPuzl";

export default {
  components: {
    SkeletonPuzl,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalCreateContact,
    ModalEditContact,
  },
  name: "ModalListContacts",
  data() {
    return {
      modal: false,
      title: "Contatos",
      loadingSkeleton: false,
      contacts: [],
      construction_uuid: '',
    };
  },
  watch: {
    modal: function (val) {
      if (! val) {
        this.$emit('updateConstruction')
      }
    }
  },
  methods: {
    closeModal() {
      this.modal = false;
      this.$emit('updateConstruction')
    },
    openModal(constructionUuid) {
      this.construction_uuid = constructionUuid
      this.modal = true;
      this.loadingSkeleton = true;
      this.$store.dispatch("customerConstructionConstructionsContact/getContacts", constructionUuid)
        .then(response => {
          this.contacts = response.data;
          this.loadingSkeleton = false;
        });
    },
    handleCreate(constructionUuid) {
      this.$refs.createContact.handleCreateModal(constructionUuid)
    },
    handleEditContact(uuid) {
      this.$refs.editContact.handleEditModal(uuid)
    },
    getBorderColor(contact) {
      if (contact.is_user && contact.is_administrator) {
        return 'indigo';
      }
      if (contact.is_user && !contact.is_administrator) {
        return 'primary';
      }
      if (!contact.is_administrator && !contact.is_user) {
        if (contact.status) {
          return 'success';
        }
        return 'danger';
      }
    },
  },
};
</script>

<style></style>
