<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <MultiFilter ref="multi-filter"
                       :showSlotDates="false"
                       :showSlotData="false"
                       :type="3"
                       :filter="filter"
                       @fetch="init"
          >
            <template slot="data"/>
            <template slot="order-by">
              <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
                <h6><a @click.prevent="handleFilterOrderBy('volume.desc')" href="#" class="font-weight-400"
                       style="font-size: 12px">
                  <i :class="filter.order_by === 'estimated_volume.desc' && 'text-primary'"
                     class="fa-solid fa-angles-up"
                     style="font-size: 12px;margin-right: 5px"></i> Vol original: Maior</a>
                </h6>
                <hr class="mb-1 mt-n2">
                <h6><a @click.prevent="handleFilterOrderBy('volume.asc')" href="#" class="font-weight-400"
                       style="font-size: 12px">
                  <i :class="filter.order_by === 'estimated_volume.asc' && 'text-primary'" class="fa-solid fa-angles-down"
                     style="font-size: 12px;margin-right: 5px"></i> Vol original: Menor </a>
                </h6>
                <hr class="mb-1 mt-n2">
                <h6><a @click.prevent="handleFilterOrderBy('latest_updated_at.desc')" href="#" class="font-weight-400"
                       style="font-size: 12px">
                  <i :class="filter.order_by === 'latest_updated_at.desc' && 'text-primary'"
                     class="fa-solid fa-angles-up"
                     style="font-size: 12px;margin-right: 5px"></i> Atualização: Mais antiga</a>
                </h6>
                <hr class="mb-1 mt-n2">
                <h6><a @click.prevent="handleFilterOrderBy('latest_updated_at.asc')" href="#" class="font-weight-400"
                       style="font-size: 12px">
                  <i :class="filter.order_by === 'latest_updated_at.asc' && 'text-primary'"
                     class="fa-solid fa-angles-down"
                     style="font-size: 12px;margin-right: 5px"></i> Atualização: Mais nova</a>
                </h6>
                <hr class="mb-1 mt-n2">
                <h6><a @click.prevent="handleFilterOrderBy('created_at.desc')" href="#" class="font-weight-400"
                       style="font-size: 12px">
                  <i :class="filter.order_by === 'created_at.desc' && 'text-primary'" class="fa-solid fa-angles-up"
                     style="font-size: 12px;margin-right: 5px"></i> Criação: Mais antiga</a>
                </h6>
                <hr class="mb-1 mt-n2">
                <h6><a @click.prevent="handleFilterOrderBy('created_at.asc')" href="#" class="font-weight-400"
                       style="font-size: 12px">
                  <i :class="filter.order_by === 'created_at.asc' && 'text-primary'" class="fa-solid fa-angles-down"
                     style="font-size: 12px;margin-right: 5px"></i> Criação: Mais nova</a>
                </h6>
                <a href="#" slot="reference">
                  <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
                </a>
              </el-popover>
            </template>
          </MultiFilter>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <template>
                <div v-show="items.length > 0" class="row">
                  <div class="col-md-8 pl-3">
                  </div>
                  <div class="col-md-4 px-1 pr-3">
                    <table class="table-bordered table table-sm padding-th">
                      <thead>
                      <tr style="border: none">
                        <th class="text-table-entity-wallet"
                            style="border-right: 1px solid #d3caca8c">
                          <a href="#" @click.prevent="handleGoBackMonth()">
                            <i v-if="validMonthGoBack()" class="fa-solid fa-arrow-left"
                               style="font-size: 12px;margin-left: 20px"></i>
                            <i v-if="!validMonthGoBack()" class="text-muted fa-solid fa-arrow-left"
                               style="font-size: 12px;margin-left: 20px"></i>
                          </a>
                        </th>
                        <th class="text-right text-table-entity-wallet"
                            style="border: none">
                          <a href="#" @click.prevent="handleAdvanceMonth()">
                            <i v-if="validMonthAdvanceBack()" class="fa-solid fa-arrow-right"
                               style="font-size: 12px;margin-right: 20px"></i>
                            <i v-if="!validMonthAdvanceBack()" class="text-muted fa-solid fa-arrow-right"
                               style="font-size: 12px;margin-right: 20px"></i>
                          </a>
                        </th>
                      </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <div v-show="items.length > 0" class="row " style="overflow-y: scroll;max-height: 70vh">
                  <div
                    class="col-md-8 table-responsive px-1 pl-3">
                    <table class="table table-bordered table-sm padding-th">
                      <thead>
                      <tr style="border: none">
                        <th rowspan="1" colspan="5"
                            style="border: none">
                          &nbsp;
                        </th>
                      </tr>
                      <div style="margin-top: 2px"></div>
                      <tr>
                        <th class=" text-uppercase bg-aqua text-table-entity-wallet ">
                          TOTAL
                        </th>
                        <th class=" text-uppercase bg-aqua text-table-entity-wallet">
                          {{ $_widgets.constructions_count }} CONTRATOS
                        </th>
                        <th class=" text-uppercase bg-aqua text-table-entity-wallet">
                          {{ $_widgets.sellers_count }} VENDEDORES
                        </th>
                        <th class=" text-uppercase text-center bg-aqua text-table-entity-wallet ">
                          {{ $_widgets.estimated_volume_sum || 0 }}
                        </th>
                        <th class=" text-uppercase text-center bg-aqua text-table-entity-wallet">
                          {{ $_widgets.schedule_travel_volume_sum || 0 }}
                        </th>
                      </tr>
                      <tr>
                        <th style="width: 30%;max-width: 0;" class=" text-uppercase bg-grey text-table-entity-wallet">
                          CLIENTE
                        </th>
                        <th style="width: 30%;max-width: 0" class=" text-uppercase bg-grey text-table-entity-wallet">
                          OBRA
                        </th>
                        <th style="width: 20%;max-width: 0" class=" text-uppercase bg-grey text-table-entity-wallet">
                          VENDEDOR
                        </th>
                        <th style="width: 10%;max-width: 0"
                            class=" text-uppercase text-center bg-grey text-table-entity-wallet">
                          ORIGINAL
                        </th>
                        <th style="width: 10%;max-width: 0"
                            class=" text-uppercase text-center bg-grey text-table-entity-wallet">
                          SALDO
                        </th>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div v-for="(item, key) in months"
                       class="col-md table-responsive px-1 "
                       style="margin-top: -1px !important"
                       :class="months[months.length -1].id === item.id && 'pr-3'">
                    <table class="table table-bordered table-sm padding-th">
                      <thead>
                      <tr>
                        <td class="td-truncate text-center pointer text-table-entity-wallet"
                            style="width: 10%; max-width: 0px;"><i class="fa-solid fa-calendar-day"></i>
                        </td>
                        <td class="td-truncate text-center pointer text-table-entity-wallet"
                            style="width: 90%; max-width: 0px;"> {{ item.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-truncate  text-center pointer text-table-entity-wallet bg-aqua"
                        > {{ getPredictedTotalByMonth(item.id) }}
                        </td>
                        <td class="td-truncate text-center text-table-entity-wallet bg-aqua"
                        > {{ getAccomplishedOrBalance(item.id) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="td-truncate text-center pointer text-table-entity-wallet bg-grey"
                        > PREVISTO
                        </td>
                        <td v-if="item.id == new Date().getMonth() + 1 || item.id < new Date().getMonth() + 1"
                            class="td-truncate text-center pointer text-table-entity-wallet bg-grey"
                        > REALIZADO
                        </td>
                        <td v-else class="td-truncate pointer text-center text-table-entity-wallet bg-grey"
                        > SALDO
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <!--                  <div v-for="(item, key) in months"-->
                  <!--                       class="col-md table-responsive px-1 mt-1"-->
                  <!--                       :class="months[months.length -1].id === item.id && 'pr-3'">-->
                  <!--                    <table class="table table-sm padding-th">-->
                  <!--                      <thead>-->
                  <!--                      &lt;!&ndash;                    <tr>&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                      <th colspan="2" class="text-center">&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                        <i class="fa-solid fa-calendar-days"></i> {{ item.name }} / 2024&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                      </th>&ndash;&gt;-->
                  <!--                      &lt;!&ndash;                    </tr>&ndash;&gt;-->
                  <!--                      <tr style="border: none">-->
                  <!--                        <th rowspan="1" colspan="2" class="text-center text-table-entity-wallet">-->
                  <!--                          <i class="fa-solid fa-calendar-days" style="font-size: 10px"></i> {{ item.name }} / 2024-->
                  <!--                        </th>-->
                  <!--                      </tr>-->
                  <!--                      <tr>-->
                  <!--                        <td style="width: 50%;max-width: 0"-->
                  <!--                            class="td-truncate text-center bg-aqua text-table-entity-wallet">-->
                  <!--                          {{ getPredictedTotalByMonth(item.id) }}-->
                  <!--                        </td>-->
                  <!--                        <td style="width: 50%;max-width: 0"-->
                  <!--                            class="td-truncate text-center bg-aqua text-table-entity-wallet">-->
                  <!--                          {{ getAccomplishedOrBalance(item.id) }}-->
                  <!--                        </td>-->
                  <!--                      </tr>-->
                  <!--                      <div class="mt-1 mb-0"/>-->
                  <!--                      <tr>-->
                  <!--                        <th class=" text-uppercase text-center bg-grey text-table-entity-wallet">-->
                  <!--                          PREVISTO-->
                  <!--                        </th>-->
                  <!--                        <th v-if="item.id == new Date().getMonth() + 1 || item.id < new Date().getMonth() + 1"-->
                  <!--                            class=" text-uppercase text-center bg-grey text-table-entity-wallet">-->
                  <!--                          REALIZADO-->
                  <!--                        </th>-->
                  <!--                        <th v-else class=" text-uppercase text-center bg-grey text-table-entity-wallet">-->
                  <!--                          SALDO-->
                  <!--                        </th>-->
                  <!--                      </tr>-->
                  <!--                      </thead>-->
                  <!--                    </table>-->
                  <!--                  </div>-->
                </div>
                <div v-show="items.length > 0" id="items" class="mb-2 table-responsive"
                     style="height: 52vh;overflow-x: hidden !important;">
                  <div class="row mt-1">
                    <div class="col-md-8 table-responsive px-1 pl-3">
                      <table class="table table-bordered table-sm padding-th ">
                        <thead>
                        <tr style="width: 100% !important;" v-for="(item, key) in items" :key="key">
                          <td style="width: 30%;max-width: 0;" class="td-truncate text-table-entity-wallet">
                            {{
                              item.entity_name
                            }}
                          </td>
                          <td @click.prevent="handlePortfolioProjection(
                      item.id,
                      [
                        item.contract_proposal_code,
                        item.customer_name,
                        item.construction_name,
                        item.company_plant_id
                      ]
                    )" style="width: 30%;max-width: 0;" class="td-truncate pointer text-table-entity-wallet">
                            {{
                              item.contract_proposal_code
                            }} | {{ item.construction_name }}
                          </td>
                          <td @click.prevent="setSellerFilter(item.seller_name)" style="width: 20%;max-width: 0;" class="pointer td-truncate text-table-entity-wallet">
                            {{
                              item.seller_name
                            }}
                          </td>
                          <td @click="handleEditConstruction(item.construction_uuid)"
                              style="width: 10%;max-width: 0;"
                              class="td-truncate text-center pointer bg-grey text-table-entity-wallet">
                            {{
                              item.estimated_volume || 0
                            }}
                          </td>
                          <td style="width: 10%;max-width: 0;"
                              class="td-truncate text-center bg-grey text-table-entity-wallet">
                            {{
                              item.performed_volume || 0
                            }}
                          </td>
                        </tr>
                        </thead>
                      </table>
                    </div>
                    <div v-for="(item, key) in months"
                         class="col-md table-responsive px-1"
                         :class="months[months.length -1].id === item.id && 'pr-3'">
                      <table class="table table-bordered table-sm padding-th">
                        <thead>
                        <tr v-for="month in items">
                          <td @click.prevent="handleCreateProjectionModal(month, item.id)"
                              style="width: 50%;max-width: 0;"
                              class="td-truncate text-center pointer text-table-entity-wallet">
                            {{ getPredictionByMonthOnItems(month, item.id) }}
                          </td>
                          <td style="width: 50%;max-width: 0;" class="td-truncate text-center text-table-entity-wallet">
                            {{ getAccomplishedOrBalanceItem(month, item.id) }}
                          </td>
                        </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="loading">
                <div class="row">
                  <div class="col-md-8 px-2">
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <br>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                  </div>
                  <div class="col-md-4 px-2">
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <br>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                    <SkeletonPuzl type="text"></SkeletonPuzl>
                  </div>
                </div>
              </template>
              <PuzlEmptyData v-if="!items.length"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalEditProjection @update="init" ref="modalEditProjection"/>
    <ModalCreateProjection @update="init" ref="modalCreateProjection"/>
    <ModalEditConstruction @update="init" ref="editConstruction"></ModalEditConstruction>
    <ModalPortfolioProjection ref="modalPortfolioProjection"></ModalPortfolioProjection>
  </div>
</template>
<script>
import MultiFilter from "@/components/Utils/MultiFilterV3.vue";
import PuzlSelect from "@/components/PuzlSelect.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import {mapGetters} from "vuex";
import {MonthsEnum} from "@/enum/MonthsEnum";
import ModalEditProjection from '@/views/Modules/Commercial/ContractProposal/Shared/_ModalEditProjection'
import SkeletonPuzl from "../../../../../components/SkeletonPuzl.vue";
import ModalEdit from "../../../Fiscal/OperationSource/Shared/_ModalEdit.vue";
import ModalCreateProjection from '@/views/Modules/Commercial/ContractProposal/Shared/_ModalCreateProjection'
import ModalEditConstruction from "../../../Commercial/CustomerConstruction/Constructions/Shared/_Edit.vue";
import ModalPortfolioProjection from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalPortfolioProjection.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Vue from "vue";

export default {
  components: {
    ModalEditConstruction,
    ModalCreateProjection,
    ModalEdit,
    SkeletonPuzl,
    PuzlSelect,
    MultiFilter,
    ModalEditProjection,
    ModalPortfolioProjection,
    PuzlEmptyData
  },
  mixins: [cursorPaginate],
  data() {
    return {
      loading: false,
      loadingSkeleton: false,
      loading_widgets: false,
      filter: {
        order_by: 'latest_updated_at.desc',
      },
      paginateItems: {
        nextUrl: null,
      },
      paginateItemsMounth: {
        nextUrl: null,
      },
      MonthsEnum: MonthsEnum,
      months: [],
      month_items: [],
      atual_month: new Date().getMonth() + 1
    }
  },
  computed: {
    ...mapGetters({
      $_widgets: "entityWallet/getWidgets",
      items: "entityWallet/fetch",
    })
  },
  methods: {
    validMonthGoBack() {
      let selected_month = this.atual_month - 1
      let atual_month = new Date().getMonth() + 1
      if ((atual_month - selected_month) > 1) {
        return false
      }
      return true
    },
    setSellerFilter(seller) {
      this.$refs['multi-filter'].global.push(seller)
      const filters = { ...this.$refs['multi-filter'].filter };
      filters.global =  this.$refs['multi-filter'].global;
      this.init(filters)
    },
    handleEditConstruction(uuid) {
      this.$refs.editConstruction.handleEditModal(uuid)
    },
    handleCreateProjectionModal(month_item, month) {
      let months = this.items.find((item) => item.id == month_item.id)
      let selected = months.months.find((item) => item.month === month)
      if (selected.projection_id) {
        return this.$refs.modalEditProjection.handleEditProjectionModal(
          selected.projection_id
        );
      } else {
        this.$refs.modalCreateProjection.openModal(
          months.id,
          months.company_plant_id,
          selected.prediction,
          selected.month
        );
      }
    },
    validMonthAdvanceBack() {
      if (!this.months.length) {
        return false
      }
      let selected_month = this.months[this.months.length - 1].id
      let atual_month = new Date().getMonth() + 1
      if ((selected_month - atual_month) > 3) {
        return false
      }
      return true
    },
    async handleGoBackMonth() {
      if (!this.validMonthGoBack()) {
        return
      }
      let loader = this.$loading.show()
      this.atual_month = this.atual_month - 1
      await this.getMonths()
      loader.hide()
    },
    async handleAdvanceMonth() {
      if (!this.validMonthAdvanceBack()) {
        return
      }
      let loader = this.$loading.show()
      this.atual_month = this.atual_month + 1
      await this.getMonths()
      loader.hide()
    },
    invalidGlobalFilter() {
      return typeof Vue.prototype.$filterable !== "number"
    },
    handlePortfolioProjection(id, contract) {
      this.$refs.modalPortfolioProjection.openModal(id, contract)
    },
    /**
     *
     * @param month
     * @returns {*}
     */
    getPredictedTotalByMonth(month) {
      if (!this.$_widgets) {
        return
      }
      if (!this.$_widgets.months) {
        return
      }
      let selected = this.$_widgets.months.find((item) => item.month == month)
      return selected ? selected.prediction : 0
    },
    /**
     *
     * @param month
     * @returns {*}
     */
    getAccomplishedOrBalance(month) {
      if (!this.$_widgets) {
        return
      }
      if (!this.$_widgets.months) {
        return
      }
      let selected = this.$_widgets.months.find((item) => item.month == month)
      let atual_month = new Date().getMonth() + 1
      return selected.month <= atual_month ? selected.accomplished : selected.balance
    },
    /**
     * Busca o saldo ou realizado por MES do contrato percorrido
     * * @param month_item
     * @param month
     * @returns {*}
     */
    getAccomplishedOrBalanceItem(month_item, month) {
      let months = this.items.find((item) => item.id == month_item.id).months
      let selected = months.find((item) => item.month === month)
      let atual_month = new Date().getMonth() + 1
      return selected.month <= atual_month ? selected.accomplished : selected.balance
    },
    /**
     * Busca o previsto por MES do contrato percorrido
     * @param month_item
     * @param month
     * @returns {*}
     */
    getPredictionByMonthOnItems(month_item, month) {
      let months = this.items.find((item) => item.id == month_item.id).months
      let selected = months.find((item) => item.month === month)
      return selected.prediction
    },
    async getWidgets(filter) {
      filter.months = this.getFilterableMonth()
      this.loading_widgets = true
      await this.$store.dispatch('entityWallet/getWidgets', filter).then(response => {
      }).catch(error => {
      }).finally(() => this.loading_widgets = false)
    },
    async getItems(filter = null) {
      this.loadingSkeleton = true
      if (filter) {
        this.filter = {...this.filter, ...filter}
        this.paginateItems.nextUrl = null
      }
      this.filter.months = this.getFilterableMonth()
      await this.$store.dispatch('entityWallet/fetch', {
        filter: this.filter,
        next_page: this.paginateItems.nextUrl
      }).then(response => {
        this.paginateItems.nextUrl = response.next_page
        this.loading = false
        this.loadingSkeleton = false
      })
    },
    getFilterableMonth() {
      let months = this.months.map((item) => item.id)
      return {
        start: months.shift() - 1,
        end: months.pop() + 2
      }
    },
    getMonths() {
      this.months = []
      const month = this.atual_month
      const year = new Date().getFullYear()
      for (let i of this.MonthsEnum) {
        i.year = year
        if (i.id > month && i.id < (month + 3)) {
          this.months.push(i)
        }
        if (i.id == month) {
          this.months.push(i)
        }
      }
    },
    handleFilterOrderBy(order_by) {
      this.filter.order_by = order_by
      this.init({})
    },
    validateScroll(e) {
      const screenHeight = e.target.clientHeight;
      const scrolled = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (scrollHeight - (screenHeight + scrolled) < 213) {
        return true
      }
      return false
    },
    navigate(e) {
      if (!this.loadingSkeleton && this.validateScroll(e)) {
        if (this.paginateItems.nextUrl) {
          this.scrollable()
        }
      }
    },
    async init(filter = {}) {
      // if (this.invalidGlobalFilter()) {
      //   return this.$swal.fire({
      //     icon: 'error',
      //     title: 'Aviso',
      //     text: 'Filtre a central para continuar',
      //   });
      // }
      let loader = this.$loading.show()
      this.atual_month = new Date().getMonth() + 1
      await this.getMonths()
      Promise.all([
          this.getWidgets(filter),
          this.scrollable(filter)
        ]
      ).finally(() => loader.hide())
    },
    async scrollable(filter = null) {
      let loader = this.$loading.show()
      await this.getItems(filter)
      loader.hide()
    },
  }
  ,
  async mounted() {
    const list = document.getElementById('items')
    if (list) {
      list.addEventListener("scroll", this.navigate)
    }
    await this.getMonths()
    this.loadingSkeleton = false
    this.loading_widgets = false
    this.loading = true
    await this.init({})
    this.loading = false
  }
}
</script>
<style scoped>
.padding-th > thead > tr > th, td {
  padding: 8px !important;
}

.padding-th > thead > tr > th:last-child, td:last-child {
  padding: 8px !important;
  border-right: 1px solid #dedede;
}

.padding-th > thead > tr > th:first-child, td:first-child {
  padding: 8px !important;
  border-left: 1px solid #dedede;
}

.numeric-custom {
  font-family: Quicksand;
  font-weight: 700 !important;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

table {
  width: 100%
}

td {
  white-space: nowrap;
}

.td-truncate {
  overflow: hidden;
  text-overflow: ellipsis;

}

.bg-grey {
  background: #8080802b !important;
}

.bg-aqua {
  background: #64baed4f !important;
}

.text-table-entity-wallet {
  font-size: 9px !important;
  font-weight: 600 !important;
}
</style>
