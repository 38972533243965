<template>
  <div>
    <AppFixedPageTitle
      title="Locais de Estoque"
      icon="/img/icons/icons8/ios/proposal-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="true"
        >
          <AppPageHeaderActionsButton
            @click="openStockLocationCreateModal"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>

    <div class="container-fluid mt-4">
      <StockLocationListPageCards
        @onEditItemClick="openStockLocationEditModal"
        @onRemoveItemClick="removeStockLocation"
      />
      <AppViewTrigger v-if="!isListing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!isListing && !listed.items.length"/>
    </div>
    <StockLocationCreateModal ref="stockLocationCreateModal" />
    <StockLocationEditModal ref="stockLocationEditModal" />
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import stockLocationStore from '../../store';
import { initStockLocationListFilterType, StockLocationListFilterType } from '../../types';
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import { dialogs } from '../../../../../helpers';
import StockLocationListPageCards from './StockLocationListPageCards.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import StockLocationCreateModal from "../create/StockLocationCreateModal.vue";
import StockLocationEditModal from "../edit/StockLocationEditModal.vue";

/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {StockLocationListFilterType} - Filtro principal */
const filter = reactive(initStockLocationListFilterType());

/** Store Getters */
const listed = computed(() => stockLocationStore.getListed());
const isListing = computed(() => stockLocationStore.getIsListing());

/** @type {import('vue').Ref<import("../create/StockLocationCreateModal.vue").ModalExpose>} */
const stockLocationCreateModal = ref(null);

/** @type {import('vue').Ref<import("../edit/StockLocationEditModal.vue").ModalExpose>} */
const stockLocationEditModal = ref(null);

const listType = ref('cards');

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: true,
      name: 'Ativo',
      selected: filter.status === true,
    },
    {
      id: false,
      name: 'Inativo',
      selected: filter.status === false,
    },
  ];
});

const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: [{
        column: "stock_locations.id",
        is_desc: false,
      }],
    },
    {
      id: 1,
      name: "DO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "stock_locations.created_at",
        is_desc: true,
      }],
    },
    {
      id: 2,
      name: "DO MAIS NOVO PARA O MAIS VELHO",
      selected_name: "MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "stock_locations.created_at",
        is_desc: false,
      }],
    },
  ],
  selected: 0,
});

onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  stockLocationStore.resetStates();
  stockLocationStore.setPageState({ filter, searchBarFilter });
});

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? stockLocationStore.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);
  
  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType();
};

/**
 * Padrão do filtro principal
 * @returns {StockLocationListFilterType}
 */
function defaultFilter() {
  return {
    ...initStockLocationListFilterType(),
    status: null, 
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {  
  prepareFilter();
  stockLocationStore.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.order_by = orderBy.items[orderBy.selected].filter;
};

/** 
 * Abrir modal para criar registro
 */
function openStockLocationCreateModal() {
  stockLocationCreateModal.value.openModal();
}

/**
 * Abrir modal para editar registro
 * @param {number} id
 */
function openStockLocationEditModal(id) {
  stockLocationEditModal.value.openModal(id);
};

/**
 * Remover registro
 * @param {number} id
 */
async function removeStockLocation(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    await stockLocationStore.remove(id);
    dialogs.notifyRemoved();
  }
};
</script>

<style scoped>
</style>
