<template>
  <div>
    <div class="container-fluid">
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <form
          class="needs-validation"
          autocomplete="off"
        >
          <card>
            <div class="row">
              <div class="col-md-12">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 mb-1 pr-4 mt-n3">
                      <div class="pb-0 ml-n2 col-form-label form-control-label">
                        Grupo de permissão
                        <a
                          href="#"
                          class="p-1 ml-1"
                          v-show="selected"
                          @click.prevent="handleEditGroupPermission(selected)"
                        >
                          <img src="/img/icons/create-new.png" width="25px" height="25px" class="mt-n1" />
                        </a>
                        <a
                          href="#"
                          class="p-1 ml-1"
                          v-show="selected"
                          @click.prevent="handleDelete(selected)"
                        >
                          <img src="/img/icons/delete.png" width="25px" height="25px" class="mt-n1" />
                        </a>
                      </div>
                    </div>
                    <div class="col-md-6 p-1">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          v-model="selected"
                          :items="group_permissions"
                          @change="setPermissionsSelected()"
                          :disabled="loadingGroupPermission"
                          :loading="loadingGroupPermission"
                        />
                      </base-input>
                    </div>
                    <div class="col-md-3 p-1">
                      <base-button
                        type="primary" block outline
                        @click.prevent="selectAll()"
                        style="padding: 0.30rem 0.5rem;"
                        size="sm"
                      >
                        <img src="/img/icons/icons8/ios/check-all.png" width="17px" height="17px" class="invert-on-hover" />
                          Selecionar todas
                        <span class="badge badge-light"style="color: black !important;">
                          {{ selected_permissions.length }} / {{ permissions.length }}
                        </span>
                      </base-button>
                    </div>
                    <div class="col-md-3 p-1">
                      <base-button
                        @click.prevent="expandAllPermissions()"
                        block type="warning" outline
                        style="padding: 0.39rem 0.5rem;"
                        size="sm"
                      >
                        <img src="/img/icons/icons8/ios/expand.png" width="17px" height="17px" class="invert-on-hover" />
                        Expandir permissões
                      </base-button>
                    </div>
                  </div>
                </div>
                <!-- AÇÕES -->
                <div class="card p-3">
                  <div class="mb-2 ml-1">
                    <img src="/img/icons/icons8/ios/channel-mosaic.png" width="25px" height="25px" />
                    <b class="text-dark ml-1"> Ações </b>
                  </div>
                  <div class="row mt-n2">
                    <div class="col-md-4" v-for="(item, key, index) in options" v-if="index < 3">
                      <collapse :id="key" class="border rounded p-0 mt-3">
                        <collapse-item
                          :total="getTotalSelected(key).qtdTotal"
                          :selected="getTotalSelected(key).qtdSelected"
                        >
                          <h5 slot="title" class="mb-0" :class="key">
                            <img :src="item.image" width="23px" height="23px" />
                            <span class="mb-1 px-2 mr-2"><b>{{ item.name }}</b></span>
                            <badge
                              style="
                                width: 15%;
                                font-size: 10px !important;
                                background-color: rgba(161, 220, 188, 0.3);
                                border-radius: 7px;
                              "
                              class="text-success"
                            >
                              {{ getTotalSelected(key).qtdSelected }} / {{ getTotalSelected(key).qtdTotal }}
                            </badge>
                          </h5>
                          <a href="#" @click.prevent="selectAllItemPermission(key)">
                            <img src="/img/icons/icons8/ios/check-all.png" v-if="selected" width="17px" height="17px" class="mt-n4" />
                          </a>
                          <transition name="fade">
                            <div class="row align-items-center pb-0 mb-3" v-if="selected">
                              <template v-for="(permission, key) in item.permissions">
                                <div v-if="!permission.title.includes('Totvs') || has_totvs" class="col-md-12" :key="key">
                                  <input v-model="selected_permissions" :value="permission.id" type="checkbox">
                                  <small class="ml-2">{{ permission.title }}</small>
                                  <el-popover ref="myPopover" class="p-0" placement="right" trigger="click">
                                    <span class="numeric m-0 pl-1">
                                      <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="17px" height="17px" />
                                      <b> Grupos vinculados </b>
                                      <div
                                        v-if="rolesData"
                                        class="font-weight-400 mt-1"
                                        style="max-height: 170px; overflow-y: auto;"
                                      >
                                        <div
                                          v-for="roleString in rolesData.rolesData.split(',')"
                                          v-if="!loadingRolesName"
                                        >
                                          <span>{{ roleString.trim().split(':')[1] }}</span>
                                          <a
                                            href="#"
                                            @click.prevent="removePermissionFromRole(permission.id, roleString.trim().split(':')[0], true)"
                                          >
                                            <img src="/img/icons/delete.png" width="17px" height="17px" class="float-right mr-2 ml-2" />
                                          </a>
                                        </div>
                                        <div class="mr-3" v-else>
                                          <SkeletonPuzl type="button" />
                                        </div>
                                      </div>
                                    </span>
                                    <a href="#" @click.prevent="getRolesByPermission(permission.id)" slot="reference">
                                      <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="17px" height="17px" class="float-right" />
                                    </a>
                                  </el-popover>
                                </div>
                              </template>
                            </div>
                          </transition>
                        </collapse-item>
                      </collapse>
                    </div>
                  </div>
                </div>
                <!-- TELAS -->
                <div class="card p-3 mt-n2">
                  <div class="mb-2 ml-1">
                    <img src="/img/icons/icons8/ios/flicker-free.png" width="25px" height="25px" />
                    <b class="text-dark ml-1"> Telas </b>
                  </div>
                  <div class="row mt-n2">
                    <div class="col-md-4" v-for="(item, key, index) in options" v-if="index >= 3">
                      <div class="col-12 mb-3">
                        <collapse :id="key" class="border rounded p-0 mt-3">
                          <collapse-item
                            :total="getTotalSelected(key).qtdTotal"
                            :selected="getTotalSelected(key).qtdSelected"
                          >
                            <h5 slot="title" class="mb-0 d-flex align-items-center" :class="key">
                              <img :src="item.image" width="23px" height="23px" />
                              <span class="px-2 mr-2"><b>{{ item.name }}</b></span>
                              <badge
                                style="
                                  width: 15%;
                                  font-size: 10px !important;
                                  background-color: rgba(161, 220, 188, 0.3);
                                  border-radius: 7px;
                                "
                                class="text-success"
                              >
                                {{ getTotalSelected(key).qtdSelected }} / {{ getTotalSelected(key).qtdTotal }}
                              </badge>
                            </h5>
                            <a href="#" @click.prevent="selectAllItemPermission(key)">
                              <img src="/img/icons/icons8/ios/check-all.png" v-if="selected" width="17px" height="17px" class="mt-n4" />
                            </a>
                            <transition name="fade">
                              <div class="row align-items-center pb-0" v-if="selected">
                                <template v-for="(permission, key) in item.permissions">
                                  <div v-if="!permission.title.includes('Totvs') || has_totvs" class="col-md-12 d-flex align-items-top mb-2" :key="key">
                                    <div><input v-model="selected_permissions" :value="permission.id" type="checkbox"></div>

                                    <small class="ml-2">{{ permission.title }}</small>
                                    <el-popover class="p-0 ml-2" placement="right" trigger="click">
                                      <span class="numeric m-0 pl-1">
                                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="17px" height="17px" />
                                        <b> Grupos vinculados </b>
                                        <div
                                          v-if="rolesData"
                                          class="font-weight-400 mt-1"
                                          style="max-height: 170px; overflow-y: auto;"
                                        >
                                          <div
                                            v-for="roleString in rolesData.rolesData.split(',')"
                                            v-if="!loadingRolesName"
                                          >
                                            <span>{{ roleString.trim().split(':')[1] }}</span>
                                            <a
                                              href="#"
                                              @click.prevent="removePermissionFromRole(permission.id, roleString.trim().split(':')[0], true)"
                                            >
                                              <img src="/img/icons/delete.png" width="17px" height="17px" class="float-right mr-2 ml-2" />
                                            </a>
                                          </div>
                                          <div class="mr-3" v-else>
                                            <SkeletonPuzl type="button" />
                                          </div>
                                        </div>
                                      </span>
                                      <a href="#" @click.prevent="getRolesByPermission(permission.id)" slot="reference">
                                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="17px" height="17px" class="float-right" />
                                      </a>
                                    </el-popover>
                                  </div>
                                </template>
                              </div>
                            </transition>
                          </collapse-item>
                        </collapse>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" class="text-capitalize">
                Cancelar
              </base-button>
              <base-button
                :disabled="!selected"
                :loading="loadingSave"
                @click.prevent="save()"
                type="success"
                native-type="submit"
              >
                <span v-show="!loadingSave" class="btn-label"><i class="fas fa-save"></i></span>
                Salvar
              </base-button>
            </div>
          </card>
        </form>
      </validation-observer>
    </div>
    <CreateGroupPermission ref="createGroupPermission" />
    <EditGroupPermission ref="editGroupPermission"/>
    <ModalPermissionGroup ref="modalPermissionGroup" @selectNewPermissionGroup="selectNewPermissionGroup" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CreateGroupPermission from '../../GroupPermission/Shared/_Create';
import EditGroupPermission from '../../GroupPermission/Shared/_Edit';
import PuzlSelect from "@/components/PuzlSelect";
import { hasTotvs } from "@/plugins/microservices/totvs";
import ModalPermissionGroup from "./_ModalPermissionGroup";
import SkeletonPuzl from "@/components/SkeletonPuzl";

export default {
  name: "Permission",
  components: {
    CreateGroupPermission,
    EditGroupPermission,
    PuzlSelect,
    ModalPermissionGroup,
    SkeletonPuzl,
  },
  data() {
    return {
      loadingSave: false,
      options: {
        additional_permissions: {
          name: 'Permissões adicionais',
          icon: 'fa-solid fa-unlock-keyhole fa-2x',
          icon_color: 'text-primary',
          image: '/img/icons/unlock.png',
          permissions: []
        },
        bill_pay: {
          name: 'Contas a pagar',
          icon: 'fa-solid fa-coins fa-2x',
          icon_color: 'text-danger',
          image: '/img/icons/finance.png',
          permissions: []
        },
        bill_receive: {
          name: 'Contas a receber',
          icon: 'fa-solid fa-coins fa-2x',
          icon_color: 'text-success',
          image: '/img/icons/finance.png',
          permissions: []
        },
        configuration: {
          name: 'Geral e configurações',
          icon: 'fas fa-cog fa-2x',
          icon_color: 'text-muted',
          image: '/img/icons/settings.png',
          permissions: []
        },
        administrative: {
          name: 'Gerencial',
          icon: 'fa-solid fa-chart-simple fa-2x',
          icon_color: 'text-grey-2',
          image: '/img/icons/bullish.png',
          permissions: []
        },
        technology: {
          name: 'Tecnologia',
          icon: 'ni ni-atom ni-2x',
          icon_color: 'text-info',
          image: '/img/icons/physics.png',
          permissions: []
        },
        puzl_adjust: {
          name: 'Puzl adjust',
          icon: 'ni ni-atom ni-2x',
          icon_color: 'text-info',
          image: '/img/icons/physics.png',
          permissions: []
        },
        commercial: {
          name: 'Comercial',
          icon: 'ni ni-building ni-2x',
          icon_color: 'text-warning',
          image: '/img/icons/icons8/ios/briefcase-green.png',
          permissions: []
        },
        operational: {
          name: 'Operacional',
          icon: 'ni ni-delivery-fast ni-2x',
          icon_color: 'text-green',
          image: '/img/icons/chemical-plant.png',
          permissions: []
        },
        service: {
          name: 'Serviços',
          icon: 'fa-solid fa-puzzle-piece',
          icon_color: 'text-primary',
          image: '/img/icons/group.svg',
          permissions: []
        },
        stock: {
          name: 'Estoque',
          icon: 'fas fa-box-open fa-2x',
          icon_color: 'text-primary',
          image: '/img/icons/open-box.png',
          permissions: []
        },
        equipment: {
          name: 'Equipamento',
          icon: 'ni ni-settings ni-2x',
          icon_color: 'text-indigo',
          image: '/img/icons/concrete-pump.png',
          permissions: []
        },
        purchases: {
          name: 'Compras',
          icon: 'fas fa-shuttle-van fa-2x',
          icon_color: 'text-primary',
          image: '/img/icons/cart-warning.png',
          permissions: []
        },
        entries: {
          name: 'Entrada',
          icon: 'fas fa-inbox fa-2x',
          icon_color: 'text-warning',
          image: '/img/icons/login-rounded.png',
          permissions: []
        },
        exit: {
          name: 'Saída',
          icon: 'fa-solid fa-right-from-bracket fa-2x',
          icon_color: 'text-lightred',
          image: '/img/icons/login-rounded-right.png',
          permissions: []
        },
        financial: {
          name: 'Financeiro',
          icon: 'ni ni-money-coins ni-2x',
          icon_color: 'text-danger',
          image: '/img/icons/finance.png',
          permissions: []
        },
        billing: {
          name: 'Faturamento',
          icon: 'fa-solid fa-receipt fa-2x',
          icon_color: 'text-indigo',
          image: '/img/icons/check.png',
          permissions: []
        },
        cash_flow: {
          name: 'Fluxo de caixa',
          icon: 'fa-solid fa-hand-holding-dollar fa-2x',
          image: '/img/icons/exchange.png',
          permissions: []
        },
        fiscal: {
          name: 'Fiscal',
          icon: 'fa-solid fa-hand-holding-dollar fa-2x',
          icon_color: 'text-darkred',
          image: '/img/icons/ledger.png',
          permissions: []
        },
        driver: {
          name: 'Motorista',
          icon: 'fas fa-shuttle-van fa-2x',
          icon_color: 'text-primary',
          image: '/img/icons/interstate-truck.png',
          permissions: []
        },
      },
      selected: null,
      selected_permissions: [],
      loadingPermissions: false,
      loadingGroupPermission: false,
      selectAllPermissions: false,
      has_totvs: false,
      groupPermissionIdToDelete: null,
      rolesData: null,
      loadingRolesName: false,
      deleteGroupPermissionByPopover: false,
    }
  },
  mounted() {
    this.init()
    // informa se cliente possui integração totvs
    this.has_totvs = hasTotvs()
  },
  computed: {
    ...mapGetters({
      'group_permissions': 'authGroupPermission/fetch',
      'permissions': 'Permission/fetch',
      'modules': 'module/fetch',
    }),
  },
  methods: {
    /**
     * Seleciona todos os itens de todos os grupos de permissões.
     */
    selectAll() {
      this.selectAllPermissions = !this.selectAllPermissions;
      if (this.selectAllPermissions) {
        this.selected_permissions = [];
        this.permissions.forEach(element => {
          this.selected_permissions.push(element.id);
        });
      } else {
        this.selected_permissions = [];
      }
    },
     /**
     * Seleciona todos os itens do grupo de permissão escolhido.
     * Caso já exista algum item selecionado, ele desconsira e faz o push sem repetir o valor.
     *
     * @param {string} key
     */
    selectAllItemPermission(key) {
      const isChecked = this.checkIfAnyItemIsSelected(key);
      this.options[key].permissions.forEach(element => {
        let foundSelectedItem = this.selected_permissions.find(item => item == element.id)
        if (isChecked) {
          if (element.id != foundSelectedItem) {
            this.selected_permissions.push(element.id);
          }
        } else {
          this.selected_permissions.splice(this.selected_permissions.indexOf(element.id), 1);
        }
      });
    },
    /**
     * Verifica se já existe algum item selecionado.
     *
     * @param {string} key
     * @return {bool}
     */
    checkIfAnyItemIsSelected(key) {
      const allPermissionWithStatus = [];
      this.options[key].permissions.forEach(element => {
        const permissionFound = this.selected_permissions.includes(element.id);
        allPermissionWithStatus.push(permissionFound);
      });
      const containFalseInArray = allPermissionWithStatus.filter(item => item == false).length > 0;
      return containFalseInArray;
    },
    /**
     * Pega o total de itens de um card.
     * Faz a contagem dos itens selecionados.
     *
     * @param {string} key
     * @return {object}
     */
    getTotalSelected(key) {
     let checkedCount = 0;
      let groupPermissionItemsCount = this.options[key].permissions.length;
      this.options[key].permissions.forEach(element => {
        const permissionFound = this.selected_permissions.includes(element.id);
        if (permissionFound) {
          checkedCount ++;
        }
      });
      return {
        qtdTotal: groupPermissionItemsCount,
        qtdSelected: checkedCount,
      }
    },
    setPermissionsSelected() {
      this.$store.dispatch('Permission/getRolesWithPermissions', this.selected).then((response) => {
        const role = response.data;
        this.selected_permissions = role.permissions.map((item) => item.id);
      });
    },
    clearAndSetSelectedPermissions(id) {
      this.selected_permissions = []
      this.selected = this.group_permissions.find((item) => item.id === id)
    },
    setPermissionAccordion() {
      this.permissions.sort((a, b) => a.order - b.order);

      for (let key in this.options) {
        this.options[key]['permissions'] = this.permissions.filter(function (item) {
            return item.name.split('.').includes(key);
        }).sort((a, b) => a.order - b.order);
      }
    },
    expandAllPermissions() {
      for (let key in this.options) {
        document.getElementById(key).getElementsByClassName("card-header")[0].click()
      }
    },
    save() {
      let params = {
        role_id: this.selected.name ? this.selected.id : this.selected,
        permissions: this.selected_permissions
      }
      this.loadingSave = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store.dispatch('Permission/update', params)
        .then(response => {
          this.$notify({type: 'success', message: response.data.message})
          this.setPermissionAccordion()
          this.loadingSave = false
        })
        .catch(() => {
          this.loadingSave = false
        })
    },
    init() {
      this.fetchGroupPermission();
      this.fetchPermission();
    },
    fetchPermission() {
      this.loadingPermissions = true;
      this.$store.dispatch('Permission/fetchItems')
        .then(() => {
          this.setPermissionAccordion()
          this.loadingPermissions = false;
        })
        .catch(() => {
          this.loadingPermissions = false;
        });
    },
    fetchGroupPermission() {
      this.loadingGroupPermission = true;
      this.$store.dispatch('authGroupPermission/fetchItems')
        .then(() => {
          this.loadingGroupPermission = false;
        })
        .catch(() => {
          this.loadingGroupPermission = false;
        });
    },
    /**
     * @param {number} id
     */
    handleEditGroupPermission(id) {
      this.$refs.editGroupPermission.handleEditModal(id)
    },
    handleNewGroupPermission() {
      this.$refs.modalPermissionGroup.openModal();
    },
    /**
     * @param {number} id
     */
    handleDelete(id, deleteGroupPermissionByPopover = null) {
      this.groupPermissionIdToDelete = id;
      this.handleNewGroupPermission();
      this.deleteGroupPermissionByPopover = deleteGroupPermissionByPopover;
    },
    /**
     * O antigo grupo de permissões é removido, e o atual que foi cadastrado é selecionado na modal,
     * mas também é atribuído para todos os usuários que estavam associados ao grupo de permissão
     * que foi deletado, para evitar que um usuário fique sem grupo de permissão.
     * @param {number} selectedGroupPermissionId
     */
    selectNewPermissionGroup(selectedGroupPermissionId) {
      this.$Progress.start();
      if (this.groupPermissionIdToDelete == selectedGroupPermissionId) {
        this.$notify({
          type: 'warning',
          message: 'O novo grupo de permissão não pode ser igual àquele que está sendo removido'
        });
        return;
      }
      const deleteAndReplaceIds = {
        id: this.groupPermissionIdToDelete,
        replacementId: selectedGroupPermissionId,
      };
      this.$store.dispatch('authGroupPermission/destroyGroupPermission', deleteAndReplaceIds)
        .then((response) => {
          this.$notify({
            type: 'success',
            message: response.message
          });
          this.clearAndSetSelectedPermissions();
          this.selected = selectedGroupPermissionId;
          this.$Progress.finish();
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
        });
    },
    /**
     * Recupera todos os grupos de permissão que foram associados a uma determina permissão.
     * @param {number} permissionId
     */
    getRolesByPermission(permissionId) {
      this.loadingRolesName = true;
      this.$store.dispatch('Permission/getRolesByPermission', permissionId).then((response) => {
        this.rolesData = response.data;
        setTimeout(() => {
          this.loadingRolesName = false;
        }, 120);
      });
    },
    /**
     * Remove o vínculo entre uma permissão e um grupo.
     * @param {number} permissionId
     * @param {string} roleId
     */
    removePermissionFromRole(permissionId, roleId) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          const permissionRoleRemovalPayload = {
            permissionId: permissionId,
            roleId: parseInt(roleId),
          };
          this.$store.dispatch('Permission/removePermissionFromRole', permissionRoleRemovalPayload).then((response) => {
            this.getRolesByPermission(permissionId);
            this.fetchGroupPermission();

            // Desmarcar o checkbox caso o grupo de permissão selecionado
            // seja o mesmo a ser desvinculado de uma permissão.
            if (parseInt(roleId) == this.selected) {
              const index = this.selected_permissions.indexOf(permissionId);
              if (index !== -1) {
                this.selected_permissions.splice(index, 1);
              }
            }

            this.$notify({type: 'success', message: response.message});
            this.$Progress.finish();
          });
        }
      }).catch(() => this.$Progress.finish());
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 767px) {
  .btn-size {
    margin-top: 35px !important;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.img:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
</style>
