<template>
  <div>
    <modal @close="close" size="lg" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <div class="row p-2">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <label style="font-size: 0.8rem" class="form-control-label">Data de criação</label>
                <input-date-picker size="small" ref="date-picker" @handleFilterDate="handleSetDate"
                                   :default-range="filter.range"/>
              </div>
              <div class="col-md-6">
                <label style="font-size: 0.8rem" class="form-control-label">CNPJ Emissor</label>
                <validation-provider rules="required">
                    <puzl-select
                      :clearable="false"
                      class="select-lg"
                      label="$alias_name$ | $ein$"
                      :label-mask="true"
                      v-model="filter.company_plant_issuer_id"
                      :items="$_company_plant_issuers"
                      :loading="loadingCompanyPlantIssuers"
                      :disabled="loadingCompanyPlantIssuers"/>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <base-button type="secondary" @click="close()">
            Fechar
          </base-button>
          <base-button :disabled="invalid" :loading="isLoading" type="primary" @click="save()">
            <span v-show="!isLoading" class="btn-label"><i class="fa-solid fa-cloud-arrow-down"></i></span>
            Iniciar exportação
          </base-button>
        </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import InputDatePicker from "@/components/InputDatePicker"
import PuzlSelect from "@/components/PuzlSelect"

export default {
  name: "ModalExporter",
  components: {
    InputDatePicker,
    PuzlSelect
  },
  data() {
    return {
      title: 'Exportador',
      isLoading: false,
      loadingCompanyPlantIssuers: false,
      filter: {
        company_plant_id: null,
        type: 0,
        range: {
          start: new Date(),
          end: new Date(),
        },
      },
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      $_company_plant_issuers: "companyPlantIssuer/fetch",
    }),
  },
  methods: {
    close() {
      this.show = false
    },
    handleSetDate(range) {
      this.filter.range = range
    },
    getCompanyPlantIssuers() {
      this.loadingCompanyPlantIssuer = true;
      this.$store
        .dispatch("companyPlantIssuer/fetchItems")
        .then(() => {
          this.loadingCompanyPlantIssuer = false;
        });
    },
    save() {
      this.isLoading = true
      this.$store.dispatch('exports/store', {filter: this.filter})
        .then(response => {
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.close()
          this.$emit('openProcessList')
          this.isLoading = false
        }).catch(error => {
        this.isLoading = false
        this.$notify({
          type: error.data.error_type,
          message: error.data.message
        });
      })
    },
    async openModal() {
      this.filter.company_plant_id = null
      this.filter.range = {
        start: new Date(),
        end: new Date(),
      }
      await this.getCompanyPlantIssuers()
      this.show = true
    }
  },
};
</script>

<style>
</style>
