<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
            <!-- CAMPO CENTRAL -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Central
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="plants_id"
                      :items="$_plants"
                      multiple
                      :loading="loadingCompanyPlants"
                      :disabled="loadingCompanyPlants"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- CAMPO SLUMP/FLOW -->
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Slump final
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                        <PuzlSelect
                            v-model="$_adjustmentFinalSlump.slump_flow_id"
                            :items="$_slumpFlows"
                            label="nomenclature"
                            :loading="loadingSlumpFlows"
                            :disabled="loadingSlumpFlows" />
                    </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                Slump referência
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-7 mb-1 pr-4">
                <validation-provider rules="required" v-slot="{errors}">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect
                      v-model="$_adjustmentFinalSlump.ref_slump_flow_id"
                      :items="$_slumpFlows"
                      label="nomenclature"
                      :loading="loadingSlumpFlows"
                      :disabled="loadingSlumpFlows" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <!-- CAMPO OBSERVAÇÃO -->
            <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                    Observação
                </label>
                <div class="col-md-7 mb-1 pr-4">
                    <base-input input-group-classes="input-group-sm">
                        <textarea
                          type="text"
                          rows="3"
                          class="form-control form-control-sm"
                          v-model="$_adjustmentFinalSlump.observation"
                        >
                        </textarea>
                    </base-input>
                </div>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
              <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");

export default {
  name: "ModalEdit",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Editar ajuste por slump final",
        create: false,
      },
      loadingCompanyPlants: true,
      loadingSlumpFlows: true,
      loadingStore: false,
      plants_id: [],
    };
  },
  computed: {
    ...mapGetters({
      $_adjustmentFinalSlump: "adjustmentFinalSlump/show",
      $_plants: "plant/activeItems",
      $_slumpFlows: 'mixSlumpFlow/activeItems',
    }),
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {number} id
     */
    handleEditModal(id) {
      this.$Progress.start();
      this.loadData();
      this.$store
        .dispatch("adjustmentFinalSlump/show", id)
        .then((response) => {
          this.plants_id = response.data.plants.map(plant => plant.id);
          this.modal.create = true;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$notify({ type: error.data.error_type, message: error.data.message });
          this.$Progress.finish();
        });
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$_adjustmentFinalSlump.plants = this.plants_id;
      this.$store
        .dispatch("adjustmentFinalSlump/update", this.$_adjustmentFinalSlump)
        .then((response) =>
        {
            this.$emit("updatedFinalSlump");
            this.$notify({ type: response.error_type, message: response.message });
            this.closeModal();
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({ type: "danger", message: error.data.message });
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({ type: "danger", message: errors });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    loadData() {
      this.loadingCompanyPlants = true;
      this.$store.dispatch("plant/getPlantsSimplified").then(() => {
        this.loadingCompanyPlants = false;
      });
      this.loadingSlumpFlows = true;
      this.$store.dispatch('mixSlumpFlow/fetchItems').then(() => {
        this.loadingSlumpFlows = false;
      });
    },
  },
};
</script>

<style scoped></style>
