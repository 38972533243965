<template>
  <div>
    <modal size="md" :show.sync="show">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <div class="container">
        <div class="row p-2">
          <div class="col-md-12">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-md-4 mt-2">
                    <label style="font-size: 0.8rem" class="form-control-label">Filtrar pela Data:</label>
                  </div>
                  <div class="col-md-8 mt-2">
                    <puzl-select
                      v-model="filter.range_type"
                      :items="range_types"/>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-2">                
                <input-date-picker 
                  size="small" 
                  ref="datePicker"
                  @handleFilterDate="handleFilterDate"
                  :default-range="defaultRange"
                  :filterable="4"
                />
              </div>
              <div class="col-md-12 mt-2">
                <label style="font-size: 0.8rem" class="form-control-label">Central</label>
                <puzl-select
                  class="select-lg"
                  v-model="selectedCompanyPlants"
                  :items="[{id: 'all', name: 'Todos'}, ...$_plants]"
                  :multiple="true"
                  :loading="loadingCompanyPlants"
                  :disabled="loadingCompanyPlants"
                  @input="setAll"
                />
              </div>
              <div class="col-md-12 mt-2">
                <div class="row">
                  <div class="col-md-3">
                    <small class="font-weight-bold mb-0 mr-2">Com NFS-E</small>
                    <input v-model="filter.invoice_with_nfse" style="padding-top: 0.1rem;" type="checkbox"/>
                  </div>
                  <div 
                    v-if="[
                      range_type_enum.longest_delivery, 
                      range_type_enum.invoice_issuance
                    ].includes(filter.range_type)" 
                    class="col-md-3"
                  >
                    <small class="font-weight-bold mb-0 mr-2">Sem NFS-E</small>
                    <input v-model="filter.invoice_without_nfse" style="padding-top: 0.1rem;" type="checkbox"/>
                  </div>
                </div>
              </div>
              <div v-if="filter.invoice_with_nfse && !filter.invoice_without_nfse" class="col-md-12 mt-2">
                <label style="font-size: 0.8rem" class="form-control-label">Status da NFS-E</label>                 
                <puzl-select
                  class="select-lg"
                  v-model="selectedNfseStatus"
                  :items="nfseStatus"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2 text-right p-0 mt-4">
        <base-button
          @click.prevent="close"
          type="secondary"
          class="text-capitalize"
        >
          Cancelar
        </base-button>
        <base-button
          @click.prevent="submitAndClose"
          type="success"
          native-type="submit"
          :loading="isLoading"
          :disabled="!(filter.invoice_with_nfse || filter.invoice_without_nfse)"
        >
          <span class="btn-label"><i class="fa-solid fa-file-excel"></i></span>
          Download
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import moment from 'moment';

const range_type_enum = {
  longest_delivery: 0,
  invoice_issuance: 1,
  nfse_issuance: 2,
  nfse_authorization: 3,
};

export default {
  props: ["show"],
  name: "ModalNfseExcelReport",
  components: {
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      title: "Resumo de Faturas e NFS-E",
      range_type_enum: range_type_enum,
      range_types: [
        {
          id: range_type_enum.longest_delivery, 
          name: 'Maior Data de Entrega',
          invoice: true,
          nfse: true,
        },
        {
          id: range_type_enum.invoice_issuance, 
          name: 'Emissão da Fatura',
          invoice: true,
          nfse: true,
        },
        {
          id: range_type_enum.nfse_issuance, 
          name: 'Emissão da NFS-E',
          invoice: false,
          nfse: true,
        },
        {
          id: range_type_enum.nfse_authorization, 
          name: 'Autorização da NFS-E',
          invoice: false,
          nfse: true,
        },
      ],
      filter: {
        range: null,
        range_type: 0,        
        company_plants: [],
        invoice_with_nfse: true,
        invoice_without_nfse: false,
      },
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date( new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date( new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      nfseStatus: [
        {
          id: 'authorized',
          name: 'Autorizada',
        },
        {
          id: 'canceled',
          name: 'Cancelada',
        },
      ],
      selectedNfseStatus: null,
      selectedCompanyPlants: [],
      isLoading: false,
      loadingCompanyPlants: true,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
  },
  mounted() {
    this.$watch("show", function (value) {
      if (value) {
        this.init();
      }
    });
  },
  methods: {
    init(){
      this.loadData();
      this.range = this.defaultRange
      this.filter.nfse_status = this.nfseStatus[0].id;
      this.selectedNfseStatus = this.nfseStatus[0].id;
      this.selectedCompanyPlants = []
    },
    close() {
      this.$emit("close");
    },
    async submitAndClose() {
      // Filtro de dados
      this.filter.nfse_status = this.selectedNfseStatus;
      this.filter.range = this.range;            
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all")
        ? this.selectedCompanyPlants
        : [];
      if ([range_type_enum.nfse_issuance, range_type_enum.nfse_authorization].includes(this.filter.range_type)) {
        this.filter.invoice_without_nfse = true;        
      };
      
      // Baixar Relatório em Excel
      let loader = this.$loading.show();
      this.isLoading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store
        .dispatch("nfse/getNfseExcelReport", { filter: this.filter })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Relatório de nfs-e.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
          loader.hide();
          this.isLoading = false;
          this.close();
        })
        .catch((error) => {
          loader.hide();
          this.isLoading = false;
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        });
    },
    /**
     * Verifica se o intervalo é maior que 31 dias.
     * @param {object} range
     * @returns {boolean}
     */
    checkDateRangeExceedsLimit(range) {
      let startDate = moment(range.start);
      let endDate = moment(range.end);
      if (endDate.diff(startDate, 'days') > 30) {
        // Seta data inicial da tela
        this.range = {
          start: new Date( new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date( new Date().getFullYear(), new Date().getMonth() + 1, 0),
        };
        this.$refs.datePicker.resetDate();
        this.$notify({
          type: "warning",
          message: "O intervalo de datas não pode ser maior que 31 dias",
        });
        return false;
      }
      return true;
    },
    handleFilterDate(filter) {
      this.range = filter;
      if (!this.checkDateRangeExceedsLimit(this.range)) {
        return;
      }
    },
    setAll() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == 'all');
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1)
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = []
        this.selectedCompanyPlants.push("all")
      }
    },
    loadData() {
      this.loadingCompanyPlants = true;
      this.$store.dispatch('plant/getPlantsSimplified').then(() => {
        this.loadingCompanyPlants = false;
      });
    },
  },
};
</script>

<style></style>