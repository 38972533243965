<template>
  <div>
    <div class="container-fluid">
      <multi-filter @fetch="init" :filter="filter" :status="multi_filter" />
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>
      <PuzlEmptyData v-if="!cmcAdjustments.length && !loadingSkeleton"></PuzlEmptyData>
      <div class="row card-wrapper" v-if="cmcAdjustments.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(cmcAdjustment, index) in cmcAdjustments" :key="index">
          <!-- Basic with action button -->
          <card
            :class="
              cmcAdjustment.color === 0
                ? 'card-border-top-success'
                : cmcAdjustment.color === 1
                ? 'card-border-top-warning'
                : cmcAdjustment.color === 2
                ? 'card-border-top-primary'
                : cmcAdjustment.color === 3
                ? 'card-border-top-danger'
                : ''
            "
          >
            <!-- Card header -->
            <div class="row align-items-center mb-3">
              <div class="col-9 justify-content-center pr-0">
                <p class="pb-0 mb-0 pr-1">
                  <b>{{ cmcAdjustment.charge_point.name }}</b>
                  | {{ cmcAdjustment.company_plant.name }}
                </p>
              </div>
              <div class="col-3 text-right pl-0">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleEditCmcAdjustment(cmcAdjustment.uuid)">
                    <i class="fas fa-edit text-warning"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDeleteCmcAdjustment(cmcAdjustment.uuid)">
                    <i class="fas fa-times text-danger"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
              <div class="col-9 justify-content-center pr-0">
                <p class="pb-0 mb-0 pr-1 pt-3">
                  <b
                    >{{ cmcAdjustment.cmc_group.name }}
                    <a @click.prevent="$refs['modal-reference'].handleOpenModal(cmcAdjustment)" href="#" class="text-primary">
                      <i class="zoom fas fa-star"></i>
                    </a>
                  </b>
                  <br />{{ cmcAdjustment.cmc_category.name }}
                </p>
                <p class="pb-0 mb-0 pr-1 pt-3">
                  <b>{{ cmcAdjustment.cmc.product }}</b>
                  <br />{{ cmcAdjustment.cmc.supplier }} ({{ cmcAdjustment.cmc.supplier_origin }})
                </p>
              </div>
              <div class="col-12 justify-content-center pt-3">
                <hr class="m-0" type="primary" />
              </div>
              <div class="col-9 justify-content-center pr-0">
                <p v-for="(dosage, index) in cmcAdjustment.dosage_locations" :key="index" class="pb-0 mb-0 pr-1 pt-1 text-success">
                  <b>{{ dosage.name }}</b>
                  <b v-if="dosage && dosage.code != 0" class="text-capitalize">({{ dosage.dosage_location }} {{ dosage.code }})</b>
                  <b v-if="dosage && dosage.code === 0" class="text-capitalize">{{ dosage.dosage_location }}</b>
                </p>

                <p v-if="!cmcAdjustment.dosage_locations || !cmcAdjustment.dosage_locations[0]" class="pb-0 mb-0 pr-1 pt-1">
                  <b>Nenhum informado</b>
                </p>
                <p class="small text-muted m-0 pt-1 pb-3">Locais de dosagem ({{ cmcAdjustment.measured_unit.initials }})</p>
              </div>
            </div>
            <!-- Ajustes -->
            <collapse class="border rounded p-0 mt-3">
              <collapse-item name="1" class="header-gray card-body-molde" back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0">Ajustes</h5>
                <div class="row">
                  <div class="col-md-12 justify-content-center">
                    <base-button
                      block
                      slot="title-container"
                      type="dark"
                      size="sm"
                      class="rounded"
                      outline
                      @click="resetAll(cmcAdjustment)"
                    >
                      zerar todas
                    </base-button>
                  </div>
                </div>

                <div class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="
                        cmcAdjustment.binder > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.binder < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                    >
                      aglomerante
                    </h5>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="4"
                        inputmode="numeric"
                        v-model="cmcAdjustment.binder"
                        v-on:input="
                          cmcAdjustment.binder = formatString($event.target.value, limit.binder.bottom, limit.binder.top, '', cmcAdjustment)
                        "
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(cmcAdjustment)"
                      v-model="cmcAdjustment.binder"
                      :connect="true"
                      :options="{ step: 1, range: { min: parseInt(limit.binder.bottom), max: parseInt(limit.binder.top) } }"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="
                        cmcAdjustment.water > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.water < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                    >
                      água
                    </h5>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="4"
                        v-on:input="
                          cmcAdjustment.water = formatString($event.target.value, limit.water.bottom, limit.water.top, '', cmcAdjustment)
                        "
                        inputmode="numeric"
                        v-model="cmcAdjustment.water"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> kg/m<sup>3</sup> </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(cmcAdjustment)"
                      v-model="cmcAdjustment.water"
                      :connect="true"
                      :options="{ step: 1, range: { min: parseInt(limit.water.bottom), max: parseInt(limit.water.top) } }"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>
                </div>

                <div class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="
                        cmcAdjustment.content_mortar > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.content_mortar < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                    >
                      teor de argamassa
                    </h5>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        maxlength="6"
                        v-on:input="
                          cmcAdjustment.content_mortar = formatString(
                            $event.target.value,
                            limit.content_mortar.bottom,
                            limit.content_mortar.top,
                            1,
                            cmcAdjustment
                          )
                        "
                        inputmode="numeric"
                        v-model="cmcAdjustment.content_mortar"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(cmcAdjustment)"
                      v-model="cmcAdjustment.content_mortar"
                      :connect="true"
                      :options="{
                        step: 0.1,
                        range: { min: parseInt(limit.content_mortar.bottom), max: parseInt(limit.content_mortar.top) },
                      }"
                      :decimals="1"
                    >
                    </base-slider>
                  </div>
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="
                        cmcAdjustment.total_air > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.total_air < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                    >
                      ar total
                    </h5>
                    <base-input input-group-classes=" input-group-sm">
                      <input
                        maxlength="6"
                        v-on:input="
                          cmcAdjustment.total_air = formatString(
                            $event.target.value,
                            limit.total_air.bottom,
                            limit.total_air.top,
                            1,
                            cmcAdjustment
                          )
                        "
                        inputmode="numeric"
                        v-model="cmcAdjustment.total_air"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(cmcAdjustment)"
                      v-model="cmcAdjustment.total_air"
                      :connect="true"
                      :options="{ step: 0.1, range: { min: parseInt(limit.total_air.bottom), max: parseInt(limit.total_air.top) } }"
                      :decimals="1"
                    >
                    </base-slider>
                  </div>
                </div>
                <!-- aditivo a -->
                <div v-if="cmcAdjustment.agent" class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="
                        cmcAdjustment.agent && cmcAdjustment.agent[0].value > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.agent && cmcAdjustment.agent[0].value < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                    >
                      Aditivo A
                    </h5>
                    <base-input input-classes="form-control-sm">
                      <el-select
                        @change="resetAgent(cmcAdjustment, 0)"
                        filterable
                        v-model="cmcAdjustment.agent[0].id"
                        size="mini"
                        placeholder="Selecione"
                      >
                        <el-option label="Selecione" value=""></el-option>
                        <el-option
                          v-if="!cmcAdjustment.agent || agent.id !== cmcAdjustment.agent[1].id"
                          v-for="agent in agentGroups"
                          :key="agent.id"
                          :label="agent.group_name"
                          :value="agent.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input v-if="cmcAdjustment.agent && cmcAdjustment.agent[0].id" input-group-classes="input-group-sm mt-3">
                      <input
                        maxlength="7"
                        v-on:input="
                          cmcAdjustment.agent[0].value = formatDecimals(
                            $event.target.value,
                            limit.agent.bottom,
                            limit.agent.top,
                            2,
                            cmcAdjustment
                          )
                        "
                        v-model="cmcAdjustment.agent[0].value"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      v-if="cmcAdjustment.agent[0].id"
                      @input="updatedAgent(cmcAdjustment, true)"
                      v-model="cmcAdjustment.agent[0].value"
                      :connect="true"
                      :options="{ step: 0.01, range: { min: parseInt(limit.agent.bottom), max: parseInt(limit.agent.top) } }"
                      :decimals="2"
                    >
                    </base-slider>
                  </div>

                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="
                        cmcAdjustment.agent && cmcAdjustment.agent[1].value > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.agent && cmcAdjustment.agent[1].value < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                    >
                      Aditivo B
                    </h5>
                    <base-input input-classes="form-control-sm">
                      <el-select
                        @change="resetAgent(cmcAdjustment, 1)"
                        filterable
                        v-model="cmcAdjustment.agent[1].id"
                        size="mini"
                        placeholder="Selecione"
                      >
                        <el-option label="Selecione" value=""></el-option>
                        <el-option
                          v-if="!cmcAdjustment.agent || agent.id !== cmcAdjustment.agent[0].id"
                          v-for="agent in agentGroups"
                          :key="agent.id"
                          :label="agent.group_name"
                          :value="agent.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input v-if="cmcAdjustment.agent && cmcAdjustment.agent[1].id" input-group-classes="input-group-sm mt-3">
                      <input
                        maxlength="7"
                        v-on:input="
                          cmcAdjustment.agent[1].value = formatDecimals(
                            $event.target.value,
                            limit.agent.bottom,
                            limit.agent.top,
                            2,
                            cmcAdjustment
                          )
                        "
                        v-model="cmcAdjustment.agent[1].value"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <div>
                      <base-slider
                        v-if="cmcAdjustment.agent[1].id"
                        @input="updatedAgent(cmcAdjustment, true)"
                        v-model="cmcAdjustment.agent[1].value"
                        :connect="true"
                        :options="{ step: 0.01, range: { min: parseInt(limit.agent.bottom), max: parseInt(limit.agent.top) } }"
                        :decimals="2"
                      >
                      </base-slider>
                    </div>
                  </div>
                </div>
                <!--Start Observation-->
                <div class="row">
                  <div class="col-md-12 pt-4">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="cmcAdjustment.observation"
                        type="text"
                        rows="4"
                        class="form-control form-control-sm"
                        v-on:input="forceUpdate(cmcAdjustment)"
                      >
                      </textarea>
                    </base-input>
                  </div>
                </div>
                <div v-if="cmcAdjustment.controls == 1" class="row">
                  <div class="col-md-6 mt-4">
                    <base-button block type="secondary" @click="restoreData(cmcAdjustment)">Cancelar</base-button>
                  </div>
                  <div class="col-md-6 mt-4">
                    <base-button block type="primary" @click="handleCmcAdjustmentUpdate(cmcAdjustment, index)">Salvar</base-button>
                  </div>
                </div>

                <!--Start Last Updated At-->
                <div class="row mt-4 justify-content-sm-center">
                  <div class="col-md-6 text-center">
                    <small>{{cmcAdjustment.updated_at | parseDate('DD MMM YYYY HH:mm:ss') }}</small>
                  </div>
                  <div class="col-md-6 text-center">
                    <small>{{ cmcAdjustment.user }}</small>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <collapse v-if="cmcAdjustment.content !== false" class="border rounded p-0 mt-3">
              <collapse-item name="1" class="header-gray card-body-molde" back-ground="border-header-warning-bottom">
                <h5 slot="title" class="mb-0">
                  Ajuste de teor
                  <i v-if="cmcAdjustment.content && cmcAdjustment.content != 0" class="fas fa-exclamation-triangle text-default pl-2"></i>
                </h5>
                <div class="row">
                  <div class="col-md-12 justify-content-center">
                    <base-button
                      block
                      slot="title-container"
                      type="dark"
                      size="sm"
                      class="rounded"
                      outline
                      @click="resetContent(cmcAdjustment)"
                    >
                      zerar todas
                    </base-button>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-12 pr-1">
                    <base-button slot="title-container" type="white" class="p-0 pr-1 pl-1 rounded m-0">
                      <i class="fas fa-info"></i>
                    </base-button>
                  </div>
                </div>

                <div class="row justify-content-md-center pt-3">
                  <div class="col-md-7 text-center">
                    <h5 :class="getContentCardClass(cmcAdjustment.content)">Teor do MCC</h5>
                    <base-input input-group-classes=" input-group-sm mt-3">
                      <input
                        maxlength="6"
                        v-on:input="cmcAdjustment.content = formatString($event.target.value, '-100', '100', '', cmcAdjustment, 1)"
                        inputmode="numeric"
                        v-model="cmcAdjustment.content"
                        @blur="checkContentLimits(cmcAdjustment)"
                        class="form-control form-control-sm"
                        :class="cmcAdjustment.content > 0 ? 'is-valid' : cmcAdjustment.content < 0 ? 'is-invalid' : ''"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(cmcAdjustment, true, 1)"
                      v-model="cmcAdjustment.content"
                      :connect="true"
                      :options="{ step: 1, range: { min: -100, max: 100 } }"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>
                </div>
                <div class="row justify-content-md-center">
                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="
                        cmcAdjustment.content_min && cmcAdjustment.content_min > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.content_min && cmcAdjustment.content_min < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                    >
                      Limitar teor mínimo
                    </h5>
                    <base-input input-group-classes="input-group-sm mt-3">
                      <input
                        maxlength="6"
                        v-on:input="cmcAdjustment.content_min = formatString($event.target.value, '0', '100', '', cmcAdjustment, 1)"
                        v-model="cmcAdjustment.content_min"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(cmcAdjustment, true, 1)"
                      v-model="cmcAdjustment.content_min"
                      :connect="true"
                      :options="{ step: 1, range: { min: 0, max: 100 } }"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>

                  <div class="col-md-6 text-center pt-2">
                    <h5
                      :class="
                        cmcAdjustment.content_max && cmcAdjustment.content_max > 0
                          ? 'text-uppercase text-success'
                          : cmcAdjustment.content_max && cmcAdjustment.content_max < 0
                          ? 'text-uppercase text-danger'
                          : 'text-uppercase text-light'
                      "
                    >
                      Limitar teor máximo
                    </h5>
                    <base-input input-group-classes="input-group-sm mt-3">
                      <input
                        maxlength="6"
                        v-on:input="cmcAdjustment.content_max = formatString($event.target.value, '0', '100', '', cmcAdjustment, 1)"
                        v-model="cmcAdjustment.content_max"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> % </small>
                      </template>
                    </base-input>
                    <base-slider
                      @input="updatedAgent(cmcAdjustment, true, 1)"
                      v-model="cmcAdjustment.content_max"
                      :connect="true"
                      :options="{ step: 1, range: { min: 0, max: 100 } }"
                      :decimals="0"
                    >
                    </base-slider>
                  </div>
                </div>
                <!--Start Observation-->
                <div class="row">
                  <div class="col-md-12 pt-4">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="cmcAdjustment.observation_content"
                        type="text"
                        rows="4"
                        class="form-control form-control-sm"
                        v-on:input="forceUpdate(cmcAdjustment, 1)"
                      >
                      </textarea>
                    </base-input>
                  </div>
                </div>
                <div v-if="cmcAdjustment.content_controls === 1" class="row">
                  <div class="col-md-6 mt-4">
                    <base-button block type="secondary" @click="restoreContentData(cmcAdjustment)">Cancelar</base-button>
                  </div>
                  <div class="col-md-6 mt-4">
                    <base-button block type="primary" @click="handleCmcAdjustmentUpdate(cmcAdjustment, index)">Salvar</base-button>
                  </div>
                </div>
                <!--Start Last Updated At-->
                <div class="row mt-4 justify-content-sm-center">
                  <div class="col-md-6 text-center">
                    <small>{{ cmcAdjustment.updated_at }}</small>
                  </div>
                  <div class="col-md-6 text-center">
                    <small>{{ cmcAdjustment.user }}</small>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
    </div>
    <LoadingPagination :show="loading && !loadingSkeleton" />
    <modal-edit-cmc-adjustment @updatedCmcAdjustment="handleInit" ref="editCmc"></modal-edit-cmc-adjustment>
    <modal-reference ref="modal-reference"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import modalEditCmcAdjustment from "./_ModalEditCmcAdjustment";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { checkNumberValue } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import adjustmentMixin from "@/views/Modules/Technology/Adjustment/Mixins/adjustment";
import ModalReference from './_ModalReference'
import list from './mixins/list'
export default {
  name: "ListCmcAdjustments",
  mixins: [cursorPaginate, adjustmentMixin, list],
  components: {
    SkeletonPuzlGrid,
    ModalReference,
    modalEditCmcAdjustment,
    MultiFilter,
    LoadingPagination,
    PuzlEmptyData,
  },
  computed: {
    ...mapGetters({
      cmcAdjustments: "adjustmentCmc/fetch",
      groups: "cmcGroup/showGroupByCategory",
    }),
  },
  methods: {
    init(filter = null) {
      this.initCmc(filter)
    }
  }
};
</script>

<style scoped></style>
