<template>
  <div>
    <modal size="xl" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div v-if="modal">
        <div class="p-2">
          <!-- Cliente -->
          <div v-if="$_contract_proposal && $_contract_proposal.entity" class="checklist-item checklist-item-success d-flex">
            <div class="row w-100 ml-0">
              <div class="col-6">
                <h3 class="mb-0">{{ $_contract_proposal.entity.entity_name }}</h3>
                <h3 class="mb-0">{{ $_contract_proposal.entity.document }}</h3>
              </div>
              <div class="col-6 pr-md-0 text-right">
                <img
                  @click.prevent="getExcelReport"
                  role="button"
                  class="mb-0"
                  src="/img/icons/excel.png"
                  width="35"
                />
                <base-button
                  v-if="$hasPrivilege(1)"
                  @click="handleCopyAnticipationBalanceTestToAnticipationBalance"
                  class="button-text active ml-4"
                  type="primary"
                  outline
                >
                  Copiar
                </base-button>
                <base-button
                  v-if="$hasPrivilege(1)"
                  @click="handleRecalculate"
                  class="button-text active ml-3"
                  type="primary"
                  outline
                >
                  Recalcular
                </base-button>
              </div>
            </div>
          </div>
          <div class="mt-3 card mb-2">
            <div class="card-body">
              <div class="row align-items-center">
                <div style="margin-top: 0.15rem !important" class="col-md-6 pr-2 pl-2 mb-2 mb-md-0">
                  <base-input input-group-classes="input-group-sm">
                    <puzl-select
                      label="$code$ | $construction_name$ | $customer_name$"
                      :labelMask="true"
                      :loading="loading"
                      :disabled="loading"
                      :items="contract_proposals"
                      @input="init"
                      v-model="filter.contract_proposal_ids"
                      :multiple="true"
                      placeholder="Contratos"
                    />
                  </base-input>
                </div>
                <div class="col-md-2 pr-2 pl-2 border-right mb-2 mb-md-0">
                  <div class="pr-3 pl-3">
                    <div class="row">
                      <div class="col-12 text-center">
                        <h5 class="card-title text-uppercase mb-0">Total Realizado</h5>
                        <span class="numeric font-weight-600"> {{ $_history.total_performed_without_retentions | currency }} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pr-2 pl-2 border-right mb-2 mb-md-0">
                  <div class="pr-3 pl-3">
                    <div class="row">
                      <div class="col-12 text-center">
                        <h5 class="card-title text-uppercase mb-0">Total Líquido</h5>
                        <span class="numeric font-weight-600"> {{ $_history.total_performed | currency }} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pr-2 pl-2">
                  <div class="pr-3 pl-3">
                    <div class="row">
                      <div class="col-12 text-center">
                        <h5 class="card-title text-uppercase mb-0">Retenções</h5>
                        <span class="numeric font-weight-600"> {{ $_history.iss_retain | currency }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Saldos -->
        <div class="row m-0">
          <div class="col-md-3 pr-2 pl-2 pt-2 pb-0">
            <div class="card card-stats mb-md-0">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="card-title text-uppercase mb-0">Saldo Total</h5>
                    <span class="numeric font-weight-600" :class="$_history.net_total_balance < 0 ? 'text-danger' : 'text-success'">
                      {{ $_history.net_total_balance | currency }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets-sm col-md-3 pr-2 pl-2 pt-2 pb-0">
            <div class="card card-stats mb-md-0">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="card-title text-uppercase text-indigo mb-0">Saldo de Antecipação</h5>
                    <span class="numeric font-weight-600 mr-1"> {{ $_history.anticipation_balance | currency }} </span>
                    <base-button v-if="$hasPermission('bill_receive.anticipation_balance_transfer')" class="p-0" @click.prevent="handleModalAnticipationBalanceTransfer" size="sm" type="indigo">
                      <i style="font-size: 12px; padding: 6px 5px" class="fas fa-exchange-alt text-center"></i>
                    </base-button>
                    <span v-if="$hasPrivilege(1)" class="numeric font-weight-600 mr-1"> {{ $_history.anticipation_balance_test | currency }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets-sm col-md-3 pr-2 pl-2 pt-2 pb-0">
            <div class="card card-stats mb-md-0">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="card-title text-uppercase mb-0">Liberação de Crédito</h5>
                    <span class="numeric font-weight-600 mr-1"> {{ $_history.free_credit | currency }} </span>
                    <base-button @click.prevent="handleModalCreditLimit" id="add-credit-button" size="sm" type="success">
                      <i class="ni ni-fat-add"></i>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets-sm col-md-3 pr-2 pl-2 pt-2 pb-0">
            <div class="card card-stats mb-0">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="card-title text-uppercase mb-0 text-danger">Devolução de Receita</h5>
                    <span class="numeric font-weight-600"> {{ (($_history.recipe_devolution || 0) * -1) | currency }} </span>
                    <base-button
                      v-if="source_contract_proposal_id"
                      :disabled="loading"
                      @click.prevent="$router.push(`/configuration/entry-launch/contract-proposal/${source_contract_proposal_id}`)"
                      id="add-credit-button"
                      size="sm"
                      type="success"
                      class="ml-1"
                    >
                      <i class="ni ni-fat-add"></i>
                    </base-button>
                    <base-button
                      v-else-if="$_contract_proposal && $_contract_proposal.entity"
                      :disabled="loading"
                      @click.prevent="$router.push(`/configuration/entry-launch/entity/${$_contract_proposal.entity.uuid}`)"
                      id="add-credit-button"
                      size="sm"
                      type="success"
                      class="ml-1"
                    >
                      <i class="ni ni-fat-add"></i>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-md-3 pr-2 pl-2 pt-2 pb-0">
            <div class="card card-stats mb-md-0">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="card-title text-uppercase text-success mb-0">Recebido</h5>
                    <span class="numeric font-weight-600"> {{ $_history.bill_receives.total_paid | currency }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets-sm col-md-3 pr-2 pl-2 pt-2 pb-0">
            <div class="card card-stats mb-md-0">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="card-title text-uppercase text-light mb-0">A faturar</h5>
                    <span class="numeric font-weight-600"> {{ $_history.opened_billing | currency }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets-sm col-md-3 pr-2 pl-2 pt-2 pb-0">
            <div class="card card-stats mb-md-0">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="card-title text-uppercase text-warning mb-0">Pendente</h5>
                    <span class="numeric font-weight-600"> {{ $_history.bill_receives.total_pending | currency }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widgets-sm col-md-3 pr-2 pl-2 pt-2 pb-0">
            <div class="card card-stats mb-md-0">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-12 text-center">
                    <h5 class="card-title text-uppercase text-danger mb-0">Atrasado</h5>
                    <span class="numeric font-weight-600"> {{ $_history.bill_receives.total_overdue | currency }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Botões -->
        <div class="row m-0 mt-5 mb-2">
          <div class="col-md-3 pr-2 pl-2 mb-2 mb-md-0">
            <base-button
              @click.prevent="handleChangeTableType('entity_anticipation_balance_history')"
              :class="{ active: table_type === 'entity_anticipation_balance_history' }"
              class="button-text"
              type="primary"
              outline
              block
            >
              MOVIMENTAÇÕES
            </base-button>
          </div>
          <div class="col-md-3 pr-2 pl-2">
            <base-button
              @click.prevent="handleChangeTableType('entity_credit_history')"
              :class="{ active: table_type === 'entity_credit_history' }"
              class="button-text"
              type="primary"
              outline
              block
            >
              LIBERAÇÃO DE CRÉDITO
            </base-button>
          </div>
          <div class="col-md-3 pr-2 pl-2">
            <base-button
              @click.prevent="handleChangeTableType('recipe_devolution')"
              :class="{ active: table_type === 'recipe_devolution' }"
              class="button-text"
              type="primary"
              outline
              block
            >
              DEVOLUÇÃO
            </base-button>
          </div>
          <div class="col-md-3 pr-2 pl-2">
            <base-button
              @click.prevent="handleChangeTableType('anticipation_transfer')"
              :class="{ active: table_type === 'anticipation_transfer' }"
              class="button-text"
              type="primary"
              outline
              block
            >
              TRANSFERÊNCIAS
            </base-button>
          </div>
        </div>
        <!-- Tabela de liberação de crédito -->
        <div v-show="table_type === 'entity_credit_history'" class="row m-0 pt-0 card-row">
          <div class="col-md-12 p-2">
            <div class="card">
              <div class="card-body mt-n2">
                <div class="row">
                  <div class="col-md-12 table-responsive">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr class="fixed">
                          <th class="text-center">
                            <span>Data</span>
                          </th>
                          <th class="text-center">
                            <span>Valido até</span>
                          </th>
                          <th class="text-center">
                            <span>Observações</span>
                          </th>
                          <th class="text-center">
                            <span>Valor</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="loadingSkeleton">
                          <tr v-for="(item, index) in 10" :key="index">
                            <th colspan="12">
                              <skeleton-puzl type="button" />
                            </th>
                          </tr>
                        </template>
                        <template v-if="!loadingSkeleton">
                          <tr v-for="(item, index) in $_credit_history" :key="index">
                            <th class="text-left">
                              <span class="style-table">
                                {{ item.created_at | parseDate("DD MMM YYYY HH:MM") }}
                                <span class="style-table">
                                  <el-popover trigger="hover" placement="bottom" class="p-0">
                                    <span> {{ item.user_name }} </span>
                                    <i slot="reference" class="fa-solid fa-user text-sucess ml-1" />
                                  </el-popover>
                                </span>
                              </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table">
                                {{ item.due_date | parseDate("DD MMM YYYY") }}
                              </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table"> {{ item.observation }} </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table numeric font-weight-bold"> {{ item.credit | currency }} </span>
                            </th>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <PuzlEmptyData v-if="!$_credit_history.length && !loadingSkeleton" />
                  </div>
                </div>
                <pagination @navegate="creditHistoryNavegate" :source="source" :loading="loadingSkeleton" />
              </div>
            </div>
          </div>
        </div>
        <!-- Tabela de antecipação -->
        <div v-show="table_type === 'entity_anticipation_balance_history'" class="row m-0 pt-0 card-row">
          <div class="col-md-12 p-2">
            <div class="card">
              <div class="card-body mt-n2">
                <div class="row">
                  <div class="col-md-12 table-responsive">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr class="fixed">
                          <th class="text-center">
                            <span>Data</span>
                          </th>
                          <th class="text-center">
                            <span>Origem</span>
                          </th>
                          <th class="text-center">
                            <span>Descrição</span>
                          </th>
                          <th class="text-center">
                            <span>Observações</span>
                          </th>
                          <th class="text-center">
                            <span>Movimento</span>
                          </th>
                          <th class="text-center">
                            <span>Valor</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="loadingSkeleton">
                          <tr v-for="(item, index) in 10" :key="index">
                            <th colspan="12">
                              <skeleton-puzl type="button" />
                            </th>
                          </tr>
                        </template>
                        <template v-if="!loadingSkeleton">
                          <tr v-for="(item, index) in $_entity_anticipation_balance_histories" :key="index">
                            <th class="text-left">
                              <span class="style-table">
                                {{ item.operation_date | parseDate("DD/MMM") }}
                                <span class="style-table">
                                  <el-popover trigger="hover" placement="bottom" class="p-0">
                                    <span> {{ item.user_name }} </span>
                                    <i slot="reference" class="fa-solid fa-user text-sucess ml-1" />
                                  </el-popover>
                                </span>
                              </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table">
                                {{ item.origin }}
                              </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table"> {{ item.description }}</span>
                            </th>
                            <th class="text-left">
                              <span class="style-table numeric font-weight-bold"> {{ item.observations }} </span>
                            </th>
                            <th class="text-left">
                              <span :class="`text-${ENTITY_ANTICIPATION_BALANCE_HISTORY_TYPE_ENUM[item.type].color}`" class="style-table numeric font-weight-bold"> {{ ENTITY_ANTICIPATION_BALANCE_HISTORY_TYPE_ENUM[item.type].name }} </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table numeric font-weight-bold"> {{ item.value | currency }} </span>
                            </th>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <PuzlEmptyData v-if="!$_entity_anticipation_balance_histories.length && !loadingSkeleton" />
                  </div>
                </div>
                <NewPagination @navegate="navegate" :source="source" :loading="loadingSkeleton" />
              </div>
            </div>
          </div>
        </div>
        <!-- Tabela de devolução -->
        <div v-show="table_type === 'recipe_devolution'" class="row m-0 pt-0 card-row">
          <div class="col-md-12 p-2">
            <div class="card">
              <div class="card-body mt-n2">
                <div class="row">
                  <div class="col-md-12 table-responsive">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr class="fixed">
                          <th class="text-center">
                            <span>Data</span>
                          </th>
                          <th class="text-center">
                            <span>Pagamento</span>
                          </th>
                          <th class="text-center">
                            <span>Observações</span>
                          </th>
                          <th class="text-center">
                            <span>Valor</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="loadingSkeleton">
                          <tr v-for="(item, index) in 10" :key="index">
                            <th colspan="12">
                              <skeleton-puzl type="button" />
                            </th>
                          </tr>
                        </template>
                        <template v-if="!loadingSkeleton">
                          <tr v-for="(item, index) in $_recipe_devolution_items" :key="index">
                            <th class="text-left">
                              <span class="style-table">
                                {{ item.entry_date | parseDate("DD/MMM") }}
                                <span class="style-table">
                                  <el-popover trigger="hover" placement="bottom" class="p-0">
                                    <span> {{ item.user_name }} </span>
                                    <i slot="reference" class="fa-solid fa-user text-sucess ml-1" />
                                  </el-popover>
                                </span>
                              </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table">
                                <template v-if="item.operation_source_is_generate_financial">
                                  {{ item.payment_method_name }}
                                  {{
                                    `| ${item.bill_pay_installments_quantity} Parcela${item.bill_pay_installments_quantity > 1 ? "s" : ""}`
                                  }}
                                </template>
                                <template v-else> Sem financeiro </template>
                                <el-popover v-if="item.operation_source_id" trigger="hover" placement="bottom" class="p-0 pr-0">
                                  <div class="border-left border-success border-3 p-2 pb-0 mb-3">
                                    <h4 class="mb-0">{{ item.operation_source_name }}</h4>
                                  </div>
                                  <h5 class="mb-0 font-weight-normal">
                                    <i v-if="item.operation_source_is_generate_financial" class="fas fa-check text-success" />
                                    <i v-else class="fas fa-ban text-danger" />
                                    Gera Financeiro
                                  </h5>
                                  <h5 class="mb-1 font-weight-normal">
                                    <i v-if="item.operation_source_is_generate_stock" class="fas fa-check text-success" />
                                    <i v-else class="fas fa-ban text-danger" />
                                    Movimenta Estoque
                                  </h5>
                                  <h5 class="mb-1 font-weight-normal">
                                    <i v-if="item.operation_source_is_entry_value" class="fas fa-check text-success" />
                                    <i v-else class="fas fa-ban text-danger" />
                                    Pagamento Antecipado
                                  </h5>
                                  <h5 class="mb-1 font-weight-normal">
                                    <i v-if="item.operation_source_is_computable" class="fas fa-check text-success" />
                                    <i v-else class="fas fa-ban text-danger" />
                                    Contabiliza
                                  </h5>
                                  <h5 class="mb-1 font-weight-normal">
                                    <i v-if="item.operation_source_is_generate_tax_book" class="fas fa-check text-success" />
                                    <i v-else class="fas fa-ban text-danger" />
                                    Gera Livro Fiscal
                                  </h5>
                                  <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                                    <i style="font-size: 20px !important" class="fas fa-puzzle-piece text-center text-indigo" />
                                  </base-button>
                                </el-popover>
                              </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table numeric font-weight-bold"> {{ item.observations }} </span>
                            </th>
                            <th class="text-left numeric font-weight-bold">
                              <span class="style-table numeric font-weight-bold text-danger">
                                {{
                                  ((item.operation_source_is_generate_financial
                                    ? item.bill_pay_installments_final_value
                                    : item.final_value) *
                                    -1)
                                    | currency
                                }}
                              </span>
                            </th>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <PuzlEmptyData v-if="!$_recipe_devolution_items.length && !loadingSkeleton" />
                  </div>
                </div>
                <pagination @navegate="recipeDevolutionNavegate" :source="source" :loading="loadingSkeleton" />
              </div>
            </div>
          </div>
        </div>
        <!-- Tabela de transferências -->
        <div v-show="table_type === 'anticipation_transfer'" class="row m-0 pt-0 card-row">
          <div class="col-md-12 p-2">
            <div class="card">
              <div class="card-body mt-n2">
                <div class="row">
                  <div class="col-md-12 table-responsive">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr class="fixed">
                          <th class="text-center">
                            <span>Data</span>
                          </th>
                          <th class="text-center">
                            <span>Status</span>
                          </th>
                          <th class="text-center">
                            <span>Cliente</span>
                          </th>
                          <th class="text-center">
                            <span>Observações</span>
                          </th>
                          <th class="text-center">
                            <span>Valor</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="loadingSkeleton">
                          <tr v-for="(item, index) in 10" :key="index">
                            <th colspan="12">
                              <skeleton-puzl type="button" />
                            </th>
                          </tr>
                        </template>
                        <template v-if="!loadingSkeleton">
                          <tr v-for="(item, index) in $_anticipation_balance_transfers" :key="index">
                            <th class="text-left">
                              <span class="style-table">
                                {{ item.created_at | parseDate("DD/MMM") }}
                                <span class="style-table">
                                  <el-popover trigger="hover" placement="bottom" class="p-0">
                                    <span> {{ item.user_name }} </span>
                                    <i slot="reference" class="fa-solid fa-user text-sucess ml-1" />
                                  </el-popover>
                                </span>
                              </span>
                            </th>
                            <th class="text-left">
                              <span v-if="item.type === 'target'" class="style-table text-success"> Recebido de </span>
                              <span v-else class="style-table text-danger"> Transferido para </span>
                            </th>
                            <th class="text-left">
                              <span v-if="item.type === 'target'" class="style-table">
                                {{ `${item.from_entity_name} | ${item.from_document}` }}
                              </span>
                              <span v-else class="style-table">
                                {{ `${item.to_entity_name} | ${item.to_document}` }}
                              </span>
                            </th>
                            <th class="text-left">
                              <span class="style-table"> {{ item.observations }} </span>
                            </th>
                            <th class="text-left numeric font-weight-bold">
                              <span v-if="item.type === 'target'" class="style-table text-success">
                                {{ item.value | currency }}
                              </span>
                              <span v-else class="style-table text-danger">
                                {{ (item.value * -1) | currency }}
                              </span>
                            </th>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <PuzlEmptyData v-if="!$_anticipation_balance_transfers.length && !loadingSkeleton" />
                  </div>
                </div>
                <pagination @navegate="anticipationBalanceNavegate" :source="source" :loading="loadingSkeleton" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center mt-0">
          <base-button type="secondary" @click="closeModal()">Fechar</base-button>
        </div>
      </div>
    </modal>
    <ModalCreditLimit @updated="init" ref="modalCreditLimit" />
    <ModalAnticipationBalanceTransfer
      @updated="init"
      @updatedAfterAnticipationBalanceTransfer="updateAfterAnticipationBalanceTransfer"
      ref="modalAnticipationBalanceTransfer" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import { PuzlEmptyData } from "@/components";
import NewPagination from "@/components/Utils/PaginationV2";
import Pagination from "@/components/Utils/Pagination";
import { SkeletonPuzl } from "@/components";
import ModalCreditLimit from "./_ModalCreditLimit";
import ModalAnticipationBalanceTransfer from "./_ModalAnticipationBalanceTransfer";

const ENTITY_ANTICIPATION_BALANCE_HISTORY_TYPE_ENUM = Object.freeze({
  '0': {
    name: 'Débito',
    color: 'danger',
  },
  '1': {
    name: 'Crédito',
    color: 'success',
  },
  '2': {
    name: 'Estorno',
    color: 'danger',
  },
  '3': {
    name: 'Estorno',
    color: 'success',
  },
});

export default {
  components: { PuzlSelect, PuzlEmptyData, Pagination, SkeletonPuzl, ModalCreditLimit, ModalAnticipationBalanceTransfer, NewPagination },
  name: "ModalEntityHistory",
  data() {
    return {
      modal: false,
      title: "Histórico de Cliente",
      loading: true,
      filter: {
        contract_proposal_ids: [],
      },
      id: null,
      loadingSkeleton: false,
      params: {
        page: 1,
      },
      source: null,
      billReceiveStatus: {
        10: "Estornado",
        8: "Aprovado",
        1: "Recebido",
        3: "Cancelado",
      },
      typeColors: {
        neutral: "light",
        negative: "danger",
        approved: "approved",
        positive: "success",
      },
      table_type: null,
      contract_proposals: [],
      source_contract_proposal_id: null,
      ENTITY_ANTICIPATION_BALANCE_HISTORY_TYPE_ENUM,
    };
  },
  computed: {
    ...mapGetters({
      $_history: "entityHistory/getHistory",
      $_entity_anticipation_balance_histories: "entityAnticipationBalanceHistory/fetch",
      $_recipe_devolution_items: "entryLaunch/fetch",
      $_anticipation_balance_transfers: "entityAnticipationBalanceTransfer/fetch",
      $_contract_proposal: "contractProposal/show",
      $_credit_history: "entityCredit/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    /**
     * @param {number} id
     * @param {object} entity
     * @param {number} contract_proposal_id
     */
    async openModal(id, entity = false, contract_proposal_id = null) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.loading = true;
      this.id = id;
      this.source_contract_proposal_id = contract_proposal_id ? contract_proposal_id : this.$_contract_proposal.id;
      this.params.page = 1;
      this.filter.contract_proposal_ids = [];
      this.contract_proposals = [];
      if (entity) {
        this.$_contract_proposal.entity = entity;
      }
      await this.init();
      this.modal = true;
      this.$store.dispatch("contractProposal/getAllByEntity", { entity_id: this.id, manipulate_state: false }).then((response) => {
        this.contract_proposals = response.data;
        this.loading = false;
      });
      this.getEntityAnticipationBalanceHistory();
    },

    init() {
      let loader = this.$loading.show();
      this.table_type = "entity_anticipation_balance_history";
      return this.$store
        .dispatch("entityHistory/getHistory", {
          filter: this.filter,
          id: this.id,
        })
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          loader.hide();
        });
    },

    getRecipeDevolutionItems() {
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("entryLaunch/getRecipeDevolutionByEntity", {
          id: this.id,
          page: this.params.page,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        });
    },

    /**
     * Retorna o tipo do item, considerando como neutro caso não seja o último registro
     *
     * @param {Object} item
     * @returns {String}
     */
    getType(item) {
      switch (true) {
        case item.type === "entity_bill_receive_history" && item.status === 8 && item.id !== item.last_id:
          return "neutral";
        case item.type === "entity_bill_receive_history" && [10, 3].includes(item.status):
        case item.type === "bill_receive_history" && item.status === 11:
          return "negative";
        case item.type === "entity_bill_receive_history" && item.status === 8:
          return "approved";
        default:
          return "positive";
      }
    },

    navegate(page) {
      this.params.page = page;
      this.getEntityAnticipationBalanceHistory();
    },

    handleModalCreditLimit() {
      this.$refs.modalCreditLimit.openModal(this.$_contract_proposal.entity.id, this.$_contract_proposal.entity.document.length === 18);
    },

    /**
     * Abre modal de transferência de saldo de antecipação
     *
     * @returns void
     */
    handleModalAnticipationBalanceTransfer() {
      this.$refs.modalAnticipationBalanceTransfer.openModal(this.$_contract_proposal.entity.uuid);
    },

    getCreditHistory() {
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("entityCredit/getHistory", {
          id: this.id,
          page: this.params.page,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        });
    },

    getEntityAnticipationBalanceTransfers() {
      this.loadingSkeleton = true;
      return this.$store
        .dispatch("entityAnticipationBalanceTransfer/fetch", {
          id: this.id,
          page: this.params.page,
        })
        .then((response) => {
          this.source = response;
          this.loadingSkeleton = false;
        });
    },

    getEntityAnticipationBalanceHistory() {
      this.loadingSkeleton = true;
      const params = {
        id: this.id,
        page: this.params.page,
        order_by: [
          {
            column: "entity_anticipation_balance_histories.operation_date",
            is_desc: true,
          },
        ],
        limit: 10,
        paginate_type: 2 // simplePaginate
      }
      return this.$store
        .dispatch("entityAnticipationBalanceHistory/fetch", params)
        .then((response) => {
          this.source = response.data;
          this.loadingSkeleton = false;
        });
    },

    creditHistoryNavegate(page) {
      this.params.page = page;
      this.getCreditHistory();
    },

    anticipationBalanceNavegate(page) {
      this.params.page = page;
      this.getEntityAnticipationBalanceTransfers();
    },

    recipeDevolutionNavegate(page) {
      this.params.page = page;
      this.getRecipeDevolutionItems();
    },

    /**
     * Dispara consulta para tabela de acordo com o tipo selecionado
     *
     * @param {string} type
     * @returns void
     */
    handleChangeTableType(type) {
      if (this.table_type === type) return;
      this.table_type = type;
      this.params.page = 1;
      switch (type) {
        case "entity_anticipation_balance_history":
          this.getEntityAnticipationBalanceHistory();
          break;
        case "recipe_devolution":
          this.getRecipeDevolutionItems();
          break;
        case "entity_credit_history":
          this.getCreditHistory();
          break;
        case "anticipation_transfer":
          this.getEntityAnticipationBalanceTransfers();
          break;
      }
    },
    /**
     * Dados do cadastro após a transferência de saldo de antecipação entre clientes/fornecedores (entity),
     * que serão capturados em contas a receber.
     * @param {number} from_entity_id
     * @param {number} to_entity_id
     */
    updateAfterAnticipationBalanceTransfer(from_entity_id, to_entity_id) {
      this.$emit("updatedAfterAnticipationBalanceTransfer", from_entity_id, to_entity_id);
    },

    handleRecalculate() {
      const loader = this.$loading.show();
      return this.$store
        .dispatch("entityAnticipationBalanceHistory/recalculate", this.id)
        .finally(() => {
          loader.hide();
          this.closeModal();
        });
    },

    handleCopyAnticipationBalanceTestToAnticipationBalance() {
      const loader = this.$loading.show();
      return this.$store
        .dispatch("entityAnticipationBalanceHistory/copyAnticipationBalanceTestToAnticipationBalance", this.id)
        .finally(() => {
          loader.hide();
          this.closeModal();
        });
    },

    getExcelReport() {
      const loader = this.$loading.show();
      this.$store
        .dispatch("entityAnticipationBalanceHistory/getExcelReport", this.id)
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Movimentações.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch(() => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.style-table {
  font-weight: 400;
  width: 15px;
}

.text-approved {
  color: rgb(79, 64, 255);
}

.text-light {
  color: #838383;
}
</style>
