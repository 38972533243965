export const removeNullable = (objects) => {
  let values = {...objects}
  for (let index in values) {
    for (let value in values[index]) {
      !values[index][value] && delete values[index][value]
    }
    if(typeof values[index] === "object" && values[index] != null)
    {
      Object.keys(values[index]).length === 0 && delete values[index]
    }
    else if (!values[index]){
      delete values[index]
    }
  }
  return values
}

export function isEmpty(obj) {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }

  return true;
}

export function isEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}
