<template>
  <div>
    <modal :show.sync="modal" data-class="programming-weighing" size="md-2">
      <template slot="header">
        <h5 class="modal-title"><img src="/img/icons/create-new.png" width="25" class="mt-n2"> <span>{{ title }}</span>
          <small>(Previsto)</small></h5>
      </template>
      <div v-if="service">
        <div class="border-3 p-2 mb-4">
          <div class="row">
            <div class="col-md-12 mb-3 info-section">
              <img src="/img/icons/icons8/ios/concrete-pump.png" width="20" height="20" style="margin-right:1.5%;">
              <strong class="mb-3">Tipo</strong>
              <div class="pzl-container-divider">
                <div></div>
              </div>
            </div>
            <div class="col-md-6 ">
              {{ service.service_name }}
              <br/>
              <small>{{ service.service_description }} </small>
            </div>
            <div class="col-md-12 my-auto text-center" style="margin-top: 10px !important;">
              <div class="cancel-service-btn"
                :class="{ 'disabled': loading || service.status === 3 }"
                @click.prevent="cancelService(service.uuid)"
              >
                <img src="/img/icons/icons8/ios/unavailable_danger.png" width="20" height="20"
                     style="margin-right:1.5%;">
                <span v-if="service.status !== 3">Cancelar serviço</span>
                <span v-else-if="service.status === 3">Cancelado</span>
              </div>
            </div>
          </div>
          <validation-observer
            v-slot="{ invalid, handleSubmit }"
            ref="formValidator"
          >
            <form
              class="needs-validation"
              ref="myForm"
              @submit.prevent="handleSubmit(save)"
              autocomplete="off"
            >
              <div>
                <div v-if="plants.length" class="form-group row m-0 p-0 mt-4">
                  <div class="col-md-12 mb-3 mt-3 info-section">
                    <img src="/img/icons/icons8/ios/info-squared.png" width="20" height="20" style="margin-right:1.5%;">
                    <strong class="mb-3">Informação</strong>
                    <div class="pzl-container-divider">
                      <div></div>
                    </div>
                  </div>
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label mb-1"
                  >
                    Central
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4 mb-1">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <el-select
                          @change="changePlant"
                          v-model="service.company_plant_uuid"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loading || service.status !== 0 && service.status !== 5"
                        >
                          <el-option
                            v-for="plant in plants"
                            :key="plant.company_plant_uuid"
                            :label="plant.company_plant_name"
                            :value="plant.company_plant_uuid"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
                  >
                    Cnpj emissor
                    <template v-if="company_plant_fixed_issuer_not_found">
                      <el-popover trigger="click"
                                  placement="right"
                      >
                        <h5 class="font-weight-normal m-0">CNPJ Emissor fixo não encontrado para central
                          selecionada.</h5>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="shadow-none text-indigo">
                          <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                        </base-button>
                      </el-popover>
                    </template>
                    <template v-else>
                      <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                    </template>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model="service.company_plant_issuer_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingPlantIssuer || !service.company_plant_uuid || service.was_billed || contract_proposal.issuer_fixed">
                        <el-option
                          v-for="issuer in $_company_plant_issuers"
                          :key="issuer.id"
                          :label="issuer.business_name"
                          :value="issuer.id"
                        >
                        </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                  >
                    Início previsto
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker
                        :disabled="loading || service.status !== 0 && service.status !== 5"
                        @input="timeCalc"
                        v-model="service.start_at"
                      />
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                  >
                    Fim previsto
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker
                        :disabled="loading || service.status !== 0 && service.status !== 5"
                        @input="timeCalc"
                        v-model="service.end_at"
                      />
                    </base-input>
                  </div>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pt-1 pb-1 col-form-label form-control-label"
                >
                  Tempo previsto
                </label>
                <div class="col-md-8 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="hours"
                      disabled
                      type="text"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        horas
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
                >
                  Volume previsto
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-8 pr-4">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-on:input="
                      service.volume = formatString(
                        $event.target.value,
                        '0',
                        '999.9',
                        '1'
                      )
                    "
                      v-model="service.volume"
                      type="text"
                      :disabled="loading || service.status !== 0 && service.status !== 5"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        m<sup>3</sup>
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div v-show="!bySeller" class="form-group row m-0 p-0 mb-1">
                <div class="col-md-12 mb-3 mt-3 info-section">
                  <img src="/img/icons/icons8/ios/administrative-tools_primary.png" width="20" height="20"
                       style="margin-right:1.5%;">
                  <strong class="mb-3">Configuração</strong>
                  <div class="pzl-container-divider">
                    <div></div>
                  </div>
                </div>
                <label
                  class="col-md-4 pb-1 pt-1 col-form-label form-control-label"
                >
                  Equipamento <span v-show="service.order_uuid" class="text-danger">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-8 pr-4">
                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="service.equipment_uuid"
                        size="mini"
                        :disabled="loading || service.status ===3"
                        placeholder="Selecione"
                        filterable
                        :loading="loadingEquipment"
                      >
                        <el-option label="Selecione" :value="null"></el-option>
                        <el-option
                          v-for="equipment in equipments"
                          :key="equipment.uuid"
                          :label="equipment.code + ' - ' + equipment.plate"
                          :value="equipment.uuid"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div v-show="!bySeller" class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
                >
                  Responsáveis <span v-show="service.order_uuid" class="text-danger">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-8 pr-4">
                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="service.employers"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        multiple
                        :disabled="loading || service.status ===3"
                        :loading="loadingEquipment"
                      >
                        <el-option
                          v-for="employer in users"
                          :key="employer.uuid"
                          :label="employer.name"
                          :value="employer.user_uuid"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Observaçoes -->
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pt-1 pb-0 col-form-label form-control-label"
                >
                  Observações
                </label>
                <div class="col-md-8 pr-4">
                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                    <textarea
                      v-model="service.observations"
                      type="text"
                      :disabled="loading || service.status ===3"
                      rows="2"
                      class="form-control form-control-sm"
                    >
                    </textarea>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="modal-footer">
                <div class="d-flex pr-2" style="gap: 18px;">
                  <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="closeModal()">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span class="font-weight-500">
                    Cancelar
                  </span>
                  </div>
                  <!-- <div v-if="!service.code" class="d-flex align-items-center button-save-emit" style="gap: 5px;"
                       @click="saveAndEmit">
                    <img src="/img/icons/icons8/ios/os.png" alt="save_emit" width="16px">
                    <span class="font-weight-500" style="white-space: nowrap;">
                    Salvar e emitir
                  </span>
                  </div> -->
                  <button v-show="!loading" class="d-flex align-items-center button-save" type="submit"
                          style="gap: 5px;">
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span class="font-weight-500">
                    Salvar
                  </span>
                  </button>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </modal>
    <ModalServiceCancellation @updatedService="cancellationService" ref="modalServiceCancellation"/>
  </div>
</template>

<script>
import moment from "moment";
import {checkNumberValue} from "@/helpers";
import {mask1, formatErrorValidation, formatDate} from "@/plugins";
import {mapGetters} from "vuex";
import ModalServiceCancellation from "./_ModalServiceCancellation";
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  components: {
    ModalServiceCancellation,
    InputDateTimePicker
  },
  name: "ModalEditService",
  data() {
    return {
      modal: false,
      title: "Editar Serviço",
      validated: false,
      loadingSave: false,
      loading: false,
      loadingEquipment: false,
      loadingPlantIssuer: true,
      company_plant_fixed_issuer_not_found: false,
      hours: null,
      plants: [],
      service: {
        id: null,
        uuid: null,
        service_name: null,
        equipment: null,
        employers: [],
        volume: null,
        company_plant_issuer_id: null,
        was_billed: false,
      }
    };
  },
  computed: {
    ...mapGetters({
      equipments: "equipment/fetch",
      users: "user/fetch",
      $_company_plant_issuers: "plant/getCompanyPlantIssuers",
      contract_proposal: "contractProposal/show",
    })
  },
  props: {
    bySeller: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    async changePlant() {
      this.$Progress.start();
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.getCompanyPlantIssuers();
      this.loading = true;
      this.service.employers = [];
      this.service.equipment_uuid = null;
      const equipment_category_id = await this.getEquipmentCategoryIdByContractProposalServiceUuid(this.service.service_uuid);
      this.$store
        .dispatch(
          "equipment/getByCompanyPlantAndService",
          {
            filter: {
              company_plant_uuid: this.service.company_plant_uuid,
              status: 1, // ativo
              with_equipment_restriction: this.service.service ? this.service.service.description : this.service.service_description,
              category_id: equipment_category_id
            }
          }
        )
        .then(response => {
          this.$store
            .dispatch("user/getByCompanyPlant", this.service.company_plant_uuid)
            .then(response => {
              this.loading = false;
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.$Progress.finish();
            });
        });
    },
    async handleCreate(service, company_plants) {
      let loader = this.$loading.show();
      this.hours = null;
      this.plants = company_plants;
      this.service = JSON.parse(JSON.stringify(service));
      this.getCompanyPlantIssuers(false);
      const equipment_category_id = await this.getEquipmentCategoryIdByContractProposalServiceUuid(service.service_uuid);
      this.$store
        .dispatch("equipment/getByCompanyPlantAndService",
          {
            filter: {
              company_plant_uuid: service.company_plant_uuid,
              status: 1, // ativo
              with_equipment_restriction: service.service_description,
              category_id: equipment_category_id
            }
          })
        .then(response => {
          this.$store
            .dispatch("user/getByCompanyPlant", {
              company_plant_uuid: service.company_plant_uuid,
              type: 'service_driver'
            })
            .then(response => {
              let new_employers = [];
              this.service.employers.map(function (item) {
                new_employers.push(item.uuid);
              });
              this.service.employers = new_employers;
              this.service.start_at = formatDate(service.start_at);
              this.service.end_at = formatDate(service.end_at);
              this.timeCalc();
            });
        });
      await this.$store.dispatch('contractProposal/show', this.service.contract_proposal_uuid).then((response) => {
        this.modal = true;
        loader.hide()
      })
    },
    getCompanyPlantIssuers(reset_company_plant_issuer = true) {
      this.loadingPlantIssuer = true;
      this.service.company_plant_issuer_id = reset_company_plant_issuer ? null : this.service.company_plant_issuer_id;
      let id = this.plants.filter(item => item.company_plant_uuid === this.service.company_plant_uuid)[0].company_plant_id
      this.$store
        .dispatch(
          "plant/fetchCompanyPlantIssuersByPlant",
          {id: id}
        ).then(response => {
        if (response.data.length) {
          let default_plant_issuer = response.data.find((item) => item.default === 1) || response.data[0]
          /**
           * verifica se possui CNPJ Emissor fixo
           */
          if (this.contract_proposal.issuer_fixed) {
            let fixed_issuer_id = this.contract_proposal.company_plant_issuer_id_fixed
            /**
             * verifica se o CNPJ Emissor fixo pertence a central selecionada
             */
            let has_issuer = response.data.find((item) => item.id === fixed_issuer_id)
            if (has_issuer) {
              this.service.company_plant_issuer_id = fixed_issuer_id
              this.company_plant_fixed_issuer_not_found = false
            } else {
              this.company_plant_fixed_issuer_not_found = true
              this.service.company_plant_issuer_id = null
            }
          } else {
            if (default_plant_issuer && !this.contract_proposal.company_plant_issuer_id) {
              this.service.company_plant_issuer_id = default_plant_issuer.id
            }
          }
        }
        this.loadingPlantIssuer = false;
      });
    },
    timeCalc() {
      this.$forceUpdate();
      if (this.service.start_at && this.service.end_at) {
        let start = moment(this.service.start_at);
        let end = moment(this.service.end_at);
        var duration = moment.duration(end.diff(start));
        this.hours = duration.asHours().toFixed(1);
      } else {
        this.hours = null;
      }
    },
    cancelService(uuid) {
      if (this.service.status === 3) {
        return this.$notify({
          type: 'danger',
          message: 'O serviço já está cancelado.'
        });
      }
      this.$refs.modalServiceCancellation.handleCreate(uuid, 1);
    },
    cancellationService(data) {
      this.closeModal();
      this.$emit('updatedService', data);
    },
    async saveAndEmit() {
      this.$Swal.confirmAction("Confirma o desejo de salvar os dados e a emissão do O.S?", ["Sim", "Não"]).then(async (result) => {
        if (result.isConfirmed) {
          await this.saveExecute();
          let loader = this.$loading.show();
          this.createServiceOrder(this.service, this.plants, this.service.schedule_uuid, loader);
        }
      });
    },
    saveExecute() {
      const promise = new Promise((resolve, reject) => {
        this.save();
        resolve([])
      });
      return promise;
    },
    createServiceOrder(service, company_plants, schedule_uuid, loader) {
      let service_order = {
        service_uuid: service.uuid,
        company_plant_uuid: service.company_plant_uuid,
        equipment_uuid: service.equipment_uuid,
        employers: service.employers,
        status: 0
      };
      this.$store
        .dispatch("scheduleService/addServiceOrder", service_order)
        .then(response => {
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          let service_order_uuid = response.data.uuid;
          const newService = response.data;
          this.$store
            .dispatch("scheduleService/generateReport", service_order_uuid)
            .then(response => {
              let blob = new Blob([response], {
                type: "application/pdf"
              });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              window.open(link, "_blank");
              loader.hide();
            })
            .catch(error => {
              loader.hide();
            });
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
          } else {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: message
            })
            service.schedule_uuid = schedule_uuid
            this.$refs.modalScheduleService.handleCreate(service, company_plants);
          }
          loader.hide();
        });
    },
    save() {
      this.$Progress.start();
      this.loadingSave = true;
      this.service.start_at = moment(this.service.start_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.service.end_at = moment(this.service.end_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      this.$store
        .dispatch("scheduleService/update", this.service)
        .then(response => {
          this.$Progress.finish();
          this.loadingSave = false;
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$emit("updatedService", response.data);
          this.closeModal();
        })
        .catch(error => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingSave = false;
          } else if (
            error.response &&
            error.response.status &&
            error.response.status === 422
          ) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingPlant = false;
            this.loadingSave = false;
          }
        });
    },
    async getEquipmentCategoryIdByContractProposalServiceUuid(contract_proposal_service_uuid) {
      const response = await this.$store.dispatch("contractService/getEquipmentCategoryIdByContractProposalServiceUuid", contract_proposal_service_uuid);
      return response.data;
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style scoped>
/deep/ div[data-class="programming-weighing"] div.modal-header.pb-1 {
  background: #FEF9F2 !important;
}

/deep/ div[data-class="programming-weighing"] div.modal-header.pb-1 > button i {
  border: 1px solid #000 !important;
  color: #000;
  padding: 3%;
  font-size: 14px !important;
}

h5.modal-title > span, h5.modal-title > small {
  color: #2B2D32;
  font-size: 18px !important;
}

div.info-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 23px;
}

div.info-section > div.pzl-container-divider {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
}

div.info-section > div.pzl-container-divider > div {
  width: 100%;
  border: 0.5px solid #E8E8E8;
}

div.cancel-service-btn {
  padding: 8px 12px 8px 12px;
  border: 1px solid #FCF3F3;
  border-radius: 8px;
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FCF3F3;
  cursor: pointer;
}

div.cancel-service-btn.disabled {
  opacity: .3;
  cursor: none;
}

div.cancel-service-btn > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  font-family: "Fredoka", sans-serif;
  text-transform: uppercase;
  color: #DB4539;
}

.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
  height: 32px;
}

button.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
  background-color: #fff;
  height: 32px !important;
  min-height: 32px !important;
}

.button-save-emit {
  cursor: pointer;
  border: 0.5px solid #1A70B7;
  border-radius: 8px;
  color: #1A70B7;
  padding: 4px 20px;
  height: 32px;
}
</style>
