<template>
  <div>
    <div class="container-fluid" style="margin-bottom: 3.5rem;">
        <multi-filter ref="multi-filte"
          :type="5"
          @clearFilter="clearFilter()"
          @handleFilterDate="handleFilterDate"
          @fetch="init"
          :filter="filter"
          :showSlotDates="true"
          :showSlotBookmark="true"
        >
        <!-- Input contas bancarias -->
        <template slot="dropdown-instead-input">
          <el-select
              @change="handleAdvancedBankAccountFilter"
              v-model.lazy="bank_accounts"
              class="select-lg w-100"
              collapse-tags
              placeholder="CONTAS"
              multiple
              filterable
              :disabled="loadingBankAccounts || loading"
            >
              <el-option
                v-for="bank_account in $_bank_accounts"
                :key="bank_account.id"
                :label="bank_account.name"
                :value="bank_account.id"
              >
              </el-option>
            </el-select>
          </template>
          <!-- Filtros de ordenacao -->
          <template slot="order-by">
            <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
              <div v-for="(option, index) in orderByOptions" :key="option.value">
                <h6>
                  <a @click.prevent="handleFilterOrderBy(option.value)" href="#" class="font-weight-400" style="font-size: 12px">
                    <i :class="[filter.order_by === option.value && 'text-primary ', option.icon]"
                      style="font-size: 12px;margin-right: 5px"/>
                    {{ option.label }}
                  </a>
                </h6>
                <hr class="mb-1 mt-n2" v-if="index < orderByOptions.length - 1">
              </div>
              <a href="#" slot="reference">
                <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
              </a>
            </el-popover>
          </template>
        <!-- Filtro avançado -->
          <!-- data competencia-->
          <template slot="dates">
            <div class="col-md-12 text-left">
              <label class="form-control-label">
                COMPETÊNCIA
              </label>
              <input-date-picker :defaultRange="filter.range_competence || undefined" @handleFilterDate="handleCompetenceFilterDate" size="sm" visibility="focus"/>
            </div>
          </template>
          <!-- valor pagamento -->
          <template slot="data">
            <div class="mt-2 pl-3 w-100 pr-3">
              <div class="text-left">
                <label class="form-control-label">
                  Valor do pagamento
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <base-input
                          v-money="money"
                          inputmode="numeric"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                          @keyup="searchWithMoneyFilter"
                          v-model.lazy="filter.range_value.min"
                          input-group-classes="input-group-sm" placeholder="de" size="sm">
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-6 pl-1">
                    <base-input
                          v-money="money"
                          inputmode="numeric"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                          @keyup="searchWithMoneyFilter"
                          v-model.lazy="filter.range_value.max"
                          input-group-classes="input-group-sm" placeholder="de" size="sm">
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- Numero do documento -->
            <div class="mt-2 pl-3 w-100 pr-3">
              <div class="text-left">
                <label class="form-control-label">
                  NÚMERO DO DOCUMENTO
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <base-input inputmode="numeric"
                                @change="setRange()" v-model="filter.range_document_number.min"
                                input-group-classes="input-group-sm" placeholder="de" size="sm">
                    </base-input>
                  </div>
                  <div class="col-6 pl-1">
                    <base-input inputmode="numeric" @change="setRange()" v-model="filter.range_document_number.max"
                                input-group-classes="input-group-sm" placeholder="até" size="sm">
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- Status -->
          <template slot="status">
            <div id="customButton" class="col-12 mb-1">
              <button
                :class="{'btn-active btn-primary':filter.status == 8}"
                @click="filter.status == 8 ? filter.status = null : filter.status = 8; init({})"
                :active="filter.status == 8"
                style="color: #4b4b50;border: 1px solid #DCDFE6; background-color: white"
                class="text-uppercase col-12 btn-sm shadow d-flex justify-content-center align-items-center"
              >
                <img style="margin-right: 4px" ref="icon" height="19" :src="'/img/icons/icons8/ios/check-dollar' + (filter.status == 8 ?  '.png' : '_success.png')"/> Recebido +
                <img style="margin-right: 4px" ref="icon" height="19" :src="'/img/icons/icons8/ios/thumb-up' + (filter.status == 8 ? '.png' : '_primary.png')"/>
                 Aprovado
              </button>
            </div>

            <div class="col-12 mb-1"
              @click="filter.status == 9 ? filter.status = null : filter.status = 9; init({})">
              <base-button-hoverable
                size="sm" type="danger" :active="filter.status == 9" icon="thumbs-down" platform="ios">
                Negado
              </base-button-hoverable>
            </div>
          </template>
          <!-- bookmarks -->
          <template slot="bookmarks">
            <div class="col-12 mb-1" v-for="(bookmark, index) in bookMarksOptions" :key="index">
              <base-button-hoverable @click="handleBookmarkClick(bookmark)"
                size="sm" :type="bookmark.type" :active="getBookmarkActive(bookmark)" :icon="bookmark.icon" platform="ios">
                {{ bookmark.label }}
              </base-button-hoverable>
            </div>
          </template>
        </multi-filter>
    </div>
    <div class="container-fluid">
      <div class="row card-wrapper">
        <!-- CARD -->
        <div class="col-md-6 col-lg-6 col-xl-4" v-if="$_items && $_items.length && !loadingSkeleton"
             v-for="(item, index) in $_items" :key="index" style="padding: 0 10px !important">
             <div class="row align-items-center card mx-1">
              <div class="w-100 d-flex justify-content-between">
                <div style="color: #2B2D32)">
                  <div style="font-weight: 400 !important; font-size: 14px; line-height: 16px; color: hsl(223, 8%, 18%, 75%);" class="font-weight-bold ml-4 mt-3">
                    {{ item.bank_account }}
                  </div>
                  <div class="mt-2" style="display: flex; align-items: center;">
                    <div style="font-weight: 500 !important; font-size: 14px; line-height: 16px; color: hsl(223, 8%, 18%, 75%); text-wrap: nowrap;"
                      class="font-weight-bold ml-4">
                      {{ item.payment_method }}
                      <span v-if="item.nsu_code" style="border-left: 1px solid hsl(223, 8%, 18%, 75%);padding: 0 5px; line-height: 12px;">{{ item.nsu_code }}</span>
                    </div>
                    <!-- Se tem nsu e credenciais de integração ou tenha integração via txt -->
                    <div v-if="item.nsu_code && item.payment_intermediary_has_credentials === 1 || item.payment_intermediary_accept_txt_file === 1"
                      class="font-weight-bold ml-2" style="cursor: pointer;">
                      <img v-if="item.card_integration_status === 0"  @click="checkCardIntegration(item.competence)"  title="Pendente" src="/img/icons/icons8/ios/api_warning.png" width="22">
                      <img v-if="item.card_integration_status === 1" src="/img/icons/icons8/ios/api_success.png" title="Integrado" width="22">
                      <img v-if="item.card_integration_status === 2" src="/img/icons/icons8/ios/api_danger.png" title="Divergente" width="22">
                    </div>
                    <div class="ml-2">
                      <el-popover v-if="item.nsu_code && item.is_nsu_code_duplicated"
                        trigger="hover" placement="right" class="p-0 pr-0">
                        <span class="text-uppercase new-default-black-font">Duplicidade de código</span>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img class="mr-1" src="/img/icons/icons8/ios/break--v2_warning.png" width="26">
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <div style="font-weight: 500 !important; font-size: 14px; line-height: 16px; color: hsl(223, 8%, 18%, 75%);" class="font-weight-bold ml-4 mt-2">
                    {{ item.payment_term }}
                  </div>
                  <div class="d-flex">
                    <!-- valor -->
                    <div style="font-weight: 500 !important; font-size: 27px; line-height: 20px; color: #2B2D32; " class="font-weight-bold ml-4 mb-2 mt-3 value-card ">
                      {{ item.total_value | currency() }}
                    </div>
                    <!-- status -->
                    <div class="mx-2 mb-1 mt-3 align-self-center"
                      style="border-radius: 5px; font-size: 0.8rem; text-align: center; font-weight: 500 !important; padding: 0.2rem 0.3rem"
                      :style="getCardStatus(item).color">
                      {{ getCardStatus(item).text }}
                    </div>
                  </div>
                  <!-- Competencia -->
                  <div class="w-100 d-flex justify-content-between">
                    <div class="ml-4" style="font-size: 14px; font-weight: 400 !important;" v-if="item.user_name">
                      {{ item.competence | parseDate("DD MMM YYYY") }}
                    </div>
                  </div>
                </div>
                <div class="mr-1" style="display: grid">
                  <!-- Menu engrenagem -->
                  <base-dropdown menuOnRight v-if="!item.billed" class="d-flex justify-content-center" style="height: fit-content">
                    <div slot="title-container"
                         class="dropdown-toggle rounded mt-3">
                      <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <div>
                      <a class="dropdown-item d-flex" @click="handleEditEntity(item.id)" style="font-size: 12px;">
                        <img width="15" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new"/>
                        Editar
                      </a>
                    </div>

                    <!-- Totvs -->
                    <div v-if="hasTotvs && (item.approved_value > 0 || item.pay_value > 0)" class="dropdown-divider p-0 m-0"></div>
                    <a @click="handleModalEntityBillReceiveIntegrationInfo(item.id)"  v-if="hasTotvs" class="dropdown-item">
                      <img src="/img/icons/icons8/ios/downloading-updates-primary.png" width="22px" height="22px" />
                      Totvs
                    </a>

                    <div v-if="$hasPermission('bill_receive.anticipation_balance_transfer') && item.anticipation_balance > 0">
                      <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                      <a class="dropdown-item d-flex" @click.prevent="handleModalAnticipationBalanceTransfer(item.entity_uuid)"
                        style="font-size: 12px;">
                        <img width="15" src="/img/icons/sorting-arrows-horizontal-red.png">
                        Transferir
                      </a>
                    </div>
                    <!-- <div>
                      <a class="dropdown-item d-flex" @click.prevent="handleDelete(item.id)" style="font-size: 12px;">
                        <img width="15" src="https://img.icons8.com/ios/100/db4539/delete--v1.png" alt="delete--v1"/>Deletar
                      </a>
                    </div> -->
                  </base-dropdown>
                  <!-- Icones informativos -->
                  <div class="mr-3 mb-1 d-flex justify-content-end align-items-end">
                    <div class="d-flex">
                      <el-popover v-if="hasTotvs && item.is_integrated_on_totvs === TotvsStatusEnum.INTEGRATED" trigger="hover" placement="right" class="mr-1">
                        <span class="p-2">
                          <span class="text-center">Integrado com a totvs</span>
                        </span>
                        <span slot="reference">
                          <img src="/img/icons/icons8/ios/connected_success.png" width="25px">
                        </span>
                      </el-popover>
                      <el-popover v-else-if="hasTotvs && item.is_integrated_on_totvs === TotvsStatusEnum.ERROR" trigger="hover" placement="right" class="mr-1">
                        <span class="p-2">
                          <span class="text-center">Erro de integração com a totvs</span>
                        </span>
                        <span slot="reference">
                          <img src="/img/icons/icons8/ios/disconnected_danger.png" width="25px">
                        </span>
                      </el-popover>
                      <el-popover trigger="click" placement="left" class="p-0"  style="font-size: 12px !important;">
                        <div class="m-2">
                          <div>
                            <div style="font-size: 12px" class="mt-2">
                              <b style="font-size: 12px">Nome:</b>
                              {{ item.user_name }}
                            </div>
                            <div style="font-size: 12px" class="mt-1">
                              <b style="font-size: 12px">Criação:</b>
                              {{ item.created_at | parseDate("DD MMM YYYY hh:mm") }}
                            </div>
                          </div>
                        </div>
                        <div slot="reference">
                          <img class="icon-img mr-2" width="25px" src="/img/icons/salesman.png" />
                        </div>
                      </el-popover>
                      <!-- Anexos -->
                      <div v-if="!item.exists_attachments">
                        <el-popover trigger="click" placement="bottom" class="p-0">
                            <div>
                              <div style="font-size: 12px">Sem anexo.</div>
                            </div>
                          <div slot="reference">
                            <img class="icon-img" width="25"  src="https://img.icons8.com/ios/100/2a2c32bf/attach.png">
                          </div>
                        </el-popover>
                      </div>
                      <div v-if="item.exists_attachments" @click.prevent="handleShowAttachments(item, index)">
                        <img class="icon-img" width="25px" src="https://img.icons8.com/ios/100/149e57/attach.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 p-0 pr-4 mb-0 pl-4 mt-2">
                <div style="border-top: 1px solid hsl(240, 1%, 38%, 15%); margin-bottom: 5px;"/>
              </div>
              <div class="w-100 d-flex justify-content-start mt-1" style="color: #2B2D32">
                <div>
                  <div>
                    <div class="ml-4 mt-1" style="font-weight: 400 !important; font-size: 12px">
                      {{ item.company_plant }}
                    </div>
                  </div>
                  <div>
                    <div class="ml-4 mt-1" style="font-weight: 500 !important; font-size: 14px">
                      {{ item.entity_name }}
                    </div>
                  </div>
                  <div>
                    <div class="ml-4 mb-3 mt-1" style="font-weight: 400 !important; font-size: 12px">
                      {{ item.document }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- Botoes de ações -->
              <div v-if="showAllowApproveEntity(item)" class="row d-flex justify-content-around col-12 mb-3 text-center">
                <div class="mb-1 aprovation-button" @click.prevent="handleClickApproveEntity(item.bill_receive_id, 8, index)">
                  <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumb-up_primary.png">
                  APROVAR
                </div>
                <div class="mb-1 aprovation-button" @click.prevent="handleClickApproveEntity(item.bill_receive_id, 9, index)">
                  <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumbs-down_danger.png">
                  NEGAR
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="row card-wrapper">
        <SkeletonPuzlGrid v-for="(index) in 3" v-show="loadingSkeleton" :key="index"></SkeletonPuzlGrid>
      </div>
      <puzl-empty-data v-if="$_items && !$_items.length && !loadingSkeleton"/>
    </div>
    <loading-pagination :show="loading && !loadingSkeleton"/>
    <modal-attachment ref="modalAttachment"/>
    <ModalEditEntity @updated="loadingSkeleton = true; init()" ref="modalEditEntity"/>
    <ModalAnticipationBalanceTransfer @updated="loadingSkeleton = true; init()" ref="modalAnticipationBalanceTransfer" />
    <ModalEntityBillReceiveIntegrationInfo ref="modalEntityBillReceiveIntegrationInfo" />
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV3";
import InputDatePicker from "@/components/InputDatePicker";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {mapGetters} from "vuex";
import LoadingPagination from "../../../../../components/LoadingPagination";
import moment from "moment";
import PuzlSelect from "@/components/PuzlSelect";
import ModalAttachment from "../../BillReceive/Shared/Modals/_ModalAttachment";
import ModalEditEntity from '@/views/Modules/Financial/BillReceive/Entity/Shared/Modals/_ModalEditEntity';
import {VMoney} from "v-money";
import { strToNum } from "@/helpers";
import { debounce } from "@/plugins";
import ModalAnticipationBalanceTransfer from '@/views/Modules/Configuration/Entity/Shared/_ModalAnticipationBalanceTransfer';
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import { hasTotvs } from "@/plugins/microservices/totvs";
import { TotvsStatusEnum } from "@/enum/TotvsStatusEnum";
import ModalEntityBillReceiveIntegrationInfo from "@/views/Modules/Totvs/EntityBillReceive/_ModalEntityBillReceiveIntegrationInfo";

const bookmarkOptionEnum = Object.freeze({
  CHECK_INTEGRATION: 0,
  IS_NSU_CODE_DUPLICATED: 1,
});

export default {
  name: "List",
  components: {
    LoadingPagination,
    SkeletonPuzlGrid,
    PuzlEmptyData,
    InputDatePicker,
    MultiFilter,
    PuzlSelect,
    ModalAttachment,
    ModalEditEntity,
    ModalAnticipationBalanceTransfer,
    BaseButtonHoverable,
    ModalEntityBillReceiveIntegrationInfo,
  },
  mixins: [cursorPaginate],
  data() {
    return {
      filter: {
        range_value: {
          min: null,
          max: null
        },
        status: null,
        api: null,
        range_document_number: {
          min: null,
          max: null
        },
        range_competence: null,
      },
      range: {
        start: moment(new Date()).format('YYYY-MM-DD'),
        end: moment(new Date()).format('YYYY-MM-DD'),
      },
      loadingSkeleton: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      bank_accounts: [],
      loadingBankAccounts: true,
      bookmarkOptionEnum: bookmarkOptionEnum,
      orderByOptions: [
        { label: 'DO MAIS NOVO PARA O MAIS VELHO', value: 'created.desc', icon: 'fa-solid fa-angles-down' },
        { label: 'DO MAIS VELHO PARA O MAIS NOVO', value: 'created.asc', icon: 'fa-solid fa-angles-up' },
        { label: 'DATA DE COMPETÊNCIA: DA MAIS NOVA PARA A MAIS VELHA', value: 'date_at.desc', icon: 'fa-solid fa-angles-down'},
        { label: 'DATA DE COMPETÊNCIA: DA MAIS VELHA PARA A MAIS NOVA', value: 'date_at.asc',icon: 'fa-solid fa-angles-up' },
        { label: 'VALOR: DO MAIOR PARA O MENOR', value: 'value.desc', icon: 'fa-solid fa-angles-down' },
        { label: 'VALOR: DO MENOS PARA O MAIOR', value: 'value.asc', icon: 'fa-solid fa-angles-up' }
      ],
      statusFilterOptions: [
        { label: 'RECEBIDO' ,icon: 'check-dollar' ,activatedIcon: 'check-dollar',type: 'success', statusCode: 1 },
        { label: 'APROVADO',icon: 'thumb-up',type: 'primary', statusCode: 8 },
        // { label: 'PENDENTE',icon: 'hourglass', type: 'warning', statusCode: 0 },
        // { label: 'ATRASADO', icon: 'present',type: 'danger', statusCode: 2 },
        { label: 'NEGADO', icon: 'thumbs-down',type: 'danger', statusCode: 9 },
      ],
      bookMarksOptions: [
        { id: bookmarkOptionEnum.CHECK_INTEGRATION, label: 'VERIFICAR INTEGRAÇÃO' ,icon: 'api' ,type: 'dark' },
        { id: bookmarkOptionEnum.IS_NSU_CODE_DUPLICATED, label: 'DUPLICIDADE DE CÓDIGO' ,icon: 'break--v2' ,type: 'warning' },
      ],
      hasTotvs: hasTotvs(),
      TotvsStatusEnum: TotvsStatusEnum,
    }
  },
  directives: {money: VMoney},
  computed: {
    ...mapGetters({
      $_items: "approval/fetch",
      $_payment_methods: "paymentMethod/fetch",
      $_bank_accounts: "bankAccount/fetch",
    })
  },
  mounted() {
    this.loadingPaymentMethods = true
    this.$store.dispatch("paymentMethod/fetchItems").then(() => {
      this.loadingPaymentMethods = false
    })
    this.$store
      .dispatch("bankAccount/fetchItems", {filter:{status:true}}).then((response) => {
      this.loadingBankAccounts = false;
    });
    this.init({})
  },
  methods: {
    getCardStatus(payment) {
      let statusText =
        payment.pay_value > 0 ? 'Recebido' :
        payment.late_value > 0 ? 'Atrasado' :
        payment.pending_value > 0 ? 'Pendente' :
        payment.canceled_value > 0 ? 'Cancelado' :
        payment.approved_value > 0 ? 'Aprovado' :
        payment.denied_value > 0 ? 'Negado' :
        'Recebido';

      let statusType =
        payment.pay_value > 0 ? 'background-color: #d4ede0; color: #149E57' :
        payment.late_value > 0 ? 'background-color: #f9dedc; color: #DB4539' :
        payment.pending_value > 0 ? 'background-color: #fdf5e4; color: #F5C865' :
        payment.canceled_value > 0 ? 'background-color: #f9dedc; color: #DB4539' :
        payment.approved_value > 0 ? 'background-color: hsl(207, 75%, 41%, 20%); color: #1A70B7' :
        payment.denied_value > 0 ? 'background-color: #f9dedc; color: #DB4539' :
        'background-color: #db4539;';
      return {
        text: statusText,
        color: statusType
      };
    },
    formatValue(item) {
      switch (true) {
        case item.pay_value > 0:
          return item.pay_value; // (Recebido)
        case item.approved_value > 0:
          return item.approved_value; // (Aprovado)
        case item.denied_value > 0:
          return item.denied_value; // (Negado)
        case item.pending_value > 0:
          return item.pending_value; // (Pendente)
        case item.late_value > 0:
          return item.late_value; // (Atrasado)
        case item.canceled_value > 0:
          return item.canceled_value; // (Cancelado)
        default:
          return '';
      }
    },
    showAllowApproveEntity(entity) {
      if (!entity.allow_approve) {
        return false
      }
      if (entity.approved_value > 0 ||
        entity.denied_value > 0 || entity.pay_value > 0) {
        return false
      }
      if (this.$hasPermission('bill_receive.approve_installment') &&
        (entity.pending_value > 0 || entity.late_value > 0)
      ) {
        return true
      }
      return false
    },
    async handleShowAttachments(bill_receive, index) {
      let loader = this.$loading.show()
      await this.getAttachments(bill_receive.bill_receive_id, 0, index)
      const item = this.$_items[index]
      loader.hide()
      this.$refs.modalAttachment.openAttachment(item.attachments)
    },
    async getAttachments(id, type, index) {
      if (!this.$_items[index].attachments) {
        this.$_items[index].attachments = 1;
        await this.$store
          .dispatch("billReceive/getAttachments", {
            id: id,
            type: type
          })
          .then(response => {
            this.$_items[index].attachments = response.data;
            this.$forceUpdate();
          });
      }
    },
    handleEditEntity(id) {
      const item = this.$_items.find((item) => item.id === id);

      // O usuário não pode editar um registro com status "Aprovado"
      // se não tiver permissão para aprovar antecipações.
      const hasBillReceiveStatusApproved = item.approved_value > 0;
      const userCannotEdit = hasBillReceiveStatusApproved && !this.$hasPermission('bill_receive.approve_installment');
      if (userCannotEdit) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para EDITAR esse registro.'
        });
        return;
      }

      this.$refs.modalEditEntity.handleCreateModal({
        id: item.id,
        entity_name: item.entity_name,
        document: item.document
      }, item.bill_receive_id)
    },
    handleAdvancedBankAccountFilter() {
      this.init({});
    },
    init(filter = null) {
      this.startCursor(filter);
      if (this.bank_accounts.length) this.filter.bank_accounts = this.bank_accounts;
      else delete this.filter.bank_accounts;
      this.filter.type = 1;
      this.filter.range_value.max = strToNum(this.filter.range_value.max) > 0 ? strToNum(this.filter.range_value.max) : null;
      this.filter.range_value.min = strToNum(this.filter.range_value.min) > 0 ? strToNum(this.filter.range_value.min) : null;
      this.$store.dispatch('approval/fetch',
        {
          ...this.filter,
          next_page: this.paginate.nextUrl,
        }).then(response => {
        this.resolveCursor(response)
      }).catch(error => {
        this.resolveCursor()
      });
    },
    handleFilterOrderBy(type) {
      this.filter.order_by == type ? this.filter.order_by = null : this.filter.order_by = type;
      this.init({});
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    handleCompetenceFilterDate(filter) {
      this.filter.range_competence = filter;
      this.init({});
    },
    async handleClickApproveEntity(id, status) {
      const text = status === 9 ? 'Negar' : 'Aprovar'
      this.$Swal.confirmAction(`Deseja realmente ${text} o pagamento?`, ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            let loader = this.$loading.show()
            this.$store.dispatch("billReceive/show", id).then(response => {
              const installment = response.data.installments.find((item) => [0, 2].includes(item.status))
              if (!installment) {
                loader.hide()
                return this.$notify({
                  type: "warning",
                  message: "Não há parcelas pendentes."
                });
              }
              this.loadingInstallmentStatus = true
              this.$Progress.start()
              this.$store
                .dispatch("billReceive/changeInstallmentStatus", {
                  id: installment.id,
                  status: status,
                  type: 0
                })
                .then(response => {
                  installment.status = status;
                  this.loadingInstallmentStatus = false;
                  this.$Progress.finish();
                  this.$notify({
                    type: response.error_type,
                    message: response.message
                  });
                  loader.hide()
                  this.init({})
                })
                .catch(error => {
                  this.$notify({
                    type: error.data.error_type,
                    message: error.data.message
                  });
                  loader.hide()
                  this.loadingInstallmentStatus = false;
                  this.$Progress.finish();
                });
            })
          }
        })
    },
    searchWithMoneyFilter: debounce(function () {
      this.init({});
    }, 500),
    clearFilter() {
      this.filter = {
        range_value: { min: null, max: null },
        status: null,
        api: null,
        range_document_number: { min: null, max: null },
        range_competence: null
      },
      this.init({});
    },
    setRange: debounce(function () {
      this.init({})
    }, 100),
    checkCardIntegration(competence){
      let loader = this.$loading.show();
      const params = {startAt: competence, endAt: competence};
      this.$store.dispatch("billReceive/checkCardIntegration", params)
        .then(() => {
          this.$notify({
            type: "success",
            message: "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.",
          });
        }).catch((error) => {
          const errors = error.status
            ? error.data.message
            : formatErrorValidation(error.response.data.errors);
          this.$notify({ type: "danger", message: errors });
        }).finally(() => {
          loader.hide();
        });
    },

    /**
     * Abre modal de transferência de saldo de antecipação
     *
     * @param {string} entity_uuid
     * @returns void
     */
     handleModalAnticipationBalanceTransfer(entity_uuid) {
      this.$refs.modalAnticipationBalanceTransfer.openModal(entity_uuid);
    },

    /**
     * Executa a função correspondente ao ID do bookmark informado.
     *
     * @param {Object} bookmark - O objeto bookmark informado.
     * @returns void
     */
    handleBookmarkClick(bookmark){
      const options = {
        [bookmarkOptionEnum.CHECK_INTEGRATION]: this.handleFilterPendingCardIntegration,
        [bookmarkOptionEnum.IS_NSU_CODE_DUPLICATED]: this.handleFilterDuplicateNsuCode,
      };
      const clickHandler = options[bookmark.id];
      if (clickHandler) {
        clickHandler();
      }
    },

    /**
     * Retorna o estado ativo do bookmark informado.
     *
     * @param {Object} bookmark - O objeto bookmark informado.
     * @returns {boolean} - O valor da propriedade de filtro correspondente ao ID do bookmark.
     */
    getBookmarkActive(bookmark){
      const options = {
        [bookmarkOptionEnum.CHECK_INTEGRATION]:  this.filter.pending_card_integration,
        [bookmarkOptionEnum.IS_NSU_CODE_DUPLICATED]: this.filter.is_nsu_code_duplicated,
      };
      return options[bookmark.id] ?? false;
    },

    /**
     * Busca somente registros com integração pendente
     *
     * @returns void
     */
    handleFilterPendingCardIntegration() {
      this.filter.pending_card_integration = this.filter.pending_card_integration ? 0 : 1;
      this.init({});
    },

    /**
     * Busca somente registros com nsu_code duplicado
     *
     * @retuns void
     */
    handleFilterDuplicateNsuCode() {
      this.filter.is_nsu_code_duplicated = this.filter.is_nsu_code_duplicated ? 0 : 1;
      this.init({});
    },

    handleModalEntityBillReceiveIntegrationInfo(entity_bill_receive_id) {
      this.$refs.modalEntityBillReceiveIntegrationInfo.openModal(entity_bill_receive_id);
    },
  }
}
</script>

<style scoped>
.aprovation-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 45%;
  height: 2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
}
.aprovation-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}
#customButton button {
  color: #4b4b50;border: 1px solid #DCDFE6;
  background-color: white;
}
#customButton .btn-active {
  color: white!important;
}
</style>
