<template>
  <div>
    <div class="container-fluid">
      <multi-filter ref="multi-filter" @fetch="init" :filter="filter" @clearFilters="clearFilters"></multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!$_operation_sources.length && !loadingSkeleton"></PuzlEmptyData>
      <div class="row card-wrapper">
        <div class="col-lg-4" v-for="(operationSource, index) in $_operation_sources" :key="index">
          <div class="card" style="box-shadow: none !important; border-radius: 8px !important; border: 0.5px solid #E8E8E8;">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <section class="top-section d-flex align-items-center mb-1">
                  <span v-if="operationSource.status === true" class="status-button-success">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                  </span>
                  <span v-else class="status-button-danger">
                      <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                      Inativo
                  </span>
                  <div class="row m-0 ml-2">
                    <div class="mx-1">
                      <el-popover
                        class="ml-0"
                        placement="bottom"
                        trigger="hover"
                      >
                        <div class="d-flex history-info" style="gap: 15px;">
                          <span>Criação</span>
                          -
                          <span>{{ operationSource.created_at | parseDate('DD MMM YYYY - HH:mm') }} | {{ operationSource.created_by_user_name }}</span>
                        </div>
                        <div v-if="operationSource.updated_by_user_id != null" class="d-flex justify-content-between history-info" style="gap: 15px;">
                          <span>Última Alteração</span>
                          -
                          <span>{{ operationSource.updated_at | parseDate('DD MMM YYYY - HH:mm') }} | {{ operationSource.updated_by_user_name }}</span>
                        </div>
                        <base-button
                          slot="reference"
                          class="p-0 m-0 shadow-none"
                          outiline
                          size="sm"
                          type="secundary"
                        >
                          <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="20px">
                        </base-button>
                      </el-popover>
                    </div>
                    <div>
                      <el-popover
                        class="ml-2 mb--1 pt-2"
                        placement="right"
                        trigger="hover"
                      >
                        <div>
                          <span>
                            <span style="font-weight: 500;color: #2B2D32;font-size: 12px;">
                              TIPO DE OPERAÇÕES PUZL
                            </span>
                            <hr>
                            <span style="font-weight: 500;font-size: 10px;color: #2B2D32">Financeiro e Estoque</span>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Gera Financeiro
                              </span>
                              <img :src="operationSource.is_generate_financial ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Nota Mãe
                              </span>
                              <img :src="operationSource.is_entry_value ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Movimenta Estoque
                              </span>
                              <img :src="operationSource.is_generate_stock ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <span style="font-weight: 500;font-size: 10px;color: #2B2D32">Contábil e Fiscal</span>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Contabiliza
                              </span>
                              <img :src="operationSource.is_computable ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Gera Livro Fiscal
                              </span>
                              <img :src="operationSource.is_generate_tax_book ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                          </span>
                        </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/brand/logo.png" alt="logo" width="20px">
                          </base-button>
                        </el-popover>
                    </div>
                  </div>
                </section>
                <div class="ml-auto">
                  <base-dropdown>
                    <base-button slot="title-container" type="empty" size="sm" class="dropdown-toggle shadow-none mt--2 mr--2" style="border-radius: 4px !important;">
                      <img height="30px" width="30px" src="/img/icons/icons8/ios/settings--v1_primary.png" class="mr-1 mt-n1"/>
                    </base-button>
                    <span class="dropdown-item" @click="handleEdit(operationSource.id)">
                      <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                      EDITAR
                    </span>
                    <span v-if="operationSource.type === 1" class="dropdown-item" @click="handleShowModalNatureOperation(operationSource.id)">
                      <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                      NATUREZA DA OPERAÇÃO
                    </span>
                    <span class="dropdown-item" @click="handleDelete(operationSource.id)">
                      <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px" class="imgBaseButton">
                      EXCLUIR
                    </span>
                  </base-dropdown>
                </div>
              </div>
              <section class="mt-2">
                
                <div v-if="operationSource.operation_nature === null">
                  <span style="font-size: 14px;font-weight: 500;color: #2B2D32;">
                    ENTRADA
                  </span>
                </div>
                <div v-else>
                  <span style="font-size: 14px;font-weight: 500;color: #2B2D32;">
                    SAÍDA
                  </span>
                </div>
                <div v-show="operationSource.product_service_hub_type_name" class="mt-2">
                  <span style="font-size: 14px;font-weight: 400;color: #2B2D32;">
                    {{ operationSource.product_service_hub_type_name }}
                  </span>
                </div>
              </section>
              <section class="mt-0">
                <div>
                  <span style="font-size: 12px;font-weight: 400;color: #2B2D32;">
                    {{ operationSource.name }}
                  </span>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <ModalEdit ref="ModalEdit"/>
      <modal-nature-operation ref="ModalNatureOperation"/>
      <loading-pagination :show="loading && !loadingSkeleton"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import ModalEdit from './_ModalEdit';
import ModalNatureOperation from './_ModalOperationNature.vue';
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import operationSourceMixin from "../Mixins/operation_source_mixin";

export default {
  name: "ListOperationSource",
  mixins: [cursorPaginate, operationSourceMixin],
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    LoadingPagination,
    ModalNatureOperation,
    ModalEdit,
    InputDatePicker,
    PuzlSelect,
    MultiFilter,
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      $_operation_sources: "operationSource/fetch",
    }),
  },
  mounted() {
    this.fetchItemsPaginate();
  },
  methods: {
    fetchItemsPaginate() {
      this.init({});
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$Progress.start();
      this.$store
        .dispatch("operationSource/fetchItemsPaginate", {
          filter: this.filter,
          next_page: this.paginate.nextUrl
        })
        .then(response => {
          this.$Progress.finish()
          this.resolveCursor(response)
        })
        .catch(error => {
          this.resolveCursor()
          this.$Progress.finish()
        });
    },
    handleShowModalNatureOperation(id) {
      this.$refs.ModalNatureOperation.handleOpenModal(id)
    },
    handleEdit(id) {
      this.$refs.ModalEdit.handleEditModal(id)
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          this.$store.dispatch('operationSource/destroy', id)
            .then((response) => {
              this.$notify({
                type: 'success',
                message: response.message
              });
            })
            .catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              })
            })
          this.$Progress.finish();
        }
      }).catch(() => this.$Progress.finish())
    },
    clearFilters() {
      this.filter = {};
      this.fetchItemsPaginate();
    },
  },
};
</script>

<style scoped>
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 116%;
  margin-left: -11px;
}
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #FCF3F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.history-info span{
  font-size: 14px;
  font-weight: 500;
  color: #2B2D32;
}
</style>
