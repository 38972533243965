<template>
  <div>
    <AppFixedPageTitle
      title="Resumo de Programações"
      icon="/img/icons/chemical-plant-white.png"
    />
    <Resume />
    <ModalResumeReport ref="modalResume" />
  </div>
</template>
<script>
import BackButton from '../../../../../components/Utils/BackButton.vue';
import Resume from "./Shared/_Resume";
import { AppFixedPageTitle } from "../../../../../components/AppGlobal/";

export default {
  components: {
    Resume,
    BackButton,
    AppFixedPageTitle,
  },
  data() {
    return {};
  },
};
</script>
<style></style>
