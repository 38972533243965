<template>
  <section class="mt-3">
    <div
      class="desktop"
      v-if="!loading"
      v-for="(item, equipment_code) in items"
      :key="equipment_code"
    >
      <div class="row container-all-body">
        <div class="col-lg-3 col-md-4">
          <div class="equipment-container mb-2">
            <span>{{ equipment_code.split("_")[0] }}</span>
            <span>{{ equipment_code.split("_")[1] }}</span>
          </div>
        </div>
        <div class="white-space"></div>
        <div class="col-lg-8 col-md-6 container">
          <div class="row">
            <div
              class="col-lg-3 col-md-6 flex-column container-body-card"
              v-for="(current, keyStringDate) in items[equipment_code]"
              :key="keyStringDate"
            >
              <BodyCard
                class="mb-2"
                v-for="(object, index) in current"
                :key="index"
                :data-identifier="
                  idList[index] !== undefined ? idList[index] : ''
                "
                :service="object"
                @updated-service="updatedService"
                @show-service-info="showServiceInfo"
                @update-service-action="updateServiceInfo"
                @update-cycle-action="updateCycleInfo"
                @cancel-service-action="cancelServiceInfo"
                @service-report-action="getReport"
              ></BodyCard>
            </div>
          </div>
        </div>
        <div class="white-space"></div>
      </div>
      <hr class="ml-3 mt-3" style="width: 100%" />
    </div>
    <PuzlEmptyData v-if="!loading && !itemsExists"></PuzlEmptyData>

    <div v-if="loading">
      <div class="row container-all-body">
        <div class="col-lg-3 col-md-4">
          <div class="equipment-container loading mb-2">
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="white-space"></div>
        <div class="col-lg-8 col-md-6 container">
          <div class="row">
            <div class="col-lg-3 col-md-6 p-0">
              <SkeletonPuzlGrid
                class="col-lg-12"
                v-for="indx in 2"
                :key="indx"
              />
            </div>
            <div class="col-lg-3 col-md-6 p-0">
              <SkeletonPuzlGrid
                class="col-lg-12"
                v-for="indx in 2"
                :key="indx"
              />
            </div>
            <div class="col-lg-3 col-md-6 p-0">
              <SkeletonPuzlGrid
                class="col-lg-12"
                v-for="indx in 2"
                :key="indx"
              />
            </div>
            <div class="col-lg-3 col-md-6 p-0">
              <SkeletonPuzlGrid
                class="col-lg-12"
                v-for="indx in 2"
                :key="indx"
              />
            </div>
          </div>
        </div>
        <div class="white-space"></div>
      </div>
      <hr class="ml-3 mt-3" style="width: 100%" />
    </div>
    <ServiceModalInfo ref="modalInfo" />
    <ModalEditService
      @updatedService="updatedService"
      ref="modalScheduleService"
    />
    <ModalServiceCancellation
      @updatedService="updatedService"
      ref="modalServiceCancellation"
    />
    <ModalServiceCycle @updatedCycle="updatedService" ref="modalServiceCycle" />
  </section>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import BodyCard from "./_BodyCard";
import ServiceModalInfo from "./_ServiceModalInfo";
import ModalEditService from "@/views/Modules/Operational/Schedule/Service/_Edit";
import ModalServiceCycle from "@/views/Modules/Operational/Schedule/Service/_ModalServiceCycle";
import ModalServiceCancellation from "@/views/Modules/Operational/Schedule/Service/_ModalServiceCancellation";

export default {
  name: "BodyAgenda",
  inheritAttrs: false,
  components: {
    BodyCard,
    SkeletonPuzlGrid,
    PuzlEmptyData,
    ServiceModalInfo,
    ModalEditService,
    ModalServiceCancellation,
    ModalServiceCycle,
  },
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    idList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    itemsExists() {
      return Object.keys(this.items).length;
    },
  },
  methods: {
    /**
     * @param {string} uuidService
     * @returns {void}
     */
    cancelServiceInfo(uuidService) {
      const cancelService = 1;
      this.$refs.modalServiceCancellation.handleCreate(
        uuidService,
        cancelService
      );
    },
    /**
     * @param {object} params
     * @returns {void}
     */
    updatedService(params) {
      this.$emit("updated-service", params);
    },
    /**
     * @param {object} param
     * @param {string} param.uuidSchedule
     * @param {string} param.uuidService
     * @returns {void}
     */
    async updateServiceInfo(param) {
      this.$Progress.start();
      let loader = this.$loading.show();
      const schedule = await this.getScheduleByUuid(param.uuidSchedule);
      const scheduleService = this.getScheduleServiceBySchedule(
        param.uuidService,
        schedule
      );
      this.$refs.modalScheduleService.handleCreate(
        scheduleService,
        schedule.company_plants
      );
      this.$Progress.finish();
      loader.hide();
    },
    /**
     * @param {object} param
     * @param {string} param.uuidSchedule
     * @param {string} param.uuidService
     * @returns {void}
     */
    async updateCycleInfo(param) {
      this.$Progress.start();
      let loader = this.$loading.show();
      const schedule = await this.getScheduleByUuid(param.uuidSchedule);
      const scheduleService = this.getScheduleServiceBySchedule(
        param.uuidService,
        schedule
      );
      this.$refs.modalServiceCycle.handleCreate(schedule, scheduleService);
      this.$Progress.finish();
      loader.hide();
    },
    /**
     * @param {object} param
     * @param {string} param.uuidSchedule
     * @param {string} param.uuidService
     * @returns {void}
     */
    async showServiceInfo(param) {
      this.$Progress.start();
      let loader = this.$loading.show();
      const schedule = await this.getScheduleByUuid(param.uuidSchedule);
      const scheduleService = this.getScheduleServiceBySchedule(
        param.uuidService,
        schedule
      );
      this.$Progress.finish();
      loader.hide();
      this.$refs.modalInfo.openModal({
        ...scheduleService,
        schedule: schedule,
      });
    },
    /**
     * @param {string} uuidSchedule
     * @param {string} param.uuidService
     * @returns {Promise<object>}
     */
    getScheduleByUuid(uuidSchedule) {
      let schedule_uuid = uuidSchedule;
      const promise = new Promise((resolve, reject) => {
        this.$store
          .dispatch("schedule/show", schedule_uuid)
          .then(async (response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
      return promise;
    },
    /**
     * @param {string} uuidService
     * @param {object} schedule
     * @returns {object}
     */
    getScheduleServiceBySchedule(uuidService, schedule) {
      let service = schedule.services.find(
        (service) => service.uuid === uuidService
      );
      let employer_uuids = [];
      service.employers.forEach((empoyerUuid) => {
        employer_uuids.push({
          uuid: empoyerUuid,
        });
      });
      service = {
        ...service,
        contract_proposal_uuid: schedule.contract_proposal.uuid,
        ...{
          service_uuid: service.service_uuid,
          schedule_uuid: schedule.uuid,
          service_name: service.service.name,
          service_description: service.service.description,
          employers: employer_uuids,
        },
      };
      return service;
    },
    /**
     * @param {string} schedule_service_orders_uuid
     * @returns {void}
     */
    getReport(schedule_service_orders_uuid) {
      let loader = this.$loading.show();
      this.$store
        .dispatch(
          "scheduleService/generateReport",
          schedule_service_orders_uuid
        )
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          window.open(link, "_blank");
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
div.equipment-container {
  background-color: #e8e8e8;
  border: 1.5px solid #e8e8e8;
  border-radius: 8px;
  padding: 12px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.equipment-container.loading {
  background-color: #f8f9fe;
}

div.container-body-card {
  padding: 0 0.5%;
}

div.white-space {
  width: 43px;
}
</style>
