<template>
  <div>
    <modal size="lg" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0"><i class="fa-solid fa-lock mr-1"></i> {{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="form-group row m-0 p-0">
              <div class="col-md-12">
                <card>
                  <div class="table-responsive">
                    <table class="table align-items-center mb-0">
                      <thead>
                      <tr>
                        <th style="border-top: 0px" class="text-uppercase text-muted text-xxs font-weight-bolder "><i
                          class="fa-regular fa-calendar-xmark"></i> DATA DE FECHAMENTO DO ESTOQUE
                        </th>
                        <th style="border-top: 0px" class="text-uppercase text-muted text-xxs font-weight-bolder "></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="text-left">
                          <div class="d-flex py ml-n2">
                            <div>
                              <base-input input-group-classes="input-group-sm">
                                <input-date-time-picker :disable-min-date="disableMinDate"
                                                        :disable-max-date="new Date()" :disabled="hasStockInProcess()"
                                                        size="md"
                                                        v-model="stockClosing.closing_at"/>
                              </base-input>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2 py-1 float-right">
                            <base-button size="sm" outline type="primary" native-type="submit"
                                         :disabled="invalid || hasStockInProcess()"
                                         :loading="loading">
                              <i v-show="!loading" class="fa-regular fa-calendar-check"></i> FECHAR ESTOQUE
                            </base-button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </card>
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <div class="col-md-12">
                <hr class="mt-0 mb-4" style="background: #e7e8f0">
              </div>
            </div>
            <div class="form-group row m-0 p-0">
              <div class="col-md-12">
                <div id="list-stock-closing" v-show="$_items.length && !loading"
                     class="table-responsive"  style="max-height: 42vh;overflow-y: auto">
                  <table class="table align-items-center mb-0">
                    <thead>
                    <tr>
                      <th style="border-top: 0"
                          class="text-center text-uppercase text-muted text-xxs font-weight-bolder "><i
                        class="fa-regular fa-calendar-xmark"></i> DATA DE FECHAMENTO
                      </th>
                      <th style="border-top: 0"
                          class="text-center text-uppercase text-muted text-xxs font-weight-bolder  ps-2"><i
                        class="fa-regular fa-calendar-check"></i> STATUS
                      </th>
                      <th style="border-top: 0" class=" text-uppercase text-muted text-xxs font-weight-bolder ">
                        <i class="fa-regular fa-user"></i> RESPONSÁVEL
                      </th>
                      <th style="border-top: 0"
                          class="text-center text-uppercase text-muted text-xxs font-weight-bolder ">
                        <i class="fa-regular fa-clock"></i> DATA DE ALTERAÇÃO
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, key) in $_items" :key="key">
                      <td class="text-center" style="font-weight: 600 !important;">
                        {{ item.closing_at | parseDate("DD/MM/YYYY") }}
                      </td>
                      <td class="text-center">
                        <base-dropdown v-if="item.status !== 3" menuOnRight>
                          <base-button
                            :disabled="Boolean(item.status === 2 && item.has_stock_before) || hasStockInProcess()"
                            size="sm" slot="title-container"
                            class="dropdown-toggle text-white text-uppercase"
                            :style="item.status === 1 ? 'color: white;background: #34a73cc2;' : 'color: white;background: rgb(205 42 42 / 76%);'"
                            block>
                            <template v-if="item.status === 1">
                              <i class="fa-solid fa-lock-open"></i>
                              ABERTO
                            </template>
                            <template v-else>
                              <i class="fa-solid fa-lock"></i>
                              FECHADO
                            </template>
                          </base-button>
                          <div>
                            <a @click.prevent="handleOpenStock(item.id)" v-if="item.status !== 1"
                               class="dropdown-item text-uppercase"
                            >
                              ABRIR
                            </a>
                            <a @click.prevent="handleCloseStock(item.id)" v-else
                               class="dropdown-item text-uppercase"
                            >
                              FECHAR
                            </a>
                          </div>
                        </base-dropdown>
                        <base-button v-if="item.status === 3"
                                     @click.prevent="handleOpenStock(item.id, true)"
                                     size="sm" slot="title-container"
                                     class=" text-white text-uppercase"
                                     style="background: #f0ac45;opacity: 70%"
                                     block>
                          <i class="fa-regular fa-clock"></i>
                          PROCESSANDO
                        </base-button>
                      </td>
                      <td style="font-weight: 600 !important;">
                        {{ item.user_name }}
                      </td>
                      <td class="text-center" style="font-weight: 600 !important;">
                        {{ item.created_at | parseDate("DD/MM/YYYY") }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <puzl-empty-data v-if="!$_items.length && !loading"/>
                <div v-show="loadingSkeleton" class="table-responsive" style="overflow: auto;height: 40vh">
                  <table class="table align-items-center mb-0">
                    <thead>
                    <tr>
                      <th style="border-top: 0" class="text-uppercase text-muted text-xxs font-weight-bolder "><i
                        class="fa-regular fa-calendar-xmark"></i> DATA DE FECHAMENTO
                      </th>
                      <th style="border-top: 0"
                          class="text-center text-uppercase text-muted text-xxs font-weight-bolder  ps-2"><i
                        class="fa-regular fa-calendar-check"></i> STATUS
                      </th>
                      <th style="border-top: 0" class=" text-uppercase text-muted text-xxs font-weight-bolder ">
                        <i class="fa-regular fa-user"></i> RESPONSÁVEL
                      </th>
                      <th style="border-top: 0" class=" text-uppercase text-muted text-xxs font-weight-bolder ">
                        <i class="fa-regular fa-clock"></i> DATA DE ALTERAÇÃO
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, key) in 5" :key="key">
                      <td colspan="5" style="border-top: 0">
                        <div class="mb-n5">
                          <skeleton-puzl type="text"/>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePickerV2.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import moment from "moment";

export default {
  name: "ModalCreate",
  components: {SkeletonPuzl, PuzlEmptyData, PuzlSelect, InputDateTimePicker},
  data() {
    return {
      modal: {
        title: 'Fechamento de estoque',
        create: false,
      },
      params: {
        hasMore: false,
        per_page: 3,
        next_page: '',
        filter: {}
      },
      disableMinDate: null,
      stockClosing: {
        closing_at: null,
        status: 3,
      },
      loadingSkeleton: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "stockClosing/fetch",
    }),
  },
  watch: {
    'modal.create': function (val) {
      if (!val) this.$emit('updated')
    }
  },
  methods: {
    navigateCalc(e) {
      const screenHeight = e.target.clientHeight;
      const scrolled = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (scrollHeight - (screenHeight + scrolled) < 213) {
        return true
      }
      return false
    },
    navigate(e) {
      if (!this.loading_paginate && this.navigateCalc(e)) {
        if (this.params.hasMore) {
          this.init()
        }
      }
    },
    closeModal() {
      this.modal.create = false;
      this.$emit('update')
    },
    handleCreateModal() {
      this.modal.create = true;
      this.init()
    },
    /*
    Valida se há fechamento de estoque inferior ao item percorrido
     */
    validatedHasStock() {
      for (let i in this.$_items) {
        let has_stock_before = this.$_items.find((item) => Boolean(item.status === 2 && item.closing_at > this.$_items[i].closing_at))
        if (this.hasStockInProcess()) {
          has_stock_before = true
        }
        this.$set(this.$_items[i], 'has_stock_before', has_stock_before)
      }
      /**
       * Busca apenas fechamentos de estoque
       */
      const closeds = this.$_items.filter((item) => item.status === 2) || []
      /**
       * Valida a maior data de fechamento
       * @type {number}
       */
      if (closeds.length) {
        let disableMinDate = closeds.reduce(function (a, b) {
          return a.closing_at > b.closing_at ? a : b;
        })
        let closing_at = moment(disableMinDate.closing_at).add(1, 'days').format('YYYY-MM-DD 00:00:00')
        this.disableMinDate = new Date(closing_at)
      } else {
        this.disableMinDate = null
      }
    },
    hasStockInProcess() {
      return this.$_items.find((item) => item.status === 3)
    },
    init(filter = {}) {
      this.loading_paginate = true
      this.params.filter = filter
      let loader = this.$loading.show()
      this.$store.dispatch('stockClosing/fetchItems', this.params).then((response) => {
        this.params.hasMore = response.has_more
        this.params.next_page = response.next_page
      }).finally(() => {
        this.validatedHasStock()
        loader.hide()
        this.loadingSkeleton = false
        this.loading_paginate = false
      })
    },
    store() {
      let text = 'Deseja realmente realizar o fechamento do estoque?'
      this.$Swal.confirmAction(`${text}`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            this.validatedHasStock()
            let loader = this.$loading.show()
            this.$store.dispatch('stockClosing/add', this.stockClosing).then(response => {
              this.$snotify.success(response.message, {
                timeout: 2000,
                icon: false,
                position: "leftTop",
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              });
              loader.hide();
            }).catch(error => {
              this.$snotify.error(error.data.message, {
                timeout: 2000,
                icon: false,
                position: "leftTop",
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              });
              loader.hide()
            })
          }
        })
    },
    /**
     * Realiza a abertura do estoque
     * @param {integer} stock_closing_id
     */
    handleOpenStock(stock_closing_id, valid_permission = false) {
      if (valid_permission) {
        if (!this.$hasPrivilege(1)) {
          return
        }
      }
      let text = 'Deseja realmente realizar a abertura do estoque?'
      let params = {
        id: stock_closing_id,
        status: 1
      }
      this.$Swal.confirmAction(`${text}`, ['Sim', 'Não'])
        .then(async (result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('stockClosing/update', params).then(async response => {
              this.$snotify.success(response.message, {
                timeout: 2000,
                icon: false,
                position: "leftTop",
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              });
              loader.hide();
              await this.handleUpdateStatus(stock_closing_id, 1)
            }).catch(error => {
              this.$snotify.error(error.data.message, {
                timeout: 2000,
                icon: false,
                position: "leftTop",
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              });
              loader.hide()
            })
          }
        })
    },
    /**
     * Realiza a atualização de status do estoque
     * @param {integer} stock_closing_id
     * @param status
     */ async handleUpdateStatus(stock_closing_id, status) {
      let index = this.$_items.findIndex((item) => item.id === stock_closing_id)
      await this.$set(this.$_items[index], 'status', status)
      this.validatedHasStock()
    },
    /**
     * Realiza a fechamento do estoque
     * @param {integer} stock_closing_id
     */
    handleCloseStock(stock_closing_id) {
      let params = {
        id: stock_closing_id,
        status: 2
      }
      let text = 'Deseja realmente realizar o fechamento do estoque?'
      this.$Swal.confirmAction(`${text}`, ['Sim', 'Não'])
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$store.dispatch('stockClosing/update', params).then(async response => {
              this.$snotify.success(response.message, {
                timeout: 2000,
                icon: false,
                position: "leftTop",
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              });
              loader.hide();
              let closing_product = this.$_items.find((item) => item.id === stock_closing_id)
              await this.$_items.map((item, index) => {
                if (item.closing_at < closing_product.closing_at) {
                  this.$set(this.$_items[index], 'status', 2)
                }
              })
              await this.handleUpdateStatus(stock_closing_id, 3)
            }).catch(error => {
              this.$snotify.error(error.data.message, {
                timeout: 2000,
                icon: false,
                position: "leftTop",
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true
              });
              loader.hide()
            })
          }
        })
    }
  },
  mounted() {
    const list_stock_closing = document.getElementById('list-stock-closing')
    list_stock_closing.addEventListener("scroll", this.navigate)
    this.loadingSkeleton = true
    this.init({})
  },
};
</script>

<style scoped></style>
