<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-2 mb-0">{{ modal.title }}</h5>
      </template>
      <div class="p-2">
        <div class="row d-flex justify-content-center mb-3">
            <div class="col-md-4 p-2">
                <base-button @click="handleCreateModalPix()" block type="success">
                        <i class="fas fa-plus"></i> PIX
                </base-button>
            </div>
            <div class="col-md-4 p-2">
                <base-button @click="handleCreateModalBank()" block type="primary">
                        <i class="fas fa-plus"></i> CONTA
                </base-button>
            </div>
        </div>
        <ListEntityAccount :entityId="entityId" ref="listEntityAccount" />
      </div>
    </modal>
    <ModalCreatePix @fetchEntityAccount="fetchEntityAccount" :entityId="entityId" ref="modalCreatePix" />
    <ModalCreateBank @fetchEntityAccount="fetchEntityAccount" :entityId="entityId" ref="modalCreateBank" />
  </div>
</template>

<script>
import ListEntityAccount from "./Shared/_List";
import ModalCreatePix from "./Shared/_ModalCreatePix";
import ModalCreateBank from "./Shared/_ModalCreateBank";

export default {
    name: "Index",
    components: { ListEntityAccount, ModalCreatePix, ModalCreateBank },
    data() {
        return {
          modal: {
              title: 'Dados Bancários',
              create: false,
          },
          entityId: null,
        };
    },
    methods: {
        closeModal() {
            this.modal.create = false;
        },
        handleCreateModal(entityId) {
          this.entityId = entityId;
          this.modal.create = true;
        },
        handleCreateModalPix() {
            this.$refs.modalCreatePix.handleCreateModal();
        },
        handleCreateModalBank() {
            this.$refs.modalCreateBank.handleCreateModal();
        },
        fetchEntityAccount() {
          this.$refs.listEntityAccount.init();
        }
    },
};
</script>

<style scoped></style>
