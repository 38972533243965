import { createAxios } from "@/plugins/axios";
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'

const endPoint = '/configuration'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    fetch: state => state.items,
  },
  actions: {
    fetchItemsPaginate({commit, state}, params) {
      return createAxios().get(`${endPoint}/product-services`, { params: params })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
