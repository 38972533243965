 <template>
<div>
    <base-header class="bg-gray-content">
       <PuzlBreadcrumb />
        <!-- Header Menus -->
        <div class="row">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                        <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="primary" @click.prevent="handleCreateLimitAdjustment">
                            <i class="fas fa-arrows-alt-v"></i>limite
                        </base-button>
                    </div>
                      <div class="col-md-3 col-lg-2 col-xlg-2 ml-3 mr-3 mb-3 ml-0 p-0">
                        <router-link :to="{ path:'/technology/adjustment'}">
                            <base-button class="pb-3 pt-3 text-uppercase" block size="lg" type="light">
                                <i class="fas fa-undo-alt"></i> voltar
                            </base-button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </base-header>
    <List></List>
    <ModalLimitSetting @createdChargePointLimitSetting="handleCreateAdjustmentList" ref="ModalLimitSetting" />
</div>
</template>

<script>
import List from './Shared/_List'
import ModalLimitSetting from './Shared/_ModalLimitSetting'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";

export default {
    name: "IndexAdjustmentChargePoint",
    components: {
      PuzlBreadcrumb,
      List,
      ModalLimitSetting,
    },
    data() {
        return {
            added: false,
        }
    },
    methods: {
        handleCreateAdjustment() {
            this.$refs.ModalCreateAdjustment.handleCreateModal()
        },
        handleCreateLimitAdjustment() {
            this.$refs.ModalLimitSetting.handleCreateModal()
        },
        handleCreateAdjustmentList() {
            this.added = !this.added
        },
    }
};
</script>

<style></style>
