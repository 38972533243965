<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4 mb-n3">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-warning">
            <img height="30px" src="/img/icons/chemical-plant.png" class="mr-2 mb-1"/>
            CARREGAMENTO
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="col-md-3 px-1">
              <el-popover trigger="click" placement="bottom">
                <base-input input-classes="form-control-sm">
                    <textarea
                      @input="setComment(item)"
                      v-model="travel.comment"
                      type="text"
                      rows="5"
                      maxlength="80"
                      class="form-control form-control-sm"
                    >
                    </textarea>
                </base-input>
                <base-button slot="reference" :disabled="loadingTravel" block size="md"
                             type="white" class="px-1">
                  <img width="20px"
                       src="/img/icons/comment-white.png">
                  Observações da nota
                </base-button>
              </el-popover>
            </div>
            <div class="col-md-2 px-1">
              <base-button :disabled="loadingTravel" @click.prevent="handleSetAdjustments()" block size="md"
                           type="white" class="px-1">
                <img src="/img/brand/logo.png" width="20px"/>
                PUZL ADJUST
              </base-button>
            </div>
            <div class="col-md-2 px-1">
              <router-link :to="{
            path: '/operational/schedule/weighing/:schedule_uuid',
            name: 'operational.schedule.weighing.index',
            params: { schedule_uuid: travel.schedule_uuid }
          }">
                <base-button block size="md" type="light" class="px-1" :disabled="!travel">
                  <img src="/img/icons/circled-left.png" width="20px"/>
                  VOLTAR
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <hr class="bg-warning mt-0 mb-2">
        </div>
        <div class="show-mobile col-md-4 mt-1">
          <card style="border: 1px solid #1a70b7">
            <div class="row">
              <div class="col-2">
                <span data-v-1bed47d4="" class="badge badge-default bg-white"
                      style="border: 1px solid #1a70b7;border-radius: 30px;">
                  <img data-v-1bed47d4="" height="28px" src="/img/icons/test-tube.png"></span>
                <br>
                <span class="badge badge-default" style="font-size: 10px;width: 2.85rem">
                   {{ travel && travel.schedule && travel.schedule.contract_proposal_formulation.mix_code }}
                </span>
              </div>
              <div class="col-10 text-left mt-n1">
                <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-500">
                  {{ travel.schedule.contract_proposal_formulation.title }}
                </h6>
                <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                  {{ travel.schedule.contract_proposal_formulation.body }}
                </h6>
                <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                  {{ travel.schedule.contract_proposal_formulation.footer }}
                </h6>
                <div v-for="(puzl_addtion, index) in travel.schedule.contract_proposal_formulation.puzl_additions"
                     :key="puzl_addtion.id">
                  <h5 class="text-indigo">
                    {{ puzl_addtion.name }}
                    ({{ puzl_addtion.volume }} <span style="text-transform: none">kg/m</span><sup>3</sup>)
                    <span class="text-indigo"
                          v-if="index != travel.schedule.contract_proposal_formulation.puzl_additions.length - 1">+</span>
                  </h5>
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="show-mobile col-md-8 mt-n4">
          <div class="row">
            <div class="col-md-12" v-if="!loadingTravel">
              <base-button block v-if="!travel.number" outline type="primary" style="background: white !important">
                <a href="#" @click.prevent="generateNumber">
                  <img class="ml-0" :class="!travel.number ? 'zoom-img' : ''" width="44px" src="/img/icons/os.png">
                  <span
                    v-if="travel.number" class="text-default" style="text-transform: none">nº </span><span
                  style="font-size: 0.9rem">
                  <strong v-if="travel.number" class="text-primary">{{ travel.number }}</strong>
                </span>
                </a>
                <img style="opacity: 35%" class="ml-2" width="25px"
                     src="/img/nfe.png">
                <a v-if="!travel.has_xml" :style="![2, 0].includes(travel.status) && 'opacity: 70%'"
                   @click.prevent="setNfe()" class="ml-3" href="#">
                  <i class="far fa-check-square text-primary" v-if="issuer.nfe === true"
                     style="color: #1a70b7 !important;"></i>
                  <i class="far fa-square text-primary" v-else style="color: #1a70b7 !important;"></i>
                </a>
              </base-button>
              <base-button block v-if="travel.number" outline type="primary" style="background: white !important">
                <a href="#" @click.prevent="generateNumber">
                  <img class="ml-0" :class="!travel.number ? 'zoom-img' : ''" width="44px" src="/img/icons/os.png">
                  <span
                    v-if="travel.number" class="text-default" style="text-transform: none">nº </span><span
                  style="font-size: 0.9rem">
                  <strong v-if="travel.number" class="text-primary">{{ travel.number }}</strong>
                </span>
                </a>
                <a v-if="!travel.has_nfe" href="#"
                   @click.prevent.once="!travel.has_xml && !sendNfeDisabled && !travel.has_nfe && !travel.has_xml && issueNfe()">
                  <img class="ml-2"
                       width="25px"
                       src="/img/nfe.png">
                </a>
                <a href="#" v-if="travel.has_nfe" @click.prevent="downloadNfe">
                  <img class="ml-2"
                       width="25px"
                       src="/img/nfe.png">
                </a>
                <span v-if="travel.has_nfe && travel.current_number && !travel.xml"
                      class="text-default ml-2" style="text-transform: none">nº </span>
                <span v-if="travel.has_nfe && travel.current_number && !travel.xml"
                      style="font-size: 0.9rem">
                <strong class="text-primary">{{ travel.current_number }}</strong>
              </span>
                <span v-if="travel.has_nfe && !travel.current_number && !travel.xml" class="text-default ml-2">
                <strong style="font-size: 10px">Gerando...</strong>
              </span>
                <span v-if="travel.has_nfe && travel.current_number && travel.xml"
                      class="text-default ml-2" style="text-transform: none">nº </span>
                <span v-if="travel.has_nfe && travel.current_number && travel.xml"
                      style="font-size: 0.9rem">
                <strong class="text-primary">{{ travel.xml.number }}</strong>
              </span>
                <a v-if="!travel.has_nfe" :style="![2, 0].includes(travel.status) && 'opacity: 70%'"
                   @click.prevent="setNfe()" class="ml-3" href="#">
                  <i class="far fa-check-square text-primary" v-if="issuer.nfe === true"
                     style="color: #1a70b7 !important;"></i>
                  <i class="far fa-square text-primary" v-else style="color: #1a70b7 !important;"></i>
                </a>
              </base-button>
            </div>
            <div class="col-md-12 pt-2">
              <h6 class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                CÓDIGO: {{ $helper.optionalChaining(travel, 'schedule.contract_proposal.code') }}
              </h6>
              <h6 class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                CLIENTE: {{
                  $helper.optionalChaining(travel,
                    'schedule.contract_proposal.construction.customer_construction.customer_name')
                }}
              </h6>
              <h6 class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                OBRA: {{
                  $helper.optionalChaining(travel, 'schedule.contract_proposal.construction.construction_name')
                }}
              </h6>
              <h6 v-if="travel.finished_at" class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                {{ travel.finished_at | parseDate('DD/MM/YYYY H:mm') }}
              </h6>
              <h6 v-if="(now_temperature && travel.temperature) && Math.abs(now_temperature - travel.temperature) >= 2"
                  class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                <img width="19" src="https://img.icons8.com/ios/100/db4539/error--v1.png" alt="error--v1"/>
                Alta variação de temperatura identificada!
              </h6>
            </div>
          </div>
        </div>
        <div class="show-md col-md-8 mt-1">
          <div class="row">
            <div class="col-md-12" v-if="!loadingTravel">
              <base-button v-if="!travel.number" outline type="primary" style="background: white !important">
                <a href="#" @click.prevent="generateNumber">
                  <img class="ml-0" :class="!travel.number ? 'zoom-img' : ''" width="44px" src="/img/icons/os.png">
                  <span
                    v-if="travel.number" class="text-default" style="text-transform: none">nº </span><span
                  style="font-size: 0.9rem">
                  <strong v-if="travel.number" class="text-primary">{{ travel.number }}</strong>
                </span>
                </a>
                <img style="opacity: 35%" class="ml-2" width="25px"
                     src="/img/nfe.png">
                <a v-if="!travel.has_xml" :style="![2, 0].includes(travel.status) && 'opacity: 70%'"
                   @click.prevent="setNfe()" class="ml-3" href="#">
                  <i class="far fa-check-square text-primary" v-if="issuer.nfe === true"
                     style="color: #1a70b7 !important;"></i>
                  <i class="far fa-square text-primary" v-else style="color: #1a70b7 !important;"></i>
                </a>
              </base-button>
              <base-button v-if="travel.number" outline type="primary" style="background: white !important">
                <a href="#" @click.prevent="generateNumber">
                  <img class="ml-0" :class="!travel.number ? 'zoom-img' : ''" width="44px" src="/img/icons/os.png">
                  <span
                    v-if="travel.number" class="text-default" style="text-transform: none">nº </span><span
                  style="font-size: 0.9rem">
                  <strong v-if="travel.number" class="text-primary">{{ travel.number }}</strong>
                </span>
                </a>
                <a v-if="!travel.has_nfe" href="#"
                   @click.prevent.once="!travel.has_xml && !sendNfeDisabled && !travel.has_nfe && !travel.has_xml && issueNfe()">
                  <img class="ml-2"
                       width="25px"
                       src="/img/nfe.png">
                </a>
                <a href="#" v-if="travel.has_nfe" @click.prevent="downloadNfe">
                  <img class="ml-2"
                       width="25px"
                       src="/img/nfe.png">
                </a>
                <span v-if="travel.has_nfe && travel.current_number && !travel.xml"
                      class="text-default ml-2" style="text-transform: none">nº </span>
                <span v-if="travel.has_nfe && travel.current_number && !travel.xml"
                      style="font-size: 0.9rem">
                <strong class="text-primary">{{ travel.current_number }}</strong>
              </span>
                <span v-if="travel.has_nfe && !travel.current_number && !travel.xml" class="text-default ml-2">
                <strong style="font-size: 10px">Gerando...</strong>
              </span>
                <span v-if="travel.has_nfe && travel.current_number && travel.xml"
                      class="text-default ml-2" style="text-transform: none">nº </span>
                <span v-if="travel.has_nfe && travel.current_number && travel.xml"
                      style="font-size: 0.9rem">
                <strong class="text-primary">{{ travel.xml.number }}</strong>
              </span>
                <a v-if="!travel.has_nfe" :style="![2, 0].includes(travel.status) && 'opacity: 70%'"
                   @click.prevent="setNfe()" class="ml-3" href="#">
                  <i class="far fa-check-square text-primary" v-if="issuer.nfe === true"
                     style="color: #1a70b7 !important;"></i>
                  <i class="far fa-square text-primary" v-else style="color: #1a70b7 !important;"></i>
                </a>
              </base-button>
            </div>
            <div class="col-md-12 pt-2">
              <h6 class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                CÓDIGO: {{ $helper.optionalChaining(travel, 'schedule.contract_proposal.code') }}
              </h6>
              <h6 class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                CLIENTE: {{
                  $helper.optionalChaining(travel,
                    'schedule.contract_proposal.construction.customer_construction.customer_name')
                }}
              </h6>
              <h6 class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                OBRA: {{
                  $helper.optionalChaining(travel, 'schedule.contract_proposal.construction.construction_name')
                }}
              </h6>
              <h6 v-if="travel.finished_at" class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                {{ travel.finished_at | parseDate('DD/MM/YYYY H:mm') }}
              </h6>
              <h6 v-if="(now_temperature && travel.temperature) && Math.abs(now_temperature - travel.temperature) >= 2"
                  class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                <img width="19" src="https://img.icons8.com/ios/100/db4539/error--v1.png" alt="error--v1"/>
                Alta variação de temperatura identificada!
              </h6>
            </div>
          </div>
        </div>
        <div class="show-md col-md-4 mt-1">
          <card style="border: 1px solid #1a70b7">
            <div class="row">
              <div class="col-2">
                <span data-v-1bed47d4="" class="badge badge-default bg-white"
                      style="border: 1px solid #1a70b7;border-radius: 30px;">
                  <img data-v-1bed47d4="" height="28px" src="/img/icons/test-tube.png"></span>
                <br>
                <span class="badge badge-default" style="font-size: 10px;width: 2.85rem">
                   {{ travel.schedule.contract_proposal_formulation.mix_code }}
                </span>
              </div>
              <div class="col-10 text-left mt-n1">
                <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-500">
                  {{ travel.schedule.contract_proposal_formulation.title }}
                </h6>
                <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                  {{ travel.schedule.contract_proposal_formulation.body }}
                </h6>
                <h6 class="h5 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                  {{ travel.schedule.contract_proposal_formulation.footer }}
                </h6>
                <div v-for="(puzl_addtion, index) in travel.schedule.contract_proposal_formulation.puzl_additions"
                     :key="puzl_addtion.id">
                  <h5 class="text-indigo">
                    {{ puzl_addtion.name }}
                    ({{ puzl_addtion.volume }} <span style="text-transform: none">kg/m</span><sup>3</sup>)
                    <span class="text-indigo"
                          v-if="index != travel.schedule.contract_proposal_formulation.puzl_additions.length - 1">+</span>
                  </h5>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- pr-6 pl-6 pt-3 pb-3 -->
      <!-- Header Menus -->
      <modal-reference ref="modalReference"/>
    </base-header>
    <template>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <div v-if="loadingTravel" class="container-fluid">
          <div class="row mb-n2">
            <div class="col-md-8 pr-md-1">
              <div class="row">
                <div class="col-12">
                  <card>
                    <!--                    CABEÇALHO-->
                    <div class="row mt-n2 mb-n4">
                      <div class="col-md-8 p-2 mb-n2 ">
                        <h5 style="color: #cbcaca !important" class="h3 p-1 mb-0">
                          <img height="30px" src="/img/icons/icons8/ios/info-squared-secondary.png" class="mr-3"/>
                          IDENTIFICAÇÃO </h5>
                      </div>
                      <div class="col-12 p-2">
                        <hr style="color: #cbcaca !important" class="mt-0 mb-2">
                      </div>
                      <div class="col-12">
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-4" type="button"/>
                      </div>
                    </div>
                  </card>
                </div>
                <div class="col-12 mt-n4">
                  <card>
                    <!--                    CABEÇALHO-->
                    <div class="row mt-n2 mb-n2">
                      <div class="col-md-8 p-2 mb-n2 ">
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="30px" src="/img/icons/concrete-pump.png" class="mr-3"/>
                          BOMBA </h5>
                      </div>
                      <div class="col-12 p-2">
                        <hr style="color: #cbcaca !important" class="mt-0 mb-2">
                      </div>
                      <div class="col-12">
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-4" type="button"/>
                      </div>
                    </div>
                  </card>
                </div>
                <div class="col-12 mt-n4">
                  <card>
                    <!--                    CABEÇALHO-->
                    <div class="row mt-n2 mb-n2">
                      <div class="col-md-8 p-2 mb-n2 ">
                        <h5 style="color: #cbcaca !important" class="h3 p-1 mb-0">
                          <img height="30px" src="/img/icons/icons8/ios/plus-math-secondary.png" class="mr-3"/>
                          ADICIONAIS </h5>
                      </div>
                      <div class="col-12 p-2">
                        <hr style="color: #cbcaca !important" class="mt-0 mb-2">
                      </div>
                      <div class="col-12">
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-4" type="button"/>
                      </div>
                    </div>
                  </card>
                </div>
                <div class="col-12 mt-n4">
                  <card>
                    <!--                    CABEÇALHO-->
                    <div class="row mt-n2 mb-n2">
                      <div class="col-md-8 p-2 mb-n2 ">
                        <h5 style="color: #cbcaca !important" class="h3 p-1 mb-0">
                          <img height="30px" src="/img/icons/icons8/ios/recycle-secondary.png" class="mr-3"/>
                          REUSO </h5>
                      </div>
                      <!--                      <div class="col-md-4 text-right p-2">-->
                      <!--                        <base-button block type="white" class="border border-dark">-->
                      <!--                          <img width="19" src="https://img.icons8.com/ios/100/2b2d32/graph-report.png"-->
                      <!--                               alt="graph-report"/> RELATÓRIO-->
                      <!--                        </base-button>-->
                      <!--                      </div>-->
                      <div class="col-12 p-2">
                        <hr style="color: #cbcaca !important" class="mt-0 mb-2">
                      </div>
                      <div class="col-12">
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-2" type="button"/>
                        <skeleton-puzl class="mb-4" type="button"/>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
            <div class="col-md-4 pl-md-1">
              <div class="card">
                <div class="card-body" style="margin-bottom: 50px">
                  <!--                    CABEÇALHO-->
                  <div class="row mt-n2 mb-n5">
                    <div class="col-md-12 p-2 mb-n2 ">
                      <h5 style="color: #cbcaca !important" class="h3 p-1 mb-0">
                        <img width="32" class="mr-3"
                             src="/img/icons/icons8/ios/concrete-mixer-secondary.png"
                        />
                        CARREGAMENTO </h5>
                    </div>
                    <div class="col-12 p-2">
                      <hr style="color: #cbcaca !important" class="mt-0 mb-2">
                    </div>
                    <div class="col-12">
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                      <skeleton-puzl class="mb-2" type="button"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="container-fluid">
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <div class="row">
                <div class="col-12">
                  <card>
                    <!--                    CABEÇALHO-->
                    <div class="row mt-n2 mb-n4">
                      <div class="col-md-4 show-md p-2 mb-n2 ">
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3"/>
                          IDENTIFICAÇÃO </h5>
                      </div>
                      <!--                      md-->
                      <div class="show-md col-md-4 text-center p-2 mb-n2 ">
                        <h5 v-show="travel.schedule.contract_proposal_formulation.concrete_removal"
                            class="h3 new-default-black-font p-1 mb-0"><span class="badge text-white badge-danger"
                                                                             style="background: rgb(229 123 123);"><i
                          class="fa-solid fa-truck-ramp-box"></i> RETIRA DE TERCEIROS</span>
                        </h5>
                      </div>
                      <div class="show-md col-md-4 p-2 mb-n2 text-right">
                        <a href="#" @click.prevent="handleCreateTemperature(travel.send_plant.id)">
                          <template v-if="travel.temperature >= 25">
                            <i style="font-size: 19px" class="fa-solid text-danger fa-temperature-high"></i>
                          </template>
                          <template v-if="travel.temperature < 25 && travel.temperature >= 20">
                            <i style="font-size: 19px" class="fa-solid fa-temperature-half text-grey2"></i>
                          </template>
                          <template v-if="travel.temperature < 20">
                            <i style="font-size: 19px" class="fa-solid text-primary fa-temperature-low"></i>
                          </template>
                        </a>
                        {{ travel.temperature }} ºC
                      </div>
                      <!--mobile-->

                      <div class="col-6 show-mobile p-2 mb-n2 ">
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3"/>
                          IDENTIFICAÇÃO </h5>
                      </div>
                      <div class="show-mobile col-6 p-2 mb-n2 text-right">
                        <a href="#" @click.prevent="handleCreateTemperature(travel.send_plant.id)">
                          <template v-if="travel.temperature >= 25">
                            <i style="font-size: 19px" class="fa-solid text-danger fa-temperature-high"></i>
                          </template>
                          <template v-if="travel.temperature < 25 && travel.temperature >= 20">
                            <i style="font-size: 19px" class="fa-solid fa-temperature-half text-grey2"></i>
                          </template>
                          <template v-if="travel.temperature < 20">
                            <i style="font-size: 19px" class="fa-solid text-primary fa-temperature-low"></i>
                          </template>
                        </a>
                        {{ travel.temperature }} ºC
                      </div>
                      <div class="show-mobile col-md-4  p-2 mb-n2 ">
                        <h5 v-show="travel.schedule.contract_proposal_formulation.concrete_removal"
                            class="h3 new-default-black-font p-1 mb-0"><span class="badge text-white badge-danger"
                                                                             style="background: rgb(229 123 123);"><i
                          class="fa-solid fa-truck-ramp-box"></i> RETIRA DE TERCEIROS</span>
                        </h5>
                      </div>
                      <div class="col-12 p-2">
                        <hr class="new-default-black mt-0 mb-2">
                      </div>
                      <div class="col-12 p-2">
                        <div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-5 pb-1 col-form-label form-control-label">
                              Central
                            </label>
                            <div class="col-md-7 pl-2">
                              <validation-provider rules="required">
                                <base-input input-classes="form-control-sm">
                                  <el-select
                                    :disabled="categories_mcc.length > 0 || allowEditing(travel.status) || travel.has_xml || travel.has_nfe || travel.number"
                                    v-model="travel.send_plant.uuid" size="mini" placeholder="Selecione" filterable
                                    :loading="loadingCompanyPlant"
                                    @change="travel.company_plant_issuer_id = null;getChargePoint(true)">
                                    <el-option label="Selecione" value=""></el-option>
                                    <el-option v-for="(plant, index) in travel.company_plants" :key="index"
                                               :label="plant.name"
                                               :value="plant.uuid">
                                    </el-option>
                                  </el-select>
                                </base-input>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-5 pb-1 col-form-label form-control-label">
                              CNPJ Emissor
                              <el-popover trigger="click"
                                          placement="right"
                                          class="mb-n4"
                                          v-if="company_plant_fixed_issuer_not_found"
                              >
                                <h5 class="font-weight-normal m-0">CNPJ Emissor fixo não encontrado para central
                                  selecionada.</h5>
                                <base-button outline slot="reference" size="sm" type="secundary"
                                             class="ml-2 mb-n2 mt-n2 shadow-none text-indigo">
                                  <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                                </base-button>
                              </el-popover>
                            </label>
                            <div class="col-md-7 pl-2">
                              <validation-provider rules="required">
                                <base-input input-classes="form-control-sm">
                                  <el-select
                                    :disabled="
                                    travel.schedule.contract_proposal.issuer_fixed ||
                                    categories_mcc.length > 0 ||
                                     allowEditing(travel.status) ||
                                      !travel.send_plant.uuid ||
                                       travel.has_xml ||
                                        travel.has_nfe ||
                                         travel.number ||
                                          company_plant_fixed_issuer_not_found"
                                    v-model="travel.company_plant_issuer_id" size="mini" placeholder="Selecione"
                                    filterable
                                    :loading="loadingCompanyPlant">
                                    <el-option label="Selecione" value=""></el-option>
                                    <el-option v-for="(item, index) in company_plant_issuers" :key="index"
                                               :label="item.alias_name"
                                               :value="item.id">
                                    </el-option>
                                  </el-select>
                                </base-input>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-5 pb-1 col-form-label form-control-label">
                              Ponto de carga
                            </label>
                            <div class="col-md-7 pl-2">
                              <validation-provider rules="required">
                                <base-input input-classes="form-control-sm">
                                  <el-select
                                    :disabled="categories_mcc.length > 0 || allowEditing(travel.status) || travel.number"
                                    filterable
                                    v-if="testChargePoint" v-model="travel.charge_point_uuid" size="mini"
                                    placeholder="Selecione">
                                    <el-option label="Selecione" value=""></el-option>
                                    <el-option @change="setChargePoint" v-for="charge_point in chargePoints"
                                               :key="charge_point.id"
                                               :label="charge_point.name" :value="charge_point.uuid">
                                    </el-option>
                                  </el-select>
                                </base-input>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-5 pb-1 col-form-label form-control-label">
                              Motorista
                            </label>
                            <div class="col-md-7 pl-2">
                              <validation-provider rules="required">
                                <base-input input-classes="form-control-sm">
                                  <el-select @change="setEquipemnt" v-model="travel.driver_uuid" size="mini"
                                             placeholder="Selecione"
                                             filterable :loading="loadingDrivers"
                                             :disabled="!$_operational_permission.is_user_allowed_to_change_driver_and_equip && (loadingDrivers || travel.was_billed)">
                                    <el-option label="Selecione" value=""></el-option>
                                    <el-option v-for="user in drivers" :key="user.uuid" :label="user.name"
                                               :value="user.uuid">
                                    </el-option>
                                  </el-select>
                                </base-input>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-5 pb-1 col-form-label form-control-label">
                              Caminhão
                            </label>
                            <div class="col-md-7 pl-2">
                              <validation-provider rules="required">
                                <base-input input-classes="form-control-sm">
                                  <el-select @change="verifyEquipment()"
                                             :disabled="!$_operational_permission.is_user_allowed_to_change_driver_and_equip && travel.was_billed"
                                             v-model="travel.equipment_uuid" size="mini" placeholder="Selecione"
                                             filterable>
                                    <el-option label="Selecione" value=""></el-option>
                                    <el-option v-for="equipment in equipments" :key="equipment.id"
                                               :label="equipment.code + ' - ' + equipment.plate"
                                               :value="equipment.uuid">
                                    </el-option>
                                  </el-select>
                                </base-input>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1">
                            <label class="col-md-5 pb-1 col-form-label form-control-label">
                              Volume
                            </label>
                            <div class="col-md-7 pl-2">
                              <validation-provider rules="required">
                                <base-input input-group-classes="input-group-sm">
                                  <input @change="verifyEquipment" v-model="travel.volume" v-on:input="
                        travel.volume = formatString(
                          $event.target.value,
                          '0',
                          '29.0',
                          '1'
                        )
                      " inputmode="numeric" class="form-control form-control-sm"
                                         :disabled="categories_mcc.length > 0 || allowEditing(travel.status) || travel.number"/>
                                  <template slot="append">
                                    <small class="input-group-sm p-0 m-0 text-lowercase">
                                      m3
                                      <!--                          <span style="text-transform: none">m</span><sup>3</sup>-->
                                    </small>
                                  </template>
                                </base-input>
                                <input v-model="old_volume" style="display: none"
                                       v-mask="['#', '#,#', '##,#', '###,#', '#.###,#', '##.###,#', '###.###,#', '#.###.###,#', '##.###.###,#']"/>
                              </validation-provider>
                              <h3 v-if="limit_volume"><small class="font-weight-500 text-warning"><i
                                class="fa-solid fa-triangle-exclamation text-warning"></i>
                                Atenção! A capacidade máxima do caminhão selecionado é de <span
                                  class="numeric font-weight-500">{{
                                    limit_volume.toString().replace('.', ',')
                                  }}</span>
                                <span style="text-transform: none;font-size: 0.8rem"> m<sup>3</sup></span>
                              </small>
                              </h3>
                            </div>
                          </div>
                          <div class="form-group row m-0 p-0 mb-1 ">
                            <label class="col-md-5 col-form-label form-control-label ">
                              Lacre
                            </label>
                            <div class="col-md-7 pl-2">
                              <validation-provider rules="required">
                                <base-input input-classes="form-control-sm">
                                  <input v-mask="'####################'" inputmode="numeric" v-model="travel.seal"
                                         class="form-control form-control-sm"
                                         :disabled="travel.was_billed"/>
                                </base-input>
                              </validation-provider>
                            </div>
                          </div>
                          <div v-if="![1, 7, 6].includes(travel.status)" class="d-flex justify-content-between">
                            <div class="col-9">
                              <a
                                :style="travel.status === 1 && 'opacity: 25%' || !travel.hardened_state && 'opacity: 25%'"
                                href="#"
                                @click.prevent="handleHardenedStateTest(travel)">
                                <i class="fas fa-cube fa-2x" style="color: #525f7f"></i>
                              </a>
                              <span class="h5 text-muted d-block">Realizar moldagem?</span>
                            </div>
                            <div class="col-3 float-right text-right">
                              <base-switch size="sm" v-model="travel.hardened_state"
                                           :disabled="travel.status == 1 || !travel.allowed_charging_configuration"
                                           @input="setHardenedState(travel)" type="success" class="success">
                              </base-switch>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12">
                            <div class="float-right mt-4 mr-3">
                              <base-button @click.native="save" :loading="loadingSave" type="success"
                                           native-type="submit"
                                           v-show="!travel.was_billed"
                                           :disabled="invalid || travel.was_billed || needs_aprovation_moisture || needs_aprovation_mcc || needs_authorize">
                                <img src="/img/icons/save.png" width="19px">
                                Salvar
                              </base-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
                <div class="col-12 mt-n4">
                  <card>
                    <!--                    CABEÇALHO-->
                    <div class="row mt-n2 mb-n2">
                      <div class="col-md-12 p-2 mb-n2 ">
                        <i class="sidebar-icon-concrete-pump mr-3" style="height: 30px;"></i>
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="30px" src="/img/icons/concrete-pump.png" class="mr-3"/>
                          BOMBA </h5>
                      </div>
                      <div class="col-12 p-2">
                        <hr class="new-default-black mt-0 mb-2">
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 ">
                      <label class="col-md-5 col-form-label form-control-label ">
                        ORDEM DE SERVIÇO
                      </label>
                      <div class="col-md-7 pl-2">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select
                            :clearable="schedule_travel_status[travel.status] === 'released'"
                            :disabled="schedule_travel_status[travel.status] !== 'released' && !travel.was_billed && !$_operational_permission.is_user_allowed_to_change_driver_and_equip"
                            @change="updateServiceOrder"
                            v-model="travel.schedule_service_id"
                            :items="travel.schedule.services"
                          />
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 ">
                      <label class="col-md-5 col-form-label form-control-label ">
                        Equipamento
                      </label>
                      <div class="col-md-7 pl-2">
                        <base-input disabled input-group-classes="input-group-sm" :value="equipment_service"/>
                      </div>
                    </div>
                  </card>
                </div>
                <div class="col-12 mt-n4">
                  <card>
                    <!--                    CABEÇALHO-->
                    <div class="row mt-n2 mb-n2">
                      <div class="col-md-8 p-2 mb-n2 ">
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="30px" src="/img/icons/icons8/ios/plus-math-2-warning.png" class="mr-3"/>
                          ADICIONAIS </h5>
                      </div>
                      <div style="padding-top: 10px !important" class="col-md-4 p-1">
                        <base-button @click.prevent="$refs.ModalAddAdditionals.openModal()" block
                                     :disabled="categories_mcc.length === 0" size="small" outline type="success"
                                     class="p-1 text-uppercase">
                          <i class="fas fa-plus-square"/> Adicionar
                        </base-button>
                      </div>
                      <!--                      <div class="col-md-4 text-right p-2">-->
                      <!--                        <base-button block type="white" class="border border-dark">-->
                      <!--                          <img width="19" src="https://img.icons8.com/ios/100/2b2d32/graph-report.png"-->
                      <!--                               alt="graph-report"/> RELATÓRIO-->
                      <!--                        </base-button>-->
                      <!--                      </div>-->
                      <div class="col-12 p-2">
                        <hr class="new-default-black mt-0 mb-2">
                      </div>
                      <div v-if="!schedule_additionals" class="col-md-12 text-center">
                        <img width="80px" src="https://cdn-icons-png.flaticon.com/512/7486/7486744.png"/>
                        <h6 style="color: #a8a6a6 !important"
                            class="h4 mb-2 mt-1 new-default-black-font p-1 mb-0 font-weight-400">
                          Nenhum adicional vinculado.
                        </h6>
                      </div>
                      <div class="col-md-12 mb-n4 px-2" v-for="additional in schedule_additionals">
                        <card>
                          <div class="row">
                            <div class="col-8 mt-n3">
                              <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-500">
                                {{ additional && additional.additional && additional.additional.short_description }}
                              </h6>
                            </div>
                            <div class="col-4 text-right mt-n3">
                              <div v-if="additional.default">
                                <base-switch @input="setStatusAdditional(additional)" size="sm"
                                             :disabled="loadingUpdateAdditional" v-model="additional.status"
                                             :type="!additional.status ? 'danger' : 'success'">
                                </base-switch>
                              </div>
                              <div v-else>
                                <a style="margin-right: 7px" href="#"
                                   @click.prevent="removeAdditional(additional.uuid)">
                                  <i class="fa fa-times  fa-2x"></i>
                                </a>
                              </div>
                            </div>
                            <div class="col-12 mt-n14 mb-n5">
                              <h6 class="h5 new-default-black-font p-1 mb-0 font-weight-400">
                                {{
                                  additional.additional.standard_value
                                }} {{ additional.additional.billing_unit_description }}
                              </h6>
                            </div>
                          </div>
                        </card>
                      </div>
                    </div>
                  </card>
                </div>
                <div class="col-12 mt-n4">
                  <card>
                    <!--                    CABEÇALHO-->
                    <div class="row mt-n2 mb-n2">
                      <div class="col-md-3 p-2 mb-n2 ">
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="30px" src="/img/icons/icons8/ios/reuse-success.png" class="mr-3"/>
                          REUSO </h5>
                      </div>
                      <div class="col-md-9">
                      </div>
                      <div class="col-12 p-2">
                        <hr class="new-default-black mt-0 mb-2">
                      </div>
                      <div class="col-12 mt-n3 mb-1"><span data-v-1bed47d4="" style="font-size: 10px;font-weight: 400;"> As opções de REUSO abaixo, serão de SOBRAS informadas com VOLUME “menor ou igual“ ao da ENTREGA ATUAL. </span>
                      </div>
                      <div class="col-12">
                        <div class="form-group row m-0 p-0 mb-1">
                          <label class="col-md-5 pb-1 pl-0 col-form-label form-control-label">
                            Concreto
                            <span class="text-danger">&nbsp;*&nbsp;</span>
                          </label>
                          <div class="col-md-7 pl-2">
                            <base-input input-classes="form-control-sm">
                              <el-select v-model="travel.left_over_uuid" size="mini" placeholder="Selecione" filterable
                                         :loading="loadingConcrete"
                                         :disabled="categories_mcc.length === 0 || travel.isset_manual_dosage || travel.automation_status !== 0">
                                <el-option label="Selecione" value=""></el-option>
                                <el-option style="text-transform: none" :disabled="loadingTravel"
                                           v-if="leftOver.schedule_travel_uuid !== travel.uuid"
                                           v-for="leftOver in travelLeftOvers"
                                           :key="leftOver.id"
                                           :label="'O.S. ' + leftOver.number + ' (' + leftOver.volume + ' m³) ' + $helper.parseDate(leftOver.created_at, 'DD/MM/YYYY') + ' - ' + categories[`${leftOver.category}${leftOver.category_type}`]"
                                           :value="leftOver.uuid">
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row" v-if="showLeftOverStatistic && leftOver">
                          <div class="col-12">
                            <h4 :class="leftOver.category_type === 1 ? 'text-success' : 'text-primary'">
                              {{ categories[`${leftOver.category}${leftOver.category_type}`] }}
                            </h4>
                          </div>
                          <template v-for="statistic in leftOverStatistic">
                            <div v-if="statistic && statistic.show" class="col-md-7" :class="statistic.loading">
                      <span style="color: #6C6E71" class="" v-if="!statistic.loading">{{
                          statistic.title
                        }} : <b style="color: #2d3748">{{ statistic.subtitle }}</b></span>
                              <span :class="statistic.loading && 'text-muted'" v-else>{{ statistic.title }}...</span>
                            </div>
                            <div v-if="statistic && statistic.show" class="col-md-5 text-left">
                              <span class="mr-1" style="color: #6C6E71" v-if="!statistic.no_check">Status:</span>
                              <span v-if="statistic.no_check && !statistic.loading"></span>
                              <span class="text-success" v-else-if="statistic.is_checked"><i
                                class="fas fa-check"></i></span>
                              <span v-else-if="statistic.loading">
                        <img style="width: 1rem"
                             src="https://cadastro.auniversidadebrasil.com.br/img/loader_keds.gif"/>
                      </span>
                              <span class="text-danger" v-else><i class="fas fa-ban"></i></span>
                            </div>
                          </template>
                          <div class="col-12" v-if="travel.incompatible_groups && travel.incompatible_groups.length">
                            <h4 class="text-danger incompatible_groups" style="opacity: 75%">Grupos incompátiveis: <span
                              class="text-default" v-for="(item, index) in travel.incompatible_groups">{{ item }} <span
                              v-if="index + 1 < travel.incompatible_groups.length"> + </span></span></h4>
                          </div>
                          <div class="col-12 mt-4" v-if="leftOverStatistic.needs_validation">
                            Autorizado por:
                            <span class="h4 text-primary">
                      {{ leftOverStatistic.user.name }}
                    </span>
                            em
                            <span class="h4">{{ leftOverStatistic.created_at | parseDate }}</span>.
                          </div>
                          <div class="col-12 mt-1" v-if="travel.left_over_observation">
                            Observação:
                            <span class="h4" style="color: #4a5568">
                      {{ travel.left_over_observation }}
                    </span>
                          </div>
                          <div class="col-12 mt-4" v-if="valid_left_over === false">
                            <h4 class="text-warning"><i class="fas fa-exclamation-triangle"></i>Este traço não permite
                              reuso
                            </h4>
                            <h5 class="text-muted">Este concreto não pode ser reutilizado</h5>
                            <div class="row">
                              <div class="col-md-6">
                                <base-input v-model="leftOverObservation" label="Observações"/>
                              </div>
                              <div class="col-6"/>
                            </div>
                            <br>
                            <div class="row">
                              <div class="col-12">
                                <base-button :loading="loadingSaveReuse" v-if="needs_authorize"
                                             :disabled="!$hasPermission('additional_permissions.technical_approvation')"
                                             @click.prevent="autorizeReuse(true)" outline type="primary"
                                             class="text-uppercase mb-1">
                                  Autorizar reuso
                                </base-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-md-1">
              <div class="card">
                <div class="card-body" :style="categories_mcc.length ? 'margin-bottom: 50px' : 'margin-bottom: 20px'">
                  <!--                    CABEÇALHO-->
                  <div class="row mt-n2 mb-n5">
                    <div class="col-md-9 p-2 mb-n2 ">
                      <h5 class="h3 new-default-black-font p-1 mb-0">
                        <img width="32" class="mr-3"
                             src="/img/icons/icons8/ios/concrete-mixer-dark.png"
                        />
                        CARREGAMENTO </h5>
                    </div>
                    <div style="padding-top: 10px !important;" class="col-md-3 p-1">
                      <base-button @click.prevent="downloadReport" block
                                   v-if="categories_mcc.length"
                                   size="small"
                                   style="border: 1px solid black !important;font-size: 12px;background: white"
                                   class="p-1 text-uppercase text-grey2">
                        <img width="19px" src="/img/icons/icons8/ios/graph-report-dark.png"/> RELATÓRIO
                      </base-button>
                    </div>
                    <!--                      <div class="col-md-4 text-right p-2">-->
                    <!--                        <base-button block type="white" class="border border-dark">-->
                    <!--                          <img width="19" src="https://img.icons8.com/ios/100/2b2d32/graph-report.png"-->
                    <!--                               alt="graph-report"/> RELATÓRIO-->
                    <!--                        </base-button>-->
                    <!--                      </div>-->
                    <div class="col-12 p-2">
                      <hr class="new-default-black mt-0 mb-2">
                    </div>


                    <div class="col-12" v-if="!travel.allowed_charging_configuration && !loadingTravel
                    && ![1, 6, 5].includes(travel.status)">
                      <h3 class="text-muted"><i class="text-warning fas fa-exclamation-triangle"></i> Fora do horário
                        permitido para
                        carregamento
                      </h3>
                    </div>

                    <div class="col-12"
                         v-if="!loadingTravel && [0, 15].includes(travel.schedule_status.status) && !categories_mcc.length">
                      <h3 class="text-muted">
                        <i class="text-warning fas fa-exclamation-triangle"></i>
                        A programação está cancelada ou não foi realizada, carregamento não permitido.
                      </h3>
                    </div>

                    <template
                      v-if="travel.allowed_charging_configuration && ![0, 15].includes(travel.schedule_status.status)">
                      <div class="col-12"
                           v-if="travel.validEquipmentAndDriver.driver && !loadingTravel && ![1, 5, 6].includes(travel.status)">
                        <h5 class="text-muted"><i class="text-warning fas fa-exclamation-triangle"></i>
                          O Motorista selecionado já está com uma viagem em
                          andamento</h5>
                      </div>
                      <div class="col-12" v-if="travel.validEquipmentAndDriver.equipment && !loadingTravel &&
                      ![1, 6, 5].includes(travel.status)">
                        <h5 class="text-muted">
                          <i class="text-warning fas fa-exclamation-triangle"></i>
                          O Equipamento selecionado já está com uma viagem em
                          andamento
                        </h5>
                      </div>
                      <div class="col-12"
                           v-if="penultimateDontHasAttachment">
                        <h5 class="text-muted">
                          <i class="text-danger fas fa-exclamation-triangle"></i>
                          O equipamento selecionado está sem anexo na penúltima viagem.
                          <button type="button"
                                  @click.prevent="handleTravelCycle({
                                    uuid: penultimate_travel_without_attachment.schedule_travel_uuid,
                                    address_construction_uuid: penultimate_travel_without_attachment.address_construction_uuid,
                                    })"
                                  class="btn base-button fs-10 new-default-black-font fs-9 new-mini-card-default  btn-light btn-sm new-btn-light">
                            <img
                              src="/img/icons/client-company.png"
                              width="14"><span style="color: #4e4c4c !important;font-family: Quicksand"
                                               class="fs-10 font-weight-600"> {{
                              penultimate_travel_without_attachment.code
                            }}
                              <img width="18px"
                                   src="/img/icons/os.png">  {{ penultimate_travel_without_attachment.number }}
                            </span>
                          </button>
                        </h5>
                      </div>
                    </template>

                    <template v-if="!(!travel.allowed_charging_configuration && !loadingTravel
                    && ![1, 6, 5].includes(travel.status)) && !(!loadingTravel && [0, 15].includes(travel.schedule_status.status))">
                      <div class="col-md-12 mb-1" v-if="binderIsLessConsumption">
                        <h4 class="text-danger">
                          ATENÇÃO! NÃO REALIZE O CARREGAMENTO DESSE CONCRETO SEM ANTES ENTRAR EM CONTATO COM O SETOR
                          DE ENGENHARIA DA EMPRESA!
                        </h4>
                        <div class="mt-n2">
                          <a href="#" @click.prevent="setCheckContact" class="text-success">
                            <i v-if="is_checked_contact" style="font-size: 19px"
                               class="fa-regular fa-square-check text-success"></i>
                            <i style="font-size: 19px" v-else class="fa-regular fa-square text-success"></i>
                            CONTATO REALIZADO, POSSO PROSSEGUIR DESSA FORMA
                          </a>
                        </div>
                      </div>
                    </template>

                    <template v-if="!needs_aprovation_moisture">
                      <div v-if="!categories_mcc.length" class="col-md-4 p-1">
                        <base-button v-if="!$hasPrivilege(1)" style="font-size: 10px !important;"
                                     @click.prevent="handleShowModalMcc" :disabled="isntSaved
                            || needs_aprovation_mcc
                            || categories_mcc.length > 0
                            || disabledMcc
                            || travel.isset_manual_dosage
                            || invalid ||
                            (!saved_travel.equipment_uuid || !saved_travel.seal || !saved_travel.driver_uuid)
                            ||
                            !travel.allowed_charging_configuration
|| loadingBinderConsumption || (binderIsLessConsumption && !is_checked_contact)" block type="warning">
                          <img class="mr-2" width="19" src="https://img.icons8.com/ios/100/FFFFFF/sandbox2.png"
                               alt="sandbox2"/> MCC
                        </base-button>
                        <base-button v-if="$hasPrivilege(1)" style="font-size: 10px !important;"
                                     @click.prevent="handleShowModalMcc" :disabled="isntSaved
                            || needs_aprovation_mcc
                            || categories_mcc.length > 0
                            || disabledMcc
                            || travel.isset_manual_dosage
                            ||
                            (!saved_travel.equipment_uuid || !saved_travel.seal || !saved_travel.driver_uuid)
                            || invalid || loadingBinderConsumption || (binderIsLessConsumption && !is_checked_contact)"
                                     block type="warning">
                          <img class="mr-2" width="19" src="https://img.icons8.com/ios/100/FFFFFF/sandbox2.png"
                               alt="sandbox2"/> MCC
                        </base-button>
                      </div>
                      <div class="col-md-4 p-1" v-if="categories_mcc.length">
                        <base-button class="text-uppercase" :disabled="categories_mcc.length === 0
                          || travel.automation_status != 0
                          || travel.status === 1
                          || travel.status === 6
                          || travel.status === 5 || needs_authorize" block size="small" @click.prevent="setAutomation"
                                     style="font-size: 11px !important;" block type="primary">
                          <img class="mr-2" width="19" src="https://img.icons8.com/ios/100/FFFFFF/bot.png" alt="bot"/>
                          {{ automation_text[travel.automation_status] }}
                        </base-button>
                      </div>
                      <div class="col-md-4 p-1" v-if="travel.automation_status == 1 && !isLocalAutomation()">
                        <base-button class="text-uppercase" @click.prevent="searchAutomation()"
                                     style="font-size: 11px !important;" block type="primary">
                          <i class="fa-solid fa-magnifying-glass"></i>
                          CONSULTAR AUTOMAÇÃO
                        </base-button>
                      </div>
                      <div class="col-md-4 p-1"
                           v-if="categories_mcc.length > 0 && travel.automation_status == 0 && travel.status === 2">
                        <base-button @click.prevent="setManual" style="font-size: 11px !important;"

                                     block
                                     type="success">
                          <img class="mr-2" width="19" src="https://img.icons8.com/ios/100/FFFFFF/manual.png"
                               alt="manual"/> MANUAL
                        </base-button>
                      </div>
                      <template v-if="!travel.was_billed && $_operational_permission.can_clear_schedule_travel_mcc">
                        <div class="col-md-4 p-1" v-if="(categories_mcc.length &&
                        travel.automation_status !== 2 &&
                        ![5, 1, 6].includes(travel.status) && ![0, 15].includes(travel.schedule_status.status)) || $hasPrivilege(1) || travel.status === 5 && ![2, 0].includes(travel.automation_status)">
                          <base-button style="font-size: 11px !important;" @click.prevent="handleClearMcc"
                                       v-show="categories_mcc.length > 0" block
                                       type="danger">
                            <img class="mr-2" width="19" src="https://img.icons8.com/ios/100/FFFFFF/broom.png"
                                 alt="broom"/> LIMPAR
                          </base-button>
                        </div>
                      </template>
                    </template>
                  </div>
                  <div class="mt-4">
                    <!--   VALIDAÇÃO DE HUMIDADE-->
                    <div class="row" v-if="needs_aprovation_moisture">
                      <div class="col-md-12 mt-4">
                          <span><i class="text-warning fa-solid fa-triangle-exclamation"></i>
                            <span style="color: orange;font-size: 0.8rem">
                              <span> Para prosseguir é recomendado atualizar as umidades </span></span>
                          </span>
                      </div>
                    </div>
                    <validation-observer v-slot="{ invalid }" ref="formValidator">
                      <div class="row" v-if="needs_aprovation_moisture" v-for="item in valid_mcc">
                        <div class="col-md-12 mt-2">
                          <h6 class="h4 new-default-black-font p-1 mb-0 font-weight-400">
                            {{ item.description.cmc_category.name }}
                          </h6>
                          <h6 class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                            {{ item.description.cmc_product.product_name }}
                          </h6>
                          <h6 class="h4 mt-n2 new-default-black-font p-1 mb-0 font-weight-400">
                            {{ item.description.cmc_supplier.supplier_name }}
                            ({{ item.description.cmc_supplier_origin.origin_name }})
                          </h6>
                          <validation-provider rules="required" v-slot="{ errors }">
                            <base-input input-group-classes="input-group-sm" width="50%">
                              <input-limit :start_value="item.moisture" :options="{
                                    min: 0.0,
                                    max: item.max_humidity > 0 ? item.max_humidity : 99.9,
                                    decimals: 1,
                                  }" v-model="item.moisture" @value="item.moisture = $event">
                                <small>%</small>
                              </input-limit>
                              <!--                                  <input-->
                              <!--                                    v-model="item.moisture"-->
                              <!--                                    inputmode="numeric"-->
                              <!--                                    :class="errors[0] ? 'is-invalid' : 'is-valid'"-->
                              <!--                                    class="form-control form-control-sm"-->
                              <!--                                    maxlength="5"-->
                              <!--                                  />-->
                              <!--                                  <template slot="append">-->
                              <!--                                    <small class="input-group-sm p-0 m-0">-->
                              <!--                                      %-->
                              <!--                                    </small>-->
                              <!--                                  </template>-->
                            </base-input>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="row mt-2" v-if="needs_aprovation_moisture">
                        <div class="col-md-12">
                          <base-button @click.prevent="cancelAprovationMoisture()" type="secondary"
                                       class="text-capitalize">
                            cancelar
                          </base-button>
                          <base-button :disabled="invalid" @click.prevent="approveMoisture(true)" type="warning"
                                       class="text-capitalize">
                            <i class="fa-solid fa-rotate-right"></i>
                            Adiar
                          </base-button>
                          <base-button :disabled="invalid" @click.prevent="approveMoisture(false)" type="success"
                                       class="text-capitalize">
                            <img src="/img/icons/save.png" width="19px">
                            Salvar
                          </base-button>
                        </div>
                      </div>
                    </validation-observer>
                    <div class="row" v-if="needs_aprovation_mcc">
                      <div class="col-md-12 mt-4">
                        <!--                            AUTORIZAÇÃO DO CARREGAMENTO-->
                        <div class=" table-responsive">
                          <table class="table">
                            <thead>
                            <tr class="thead-light">
                              <th colspan="1" rowspan="1"
                                  class="el-table_1_column_1 text-center is-leaf is-sortable">
                                <div class="cell"><span class="caret-wrapper"><i
                                  class="sort-caret ascending"></i><i
                                  class="sort-caret descending"></i></span>
                                </div>
                              </th>
                              <th colspan="1" rowspan="1"
                                  class="el-table_1_column_1 text-center is-leaf is-sortable">
                                <div class="cell text-danger">Inferior<span class="caret-wrapper"><i
                                  class="sort-caret ascending"></i><i
                                  class="sort-caret descending"></i></span>
                                </div>
                              </th>
                              <th colspan="1" rowspan="1"
                                  class="el-table_1_column_1 text-center is-leaf is-sortable">
                                <div class="cell text-success">Superior<span class="caret-wrapper"><i
                                  class="sort-caret ascending"></i><i
                                  class="sort-caret descending"></i></span>
                                </div>
                              </th>
                              <th colspan="1" rowspan="1"
                                  class="el-table_1_column_1 text-center is-leaf is-sortable">
                                <div class="cell text-primary">Ajuste<span class="caret-wrapper"><i
                                  class="sort-caret ascending"></i><i
                                  class="sort-caret descending"></i></span>
                                </div>
                              </th>
                              <th colspan="1" rowspan="1"
                                  class="el-table_1_column_1 text-center is-leaf is-sortable">
                                <div class="cell text-muted">Status<span class="caret-wrapper"><i
                                  class="sort-caret ascending"></i><i
                                  class="sort-caret descending"></i></span>
                                </div>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in adjustment_groups.parameters">
                              <th class="text-muted text-center">{{ item.name }}</th>
                              <th class="text-muted text-center">{{ item.bottom }}</th>
                              <th class="text-muted text-center">{{ item.top }}</th>
                              <th class="text-center"
                                  :class="item.diff > 0 && 'text-success'
                                      || item.diff < 0 && 'text-danger text-center' || item.diff == 0 && 'text-muted text-center'">
                                {{ parseFloat(item.diff).toFixed(1) }}{{ item.type }}
                              </th>
                              <th class="text-muted text-center">
                                <i v-if="!item.status" class="text-danger fas fa-ban"></i>
                                <i v-else class="text-success fas fa-check"></i>
                              </th>
                            </tr>
                            <tr v-for="item in adjustment_groups.groups">
                              <th class="text-muted text-center">{{ item.name }}</th>
                              <th class="text-muted text-center">{{ item.bottom }}</th>
                              <th class="text-muted text-center">{{ item.top }}</th>
                              <th class="text-center" :class="item.diff > 0 && 'text-success'
                                    || item.diff < 0 && 'text-danger' || item.diff == 0 && 'text-muted'">
                                {{ parseFloat(item.diff).toFixed(1) }}{{ item.type }}
                              </th>
                              <th class="text-muted text-center">
                                <i v-if="!item.status" class="text-danger fas fa-ban"></i>
                                <i v-else class="text-success fas fa-check"></i>
                              </th>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2">
                        <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                          Observações
                        </label>
                        <textarea type="text" v-model="approval_observation" style="height: 100px" rows="2"
                                  class="form-control form-control-sm">
                          </textarea>
                      </div>
                      <div class="col-md-12 mt-2">
                        <base-button @click.prevent="approveCharging" type="success" outline>
                          Autorizar carregamento
                        </base-button>
                      </div>
                    </div>
                  </div>
                  <!--                    MATERIAIS-->
                  <div v-for="(itemMcc, indexCategory) in categories_mcc" class="row mb-n5 mt-5"
                       style="margin-top: 1rem">
                    <!--                      <div class="col-md-4 p-2 text-left pr-0 mb-0 mt-n2">-->
                    <!--                        <h5 class="h3 new-default-black-font p-1 mb-0">-->
                    <!--                          <img height="30px" src="https://img.icons8.com/ios/100/2b2d32/blockly-blue.png" class="mr-3">-->
                    <!--                          {{ itemMcc.name }} </h5>-->
                    <!--                      </div>-->
                    <!--                      <div class="col-8 show-md pl-0 pr-2">-->
                    <!--                        <div style="border-top: 2px solid black;padding-top: 20px !important;margin-top: 20px;"></div>-->
                    <!--                      </div>-->
                    <div class="col-md-12 p-1 mb-n4">
                      <div style=";margin-bottom: 20px">
                        <h5 class="h3 new-default-black-font p-1" style="margin-bottom: -2rem !important">
                          <img height="30px" :src="validHaveManualDosage(itemMcc)" class="mr-3">
                          {{ itemMcc.name }} </h5>
                        <br>
                        <div class="col-12 p-1 mt-1">
                          <div class="row mt-2 show-md" v-for="(group, indexGroup) in itemMcc.groups">
                            <div class="col-7 pr-md-0 h-100 d-table" :style="((itemMcc.id == 6 && group.water_correction)
|| ([2, 3].includes(itemMcc.id) && group.moisture) ||
((group.category_id === 4 || group.category_id === 6) && travel.automation_status == 0)) ? 'height: 165px !important' : 'height: 151px !important'">
                              <div style="border: 1px solid rgb(189 188 188)"
                                   class="card p-2 card-body d-table-cell align-middle border-material-charging">
                                <h6 class="h4 new-default-black-font p-1 mb-0 font-weight-400"
                                    style="line-height: 15px">
                                  {{ group.product || '-' }}: {{ group.supplier || '-' }}
                                  ({{ group.supplier_origin || '-' }})
                                </h6>
                                <h6 class="h4 new-default-black-font p-1 mb-0 font-weight-400"
                                    style="line-height: 14px">
                                  <a href="#" @click.prevent="copyDosageCode(group.dosage.uuid)">{{
                                      group.dosage.text
                                    }}</a>: {{ group.group_name }}
                                  <a href="#" @click.prevent="copyGroupCode(group.id)">
                                  <span class="badge badge-warning ml-0 text-white"
                                        style="font-size: 10px;background: rgb(62 144 79) !important;">
                                 MATERIAL {{ group.group_id }}
                                </span>
                                  </a>
                                </h6>
                                <h6 class="h4 new-default-black-font p-1 mb-0 font-weight-400"
                                    style="line-height: 14px">
                                  UNIDADE: {{ group.dosage.unit.letter || '-' }}
                                </h6>
                                <div class="row mt-0 p-1">
                                  <div v-if="itemMcc.id == 6 && group.water_correction"
                                       class="col-md-4 pr-md-1 pl-md-3">
                                    <div class="input-group input-group-sm">
                                      <input style="height: 32px" readonly type="text" class="form-control numeric"
                                             :placeholder="group.water_correction + ' %'"
                                             aria-describedby="basic-addon2">
                                      <div class="input-group-append">
                                      <span class="input-group-text" id="basic-addon2"
                                      >
                                        <i style="font-size: 19px" class="fa-solid fa-droplet-slash text-info"></i>
                                      </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="[2, 3].includes(itemMcc.id) && group.moisture"
                                       class="col-md-4 pr-md-1 pl-sm-3">
                                    <div class="input-group input-group-sm">
                                      <input style="height: 32px" readonly type="text" class="form-control numeric"
                                             :placeholder="group.moisture + ' %'"
                                             aria-describedby="basic-addon2">
                                      <div class="input-group-append">
                                      <span class="input-group-text" id="basic-addon2"
                                      >
                                        <img width="19" src="https://img.icons8.com/ios/50/1a70b7/humidity.png"
                                             alt="humidity"/></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-8 pl-md-1"
                                       :class="!group.moisture && !group.water_correction && 'pl-md-3'">
                                    <div
                                      v-if="(group.category_id === 4 || group.category_id === 6)"
                                      class="input-group input-group-sm">
                                      <input-limit placeholder="CORTE INDIVIDUAL"
                                                   :disabled="group.manual_dosage > 0 || group.automation"
                                                   :options="{min: 0, max: group.charging.old_wet_weight, decimals: group.category_id === 4 ? 2 : 0}"
                                                   :start_value="group.charging.individual_cut"
                                                   v-model="group.charging.individual_cut"
                                                   @value="group.charging.individual_cut = $event">
                                        <small class="input-group-append p-0 m-0">
                                          <a v-show="group.charging.individual_cut != '-' && !group.manual_dosage > 0"
                                             href="#"
                                             @click.prevent="setIndividualCut(group.id, group.charging.individual_cut)">
                                            <img width="19"
                                                 src="/img/icons/icons8/ios/cut-warning.png"
                                                 alt="cut"/>
                                          </a>
                                          <img v-show="group.charging.individual_cut == '-' || group.manual_dosage > 0"
                                               width="19"
                                               src="/img/icons/icons8/ios/cut-secondary.png"
                                               alt="cut"/>
                                        </small>
                                      </input-limit>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div @click.prevent="handleShowModalDosageManual(indexCategory, indexGroup)"
                                 :class="(group.manual_dosage == null && !group.automation) && 'text-center'"
                                 class="col-5 pl-md-0 h-100 d-table pointer"
                                 :style="((itemMcc.id == 6 && group.water_correction)
|| ([2, 3].includes(itemMcc.id) && group.moisture) ||
((group.category_id === 4 || group.category_id === 6) && travel.automation_status == 0)) ? 'height: 165px !important' : 'height: 151px !important'">
                              <div style="border: 1px solid rgb(189 188 188)"
                                   class="card p-2 card-body d-table-cell align-middle border-material-values-charging">
                                <div class="show-mobile"
                                     style="border: 1px solid #1a70b7;margin-bottom: 5px;margin-right: -8px;margin-left: -8px"></div>
                                <template
                                  v-if="group.manual_dosage == null && !group.automation && !group.charging.hasOwnProperty('accumulated_wet_weight')">
                                  <h1 class="numeric" style="font-size: 30px">{{
                                      decimals(itemMcc.id, group.charging.wet_weight)
                                    }} {{ group.dosage.unit.letter }}</h1>
                                  <template
                                    v-if="travel.automation_status == 0 || group.is_dosage_manual || group.is_dosage_automation">
                                    <h5 style="font-size: 12px" class="mt-n3 font-weight-400">INDIVIDUAL</h5>
                                  </template>
                                  <small v-else>
                                    <sup class="font-weight-400">
                                      <h5 style="font-size: 12px;margin-top: -12px !important;" class="font-weight-400">
                                        AUTOMAÇÃO</h5>
                                      <img
                                        style="margin-top: -12px !important;"
                                        width="50px"
                                        src="/img/icons/puzzle_loading.gif"
                                      />
                                    </sup>
                                  </small>
                                </template>
                                <template
                                  v-if="group.manual_dosage == null && !group.automation
                                        && group.charging.hasOwnProperty('accumulated_wet_weight')">
                                  <h1 class="numeric" style="font-size: 26px">{{
                                      decimals(itemMcc.id, group.charging.accumulated_wet_weight)
                                    }} {{ group.dosage.unit.letter }}</h1>
                                  <h5 style="font-size: 12px" class="mt-n3 font-weight-400">ACUMULADO</h5>
                                </template>
                                <template v-if="group.manual_dosage != null || group.automation != null">
                                  <h6
                                    class="h4 new-default-black-font p-1 mb-0 font-weight-400 numeric"
                                    style="line-height: 14px">
                                    {{ labelGroups[group.charging.accumulated_wet_weight ? 0 : 1] }}:
                                    <template v-if="group.is_individual">
                                      {{ decimals(itemMcc.id, group.charging.wet_weight) }} {{
                                        group.dosage.unit.letter
                                      }}
                                    </template>
                                    <template v-else>
                                      {{ decimals(itemMcc.id, group.charging.accumulated_wet_weight) }}
                                      {{ group.dosage.unit.letter }}
                                    </template>
                                  </h6>
                                  <h6 v-if="group.automation > 0"
                                      class="h4 new-default-black-font p-1 mt-n1 mb-0 mb-n2 font-weight-400 numeric">
                                    Automação:
                                    <span style="font-size: 14px !important" class="text-primary">{{
                                        decimals(itemMcc.id, group.automation)
                                      }} {{ group.dosage.unit.letter }}</span>
                                  </h6>
                                  <template v-if="group.manual_dosage !== null">
                                    <h6
                                      class="h4 new-default-black-font p-1 mb-0 mt-n1 font-weight-400 numeric">
                                      Manual: {{ decimals(itemMcc.id, group.manual_dosage) }} {{
                                        group.dosage.unit.letter
                                      }}
                                    </h6>
                                  </template>
                                  <h6 v-if="group.variation_percentage != null"
                                      class="h4 new-default-black-font p-1 mb-0 mt-n2 font-weight-400 numeric">
                                    Desvio: <span style="font-size: 14px !important;" class="numeric"
                                                  :class="group.variation_value == 0 ? 'text-muted' : group.variation_value > 0 ? 'text-success' : 'text-danger'">
                                    {{ group.variation_value == 0 ? 0 : group.variation_value }} kg
                                    {{ group.variation_percentage }}
                                  </span>
                                  </h6>
                                  <h6 v-if="group.reuse > 0"
                                      class="h4 new-default-black-font p-1 mt-n1 mb-0 font-weight-400 numeric">
                                    Reuso: {{ decimals(itemMcc.id, group.reuse) }} kg
                                  </h6>
                                </template>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2 show-mobile" v-for="(group, indexGroup) in itemMcc.groups">
                            <div class="col-12 pr-md-0 h-100 d-table" :style="((itemMcc.id == 6 && group.water_correction)
|| ([2, 3].includes(itemMcc.id) && group.moisture) ||
((group.category_id === 4 || group.category_id === 6) && travel.automation_status == 0)) ? 'height: 165px !important' : 'height: 151px !important'">
                              <div style="border: 1px solid rgb(189 188 188)"
                                   class="card p-2 card-body d-table-cell align-middle border-material-charging">
                                <h6 class="h4 new-default-black-font p-1 mb-0 font-weight-400"
                                    style="line-height: 14px">
                                  {{ group.product || '-' }}: {{ group.supplier || '-' }}
                                  ({{ group.supplier_origin || '-' }})
                                </h6>
                                <h6 class="h4 new-default-black-font p-1 mb-0 font-weight-400"
                                    style="line-height: 14px">
                                  <a href="#" @click.prevent="copyDosageCode(group.dosage.uuid)">{{
                                      group.dosage.text
                                    }}</a>: {{ group.group_name }}
                                  <a href="#" @click.prevent="copyGroupCode(group.id)">
                                  <span class="badge badge-warning ml-0 text-white"
                                        style="font-size: 10px;background: rgb(62 144 79) !important;">
                                 MATERIAL {{ group.group_id }}
                                </span>
                                  </a>
                                </h6>
                                <h6 class="h4 new-default-black-font p-1 mb-0 font-weight-400"
                                    style="line-height: 14px">
                                  UNIDADE: {{ group.dosage.unit.letter || '-' }}
                                </h6>
                                <div class="row mt-0 p-1">
                                  <div v-if="itemMcc.id == 6 && group.water_correction"
                                       class="col-md-4 pr-md-1 pl-md-3">
                                    <div class="input-group input-group-sm">
                                      <input style="height: 32px" readonly type="text" class="form-control numeric"
                                             :placeholder="group.water_correction + ' %'"
                                             aria-describedby="basic-addon2">
                                      <div class="input-group-append">
                                      <span class="input-group-text" id="basic-addon2"
                                      >
                                        <i style="font-size: 19px" class="fa-solid fa-droplet-slash text-info"></i>
                                      </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="[2, 3].includes(itemMcc.id) && group.moisture"
                                       class="col-md-4 pr-md-1 pl-sm-3">
                                    <div class="input-group input-group-sm">
                                      <input style="height: 32px" readonly type="text" class="form-control numeric"
                                             :placeholder="group.moisture + ' %'"
                                             aria-describedby="basic-addon2">
                                      <div class="input-group-append">
                                      <span class="input-group-text" id="basic-addon2"
                                      >
                                        <img width="19" src="https://img.icons8.com/ios/50/1a70b7/humidity.png"
                                             alt="humidity"/></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-8 pl-md-1"
                                       :class="!group.moisture && !group.water_correction && 'pl-md-3'">
                                    <div
                                      v-if="(group.category_id === 4 || group.category_id === 6)"
                                      class="input-group input-group-sm">
                                      <input-limit placeholder="CORTE INDIVIDUAL"
                                                   :disabled="group.manual_dosage > 0 || group.automation"
                                                   :options="{
                            min: 0,
                            max: group.charging.old_wet_weight,
                            decimals: group.category_id === 4 ? 2 : 0
                          }" :start_value="group.charging.individual_cut" v-model="group.charging.individual_cut"
                                                   @value="group.charging.individual_cut = $event">
                                        <small class="input-group-append p-0 m-0">
                                          <a v-show="group.charging.individual_cut != '-' && !group.manual_dosage > 0"
                                             href="#"
                                             @click.prevent="setIndividualCut(group.id, group.charging.individual_cut)">
                                            <img width="19"
                                                 src="/img/icons/icons8/ios/cut-warning.png"
                                                 alt="cut"/>
                                          </a>
                                          <img v-show="group.charging.individual_cut == '-' || group.manual_dosage > 0"
                                               width="19"
                                               src="/img/icons/icons8/ios/cut-secondary.png"
                                               alt="cut"/>
                                        </small>
                                      </input-limit>
                                    </div>
                                  </div>
                                </div>
                                <div class="show-mobile"
                                     :style="((itemMcc.id == 6 && group.water_correction)
|| ([2, 3].includes(itemMcc.id) && group.moisture) ||
((group.category_id === 4 || group.category_id === 6) && travel.automation_status == 0)) && 'margin-top: 10px'"
                                     style="border: 1px solid #1a70b7;margin-bottom: 5px;margin-right: -8px;margin-left: -8px"></div>
                                <div :class="(!group.manual_dosage && !group.automation) && 'text-center'"
                                     @click.prevent="handleShowModalDosageManual(indexCategory, indexGroup)">
                                  <template
                                    v-if="group.manual_dosage == null && !group.automation && !group.charging.hasOwnProperty('accumulated_wet_weight')">
                                    <h1 class="numeric" style="font-size: 30px">{{
                                        decimals(itemMcc.id, group.charging.wet_weight)
                                      }} {{ group.dosage.unit.letter }}</h1>
                                    <template
                                      v-if="travel.automation_status == 0 || group.is_dosage_manual || group.is_dosage_automation">
                                      <h5 style="font-size: 12px" class="mt-n3 font-weight-400">INDIVIDUAL</h5>
                                    </template>
                                    <small v-else>
                                      <sup class="font-weight-400">
                                        <h5 style="font-size: 12px;margin-top: -12px !important;"
                                            class="font-weight-400">
                                          AUTOMAÇÃO</h5>
                                        <img
                                          style="margin-top: -12px !important;"
                                          width="50px"
                                          src="/img/icons/puzzle_loading.gif"
                                        />
                                      </sup>
                                    </small>
                                  </template>
                                  <template
                                    v-if="group.manual_dosage == null && !group.automation
                                        && group.charging.hasOwnProperty('accumulated_wet_weight')">
                                    <h1 class="numeric" style="font-size: 26px">{{
                                        decimals(itemMcc.id, group.charging.accumulated_wet_weight)
                                      }} {{ group.dosage.unit.letter }}</h1>
                                    <h5 style="font-size: 12px" class="mt-n3 font-weight-400">ACUMULADO</h5>
                                  </template>
                                  <template v-if="group.manual_dosage != null || group.automation != null">
                                    <h6
                                      class="h4 new-default-black-font p-1 mb-0 font-weight-400 text-left numeric"
                                      style="line-height: 14px">
                                      {{ labelGroups[group.charging.accumulated_wet_weight ? 0 : 1] }}:
                                      <template v-if="group.is_individual">
                                        {{ decimals(itemMcc.id, group.charging.wet_weight) }} {{
                                          group.dosage.unit.letter
                                        }}
                                      </template>
                                      <template v-else>
                                        {{ decimals(itemMcc.id, group.charging.accumulated_wet_weight) }}
                                        {{ group.dosage.unit.letter }}
                                      </template>
                                    </h6>
                                    <h6 v-if="group.automation > 0"
                                        class="h4 new-default-black-font p-1 mb-0 font-weight-400 text-left numeric">
                                      Automação:
                                      <span style="font-size: 14px !important" class="text-primary">{{
                                          decimals(itemMcc.id, group.automation)
                                        }} {{ group.dosage.unit.letter }}</span>
                                    </h6>
                                    <template v-if="group.manual_dosage !== null">
                                      <h6
                                        class="h4 new-default-black-font p-1 mb-0 font-weight-400 text-left  numeric">
                                        Manual: {{ decimals(itemMcc.id, group.manual_dosage) }} {{
                                          group.dosage.unit.letter
                                        }}
                                      </h6>
                                    </template>
                                    <h6 v-if="group.variation_percentage != null"
                                        class="h4 new-default-black-font p-1 mb-0 font-weight-400 text-left numeric">
                                      Desvio: <span style="font-size: 14px !important;" class="numeric"
                                                    :class="group.variation_value == 0 ? 'text-muted' : group.variation_value > 0 ? 'text-success' : 'text-danger'">
                                    {{ group.variation_value == 0 ? 0 : group.variation_value }} kg
                                    {{ group.variation_percentage }}
                                  </span>
                                    </h6>
                                    <h6 v-show="group.reuse > 0"
                                        class="h4 new-default-black-font p-1 mb-0 font-weight-400 text-left ">
                                      Reuso: {{ decimals(itemMcc.id, group.reuse) }} kg
                                    </h6>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-if="hasAutomationAndDontUsed()">
                    <div class="row mt-5 mb-n5"
                         v-if="![1, 5, 6].includes(travel.status) && travel.all_isset_manual_dosage">
                      <template v-if="!travel.deviation.is_validated && !is_checked_limit">
                        <div class="col-md-12 mb-n4 mt-2">
                          <h4 class="text-danger">
                            <el-popover trigger="click" placement="bottom" class="p-0">
                              <table v-if="travel.deviation" class="table table-sm table-bordered mb-n1">
                                <thead>
                                <tr>
                                  <th>Categoria</th>
                                  <th>Limite</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td style="font-size:0.8rem !important">Cimento</td>
                                  <td style="font-size:0.8rem !important" class="text-center numeric">{{
                                      travel.deviation.deviations.binder_limit_pct > 0 ? travel.deviation.deviations.binder_limit_pct + ' %' : '-'
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td style="font-size:0.8rem !important">Agregado miúdo</td>
                                  <td style="font-size:0.8rem !important" class="text-center numeric">{{
                                      travel.deviation.deviations.fine_limit_pct > 0 ? travel.deviation.deviations.fine_limit_pct + ' %' : '-'
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td style="font-size:0.8rem !important">Agregado graúdo</td>
                                  <td style="font-size:0.8rem !important" class="text-center numeric">{{
                                      travel.deviation.deviations.coarse_limit_pct > 0 ? travel.deviation.deviations.coarse_limit_pct + ' %' : '-'
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td style="font-size:0.8rem !important">Aditivo</td>
                                  <td style="font-size:0.8rem !important" class="text-center numeric">{{
                                      travel.deviation.deviations.additive_limit_pct > 0 ? travel.deviation.deviations.additive_limit_pct + ' %' : '-'
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td style="font-size:0.8rem !important">Adição</td>
                                  <td style="font-size:0.8rem !important" class="text-center numeric">{{
                                      travel.deviation.deviations.addition_limit_pct > 0 ? travel.deviation.deviations.addition_limit_pct + ' %' : '-'
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td style="font-size:0.8rem !important">Água</td>
                                  <td style="font-size:0.8rem !important" class="text-center numeric">{{
                                      travel.deviation.deviations.water_limit_pct > 0 ? travel.deviation.deviations.water_limit_pct + ' %' : '-'
                                    }}
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <span slot="reference">
                      <img class="pointer" width="19" src="https://img.icons8.com/ios/100/dec120/error--v1.png"
                           alt="error--v1"/>
                      </span>
                            </el-popover>
                            <span style="color: black !important;font-family: system-ui;font-size: 0.8rem !important;">
                                 Existe desvio de material acima do limite permitido, marque essa
                              opção para concluir o carregamento
                              <i @click.prevent="setCheckLimit()" v-if="is_checked_limit" style="font-size: 19px"
                                 class="fa-regular fa-square-check text-success pointer"></i>
                              <i @click.prevent="setCheckLimit()" style="font-size: 19px" v-else
                                 class="fa-regular fa-square text-success pointer"></i>
                            </span>
                          </h4>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col-md-12 p-1 mt-2">
                          <label class="form-control-label">Observações de carregamento</label>
                          <textarea :disabled="[1, 6].includes(travel.status)" v-model="travel.observation"
                                    maxlength="200" class="form-control" rows="3"
                                    placeholder=""></textarea>
                        </div>
                        <div class="col-md-12 p-1">
                          <h6 data-v-1bed47d4="" class="h4 new-default-black-font p-1 mb-0 font-weight-400">
                            <img width="19" src="https://img.icons8.com/ios/100/db4539/error--v1.png" alt="error--v1"/>
                            AO CONCLUIR O CARREGAMENTO, VOCÊ SE RESPONSABILIZA PELAS QUANTIDADES GERADAS PELO PUZL MIX.
                          </h6>
                        </div>
                        <div class="col-md-12 p-1 text-right">
                          <base-button style="font-size: 0.88rem;z-index: 1"
                                       type="primary"
                                       size="sm" outline @click.prevent="!concluding && completeCharging()"
                                       class="mt-0 mb-2 text-uppercase">
                            <i class="fa-regular fa-circle-check"></i> Concluir carregamento
                          </base-button>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="![1, 5, 6].includes(travel.status) && travel.all_isset_manual_dosage">
                      <div class="row mt-5 mb-n5">
                        <div class="col-md-12">
                          <h6 class="text-danger h5">
                            Usuário não possui permissão para concluir carregamento manual.
                          </h6>
                        </div>
                      </div>
                    </template>
                  </template>
                  <div class="row mt-5" v-if="[1, 5].includes(travel.status) && travel.all_isset_manual_dosage">
                    <div class="col-md-12 p-1 mt-2" v-show="travel.observation">
                      <label class="form-control-label">Observações de carregamento</label>
                      <textarea disabled v-model="travel.observation" maxlength="200" class="form-control" rows="3"
                                placeholder=""></textarea>
                    </div>
                    <div style="margin-bottom: -3rem" class="col-12 mt-2 p-1">
                  <span>Carregamento concluído por
                    <span class="text-primary">{{ travel.user_name }} </span>
                    <span>em {{ travel.finished_at | parseDate }}.</span>
                  </span>
                    </div>
                  </div>
                  <div class="row mb-2"
                       v-if="travel.automation_status === 4 && !needs_aprovation_moisture && !start_at">
                    <div class="col-md-12 text-center" style="margin-top: 30px !important;">
                      <img width="350px"
                           src="/img/theme/automation_canceled.avif"/>
                      <h4 class="text-danger"> Carregamento concluído com automação cancelada.</h4>
                      <h5 class="mt-n1">FAVOR REALIZE NOVAMENTE O CARREGAMENTO.</h5>
                    </div>
                  </div>
                  <div>
                    <card class="float-danger" v-if="has_another_os">
                      <h5><i class="fa-solid fa-triangle-exclamation text-danger"></i> O.S {{ another_os.number }} sem
                        mcc
                      </h5>
                      <router-link target="_blank"
                                   :to="`/operational/schedule/charging/${another_os.schedule_travel_uuid}`">
                        <base-button size="sm" @click="has_another_os = false">
                          <i class="fa-solid fa-arrow-up-right-from-square"></i> ABRIR VIAGEM
                        </base-button>
                      </router-link>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="scrolling-box" v-show="!categories_mcc.length" style="margin-top: -2%">
          <a href="javascript:void(0)" class="float-moisture" @click.prevent="handleCreateWater()">
            <i style="font-size: 25px; margin-top: 12px" class="text-white fa-solid fa-droplet"></i>
          </a>
          <a href="javascript:void(0)" class="float" @click.prevent="handleCreateMoisture()">
            <i style="font-size: 25px; margin-top: 12px" class="text-white fa-solid fa-droplet"></i>
          </a>
          <a href="javascript:void(0)" class="float-temperature"
             @click.prevent="handleCreateTemperature(travel.send_plant.id)">
            <i style="font-size: 15px" class="text-white fa-solid fa-temperature-high mt-2"></i>
            <br>
            <b style="margin-top: -10px">{{ travel.temperature }}
              °C</b>
          </a>
        </div>
      </validation-observer>
    </template>
    <ModalMCC @setMcc="setMcc" ref="ModalMCC"/>
    <ModalCreateTemperature ref="ModalCreateTemperature" @setNowTemperature="setNowTemperature"
                            @createdTemperature="setTemperature"></ModalCreateTemperature>
    <ModalCreateWater ref="ModalCreateWater"></ModalCreateWater>
    <ModalCreateMoisture ref="ModalCreateMoisture"/>
    <ModalAdjustment ref="ModalAdjustment"/>
    <ModalHardenedStateTest ref="hardenedStateTest"/>
    <modal-manual-dosage ref="ModalManualDosage" @setManualDosage="setManualDosage"/>
    <modal-add-additionals ref="ModalAddAdditionals"/>
    <modal-manual-charging-justification @completeCharging="forceComplete"
                                         ref="modalManualChargingJustification"/>
    <modal size="md" :show.sync="showJustification">
      <template slot="header">
        <h5 class="modal-title">Justificativa</h5>
      </template>
      <div class="row">
        <div class="col-12">
          <label>Informe uma justificativa para desabilitar o adicional</label>
          <textarea v-model="justification" maxlength="150" class="form-control" rows="3"
                    placeholder=""></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <base-button type="secondary" @click="cancelJustification()">
          Cancelar
        </base-button>
        <base-button type="success" @click.native="saveJustification" native-type="submit"
                     :disabled="!justification" :loading="loadingUpdateAdditional">
          Salvar
        </base-button>
      </div>
    </modal>
    <!--    <base-header class="bg-gray-content">-->
    <!--      <div class="row align-items-center py-4">-->
    <!--        <div class="col-lg-12 col-12">-->
    <!--          <h6 class="h2 text-gray d-inline-block mb-0">-->
    <!--            {{ this.$route.meta.breadcrumbs[1].title }}</h6>-->
    <!--          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4 text-gray">-->
    <!--            <ol class="breadcrumb">-->
    <!--              <li class="breadcrumb-item"><a href="#"><i :class="this.$route.meta.icon"></i></a></li>-->
    <!--              <li class="breadcrumb-item"><a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a></li>-->
    <!--              <li class="breadcrumb-item" aria-current="page">-->
    <!--                {{ this.$route.meta.breadcrumbs[1].title }}-->
    <!--              </li>-->
    <!--            </ol>-->
    <!--          </nav>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      &lt;!&ndash; Header Menus &ndash;&gt;-->
    <!--      <div class="row mb-3">-->
    <!--        &lt;!&ndash;        <div class="col-md-2 mb-2" v-show="travel.status !== 1">&ndash;&gt;-->
    <!--        &lt;!&ndash;          <base-button&ndash;&gt;-->
    <!--        &lt;!&ndash;            v-show="travel.status !== 1"&ndash;&gt;-->
    <!--        &lt;!&ndash;            block&ndash;&gt;-->
    <!--        &lt;!&ndash;            size="lg"&ndash;&gt;-->
    <!--        &lt;!&ndash;            type="primary"&ndash;&gt;-->
    <!--        &lt;!&ndash;            class="text-uppercase"&ndash;&gt;-->
    <!--        &lt;!&ndash;            @click.prevent="handleCreateMoisture"&ndash;&gt;-->
    <!--        &lt;!&ndash;          >&ndash;&gt;-->
    <!--        &lt;!&ndash;            umidade&ndash;&gt;-->
    <!--        &lt;!&ndash;          </base-button>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <div class="col-md-2 mb-2" v-show="travel.status !== 1"&ndash;&gt;-->
    <!--        &lt;!&ndash;        >&ndash;&gt;-->
    <!--        &lt;!&ndash;          <base-button&ndash;&gt;-->
    <!--        &lt;!&ndash;            block&ndash;&gt;-->
    <!--        &lt;!&ndash;            size="lg"&ndash;&gt;-->
    <!--        &lt;!&ndash;            type="danger"&ndash;&gt;-->
    <!--        &lt;!&ndash;            class="text-uppercase"&ndash;&gt;-->
    <!--        &lt;!&ndash;            @click.prevent="handleCreateTemperature(travel.send_plant.id)"&ndash;&gt;-->
    <!--        &lt;!&ndash;          >&ndash;&gt;-->
    <!--        &lt;!&ndash;            temperatura&ndash;&gt;-->
    <!--        &lt;!&ndash;          </base-button>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <div class="col-md-2 mb-2" v-show="travel.status !== 1"&ndash;&gt;-->
    <!--        &lt;!&ndash;        >&ndash;&gt;-->
    <!--        &lt;!&ndash;          <base-button&ndash;&gt;-->
    <!--        &lt;!&ndash;            block&ndash;&gt;-->
    <!--        &lt;!&ndash;            size="lg"&ndash;&gt;-->
    <!--        &lt;!&ndash;            type="info"&ndash;&gt;-->
    <!--        &lt;!&ndash;            class="text-uppercase"&ndash;&gt;-->
    <!--        &lt;!&ndash;            @click.prevent="handleCreateWater()"&ndash;&gt;-->
    <!--        &lt;!&ndash;          >&ndash;&gt;-->
    <!--        &lt;!&ndash;            corte de água&ndash;&gt;-->
    <!--        &lt;!&ndash;          </base-button>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        <div class="col-md-2 mb-2">-->
    <!--          <base-button :disabled="loadingTravel" block size="lg" type="indigo" class="text-uppercase"-->
    <!--                       @click.prevent="handleSetAdjustments()">-->
    <!--            <i class="fa-solid fa-puzzle-piece"></i> Ajustes-->
    <!--          </base-button>-->
    <!--        </div>-->
    <!--        <div class="col-md-2 mb-2">-->
    <!--          <router-link :to="{-->
    <!--            path: '/operational/schedule/weighing/:schedule_uuid',-->
    <!--            name: 'operational.schedule.weighing.index',-->
    <!--            params: { schedule_uuid: travel.schedule_uuid }-->
    <!--          }">-->
    <!--            <base-button block size="lg" type="light" class=" " :disabled="!travel">-->
    <!--              VOLTAR-->
    <!--            </base-button>-->
    <!--          </router-link>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </base-header>-->
    <!--    <div class="row">-->
    <!--      <div class="col-md-12">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="container-fluid">-->
    <!--      <div>-->
    <!--        <div class="row -mr-1 mb-3" v-if="loadingTravel">-->
    <!--          <div class="col-md-4">-->
    <!--            <SkeletonPuzl class="mb-2" type="button"/>-->
    <!--          </div>-->
    <!--          <div class="col-md-8"/>-->
    <!--          <div class="col-md-3">-->
    <!--            <SkeletonPuzl class="mb-2" type="button"/>-->
    <!--          </div>-->
    <!--          <div class="col-md-9"/>-->
    <!--          <div class="col-md-4">-->
    <!--            <SkeletonPuzl class="mb-2" type="button"/>-->
    <!--          </div>-->
    <!--          <div class="col-md-8"/>-->
    <!--        </div>-->
    <!--        <div class="row mr-1 mb-3">-->
    <!--          <div class="col-md-12 mb-4" v-if="!loadingTravel">-->
    <!--            <base-button v-if="!travel.number" outline type="primary" style="background: white !important">-->
    <!--              <a href="#" @click.prevent="generateNumber">-->
    <!--                <img class="ml-0" :class="!travel.number ? 'zoom-img' : ''" width="44px" src="/img/icons/os.png"> <span-->
    <!--                v-if="travel.number" class="text-default" style="text-transform: none">nº </span><span-->
    <!--                style="font-size: 0.9rem">-->
    <!--                  <strong v-if="travel.number" class="text-primary">{{ travel.number }}</strong>-->
    <!--                </span>-->
    <!--              </a>-->
    <!--              <img style="opacity: 35%" class="ml-2" width="25px"-->
    <!--                   src="https://puzlmix.s3.sa-east-1.amazonaws.com/icons/nfe.png">-->
    <!--              <a v-if="!travel.has_xml" :style="![2, 0].includes(travel.status) && 'opacity: 70%'"-->
    <!--                 @click.prevent="setNfe()" class="ml-3" href="#">-->
    <!--                <i class="far fa-check-square text-primary" v-if="issuer.nfe === true"-->
    <!--                   style="color: #1a70b7 !important;"></i>-->
    <!--                <i class="far fa-square text-primary" v-else style="color: #1a70b7 !important;"></i>-->
    <!--              </a>-->
    <!--            </base-button>-->
    <!--            <base-button v-if="travel.number" outline type="primary" style="background: white !important">-->
    <!--              <a href="#" @click.prevent="generateNumber">-->
    <!--                <img class="ml-0" :class="!travel.number ? 'zoom-img' : ''" width="44px" src="/img/icons/os.png"> <span-->
    <!--                v-if="travel.number" class="text-default" style="text-transform: none">nº </span><span-->
    <!--                style="font-size: 0.9rem">-->
    <!--                  <strong v-if="travel.number" class="text-primary">{{ travel.number }}</strong>-->
    <!--                </span>-->
    <!--              </a>-->
    <!--              <a v-if="!travel.has_nfe" href="#"-->
    <!--                 @click.prevent.once="!travel.has_xml && !sendNfeDisabled && !travel.has_nfe && !travel.has_xml && issueNfe()">-->
    <!--                <img class="ml-2"-->
    <!--                     width="25px"-->
    <!--                     src="https://puzlmix.s3.sa-east-1.amazonaws.com/icons/nfe.png">-->
    <!--              </a>-->
    <!--              <a href="#" v-if="travel.has_nfe" @click.prevent="downloadNfe">-->
    <!--                <img class="ml-2"-->
    <!--                     width="25px"-->
    <!--                     src="https://puzlmix.s3.sa-east-1.amazonaws.com/icons/nfe.png">-->
    <!--              </a>-->
    <!--              <span v-if="travel.has_nfe && travel.current_number && !travel.xml"-->
    <!--                    class="text-default ml-2" style="text-transform: none">nº </span>-->
    <!--              <span v-if="travel.has_nfe && travel.current_number && !travel.xml"-->
    <!--                    style="font-size: 0.9rem">-->
    <!--                <strong class="text-primary">{{ travel.current_number }}</strong>-->
    <!--              </span>-->
    <!--              <span v-if="travel.has_nfe && !travel.current_number && !travel.xml" class="text-default ml-2">-->
    <!--                <strong style="font-size: 10px">Gerando...</strong>-->
    <!--              </span>-->
    <!--              <span v-if="travel.has_nfe && travel.current_number && travel.xml"-->
    <!--                    class="text-default ml-2" style="text-transform: none">nº </span>-->
    <!--              <span v-if="travel.has_nfe && travel.current_number && travel.xml"-->
    <!--                    style="font-size: 0.9rem">-->
    <!--                <strong class="text-primary">{{ travel.xml.number }}</strong>-->
    <!--              </span>-->
    <!--              <a v-if="!travel.has_nfe" :style="![2, 0].includes(travel.status) && 'opacity: 70%'"-->
    <!--                 @click.prevent="setNfe()" class="ml-3" href="#">-->
    <!--                <i class="far fa-check-square text-primary" v-if="issuer.nfe === true"-->
    <!--                   style="color: #1a70b7 !important;"></i>-->
    <!--                <i class="far fa-square text-primary" v-else style="color: #1a70b7 !important;"></i>-->
    <!--              </a>-->
    <!--            </base-button>-->

    <!--            &lt;!&ndash;            <h2 class="text-info mb-2">&ndash;&gt;-->
    <!--            &lt;!&ndash;              <img width="55px"&ndash;&gt;-->
    <!--            &lt;!&ndash;                   src="/img/icons/os.png">&ndash;&gt;-->

    <!--            &lt;!&ndash;              <base-button>&ndash;&gt;-->

    <!--            &lt;!&ndash;              </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </h2>&ndash;&gt;-->
    <!--          </div>-->
    <!--          <div class="col-md-6" v-if="!loadingTravel">-->
    <!--            <div class="checklist-item checklist-item-danger">-->
    <!--              <div class="checklist-info" v-if="travel">-->
    <!--                &lt;!&ndash;                <h2 class="text-info mb-2" v-if="travel.number">&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <small class="text-default"><b><img width="80px"&ndash;&gt;-->
    <!--                &lt;!&ndash;                                                      src="https://puzlmix.s3.sa-east-1.amazonaws.com/icons/nfe.png">&ndash;&gt;-->
    <!--                &lt;!&ndash;                    <span>&ndash;&gt;-->
    <!--                &lt;!&ndash;                    n°&ndash;&gt;-->
    <!--                &lt;!&ndash;                    </span>&ndash;&gt;-->
    <!--                &lt;!&ndash;                  </b></small>&ndash;&gt;-->
    <!--                &lt;!&ndash;                  {{ travel.number }}&ndash;&gt;-->
    <!--                &lt;!&ndash;                </h2>&ndash;&gt;-->
    <!--                <h4 class="checklist-title mb-0" style="margin-top: -10px">-->
    <!--                  <span v-if="travel.finished_at">-->
    <!--                   {{ travel.finished_at | parseDate('DD/MM/YYYY hh:mm') }}-->
    <!--                  </span>-->
    <!--                  <span v-if="travel.temperature">-->
    <!--                    <template v-if="travel.finished_at">|</template> Temperatura ambiente: {{-->
    <!--                      travel.temperature-->
    <!--                    }} <sup>o</sup>C-->
    <!--                    <br>-->
    <!--                    <span style="font-size: 0.8rem"-->
    <!--                          v-if="(now_temperature && travel.temperature) && Math.abs(now_temperature - travel.temperature) >= 2"-->
    <!--                          class="text-danger"><i class="fa-solid fa-triangle-exclamation"></i>-->
    <!--                      <span style="font-size: 0.9rem"> Alta variação de temperatura identificada!</span>-->
    <!--                    </span>-->
    <!--                  </span>-->
    <!--                  <span v-else>-->
    <!--                    N/I-->
    <!--                  </span>-->
    <!--                </h4>-->
    <!--                <h4 class="checklist-title mb-0">-->
    <!--                  Cód. Contrato:-->
    <!--                  <span class="small">-->
    <!--                    {{ $helper.optionalChaining(travel, 'schedule.contract_proposal.code') }}-->
    <!--                  </span>-->
    <!--                </h4>-->
    <!--                <h4 class="checklist-title mb-0">-->
    <!--                  Obra:-->
    <!--                  <span class="small">-->
    <!--                    {{-->
    <!--                      $helper.optionalChaining(travel, 'schedule.contract_proposal.construction.construction_name')-->
    <!--                    }}-->
    <!--                  </span>-->
    <!--                </h4>-->
    <!--                <h4 class="checklist-title mb-0">-->
    <!--                  Cliente:-->
    <!--                  <span class="small">-->
    <!--                    {{-->
    <!--                      $helper.optionalChaining(travel,-->
    <!--                        'schedule.contract_proposal.construction.customer_construction.customer_name')-->
    <!--                    }}-->
    <!--                  </span>-->
    <!--                </h4>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div v-if="!loadingTravel" class="border-left border-3 p-2 border-primary mt-3">-->
    <!--          <div class="">-->
    <!--            <div class="row">-->
    <!--              <div class="col-md-12" v-if="$helper.optionalChaining(travel, 'schedule.contract_proposal_formulation')">-->
    <!--                <span class="h4 mb-0">-->
    <!--                 {{ travel.schedule.contract_proposal_formulation.title }}-->
    <!--                </span>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="mt-2" v-if="$helper.optionalChaining(travel, 'schedule.contract_proposal_formulation')">-->
    <!--              <h5 class="small">-->
    <!--                {{ travel.schedule.contract_proposal_formulation.body }}-->
    <!--              </h5>-->
    <!--              <h5 class="small">-->
    <!--                {{ travel.schedule.contract_proposal_formulation.footer }}-->
    <!--              </h5>-->
    <!--              <div v-for="(puzl_addtion, index) in travel.schedule.contract_proposal_formulation.puzl_additions"-->
    <!--                   :key="puzl_addtion.id">-->
    <!--                <h5 class="text-indigo">-->
    <!--                  {{ puzl_addtion.name }}-->
    <!--                  ({{ puzl_addtion.volume }} <span style="text-transform: none">kg/m</span><sup>3</sup>)-->
    <!--                  <span class="text-indigo"-->
    <!--                        v-if="index != travel.schedule.contract_proposal_formulation.puzl_additions.length - 1">+</span>-->
    <!--                </h5>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <transition name="slide-fade">-->
    <!--        <card class="mt-4" v-if="$helper.optionalChaining(travel, 'schedule') && !loadingTravel">-->
    <!--          &lt;!&ndash; Card body Identification &ndash;&gt;-->
    <!--          <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">-->
    <!--            <div>-->
    <!--              <div class="row align-items-center mb-3">-->
    <!--                <div class="col-12">-->
    <!--                  &lt;!&ndash; Title &ndash;&gt;-->
    <!--                  <div class="card-header border-header-success-bottom p-2">-->
    <!--                    <h5 class="h4 mb-0" slot="title">Identificação</h5>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row m-0 p-0 mb-1">-->
    <!--                <label class="col-md-5 pb-1 col-form-label form-control-label">-->
    <!--                  Central-->
    <!--                  <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--                </label>-->
    <!--                <div class="col-md-7 pl-2">-->
    <!--                  <validation-provider rules="required">-->
    <!--                    <base-input input-classes="form-control-sm">-->
    <!--                      <el-select-->
    <!--                        :disabled="categories_mcc.length > 0 || allowEditing(travel.status) || travel.has_xml || travel.has_nfe || travel.number"-->
    <!--                        v-model="travel.send_plant.uuid" size="mini" placeholder="Selecione" filterable-->
    <!--                        :loading="loadingCompanyPlant"-->
    <!--                        @change="travel.company_plant_issuer_id = null;getChargePoint(true)">-->
    <!--                        <el-option label="Selecione" value=""></el-option>-->
    <!--                        <el-option v-for="(plant, index) in travel.company_plants" :key="index" :label="plant.name"-->
    <!--                                   :value="plant.uuid">-->
    <!--                        </el-option>-->
    <!--                      </el-select>-->
    <!--                    </base-input>-->
    <!--                  </validation-provider>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row m-0 p-0 mb-1">-->
    <!--                <label class="col-md-5 pb-1 col-form-label form-control-label">-->
    <!--                  CNPJ Emissor-->
    <!--                  <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--                </label>-->
    <!--                <div class="col-md-7 pl-2">-->
    <!--                  <validation-provider rules="required">-->
    <!--                    <base-input input-classes="form-control-sm">-->
    <!--                      <el-select-->
    <!--                        :disabled="categories_mcc.length > 0 || allowEditing(travel.status) || !travel.send_plant.uuid || travel.has_xml || travel.has_nfe || travel.number"-->
    <!--                        v-model="travel.company_plant_issuer_id" size="mini" placeholder="Selecione" filterable-->
    <!--                        :loading="loadingCompanyPlant">-->
    <!--                        <el-option label="Selecione" value=""></el-option>-->
    <!--                        <el-option v-for="(item, index) in company_plant_issuers" :key="index" :label="item.alias_name"-->
    <!--                                   :value="item.id">-->
    <!--                        </el-option>-->
    <!--                      </el-select>-->
    <!--                    </base-input>-->
    <!--                  </validation-provider>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row m-0 p-0 mb-1">-->
    <!--                <label class="col-md-5 pb-1 col-form-label form-control-label">-->
    <!--                  Ponto de carga-->
    <!--                  <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--                </label>-->
    <!--                <div class="col-md-7 pl-2">-->
    <!--                  <validation-provider rules="required">-->
    <!--                    <base-input input-classes="form-control-sm">-->
    <!--                      <el-select :disabled="categories_mcc.length > 0 || allowEditing(travel.status) || travel.number"-->
    <!--                                 filterable-->
    <!--                                 v-if="testChargePoint" v-model="travel.charge_point_uuid" size="mini"-->
    <!--                                 placeholder="Selecione">-->
    <!--                        <el-option label="Selecione" value=""></el-option>-->
    <!--                        <el-option @change="setChargePoint" v-for="charge_point in chargePoints" :key="charge_point.id"-->
    <!--                                   :label="charge_point.name" :value="charge_point.uuid">-->
    <!--                        </el-option>-->
    <!--                      </el-select>-->
    <!--                    </base-input>-->
    <!--                  </validation-provider>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row m-0 p-0 mb-1">-->
    <!--                <label class="col-md-5 pb-1 col-form-label form-control-label">-->
    <!--                  Motorista-->
    <!--                  <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--                </label>-->
    <!--                <div class="col-md-7 pl-2">-->
    <!--                  <validation-provider rules="required">-->
    <!--                    <base-input input-classes="form-control-sm">-->
    <!--                      <el-select @change="setEquipemnt" v-model="travel.driver_uuid" size="mini" placeholder="Selecione"-->
    <!--                                 filterable :loading="loadingDrivers" :disabled="loadingDrivers || travel.was_billed">-->
    <!--                        <el-option label="Selecione" value=""></el-option>-->
    <!--                        <el-option v-for="user in drivers" :key="user.uuid" :label="user.name" :value="user.uuid">-->
    <!--                        </el-option>-->
    <!--                      </el-select>-->
    <!--                    </base-input>-->
    <!--                  </validation-provider>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row m-0 p-0 mb-1">-->
    <!--                <label class="col-md-5 pb-1 col-form-label form-control-label">-->
    <!--                  Caminhão-->
    <!--                  <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--                </label>-->
    <!--                <div class="col-md-7 pl-2">-->
    <!--                  <validation-provider rules="required">-->
    <!--                    <base-input input-classes="form-control-sm">-->
    <!--                      <el-select @change="verifyEquipment()" :disabled="travel.was_billed"-->
    <!--                                 v-model="travel.equipment_uuid" size="mini" placeholder="Selecione" filterable>-->
    <!--                        <el-option label="Selecione" value=""></el-option>-->
    <!--                        <el-option v-for="equipment in equipments" :key="equipment.id"-->
    <!--                                   :label="equipment.code + ' - ' + equipment.plate" :value="equipment.uuid">-->
    <!--                        </el-option>-->
    <!--                      </el-select>-->
    <!--                    </base-input>-->
    <!--                  </validation-provider>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row m-0 p-0 mb-1">-->
    <!--                <label class="col-md-5 pb-1 col-form-label form-control-label">-->
    <!--                  Volume-->
    <!--                  <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--                </label>-->
    <!--                <div class="col-md-7 pl-2">-->
    <!--                  <validation-provider rules="required">-->
    <!--                    <base-input input-group-classes="input-group-sm">-->
    <!--                      <input @change="verifyEquipment" v-model="travel.volume" v-on:input="-->
    <!--                        travel.volume = formatString(-->
    <!--                          $event.target.value,-->
    <!--                          '0',-->
    <!--                          '29.0',-->
    <!--                          '1'-->
    <!--                        )-->
    <!--                      " inputmode="numeric" class="form-control form-control-sm"-->
    <!--                             :disabled="categories_mcc.length > 0 || allowEditing(travel.status) || travel.number"/>-->
    <!--                      <template slot="append">-->
    <!--                        <small class="input-group-sm p-0 m-0">-->
    <!--                          m3-->
    <!--                          &lt;!&ndash;                          <span style="text-transform: none">m</span><sup>3</sup>&ndash;&gt;-->
    <!--                        </small>-->
    <!--                      </template>-->
    <!--                    </base-input>-->
    <!--                    <input v-model="old_volume" style="display: none"-->
    <!--                           v-mask="['#', '#,#', '##,#', '###,#', '#.###,#', '##.###,#', '###.###,#', '#.###.###,#', '##.###.###,#']"/>-->
    <!--                  </validation-provider>-->
    <!--                  <h3 v-if="limit_volume"><small class="font-weight-500 text-warning"><i-->
    <!--                    class="fa-solid fa-triangle-exclamation text-warning"></i>-->
    <!--                    Atenção! A capacidade máxima do caminhão selecionado é de <span class="numeric font-weight-500">{{-->
    <!--                        limit_volume.toString().replace('.', ',')-->
    <!--                      }}</span>-->
    <!--                    <span style="text-transform: none;font-size: 0.8rem"> m<sup>3</sup></span>-->
    <!--                  </small>-->
    <!--                  </h3>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row m-0 p-0 mb-1 ">-->
    <!--                <label class="col-md-5 col-form-label form-control-label ">-->
    <!--                  Lacre-->
    <!--                  <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--                </label>-->
    <!--                <div class="col-md-7 pl-2">-->
    <!--                  <validation-provider rules="required">-->
    <!--                    <base-input input-classes="form-control-sm">-->
    <!--                      <input v-mask="'####################'" inputmode="numeric" v-model="travel.seal"-->
    <!--                             class="form-control form-control-sm"-->
    <!--                             :disabled="categories_mcc.length > 0 || allowEditing(travel.status)"/>-->
    <!--                    </base-input>-->
    <!--                  </validation-provider>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div v-if="![1, 7, 6].includes(travel.status)" class="d-flex justify-content-between">-->
    <!--                <div class="col-9">-->
    <!--                  <a :style="travel.status === 1 && 'opacity: 25%' || !travel.hardened_state && 'opacity: 25%'" href="#"-->
    <!--                     @click.prevent="handleHardenedStateTest(travel)">-->
    <!--                    <i class="fas fa-cube fa-2x" style="color: #525f7f"></i>-->
    <!--                  </a>-->
    <!--                  <span class="h5 text-muted d-block">Realizar moldagem?</span>-->
    <!--                </div>-->
    <!--                <div class="col-3 float-right text-right">-->
    <!--                  <base-switch size="sm" v-model="travel.hardened_state"-->
    <!--                               :disabled="travel.status == 1 || !travel.allowed_charging_configuration"-->
    <!--                               @input="setHardenedState(travel)" type="success" class="success">-->
    <!--                  </base-switch>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mb-2">-->
    <!--              <div class="col-12">-->
    <!--                <div class="float-right mt-4">-->
    <!--                  <base-button @click.native="save" :loading="loadingSave" type="success" native-type="submit"-->
    <!--                               v-show="!travel.was_billed"-->
    <!--                               :disabled="invalid || travel.was_billed || needs_aprovation_moisture || needs_aprovation_mcc || needs_authorize">-->
    <!--                    Salvar-->
    <!--                  </base-button>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; Card body Reuse &ndash;&gt;-->
    <!--            <div>-->
    <!--              <div class="row align-items-center mb-3">-->
    <!--                <div class="col-12">-->
    <!--                  &lt;!&ndash; Title &ndash;&gt;-->
    <!--                  <div class="card-header border-header-primary-bottom p-2">-->
    <!--                    <h5 class="h4 mb-0" slot="title">Reuso</h5>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row m-0 p-0 mb-1">-->
    <!--                <label class="col-md-5 pb-1 col-form-label form-control-label">-->
    <!--                  Concreto-->
    <!--                  <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--                </label>-->
    <!--                <div class="col-md-7 pl-2">-->
    <!--                  <base-input input-classes="form-control-sm">-->
    <!--                    <el-select v-model="travel.left_over_uuid" size="mini" placeholder="Selecione" filterable-->
    <!--                               :loading="loadingConcrete"-->
    <!--                               :disabled="categories_mcc.length === 0 || travel.isset_manual_dosage || travel.automation_status !== 0">-->
    <!--                      <el-option label="Selecione" value=""></el-option>-->
    <!--                      <el-option style="text-transform: none" :disabled="loadingTravel"-->
    <!--                                 v-if="leftOver.schedule_travel_uuid !== travel.uuid"-->
    <!--                                 v-for="leftOver in travelLeftOvers"-->
    <!--                                 :key="leftOver.id"-->
    <!--                                 :label="'O.S. ' + leftOver.number + ' (' + leftOver.volume + ' m³) ' + $helper.parseDate(leftOver.created_at, 'DD/MM/YYYY') + ' - ' + categories[`${leftOver.category}${leftOver.category_type}`]"-->
    <!--                                 :value="leftOver.uuid">-->
    <!--                      </el-option>-->
    <!--                    </el-select>-->
    <!--                  </base-input>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="col-12">-->
    <!--                <div class="row" v-if="showLeftOverStatistic && leftOver">-->
    <!--                  <div class="col-12">-->
    <!--                    <h4 :class="leftOver.category_type === 1 ? 'text-success' : 'text-primary'">-->
    <!--                      {{ categories[`${leftOver.category}${leftOver.category_type}`] }}-->
    <!--                    </h4>-->
    <!--                  </div>-->
    <!--                  <template v-for="statistic in leftOverStatistic">-->
    <!--                    <div v-if="statistic && statistic.show" class="col-md-7" :class="statistic.loading">-->
    <!--                      <span style="color: #6C6E71" class="" v-if="!statistic.loading">{{-->
    <!--                          statistic.title-->
    <!--                        }} : <b style="color: #2d3748">{{ statistic.subtitle }}</b></span>-->
    <!--                      <span :class="statistic.loading && 'text-muted'" v-else>{{ statistic.title }}...</span>-->
    <!--                    </div>-->
    <!--                    <div v-if="statistic && statistic.show" class="col-md-5 text-left">-->
    <!--                      <span class="mr-1" style="color: #6C6E71" v-if="!statistic.no_check">Status:</span>-->
    <!--                      <span v-if="statistic.no_check && !statistic.loading"></span>-->
    <!--                      <span class="text-success" v-else-if="statistic.is_checked"><i class="fas fa-check"></i></span>-->
    <!--                      <span v-else-if="statistic.loading">-->
    <!--                        <img style="width: 1rem"-->
    <!--                             src="https://cadastro.auniversidadebrasil.com.br/img/loader_keds.gif"/>-->
    <!--                      </span>-->
    <!--                      <span class="text-danger" v-else><i class="fas fa-ban"></i></span>-->
    <!--                    </div>-->
    <!--                  </template>-->
    <!--                  <div class="col-12" v-if="travel.incompatible_groups && travel.incompatible_groups.length">-->
    <!--                    <h4 class="text-danger incompatible_groups" style="opacity: 75%">Grupos incompátiveis: <span-->
    <!--                      class="text-default" v-for="(item, index) in travel.incompatible_groups">{{ item }} <span-->
    <!--                      v-if="index + 1 < travel.incompatible_groups.length"> + </span></span></h4>-->
    <!--                  </div>-->
    <!--                  <div class="col-12 mt-4" v-if="leftOverStatistic.needs_validation">-->
    <!--                    Autorizado por:-->
    <!--                    <span class="h4 text-primary">-->
    <!--                      {{ leftOverStatistic.user.name }}-->
    <!--                    </span>-->
    <!--                    em-->
    <!--                    <span class="h4">{{ leftOverStatistic.created_at | parseDate }}</span>.-->
    <!--                  </div>-->
    <!--                  <div class="col-12 mt-1" v-if="travel.left_over_observation">-->
    <!--                    Observação:-->
    <!--                    <span class="h4" style="color: #4a5568">-->
    <!--                      {{ travel.left_over_observation }}-->
    <!--                    </span>-->
    <!--                  </div>-->
    <!--                  <div class="col-12 mt-4" v-if="valid_left_over === false">-->
    <!--                    <h4 class="text-warning"><i class="fas fa-exclamation-triangle"></i>Este traço não permite reuso-->
    <!--                    </h4>-->
    <!--                    <h5 class="text-muted">Este concreto não pode ser reutilizado</h5>-->
    <!--                    <div class="row">-->
    <!--                      <div class="col-md-6">-->
    <!--                        <base-input v-model="leftOverObservation" label="Observações"/>-->
    <!--                      </div>-->
    <!--                      <div class="col-6"/>-->
    <!--                    </div>-->
    <!--                    <br>-->
    <!--                    <div class="row">-->
    <!--                      <div class="col-12">-->
    <!--                        <base-button :loading="loadingSaveReuse" v-if="needs_authorize"-->
    <!--                                     @click.prevent="autorizeReuse(true)" outline type="primary"-->
    <!--                                     class="text-uppercase mb-1">-->
    <!--                          Autorizar reuso-->
    <!--                        </base-button>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; Card body Additionals &ndash;&gt;-->
    <!--            <div>-->
    <!--              <div class="row align-items-center mb-3">-->
    <!--                <div class="col-12">-->
    <!--                  &lt;!&ndash; Title &ndash;&gt;-->
    <!--                  <div class="card-header border-header-danger-bottom p-2">-->
    <!--                    <h5 class="h4 mb-0" slot="title">Adicionais</h5>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-group row">-->
    <!--                <div class="col-md-3">-->
    <!--                  <base-button @click.prevent="$refs.ModalAddAdditionals.openModal()" block-->
    <!--                               :disabled="categories_mcc.length === 0" size="small" outline type="primary"-->
    <!--                               class="p-1 text-uppercase">-->
    <!--                    <i class="fas fa-plus-square"/> incluir adicionais-->
    <!--                  </base-button>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div v-for="additional in schedule_additionals" class="card text-white"-->
    <!--                   style="border: 1px solid black;opacity: 80%" :class="additional.status ? 'bg-success' : 'bg-danger'">-->
    <!--                <div class="row pl-3 p-2">-->
    <!--                  <div class="col-6">-->
    <!--                    <span class="h5 text-white numeric" v-if="additional && additional.additional">-->
    <!--                      {{ additional.additional.short_description }}-->
    <!--                    </span>-->
    <!--                  </div>-->
    <!--                  <div class="col-4">-->
    <!--                    <span style="text-transform: none" class="h5 text-white numeric"-->
    <!--                          v-if="additional && additional.calculation_base">-->
    <!--                      {{ additional.price | currency() }}-->
    <!--                    </span>-->
    <!--                  </div>-->
    <!--                  <div class="col-2 text-right">-->
    <!--                    <div v-if="additional.default">-->
    <!--                      <base-switch @input="setStatusAdditional(additional)" size="sm"-->
    <!--                                   :disabled="loadingUpdateAdditional" v-model="additional.status" type="white"-->
    <!--                                   class="white">-->
    <!--                      </base-switch>-->
    <!--                    </div>-->
    <!--                    <div v-else>-->
    <!--                      <a style="margin-right: 7px" href="#" @click.prevent="removeAdditional(additional.uuid)">-->
    <!--                        <i class="fa fa-times text-white fa-2x"></i>-->
    <!--                      </a>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="col-12">-->
    <!--                    <span style="text-transform: none" class="h5 text-white numeric"-->
    <!--                          v-if="additional && additional.additional">-->
    <!--                      {{-->
    <!--                        additional.additional.standard_value-->
    <!--                      }} {{ additional.additional.billing_unit_description }}-->
    <!--                    </span>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->

    <!--              &lt;!&ndash;              <div style="class="border p-2 card&#45;&#45;hover mt-1 mb-1" v-for="additional in schedule_additionals">&ndash;&gt;-->
    <!--              &lt;!&ndash;                <div class="row">&ndash;&gt;-->
    <!--              &lt;!&ndash;                  <div class="col-5">&ndash;&gt;-->
    <!--              &lt;!&ndash;   <span style="text-transform: none" class="h5" v-if="additional && additional.additional">&ndash;&gt;-->
    <!--              &lt;!&ndash;                      {{ additional.additional.short_description }}&ndash;&gt;-->
    <!--              &lt;!&ndash;                    </span>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  <div class="col-4">&ndash;&gt;-->
    <!--              &lt;!&ndash;   <span style="text-transform: none" class="h5" v-if="additional && additional.calculation_base">&ndash;&gt;-->
    <!--              &lt;!&ndash;                      {{ additional.calculation_base }}&ndash;&gt;-->
    <!--              &lt;!&ndash;                    </span>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  <div class="col-3 text-right">&ndash;&gt;-->
    <!--              &lt;!&ndash;                    <div v-if="additional.default">&ndash;&gt;-->
    <!--              &lt;!&ndash;                      <base-switch&ndash;&gt;-->
    <!--              &lt;!&ndash;                        @input="setStatusAdditional(additional)"&ndash;&gt;-->
    <!--              &lt;!&ndash;                        size="sm"&ndash;&gt;-->
    <!--              &lt;!&ndash;                        :disabled="loadingUpdateAdditional"&ndash;&gt;-->
    <!--              &lt;!&ndash;                        v-model="additional.status"&ndash;&gt;-->
    <!--              &lt;!&ndash;                        type="success"&ndash;&gt;-->
    <!--              &lt;!&ndash;                        class="success"&ndash;&gt;-->
    <!--              &lt;!&ndash;                      >&ndash;&gt;-->
    <!--              &lt;!&ndash;                      </base-switch>&ndash;&gt;-->
    <!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;                    <div v-else>&ndash;&gt;-->
    <!--              &lt;!&ndash;                      <a href="#" @click.prevent="removeAdditional(additional.uuid)">&ndash;&gt;-->
    <!--              &lt;!&ndash;                        <i class="fa fa-times text-danger"></i>&ndash;&gt;-->
    <!--              &lt;!&ndash;                      </a>&ndash;&gt;-->
    <!--              &lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  <div class="col-12">&ndash;&gt;-->
    <!--              &lt;!&ndash;                       <span style="text-transform: none" class="h5" v-if="additional && additional.additional">&ndash;&gt;-->
    <!--              &lt;!&ndash;                     {{ additional.additional.standard_value }} {{ additional.additional.billing_unit_description }}&ndash;&gt;-->
    <!--              &lt;!&ndash;                    </span>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            </div>-->

    <!--            &lt;!&ndash;            CARREGAMENTO&ndash;&gt;-->
    <!--          -->
    <!--            &lt;!&ndash; Card body Charging &ndash;&gt;-->
    <!--            &lt;!&ndash;            <div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <div class="row align-items-center mb-3">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  &lt;!&ndash; Title &ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <div class="card-header border-header-danger-bottom p-2">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <h5 class="h4 mb-0" slot="title">Carregamento</h5>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <div class="row">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <div class="row">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <div class="col-12" v-if="!travel.allowed_charging_configuration && !loadingTravel&ndash;&gt;-->
    <!--            &lt;!&ndash;                    && ![1, 6, 5].includes(travel.status)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <h3 class="text-muted"><i class="text-warning fas fa-exclamation-triangle"></i> Fora do horário&ndash;&gt;-->
    <!--            &lt;!&ndash;                        permitido para&ndash;&gt;-->
    <!--            &lt;!&ndash;                        carregamento&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </h3>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->

    <!--            &lt;!&ndash;                    <div class="col-12"&ndash;&gt;-->
    <!--            &lt;!&ndash;                         v-if="!loadingTravel && [0, 15].includes(travel.schedule_status.status) && !categories_mcc.length">&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <h3 class="text-muted">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <i class="text-warning fas fa-exclamation-triangle"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        A programação está cancelada ou não foi realizada, carregamento não permitido.&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </h3>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <template&ndash;&gt;-->
    <!--            &lt;!&ndash;                      v-if="travel.allowed_charging_configuration && ![0, 15].includes(travel.schedule_status.status)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <div class="col-12"&ndash;&gt;-->
    <!--            &lt;!&ndash;                           v-if="travel.validEquipmentAndDriver.driver && !loadingTravel && ![1, 5, 6].includes(travel.status)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <h5 class="text-muted"><i class="text-warning fas fa-exclamation-triangle"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          O Motorista selecionado já está com uma viagem em&ndash;&gt;-->
    <!--            &lt;!&ndash;                          andamento</h5>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <div class="col-12" v-if="travel.validEquipmentAndDriver.equipment && !loadingTravel &&&ndash;&gt;-->
    <!--            &lt;!&ndash;                      ![1, 6, 5].includes(travel.status)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <h5 class="text-muted">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <i class="text-warning fas fa-exclamation-triangle"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          O Equipamento selecionado já está com uma viagem em&ndash;&gt;-->
    <!--            &lt;!&ndash;                          andamento&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </h5>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <div class="col-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <div class="form-group row">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <template v-if="!(!travel.allowed_charging_configuration && !loadingTravel&ndash;&gt;-->
    <!--            &lt;!&ndash;                    && ![1, 6, 5].includes(travel.status)) && !(!loadingTravel && [0, 15].includes(travel.schedule_status.status))">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="col-md-12 mb-1" v-if="binderIsLessConsumption">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <h4 class="text-danger">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              ATENÇÃO! NÃO REALIZE O CARREGAMENTO DESSE CONCRETO SEM ANTES ENTRAR EM CONTATO COM O SETOR&ndash;&gt;-->
    <!--            &lt;!&ndash;                              DE ENGENHARIA DA EMPRESA!&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </h4>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <div class="mt-n2">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <a href="#" @click.prevent="setCheckContact" class="text-success">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <i v-if="is_checked_contact" style="font-size: 19px"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                   class="fa-regular fa-square-check text-success"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <i style="font-size: 19px" v-else class="fa-regular fa-square text-success"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                CONTATO REALIZADO, POSSO PROSSEGUIR DESSA FORMA&ndash;&gt;-->
    <!--            &lt;!&ndash;                              </a>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="col-md-2">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <base-button block size="small" type="indigo" class="p-1 mb-1 text-uppercase" outline&ndash;&gt;-->
    <!--            &lt;!&ndash;                                       :disabled="isntSaved&ndash;&gt;-->
    <!--            &lt;!&ndash;                            || needs_aprovation_mcc&ndash;&gt;-->
    <!--            &lt;!&ndash;                            || categories_mcc.length > 0&ndash;&gt;-->
    <!--            &lt;!&ndash;                            || disabledMcc&ndash;&gt;-->
    <!--            &lt;!&ndash;                            || travel.isset_manual_dosage&ndash;&gt;-->
    <!--            &lt;!&ndash;                            || invalid ||&ndash;&gt;-->
    <!--            &lt;!&ndash;                            !travel.allowed_charging_configuration&ndash;&gt;-->
    <!--            &lt;!&ndash;|| loadingBinderConsumption || (binderIsLessConsumption && !is_checked_contact)" @click.prevent="handleShowModalMcc">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <i class="fa fa-puzzle-piece"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            mcc&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div :class="travel.automation_status !== 0 ? 'col-md-4' : 'col-md-2'">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <base-button :disabled="categories_mcc.length === 0&ndash;&gt;-->
    <!--            &lt;!&ndash;                          || travel.automation_status != 0&ndash;&gt;-->
    <!--            &lt;!&ndash;                          || travel.status === 1&ndash;&gt;-->
    <!--            &lt;!&ndash;                          || travel.status === 6&ndash;&gt;-->
    <!--            &lt;!&ndash;                          || travel.status === 5 || needs_authorize" block size="small"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                       :type="automation_class[travel.automation_status]"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                       class="p-1 mb-1 text-uppercase" outline&ndash;&gt;-->
    <!--            &lt;!&ndash;                                       @click.prevent="setAutomation">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <i class="fa-solid fa-circle-check" v-if="travel.automation_status === 2"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <i v-else class="fa-solid fa-diagram-successor"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            {{ automation_text[travel.automation_status] }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="col-md-3">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <base-button @click.prevent="searchAutomation()" block&ndash;&gt;-->
    <!--            &lt;!&ndash;                                       v-if="travel.automation_status == 1 && !isLocalAutomation()"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                       size="small" type="primary" class="p-1 mb-1 text-uppercase" outline>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <i class="fa-solid fa-magnifying-glass"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            CONSULTAR AUTOMAÇÃO&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div&ndash;&gt;-->
    <!--            &lt;!&ndash;                          :class="travel.automation_status == 1 || travel.automation_status == 2 || travel.automation_status == 5 ? 'col-md-1' : 'col-md-3'"/>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="col-md-2 text-right" v-if="(categories_mcc.length &&&ndash;&gt;-->
    <!--            &lt;!&ndash;                        travel.automation_status !== 2 &&&ndash;&gt;-->
    <!--            &lt;!&ndash;                        ![5, 1, 6].includes(travel.status) && ![0, 15].includes(travel.schedule_status.status)) || $hasPrivilege(1) || travel.status === 5 && ![2, 0].includes(travel.automation_status)">&ndash;&gt;-->
    <!--                          <base-button v-show="categories_mcc.length > 0" block @click.prevent="handleClearMcc"-->
    <!--                                       size="small" type="danger" class="p-1 mb-1 text-uppercase">-->
    <!--                            <i class="fas fa-1x fa-eraser"></i>-->
    <!--                            LIMPAR-->
    <!--                          </base-button>-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      &lt;!&ndash;   VALIDAÇÃO DE HUMIDADE&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <div class="row" v-if="needs_aprovation_moisture">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="col-md-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <span><i class="text-warning fa-solid fa-triangle-exclamation"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <span style="color: orange;font-size: 0.8rem">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <span> Para prosseguir é recomendado atualizar as umidades </span></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <validation-observer v-slot="{ invalid }" ref="formValidator">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="row" v-if="needs_aprovation_moisture" v-for="item in valid_mcc">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="col-md-4 ml-3 mt-2 border-3 border-left border-primary">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <p><b>{{ item.description.cmc_category.name }}</b>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <br>{{ item.description.cmc_product.product_name }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <b style="font-size: 0.9rem">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                {{ item.description.cmc_supplier.supplier_name }} |&ndash;&gt;-->
    <!--            &lt;!&ndash;                                {{ item.description.cmc_supplier_origin.origin_name }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                              </b>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <validation-provider rules="required" v-slot="{ errors }">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <base-input input-group-classes="input-group-sm">&ndash;&gt;-->

    <!--            &lt;!&ndash;                                  <input-limit :start_value="item.moisture" :options="{&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    min: 0.0,&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    max: 49.9,&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    decimals: 1,&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  }" v-model="item.moisture" @value="item.moisture = $event">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <small>%</small>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </input-limit>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                  <input&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                    v-model="item.moisture"&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                    inputmode="numeric"&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                    :class="errors[0] ? 'is-invalid' : 'is-valid'"&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                    class="form-control form-control-sm"&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                    maxlength="5"&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                  />&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                  <template slot="append">&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                    <small class="input-group-sm p-0 m-0">&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                      %&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                    </small>&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  &lt;!&ndash;                                  </template>&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                </base-input>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              </validation-provider>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </p>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="row mt-2 ml-2" v-if="needs_aprovation_moisture">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="col-md-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <base-button @click.prevent="cancelAprovationMoisture()" type="secondary"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                         class="text-capitalize">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              cancelar&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <base-button :disabled="invalid" @click.prevent="approveMoisture(true)" type="warning"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                         class="text-capitalize">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              Adiar&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <base-button :disabled="invalid" @click.prevent="approveMoisture(false)" type="success"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                         class="text-capitalize">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              Salvar&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </validation-observer>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      &lt;!&ndash;  FIM VALIDAÇÃO DE HUMIDADE&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <div class="row">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="col-md-9">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <template v-show="![5, 1].includes(travel.status) && travel.allowed_charging_configuration&ndash;&gt;-->
    <!--            &lt;!&ndash;                          && !travel.validEquipmentAndDriver.driver && !travel.validEquipmentAndDriver.equipment&ndash;&gt;-->
    <!--            &lt;!&ndash;                          && categories_mcc.length && !needs_aprovation_moisture">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            &lt;!&ndash;                            <base-button type="primary" :disabled="disableSetTime"&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                            &lt;!&ndash;                                         v-if="!start_at && !needs_aprovation_moisture" @click.prevent="setTime">&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                            &lt;!&ndash;                              <span class="btn-label">&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                            &lt;!&ndash;                                <i class="fas fa-clock"></i></span>&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                            &lt;!&ndash;                              INICIAR&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                            &lt;!&ndash;                            </base-button>&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <button class="btn btn-warning" :disabled="finished_at != null"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    v-show="start_at && !needs_aprovation_moisture && !finished_at"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    @click.prevent="finishTime(false)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <span class="btn-label">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <i class="far fa-calendar-check"></i></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              FINALIZAR&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <button class="btn btn-light" v-if="!started()"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    v-show="start_at && !needs_aprovation_moisture && !finished_at"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    @click.prevent="resetTime">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <span class="btn-label">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <i class="fas fa-hourglass-end"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              ZERAR&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <h2 class="" v-if="!needs_aprovation_moisture && timeStarted !== '00:00:00'">{{
      &ndash;&gt;-- >
      < !-- &lt; ! & ndash;                                timeStarted & ndash;&gt;-- >
      < !-- &lt; ! & ndash;
    }}</h2>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="col-md-3 text-right">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <base-button @click.prevent="downloadReport()" type="primary" v-if="categories_mcc.length">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <span class="btn-label">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <i class="fa-solid fa-print"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            RELATÓRIO&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="row" v-if="travel.approval_observation">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="col-md-12 mb-2">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <span class="display-5 text-uppercase" style="font-size: 1rem">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <i class="fas fa-exclamation-triangle text-warning"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              CARREGAMENTO AUTORIZADO POR <b>{{ travel.approval_user_name }}.</b>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="col-md-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              Observação: <b>{{ travel.approval_observation }}</b>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <hr v-if="travel.approval_observation">&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="row" v-if="needs_aprovation_mcc">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="col-md-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            &lt;!&ndash;                            AUTORIZAÇÃO DO CARREGAMENTO&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <div class=" table-responsive">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <table class="table">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <thead>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <tr class="thead-light">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th colspan="1" rowspan="1"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="el-table_1_column_1 text-center is-leaf is-sortable">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <div class="cell"><span class="caret-wrapper"><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret ascending"></i><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret descending"></i></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th colspan="1" rowspan="1"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="el-table_1_column_1 text-center is-leaf is-sortable">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <div class="cell text-danger">Inferior<span class="caret-wrapper"><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret ascending"></i><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret descending"></i></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th colspan="1" rowspan="1"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="el-table_1_column_1 text-center is-leaf is-sortable">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <div class="cell text-success">Superior<span class="caret-wrapper"><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret ascending"></i><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret descending"></i></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th colspan="1" rowspan="1"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="el-table_1_column_1 text-center is-leaf is-sortable">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <div class="cell text-primary">Ajuste<span class="caret-wrapper"><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret ascending"></i><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret descending"></i></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th colspan="1" rowspan="1"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="el-table_1_column_1 text-center is-leaf is-sortable">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <div class="cell text-muted">Status<span class="caret-wrapper"><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret ascending"></i><i&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="sort-caret descending"></i></span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                </tr>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                </thead>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <tbody>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <tr v-for="item in adjustment_groups.parameters">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-muted text-center">{{ item.name }}</th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-muted text-center">{{ item.bottom }}</th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-muted text-center">{{ item.top }}</th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-center"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      :class="item.diff > 0 && 'text-success'&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      || item.diff < 0 && 'text-danger text-center' || item.diff == 0 && 'text-muted text-center'">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    {{ parseFloat(item.diff).toFixed(1) }}{{ item.type }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-muted text-center">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <i v-if="!item.status" class="text-danger fas fa-ban"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <i v-else class="text-success fas fa-check"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                </tr>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <tr v-for="item in adjustment_groups.groups">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-muted text-center">{{ item.name }}</th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-muted text-center">{{ item.bottom }}</th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-muted text-center">{{ item.top }}</th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-center" :class="item.diff > 0 && 'text-success'&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    || item.diff < 0 && 'text-danger' || item.diff == 0 && 'text-muted'">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    {{ parseFloat(item.diff).toFixed(1) }}{{ item.type }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <th class="text-muted text-center">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <i v-if="!item.status" class="text-danger fas fa-ban"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <i v-else class="text-success fas fa-check"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </th>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                </tr>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                </tbody>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              </table>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="col-md-12 mt-2">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">&ndash;&gt;-->
    <!--            &lt;!&ndash;                              Observações&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </label>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <textarea type="text" v-model="approval_observation" style="height: 100px" rows="2"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      class="form-control form-control-sm">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </textarea>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="col-md-12 mt-2">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <base-button @click.prevent="approveCharging" type="success" outline>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              Autorizar carregamento&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        &lt;!&ndash;                     INICIO GRUPOS CALCULADOS&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                        <div class="timeline timeline-one-side" v-if="!needs_aprovation_mcc">&ndash;&gt;-->
    <!--            &lt;!&ndash;                          <div class="timeline-block" v-for="(itemMcc, indexCategory) in categories_mcc"&ndash;&gt;-->
    <!--            &lt;!&ndash;                               :key="indexCategory">&ndash;&gt;-->
    <!--            &lt;!&ndash;                            <div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <span :class="validHaveManualDosage(itemMcc)" class="timeline-step">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <i class="ni ni-bell-55"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              <div class="timeline-content ml-5">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <small class="text-muted font-weight-bold">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  {{ itemMcc.name }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                </small>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                <div v-for="(group, indexGroup) in itemMcc.groups" :key="indexGroup">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  <a href="#" @click.prevent="handleShowModalDosageManual(indexCategory, indexGroup)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    <div class="border p-2 m-1 card&#45;&#45;hover d-flex justify-content-between">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      <div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <span class="h5 mt-1 mb-0 d-block">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          {{ group.product || '-' }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span class="mb-0 text-body text-muted">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{ group.supplier || '-' }} ({{ group.supplier_origin || '-' }})&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <span class="h5 mt-1 mb-0 d-block">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          {{ group.dosage.text || '-' }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span class="mb-0 text-body text-muted">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{ group.group_name || '-' }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <span class="h5 mt-1 mb-0 d-block">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          unid.&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span class="mb-0 text-body text-muted">{{ group.dosage.unit.letter || '-' }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <badge type="primary" v-if="itemMcc.id == 6 && group.water_correction">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          Corte de Água: {{ group.water_correction }} %&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </badge>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <badge type="default" v-if="[2, 3].includes(itemMcc.id) && group.moisture">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          Umidade: {{ group.moisture }} %&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </badge>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      <h2 class="mt-4 text-center mr-md-5 numeric"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          :class="(!group.previous_has_automation_manual || travel.automation_status == 1) && 'text-muted'"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          v-if="group.manual_dosage == null && !group.automation && !group.charging.hasOwnProperty('accumulated_wet_weight')">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        {{
      &ndash;&gt;-- >
      < !-- &lt; ! & ndash;                                          decimals(itemMcc.id, group.charging.wet_weight) & ndash;&gt;-- >
      < !-- &lt; ! & ndash;
    }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <small&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          v-if="travel.automation_status == 0 || group.is_dosage_manual || group.is_dosage_automation">(Individual)</small>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <small v-else>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <sup class="font-weight-400">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            <img&ndash;&gt;-->
    <!--            &lt;!&ndash;                                              width="32px"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                              src="/img/icons/loading_automation.gif"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            /> AUTOMAÇÃO&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </sup>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </small>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      </h2>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      <h2 class="mt-4 text-center mr-md-5 numeric"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          :class="(!group.previous_has_automation_manual || travel.automation_status == 1) && 'text-muted'"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          v-if="group.manual_dosage == null && !group.automation&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        && group.charging.hasOwnProperty('accumulated_wet_weight')">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        {{
      &ndash;&gt;-- >
      < !-- &lt; ! & ndash;                                          decimals(itemMcc.id, group.charging.accumulated_wet_weight) & ndash;&gt;-- >
      < !-- &lt; ! & ndash;
    }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <small>(Acumulado)</small>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      </h2>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      <span v-if="group.manual_dosage != null || group.automation"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            class="border-1 border-success border-left mr-md-n2" style="width: 180px">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <span class="h5 mt-1 ml-2 mb-0 d-block">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          {{ labelGroups[+group.is_individual] }}:&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span class="mb-0 text-body text-muted numeric" v-if="group.is_individual">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{
      &ndash;&gt;-- >
      < !-- &lt; ! & ndash;                                              decimals(itemMcc.id, group.charging.wet_weight) & ndash;&gt;-- >
      < !-- &lt; ! & ndash;
    }} {{ group.dosage.unit.letter }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            &lt;!&ndash;                                                                {{ aglomerante.dosage_accumulated || '-' }}&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span class="mb-0 text-body text-muted numeric" v-else>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{
      &ndash;&gt;-- >
      < !-- &lt; ! & ndash;                                              decimals(itemMcc.id, group.charging.accumulated_wet_weight) & ndash;&gt;-- >
      < !-- &lt; ! & ndash;
    }} {{ group.dosage.unit.letter }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <span class="h5 mt-1 ml-2 mb-0 d-block" v-if="group.reuse > 0">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          Reuso:&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span class="mb-0 text-body text-muted numeric">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{ decimals(itemMcc.id, group.reuse) }} kg&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <span class="h5 mt-1 ml-2 mb-0 d-block" v-if="group.automation !== null">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          Automação:&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span class="mb-0 text-blue numeric">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{
      &ndash;&gt;-- >
      < !-- &lt; ! & ndash;                                              decimals(itemMcc.id, group.automation) & ndash;&gt;-- >
      < !-- &lt; ! & ndash;
    }} {{ group.dosage.unit.letter }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            &lt;!&ndash;                                                               {{ aglomerante.dosage_automation || '-' }}&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </span>&ndash;&gt;-->

    <!--            &lt;!&ndash;                                        <span class="h5 mt-1 ml-2 mb-0 d-block"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                              v-if="IsSetManualDosage || group.manual_dosage !== null">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <template v-if="group.manual_dosage != null">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            Manual:&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span v-if="group.manual_dosage != null"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                                class="mb-0 text-body text-muted numeric">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{ group.manual_dosage }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{
      &ndash;&gt;-- >
      < !-- &lt; ! & ndash;                                              group.manual_dosage != null && group.dosage.unit.letter & ndash;&gt;-- >
      < !-- &lt; ! & ndash;
    }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        <span class="h5 mt-1 ml-2 mb-0 d-block">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <template v-if="group.variation_percentage != null">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            Desvio:&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </template>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          <span v-if="group.variation_percentage != null" class="mb-0 numeric"&ndash;&gt;-->
    <!--            &lt;!&ndash;                                                :class="group.variation_value == 0 ? 'text-muted' : group.variation_value > 0 ? 'text-success' : 'text-danger'">&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{ group.variation_value == 0 ? 0 : group.variation_value }} kg&ndash;&gt;-->
    <!--            &lt;!&ndash;                                            {{ group.variation_percentage }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                                          </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                        </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                      </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                    </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                  </a>&ndash;&gt;-->
    <!--            &lt;!&ndash;                                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                              </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                            </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                          </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                        </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              &lt;!&ndash;              Se automação não estiver em andamento&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;              <div class="row mb-2 mt-4" v-if="travel.automation_status !== 1">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-md-12" v-if="![1, 5, 6].includes(travel.status) && travel.all_isset_manual_dosage">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <p class="text-danger h6" style="font-size: 11px">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <i class="fa-solid fa-triangle-exclamation"></i> Atenção! Ao clicar no botão <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                    class="text-primary">Concluir carregamento</span>, você se responsabiliza sobre as quantidades&ndash;&gt;-->
    <!--            &lt;!&ndash;                    geradas pelo PUZL MIX.&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </p>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <base-button style="font-size: 0.88rem;z-index: 1"&ndash;&gt;-->
    <!--            &lt;!&ndash;                               v-if="![1, 5, 6].includes(travel.status) && travel.all_isset_manual_dosage" block&ndash;&gt;-->
    <!--            &lt;!&ndash;                               type="primary"&ndash;&gt;-->
    <!--            &lt;!&ndash;                               size="sm" outline @click.prevent="!concluding && completeCharging()"&ndash;&gt;-->
    <!--            &lt;!&ndash;                               class="mt-2 mb-2 text-uppercase">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <i class="fa-regular fa-circle-check"></i> Concluir carregamento&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-12" v-if="[1, 5].includes(travel.status) && travel.all_isset_manual_dosage">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span>Carregamento concluído por&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <span class="text-primary">{{ travel.user_name }} </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <span>em {{ travel.finished_at | parseDate }}.</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              &lt;!&ndash;              Se automação estiver em andamento&ndash;&gt;&ndash;&gt;-->
    <!--            &lt;!&ndash;              <div class="row mb-2 mt-4" v-if="travel.automation_status === 1 && !isLocalAutomation()">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-md-12" v-if="![1, 5, 6].includes(travel.status)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <p class="text-danger h6" style="font-size: 11px">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <i class="fa-solid fa-triangle-exclamation"></i> Atenção! Ao clicar no botão <span&ndash;&gt;-->
    <!--            &lt;!&ndash;                    class="text-primary">Concluir carregamento</span>, você se responsabiliza sobre as quantidades&ndash;&gt;-->
    <!--            &lt;!&ndash;                    geradas pelo PUZL MIX.&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </p>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-12">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <base-button style="font-size: 0.88rem;z-index: 1"&ndash;&gt;-->
    <!--            &lt;!&ndash;                               v-if="![1, 5, 6].includes(travel.status) && !validDoesntHaveDosageAutomation()" block&ndash;&gt;-->
    <!--            &lt;!&ndash;                               type="primary"&ndash;&gt;-->
    <!--            &lt;!&ndash;                               size="sm" outline @click.prevent="!concluding && completeCharging()"&ndash;&gt;-->
    <!--            &lt;!&ndash;                               class="mt-2 mb-2 text-uppercase">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <i class="fa-regular fa-circle-check"></i> Concluir carregamento&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-12" v-if="[1, 5].includes(travel.status)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <span>Carregamento concluído por&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <span class="text-primary">{{ travel.user_name }} </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <span>em {{ travel.finished_at | parseDate }}.</span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </span>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <div class="row mb-2" style="margin-top: -60px"&ndash;&gt;-->
    <!--            &lt;!&ndash;                   v-if="travel.automation_status === 4 && !needs_aprovation_moisture && !start_at">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <div class="col-md-12 text-center">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <img width="350px"&ndash;&gt;-->
    <!--            &lt;!&ndash;                       src="https://img.freepik.com/vetores-gratis/ilustracao-do-conceito-abstrato-de-licenca-de-construcao-aprovacao-oficial-servico-de-empreiteiro-projeto-de-reforma-de-propriedade-planta-de-casa-formulario-de-inscricao-negocio-imobiliario_335657-3330.jpg?t=st=1686673694~exp=1686674294~hmac=0dadf0aa1696271a8d5642e31b0c3fee8fa6fc5763d3bbe2ee109a403c02af18"/>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <h4 class="text-danger"> Carregamento concluído com automação cancelada.</h4>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <h5 class="mt-n1">FAVOR REALIZE NOVAMENTE O CARREGAMENTO.</h5>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <div>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <card class="float-danger" v-if="has_another_os">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <h5><i class="fa-solid fa-triangle-exclamation text-danger"></i> O.S {{ another_os.number }} sem mcc&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </h5>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <router-link target="_blank"&ndash;&gt;-->
    <!--            &lt;!&ndash;                               :to="`/operational/schedule/charging/${another_os.schedule_travel_uuid}`">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    <base-button size="sm" @click="has_another_os = false">&ndash;&gt;-->
    <!--            &lt;!&ndash;                      <i class="fa-solid fa-arrow-up-right-from-square"></i> Abrir viagem&ndash;&gt;-->
    <!--            &lt;!&ndash;                    </base-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </router-link>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </card>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;              <div class="scrolling-box" v-show="!categories_mcc.length" style="margin-top: -2%">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a href="javascript:void(0)" class="float-moisture" @click.prevent="handleCreateWater()">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <i style="font-size: 25px; margin-top: 12px" class="text-white fa-solid fa-droplet"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a href="javascript:void(0)" class="float" @click.prevent="handleCreateMoisture()">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <i style="font-size: 25px; margin-top: 12px" class="text-white fa-solid fa-droplet"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a href="javascript:void(0)" class="float-temperature"&ndash;&gt;-->
    <!--            &lt;!&ndash;                   @click.prevent="handleCreateTemperature(travel.send_plant.id)">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <i style="font-size: 15px" class="text-white fa-solid fa-temperature-high mt-2"></i>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <br>&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <b style="margin-top: -10px">{{ travel.temperature }}&ndash;&gt;-->
    <!--            &lt;!&ndash;                    °C</b>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--          </validation-observer>-->

    <!--        </card>-->
    <!--        &lt;!&ndash;        <card clas="mt-2" v-else style="height: 350px;margin-top: 15px">&ndash;&gt;-->
    <!--        &lt;!&ndash;          <div class="row">&ndash;&gt;-->
    <!--        &lt;!&ndash;            <div class="col-12">&ndash;&gt;-->
    <!--        &lt;!&ndash;              <skeleton-puzl class="mb-4" type="button"/>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <skeleton-puzl class="mb-4" type="button"/>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <skeleton-puzl class="mb-4" type="button"/>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <skeleton-puzl class="mb-4" type="button"/>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <skeleton-puzl class="mb-4" type="button"/>&ndash;&gt;-->
    <!--        &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;          <div class="row ">&ndash;&gt;-->
    <!--        &lt;!&ndash;            <div class="col-12">&ndash;&gt;-->
    <!--        &lt;!&ndash;              <div class="float-right ">&ndash;&gt;-->
    <!--        &lt;!&ndash;                <base-button&ndash;&gt;-->
    <!--        &lt;!&ndash;                  style="opacity: 50%"&ndash;&gt;-->
    <!--        &lt;!&ndash;                  native-type="submit"&ndash;&gt;-->
    <!--        &lt;!&ndash;                  :disabled="true"&ndash;&gt;-->
    <!--        &lt;!&ndash;                >&ndash;&gt;-->
    <!--        &lt;!&ndash;                  Salvar&ndash;&gt;-->
    <!--        &lt;!&ndash;                </base-button>&ndash;&gt;-->
    <!--        &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </card>&ndash;&gt;-->
    <!--      </transition>-->
    <!--    </div>-->
    <ModalTravelCycle @updatedCycle="validPenultimateTravel()" ref="travelCycle"/>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe"
import ModalMCC from './_ModalMcc'
import ModalCreateTemperature from '../../../../Configuration/Temperature/Shared/_ModalCreate'
import ModalCreateWater from '../../../../Configuration/Water/Shared/_ModalCreate'
import ModalCreateMoisture from '../../../../Configuration/Moisture/Shared/_ModalCreate'
import ModalAdjustment from './_ModalAdjustments'
import ModalManualDosage from './_ModalManualDosage'
import moment from 'moment'
import ModalAddAdditionals from './_ModalAddAdditionals'
import {formatDate, mask1} from "@/plugins";
import SkeletonPuzl from "../../../../../../components/SkeletonPuzl";
import ModalHardenedStateTest from "../../Weighing/Shared/_ModalHardenedStateTest";
import InputLimit from "@/components/Utils/InputLimit";
import {base_url_ms} from '@/plugins'
import {debounce} from "@/plugins";
import ModalManualChargingJustification from './_ModalManualChargingJustification.vue'
import BackButton from "../../../../../../components/Utils/BackButtonV2.vue";
import ModalReference from "../../../../Technology/Mix/Shared/_ModalReference.vue";
import automation from "../../../../../../store/modules/automation";
import PuzlSelect from "@/components/PuzlSelect.vue";
import ModalTravelCycle
  from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";

export default {
  name: 'CreateCharging',
  components: {
    ModalReference,
    BackButton,
    ModalHardenedStateTest,
    InputLimit,
    SkeletonPuzl,
    ModalMCC,
    ModalCreateTemperature,
    ModalCreateWater,
    ModalCreateMoisture,
    ModalAdjustment,
    ModalManualDosage,
    ModalAddAdditionals,
    ModalManualChargingJustification,
    PuzlSelect,
    ModalTravelCycle,
  },
  data() {
    return {
      labelGroups: [
        'Acumulado',
        'Individual'
      ],
      automation_text: {
        0: "automação",
        1: "em andamento",
        2: "concluída",
        4: "cancelada",
        5: "interrompida",
      },
      automation_class: {
        0: 'primary',
        1: 'warning',
        2: 'success',
        4: 'danger',
        5: 'light',
      },
      note_comments: null,
      issuer: {
        nfe: false,
        os: false,
      },
      concluding: false,
      selected_plant: null,
      leftOverObservation: '',
      showJustification: false,
      loadingGeneralSettings: false,
      loadingBinderConsumption: false,
      format_mixe: period_types,
      equipment_service: null,
      show: false,
      base_url_ms: base_url_ms(),
      prevRoute: null,
      charging: {},
      now_temperature: null,
      needs_aprovation_moisture: false,
      valid_mcc: [],
      loadingCompanyPlant: true,
      loadingChargePoint: false,
      loadingConcrete: false,
      loadingDrivers: false,
      categories_mcc: [],
      binderIsLessConsumption: false,
      start_at: '',
      loadingSaveReuse: false,
      IsSetManualDosage: false,
      time: '',
      needs_aprovation_mcc: false,
      leftOver: null,
      disabledMcc: false,
      isntSaved: true,
      penultimate_travel_without_attachment: null,
      loadingSave: false,
      testChargePoint: true,
      finished_at: null,
      mccs: [],
      has_another_os: false,
      another_os: {
        number: null,
        schedule_travel_uuid: null
      },
      loadingCompanyPlantIssuer: false,
      interval_automation: null,
      approval_observation: null,
      params_to_approval_mcc: null,
      adjustment_groups: [],
      is_checked_contact: false,
      justification: null,
      loadingUpdateAdditional: false,
      refreshAll: false,
      needs_authorize: false,
      old_plant_uuid: null,
      default_plant_issuer: null,
      old_volume: null,
      after_automation_time: 0,
      saved_travel: {},
      leftOverStatistic: {
        content: {
          title: 'Teor de reuso',
          subtitle: '',
          is_checked: false,
          loading: false,
        },
        ambient_temperature: {
          title: 'Temperatura Ambiente',
          subtitle: '',
          is_checked: false,
          loading: false,
        },
        concrete_temperature: {
          title: 'Temperatura do Concreto',
          subtitle: '',
          is_checked: false,
          loading: false,
        },
        variation_temperature: {
          title: 'Variação da Temperatura',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
        },
        travel_cycle: {
          title: 'Ciclo da Viagem',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
        },
        concreted_piece: {
          title: 'Peça',
          subtitle: '',
          is_checked: false,
          loading: false,
        },
        features: {
          title: 'Responsabilidades',
          subtitle: '',
          is_checked: false,
          loading: false,
        },
        only_aggregate: {
          title: 'Considerar somente agregados',
          subtitle: '',
          no_check: true,
          is_checked: false,
          loading: false,
        },
        slump: {
          title: 'Slump',
          subtitle: '',
          no_check: true,
          is_checked: false,
          loading: false,
        },
        mixe: {
          title: 'Este traço não permite reuso',
          subtitle: '',
          is_checked: false,
          loading: false,
        },
        groups: {
          title: 'Grupos incompátiveis',
        }
      },
      company_plant_fixed_issuer_not_found: false,
      is_checked_limit: false,
      sendNfeDisabled: false,
      nfe_blocked: false,
      limit_volume: undefined,
      charge_point: null,
      valid_left_over: null,
      showLeftOverStatistic: false,
      loadingTravel: true,
      additionalSelected: null,
      categories:
        {
          11: 'REAPROVEITAMENTO - REUSO',
          12: 'REDIRECIONAMENTO - REUSO',
          21: 'SOBRA DE CONCRETO - DESCARTE',
          22: 'PERDA DE CONCRETO - DESCARTE',
        },
      list_concrete: [
        {
          id: '1',
          name: '257 (1,5 m3) Reaproveitamento',
        }
      ],
      schedule_travel_status: {
        0: 'released',
        1: 'finished',
        2: 'loading',
        4: 'waiting_for_release',
        5: 'in_progress',
        6: 'canceled',
        7: 'unrealized',
        8: 'has_pendency',
        9: 'seller_request',
      },
    }
  },
  computed: {
    penultimateDontHasAttachment: function () {
      return this.$_general_settings?.disable_charging_without_attachment && this.penultimate_travel_without_attachment && !this.penultimate_travel_without_attachment?.has_attachment
    },
    automation() {
      return automation
    },
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
      plants: 'plant/activeItems',
      travel: "travels/show",
      $_general_settings: "generalSetting/show",
      mcc: "travels/mcc",
      company_plant_issuers: "plant/getCompanyPlantIssuers",
      equipments: "equipment/fetch",
      chargePoints: 'plantChargePoint/fetch',
      drivers: 'user/getDrivers',
      travelLeftOvers: 'travelLeftOver/fetch',
      schedule_additionals: 'scheduleAdditional/fetch',
      user: "auth/getUser",
      $_technical_permission: "technicalPermission/show",
    }),
    timeStarted: function () {
      var date = new Date(0);
      date.setMilliseconds(this.time);
      return date.toISOString().substr(11, 8);
    },
    disableSetTime: function () {
      let values = [
        'send_plant',
        'charge_point_uuid',
        'volume',
        'driver_uuid',
        'equipment_uuid',
      ]
      let disabled = false
      const self = this
      values.forEach(function (item, index) {
        if (!self.$helper.optionalChaining(self.travel, item)) {
          disabled = true
        }
      })
      return disabled
    },
  },
  watch: {
    showJustification: function (show) {
      if (!show) {
        this.getAdditionals()
      }
    },
    'after_automation_time': function (value) {
      if (value >= 1 && !this.interval_automation) {
        const self = this
        self.interval_automation = setInterval(function () {
          /*
            Valida se existe mcc
           */
          if (self.categories_mcc.length === 0) {
            return;
          }
          self.$store.dispatch("travels/show", {uuid: self.$route.params.travel_uuid, code: '0'})
            .then(response => {
              self.categories_mcc = response.data.categories_mcc
              if (response.data.automation_status === 2 && self.travel.categories_mcc.length) {
                self.categories_mcc = response.data.categories_mcc
                for (var mcc of self.categories_mcc) {
                  let previous = null
                  for (var group of mcc.groups) {
                    if (previous) {
                      group.previous_manual_dosage = previous.manual_dosage
                    }
                    if (!previous || previous.manual_dosage != null || previous.automation) {
                      group.previous_has_automation_manual = true
                      if (previous) {
                        const automation = previous.automation || 0
                        const manual = previous.manual_dosage || 0
                        group.previous_value = parseFloat(automation) + parseFloat(manual)
                      } else {
                        group.previous_value = 0
                      }
                    }
                    previous = group
                  }
                }
                self.after_automation_time = 0
                clearInterval(self.interval_automation)
                self.interval_automation = null
                self.$notify({type: 'success', message: 'Automação concluída com sucesso!'});
              }
              /*
                CANCELAMENTO DE CARGA PELO OPERADOR EM TEMPO DE EXECUÇÃO
               */
              if (response.data.automation_status === 0 && !self.travel.categories_mcc.length) {
                self.after_automation_time = 0
                clearInterval(self.interval_automation)
                self.interval_automation = null
                self.getAdditionals(false)
                self.travel.left_over_uuid = null
                self.travel.categories_mcc = []
                self.categories_mcc = []
                self.disabledMcc = false
                self.travel.all_isset_manual_dosage = false
                self.travel.isset_manual_dosage = false
                self.IsSetManualDosage = false
                self.$refs.ModalMCC.clearTeste()
                self.mccs = []
                self.travel.approval_observation = null
                self.travel.approval_user_id = null
                self.approval_observation = null
                self.$notify({type: 'warning', message: 'Automação cancelada pelo operador'})
                self.$Progress.finish();
                self.after_automation_time = 0
              }
              if (response.data.automation_status === 5) {
                this.after_automation_time = 0
                clearInterval(this.interval_automation)
                this.interval_automation = null
              }
              /*
                CANCELAMENTO DE CARGA EM BACKGROUND
               */
              if (response.data.automation_status === 4 && !self.travel.categories_mcc.length) {
                self.after_automation_time = 0
                clearInterval(self.interval_automation)
                self.interval_automation = null
                self.getAdditionals(false)
                self.travel.left_over_uuid = null
                self.travel.categories_mcc = []
                self.categories_mcc = []
                self.disabledMcc = false
                self.travel.all_isset_manual_dosage = false
                self.travel.isset_manual_dosage = false
                self.IsSetManualDosage = false
                self.$refs.ModalMCC.clearTeste()
                self.mccs = []
                self.travel.approval_observation = null
                self.travel.approval_user_id = null
                self.approval_observation = null
                self.$notify({type: 'warning', message: 'Automação cancelada pelo operador'})
                self.$Progress.finish();
                self.after_automation_time = 0
              }
            })
        }, 10000);
      }
    }
  },
  methods: {
    async validPenultimateTravel() {
      await this.showTravel()
      this.verifyEquipment()
    },
    /**
     * Abre modal de edição de Ciclo de Viagem
     * @param {object} travel
     */
    handleTravelCycle(travel) {
      this.$refs.travelCycle.openModal(
        travel.uuid,
        travel.address_construction_uuid
      );
    },
    setComment: debounce(function () {
      let params = {
        comments: this.travel.comment,
        id: this.travel.id
      }
      let loader = this.$loading.show()
      this.$store.dispatch("travels/postSetComment", params).then(response => {
        loader.hide()
        this.$snotify.success(response.message.toUpperCase(), {
          timeout: 1000,
          icon: false,
          position: "centerTop",
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false
        });
      }).catch(() => loader.hide());
    }, 500),
    setObservation() {
      let params = {
        observation: this.travel.observation,
        id: this.travel.id
      }
      let loader = this.$loading.show()
      this.$store.dispatch("travels/postSetObservation", params).then(response => {
        loader.hide()
        this.$snotify.success(response.message.toUpperCase(), {
          timeout: 1000,
          icon: false,
          position: "centerTop",
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false
        });
      }).catch(() => loader.hide());
    },
    setCheckLimit() {
      if (!this.$_technical_permission.is_block_completion_possible_with_excessive_deviation) {
        return this.$notify({
          type: 'warning',
          message: 'Você não possui permissão para concluir carregamentos com limite acima do permitido'
        })
      }
      this.is_checked_limit = !this.is_checked_limit
    },
    updateServiceOrder() {
      const loader = this.$loading.show();
      const service = this.travel.schedule.services.find(
        (item) => item.id === this.travel.schedule_service_id,
      );
      this.$store
        .dispatch("travels/updateService", {
          schedule_travel_id: this.travel.id,
          schedule_service_id: service ? service.id : null,
        })
        .then(() => {
          this.setEquipmentService();
        })
        .catch((error) => {
          this.travel.schedule_service_id = null;
          this.$notify({type: "danger", message: error.data.message});
        })
        .finally(() => {
          loader.hide();
        });
    },
    setEquipmentService() {
      const service = this.travel.schedule.services.find((item) => item.id === this.travel.schedule_service_id);
      this.equipment_service = service?.equipment_code ?? null;
    },
    /**
     *
     * @param {number} mcc_id - id schedule_travel_mcc
     * @param {number} individual_cut - valor de corte
     */
    setIndividualCut(mcc_id, individual_cut) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('travels/putUpdateIndividualCut', {
        mcc_id: mcc_id,
        individual_cut: individual_cut
      }).then((response) => {
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        this.showTravel()
      }).catch(error => {
        this.$notify({type: 'danger', message: error.data.message})
      }).finally(() => {
        this.$Progress.finish()
        loader.hide()
      })
    },
    /**
     * Valida se a automação é local, se for local, não será consulta, o usuário deve aguardar
     * @returns {boolean}
     */
    isLocalAutomation() {
      const chargePoint = this.chargePoints.find(item => item.uuid == this.travel.charge_point_uuid)
      if (chargePoint.automation && chargePoint.automation.automation_id == 2) {
        return false
      }
      return true
    },
    setCheckContact() {
      if (!this.categories_mcc.length) {
        this.is_checked_contact = !this.is_checked_contact
      }
    },
    downloadNfe() {
      if (this.travel.has_xml) {
        let loader = this.$loading.show()
        this.$Progress.start()
        // this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
        this.$store.dispatch('travels/getXmlByTravel', this.travel.id).then(async response => {
          if (response.data.status && response.data.status === 2) {
            return this.$notify({
              type: 'danger',
              message: 'Não foi possível realizar o download da Danfe. Nota rejeitada'
            })
          }
          if (!response.data.pdf_file_path) {
            return this.$notify({
              type: 'danger',
              message: 'Não foi possível realizar o download da Danfe. Emissão em andamento'
            })
          }
          const url = this.base_url_ms + 'download-s3?url=' + response.data.pdf_file_path
          let file_name = `${response.data.key}.pdf`
          this.$store.dispatch('exports/download', url).then(async response => {
            let blob = new Blob([response], {type: "application/pdf"});
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", `${file_name}`);
            await link.click()
          });
        })
          .catch((error) => {
            return this.$notify({
              type: 'danger',
              message: 'Não foi possível realizar o download da Danfe. Emissão em andamento'
            })
          })
          .finally(() => {
            this.$Progress.finish()
            loader.hide()
          })
        return
      }
    },
    validNfe() {
      return this.$notify({type: 'danger', message: 'Favor emitir a O.S para continuar'})
    },
    issueNfe: debounce(async function () {
      if (this.sendNfeDisabled) {
        return;
      }
      if (this.travel.has_xml) {
        return;
      }
      if (this.travel.has_nfe) {
        return;
      }
      if (!this.nfe_blocked && this.travel.block_change_nfe && !this.issuer.nfe) {
        this.issuer.nfe = true
        await this.updateNfe(this.issuer.nfe)
      }
      if (!this.issuer.nfe) {
        return this.$notify({type: 'danger', message: 'Não é possível emitir a NF-e. Altere o checkbox para continuar'})
      }
      if (!this.travel.number) {
        return this.$notify({type: 'danger', message: 'Favor emitir a O.S para continuar'})
      }
      if (!this.categories_mcc.length) {
        return this.$notify({type: 'danger', message: 'Favor realize o carregamento antes de continuar.'});
      }
      this.sendNfeDisabled = true;
      let loader = this.$loading.show()
      this.$Progress.start()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store.dispatch('travels/postSendNfe', this.travel.id).then((response) => {
        this.travel.xml = response.data
        this.travel.current_number = response.data && response.data.number
        this.travel.has_nfe = true
        this.travel.has_xml = true
        this.$notify({
          type: response.error_type,
          message: response.message
        })
      }).catch(error => {
        this.sendNfeDisabled = false
        this.$notify({type: 'danger', message: error.data.message})
      }).finally(() => {
        this.$Progress.finish()
        loader.hide()
      })
    }, 200),
    copyMixCode(code) {
      this.$helper.copy(code)
      this.$notify({type: 'success', message: 'Código do traço copiado com sucesso!'})
    },
    copyDosageCode(code) {
      this.$helper.copy(code)
      this.$notify({type: 'success', message: 'Código do local de saída copiado com sucesso!'})
    },
    copyGroupCode(code) {
      this.$helper.copy(code)
      this.$notify({type: 'success', message: 'Código do material copiado com sucesso!'})
    },
    verifyEquipment() {
      this.penultimate_travel_without_attachment = null
      this.limit_volume = undefined
      const equipment = this.equipments.find(item => item.uuid === this.travel.equipment_uuid)
      let loader = this.$loading.show()
      this.getPenultimateTravelByEquipment(true)
      loader.hide()
      if (!equipment) {
        return
      }
      if (Number(this.travel.volume) > Number(equipment.price)) {
        this.limit_volume = equipment.price
      }
    },
    /**
     * Prenche equipamento padrão
     *
     * @returns {bool}
     */
    fillDriverDefaultEquipment() {
      if (this.travel.driver_uuid) {
        const driver = this.drivers.find(item => item.uuid === this.travel.driver_uuid)
        if (!driver.equipment_default) {
          return;
        }
        const default_equipment = this.equipments.find(item => item.uuid === driver.equipment_default)
        this.travel.equipment_uuid = default_equipment ? default_equipment.uuid : null;
      }
    },
    started() {
      return this.categories_mcc.some(function (item) {
        return item.groups.some(val => val.manual_dosage !== null || val.automation !== null)
      })
    },
    searchAutomation() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      let loader = this.$loading.show()
      this.$store
        .dispatch("travels/searchAutomation", this.travel.uuid).then(response => {
        this.$store.dispatch("travels/show", {uuid: this.$route.params.travel_uuid, code: '0'})
          .then(response => {
            if (response.data.automation_status === 2) {
              this.categories_mcc = response.data.categories_mcc
              for (var mcc of this.categories_mcc) {
                let previous = null
                for (var group of mcc.groups) {
                  if (previous) {
                    group.previous_manual_dosage = previous.manual_dosage
                  }
                  if (!previous || previous.manual_dosage != null || previous.automation) {
                    group.previous_has_automation_manual = true
                    if (previous) {
                      const automation = previous.automation || 0
                      const manual = previous.manual_dosage || 0
                      group.previous_value = parseFloat(automation) + parseFloat(manual)
                    } else {
                      group.previous_value = 0
                    }
                  }
                  previous = group
                }
              }
              this.after_automation_time = 0
              clearInterval(this.interval_automation)
              this.interval_automation = null
              this.$notify({type: 'success', message: 'Automação concluída com sucesso!'});
            } else if (response.data.automation_status === 0) {
              this.after_automation_time = 0
              clearInterval(this.interval_automation)
              this.interval_automation = null
              this.getAdditionals(false)
              this.travel.left_over_uuid = null
              this.travel.categories_mcc = []
              this.categories_mcc = []
              this.disabledMcc = false
              this.travel.all_isset_manual_dosage = false
              this.travel.isset_manual_dosage = false
              this.IsSetManualDosage = false
              this.$refs.ModalMCC.clearTeste()
              this.mccs = []
              this.travel.approval_observation = null
              this.travel.approval_user_id = null
              this.approval_observation = null
              this.$notify({type: 'warning', message: 'Automação cancelada pelo operador'})
              this.$Progress.finish();
              this.after_automation_time = 0
            } else {
              this.$notify({type: 'success', message: 'Automação em andamento.'})
            }
            loader.hide()
          })
      })
    },
    async setNfe() {
      if (this.nfe_blocked) {
        return this.$notify({type: 'danger', message: 'Altere a configuração de emissão da central para continuar.'})
      }
      if (!this.nfe_blocked && this.travel.block_change_nfe && !this.issuer.nfe) {
        this.issuer.nfe = true
        await this.updateNfe(this.issuer.nfe)
      }
      if (this.travel.has_xml || this.travel.block_change_nfe || this.travel.has_nfe) {
        return
      }
      const copy = {...this.travel}
      this.issuer.nfe = !Boolean(this.issuer.nfe)
      let loader = this.$loading.show()
      this.$store.dispatch('travels/update', {
        uuid: this.travel.uuid,
        has_xml: this.travel.has_xml,
        nfe: this.issuer.nfe,
        only_change_nfe: true
      }).then(response => {
        this.travel.charge_point_uuid = copy.charge_point_uuid
        this.travel.company_plant_issuer_id = copy.company_plant_issuer_id
        this.travel.send_plant.uuid = copy.send_plant.uuid
        this.travel.driver_uuid = copy.driver_uuid
        this.travel.equipment_uuid = copy.equipment_uuid
        this.travel.volume = copy.volume
        this.travel.seal = copy.seal
        if (this.default_plant_issuer && !this.travel.company_plant_issuer_id) {
          if (!this.travel.schedule.contract_proposal.issuer_fixed ) {
            this.travel.company_plant_issuer_id = this.default_plant_issuer.id
          }
        }
        this.$Progress.finish();
        this.loadingSave = false
        this.isntSaved = false
        loader.hide()
        this.$notify({type: 'success', message: response.message});
      }).catch(error => {
        this.$Progress.finish();
        loader.hide()
        this.loadingSave = false
        this.$notify({type: 'danger', message: error.message});
      })
    },
    /**
     * Realiza o download do relatório de carregamento
     */
    downloadReport() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      let loader = this.$loading.show();
      let params = {
        uuid: this.travel.uuid,
        type: "report"
      };
      this.$store
        .dispatch("travels/download", params)
        .then(response => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf");
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },
    generateNumber() {
      if (this.travel.number) {
        let params = {
          uuid: this.travel.uuid,
          type: "proof"
        };
        let loader = this.$loading.show();
        this.$store
          .dispatch("travels/download", params)
          .then(response => {
            let blob = new Blob([response], {type: "application/pdf"});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "custom_name.pdf");
            window.open(link, "_blank");
            loader.hide();
          })
          .catch(error => {
            loader.hide();
          });
        return
      }
      if (!this.categories_mcc.length) {
        return this.$notify({type: 'warning', message: 'Favor realize o carregamento antes de continuar.'});
      }
      if (this.loadingGeneralSettings) {
        return
      }
      if (!this.$_general_settings.is_concrete_os_issued_before_loading_completion) {
        return this.$notify({type: 'warning', message: 'A O.S será emitida após a conclusão do carregamento'});
      }
      this.$Swal.confirmAction('Deseja realmente emitir o comprovante de carregamento antes de concluir o carregamento?')
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show()
            this.$Progress.start();
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$store.dispatch('travels/generateNumber', {
              id: this.travel.id
            })
              .then(response => {
                loader.hide()
                this.travel.number = response.data
                this.$notify({type: 'success', message: response.message});
              }).catch(() => {
              loader.hide()
            })
          }
        }).catch(() => this.$Progress.finish())
    },
    setNowTemperature(e) {
      this.now_temperature = e
    },
    cancelAprovationMoisture() {
      this.needs_aprovation_moisture = false
      this.valid_mcc = []
      this.$notify({type: 'success', message: 'Solicitação realizada com sucesso!'});
    },
    saveJustification() {
      let params = {
        justification: this.justification,
        status: this.additionalSelected.status ? 1 : 3,
        uuid: this.additionalSelected.uuid
      }
      this.loadingUpdateAdditional = true
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('scheduleAdditional/update', params)
        .then(response => {
          this.$Progress.finish();
          this.showJustification = false
          this.loadingUpdateAdditional = false
          this.$notify({type: 'success', message: response.message});
        }).catch(error => {
        this.$notify({type: 'danger', message: error.data.message});
        this.loadingUpdateAdditional = false
        this.$Progress.finish();
      })
    },
    cancelJustification() {
      this.showJustification = false
      this.getAdditionals()
    },
    validHaveManualDosage(category, result = false) {
      if (category.groups[0].automation && this.travel.automation_status === 1) {
        return '/img/icons/icons8/ios/badge-primary.png'
      }
      let have = null
      if (category.hasOwnProperty('accumulated_category_uuid')) {
        const Category = this.categories_mcc.find((item) => item.uuid === category.accumulated_category_uuid)
        const categoryIndex = this.categories_mcc.findIndex((item) => item.uuid === category.uuid)
        if (this.validHaveManualDosage(Category, true)) {
          have = true
          const category = Category.groups.slice(-1)[0]
          this.categories_mcc[categoryIndex].previous_category_has_manual = true
          this.categories_mcc[categoryIndex].groups[0].previous_value = parseFloat(category.manual_dosage)
        } else {
          this.categories_mcc[categoryIndex].previous_category_has_manual = false
          return '/img/icons/icons8/ios/badge-danger.png'
        }
      }
      let valid_charging = []
      for (var item of category.groups) {
        let value = item.automation !== null || item.manual_dosage !== null
        valid_charging.push(value)
      }
      if (valid_charging.every(elem => elem === true)) {
        have = true
      } else {
        have = false
      }
      if (result) {
        return have
      }
      if (have) {
        return '/img/icons/icons8/ios/badge-success.png'
      }
      return '/img/icons/icons8/ios/badge-yellow.png'
    },
    setStatusAdditional(additional) {
      this.justification = null
      this.additionalSelected = additional
      if (!additional.status) {
        this.showJustification = true
        return
      }
      this.saveJustification()
    },
    setChargePoint() {
      this.charge_point = this.chargePoints.find(item => item.uuid == this.travel.charge_point_uuid)
    },
    decimals(category, value) {
      if (value == 0) {
        return 0
      }
      value = parseFloat(value)
      switch (category) {
        case 2:
        case 3:
        case 6:
          value = parseInt(value)
          break;
        case 1:
          value = value.toFixed(1)
          break;
        default:
          value = value.toFixed(2)
          break;
      }
      if (value == -0.00) {
        return 0.00
      }
      return value
    },
    setEquipemnt() {
      if (this.user.operational.equipment_default) {
        this.travel.equipment_uuid = this.user.operational.equipment_default
      } else {
        this.fillDriverDefaultEquipment();
      }
      this.getPenultimateTravelByEquipment()
    },
    async getCompanyPlantIssuers() {
      this.loadingCompanyPlantIssuer = true
     await this.$store.dispatch('plant/fetchCompanyPlantIssuersByPlantByUuid', {
        uuid: this.travel.send_plant.uuid
      }).then(async response => {
        if (![0].includes(this.travel.status)) {
          return;
        }
        let default_plant_issuer = response.data.find(item => item.default)
        /**
         * verifica se possui CNPJ Emissor fixo
         */
        if (this.travel.schedule.contract_proposal.issuer_fixed) {
          let fixed_issuer_id = this.travel.schedule.contract_proposal.company_plant_issuer_id_fixed
          /**
           * verifica se o CNPJ Emissor fixo pertence a central selecionada
           */
          let has_issuer = response.data.find((item) => item.id === fixed_issuer_id)
          if (has_issuer) {
            default_plant_issuer = {
              id: fixed_issuer_id
            }
            let has_issuer_filled = !!this.travel.company_plant_issuer_id
            this.travel.company_plant_issuer_id = fixed_issuer_id
             if (has_issuer_filled) {
                  this.save()
            }
            this.company_plant_fixed_issuer_not_found = false
          } else {
            this.company_plant_fixed_issuer_not_found = true
            this.travel.company_plant_issuer_id = null
          }
        } else {
          if (default_plant_issuer && !this.travel.company_plant_issuer_id) {
            this.travel.company_plant_issuer_id = default_plant_issuer.id
          }
        }
        this.default_plant_issuer = default_plant_issuer
        this.loadingCompanyPlantIssuer = false
      }).catch(error => {
        this.loadingCompanyPlantIssuer = false
      })
    },
    autorizeReuse(needsAutorize = false) {
      let leftOverStatistic = {...this.leftOverStatistic}
      leftOverStatistic.incompatible_groups = this.travel.incompatible_groups
      let params = {
        travel_reference_uuid: this.travel.uuid,
        uuid: this.leftOver ? this.leftOver.uuid : null,
        left_over_statistic: this.leftOver ? leftOverStatistic : null,
        needs_authorize: needsAutorize,
        observation: this.leftOverObservation,
      }
      this.$Progress.start();
      this.loadingSaveReuse = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travelLeftOver/autorize', params).then(response => {
        this.$Progress.finish();
        this.travel.old_left_over_uuid = response.data.uuid
        this.$notify({type: 'success', message: response.message});
        this.needs_authorize = false
        this.loadingSaveReuse = false
        this.valid_left_over = true
        this.getLeftOver()
        this.showTravel()
        this.$Progress.finish();
      }).catch((error) => {
        this.$notify({type: error.data.error_type, message: error.data.message})
        this.needs_authorize = false
        this.loadingSaveReuse = false
        this.valid_left_over = true
        this.getLeftOver()
        this.showTravel()
        this.$Progress.finish();
      })
    },
    setManualDosage(params) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$Progress.start();
      let parameters = {
        uuid: params.group.uuid,
        automation: params.group.automation,
        wet_weight: params.group.charging_wet_weight,
        category_id: params.group.category_id,
        moisture: params.group.moisture,
        variation: params.group.variation,
        water_correction: params.group.water_correction,
        theorical_without_reuse: params.group.charging.theorical_without_reuse,
        manual_dosage: params.group.manual_dosage,
        adjustment: params.group.adjustment,
        schedule_travel_uuid: this.travel.uuid,
        measured_unit_id: params.group.measured_unit_id,
        is_individual: params.group.is_individual,
        previous_manual_dosage: params.group.previous_manual_dosage
      }
      if (parameters.category_id === 6) {
        parameters.volume_ag_am = params.group.volume_ag_am
        parameters.is_first = params.group.is_first
      }
      this.$store.dispatch('travels/putSetManualDosage', parameters)
        .then(response => {
          this.travel.isset_manual_dosage = true
          this.travel.all_isset_manual_dosage = response.data.all_isset_manual_dosage
          this.is_checked_limit = 0
          this.disabledMcc = true
          this.$Progress.finish();
          this.$set(this.categories_mcc[params.indexCategory].groups, params.indexGroup, params.group)
          this.$set(this.travel, 'deviation', response.data.deviation)
          this.$notify({type: 'success', message: 'Carregamento manual atribuída com sucesso!'});
          this.$refs.ModalManualDosage.close()
          this.IsSetManualDosage = false
          this.IsSetManualDosage = true
          for (var mcc of this.categories_mcc) {
            let previous = null
            for (var group of mcc.groups) {
              if (previous) {
                group.previous_manual_dosage = previous.manual_dosage
              }
              if (!previous || previous.manual_dosage != null || previous.automation !== null) {
                group.previous_has_automation_manual = true
                if (previous) {
                  const automation = previous.automation || 0
                  const manual = previous.manual_dosage || 0
                  group.previous_value = parseFloat(automation) + parseFloat(manual)
                } else {
                  group.previous_value = 0
                }
              }
              previous = group
            }
          }
          const self = this
          const filter = this.categories_mcc[params.indexCategory].groups.findIndex(function (item) {
            if (item.group_id === self.categories_mcc[params.indexCategory].groups[params.indexGroup]
              || item.manual_dosage !== null || item.automation !== null) {
              return false;
            }
            if (item.manual_dosage === null) {
              return item
            }
          })
          if (filter !== -1) {
            setTimeout(() => {
              this.handleShowModalDosageManual(params.indexCategory, filter, true)
            }, 250);
          }
          var i = 1;
          var category = null
          let filter_next_category = -1
          for (var i = 0; i <= 6; i++) {
            filter_next_category = self.categories_mcc[params.indexCategory + i].groups.findIndex(function (item) {
              if (item.manual_dosage !== null || item.automation !== null) {
                return false;
              }
              if (item.manual_dosage === null && item.automation === null) {
                if (params.indexCategory + 1 != item.category_id) {
                  category = params.indexCategory + i
                }
                return item
              }
            })
            if (filter_next_category !== -1) {
              break;
            }
          }
          if (filter_next_category !== -1 && filter === -1) {
            setTimeout(() => {
              category = category ? category : params.indexCategory + 1
              this.handleShowModalDosageManual(category, filter_next_category, true)
            }, 250);
          }
        })
    },
    setTime() {
      this.start_at = moment(new Date())
      let params = {
        start_at: this.start_at,
        uuid: this.$route.params.travel_uuid
      }
      this.$store.dispatch('travels/putSetTimer', params)
    },
    completeCharging: debounce(async function (msg = true) {
      this.concluding = true
      if (this.travel.schedule.contract_proposal.automatic_nfe !==
        this.travel.nfe && this.travel.nfe && !this.travel.has_xml && !this.travel.block_change_nfe) {
        this.$Swal.confirmAction('A nota fiscal está selecionada para emissão. ' +
          'Deseja manter essa seleção e continuar com a conclusão do carregamento?')
          .then((result) => {
            if (!result.isConfirmed) {
              let loader = this.$loading.show()
              this.$store.dispatch('travels/update', {
                uuid: this.travel.uuid,
                has_xml: this.travel.has_xml,
                nfe: false,
                only_change_nfe: true
              }).then(() => {
                this.travel.nfe = false
                this.$Progress.finish();
                loader.hide()
                this.continueCompleteCharging(msg)
              })
            } else {
              this.continueCompleteCharging(msg)
            }
          })
      } else {
        this.continueCompleteCharging(msg)
      }
    }, 100),
    async forceComplete(params) {
      if (!this.finished_at) {
        return this.finishTime(true, true, params)
      }
      await this.setObservation()
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$Progress.start();
      let loader = this.$loading.show()
      this.$store.dispatch('travels/postCompleteManualCharging',
        {uuid: this.$route.params.travel_uuid, reason: params.reason}).then(response => {
        this.$Progress.finish();
        loader.hide()
        this.$notify({type: 'success', message: response.message})
        this.$refs.modalManualChargingJustification.closeModal()
        this.showTravel()
        // this.$router.push({
        //   name: 'operational.schedule.weighing.index',
        //   params: {schedule_uuid: this.travel.schedule_uuid}
        // })
      }).catch(error => {
        loader.hide()
        this.$Progress.finish();
        this.showTravel()
        this.$notify({type: 'danger', message: error.data.message})
      })
    },
    hasAutomationAndDontUsed() {
      const chargePoint = this.chargePoints.find(item => item.uuid == this.travel.charge_point_uuid)
      if (chargePoint && chargePoint.automation && this.travel.automation_status === 0) {
        if (this.$_operational_permission.can_manual_schedule_travel_charging) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    continueCompleteCharging: debounce(async function (msg = true) {
      if (this.penultimateDontHasAttachment) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Não é possível concluir o carregamento',
          text: 'O equipamento selecionado está sem anexo na penúltima viagem.',
        })
      }
      const chargePoint = this.chargePoints.find(item => item.uuid == this.travel.charge_point_uuid)
      if (chargePoint.automation && this.travel.automation_status === 0) {
        this.concluding = false
        return this.$refs.modalManualChargingJustification.openModal(this.travel)
      }
      if (msg) {
        if (!this.finished_at) {
          return this.finishTime(true)
        }
        await this.setObservation()
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
        this.$Progress.start();
        let loader = this.$loading.show()
        this.$store.dispatch('travels/postCompleteCharging',
          {uuid: this.$route.params.travel_uuid}).then(response => {
          this.$Progress.finish();
          loader.hide()
          this.$notify({type: 'success', message: response.message})
          this.showTravel()
          this.$refs.modalManualChargingJustification.closeModal()
          // this.$router.push({
          //   name: 'operational.schedule.weighing.index',
          //   params: {schedule_uuid: this.travel.schedule_uuid}
          // })
        }).catch(error => {
          loader.hide()
          this.$Progress.finish();
          this.showTravel()
          this.$refs.modalManualChargingJustification.closeModal()
          this.$notify({type: 'danger', message: error.data.message})
        })
      } else {
        await this.setObservation()
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
        this.$Progress.start();
        let loader = this.$loading.show()
        this.$store.dispatch('travels/postCompleteCharging',
          {uuid: this.$route.params.travel_uuid}).then(response => {
          this.$Progress.finish();
          loader.hide()
          this.$notify({type: 'success', message: response.message})
          this.showTravel()
          this.$refs.modalManualChargingJustification.closeModal()
          // this.$router.push({
          //   name: 'operational.schedule.weighing.index',
          //   params: {schedule_uuid: this.travel.schedule_uuid}
          // })
        }).catch(error => {
          loader.hide()
          this.$Progress.finish();
          this.showTravel()
          this.$refs.modalManualChargingJustification.closeModal()
          this.$notify({type: 'danger', message: error.data.message})
        })
      }
    }, 300),
    putSetTimer(msg = true) {
      let params = {
        start_at: this.start_at,
        uuid: this.$route.params.travel_uuid,
        unset: true,
      }
      this.$store.dispatch('travels/putSetTimer', params)
        .then(response => {
          this.start_at = null
          this.time = null
          this.finished_at = null
          if (msg) {
            this.$notify({type: 'success', message: response.message});
          }
        })
    },
    resetTime() {
      this.$Swal.confirmAction('O cronômetro será redefinido e o tempo decorrido até aqui será perdido.')
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$Progress.finish();
            this.putSetTimer()
          }
        }).catch(() => this.$Progress.finish())
    },
    /**
     * Valida se existe algum grupo que deve ser carregado manualmente que não foi preenchido
     * @returns {boolean}
     */
    validDoesntHaveDosageAutomation() {
      let have = false
      for (let item of this.categories_mcc) {
        for (let group of item.groups) {
          if (group.is_dosage_automation) {
            if (!group.manual_dosage) {
              have = true
            }
          }
        }
      }
      return have
    },
    handleShowModalDosageManual(indexCategory, indexGroup, ignore_error = false) {
      if (this.needs_authorize) {
        return this.$notify({
          type: 'warning',
          message: 'Autorize ou cancele o reuso antes de continuar'
        })
      }
      var group = this.categories_mcc[indexCategory]['groups'][indexGroup]
      if (this.travel.has_left_over || (this.travel.automation_status == 1 && !group.is_dosage_automation) || this.travel.status === 1) {
        return
      }
      const all_groups = this.categories_mcc[indexCategory]['groups']
      let has_acummulated_after = false
      let is_before = true
      for (var i in all_groups) {
        if (has_acummulated_after) {
          continue;
        }
        if (!is_before) {
          const group = all_groups[i]
          if (group.is_individual) {
            continue;
          }
          if (group.manual_dosage !== null) {
            has_acummulated_after = true
          }
        }
        if (i == indexGroup) {
          is_before = false
        }
        if (is_before) {
          continue;
        }
      }
      // Verifica se a dosagem não é manual e o status de automação de viagem não é igual a não iniciado
      if (!group.is_dosage_manual && !this.travel.automation_status === 0) {
        // Valida se a viagem já foi concluída
        if (!group.is_dosage_manual && this.travel.status === 5) {
          return this.$notify({
            type: 'danger',
            message: 'Não é possível carregar um grupo que o local de saída não seja dosagem manual'
          })
        }
      }
      if (group.is_dosage_automation) {
        ignore_error = true
      }
      if (this.categories_mcc[indexCategory].hasOwnProperty('accumulated_category_uuid')) {
        const accumulatedCategory = this.categories_mcc.find((item) => item.uuid === this.categories_mcc[indexCategory].accumulated_category_uuid)
        let groups = {...accumulatedCategory.groups}
        let lastAccumulated = 0;
        for (var item of Object.values(groups).reverse()) {
          if (item.charging.accumulated_wet_weight) {
            lastAccumulated = item.manual_dosage
            break;
          }
        }
        for (var group of this.categories_mcc[indexCategory].groups) {
          if (group.charging.accumulated_wet_weight) {
            group.previous_manual_dosage = lastAccumulated
            break;
          }
        }
      }
      if (!this.categories_mcc[indexCategory].groups[indexGroup].previous_has_automation_manual && !ignore_error) {
        return this.$notify({type: 'danger', message: 'Favor informe o carregamento do grupo anterior!'})
      }
      if (!this.categories_mcc[indexCategory].previous_category_has_manual && !ignore_error) {
        return this.$notify({
          type: 'danger',
          message: 'Favor informe o carregamento da categoria anterior ser acumulada!'
        })
      }
      /**
       * Validar se existe carregamento na categoria que utiliza o acumulado
       */
      const has_accumulated = this.categories_mcc.find((item) =>
        item.accumulated_category_uuid && item.accumulated_category_uuid === this.categories_mcc[indexCategory].uuid)
      if (has_accumulated) {
        const has_some = has_accumulated.groups.some((item) => item.manual_dosage !== null || item.automation != null)
        if (has_some) {
          has_acummulated_after = true
        }
      }
      if (this.categories_mcc[indexCategory].groups[indexGroup].manual_dosage) {
        this.categories_mcc[indexCategory].groups[indexGroup].manual_dosage = this.decimals(this.categories_mcc[indexCategory].id, this.categories_mcc[indexCategory].groups[indexGroup].manual_dosage)
      }
      let Group = {...this.categories_mcc[indexCategory].groups[indexGroup]}
      const before_order = Group.order - 1
      const group_before =
        this.categories_mcc[indexCategory].groups.find(item => item.order === before_order)
      Group.has_acummulated_after = has_acummulated_after
      if (group_before) {
        Group.group_before_manual_dosage = group_before.manual_dosage
      }
      if (!this.start_at) {
        this.setTime()
      }
      this.$refs.ModalManualDosage.openModal(Group,
        {
          indexCategory: indexCategory,
          indexGroup: indexGroup
        },
        this.categories_mcc[indexCategory],
        this.travel
      )
    },
    showTime() {
      if (this.start_at && !this.finished_at) {
        var now = moment(new Date());
        var duration = moment.duration(now.diff(this.start_at));
        var milliseconds = duration.asMilliseconds();
        this.time = milliseconds
      }
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return this.$helper.checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return this.$helper.checkNumberValue(value, min, max);
    },
    /**
     * Finaliza o temporizador da carga
     * @param {Boolean} complete
     * @param force
     * @param additional_params
     */
    finishTime(complete = false, force = false, additional_params = null) {
      let loader = this.$loading.show()
      this.finished_at = moment(new Date())
      this.$Progress.start();
      if (!force) {
        this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      }
      let params = {
        finished_at: this.finished_at,
        uuid: this.$route.params.travel_uuid
      }
      this.$store.dispatch('travels/putFinishTimer', params)
        .then(response => {
          loader.hide()
          this.$Progress.finish();
          if (force) {
            return this.forceComplete(additional_params)
          }
          if (complete) {
            this.continueCompleteCharging(false)
          }
          this.$notify({type: 'success', message: response.message});
        })
    },
    setTemperature(params) {
      this.travel.temperature = params.temperature
      this.$refs.ModalCreateTemperature.closeModal()
    },
    handleClearMcc(force = false) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
          this.$Progress.start()
          this.$store
            .dispatch("travels/deleteClearMcc", {uuid: this.travel.uuid, force: Number(force)})
            .then(response => {
              this.getAdditionals()
              this.travel.left_over_uuid = null
              this.travel.categories_mcc = []
              this.categories_mcc = []
              this.travel.automation_status = 0
              this.showLeftOverStatistic = false
              this.leftOver = null
              this.disabledMcc = false
              this.travel.all_isset_manual_dosage = false
              this.travel.isset_manual_dosage = false
              this.IsSetManualDosage = false
              this.$refs.ModalMCC.clearTeste()
              this.mccs = []
              this.travel.approval_observation = null
              this.travel.approval_user_id = null
              this.approval_observation = null
              this.$notify({type: 'success', message: response.message})
              this.$Progress.finish();
              this.start_at = null
              this.time = null
              this.finished_at = null
              loader.hide()
            })
            .catch(error => {
              this.$notify({type: 'danger', message: error.data.message})
              this.$Progress.finish();
              loader.hide()
            });
        }
      }).catch()
    },
    showTravel() {
      let loader = this.$loading.show()
      this.$store
        .dispatch("travels/show", {uuid: this.$route.params.travel_uuid, code: '0'})
        .then(response => {
          this.saved_travel = {...response.data}
          this.once = true
          loader.hide()
          this.categories_mcc = response.data.categories_mcc
          for (var mcc of this.categories_mcc) {
            let previous = null
            for (var group of mcc.groups) {
              if (group.manual_dosage > 0 || group.automation > 0) {
                this.travel.isset_manual_dosage = true
              }
              if (previous) {
                group.previous_manual_dosage = previous.manual_dosage
              }
              if (!previous || previous.manual_dosage !== null || previous.automation !== null) {
                group.previous_has_automation_manual = true
                if (previous) {
                  const automation = previous.automation || 0
                  const manual = previous.manual_dosage || 0
                  group.previous_value = parseFloat(automation) + parseFloat(manual)
                } else {
                  group.previous_value = 0
                }
              }
              previous = group
            }
          }
        })
        .catch(error => {
          loader.hide()
          this.$Progress.finish();
        });
    },
    handleShowModalMcc() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$Progress.start()
      let params = {
        uuid: this.travel.schedule.contract_proposal_formulation.uuid,
        charge_point_uuid: this.travel.charge_point_uuid,
        company_plant_uuid: this.travel.send_plant.uuid,
      }
      this.has_another_os = false
      this.another_os.number = null
      this.another_os.schedule_travel_uuid = null
      this.$store
        .dispatch("travels/getMCC", params)
        .then(response => {
          this.$refs.ModalMCC.clearTeste()
          let params = {
            mcc: this.mcc,
            plant: this.travel.send_plant.name,
            charge_point: this.chargePoints.find(item => item.uuid == this.travel.charge_point_uuid).name,
            travel_uuid: this.travel.uuid,
            selected: this.categories_mcc
          }
          if (response.error) {
            this.$notify({type: 'danger', message: response.message})
          } else {
            this.$notify({type: 'success', message: response.message})
            this.$refs.ModalMCC.openModal(params)
          }
          this.$Progress.finish();
        })
        .catch(error => {
          this.$notify({type: 'danger', message: error.data.message})
          this.$Progress.finish();
        });
    },
    handleSetAdjustments() {
      if (!this.travel.schedule.contract_proposal_formulation.mix_mixe_id && !this.travel.has_adjustments) {
        return this.$notify({type: 'danger', message: 'Traço não encontrado!'})
      }
      this.$Progress.start();
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let teste = {
        has_dosage: this.travel.isset_manual_dosage || false,
        mccs: this.mccs,
        schedule_travel_uuid: this.travel.uuid,
        volume_adjustments: this.travel.volume || null,
        concrete_piece_adjustments: this.travel.mix_concreted_piece_uuid,
        temperature_adjustments: this.travel.temperature || null,
        concrete_cycle_adjustments: this.travel.concrete_cycle,
        charge_point_adjustments: this.travel.charge_point_uuid || null,
        company_plant_uuid: this.travel.send_plant.uuid,
        mix_design_mixe_id: this.travel.schedule.contract_proposal_formulation.mix_mixe_id
      }
      this.$store
        .dispatch("travels/getAdjustments", teste)
        .then(response => {
          this.$Progress.finish();
          this.$notify({type: 'success', message: response.message});
          this.$refs.ModalAdjustment.openModal()
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    handleCreateTemperature(plantId = null) {
      this.$refs.ModalCreateTemperature.handleCreateModal(plantId)
    },
    handleCreateMoisture() {
      let params = {
        company_plant_uuid: this.travel.send_plant.uuid,
        charge_point_uuid: this.travel.charge_point_uuid ? this.travel.charge_point_uuid : null
      }
      this.$refs.ModalCreateMoisture.handleCreateModal(params)
    },
    handleCreateWater() {
      const chargePoint = this.chargePoints.find(item => item.uuid == this.travel.charge_point_uuid)
      let params = {
        company_plant_id: this.travel.send_plant.id,
        charge_point_id: chargePoint ? chargePoint.id : null
      }
      this.$refs.ModalCreateWater.handleCreateModal(params)
    },
    async updateNfe(value) {
      const copy = {...this.travel}
      await this.$store.dispatch('travels/update', {
        uuid: this.travel.uuid,
        has_xml: this.travel.has_xml,
        nfe: value,
        only_change_nfe: true
      }).then(response => {
        this.travel.charge_point_uuid = copy.charge_point_uuid
        this.travel.company_plant_issuer_id = copy.company_plant_issuer_id
        this.travel.send_plant.uuid = copy.send_plant.uuid
        this.travel.driver_uuid = copy.driver_uuid
        this.travel.equipment_uuid = copy.equipment_uuid
        this.travel.volume = copy.volume
        this.travel.seal = copy.seal
        if (this.default_plant_issuer && !this.travel.company_plant_issuer_id) {
          if (!this.travel.schedule.contract_proposal.issuer_fixed ) {
            this.travel.company_plant_issuer_id = this.default_plant_issuer.id
          }
        }
        this.$Progress.finish();
      }).catch(error => {
        this.$Progress.finish();
      })
    },
    async getChargePoint(clear_charge_point = false) {
      this.selected_plant = this.plants.find((item) => item.uuid === this.travel.send_plant.uuid)
      this.nfe_blocked = !this.selected_plant.general_setting.nfe
      await this.getCompanyPlantIssuers()
      if (!this.nfe_blocked && this.travel.block_change_nfe && !this.issuer.nfe) {
        this.issuer.nfe = true
        await this.updateNfe(this.issuer.nfe)
      }
      if (this.nfe_blocked) {
        this.$nextTick(function () {
          const copy = {...this.travel}
          this.travel.nfe = false
          this.issuer.nfe = false
          this.$store.dispatch('travels/update', {
            uuid: this.travel.uuid,
            has_xml: this.travel.has_xml,
            nfe: false,
            only_change_nfe: true
          }).then(response => {
            this.travel.charge_point_uuid = copy.charge_point_uuid
            this.travel.company_plant_issuer_id = copy.company_plant_issuer_id
            this.travel.send_plant.uuid = copy.send_plant.uuid
            this.travel.driver_uuid = copy.driver_uuid
            this.travel.equipment_uuid = copy.equipment_uuid
            this.travel.volume = copy.volume
            this.travel.seal = copy.seal
            if (this.default_plant_issuer && !this.travel.company_plant_issuer_id) {
              if (!this.travel.schedule.contract_proposal.issuer_fixed ) {
                this.travel.company_plant_issuer_id = this.default_plant_issuer.id
              }
            }
            this.$Progress.finish();
            this.loadingSave = false
            this.isntSaved = false
          }).catch(error => {
            this.$Progress.finish();
            this.loadingSave = false
          })
        })
      }
      if (!this.travel.send_plant.uuid) {
        return
      }
      if (clear_charge_point) {
        this.travel.charge_point_uuid = null
      }
      let filter = {
        company_plant_uuid: this.travel.send_plant.uuid,
        category_id: 1,
        status: 1,
        is_concrete: 1,
      }
      this.$store.dispatch('user/fetchDrivers', {filter: filter}).then(() => {
        this.loadingDrivers = false
      })
      if (this.travel.schedule.contract_proposal_formulation.concrete_removal) {
        filter.concrete_removal = 1
      }
      this.$store
        .dispatch("equipment/getByCompanyPlantAndService", {filter: filter})
        .then(() => {
          this.verifyEquipment()
        })
      this.$store
        .dispatch("plantChargePoint/getActiveByPlant", this.travel.send_plant.id)
        .then(response => {
          this.setChargePoint()
          this.chargePointItems = response.data;
          this.correctionInput = true;
          this.charge_point_select = true;
          if (response.data.length === 1) {
            if (!this.travel.charge_point_uuid) {
              this.travel.charge_point_uuid = response.data[0].uuid
              this.testChargePoint = false
              this.testChargePoint = true
            }
          }
          if (this.travel.charge_point_uuid) {
            let indexes = {
              has_dosage: this.travel.isset_manual_dosage || false,
              mccs: this.mccs,
              schedule_travel_uuid: this.travel.uuid,
              volume_adjustments: this.travel.volume || null,
              concrete_piece_adjustments: this.travel.mix_concreted_piece_uuid,
              temperature_adjustments: this.travel.temperature || null,
              concrete_cycle_adjustments: this.travel.concrete_cycle,
              charge_point_adjustments: this.travel.charge_point_uuid || null,
              company_plant_uuid: this.travel.send_plant.uuid,
              mix_design_mixe_id: this.travel.schedule.contract_proposal_formulation.mix_mixe_id
            }
            this.getBinderConsumption(indexes)
          }
        });
    },
    approveMoisture(postpone = false) {
      this.$Progress.start();
      let loader = this.$loading.show()
      this.params_to_approval_mcc.valid_mcc = true
      this.params_to_approval_mcc.postpone = postpone
      this.params_to_approval_mcc.valid_mccs = this.valid_mcc
      this.$store
        .dispatch("travels/approveCharging",
          {
            params: this.params_to_approval_mcc,
            schedule_travel_uuid: this.travel.uuid,
          })
        .then(response => {
          loader.hide()
          this.needs_aprovation_moisture = false
          this.valid_mcc = []
          this.showTravel()
          this.getAdditionals()
          this.$notify({type: 'success', message: response.message})
          this.$Progress.finish();
        })
        .catch(error => {
          loader.hide()
          this.$notify({type: 'danger', message: error.data.message})
          this.$Progress.finish();
        });
    },
    approveCharging() {
      this.$Progress.start();
      let loader = this.$loading.show()
      this.params_to_approval_mcc.approval = {
        observation: this.approval_observation
      }
      this.$store
        .dispatch("travels/approveCharging",
          {params: this.params_to_approval_mcc, schedule_travel_uuid: this.travel.uuid})
        .then(response => {
          loader.hide()
          this.needs_aprovation_mcc = false
          if (response.data.hasOwnProperty('valid_mcc')) {
            this.valid_mcc = response.data.valid_mcc
            this.needs_aprovation_moisture = true
            this.$notify({type: 'warning', message: 'Realize a validação de umidade antes de continuar'})
          } else {
            this.showTravel()
            this.getAdditionals(true, true)
          }
          this.$Progress.finish();
        })
        .catch(error => {
          loader.hide()
          this.$notify({type: 'danger', message: error.data.message})
          this.$Progress.finish();
        });
    },
    setMcc(fields) {
      this.needs_aprovation_mcc = false
      this.mccs = fields.mccs
      let params = {
        mccs: fields.params,
        adjustments: {
          mccs: this.mccs,
          volume_adjustments: this.travel.volume ?? null,
          concrete_piece_adjustments: this.travel.mix_concreted_piece_uuid,
          temperature_adjustments: this.travel.temperature ?? null,
          concrete_cycle_adjustments: this.travel.concrete_cycle,
          charge_point_adjustments: this.travel.charge_point_uuid ?? null,
          company_plant_uuid: this.travel.send_plant.uuid,
          mix_design_mixe_id: this.travel.schedule.contract_proposal_formulation.mix_mixe_id
        }
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store
        .dispatch("travels/postSetMCC", {params: params, schedule_travel_uuid: this.travel.uuid})
        .then(response => {
          const {data} = response
          // SE HOUVER NECESSIDADE DE APROVAÇÃO
          if (data.hasOwnProperty('parameters')) {
            this.needs_aprovation_mcc = true
            this.adjustment_groups = data
            this.params_to_approval_mcc = params
            this.$refs.ModalMCC.modal = false
            loader.hide()
            this.$notify({type: 'success', message: response.message})
            this.$Progress.finish();
            return
          }
          if (data.hasOwnProperty('valid_mcc')) {
            this.needs_aprovation_moisture = true
            this.valid_mcc = data.valid_mcc
            this.$refs.ModalMCC.modal = false
            this.params_to_approval_mcc = params
            loader.hide()
            this.$notify({type: 'warning', message: 'Realize a validação de umidade antes de continuar'})
            this.$Progress.finish();
            return
          }
          if (!this.start_at) {
            this.setTime()
          }
          this.showTravel()
          this.getAdditionals(true, true)
          this.$refs.ModalMCC.modal = false
          loader.hide()
          this.$Progress.finish();
        })
        .catch(error => {
          if (error.data.data && error.data.data.hasOwnProperty('number')) {
            this.has_another_os = true
            this.another_os.number = error.data.data.number
            this.another_os.schedule_travel_uuid = error.data.data.uuid
            this.$refs.ModalMCC.close()
          }
          loader.hide()
          this.$notify({type: 'danger', message: error.data.message})
          this.$Progress.finish();
        });
    },
    setAutomation() {
      const self = this
      let loader = this.$loading.show()
      const automations = []
      for (let item of this.categories_mcc) {
        for (let group of item.groups) {
          if (group.manual_dosage && Number(group.manual_dosage) > 0) {
            loader.hide()
            return this.$notify({
              type: 'danger',
              message: 'O carregamento manual já foi iniciado para este carregamento.'
            });
          }
          const Group = {
            uuid: group.uuid,
            theorical_individual: this.decimals(group.category_id, group.charging.wet_weight),
            wet_weight: group.charging.wet_weight.toString(),
            is_first: group.is_first,
            theorical_without_reuse: group.charging.theorical_without_reuse,
            water_volume_ag_am: group.volume_ag_am,
          }
          automations.push(Group)
        }
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$Progress.start()
      this.$store
        .dispatch("travels/postStoreAutomations", {
          mccs: automations,
          schedule_travel_uuid: this.travel.uuid
        })
        .then(response => {
          this.$refs.ModalMCC.modal = false
          self.after_automation_time += 1
          this.$store
            .dispatch("travels/show", {uuid: this.$route.params.travel_uuid, code: '0'})
            .then(response => {
              this.once = true
              loader.hide()
              this.categories_mcc = response.data.categories_mcc
            })
            .catch(error => {
              loader.hide()
              this.$Progress.finish();
            });
          this.$notify({type: 'success', message: response.message})
          this.$Progress.finish();
        })
        .catch(error => {
          self.after_automation_time = 0
          loader.hide()
          this.$notify({type: 'danger', message: error.data.message})
          this.$Progress.finish();
        });
    },
    setManual() {
      let loader = this.$loading.show()
      const automations = []
      for (let item of this.categories_mcc) {
        for (let group of item.groups) {
          // if (group.manual_dosage) {
          //   loader.hide()
          //   return this.$notify({
          //     type: 'danger',
          //   });
          // }
          const Group = {
            uuid: group.uuid,
            theorical_individual: this.decimals(group.category_id, group.charging.wet_weight),
            wet_weight: group.charging.wet_weight.toString(),
            is_first: group.is_first,
            category_id: group.category_id,
            is_individual: group.is_individual,
            accumulated_wet_weight: group.charging.accumulated_wet_weight,
            theorical_without_reuse: group.charging.theorical_without_reuse,
            water_volume_ag_am: group.volume_ag_am,
          }
          automations.push(Group)
        }
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$Progress.start()
      this.$store
        .dispatch("travels/postStoreAutomations", {
          mccs: automations,
          manual: true,
          schedule_travel_uuid: this.travel.uuid
        })
        .then(response => {
          this.$refs.ModalMCC.modal = false
          this.$store
            .dispatch("travels/show", {uuid: this.$route.params.travel_uuid, code: '0'})
            .then(response => {
              this.once = true
              loader.hide()
              this.categories_mcc = response.data.categories_mcc
              for (let item of this.categories_mcc) {
                for (let group of item.groups) {
                  group.previous_has_automation_manual = true
                  const Group = {
                    uuid: group.uuid,
                    theorical_individual: this.decimals(group.category_id, group.charging.wet_weight),
                    wet_weight: group.charging.wet_weight.toString(),
                    is_first: group.is_first,
                    category_id: group.category_id,
                    is_individual: group.is_individual,
                    accumulated_wet_weight: group.charging.accumulated_wet_weight,
                    theorical_without_reuse: group.charging.theorical_without_reuse,
                    water_volume_ag_am: group.volume_ag_am,
                  }
                  automations.push(Group)
                }
              }
            })
            .catch(error => {
              loader.hide()
              this.$Progress.finish();
            });
          this.$notify({type: 'success', message: response.message})
          this.$Progress.finish();
        })
        .catch(error => {
          loader.hide()
          this.$notify({type: 'danger', message: error.data.message})
          this.$Progress.finish();
        });
    },
    setLeftOver(leftOver) {
      this.showLeftOverStatistic = true
      this.leftOver = leftOver
      var time = 0
      const self = this
      for (var key in this.leftOverStatistic) {
        time = time + 100
        self.setTimeout(key, time)
      }
      this.$forceUpdate()
    },
    setTimeout(key, time) {
      const self = this
      setTimeout(function () {
        self.leftOverStatistic[key].loading = true
        if (key !== 'mixe') {
          self.leftOverStatistic[key].show = true
        }
        self.geValidtLeftOver(key)
      }, time);
    },
    geValidtLeftOver(key) {
      let params = {
        key: key,
        schedule_travel_left_over_uuid: this.leftOver.uuid,
        schedule_travel_uuid: this.travel.uuid,
      }
      if (key === 'mixe') {
        params.mixe_id = this.travel.schedule.contract_proposal_formulation.mix_mixe_id
      }
      this.$store.dispatch('travelLeftOver/getValidLeftOver', params)
        .then(response => {
          this.leftOverStatistic[key].loading = false
          this.leftOverStatistic[key].subtitle = response.data.subtitle
          this.leftOverStatistic[key].is_checked = response.data.value
          if (key === 'mixe') {
            this.leftOverStatistic[key].is_checked ?
              this.leftOverStatistic[key].show = false : this.leftOverStatistic[key].show = true
            this.validAllLeftOver()
          }
        })
        .catch()
    },
    getIncompatibleGroups() {
      let params = {
        key: 'incompatible_groups',
        schedule_travel_left_over_uuid: this.leftOver.uuid,
        schedule_travel_uuid: this.travel.uuid
      }
      this.$store.dispatch('travelLeftOver/getValidLeftOver', params)
        .then(response => {
          this.travel.incompatible_groups = response.data
        })
        .catch()
    },
    validAllLeftOver() {
      let valid = true
      for (var key in this.leftOverStatistic) {
        if (this.leftOverStatistic[key]['no_check']) {
          continue;
        }
        if (valid) {
          if (!this.leftOverStatistic[key]['is_checked']) {
            valid = false
          }
        }
      }
      if (!valid) {
        this.needs_authorize = true
      } else {
        this.autorizeReuse()
      }
      return this.valid_left_over = valid
    },
    save() {
      let params = {
        has_mcc: Number(this.categories_mcc.length > 0),
        uuid: this.travel.uuid,
        company_plant_issuer_id: this.travel.company_plant_issuer_id,
        send_plant_uuid: this.travel.send_plant.uuid,
        driver_uuid: this.travel.driver_uuid,
        equipment_uuid: this.travel.equipment_uuid,
        volume: this.travel.volume.replace(',', '.'),
        seal: this.travel.seal,
        charge_point_uuid: this.travel.charge_point_uuid,
        hardened_state: this.travel.hardened_state,
        schedule_uuid: this.travel.schedule_uuid,
        arrival_time: formatDate(this.travel.arrival_time),
        construction_exit_time: formatDate(this.travel.construction_exit_time),
        return_plant_uuid: this.travel.return_plant.uuid,
        was_billed: this.travel.was_billed,
        status: this.travel.status,
      }
      let loader = this.$loading.show()
      this.$Progress.start();
      this.loadingSave = true
      if (this.travel.send_plant.uuid != this.old_plant_uuid
        || this.travel.volume != this.old_volume) {
        params.core_changes = 2
      }
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store.dispatch('travels/update', params).then(response => {
        this.$Progress.finish();
        this.loadingSave = false
        this.isntSaved = false
        let indexes = {
          has_dosage: this.travel.isset_manual_dosage || false,
          mccs: this.mccs,
          schedule_travel_uuid: this.travel.uuid,
          volume_adjustments: this.travel.volume || null,
          concrete_piece_adjustments: this.travel.mix_concreted_piece_uuid,
          temperature_adjustments: this.travel.temperature || null,
          concrete_cycle_adjustments: this.travel.concrete_cycle,
          charge_point_adjustments: this.travel.charge_point_uuid || null,
          company_plant_uuid: this.travel.send_plant.uuid,
          mix_design_mixe_id: this.travel.schedule.contract_proposal_formulation.mix_mixe_id
        }
        this.saved_travel.seal = {...this.travel.seal}
        this.saved_travel.equipment_uuid = {...this.travel.equipment_uuid}
        this.saved_travel.driver_uuid = {...this.travel.driver_uuid}
        this.getBinderConsumption(indexes)
        loader.hide()
        this.$notify({type: 'success', message: response.message});
        this.old_volume = this.travel.volume;
        this.old_plant_uuid = this.travel.send_plant.uuid;
        this.getLeftOver()
      }).catch(error => {
        this.$Progress.finish();
        this.showTravel()
        loader.hide()
        this.loadingSave = false
        this.$notify({type: 'danger', message: error.data.message});
      })
    },
    allowEditing(status) {
      let allow = false;
      switch (status) {
        case 1:
        case 5:
        case 6:
        case 7:
          allow = true;
          break;
      }
      return allow
    },
    getAdditionals(msg = true, valid_additionals = false) {
      let loader = this.$loading.show()
      this.loading = true;
      this.$Progress.start();
      let params = {
        filter: {
          schedule_travel_uuid: this.$route.params.travel_uuid
        }
      }
      this.$store
        .dispatch(
          "scheduleAdditional/fetchItems",
          params)
        .then(response => {
          loader.hide()
          this.loading = false;
          this.$Progress.finish();
          if (this.schedule_additionals.length > 0 && valid_additionals) {
            this.$notify({type: 'warning', message: 'Há adicionais vinculados a viagem. Favor verifique-os'})
          } else {
            if (msg) {
              this.$notify({
                type: response.error_type,
                message: response.message
              });
            }
          }
        }).catch(error => {
        loader.hide()
        this.loading = false;
        this.$Progress.finish();
        this.$notify({
          type: error.error_type,
          message: error.message
        });
      });
    },
    removeAdditional(uuid) {
      this.loading = true;
      this.$Progress.start();
      this.$store
        .dispatch(
          "scheduleAdditional/destroy",
          uuid)
        .then(response => {
          this.loading = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        }).catch(error => {
        this.loading = false;
        this.$Progress.finish();
        this.$notify({
          type: error.error_type,
          message: error.message
        });
      });
    },
    handleHardenedStateTest(travel) {
      if (travel.status == 1 || !travel.hardened_state) {
        return
      }
      this.$refs.hardenedStateTest.openModal(travel.uuid, this.travel.schedule.contract_proposal_formulation, travel.send_plant.id);
    },
    setHardenedState(travel) {
      let params = {
        schedule_travel_uuid: travel.uuid,
        hardened_state: travel.hardened_state,
      }
      this.loadingHardenedState = true
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      this.$store.dispatch('travelConfiguration/storeDefaultHardenedStates', params)
        .then(response => {
            this.loadingHardenedState = false
            this.$notify({type: 'success', message: response.message});
          }
        ).catch(() => this.loadingHardenedState = false
      )
    },
    getBinderConsumption(indexes) {
      this.$store.dispatch('travels/getBinderConsumption', {
        indexes: indexes,
        schedule_travel_uuid: this.$route.params.travel_uuid
      })
        .then(({data}) => {
          this.binderIsLessConsumption = data.is_less
          if (this.binderIsLessConsumption && data.has_mcc) {
            this.is_checked_contact = true
          }
          this.loadingBinderConsumption = false
        })
    },
    getLeftOver() {
      this.loadingLeftOver = true
      let params = {
        travel_uuid: this.$route.params.travel_uuid,
        schedule_uuid: this.travel.schedule_uuid
      }
      this.$store.dispatch('travelLeftOver/get', {filter: params})
        .then(response => {
          this.loadingLeftOver = false
        })
        .catch()
    },
    getPenultimateTravelByEquipment() {
      this.$store.dispatch('travels/getPenultimateTravelByEquipment', this.travel.equipment_uuid)
        .then(response => {
          this.penultimate_travel_without_attachment = response.data
        })
        .catch()
    }
  },
  async mounted() {
    await this.$store.dispatch('technicalPermission/getByUserId', this.$user.id);
    await this.$store.dispatch('operationalPermission/getByUserId', this.$user.id);
    this.loadingGeneralSettings = true
    this.$store
      .dispatch("generalSetting/show").then(response => {
      this.loadingGeneralSettings = false
    })
    this.isntSaved = true
    this.issuer.nfe = true
    this.showLeftOverStatistic = false
    this.$watch('travel.left_over_uuid', async function (val) {
      if (this.travel.old_left_over_uuid === val) {
        return
      }
      this.leftOverStatistic = {
        content: {
          title: 'Teor de reuso',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
        ambient_temperature: {
          title: 'Temperatura Ambiente',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
        concrete_temperature: {
          title: 'Temperatura do Concreto',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
        variation_temperature: {
          title: 'Variação da Temperatura',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
        travel_cycle: {
          title: 'Ciclo da Viagem',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
        concreted_piece: {
          title: 'Peça',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
        features: {
          title: 'Responsabilidades',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
        only_aggregate: {
          title: 'Considerar somente agregados',
          subtitle: '',
          no_check: true,
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
        slump: {
          title: 'Slump',
          subtitle: '',
          value: null,
          no_check: true,
          is_checked: false,
          loading: false,
          show: false,
        },
        mixe: {
          title: 'Este traço não permite reuso',
          subtitle: '',
          value: null,
          is_checked: false,
          loading: false,
          show: false,
        },
      }
      if (val) {
        const value = this.travelLeftOvers.find(item => item.uuid == val)
        this.valid_left_over = null
        await this.setLeftOver(value)
        await this.getIncompatibleGroups()
      } else {
        this.leftOver = null
        if (this.travel.old_left_over_uuid) {
          await this.autorizeReuse()
        }
      }
    })
    this.$store
      .dispatch("travels/show", {uuid: this.$route.params.travel_uuid, code: '0'})
      .then(response => {
        this.saved_travel = {...response.data}
        this.is_checked_contact = false
        this.binderIsLessConsumption = false
        this.loadingBinderConsumption = true
        let teste = {
          has_dosage: response.data.isset_manual_dosage || false,
          mccs: this.mccs,
          schedule_travel_uuid: response.data.uuid,
          volume_adjustments: response.data.volume || null,
          concrete_piece_adjustments: response.data.mix_concreted_piece_uuid,
          temperature_adjustments: response.data.temperature || null,
          concrete_cycle_adjustments: response.data.concrete_cycle,
          charge_point_adjustments: response.data.charge_point_uuid || null,
          company_plant_uuid: response.data.send_plant.uuid,
          mix_design_mixe_id: response.data.schedule.contract_proposal_formulation.mix_mixe_id
        }
        this.setEquipmentService()
        if (this.travel.status === 7) {
          this.$notify({
            type: 'warning',
            message: 'O carregamento não pode ser realizado nessa viagem. Fora do horário permitido para carregamento'
          })
          return this.$router.push({
            name: 'operational.schedule.weighing.index',
            params: {
              schedule_uuid: this.travel.schedule_uuid
            }
          })
        }
        this.issuer.nfe = Boolean(this.travel.nfe)
        if (response.data.automation_status == 1) {
          this.after_automation_time = 1
        }
        if (this.travel.schedule_status.status === 16 && !this.travel.finished_at) {
          this.$notify({
            type: 'warning',
            message: 'O carregamento não pode ser realizado nessa viagem. Há pendências para a programação'
          })
          this.$Progress.finish();
          return this.$router.push({
            name: 'operational.schedule.weighing.index',
            params: {
              schedule_uuid: this.travel.schedule_uuid
            }
          })
        }
        if (this.travel.has_pending_schedule_service_order === true) {
          this.$notify({
            type: 'warning',
            message: 'O carregamento não pode ser iniciado até que todos os serviços vinculados a essa programação tenham sido emitidos.'
          })
          this.$Progress.finish();
          return this.$router.push({
            name: 'operational.schedule.weighing.index',
            params: {
              schedule_uuid: this.travel.schedule_uuid
            }
          })
        }
        this.$store.dispatch('plant/fetchItems').then(() => {
          this.loadingCompanyPlant = false
          this.$Progress.start();
          this.getChargePoint()
        })
        let params = {
          groups: JSON.parse(response.data.groups),
          adjusted_groups: JSON.parse(response.data.adjusted_groups)
        }
        this.start_at = response.data.travel_timer ? response.data.travel_timer.start_at : null
        let item = response.data
        if (item.charge_point_uuid
          && item.equipment_uuid
          && item.driver_uuid
          && item.send_plant.uuid
          && item.volume) {
          this.isntSaved = false
        } else {
          this.isntSaved = true
        }
        this.categories_mcc = response.data.categories_mcc
        for (var mcc of this.categories_mcc) {
          let previous = null
          for (var group of mcc.groups) {
            if (previous) {
              group.previous_manual_dosage = previous.manual_dosage
            }
            if (!previous || previous.manual_dosage !== null || previous.automation !== null) {
              group.previous_has_automation_manual = true
              if (previous) {
                const automation = previous.automation || 0
                const manual = previous.manual_dosage || 0
                group.previous_value = parseFloat(automation) + parseFloat(manual)
              } else {
                group.previous_value = 0
              }
            }
            previous = group
            this.mccs.push(group.adjustment_uuid)
          }
        }
        this.old_volume = this.travel.volume;
        this.old_plant_uuid = this.travel.send_plant.uuid;
        this.showLeftOverStatistic = true
        this.leftOver = this.travel.left_over
        this.leftOverStatistic = this.travel.left_over_statistic
        this.loadingTravel = false
        this.$Progress.finish();
        this.getLeftOver()
        this.getAdditionals()
        this.$nextTick(() => {
          this.$refs.ModalAdjustment.setGroups(params)
        });
        if (!this.categories_mcc.length) {
          this.$nextTick(() => {
            const plantId = this.travel.send_plant ? this.travel.send_plant.id : null
            this.$refs.ModalCreateTemperature.handleCreateModal(plantId, false)
          });
        }
        this.finished_at = response.data && response.data.travel_timer && response.data.travel_timer.finished_at ?
          response.data.travel_timer.finished_at : null
        if (this.finished_at) {
          var now = moment(this.finished_at)
          var start_at = moment(this.start_at)
          this.time = Math.abs(now - start_at)
        }
        if (
          !this.travel.schedule_service_id &&
          response.data.schedule.services &&
          response.data.schedule.services.length === 1 &&
          ['released', 'in_progress', 'loading'].includes(this.schedule_travel_status[this.travel.status])
        ) {
          this.travel.schedule_service_id = response.data.schedule.services[0].id;
          this.updateServiceOrder();
        }
        this.getPenultimateTravelByEquipment()
      })
      .catch(error => {
        this.loadingTravel = false
        this.$notify({type: "warning", message: 'Viagem não encontrada!'});
        return this.$router.push('/operational/schedule')
        this.$Progress.finish();
      });
    const ref = this
    setInterval(function () {
      ref.showTime()
    }, 1);
  }
}
</script>
<style scoped>
@media screen and (min-width: 768px) {
  .incompatible_groups {
    max-width: 70%;
  }
}

@media only screen and (min-width: 600px) {
  .border-charging {
    border-right: 1px solid grey;
    margin-bottom: 6px;
    color: grey !important;
    height: 125px;
  }

  .border-material-charging {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: 1px solid #ece4e4 !important;
  }

  .border-material-values-charging {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.zoom-img:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@media only screen and (max-width: 600px) {

  .float-moisture {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 170px;
    right: 160px;
    background-color: #00ABE7;
    color: #FFF;
    border-radius: 10%;
    text-align: center;
  }

  .float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 170px;
    right: 100px;
    background-color: #1a70b7;
    color: #FFF;
    border-radius: 10%;
    text-align: center;
  }

  .float-temperature {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 170px;
    right: 40px;
    background-color: crimson;
    color: #FFF;
    border-radius: 10%;
    text-align: center;
  }

  .float:hover,
  .float-moisture:hover,
  .float-temperature:hover {
    zoom: 101%;
    color: white;
  }
}

@media only screen and (min-width: 600px) {
  .float-moisture {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 170px;
    right: 180px;
    background-color: #00ABE7;
    color: #FFF;
    border-radius: 10%;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
  }

  .float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 170px;
    right: 120px;
    background-color: #1a70b7;
    color: #FFF;
    border-radius: 10%;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
  }

  .float-danger {
    position: fixed;
    bottom: 135px;
    right: 40vw;
    border-radius: 10%;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
  }

  .float-temperature {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 170px;
    right: 60px;
    background-color: crimson;
    color: #FFF;
    border-radius: 10%;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
  }

  .float:hover,
  .float-moisture:hover,
  .float-temperature:hover {
    zoom: 101%;
    color: white;
  }
}
</style>
