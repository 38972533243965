import * as types from './mutation_types'
import { createAxios } from "@/plugins/axios";
import {destroy, update, cursorPaginate,setUrlWithNextPage} from '@/store/baseStore'
const endPoint = '/mix/mixdesigns+microservice/'
var nextPage = null
export default {
  namespaced: true,
  state: {
    items: [],
    groups: [],
    groupHistory:[],
    item: [],
  },
  getters:{
    show: state => state.item,
    showGroups: state => state.groups,
    groupHistory: state => state.groupHistory,
    fetch: state => state.items,
    activeItems (state) {
      return state.items.filter(item => item.status === true)
    },
    fetchNextPage: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SET_NEXT_PAGE](state, payload) {
      nextPage = payload.next_page
      nextPage = payload.filters.length === 1 ? nextPage+'&filters[0]=' + payload.filters[0] : nextPage
    },
    [types.SET_NEXT_PAGE_DATA](state, payload) {
      state.items = state.items.concat(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.SET_GROUP_BY_UUID] (state, payload){
      state.groups = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
    [types.SET_GROUPS] (state, payload){
      state.groups = payload
    },
    [types.SET_GROUP_HISTORY] (state, payload){
      state.groupHistory = payload
    },
  },
  actions: {
    fetchItems({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint, params), {params : params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchFilterItems({commit}, payload) {
      return createAxios().get(endPoint, {params : payload})
        .then(({data}) =>{
          commit(types.SET, data.data)
          commit(types.SET_NEXT_PAGE,  {next_page :data.next_page, filters: []})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchNextPage({ commit }, payload) {
      return createAxios().get(nextPage, {params : payload})
        .then(({ data }) => {
          commit(types.SET_NEXT_PAGE_DATA, data.data)
          commit(types.SET_NEXT_PAGE, {next_page :data.next_page, filters: payload.filters})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchGroupByMixDesignUuid({commit, dispatch}, uuid) {
      return createAxios().get(`${endPoint}groups/${uuid}`)
        .then(({data}) =>{
          commit(types.SET_GROUP_BY_UUID, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return createAxios().get(endPoint+`${uuid}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return createAxios().put(endPoint+payload.uuid, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    replicate({commit}, params) {
      return createAxios().post(`${endPoint}${params.uuid}/replicate`, params)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    adjustment({commit}, params) {
      return createAxios().post(`${endPoint}${params.uuid}/adjustment`, params)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    groups({commit}, params) {
      return createAxios().post(`${endPoint}groups`, params)
        .then(({data}) =>{
          commit(types.SET_GROUPS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getGroupHistory({commit}, payload) {
      return createAxios().get(`${endPoint}groups/history/${payload.mix_design_id}`,  {params : payload})
        .then(({data}) =>{
          commit(types.SET_GROUP_HISTORY, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return createAxios().delete(endPoint+uuid)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  },
}
