<template>
  <div v-bind="$attrs">
    <modal
      size="md-2"
      :show.sync="modal.show"
      data-class="programming-weighing-info"
    >
      <template slot="header">
        <h5 class="modal-title">
          <img
            src="/img/icons/icons8/ios/icon-plus-success.png"
            width="25"
            class="mt-n2 mr-2"
          />
          <span>{{ modal.title }}</span>
        </h5>
      </template>
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <form
          class="needs-validation"
          ref="myForm"
          @submit.prevent="handleSubmit(save)"
          autocomplete="off"
        >
          <div class="pl-3">
            <div class="row">
              <div class="col-md-12 mb-3 info-section">
                <img
                  src="/img/icons/icons8/ios/info-squared.png"
                  width="20"
                  height="20"
                  style="margin-right: 1.5%"
                />
                <strong class="mb-3">Identificação</strong>
                <div class="pzl-container-divider">
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Nome do checklist
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <input
                          v-model="checklist.name"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Categoria de equipamento
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          style="width: 100%"
                          v-model.lazy="checklist.equipment_category_uuid"
                          :items="equipmentCategoriesHydrate"
                          :disableBoxShadow="true"
                          placeholder="Selecione"
                          class="select-xl col-md-12 px-0 new-default-black-font"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="row mt-3">
                <div class="col-md-12 mb-3 info-section">
                  <img
                    src="/img/icons/icons8/ios/setting-double-check-primary.png"
                    width="20"
                    height="20"
                    style="margin-right: 1.5%"
                  />
                  <strong class="mb-3">Configuração</strong>
                  <div class="pzl-container-divider">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                      class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                    >
                      Informação de KM
                    </label>
                    <div class="col-md-8 pr-4 d-flex justify-content-end">
                      <label
                        class="app-badge"
                        :class="{
                          'green-backgroud-light': checklist.km_information,
                          'red-backgroud-light': !checklist.km_information,
                        }"
                        style="width: 130px"
                      >
                        <input
                          type="checkbox"
                          v-model="checklist.km_information"
                        />
                        <span
                          style="gap: 4px"
                          class="d-flex justify-content-center align-items-center"
                          :class="{
                            'green-text-badge': checklist.km_information,
                            'red-text-badge': !checklist.km_information,
                          }"
                        >
                          <img
                            :src="
                              checklist.km_information
                                ? '/img/icons/ok--v1.png'
                                : '/img/icons/icons8/ios/cancel_danger.png'
                            "
                            alt="..."
                            width="14px"
                          />
                          {{ checklist.km_information ? "Ativo" : "Inativo" }}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                      class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                    >
                      Informação de horímetro
                    </label>
                    <div class="col-md-8 pr-4 d-flex justify-content-end">
                      <label
                        class="app-badge"
                        :class="{
                          'green-backgroud-light':
                            checklist.hour_meter_information,
                          'red-backgroud-light':
                            !checklist.hour_meter_information,
                        }"
                        style="width: 130px"
                      >
                        <input
                          type="checkbox"
                          v-model="checklist.hour_meter_information"
                        />
                        <span
                          style="gap: 4px"
                          class="d-flex justify-content-center align-items-center"
                          :class="{
                            'green-text-badge':
                              checklist.hour_meter_information,
                            'red-text-badge': !checklist.hour_meter_information,
                          }"
                        >
                          <img
                            :src="
                              checklist.hour_meter_information
                                ? '/img/icons/ok--v1.png'
                                : '/img/icons/icons8/ios/cancel_danger.png'
                            "
                            alt="..."
                            width="14px"
                          />
                          {{
                            checklist.hour_meter_information
                              ? "Ativo"
                              : "Inativo"
                          }}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                      class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                    >
                      Travamento de operação
                    </label>
                    <div class="col-md-8 pr-4 d-flex justify-content-end mt-n2">
                      <label
                        class="app-badge"
                        :class="{
                          'green-backgroud-light': checklist.driver_information,
                          'red-backgroud-light': !checklist.driver_information,
                        }"
                        style="width: 130px"
                      >
                        <input
                          type="checkbox"
                          v-model="checklist.driver_information"
                        />
                        <span
                          style="gap: 4px"
                          class="d-flex justify-content-center align-items-center"
                          :class="{
                            'green-text-badge': checklist.driver_information,
                            'red-text-badge': !checklist.driver_information,
                          }"
                        >
                          <img
                            :src="
                              checklist.driver_information
                                ? '/img/icons/ok--v1.png'
                                : '/img/icons/icons8/ios/cancel_danger.png'
                            "
                            alt="..."
                            width="14px"
                          />
                          {{
                            checklist.driver_information ? "Ativo" : "Inativo"
                          }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 mb-3 info-section">
                  <img
                    src="/img/icons/icons8/ios/repeat-black.png"
                    width="20"
                    height="20"
                    style="margin-right: 1.5%"
                  />
                  <strong class="mb-3">Checklist automático</strong>
                  <div class="pzl-container-divider">
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                >
                  Periodicidade
                  <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-8 pr-4">
                  <validation-provider rules="">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        style="width: 100%"
                        v-model.lazy="checklist.frequency"
                        :items="frequencyList"
                        :disableBoxShadow="true"
                        placeholder="Selecione"
                        class="select-xl col-md-12 px-0 new-default-black-font"
                        @input="updateFrequency"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div
                class="form-group row m-0 p-0 mb-1"
                v-if="checklist.frequency === 2"
              >
                <label
                  class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                >
                  Dias
                  <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-8 pr-4">
                  <validation-provider rules="">
                    <base-input input-classes="form-control-sm">
                      <el-select
                        v-model="checklist.chosen_days"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        multiple
                      >
                        <el-option
                          v-for="day in daysMonth"
                          :key="day.key"
                          :label="day.name"
                          :value="day.key"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div v-if="checklist.frequency === 3">
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Quilometragem
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider>
                      <base-input input-classes="form-control-sm">
                        <input
                          v-model="mileageHourMeter.mileage"
                          type="number"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                    Horímetro
                    <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-8 pr-4">
                    <validation-provider>
                      <base-input input-classes="form-control-sm">
                        <input
                          v-model="mileageHourMeter.hourMeter"
                          type="number"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-4 pt-1 pb-0 col-form-label form-control-label pl-0"
                  >
                  </label>
                  <div class="col-md-8 pr-4 d-flex justify-content-end">
                    <div class="alternative-add-btn" @click="alternativeAdd">
                      <img
                        src="/img/icons/icons8/ios/icon-plus-success.png"
                        width="22"
                      /><span>Adicionar</span>
                    </div>
                  </div>
                </div>
                <div style="border-top: 0.5px solid #e8e8e8" class="mt-3 pt-3">
                  <div
                    class="form-group row m-0 p-0 mb-1"
                    v-for="(item, index) in checklist.mileage_hour_meter"
                    :key="index"
                  >
                    <div
                      class="col-md-12 pr-4"
                      style="padding: 0; position: relative"
                    >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        readonly
                        :value="
                          'Após ' +
                          item.mileage +
                          ' KM ou ' +
                          item.hourMeter +
                          ' horas'
                        "
                      />
                      <div
                        class="trash-input"
                        @click="alternativeRemove(index)"
                      >
                        <img width="18" src="/img/icons/delete.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex pr-2" style="gap: 18px">
              <div
                class="d-flex align-items-center button-cancel"
                style="gap: 5px"
                @click="closeModal()"
              >
                <img
                  src="/img/icons/icons8/ios/cancel_danger.png"
                  alt="cancel_danger"
                  width="16px"
                />
                <span class="font-weight-500"> Cancelar </span>
              </div>
              <button
                v-show="!loading"
                class="d-flex align-items-center button-save"
                type="submit"
                style="gap: 5px"
              >
                <img
                  src="/img/icons/icons8/ios/save_success.png"
                  alt="save_success"
                  width="16px"
                />
                <span class="font-weight-500"> Salvar </span>
              </button>
            </div>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import { mapGetters } from "vuex";
export default {
  name: "ModalCreate",
  inheritAttrs: false,
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "Cadastro do checklist",
        show: false,
      },
      loading: false,
      frequencyList: [
        { id: 1, name: "Diariamente" },
        { id: 2, name: "Dias do mês" },
        { id: 3, name: "Quilometragem e horímetro" },
      ],
      mileageHourMeter: {
        mileage: null,
        hourMeter: null,
      },
      checklist: {
        name: null,
        equipment_category_uuid: null,
        km_information: false,
        hour_meter_information: false,
        driver_information: false,
        frequency: 1,
        chosen_days: [],
        mileage_hour_meter: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      $_checklist_items: "itemCheckList/fetch",
      $_equipment_categories: "equipmentCategory/fetch",
    }),
    daysMonth() {
      if (this.checklist.chosen_days) {
        this.checklist.chosen_days.sort((a, b) => a - b);
      }
      return Array.from({ length: 31 }, (_, i) => ({
        key: i + 1,
        name: i + 1,
      }));
    },
    equipmentCategoriesHydrate() {
      return this.$_equipment_categories.map((equipment_category) => {
        return {
          id: equipment_category.uuid,
          name: equipment_category.category_name,
        };
      });
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    openModal() {
      this.checklistReset();
      this.modal.show = true;
    },
    updateFrequency(selected) {
      if (this.checklist.frequency === 1) {
        this.checklist.mileage_hour_meter = [];
        this.checklist.chosen_days = [];
      } else if (this.checklist.frequency === 2) {
        this.mileageHourMeter = {
          mileage: null,
          hourMeter: null,
        };
        this.checklist.mileage_hour_meter = [];
      } else if (this.checklist.frequency === 3) {
        this.checklist.chosen_days = [];
      }
    },
    checklistReset() {
      this.checklist = {
        equipment_category_uuid: null,
        name: null,
        description: null,
        km_information: false,
        hour_meter_information: false,
        driver_information: false,
        frequency: 1,
        chosen_days: [],
        mileage_hour_meter: [],
      };
    },
    closeModal() {
      this.modal.show = false;
    },
    getEquipmentCategories() {
      this.$store.dispatch("equipmentCategory/fetchItems").then(() => {});
    },
    alternativeRemove(index) {
      this.checklist.mileage_hour_meter.splice(index, 1);
    },
    alternativeAdd() {
      if (
        !this.mileageHourMeter ||
        !this.mileageHourMeter.mileage ||
        !this.mileageHourMeter.hourMeter
      ) {
        return;
      }
      this.checklist.mileage_hour_meter.push({
        mileage: this.mileageHourMeter.mileage,
        hourMeter: this.mileageHourMeter.hourMeter,
      });
      this.mileageHourMeter = {
        mileage: null,
        hourMeter: null,
      };
    },
    save() {
      if (
        this.checklist.frequency === 2 &&
        (!this.checklist.chosen_days || !this.checklist.chosen_days.length)
      ) {
        return;
      }
      if (
        this.checklist.frequency === 3 &&
        (!this.checklist.mileage_hour_meter ||
          !this.checklist.mileage_hour_meter.length)
      ) {
        return;
      }
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("checklist/add", this.checklist)
        .then((response) => {
          this.$notify({
            type: "success",
            message: response.message,
          });
          this.$emit("refresh");
          this.closeModal();
          this.$router.push(`/equipment/checklist-item/${response.data.uuid}`);
        })
        .catch((error) => {
          if (error.data && error.data.message) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          loader.hide();
        });
    },
    init() {
      this.getEquipmentCategories();
    },
  },
};
</script>

<style scoped>
/deep/ div[data-class="programming-weighing-info"] div.modal-header.pb-1 {
  background: #fef9f2 !important;
}

/deep/
  div[data-class="programming-weighing-info"]
  div.modal-header.pb-1
  > button
  i {
  border: 1px solid #000 !important;
  color: #000;
  padding: 3%;
  font-size: 14px !important;
}

h5.modal-title > span,
h5.modal-title > small {
  color: #2b2d32;
  font-size: 18px !important;
}

div.info-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 23px;
}

div.info-section > div.pzl-container-divider {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
}

div.info-section > div.pzl-container-divider > div {
  width: 100%;
  border: 0.5px solid #e8e8e8;
}

div.close-btn {
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  border: 0.5px solid #e8e8e8;
  gap: 10px;
}

div.close-btn > i {
  border: 1px solid #000;
  color: #000;
  padding: 3%;
  font-size: 8px !important;
}

div.close-btn > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  font-family: "Fredoka";
}

.read-info {
  border: 0.5px solid #e8e8e8;
  height: 31px;
  padding: 8px 2px 8px 23px;
  border-radius: 4px;
  width: 100%;
}
textarea.read-info {
  height: 51px;
}
.container-icon {
  width: 25px;
  height: 31px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #db4539;
  border-radius: 8px;
  color: #db4539;
  padding: 4px 20px;
  height: 32px;
}
button.button-save {
  cursor: pointer;
  border: 0.5px solid #149e57;
  border-radius: 8px;
  color: #149e57;
  padding: 4px 30px;
  background-color: #fff;
  height: 32px !important;
  min-height: 32px !important;
}
.green-text-badge {
  color: #149e57;
}
.red-text-badge {
  color: #db4539;
}
.green-backgroud-light {
  background: #f2f7f3;
}
.red-backgroud-light {
  background: #fcf3f3;
}
label.app-badge {
  display: flex;
  max-width: 100%;
  height: 24px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  cursor: pointer;
}
label.app-badge > input[type="checkbox"] {
  display: none;
}
label.app-badge > span {
  font-family: "Fredoka";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
div.alternative-add-btn {
  border: 0.5px solid #149e57;
  border-radius: 8px;
  width: 121px;
  gap: 4px;
  padding: 4px 12px 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  cursor: pointer;
}
div.alternative-add-btn > span {
  color: #149e57;
}
div.trash-input {
  position: absolute;
  height: 20px;
  width: 30px;
  cursor: pointer;
  z-index: 1;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 25px;
}
</style>
