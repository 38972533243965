<template>
<div>
    <PageHeader
      title="Tipos de Operação PUZL"
      icon="/img/icons/icons8/ios/ledger_success.png"
      type="success"
      isBackButtonEnabled="true">
        <PageHeaderButton @click="handleCreateModal"
          text="Novo"
          type="success"
          icon="/img/icons/plus-math--v1-white.png" />
    </PageHeader>
    <ListOperationSource />
    <CreateOperationSource ref="modalCreate" />
</div>
</template>

<script>
import ListOperationSource from "./Shared/_List";
import CreateOperationSource from "./Shared/_ModalCreate";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import { PageHeader, PageHeaderButton } from "@/components";

export default {
    name: "Index",
    components: {
        ListOperationSource,
        CreateOperationSource,
        PuzlBreadcrumb,
        PageHeader,
        PageHeaderButton,
    },
    methods: {
        handleCreateModal() {
            this.$refs.modalCreate.handleCreateModal();
        },
    },
    mounted() {
        this.$store.dispatch("productServiceHubType/fetch");
        this.$store.dispatch("documentTemplate/fetch")
    }
}
</script>

<style></style>
