<template>
  <div class="row mx-1"  style="overflow-y: scroll; max-height: 500px">
    <table class="table table-custom-shaded table-sm mb-9 pb-3">
      <thead>
        <tr class="fixed">
          <th class="text-left new-default-black-font">Status</th>
          <th class="text-left new-default-black-font">Central</th>
          <th class="text-left new-default-black-font">Prazo</th>
          <th class="text-left new-default-black-font">Código</th>
          <th class="text-left new-default-black-font">Tipo</th>
          <th class="text-left new-default-black-font">Icones</th>
          <th class="text-left new-default-black-font">Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <!-- Status -->
          <td style="width: 6rem;">
            <div class="align-self-center status-button mr-2 px-3 d-flex justify-content-center align-items-center"
            :style="{ backgroundColor: '#fdf7e7', color: '#f5c865' }">
              Pendente
            </div>
          </td>
          <td style="width: 15%;">BETIM</td>
          <td style="width: 20%;">
            22/04/2024
          </td>
          <td style="width: 15%;">RC-003</td>
          <td>Manutenção</td>
          <td>
            <div class="d-flex justify-content-center pointer">
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div style="font-size: 12px">xxxxxxx</div>
                  <span slot="reference">
                    <img src="/img/icons/paperclip-green.png" width="22">
                  </span>
                </el-popover>
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div style="font-size: 12px">xxxxxxx</div>
                  <span slot="reference">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </span>
                </el-popover>
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div style="font-size: 12px">xxxxxxx</div>
                  <span slot="reference">
                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                  </span>
                </el-popover>
              </div>
              <div class="mr-2">
                <el-popover trigger="click" placement="bottom" class="p-0">
                  <div style="font-size: 12px">xxxxxxx</div>
                  <span slot="reference">
                    <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22"/>
                  </span>
                </el-popover>
              </div>
            </div>
          </td>
          <!-- Menu engrenagem -->
          <td>
            <base-dropdown menuOnRight>
              <div slot="title-container" class="dropdown-toggle rounded m-0">
                <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
              </div>
              <a class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex">
                <img width="23" height="23" src="/img/icons/icons8/ios/print-primary.png" />
                Imprimir documento
              </a>
            </base-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "PurchasesOrderTable",
};
</script>

<style>
.table-custom-shaded thead th {
background-color: #CCCCCC !important;
font-size: 14px !important;
}

.table-custom-shaded tr td {
padding-left: 1rem !important;
}
.table-custom-shaded td, .table-custom-shaded th {
background-color: white;
box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
border-radius: 5px !important;
border: 0 !important;
}
.table-custom-shaded td:last-child, .table-custom-shaded th:last-child, .table-custom-shaded th:first-child {
text-align: center !important;
padding-left: 0.5rem !important;
}
.table-custom-shaded th {
padding-left: 1.2rem !important;
}
.table-custom-shaded td {
font-weight: 700 !important;
padding-top: 0.85rem;
}
.table-custom-shaded td:last-child {
padding-top: 0.8rem !important;
}
.table-custom-shaded td:first-child {
font-weight: 500 !important;
padding-top: 0.5rem !important;

}
.status-button {
border-radius: 7px;
height: 30px;
box-shadow: unset !important;
min-width: 4rem;
margin-right: 1rem;
font-size: 13px;
}
</style>
