<template>
  <div>
    <modal size="sm" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 mb-1">
              <label class="col-form-label pb-1 form-control-label">
                Data do pagamento <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
              <validation-provider rules="required">
                <input-date-time-picker v-model="payment_allotment.paid_at" />
              </validation-provider>
              <label class="col-form-label pb-1 form-control-label mb-0 mt-1">
                Conta bancária <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
              <base-input input-classes="form-control-sm">
                <puzl-select :loading="loadingBankAccount" v-model="payment_allotment.bank_account_id" :items="bank_accounts" />
              </base-input>
              <label class="col-form-label pb-1 form-control-label mb-0 mt-0">
                Método de pagamento <span class="text-danger">&nbsp;*&nbsp;</span>
              </label>
              <base-input input-classes="form-control-sm">
                <puzl-select v-model="payment_allotment.payment_method_id" :items="$_payment_methods" />
              </base-input>
            </div>
            <div class="col-md-12 mt-2">
              <small>
                Valor total:
                <span style="font-size: 0.9rem" class="pl-1 money font-weight-600">{{ statistic.total_value | currency() }}</span></small
              >
            </div>
            <div class="col-md-12">
              <small>
                Pagamentos: <span style="font-size: 0.9rem" class="money font-weight-600"> {{ payment_allotment.ids.length }}</span></small
              >
            </div>
            <div class="col-md-12">
              <small>
                Fornecedores: <span style="font-size: 0.9rem" class="money font-weight-600"> {{ statistic.providers }}</span></small
              >
            </div>
            <div class="col-md-12 border-left border-3 border-approved pl-3 mt-1 ml-5 mb-1">
              <small>
                Aprovado:
                <span style="font-size: 0.9rem" class="money font-weight-600">
                  {{ statistic.approved | currency() }} ({{ statistic.approved_length }})</span
                ></small
              >
            </div>
            <div class="col-md-12 border-left border-3 border-warning pl-3 mt-1 ml-5 mb-1">
              <small>
                Pendente:
                <span style="font-size: 0.9rem" class="money font-weight-600">
                  {{ statistic.pending | currency() }} ({{ statistic.pending_length }})</span
                ></small
              >
            </div>
            <div class="col-md-12 border-left border-3 border-danger pl-3 ml-5">
              <small>
                Atrasado:
                <span style="font-size: 0.9rem" class="money font-weight-600">
                  {{ statistic.late | currency() }} ({{ statistic.late_length }})</span
                ></small
              >
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center row m-3 pb-1 p-0">
          <div class="col-md-4 pl-1 pr-1">
            <base-button block type="secondary" @click="closeModal()"> Fechar</base-button>
          </div>
           <div v-if="$hasPermission('bill_pay.approve_installment')" class="col-md-4 mt-3 mt-md-0 pl-1 pr-1 ">
            <base-button
              :disabled="!payment_allotment.paid_at || !payment_allotment.payment_method_id || !payment_allotment.bank_account_id"
              :loading="loading"
              block
              type="primary"
              @click="save('Approve')"
            >
              Aprovar
            </base-button>
          </div>
          <div v-if="$hasPermission('bill_pay.pay_installment')" class="col-md-4 mt-3 mt-md-0 pl-1 pr-1">
            <base-button
              :disabled="!payment_allotment.paid_at || !payment_allotment.payment_method_id || !payment_allotment.bank_account_id"
              :loading="loading"
              block
              type="success"
              @click="save()"
            >
              Pagar
            </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import InputTimePicker from "@/components/InputTimePicker";
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";

export default {
  name: "ModalPaidAllotments",
  data() {
    return {
      modal: {
        title: "Confirmação de pagamento",
        create: false,
      },
      statistic: {
        providers: 0,
        pending: 0,
        pending_length: 0,
        late_length: 0,
        late: 0,
        total_value: 0,
      },
      payment_allotment: {
        ids: [],
        bank_account_id: null,
        payment_method_id: null,
        paid_at: null,
      },
      loading: false,
      bank_accounts: [],
      loadingBankAccount: false,
    };
  },
  computed: {
    ...mapGetters({
      $_items: "billPay/fetch",
      $_payment_methods: "paymentMethod/fetch",
    }),
  },
  components: {
    InputTimePicker,
    PuzlSelect,
    InputDateTimePicker,
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(bill_pay_ids) {
      if(bill_pay_ids.length > 100) {
        return this.$notify({
          type: 'danger',
          message: 'O limite de parcelas (100) foi excedido, operação não permitida.'
        })
      }
      this.payment_allotment.ids = bill_pay_ids;
      this.payment_allotment.payment_method_id = null;
      this.payment_allotment.bank_account_id = null;
      this.payment_allotment.paid_at = null;
      const selected = this.$_items.filter((item) => bill_pay_ids.includes(item.id));
      const company_plant_id = selected[0].company_plant_id;
      this.statistic.total_value = 0;
      this.statistic.providers = selected.reduce(
        (entryMap, e) => entryMap.set(e.entity_document, [...(entryMap.get(e.entity_document) || []), e]),
        new Map()
      ).size;
      selected.forEach(function (item) {
        this.statistic.total_value += Number(item.final_value);
      }, this);
      const pendings = selected.filter((item) => item.status.status === 0);
      const approved = selected.filter((item) => item.status.status === 8);
      const late = selected.filter((item) => item.status.status === 2);
      this.statistic.pending = pendings.reduce((partialSum, a) => partialSum + Number(a.final_value), 0);
      this.statistic.approved = approved.reduce((partialSum, a) => partialSum + Number(a.final_value), 0);
      this.statistic.late = late.reduce((partialSum, a) => partialSum + Number(a.final_value), 0);
      this.statistic.pending_length = pendings.length;
      this.statistic.late_length = late.length;
      this.statistic.approved_length = approved.length;
      this.getBankAccountsByCompanyPlant(company_plant_id);
      this.modal.create = true;
    },
    save(type = 'Pay') {
      if(type === 'Pay' && moment(this.payment_allotment.paid_at).isAfter(moment(), 'day')) {
        return this.$notify({
            type: 'danger',
            message: 'Data de pagamento não pode ser maior que hoje'
          })
      }
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.loading = true;
      this.$store.dispatch(`billPay/post${type}PaymentAllotments`, this.payment_allotment).then((response) => {
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.$emit("fetch");
        this.modal.create = false;
      }).catch(error => {
          this.$notify({
            type: "danger",
            message: error.data.message
          });
      })
      .finally(() => {
        this.loading = false;
      });
    },
    getBankAccountsByCompanyPlant() {
      this.loadingBankAccount = true;
      this.$store
        .dispatch("bankAccount/getByBillPay", { bill_pay_installment_ids :this.payment_allotment.ids })
        .then((response) => {
          this.bank_accounts = response.data;
          this.loadingBankAccount = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch("paymentMethod/fetchItems");
  },
};
</script>

<style scoped></style>
