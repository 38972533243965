<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb/>
    </base-header>
    <list/>
  </div>
</template>

<script>
import List from './Shared/_List.vue'
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb.vue";
export default {
  name: "Index",
  components : {
    PuzlBreadcrumb,
    List
  }
}
</script>

<style scoped>

</style>
