<template>
  <div>
    <div>
      <BarLineChart v-if="$_responsibilityBarChart.loading === false" :theme="theme" :option="$_responsibilityBarChart" />
    </div>
    <div>
      <BarLineChart v-if="$_osBarChart.loading === false" :theme="theme" :option="$_osBarChart" />
    </div>
  </div>
</template>

<script>
import BarLineChart from "@/components/Echarts/BarLineChart.vue";

import { mapGetters } from "vuex";
export default {
  name: "BarChart",
  components: {
    BarLineChart,
  },
  computed: {
    ...mapGetters({
      $_responsibilityBarChart: "DashboardTechnology/responsibilityBarChart",
      $_osBarChart: "DashboardTechnology/osBarChart",
    }),
  },
  props: {
    theme: {
      type: Boolean,
      default: false,
      description: "Tema",
    },
  },
};
</script>

<style></style>
