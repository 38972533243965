<template>
  <div>
    <div class="container-fluid show-md">
      <card id="stats">
        <div class="container-fluid">
          <div class="row mb-4" style="margin-left: 30px">
            <div class="col-md-2 pr-1">
              <puzl-select @change="init()" class="select-xl" :clearable="false" v-model="year"
                           :items="[{id: 2021, name: '2021'}, {id: 2022, name: '2022'}, {id: 2023, name: '2023'}]"/>
            </div>
            <div class="col-md-2 pr-1 pl-1">
              <puzl-select
                @change="init()"
                class="select-xl"
                placeholder="Selecionar central"
                style="min-width: 200px"
                v-model="filters.company_plants"
                :items="$_plants"
                :disabled="loadingPlants"/>
            </div>
            <div class="col-md-3 pr-1 pl-1">
              <puzl-select
                :clearable="false"
                @change="init()"
                class="select-xl"
                placeholder="Opção de plano de contas"
                style="min-width: 200px"
                v-model="filters.chart_account"
                :items="[{id: 1, name: 'PAGAMENTO'}, {id: 2, name: 'DATA DE ENTRADA'}]"
              />
            </div>
            <div class="col-md-4 pl-1">
              <router-link
                :to="{
                  path: '/configuration/chart-account-resume',
                }"
              >
                <base-button type="warning" class="text-uppercase">Plano de contas</base-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="container-fluid testimonial-group">
          <div class="row card-scrollable" v-if="items && items['months']">
            <div style="position: sticky !important;left: 0;z-index: 2;background: white;border: 1px solid white"
                 class="col-md-2">
              <div class="row">
                <div class="col-2">
                </div>
                <div class="col-10 pl-0 card-sticky">
                  <div class="custom-box  bg-primary" style="width: 100%">
                    <div class="row">
                      <div class="col-12 title-box-w  text-uppercase">DESCRIÇÃO</div>
                    </div>
                  </div>
                </div>
                <!--                receitas-->
                <div class="col-2">
                  <div @click.prevent="show_receipt = !show_receipt" class="pointer avatar avatar-sm-3 rounded-pill"
                       style="margin-top: 15px">
                    <i v-show="!show_receipt" class="fa-solid fa-plus"></i>
                    <i v-show="show_receipt" class="fa-solid fa-minus"></i>
                  </div>
                </div>
                <div class="col-10 pl-0 mt-2">
                  <div class="custom-box custom-border bg-white" style="width: 100%">
                    <div class="row">
                      <div class="col-12 title-box ">(+) RECEITAS</div>
                    </div>
                  </div>
                  <div v-show="show_receipt" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto !important;">
                    <div class="row">
                      <div class="col-12 title-box ">CONCRETO</div>
                      <div class="col-12 title-box ">SERVIÇO</div>
                      <div class="col-12 title-box ">ADICIONAL</div>
                      <div class="col-12 title-box ">AVULSO</div>
                      <div v-for="item in items.single_chart_accounts" class="col-12 title-box ">{{ item.title }}</div>
                    </div>
                  </div>
                </div>
                <!--                impostos-->
                <div class="col-2">
                  <div @click.prevent="show_taxes = !show_taxes" class="pointer avatar avatar-sm-3 rounded-pill"
                       style="margin-top: 15px">
                    <i v-show="!show_taxes" class="fa-solid fa-plus"></i>
                    <i v-show="show_taxes" class="fa-solid fa-minus"></i>
                  </div>
                </div>
                <div class="col-10 pl-0 mt-2">
                  <div class="custom-box custom-border bg-white" style="width: 100%">
                    <div class="row">
                      <div class="col-12 title-box ">(-) IMPOSTOS</div>
                    </div>
                  </div>
                  <div v-show="show_taxes" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto !important;">
                    <div class="row">
                      <div class="col-12 title-box ">ISS RETIDO</div>
                      <div class="col-12 title-box ">ISS DEVIDO</div>
                    </div>
                  </div>
                </div>
                <!--                receita liquida-->
                <div class="col-2">
                </div>
                <div class="col-10 pl-0 mt-2">
                  <div class="custom-box custom-border bg-light-custom" style="width: 100%">
                    <div class="row">
                      <div class="col-12 title-box text-white ">RECEITA LÍQUIDA</div>
                    </div>
                  </div>
                </div>
                <!--                materia prima-->
                <div class="col-2">
                  <div @click.prevent="show_mcc = !show_mcc" class="pointer avatar avatar-sm-3 rounded-pill"
                       style="margin-top: 32px">
                    <i v-show="!show_mcc" class="fa-solid fa-plus"></i>
                    <i v-show="show_mcc" class="fa-solid fa-minus"></i>
                  </div>
                </div>
                <div class="col-10 pl-0 mt-4">
                  <div class="custom-box custom-border bg-white" style="width: 100%">
                    <div class="row">
                      <div class="col-12 title-box ">(-) MATÉRIA PRIMA</div>
                    </div>
                  </div>
                  <div v-show="show_mcc" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto !important;">
                    <div class="row">
                      <div v-for="mcc in items.accumulated.mcc.details"
                           class="col-12 title-box text-uppercase text-truncate">
                        <!--                        <el-popover trigger="hover" placement="top" >-->
                        <!--                                       <span class="text-center">{{ mcc.title }}</span>-->
                        <!--                          <span slot="reference">-->
                        <small class="text-truncate">{{ mcc.title }}</small>
                        <!--                                            </span>-->
                        <!--                        </el-popover>-->
                      </div>
                    </div>
                  </div>
                </div>
                <!--                margem de distribuição-->
                <div class="col-2"/>
                <div class="col-10 pl-0 mt-2">
                  <div class="custom-box custom-border bg-light-custom" style="width: 100%">
                    <div class="row">
                      <div class="col-12 title-box text-white  text-uppercase">Margem de contribuição</div>
                    </div>
                  </div>
                </div>
                <!--                custos-->
                <div class="col-2"/>
                <div class="col-10 pl-0 mt-2">
                  <div class="custom-box custom-border bg-danger" style="width: 100%">
                    <div class="row">
                      <div class="col-12 title-box text-white  text-uppercase">Despesas e custos</div>
                    </div>
                  </div>
                </div>
                <!--                plano de contas-->
                <template v-for="(chart_account, key) in Object.values(items.chart_accounts)">
                  <div class="col-2">
                    <div @click.prevent="chart_account.show = !chart_account.show"
                         class="avatar avatar-sm-3 rounded-pill pointer"
                         :style="key === 0 ? 'margin-top: 34px' : 'margin-top: 15px'">
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                  <div class="col-10 pl-0" :class="key === 0 ? 'mt-4' : 'mt-2'">
                    <div class="custom-box custom-border bg-white" style="width: 100%">
                      <div class="row">
                        <div class="col-12 title-box text-uppercase">(-) {{ chart_account.title }}</div>
                      </div>
                    </div>
                    <div v-show="chart_account.show" class="custom-box custom-border bg-white"
                         style="width: 100%;border-top: none;height: auto !important;">
                      <div class="row">
                        <div v-for="detail in chart_account.details" class="col-12 title-box text-uppercase">
                          <small class="text-truncate">{{ detail.name }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <!--                linha final-->
                <div class="col-2"/>
                <div class="col-10 pl-0 mt-4">
                  <div class="custom-box custom-border bg-primary final-sum" style="width: 100%">
                    <div class="row">
                      <div class="col-12 title-box text-white text-uppercase">Resultado</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="position: sticky !important;left: 25vw;z-index: 2;margin-right: 1px;background: white"
                 class="second-stat col-md-3">
              <div class="row margin-second-row">
                <div class="col-2">
                </div>
                <div class="col-10 card-sticky">
                  <div class="custom-box  bg-primary" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box-w text-center text-uppercase">ACUMULADO</div>
                      <div class="col-6 title-box-w text-center">{{ items['accumulated']['volume'] }} m<sup>3</sup>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="" style="margin-top: 15px">
                  </div>
                </div>
                <div class="col-10 mt-2">
                  <div class="custom-box custom-border bg-white" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box text-center">
                        {{ items['accumulated']['receipt']['total'] | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">
                        {{
                          ((items['accumulated']['receipt']['total'] / items['accumulated']['volume']) || 0) | currency()
                        }}
                        /m <sup>3</sup></div>
                    </div>
                  </div>
                  <div v-show="show_receipt" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto">
                    <div class="row">
                      <div class="col-6 title-box text-center">
                        {{ items['accumulated']['receipt']['details']['concrete'] | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          ((items['accumulated']['receipt']['details']['concrete'] / items['accumulated']['volume']) || 0) | currency()
                        }} /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center">
                        {{ items['accumulated']['receipt']['details']['service'] | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          ((items['accumulated']['receipt']['details']['service'] / items['accumulated']['volume']) || 0) | currency()
                        }} /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center">
                        {{ items['accumulated']['receipt']['details']['additional'] | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          ((items['accumulated']['receipt']['details']['additional'] / items['accumulated']['volume']) || 0) | currency()
                        }} /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center">
                        {{ items['accumulated']['receipt']['details']['single'] | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          ((items['accumulated']['receipt']['details']['single'] / items['accumulated']['volume']) || 0) | currency()
                        }} /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center" v-for="item in items['single_chart_accounts']">
                        {{ item.total | currency() }}
                      </div>
                      <div class="col-6 title-box text-center" v-for="item in items['single_chart_accounts']">
                        {{ (item.total / items['accumulated']['volume']) | currency()  }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="" style="margin-top: 15px">
                  </div>
                </div>
                <div class="col-10 mt-2">
                  <div class="custom-box custom-border bg-white" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box text-center">
                        {{ items['accumulated']['taxes']['total'] | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">
                        {{
                          ((items['accumulated']['taxes']['total'] / items['accumulated']['volume']) || 0) | currency()
                        }}
                        /m <sup>3</sup></div>
                    </div>
                  </div>
                  <div v-show="show_taxes" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto">
                    <div class="row">
                      <div class="col-6 title-box text-center">
                        {{ items['accumulated']['taxes']['details']['iss_retain'] | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          ((items['accumulated']['taxes']['details']['iss_retain'] / items['accumulated']['volume']) || 0) | currency()
                        }} /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center">
                        {{ items['accumulated']['taxes']['details']['iss_due'] | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          ((items['accumulated']['taxes']['details']['iss_due'] / items['accumulated']['volume']) || 0) | currency()
                        }} /m<sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <!--                RECEITA-->
                <div class="col-2">
                </div>
                <div class="col-10 mt-2">
                  <div class="custom-box bg-light-custom" style="width: 100%;border: 1px solid black">
                    <div class="row">
                      <div class="col-6 title-box-w text-white text-center">
                        {{
                          (items['accumulated']['receipt']['total'] - items['accumulated']['taxes']['total']) | currency()
                        }}
                      </div>
                      <div class="col-6 title-box-w text-center text-white">
                        {{
                          (((items['accumulated']['receipt']['total'] - items['accumulated']['taxes']['total']) / items['accumulated']['volume']) || 0) | currency()
                        }} /m <sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="  " style="margin-top: 32px">
                  </div>
                </div>
                <div class="col-10 mt-4">
                  <div class="custom-box bg-white" style="width: 100%;border: 1px solid black">
                    <div class="row">
                      <div class="col-6 title-box text-center">
                        {{ calc(items['accumulated']['mcc']['total']) | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">
                        {{
                          calc(items['accumulated']['mcc']['total'] / items['accumulated']['volume']) | currency()
                        }}
                        /m <sup>3</sup></div>
                    </div>
                  </div>
                  <div v-show="show_mcc" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto">
                    <div class="row" v-for="mcc in items.accumulated.mcc.details">
                      <div class="col-6 title-box text-center">{{ calc(mcc.total) | currency() }}</div>
                      <div class="col-6 title-box text-center">
                        {{ calc(mcc.total / items['accumulated']['volume']) | currency() }} /m<sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <!--                MARGEM DE DISTRIBUIÇÃO-->
                <div class="col-2"/>
                <div class="col-10 mt-2">
                  <div class="custom-box  bg-light-custom" style="width: 100%;border: 1px solid black">
                    <div class="row">
                      <div class="col-6 title-box text-white text-center">
                        {{
                          ((items['accumulated']['receipt']['total'] - items['accumulated']['taxes']['total']) - items['accumulated']['mcc']['total']) | currency()
                        }}
                      </div>
                      <div class="col-6 title-box text-center text-white">
                        {{
                          calc(((items['accumulated']['receipt']['total'] - items['accumulated']['taxes']['total']) - items['accumulated']['mcc']['total']) / items['accumulated']['volume']) | currency()
                        }} /m <sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <!--                CUSTOS-->
                <div class="col-2"/>
                <div class="col-10 mt-2">
                  <!--                  custos-->
                  <div class="custom-box  bg-danger" style="width: 100%;border: 1px solid black">
                    <div class="row">
                      <div class="col-6 title-box text-white text-center">
                        {{
                          sumChartAccount() | currency()
                        }}
                      </div>
                      <div class="col-6 title-box text-center text-white">
                        {{
                          calc(sumChartAccount() / items['accumulated']['volume']) | currency()
                        }} /m <sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <template v-for="(chart_account, key) in Object.values(items.chart_accounts)">
                  <div class="col-2">
                    <div :style="key === 0 && 'margin-top: 33px'">
                      <i class="fa-solid fa-plus invisible"></i>
                    </div>
                  </div>
                  <div class="col-10" :class="key === 0 ? 'mt-4' : 'mt-2'">
                    <div class="custom-box custom-border bg-white" style="width: 100%">
                      <div class="row">
                        <div class="col-6 title-box text-center">{{ chart_account.total | currency() }}</div>
                        <div class="col-6 title-box text-center">
                          {{ calc(chart_account.total / items['accumulated']['volume']) | currency() }} /m <sup>3</sup>
                        </div>
                      </div>
                    </div>
                    <div v-show="chart_account.show" class="custom-box custom-border bg-white"
                         style="width: 100%;border-top: none;height: auto !important;">
                      <div class="row pointer" title="Clique para exibir detalhes"
                           @click.prevent="handleShowModalEntries(detail.id, {title: detail.title, month: null, item: detail,  year: year, index: null})"
                           v-for="detail in chart_account.details">
                        <div class="col-6 title-box  text-center">
                          {{ calc(detail.title) | currency() }}
                        </div>
                        <div class="col-6 title-box text-center ">
                          {{ calc(detail.title / items['accumulated']['volume']) | currency() }} /m <sup>3</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="col-2"/>
                <div class="col-10 mt-4">
                  <div class="custom-box custom-border bg-primary" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box text-white text-center">{{
                          calc((items['accumulated']['receipt']['total'] - items['accumulated']['taxes']['total']) - items['accumulated']['mcc']['total'] - sumChartAccount()) | currency()
                        }}
                      </div>
                      <div class="col-6 title-box text-white text-center">{{
                          calc(((items['accumulated']['receipt']['total'] - items['accumulated']['taxes']['total']) - items['accumulated']['mcc']['total'] - sumChartAccount()) / items['accumulated']['volume']) | currency()
                        }} /m <sup>3</sup></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            meses-->
            <div v-for="(item, index) in items['months']" class="col-md-3 margin" style="z-index: 0;opacity: 95%;">
              <div class="row">
                <div class="col-2">
                </div>
                <div class="col-10 card-sticky">
                  <div class="custom-box  bg-primary" style="width: 100%">
                    <div class="row">
                      <div class="col-6 text-center title-box-w text-uppercase">{{ item.title }}</div>
                      <div class="col-6 title-box-w text-center">
                        {{ (item.receipt.details.sum_schedule_travel_volume || 0) }} m<sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="" style="margin-top: 15px">
                  </div>
                </div>
                <div class="col-10 mt-2">
                  <div class="custom-box custom-border bg-white" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box text-center">{{ (item.receipt.title || 0) | currency() }}</div>
                      <div class="col-6 title-box text-center">
                        {{ calc(item.receipt.title / item.receipt.details.sum_schedule_travel_volume) | currency() }}
                        /m <sup>3</sup></div>
                    </div>
                  </div>
                  <div v-show="show_receipt" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto">
                    <div class="row">
                      <div class="col-6 title-box text-center">
                        {{ (item.receipt.details.sum_schedule_travel_cost_billing || 0) | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">
                        {{
                          ((item.receipt.details.sum_schedule_travel_cost_billing / item.receipt.details.sum_schedule_travel_volume) || 0) | currency()
                        }}
                        /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center">
                        {{ (item.receipt.details.sum_schedule_service_cost_cost || 0) | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">
                        {{
                          ((item.receipt.details.sum_schedule_service_cost_cost / item.receipt.details.sum_schedule_travel_volume) || 0) | currency()
                        }}
                        /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center">
                        {{ (item.receipt.details.sum_schedule_additional_price || 0) | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">
                        {{
                          ((item.receipt.details.sum_schedule_additional_price / item.receipt.details.sum_schedule_travel_volume) || 0) | currency()
                        }}
                        /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center">
                        {{ (item.receipt.details.sum_single_additional_invoice_value || 0) | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">
                        {{
                          ((item.receipt.details.sum_single_additional_invoice_value / item.receipt.details.sum_schedule_travel_volume) || 0) | currency()
                        }}
                        /m<sup>3</sup></div>

                      <div class="col-6 title-box text-center" v-for="(single_chart_account, key) in items['single_chart_accounts']">
                        {{ verifySingleChartAccount(item, key) | currency() }}
                      </div>
                      <div class="col-6 title-box text-center" v-for="(single_chart_account, key) in items['single_chart_accounts']">
                        {{ verifySingleChartAccount(item, key) | currency()  }}
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="  " style="margin-top: 15px">
                  </div>
                </div>
                <div class="col-10 mt-2">
                  <div class="custom-box custom-border bg-white" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box text-center">{{ item.taxes.title | currency() }}</div>
                      <div class="col-6 title-box text-center">
                        {{ ((item.taxes.title / item.receipt.details.sum_schedule_travel_volume) || 0) | currency() }}
                        /m <sup>3</sup></div>
                    </div>
                  </div>
                  <div v-show="show_taxes" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto">
                    <div class="row">
                      <div class="col-6 title-box text-center">
                        {{ (item.taxes.details[0].entry_launch_items_total_value || 0) | currency() }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          ((item.taxes.details[0].entry_launch_items_total_value / item.receipt.details.sum_schedule_travel_volume) || 0) | currency()
                        }} /m<sup>3</sup></div>
                      <div class="col-6 title-box text-center">
                        {{ (item.taxes.details[1].entry_launch_items_total_value || 0)| currency() }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          ((item.taxes.details[1].entry_launch_items_total_value / item.receipt.details.sum_schedule_travel_volume) || 0) | currency()
                        }} /m<sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <!--                RECEITA-->
                <div class="col-2">
                </div>
                <div class="col-10 mt-2">
                  <div class="custom-box custom-border bg-light-custom" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box-w text-center">{{
                          (item.receipt.title - item.taxes.title) | currency()
                        }}
                      </div>
                      <div class="col-6 title-box-w text-center">{{
                          calc((item.receipt.title - item.taxes.title) / item.receipt.details.sum_schedule_travel_volume) | currency()
                        }} /m <sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="  " style="margin-top: 32px">
                  </div>
                </div>
                <div class="col-10 mt-4">
                  <div class="custom-box custom-border bg-white" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box text-center">{{ (item.mcc.title || 0) | currency() }}</div>
                      <div class="col-6 title-box text-center">
                        {{ ((item.mcc.title / item.receipt.details.sum_schedule_travel_volume) || 0) | currency() }} /m
                        <sup>3</sup></div>
                    </div>
                  </div>
                  <div v-show="show_mcc" class="custom-box custom-border bg-white"
                       style="width: 100%;border-top: none;height: auto">
                    <div class="row" v-for="(mcc, index) in items.accumulated.mcc.details">
                      <div class="col-6 title-box text-center">{{
                          calc(verifyMcc(item.mcc, mcc.cmc_id)) | currency()
                        }}
                      </div>
                      <div class="col-6 title-box text-center">{{
                          (calc(verifyMcc(item.mcc, mcc.cmc_id) / item.receipt.details.sum_schedule_travel_volume) || 0) | currency()
                        }} /m<sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <!--                MARGEM DE DISTRIBUIÇÃO-->
                <div class="col-2"/>
                <div class="col-10 mt-2">
                  <div class="custom-box custom-border bg-light-custom" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box-w text-center">
                        {{ ((item.receipt.title - item.taxes.title) - item.mcc.title) | currency() }}
                      </div>
                      <div class="col-6 title-box-w text-center">{{
                          calc((((item.receipt.title - item.taxes.title) - item.mcc.title) / item.receipt.details.sum_schedule_travel_volume)) | currency()
                        }} /m <sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <!--                CUSTOS-->
                <div class="col-2"/>
                <div class="col-10 mt-2">
                  <div class="custom-box custom-border bg-danger" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box-w text-center">
                        {{ sumChartAccountByMonth(item) | currency() }}
                      </div>
                      <div class="col-6 title-box-w text-center">{{
                          calc(sumChartAccountByMonth(item) / item.receipt.details.sum_schedule_travel_volume) | currency()
                        }} /m <sup>3</sup></div>
                    </div>
                  </div>
                </div>
                <template v-for="(chart_account, key) in Object.values(item.chart_accounts)">
                  <div class="col-2">
                    <div :style="key === 0 && 'margin-top: 33px'">
                      <i class="fa-solid fa-plus invisible"></i>
                    </div>
                  </div>
                  <div class="col-10" :class="key === 0 ? 'mt-4' : 'mt-2'">
                    <div class="custom-box custom-border bg-white" style="width: 100%">
                      <div class="row">
                        <div class="col-6 title-box text-center">{{ chart_account.title | currency() }}</div>
                        <div class="col-6 title-box text-center">
                          {{ calc(chart_account.title / item.receipt.details.sum_schedule_travel_volume) | currency() }}
                          /m <sup>3</sup></div>
                      </div>
                    </div>
                    <div v-show="verifyChartAccountIsOpen(chart_account.id)" class="custom-box custom-border bg-white"
                         style="width: 100%;border-top: none;height: auto !important;">
                      <div @click.prevent="handleShowModalEntries(detail.id, {
                        month: item.title,
                        index: index + 1,
                        year: year,
                        title: verifyChartAccount(chart_account.id, item, key),
                        item: detail,
                        })" title="Clique para exibir detalhes" class="row pointer"
                           v-for="(detail, key) in items.chart_accounts[chart_account.id].details">
                        <div class="col-6 title-box  text-center">
                          {{ verifyChartAccount(chart_account.id, item, key) | currency() }}
                        </div>
                        <div class="col-6 title-box text-center ">
                          {{
                            calc(verifyChartAccount(chart_account.id, item, key) / item.receipt.details.sum_schedule_travel_volume) | currency()
                          }} /m <sup>3</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="col-2"/>
                <div class="col-10 mt-4">
                  <div class="custom-box custom-border bg-primary final-sum" style="width: 100%">
                    <div class="row">
                      <div class="col-6 title-box-w text-center">{{
                          calc(((item.receipt.title - item.taxes.title) - item.mcc.title) - sumChartAccountByMonth(item)) | currency()
                        }}
                      </div>
                      <div class="col-6 title-box-w text-center">{{
                          calc(((((item.receipt.title - item.taxes.title) - item.mcc.title)) - sumChartAccountByMonth(item)) / item.receipt.details.sum_schedule_travel_volume) | currency()
                        }} /m <sup>3</sup></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-left: 30px" v-else>
            <div v-for="item in 4" style="position: sticky !important;left: 0;z-index: 2;background: white"
                 class="col-md-2">
              <div class="row">
                <div class="col-md-12 pr-3 pl-3">
                  <card>
                    <skeleton-puzl type="button"/>
                    <skeleton-puzl type="button"/>
                    <skeleton-puzl type="button"/>
                    <skeleton-puzl type="button"/>
                    <br>
                    <skeleton-puzl type="button"/>
                    <skeleton-puzl type="button"/>
                    <br>
                    <skeleton-puzl type="button"/>
                    <skeleton-puzl type="button"/>
                    <skeleton-puzl type="button"/>
                    <br>
                    <skeleton-puzl type="button"/>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
    <modal-show-entries @fetch="init" ref="ModalShowEntries"/>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect.vue";
import InputDatePicker from "@/components/InputDatePicker.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import {mapGetters} from "vuex";
import ModalShowEntries from './_ModalShowEntries.vue';


export default {
  name: 'List',
  components: {
    SkeletonPuzl,
    SkeletonPuzlGrid,
    PuzlSelect,
    InputDatePicker,
    ModalShowEntries
  },
  data() {
    return {
      year: null,
      show_receipt: false,
      show_taxes: false,
      show_mcc: false,
      items: [],
      loadingPlants: false,
      filters: {
        company_plants: null,
        chart_account: 1
      },
    }
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    })
  },
  methods: {
    calc(val) {
      if (!val) {
        return 0
      }
      return isFinite(val) ? val : 0.0
    },
    verifyChartAccount(key, chart_account, chart_key) {
      const selected = chart_account.chart_accounts[key].details
      if (selected[chart_key]) {
        return this.calc(selected[chart_key].title)
      } else {
        return 0
      }
    },
    verifySingleChartAccount(month, key) {
      const selected = month.single_chart_accounts[key]
      if (selected) {
        return this.calc(selected.title)
      } else {
        return 0
      }
    },
    handleShowModalEntries(chart_account_id, params) {
      this.$refs.ModalShowEntries.handleCreateModal(chart_account_id, params)
    },
    verifyChartAccountIsOpen(chart_account_id) {
      return this.items.chart_accounts[chart_account_id].show
    },
    sumChartAccount() {
      return Object.values(this.items.chart_accounts).reduce((a, b) => Number(a) + Number(b.total), 0).toFixed(2)
    },
    sumChartAccountByMonth(item) {
      return Object.values(item.chart_accounts).reduce((a, b) => Number(a) + Number(b.title), 0).toFixed(2)
    },
    init() {
      let filters = this.$helper.cloneObject(this.filters)
      filters.company_plants = filters.company_plants ? [filters.company_plants] : null
      let loader = this.$loading.show()
      let promise = undefined

      const date = new Date();

      if (this.year !== date.getFullYear()) {
        promise = Promise.all([
          this.$store.dispatch('dre/getStatistics', {month: 1, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 2, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 3, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 4, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 5, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 6, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 7, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 8, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 9, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 10, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 11, year: this.year, filters: filters}),
          this.$store.dispatch('dre/getStatistics', {month: 12, year: this.year, filters: filters}),
        ])
      } else {
        const promises = [];
        for (let i = 1; i <= date.getMonth() + 1; i++) {
          promises.push(this.$store.dispatch('dre/getStatistics', {month: i, year: this.year, filters}));
        }
        promise = Promise.all(promises);
      }
      promise.then((response) => {
        let months = response.map((res) => res.data)
        let mcc_categories = {}
        let chart_accounts = {}
        let single_chart_accounts = {}

        const cloneObject = this.$helper.cloneObject
        /**
         * percorre os dados estátisticos definido por período
         */
        months.forEach(function (item, key) {
          let month = cloneObject(item)
          /**
           * valida se existe matéria prima para o período selecionado
           */
          if (month.mcc.details.length) {
            /**
             * se houver, realiza a soma total
             */
            month.mcc.details.forEach(function (mcc) {
              if (mcc_categories[mcc.cmc_id]) {
                mcc_categories[mcc.cmc_id].total += Number(Number(mcc.entry_launch_items_total_value).toFixed(2))
              } else {
                mcc_categories[mcc.cmc_id] = {
                  title: mcc.title,
                  cmc_id: mcc.cmc_id,
                  category_id: mcc.category_id,
                  total: Number(mcc.entry_launch_items_total_value)
                }
              }
            })
          }
          if (Object.values(month.single_chart_accounts).length) {
            /**
             * se houver, realiza a soma total
             */
            Object.values(month.single_chart_accounts).forEach(function (chart_account) {
              if (single_chart_accounts[chart_account.id]) {
                single_chart_accounts[chart_account.id].total += Number(Number(chart_account.title).toFixed(2))
              } else {
                single_chart_accounts[chart_account.id] = {
                  title: chart_account.name,
                  total: Number(chart_account.title)
                }
              }
            })
          }
          if (month.chart_accounts) {
            Object.values(month.chart_accounts).forEach(function (chart_account, key) {
              if (!chart_accounts[chart_account.id]) {
                chart_accounts[chart_account.id] = {
                  title: chart_account.name,
                  show: false,
                  details: cloneObject(chart_account.details),
                  total: Number(Number(chart_account.title).toFixed(2))
                }
              } else {
                chart_accounts[chart_account.id].total += Number(Number(chart_account.title).toFixed(2))
                for (let index in chart_account.details) {
                  if (!chart_accounts[chart_account.id].details[index]) {
                    if (Object.values(chart_accounts[chart_account.id].details).length) {
                      chart_accounts[chart_account.id].details[index] = {
                        id: chart_account.details[index].id,
                        name: chart_account.details[index].name,
                        title: Number(chart_account.details[index].title)
                      }
                    } else {
                      chart_accounts[chart_account.id].details = {}
                      chart_accounts[chart_account.id].details[index] = {
                        id: chart_account.details[index].id,
                        name: chart_account.details[index].name,
                        title: Number(chart_account.details[index].title)
                      }
                    }
                  } else {
                    chart_accounts[chart_account.id].details[index].title = Number(chart_accounts[chart_account.id].details[index].title) + Number(chart_account.details[index].title)
                  }
                }
              }
            })
          }
        })
        this.items = {
          months: months,
          chart_accounts: chart_accounts,
          single_chart_accounts: single_chart_accounts,
          accumulated: {
            volume: months.reduce((a, b) => Number(a) + Number(b.receipt && b.receipt.details && b.receipt.details.sum_schedule_travel_volume), 0).toFixed(2),
            receipt: {
              total: months.reduce((a, b) => Number(a) + Number(b.receipt && b.receipt.details && b.receipt.details.total), 0).toFixed(2),
              details: {
                concrete: months.reduce((a, b) => Number(a) + Number(b.receipt && b.receipt.details && b.receipt.details.sum_schedule_travel_cost_billing), 0).toFixed(2),
                service: months.reduce((a, b) => Number(a) + Number(b.receipt && b.receipt.details && b.receipt.details.sum_schedule_service_cost_cost), 0).toFixed(2),
                additional: months.reduce((a, b) => Number(a) + Number(b.receipt && b.receipt.details && b.receipt.details.sum_schedule_additional_price), 0).toFixed(2),
                single: months.reduce((a, b) => Number(a) + Number(b.receipt && b.receipt.details && b.receipt.details.sum_single_additional_invoice_value), 0).toFixed(2),
              }
            },
            taxes: {
              total: months.reduce((a, b) => Number(a) + (Number(b.taxes && b.taxes.details[0] && b.taxes.details[0].entry_launch_items_total_value) + Number(b.taxes && b.taxes.details[1] && b.taxes.details[1].entry_launch_items_total_value)), 0).toFixed(2),
              details: {
                iss_retain: months.reduce((a, b) => Number(a) + Number(b.taxes && b.taxes.details && b.taxes.details[0].entry_launch_items_total_value), 0).toFixed(2),
                iss_due: months.reduce((a, b) => Number(a) + Number(b.taxes && b.taxes.details && b.taxes.details[1].entry_launch_items_total_value), 0).toFixed(2),
              }
            },
            mcc: {
              total: months.reduce((a, b) => a + (b.mcc && b.mcc.title), 0).toFixed(2),
              details: Object.values(mcc_categories).sort(function (obj1, obj2) {
                return obj1.category_id < obj2.category_id ? -1 : (obj1.category_id > obj2.category_id ? 1 : 0);
              })
            },
          },
        }
        loader.hide()
      })
    },
    /**
     * Retorna o valor do material caso exista no período informado
     * @param item
     * @param mcc_id
     * @returns {*|number}
     */
    verifyMcc(item, mcc_id) {
      const mcc = item.details.find(item => item.cmc_id == mcc_id)
      return mcc ? mcc.entry_launch_items_total_value : 0
    }
  },
  mounted() {
    this.year = new Date().getFullYear()
    this.filters.chart_account = 1
    this.loadingPlants = true
    this.$store.dispatch("plant/fetchItems").then(() => {
      this.loadingPlants = false
    })
    this.init()
  }
}

</script>

<style scoped type="text/css">

/* The heart of the matter */
.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}


.testimonial-group > .row > .col-md-4, .col-md-3, .col-md-2 {
  display: inline-block;
}

@media (max-width: 1440px) and (min-width: 1080px) {
  .testimonial-group > .row > .col-md-4, .col-md-3 {
    max-width: 23vw !important;
  }

  .margin-second-row {
    margin-left: -6.2vw;
  }

  .second-stat {
    max-width: 20vw !important;
  }

  .margin {
    margin-left: -4.9vw !important;
  }
}

@media (max-width: 1440px) and (min-width: 1080px) {
  .testimonial-group > .row > .col-md-4, .col-md-3 {
    max-width: 28vw !important;
  }

  .margin-second-row {
    margin-left: -6.2vw;
  }

  .second-stat {
    max-width: 28vw !important;
  }

  .margin {
    margin-left: -4.9vw !important;
  }
}

@media (min-width: 1441px) {
  .testimonial-group > .row > .col-md-4, .col-md-3 {
    max-width: 19vw !important;
  }

  .margin-second-row {
    margin-left: -5vw;
  }

  .second-stat {
    max-width: 18vw !important;
  }

  .margin {
    margin-left: -3.7vw !important;
  }
}

@media (max-width: 1080px) {
  .col-md-2 {
    max-width: 30vw !important;
  }

  .col-md-3 {
    max-width: 28vw !important;
  }

  .second-stat {
    left: 30vw !important;
  }
}

@media (min-width: 1081px) {
  .col-md-2 {
    max-width: 25vw !important;
  }
}

.grid-item--header {
  position: sticky;
  position: -webkit-sticky;
  background: white;
  top: 0;
}

.custom-box {
  height: 40px;
  color: white;
  border-radius: 2px;
  padding: 5px;
}

.card-scrollable {
  height: 80vh;
  overflow: auto;
}

.card-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.title-box {
  padding-top: 5px;
  color: #383636;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
}

.title-box-w {
  padding-top: 5px;
  color: white;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
}

.grid-col--fixed-left {
  position: sticky;
  left: 0;
  z-index: 1;
}

.body-box {
  padding-top: 8px;
  height: 40px;
  color: #383636;
  border-radius: 2px;
}

.custom-border {
  border: 1px solid black;
}

.final-sum {
  border-left: none !important;
}

.bg-light-custom {
  background: #879099 !important;
}

#stats > .card-body {
  padding: 1.5rem 0.2rem !important;
}
</style>
