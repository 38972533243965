<template>
  <div>
    <div class="container-fluid">
      <div class="row pt-0" style="margin-top: -0.5rem">
        <div class="col-md-12 p-1">
          <card>
            <div class="row">
              <div class="col-md-6 pl-2 pr-2 pb-2 ">
                <label>Período do carregamento</label>
                <input-date-picker :filterable="4" size="small" :default-range="range" ref="date-picker"
                                   @handleFilterDate="handleFilterDate"/>
              </div>
              <div class="col-md-6 pl-2 pr-2 pb-2">
                <label>
                  Filtro de centrais
                </label>
                <puzl-select
                  v-model="filter.company_plants"
                  :items="$_company_plants"
                  @change="init"
                  multiple
                  placeholder="Centrais"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row mt-n4 ">
        <div class="col-md-4 p-1">
          <div class="card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                     style="font-family: FreeMono, monospace;"><small
                    class="card-text font-weight-bold">
                    VOLUME DE CONCRETO</small></p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-primary rounded-circle float-right"><i
                    class="text-white fa fa-flask"></i></div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric">
                      {{ Number($_widgets.formulation_volume).toFixed(1).toString().replace('.', ',') }} m³
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-4 p-1">
          <div class="card card-stats">
            <div class=" card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small
                    class="card-text font-weight-bold">
                    VOLUME DE SERVIÇOS</small></p>
                </div>
                <div class="col-3" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape bg-info rounded-circle float-right"><i
                    class="text-white fa fa-tasks"></i></div>
                </div>
                <div class="col-md-12" style="margin-top: -25px">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric">
                      {{ Number($_widgets.service_volume).toFixed(1).toString().replace('.', ',') }} m³
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-4 p-1">
          <div class="card card-stats">
            <div class=" card-body">
              <div class="row">
                <div class="col-8" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"><small
                    class="card-text font-weight-bold">
                    Sobra</small></p>
                </div>
                <div class="col-4" style="margin-top: -5px">
                  <div class="text-center shadow icon icon-shape rounded-circle float-right bg-success">
                    <i class="text-white fa-solid fa-coins"></i>
                  </div>
                </div>
                <div class="col-md-12 " style="margin-top: -25px">
                  <h2 v-if="$_widgets.total_value" class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric">
                      {{ Number($_widgets.reuse_volume).toFixed(1).toString().replace('.', ',') }} m³
                    </div>
                  </h2>
                  <h2 v-else class="font-weight-semibold mb-0 pl-2">
                    <span v-if="loadingWidgets"><SpinnerPuzl/></span>
                    <div v-if="!loadingWidgets" class="numeric">
                      {{ 0 | currency }}
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-n4">
        <div class="col-md-3 p-1">
          <div class="border-1 border-success card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                     style="font-family: FreeMono, monospace;"><small
                    class="card-text font-weight-bold text-success">
                    REAPROVEITAMENTO</small></p>
                </div>
                <div class="col-3 text-right">
                  <i class="fa fa-recycle text-success main-icon-menu fa-2x"></i>
                </div>
                <div class="col-md-12 mt-n3">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <div class="numeric">
                      {{ floatToHuman($_widgets.recycling_volume) }} m3 ({{ Math.round($_widgets.recycling) }})
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="border-1 border-primary card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                     style="font-family: FreeMono, monospace;"><small
                    class="card-text font-weight-bold text-primary">
                    REDIRECIONAMENTO </small></p>
                </div>
                <div class="col-3 text-right">
                  <i class="fa fa-recycle text-primary main-icon-menu fa-2x"></i>
                </div>
                <div class="col-md-12 mt-n3">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <div class="numeric">
                      {{ floatToHuman($_widgets.redirection_volume) }} m3 ({{ Math.round($_widgets.redirection) }})
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="border-1 border-warning card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                     style="font-family: FreeMono, monospace;"><small
                    class="card-text font-weight-bold text-warning">
                    DESCARTE</small></p>
                </div>
                <div class="col-3 text-right">
                  <i class="fa fa-recycle text-warning main-icon-menu fa-2x"></i>
                </div>
                <div class="col-md-12 mt-n3">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <div class="numeric">
                      {{ floatToHuman($_widgets.disposal_volume) }} m3 ({{ Math.round($_widgets.disposal) }})
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widgets-sm col-md-3 p-1">
          <div class="border-1 border-danger card card-stats">
            <div class="card-body">
              <div class="row">
                <div class="col-9" style="margin-top: -5px">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold pl-2"
                     style="font-family: FreeMono, monospace;"><small
                    class="card-text font-weight-bold text-danger">
                    PERDA</small></p>
                </div>
                <div class="col-3 text-right">
                  <i class="fa fa-recycle text-danger main-icon-menu fa-2x"></i>
                </div>
                <div class="col-md-12 mt-n3">
                  <h2 class="font-weight-semibold mb-0 pl-2 text-lowercase">
                    <div class="numeric">
                      {{ floatToHuman($_widgets.lost_volume) }} m3 ({{ Math.round($_widgets.loss) }})
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-n4">
        <div class="col-md-12 p-1">
          <card>
            <div class="table-responsive p-0">
              <table class="table table-sm table-bordered">
                <thead>
                <th colspan="8" class="text-centr"
                    style="font-weight: 900 !important;background: rgb(201 201 201 / 37%);color: white;"><h4
                  style="padding: 0px !important; margin-bottom: 0px; font-weight: 500 !important; font-size: 0.85rem;">
                  <i class="fa fa-recycle text-success main-icon-menu" style="font-size:  1.2rem;"></i> SOBRA DE
                  CONCRETO</h4></th>
                <tr class="fixed">
                  <th width="10%" class="text-center">Data</th>
                  <th width="30%">CENTRAL</th>
                  <th width="10%" class="">O.S Origem</th>
                  <th width="10%" class="text-center">Volume Origem</th>
                  <th width="10%" class="">O.S Destino</th>
                  <th width="10%" class="text-center">Volume Destino</th>
                  <th width="10%" class="text-center">Sobra</th>
                  <th width="10%" class="">Tipo</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="loadingSkeleton" v-for="item in 5">
                  <th colspan="8">
                    <skeleton-puzl type="button"/>
                  </th>
                </tr>
                <tr v-if="!loadingSkeleton" v-for="item in $_items.data">
                  <td class="text-center d-flex">{{ item.created_at | parseDate('DD/MM/YYYY') }}
                    <el-popover trigger="hover" placement="bottom" class="p-0">
                      {{ item.user }}
                      <div slot="reference">
                        <img src="/img/icons/user-green.png" width="25px" height="25px">
                      </div>
                    </el-popover>
                  </td>
                  <td>{{ item.company_plant }}</td>
                  <td>
                    <div class="d-flex">
                      <el-popover trigger="click" placement="bottom" class="p-0">
                        <div class="text-center" v-if="!item.info">
                          <img width="50px"
                               src="/img/icons/loading_line.gif"/>
                        </div>
                        <div v-else style="white-space: pre-line">
                            {{item.info}}
                        </div>
                        <div slot="reference" @click.prevent="getInfo(item)">
                          <i style="font-size: 19px" class="pointer fa-solid fa-circle-info text-warning mr-2"></i>
                        </div>
                      </el-popover>
                      {{ item.number }}
                      <div
                        class="text-danger ml-1" style="font-size: 12px" v-if="item.status === 6">(CANCELADA)
                      </div>
                    </div>
                  </td>
                  <td class="text-center">{{ item.travel_volume }}</td>
                  <td class="">
                    <div class="d-flex " v-if="item.ref_number">
                      <el-popover trigger="click" placement="bottom" class="p-0">
                        <div class="text-center" v-if="!item.info">
                          <img width="50px"
                               src="/img/icons/loading_line.gif"/>
                        </div>
                        <div v-else style="white-space: pre-line">
                          {{item.info}}
                        </div>
                        <div slot="reference" @click.prevent="getRefInfo(item)">
                          <i style="font-size: 19px" class="pointer fa-solid fa-circle-info text-warning mr-2"></i>
                        </div>
                      </el-popover>
                      <div
                        class="text-danger ml-1" style="font-size: 12px" v-if="item.ref_status === 6">(CANCELADA)
                      </div>
                      {{item.ref_number}}
                    </div>
                    <div v-else>-</div>
                  </td>
                  <td class="text-center">{{ item.ref_volume || '-' }}</td>
                  <td :class="classLeftOver[`${item.category}${item.category_type}`]" class="text-center">
                    {{ item.reuse_volume }}
                  </td>
                  <td :class="classLeftOver[`${item.category}${item.category_type}`]" class="">
                    {{ categories[`${item.category}${item.category_type}`] }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <puzl-empty-data v-if="!$_items.data.length && !loadingSkeleton"/>
            <pagination v-show="$_items.data.length" @navegate="navegate" :source="$_items"
                        :loading="loadingSkeleton"/>

            <br>
            <div class="table-responsive">
              <table class="table table-sm table-bordered">
                <thead>
                <th colspan="7" class="text-centr"
                    style="font-weight: 900 !important;background: rgb(201 201 201 / 37%);color: white;"><h4
                  style="padding: 0px !important; margin-bottom: 0px; font-weight: 500 !important; font-size: 0.85rem;">
                  <i class="fa-solid fa-ban text-danger" style="font-size:  1.2rem;"></i> CANCELAMENTOS</h4></th>
                <tr class="fixed">
                  <th width="7%" class="text-center">Data</th>
                  <th>CENTRAL</th>
                  <th class="text-center">O.S</th>
                  <th class="text-center">Volume</th>
                  <th class="text-center">Motivo</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in 5" v-show="loadingSkeletonCanceleds">
                  <th colspan="7">
                    <skeleton-puzl type="button"/>
                  </th>
                </tr>
                <tr v-for="item in $_canceled_items.data"
                    v-show="!loadingSkeletonCanceleds && $_canceled_items.data.length">
                  <td class="d-flex">{{ item.date_at | parseDate('DD/MM/YYYY') }}
                    <el-popover trigger="hover" placement="bottom" class="p-0">
                      {{ item.user }}
                      <div slot="reference">
                        <img src="/img/icons/salesman.png" width="25px" height="25px">
                      </div>
                    </el-popover>
                  </td>
                  <td>{{ item.company_plant }}</td>
                  <td class="text-center">
                    <div class="d-flex">
                    <el-popover trigger="click" @click.prevent="getInfo(item)" placement="bottom" class="p-0">
                      <div class="text-center" v-if="!item.info">
                        <img width="50px"
                             src="/img/icons/loading_line.gif"/>
                      </div>
                      <div v-else style="white-space: pre-line">
                        {{item.info}}
                      </div>
                      <div slot="reference" @click.prevent="getInfo(item)">
                        <i style="font-size: 19px" class="pointer fa-solid fa-circle-info text-warning mr-2"></i>
                      </div>
                    </el-popover>
                    {{ item.number }}
                    </div>
                  </td>
                  <td class="text-center">{{ item.volume }}</td>
                  <td class="text-center">{{ item.reason_for_cancellation }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <puzl-empty-data
              v-show="!$_canceled_items.data.length && !loadingSkeletonCanceleds"/>
            <pagination v-show="$_canceled_items.data.length" @navegate="navegateCanceleds" :source="$_canceled_items"
                        :loading="loadingSkeletonCanceleds"/>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV2";
import InputDatePicker from "@/components/InputDatePicker";
import {mapGetters} from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Pagination from '@/components/Utils/Pagination'
import PuzlSelect from "@/components/PuzlSelect";
import {toFixed} from "@/plugins";
import {floatToHuman} from "@/helpers";
import SpinnerPuzl from "@/components/SpinnerPuzl.vue";

export default {
  name: "ListResume",
  components: {
    SpinnerPuzl,
    PuzlEmptyData,
    SkeletonPuzl,
    InputDatePicker,
    MultiFilter,
    Pagination,
    PuzlSelect,
  },
  data() {
    return {
      loadingSkeleton: false,
      loadingWidgets: false,
      loadingSkeletonCanceleds: false,
      floatToHuman: floatToHuman,
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
      categories: {
        11: "Reaproveitamento - Reuso",
        12: "Redirecionamento - Reuso",
        21: "Sobra de Concreto - descarte",
        22: "Perda de Concreto - descarte"
      },
      classLeftOver: {
        11: "text-success",
        12: "text-primary",
        21: "text-warning",
        22: "text-danger",
      },
      params: {
        page: 1,
        per_page: 15,
      },
      canceled_params: {
        page: 1,
        per_page: 15,
      },
      filter: {
        company_plants: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      $_widgets: 'reuseControl/getWidgets',
      $_items: 'reuseControl/fetch',
      $_canceled_items: 'reuseControl/fetchCanceleds',
      $_company_plants: "plant/activeItems",
    }),
  },
  mounted() {
    this.$store.dispatch("plant/fetchItemsActive");
    this.load()
  },
  methods: {
    /**
     * Busca informações da viagem relacionada
     */
    async getInfo(item) {
      const data = await this.$store.dispatch('travels/getInfo', item.id).then(data => {
        return data.data
      })
      item.info = data
    },
    /**
     * Busca informações da referencia relacionada
     */
    async getRefInfo(item) {
      const data = await this.$store.dispatch('travels/getInfo', item.ref_id).then(data => {
        return data.data
      })
      item.info = data
    },
    navegate(page) {
      this.params.page = page
      this.getItems()
    },
    navegateCanceleds(page) {
      this.canceled_params.page = page
      this.getCanceleds()
    },
    getWidgets() {
      this.loadingWidgets = true;
      this.$store.dispatch("reuseControl/getWidgets", {filter: this.filter})
        .then(() => {
          this.loadingWidgets = false
        })
    },
    load() {
      this.init()
    },
    setDefaultParams() {
      this.filter.range = this.range
      this.params.filter = this.filter;
    },
    getItems() {
      this.loadingSkeleton = true;
      this.params.filter = this.filter
      this.$store.dispatch("reuseControl/fetchItems", this.params)
        .then(() => {
          this.loadingSkeleton = false
        })
    },
    getCanceleds() {
      this.loadingSkeletonCanceleds = true;
      this.canceled_params.filter = this.filter
      this.$store.dispatch("reuseControl/getCanceleds", this.canceled_params)
        .then(() => {
          this.loadingSkeletonCanceleds = false
        })
    },
    init() {
      this.setDefaultParams()
      this.getWidgets()
      this.getItems()
      this.getCanceleds()
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.load({});
    },
  },
}
</script>
