<template>
  <div>
    <base-header class="bg-gray-content mb-3">
      <PuzlBreadcrumb />
    </base-header>
    <ListChargeResume />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ListChargeResume from "./Shared/_List";

export default {
  name: "Index",
  components: {
    PuzlBreadcrumb,
    ListChargeResume,
  },
};
</script>
