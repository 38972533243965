<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-3">
          <h2 style="font-size: 1.2rem !important" class="text-primary">
            <img height="30px" src="/img/icons/icons8/ios/physics.png" class="mr-2 mb-1" />
            Mapa de ponto de carga
          </h2>
        </div>
        <div class="col-md-9 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md px-1">
              <base-button type="success" block @click.prevent="handleCreateAdjustment">
                <img height="19" src="/img/icons/plus-math--v1-white.png" class="mr-1 mt-n1" />
                NOVO
              </base-button>
            </div>
            <base-dropdown class="mb-md-0 mb-2 col-md px-1">
              <base-button block slot="title-container" class="dropdown-toggle new-default-black text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/icons8/ios/vertical-settings-mixer--v1.png" class="mr-1 mt-n1" />
                Individual
              </base-button>
              <div>
                <router-link :to="{ path: '/technology/adjustment/type' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/test-tube.png" class="mr-1 mt-n1" /> Tipo
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link :to="{ path: '/technology/adjustment/level-responsibility' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/3-circle_danger.png" class="mr-1 mt-n1" /> Nível de aplicação
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link :to="{ path: '/technology/adjustment/final-slump' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/vlc_warning.png" class="mr-1 mt-n1" /> Slump final
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link :to="{ path: '/technology/adjustment/contract-hour' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/client-company_success.png" class="mr-1 mt-n1" /> Contrato e Horário
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link :to="{ path: '/technology/adjustment/charge-point' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/chemical-plant-black.png" class="mr-1 mt-n1" /> Ponto de carga
                  </a>
                </router-link>
              </div>
            </base-dropdown>
            <base-dropdown class="mb-md-0 mb-2 col-md px-1">
              <base-button block slot="title-container" class="dropdown-toggle new-default-black text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/icons8/ios/vertical-settings-mixer--v1.png" class="mr-1 mt-n1" />
                Geral
              </base-button>
              <div>
                <router-link :to="{ path: '/technology/adjustment/temperature' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/dew-point_danger.png" class="mr-1 mt-n1" /> Temperatura ambiente
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link :to="{ path: '/technology/adjustment/concrete-cycle' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/sync-settings_warning.png" class="mr-1 mt-n1" /> Ciclo do concreto
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link :to="{ path: '/technology/adjustment/concrete-piece' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/puzzle_black.png" class="mr-1 mt-n1" /> Peça a concretar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link :to="{ path: '/technology/adjustment/volume' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/scales--v1_primary.png" class="mr-1 mt-n1" /> Volume carregado
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                </router-link>
                <router-link :to="{ path: '/technology/adjustment/reuse' }">
                  <a class="dropdown-item new-default-black-font">
                    <img height="19" src="/img/icons/icons8/ios/recycle-sign_success.png" class="mr-1 mt-n1" /> Puzl Reuse
                  </a>
                </router-link>
              </div>
            </base-dropdown>
            <base-dropdown class="mb-md-0 mb-2 col-md px-1">
              <base-button
                block
                style="font-size: 0.8rem;height: 2.6rem"
                slot="title-container" class="text-uppercase dropdown-toggle" type="warning">
                <img src="/img/icons/graph-report.png" width="22px" height="22px">
                RELATÓRIOS
              </base-button>
              <div>
                <a href="#" @click.prevent="$refs.ModalReportAutomation.handleOpenModal()" class="new-default-black-font dropdown-item">
                  <img src="/img/icons/excel.png" class="pointer" style="width: 19px;">
                  INTEGRAÇÃO DE AUTOMAÇÃO
                </a>
              </div>
            </base-dropdown>
            <base-dropdown class="mb-md-0 mb-2 col-md px-1">
              <base-button block slot="title-container" type="primary" class="dropdown-toggle text-uppercase" style="font-size: 0.8rem">
                <img height="19" src="/img/icons/icons8/ios/fit-to-width.png" class="mr-1 mt-n1" />
                Limites
              </base-button>
              <div>
                <a class="dropdown-item new-default-black-font" @click="handleCreateLimitAdjustment">
                  <img height="19" src="/img/icons/icons8/ios/sandbox2_warning.png" class="mr-1 mt-n1" /> MCC
                </a>
                <div class="dropdown-divider p-0 m-0"></div>

                <a class="dropdown-item new-default-black-font" @click="handleCreateAllLimitsAdjustment">
                  <img height="19" src="/img/icons/icons8/ios/customize-view_danger.png" class="mr-1 mt-n1" /> Ajustes totais
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
      </div>
    </base-header>
    <ListCmcAdjustments v-bind:added="this.added" />
    <ModalCreate @createdCmcAdjustments="handleCreateAdjustmentList" ref="ModalCreateAdjustment" />
    <ModalLimitSettingCreate @updatedLimit="handleCreateAdjustmentList" ref="ModalLimitSettingCreate" />
    <ModalAllLimitsSettingCreate ref="ModalAllLimitsSettingCreate" />
    <ModalReportAutomation ref="ModalReportAutomation"/>
  </div>
</template>
<script>
import ListCmcAdjustments from "./Shared/_List";
import ModalCreate from "./Shared/_ModalCreate";
import ModalLimitSettingCreate from "./Shared/_ModalLimitSettingCreate";
import ModalAllLimitsSettingCreate from "./Shared/_ModalAllLimitsSettingCreate";
import ModalReportAutomation from './Shared/_ModalReportAutomation.vue'
export default {
  name: "IndexAdjustment",
  components: {
    ListCmcAdjustments,
    ModalCreate,
    ModalLimitSettingCreate,
    ModalAllLimitsSettingCreate,
    ModalReportAutomation
  },
  data() {
    return {
      added: false,
    };
  },
  methods: {
    handleCreateAdjustment() {
      this.$refs.ModalCreateAdjustment.handleCreateModal();
    },
    handleCreateAdjustmentList() {
      this.added = !this.added;
    },
    handleCreateLimitAdjustment() {
      this.$refs.ModalLimitSettingCreate.handleCreateModal();
    },
    handleCreateAllLimitsAdjustment() {
      this.$refs.ModalAllLimitsSettingCreate.handleCreateModal();
    },
  },
};
</script>

<style></style>
