<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ customerConstructionParams && customerConstructionParams.byCreateTemporaryCustomer ? 'NOVA PROPOSTA' : 'NOVO CONTRATO' }}
          </h6>
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ customerConstructionParams && customerConstructionParams.byCreateTemporaryCustomer ? 'NOVA PROPOSTA' : 'NOVO CONTRATO' }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row mb-3 mt-n3">
        <div class="col-md-2 mb-2">
          <router-link
            :to="{
              name: 'commercial.contract-proposal.index',
              path: '/commercial/contract'
            }"
          >
            <base-button block size="lg" type="light" class="text-uppercase">
              voltar
            </base-button>
          </router-link>
        </div>
        <div
          class="col-md-2 mb-2"
          v-if="
            !construction &&
            (customerConstructionParams && !customerConstructionParams.byCreateTemporaryCustomer)
          "
        >
          <base-button
            block size="lg"
            type="primary"
            class="text-uppercase"
            @click="openModalSearchEntityAndConstruction(customerConstructionParams)"
          >
            <img src="/img/icons/icons8/ios/search.png" height="22" class="mr-1"/>
            Procurar
          </base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >
        <card>
          <div
            v-if="customerConstructionParams && !loadingCustomerName"
            class="col-12 row align-items-center mb-4 mt-n1 card-body-molde"
          >
            <div :style="{ 'margin-bottom': construction && entity ? '-18px' : '0' }">
              <span class="h3">
                Cliente / obra:
              </span>
              <span v-if="entity" class="text-dark">
                {{ customerName }}
              </span>
              <span v-else class="text-dark">
                {{ customerConstructionParams.customer_name }}
              </span>
            </div>
          </div>
          <div v-if="construction && entity" class="row align-items-center mb-4 mt-n1 card-body-molde">
            <div class="col-12">
              <div>
                <span class="h3">Proposta / Contrato: </span>
                <span class="text-dark">{{ code }}</span>
              </div>
              <div>
                <span class="h3">Cliente: </span>
                <span class="text-dark">{{ entity && entity.entity_name }}</span>
              </div>
              <div>
                <span class="h3">Obra: </span>
                <span class="text-dark mr-2">{{ construction && construction.construction_name }}</span>
                <span
                  v-if="construction"
                  class="btn btn-sm btn-warning text-capitalize"
                  @click.prevent="handleListContacts()"
                >
                  <i class="fas fa-id-card-alt"></i> CONTATOS
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <!-- CARD CLIENTE -->
            <div
              class="col-12 col-md-6 mt-n3"
              v-if="!customerConstructionParams || !customerConstructionParams.byCreateTemporaryCustomer"
            >
              <div class="card p-4">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <span class="h3 new-default-black-font p-1 mb-0" style="font-size: 12px;">
                    <img height="30px" src="/img/icons/icons8/ios/contract-job-primary.png" class="mr-1"/>
                    Cliente
                  </span>
                  <span
                    style="width: 115px; margin-left: auto;"
                    class="btn btn-sm p-1 text-center float-right invert-color"
                    :class="totalEntityAttachments > 0 ? 'btn-outline-success' : 'btn-outline-dark'"
                    v-if="!loadingEntityAttachments"
                  >
                    <a
                      href="#"
                      @click.prevent="handleShowEntityAttachments(entity.id, entity.entity_name, entity.document)"
                    >
                      <img
                        :src="
                          totalEntityAttachments > 0
                            ? '/img/icons/paperclip-green.png'
                            : '/img/icons/paperclip.png'
                        "
                        class="invert-on-hover"
                        width="25px"
                      />
                      <span
                        :class="totalEntityAttachments > 0 ? 'text-success invert-color' : 'text-dark invert-color'"
                        class="invert-on-hover"
                        style="font-size: 12px;"
                      >
                        ANEXOS
                      </span>
                      <span
                        style="font-size: 12px;"
                        :class="totalEntityAttachments > 0 ? 'text-success invert-color' : 'text-dark invert-color'"
                        class="invert-on-hover"
                      >
                        {{ totalEntityAttachments }}
                      </span>
                    </a>
                  </span>
                  <span
                    style="width: 115px;"
                    class="btn btn-sm btn-outline-warning p-1 text-center float-right invert-color"
                    v-if="!loadingEntityAttachments && entity"
                    @click="openModalEditEntity(entity.uuid)"
                  >
                    <img height="25px" src="/img/icons/create-new.png" class="ml-2 invert-on-hover"/>
                    <span style="font-size: 12px;">EDITAR</span>
                  </span>
                  <span
                    style="width: 115px;"
                    class="btn btn-sm btn-outline-success p-1 text-center float-right invert-color"
                    v-if="!loadingEntityAttachments && !entity"
                    @click.prevent="openModalCreateEntity(construction)"
                  >
                    <img height="25px" src="/img/icons/icons8/ios/plus-math-success.png" class="invert-on-hover"/>
                    <span style="font-size: 12px;">CADASTRAR</span>
                  </span>
                  <span v-if="loadingEntityAttachments">
                    <SpinnerPuzl/>
                  </span>
                </div>
                <a
                  href="#"
                  class="text-right mt-2"
                  @click.prevent="isHideEntityCard = !isHideEntityCard"
                >
                  <span v-if="isHideEntityCard" class="text-dark" style="font-size: 11px;">
                    Expandir
                  </span>
                  <span v-else class="text-dark" style="font-size: 11px;">
                    Ocultar
                  </span>
                  <img height="17px" src="/img/icons/icons8/ios/arrow-up.png" class="mr-2"/>
                </a>
                <hr v-if="!isHideEntityCard" class="new-default-black mt-2 mb-2">
                <div v-if="!isHideEntityCard" class="row my-1 align-items-center">
                  <div class="col-12">
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Nome:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ entity && entity.entity_name }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        CPF:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ entity && entity.document }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Logradouro:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ entity && entity.address && entity.address.address + ', ' + entity.address.number }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Bairro:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ entity && entity.address && entity.address.district }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Município:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ entity && entity.address && entity.address.city }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        UF:
                      </label>
                      <div class="col-md-8">
                      <span class="form-control form-control-sm">
                        {{ entity && entity.address && entity.address.state }}
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CARD OBRA -->
            <div class="col-12 col-md-6 mt-n3">
              <div class="card p-4">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <span class="h3 new-default-black-font p-1 mb-0" style="font-size: 14px;">
                    <img height="30px" src="/img/icons/icons8/ios/brick-wall.png" class="mr-3"/>
                    Obra
                  </span>
                  <span
                    style="width: 115px; margin-left: auto;"
                    class="btn btn-sm p-1 text-center float-right invert-color"
                    :class="totalConstructionAttachments > 0 ? 'btn-outline-success' : 'btn-outline-dark'"
                    v-if="!loadingConstructionAttachments"
                  >
                    <a
                      href="#"
                      @click.prevent="handleShowConstructionAttachments(construction.id, construction.construction_name)"
                    >
                      <img
                        :src="
                          totalConstructionAttachments > 0
                            ? '/img/icons/paperclip-green.png'
                            : '/img/icons/paperclip.png'
                        "
                        class="invert-on-hover"
                        width="25px"
                      />
                      <span
                        :class="totalConstructionAttachments > 0 ? 'text-success invert-color' : 'text-dark invert-color'"
                        class="invert-on-hover"
                        style="font-size: 12px;"
                      >
                        ANEXOS
                      </span>
                      <span
                        style="font-size: 12px;"
                        :class="totalConstructionAttachments > 0 ? 'text-success invert-color' : 'text-dark invert-color'"
                        class="invert-on-hover"
                      >
                        {{ totalConstructionAttachments }}
                      </span>
                    </a>
                  </span>
                  <span
                    style="width: 115px;"
                    class="btn btn-sm btn-outline-warning p-1 text-center float-right invert-color"
                    v-if="!loadingConstructionAttachments && construction"
                    @click="openModalEditConstruction(construction.uuid)"
                  >
                    <img height="25px" src="/img/icons/create-new.png" class="ml-2 invert-on-hover"/>
                    <span style="font-size: 12px;">EDITAR</span>
                  </span>
                  <span
                    style="width: 115px;"
                    class="btn btn-sm btn-outline-success p-1 text-center float-right invert-color"
                    v-if="!loadingConstructionAttachments && !construction"
                    @click.prevent="handleShowModalCustomerConstruction(entity)"
                  >
                    <img height="25px" src="/img/icons/icons8/ios/plus-math-success.png" class="invert-on-hover"/>
                    <span style="font-size: 12px;">CADASTRAR</span>
                  </span>
                  <span v-if="loadingConstructionAttachments">
                    <SpinnerPuzl/>
                  </span>
                </div>
                <a
                  href="#"
                  class="text-right text-dark mt-2"
                  style="font-size: 11px;"
                  @click.prevent="isHideConstructionCard = !isHideConstructionCard"
                >
                  <span v-if="isHideEntityCard" class="text-dark" style="font-size: 11px;">
                    Expandir
                  </span>
                  <span v-else class="text-dark" style="font-size: 11px;">
                    Ocultar
                  </span>
                  <img height="17px" src="/img/icons/icons8/ios/arrow-up.png" class="mr-2"/>
                </a>
                <hr v-if="!isHideConstructionCard" class="new-default-black mt-2 mb-2">
                <div v-if="!isHideConstructionCard" class="row my-1 align-items-center">
                  <div class="col-12">
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Nome da construção:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ (construction && construction.construction_name) || "" }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Central:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ (construction && construction.plant.name) || "" }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Endereço:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{
                            (construction && construction.default_address.address + ', ' + construction.default_address.number) || ""
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Bairro:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ (construction && construction.default_address.district) || "" }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        Município:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ (construction && construction.default_address.city) || "" }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1">
                      <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                        UF:
                      </label>
                      <div class="col-md-8">
                        <span class="form-control form-control-sm">
                          {{ (construction && construction.default_address.state) || "" }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- CARD CONTRATO -->
          <div class="row mb-3 mt-n3" v-if="shouldDisplayDiv">
            <div class="col-12">
              <div class="card p-4">
                <div class="row">
                  <div class="col-md-8 show-md">
                    <div style="display: flex; align-items: center;">
                    <span class="mr-3 h3">
                      <img height="30px" src="/img/icons/icons8/ios/business.png" class="mr-2"/>
                      Contrato <span class="text-danger">&nbsp;*&nbsp;</span>
                    </span>
                      <span class="mt-n2" style="width: auto;">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="contract_proposal.contract_uuid"
                            :items="contracts"
                            customKey="uuid"
                            :loading="loadingContract"
                            :disabled="loadingContract"/>
                        </base-input>
                      </validation-provider>
                    </span>
                    </div>
                  </div>
                  <div class="col-md-12 show-mobile">
                    <div style="display: flex; align-items: center;">
                    <span class="mr-3 h3">
                      <img height="30px" src="/img/icons/icons8/ios/business.png" class="mr-2"/>
                      Contrato <span class="text-danger">&nbsp;*&nbsp;</span>
                    </span>
                    </div>
                  </div>
                  <div class="col-md-12 show-mobile mb-2">
                         <span class="mt-n2" style="width: auto;">
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <puzl-select
                            v-model="contract_proposal.contract_uuid"
                            :items="contracts"
                            customKey="uuid"
                            :loading="loadingContract"
                            :disabled="loadingContract"/>
                        </base-input>
                      </validation-provider>
                    </span>
                  </div>
                  <div class="col-md-4 mt-1 text-right">
                    <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
                      <div class="row p-1 pl-2">
                        <div class="col-6">
                          <div class="form-control-label">
                            REIDI
                          </div>
                        </div>
                        <div class="col-6" @click="validPermissionCanIncludeReidi">
                          <base-switch
                            :disabled="!$_financial_limit.invoice_can_include_reidi"
                            v-model="contract_proposal.reidi"
                            type="danger"
                            offText="NÃO"
                            onText="SIM"
                          ></base-switch>
                        </div>
                        <div class="col-12">
                          <div class="form-control-label">
                            INSC. ESTADUAL
                          </div>
                        </div>
                        <div class="col-12">
                          <input
                            v-model="contract_proposal.state_registration"
                            class="form-control form-control-sm"/>
                        </div>
                        <div class="col-6 mt-2">
                          <div class="form-control-label">
                            FIXAR CNPJ EMISSOR?
                          </div>
                        </div>
                        <div class="col-6 mt-2">
                          <base-switch
                            v-model="contract_proposal.issuer_fixed"
                            type="danger"
                            @input="validIssuerFixed"
                            offText="NÃO"
                            onText="SIM"
                          ></base-switch>
                        </div>
                        <div class="col-12">
                          <PuzlSelect
                            :disabled="!contract_proposal.issuer_fixed"
                            :label="'business_name'"
                            v-model="contract_proposal.company_plant_issuer_id_fixed"
                            :items="company_plant_issuers"
                          />
                        </div>
                      </div>
                      <base-button
                        slot="reference"
                        outline type="primary">
                        <i class="fa-solid fa-wand-sparkles"></i> REGRAS ESPECIAIS
                      </base-button>
                    </el-popover>
                  </div>
                </div>
                <hr class="new-default-black mt-2 mb-2">
                <div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <div class="col-md-4">
                      <div class="pb-1 col-form-label form-control-label">
                        Central
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.company_plant_id"
                            :items="plants"
                            @change="setISS()"
                            @input="getValidityPriceByPlant();contract_proposal.company_plant_issuer_id_fixed = null"
                            :loading="loadingPlant"
                            :disabled="loadingPlant"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <div class="pb-1 col-form-label form-control-label">
                        Vendedor
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.seller"
                            :items="users"
                            customKey="uuid"
                            :loading="loadingUsers"
                            :disabled="loadingUsers"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <div class="pb-1 col-form-label form-control-label">
                        Volume médio por BT
                        <span class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider rules="required" v-slot="{errors}">
                        <input-limit
                          :options="{
                            min: 0,
                            max: 49.9,
                            decimals: 1
                          }"
                          :start_value="contract_proposal.average_volume_per_bt"
                          v-model="contract_proposal.average_volume_per_bt"
                          @value="contract_proposal.average_volume_per_bt = $event"
                        >
                          <small class="input-group-sm p-0 m-0">
                            <span style="text-transform: none">m</span><sup>3</sup>
                          </small>
                        </input-limit>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <div class="pb-1 col-form-label form-control-label">
                        CNO
                        <span v-show="is_cno_required_prop_contracts" class="text-danger">&nbsp;*</span>
                      </div>
                      <validation-provider
                        :rules="is_cno_required_prop_contracts ? 'required|min:14' : 'min:14'"
                        v-slot="{errors}"
                      >
                        <base-input input-classes="form-control-sm">
                          <input
                            class="form-control form-control-sm"
                            maxlength="15"
                            v-mask="'##.###.#####/##'"
                            v-model="contract_proposal.cno"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <div class="pb-1 col-form-label form-control-label">
                        Cód. de obra da prefeitura
                        <span
                          v-if="plant_requires_municipal_building_code || is_municipal_work_code_required_prop_contracts"
                          class="text-danger"
                        >
                          &nbsp;*
                        </span>
                      </div>
                      <validation-provider
                        :rules="plant_requires_municipal_building_code || is_municipal_work_code_required_prop_contracts && 'required' || ''"
                      >
                        <base-input input-group-classes="input-group-sm">
                          <input class="form-control form-control-sm"
                                 v-model="contract_proposal.city_hall_construction_code"/>
                          </el-date-picker>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <div class="pb-1 col-form-label form-control-label">
                        Art/nº de controle do encapsulamento
                      </div>
                      <base-input input-group-classes="input-group-sm">
                        <input class="form-control form-control-sm" v-model="contract_proposal.art_code"/>
                        </el-date-picker>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mt-n3" v-if="shouldDisplayDiv">
            <!-- CARD FATURAMENTO -->
            <div class="col-12 col-md-6 mt-n3">
              <div class="card p-4">
                <div class="row">
                  <div class="col-md-6">
                    <div style="display: flex; align-items: center;">
                  <span class="mr-3 h3">
                    <img height="30px" src="/img/icons/check.png" class="mr-2"/>
                    Faturamento
                  </span>
                    </div>
                  </div>
                  <div class="col-md-6 show-md">
                    <div class="d-flex float-right">
                      <div class="form-control-label pr-2">
                        POR MEDIÇÃO
                      </div>
                      <div>
                        <base-switch
                          v-model="contract_proposal.measurement"
                          type="primary"
                          offText="NÃO"
                          onText="SIM"
                        ></base-switch>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 show-mobile">
                    <div class="d-flex">
                      <div class="form-control-label pr-2">
                        POR MEDIÇÃO
                      </div>
                      <div>
                        <base-switch
                          v-model="contract_proposal.measurement"
                          type="primary"
                          offText="NÃO"
                          onText="SIM"
                        ></base-switch>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="new-default-black mt-2 mb-2">
                <div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <div class="col-md-6 mb-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Métodos de pagamento
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.payment_methods_ids"
                            :items="payment_methods"
                            :multiple="true"
                            @input="setPaymentTermsByMethod()"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mb-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Condições de pagamento
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <validation-provider rules="required">
                        <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="contract_proposal.payment_terms_uuids"
                            :items="payment_terms_by_principal"
                            customKey="uuid"
                            :multiple="true"
                            @input="setMethods"
                            @change="setAll()"
                            :loading="loadingPaymentTerm"
                            :disabled="loadingPaymentTerm || !contract_proposal.payment_methods_ids.length"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mt-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Observações para nota fiscal de serviço
                        <span class="text-danger" v-if="entity && entity.is_nfse_observations_required">
                          &nbsp;*
                        </span>
                      </div>
                      <div class="mb-1" v-if="entity && entity.is_nfse_observations_required">
                        <a href="#" class="text-dark" @click.prevent="SetServiceInvoiceNotes()">
                          <b style="font-size: 12px"> Número de empenho: </b>
                          <span
                            class="badge mr-1 ml-1"
                            style="background-color: #ffff; border: 1px solid #ccc; padding: 4px; border-radius: 4px;"
                          >
                            <i style="font-size: 15px" class="zoom pointer fa-regular fa-clipboard text-dark"></i>
                            <span style="font-size: 10px" class="font-weight-500">
                              Preencher
                            </span>
                          </span>
                        </a>
                      </div>
                      <validation-provider :rules="entity && entity.is_nfse_observations_required ? 'required' : ''"
                                           v-slot="{errors}">
                        <base-input input-classes="form-control-sm">
                          <textarea v-model="contract_proposal.observation" maxlength="400" class="form-control"
                                    rows="2"/>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mt-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Orientações para o faturamento
                      </div>
                      <base-input input-classes="form-control-sm">
                        <textarea v-model="contract_proposal.billing_guidelines" maxlength="250" class="form-control"
                                  rows="2"/>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CARD AUTOMATIZAÇÕES -->
            <div class="col-12 col-md-6 mt-n3">
              <div class="card p-4">
                <div style="display: flex; align-items: center;">
                  <span class="mr-3 h3">
                    <img height="30px" src="/img/icons/icons8/ios/bot.png" class="mr-2"/>
                    Automatizações
                  </span>
                </div>
                <hr class="new-default-black mt-2 mb-2">
                <section v-if="construction && contract_proposal.payment_terms_uuids.length">
                  <div class="form-group row m-0 p-0 mb-1">
                    <div class="col-md-3 mt-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        NF-e
                      </div>
                      <base-input>
                        <base-switch
                          @input="setNfse"
                          v-model="contract_proposal.automatic_nfe"/>
                      </base-input>
                    </div>
                    <div class="col-md-3 mt-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        NFS-e
                      </div>
                      <base-input>
                        <base-switch
                          @input="unsetIssRetain()"
                          v-model="contract_proposal.automatic_nfse"/>
                      </base-input>
                    </div>
                    <div class="col-md-3 mt-2">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Faturamento
                      </div>
                      <base-input>
                        <base-switch
                          :disabled="loadingGeneralSettings"
                          @input="setAll()"
                          v-model="contract_proposal.automatic_billing"/>
                      </base-input>
                    </div>
                    <div class="col-md-3 mt-2" v-if="contract_proposal.automatic_billing">
                      <div class="pt-0 pb-0 col-form-label form-control-label">
                        Boleto
                      </div>
                      <base-input>
                        <base-switch
                          @input="setTicket(contract_proposal.billing.automatic_ticket)"
                          v-model="contract_proposal.billing.automatic_ticket"
                          :disabled="!contract_proposal.automatic_billing"/>
                      </base-input>
                    </div>
                    <div class="row" v-if="contract_proposal.automatic_billing">
                      <label class="col-md-6 pb-0 pt-1 mt-3 col-form-label form-control-label">
                        Conta bancária:
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </label>
                      <div class="col-md-6 mt-3">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <PuzlSelect
                              v-model="contract_proposal.billing.bank_account_id"
                              :items="$_bank_accounts"
                              :labelMask="true"/>
                          </base-input>
                        </validation-provider>
                      </div>
                      <label class="col-md-6 pb-0 pt-1 mt-3 col-form-label form-control-label">
                        Método de pagamento:
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </label>
                      <div class="col-md-6 mt-3">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <PuzlSelect
                              v-model="contract_proposal.billing.payment_method_id"
                              :items="Payment_methods"
                              @input="getPaymentTerms()"
                              @change="getPaymentIntermediaries()"
                              :disabled="loadingMethod || contract_proposal.billing.automatic_ticket || !contract_proposal.payment_terms_uuids.length"/>
                          </base-input>
                        </validation-provider>
                      </div>

                      <label class="col-md-6 pb-0 pt-1 mt-3 col-form-label form-control-label">
                        Condição de pagamento:
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </label>
                      <div class="col-md-6 mt-3">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <PuzlSelect
                              v-model="contract_proposal.billing.payment_term_id"
                              :items="filteredPaymentTermsByMethod"
                              @input="setRange()"
                              :disabled="loadingPaymentTerm || !contract_proposal.billing.payment_method_id"/>
                          </base-input>
                        </validation-provider>
                      </div>
                      <label class="col-md-6 pb-0 pt-1 mt-3 col-form-label form-control-label">
                        Frequência de faturamento:
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </label>
                      <div class="col-md-6 px-1 mt-3">
                        <div class="card" style="width: 250px !important;">
                          <div class="card-body">
                            <div class="row mb-2">
                              <div class="col-4 p-1">
                                <button type="button"
                                        @click.prevent="contract_proposal.billing.range = 1;frequency_selecteds = []"
                                        :class="contract_proposal.billing.range === 1 ? 'btn-primary-active' : 'new-default-black-font'"
                                        class="btn base-button fs-11 new-btn-light  btn-block btn-light btn-sm"
                                >DIÁRIO
                                </button>
                              </div>
                              <div class="col-4 p-1">
                                <button type="button"
                                        @click.prevent="contract_proposal.billing.range = 2;frequency_selecteds = []"
                                        :class="contract_proposal.billing.range === 2 ? 'btn-primary-active' : 'new-default-black-font'"
                                        class="btn base-button fs-11 new-btn-light  btn-block btn-light btn-sm"
                                >SEMANA
                                </button>
                              </div>
                              <div class="col-4 p-1">
                                <button type="button"
                                        @click.prevent="contract_proposal.billing.range = 3;frequency_selecteds = []"
                                        :class="contract_proposal.billing.range === 3 ? 'btn-primary-active' : 'new-default-black-font'"
                                        class="btn base-button fs-11 new-btn-light  btn-block btn-light btn-sm"
                                >MÊS
                                </button>
                              </div>
                            </div>
                            <!--                              <template v-if="contract_proposal.billing.range === 1">-->
                            <!--                                <div class="row">-->
                            <!--                                  <div class="col-12 text-center">-->
                            <!--                                    <img data-v-42102372=""-->
                            <!--                                         src="https://uxwing.com/wp-content/themes/uxwing/download/time-and-date/checkmark-date-calendar-icon.png"-->
                            <!--                                         style="width: 35px;opacity: 55%;margin-top: 10px;">-->
                            <!--                                  </div>-->
                            <!--                                </div>-->
                            <!--                              </template>-->
                            <template v-if="contract_proposal.billing.range === 2">
                              <table class="table table-sm">
                                <thead>
                                <tr>
                                  <th v-for="item in weekDays">
                                    <span @click.prevent="setFrequency(item.id)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item.id) && 'frequency-selected'">{{
                                        item.name
                                      }}</span>
                                  </th>
                                </tr>
                                </thead>
                              </table>
                            </template>
                            <template v-if="contract_proposal.billing.range === 3">
                              <table>
                                <thead>
                                <tr>
                                  <th v-for="item in generateRange(1, 7)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <th v-for="item in generateRange(8, 14)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                <tr>
                                  <th v-for="item in generateRange(15, 21)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                <tr>
                                  <th v-for="item in generateRange(22, 28)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                <tr>
                                  <th v-for="item in generateRange(29, 31)">
                                    <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                        item
                                      }}</span>
                                  </th>
                                </tr>
                                </tbody>
                              </table>
                            </template>
                          </div>
                        </div>
                        <!--                        <table class="table">-->
                        <!--                          <tr>-->
                        <!--                            <th style="padding: 1px;border: none" v-for="item in generateRange(1, 7)"-->
                        <!--                                class="pointer">-->
                        <!--                                  <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                        :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                      item-->
                        <!--                                    }}</span>-->
                        <!--                            </th>-->
                        <!--                            <tr>-->
                        <!--                              <th style="padding: 1px;border: none" v-for="item in generateRange(8, 14)"-->
                        <!--                                  class="pointer">-->
                        <!--                                    <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                        item-->
                        <!--                                      }}</span>-->
                        <!--                              </th>-->
                        <!--                            </tr>-->
                        <!--                            <tr>-->
                        <!--                              <th style="padding: 1px;border: none" v-for="item in generateRange(15, 21)"-->
                        <!--                                  class="pointer">-->
                        <!--                                    <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                        item-->
                        <!--                                      }}</span>-->
                        <!--                              </th>-->
                        <!--                            </tr>-->
                        <!--                            <tr>-->
                        <!--                              <th style="padding: 1px;border: none" v-for="item in generateRange(22, 28)"-->
                        <!--                                  class="pointer">-->
                        <!--                                    <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                        item-->
                        <!--                                      }}</span>-->
                        <!--                              </th>-->
                        <!--                            </tr>-->
                        <!--                            <tr>-->
                        <!--                              <th style="padding: 1px;border: none" v-for="item in generateRange(29, 31)"-->
                        <!--                                  class="pointer">-->
                        <!--                                    <span @click.prevent="setFrequency(item)" class="badge frequency"-->
                        <!--                                          :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{-->
                        <!--                                        item-->
                        <!--                                      }}</span>-->
                        <!--                              </th>-->
                        <!--                            </tr>-->
                        <!--                        </table>-->
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <!-- BOTÕES SALVAR E CANCELAR -->
          <div class="row text-right">
            <div class="col-md-6"/>
            <div class="col-md-3 p-1">
              <router-link
                :to="{
                  name: 'commercial.contract-proposal.index',
                  path: '/commercial/contract'
                }"
              >
                <base-button block type="danger" class="text-capitalize">
                  <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2"/>
                  cancelar
                </base-button>
              </router-link>
            </div>
            <div class="col-md-3 p-1">
              <base-button
                block @click.native="handleSaveAndActive"
                type="success"
                native-type="submit"
                :disabled="invalid || ($route.query.fromContractScreen == 'true' && (!construction || !entity))"
                :loading="loadingSave"
              >
                <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2"/>
                Salvar e ativar
              </base-button>
            </div>
          </div>
        </card>
      </validation-observer>
    </div>
    <ModalChangeStatus ref="changeStatus"/>
    <ModalSearchCpfCnpj ref="searchCpfCnpj" @set_entity="setEntity"/>
    <ModalListContacts @updateConstruction="updateConstruction" ref="listContacts"/>
    <ModalStoreContacts ref="storeContact"/>
    <ModalCreateEntity
      @set_entity="setEntity"
      @set_entity_name="updateCustomerNameAfterEntityRegistration"
      ref="createEntity"
    />
    <ModalCreateCustomerConstruction
      @set_construction="setConstruction"
      ref="CreateCustomerConstruction"
      :entityProps="entityProps"
    />
    <ModalConstructionAttachmentList
      ref="modalConstructionAttachmentList"
      @updatedTotalAttachmentAfterUpload="updatedTotalConstructionAttachmentAfterUpload"
      @updateTotalAttachmentAfterDeletion="updateTotalConstructionAttachmentAfterDeletion"/>
    <ModalEntityAttachmentList
      ref="modalEntityAttachmentList"
      @storedAttachment="updatedTotalEntityAttachmentAfterUpload"
      @deletedLastAttachment="updateTotalEntityAttachmentAfterDeletion"/>
    <ModalEditEntity @updatedEntity="updateEntityAfterEdit" ref="modalEditEntity"/>
    <ModalEditConstruction @updatedConstruction="updateConstructionAfterEdit" ref="modalEditConstruction"/>
    <ModalSearchCustomerConstruction @setConstruction="setConstruction" ref="modalSearchCustomerConstruction"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import ModalListContacts from "../../../Operational/Schedule/Weighing/Shared/_ModalListContacts"
import ModalChangeStatus from "./_ModalChangeStatus";
import ModalSearchCpfCnpj from "./_ModalSearchCpfCnpj";
import PuzlSelect from "@/components/PuzlSelect";
import {onlyNumbers} from "@/helpers";
import inputLimit from "@/components/Utils/InputLimit";
import ModalStoreContacts from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Contact/Shared/_Create";
import {construction as $_totvs} from "@/plugins/microservices/totvs";
import ModalConstructionAttachmentList
  from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_ModalConstructionAttachmentList";
import ModalEntityAttachmentList from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityAttachmentList";
import ModalCreateEntity from "@/views/Modules/Configuration/Entity/Shared/_Create";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import ModalCreateCustomerConstruction from './_ModalCreateCustomerConstruction';
import ModalEditConstruction from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_Edit";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import ModalSearchCustomerConstruction from './_ModalSearchCustomerConstruction';
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";

export default {
  name: "CreateContractProposal",
  components: {
    ModalChangeStatus,
    ModalListContacts,
    ModalSearchCpfCnpj,
    ModalCreateEntity,
    ModalCreateCustomerConstruction,
    PuzlSelect,
    inputLimit,
    ModalStoreContacts,
    ModalConstructionAttachmentList,
    ModalEntityAttachmentList,
    ModalEditEntity,
    ModalEditConstruction,
    SpinnerPuzl,
    ModalSearchCustomerConstruction,
  },
  data() {
    return {
      block_iss: false,
      loadingPlant: true,
      loadingConstruction: false,
      loadingContract: true,
      payment_intermediaries_exists: false,
      payment_terms_by_method: [],
      loadingPaymentTerm: true,
      loadingUsers: true,
      loadingCode: true,
      loadingSave: false,
      Payment_methods: [],
      loadingCompanyPlantIssuer: false,
      loadingPaymentIntermediaries: false,
      invalid: false,
      loadingMethod: false,
      construction: null,
      range_frequency_options: [
        {
          id: 1,
          name: 'Diariamente'
        },
        {
          id: 2,
          name: 'Semanalmente'
        },
        {
          id: 3,
          name: 'Mensalmente'
        },
        {
          id: 4,
          name: 'Personalizado'
        },
      ],
      entity: null,
      entityProps: null,
      entity_is_company: false,
      without_fields: true,
      loadingGeneralSettings: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      payment_terms_by_principal: [],
      frequency_selecteds: [],
      weekDays: [
        {
          id: 0,
          name: 'DOM',
        },
        {
          id: 1,
          name: 'SEG',
        },
        {
          id: 2,
          name: 'TER',
        },
        {
          id: 3,
          name: 'QUA',
        },
        {
          id: 4,
          name: 'QUI',
        },
        {
          id: 5,
          name: 'SEX',
        },
        {
          id: 5,
          name: 'SAB',
        },
      ],
      payment_intemerdiaries_selected: [],
      contract_proposal: {
        company_plant_id: null,
        company_plant_issuer_id_fixed: null,
        issuer_fixed: false,
        price_validity: null,
        measurement: 0,
        customer_construction_uuid: null,
        seller: '',
        reidi: false,
        state_registration: null,
        observation: '',
        billing_guidelines: '',
        billing: {
          automatic_nfe: false,
          automatic_nfse: false,
          automatic_ticket: false,
          range: '',
          bank_account_id: '',
          emails: '',
          company_plant_id: '',
          payment_term_id: '',
          payment_method_id: '',
          payment_intermediary_id: '',
          company_plant_issuer_id: '',
        },
        entity_uuid: null,
        entity_id: null,
        average_volume_per_bt: '8,0',
        contract_uuid: null,
        payment_terms_uuids: [],
        payment_methods_ids: [],
        status: 2,
        code: null,
        automatic_billing: false,
        automatic_nfe: true,
        automatic_nfse: true,
        iss_retain: false,
        city_hall_construction_code: '',
        art_code: '',
      },
      disablePriceValidity: false,
      plant_requires_municipal_building_code: null,
      is_cno_required_prop_contracts: false,
      is_municipal_work_code_required_prop_contracts: false,
      totalConstructionAttachments: null,
      totalEntityAttachments: null,
      loadingConstructionAttachments: true,
      loadingEntityAttachments: true,
      isRequiredConstructionAttachment: false,
      isHideEntityCard: false,
      isHideConstructionCard: false,
      customerConstructionParams: null,
      constructionParams: null,
      customerName: null,
      loadingCustomerName: false,
      ContractProposalEnum: ContractProposalEnum,
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      company_plant_issuers: "plant/getCompanyPlantIssuers",
      code: "contractProposal/getMaxCode",
      price_validity: "contractProposal/getPriceValidity",
      $_bank_accounts: 'bankAccount/fetch',
      users: "user/fetch",
      contracts: 'contractContracts/fetch',
      payment_terms: "paymentTerm/fetch",
      payment_methods: "paymentMethod/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      $_financial_limit: "permissionFinancialLimit/show",
    }),
    filteredPaymentTermsByMethod() {
      return this.payment_terms_by_method.filter((item) => this.contract_proposal.payment_terms_uuids.includes(item.uuid));
    },
    /**
     * Ao realizar cadastro com cliente/obra temporário, apenas os dados da obra devem ser preenchidos.
     * Caso for realizar o contrato completo, dados de obra e tomador devem ser preeenchidos,
     * para liberar o restante do formulário.
     */
    shouldDisplayDiv() {
      // (query params)
      if (this.customerConstructionParams) {
        if (this.customerConstructionParams.byCreateTemporaryCustomer) {
          return this.construction;
        } else {
          return this.entity && this.construction;
        }
      } else {
        return this.entity && this.construction;
      }
    },
  },
  watch: {
    'contract_proposal.company_plant_id': function () {
      this.getCompanyPlantIssuers()
      this.checksIfRequiresCityHallCodeInCentral();
    },
  },
  methods: {
    generateRange(start, end) {
      return Array.from({length: (end - start + 1)}, (_, index) => start + index);
    },
    setFrequency(item) {
      if (this.frequency_selecteds.includes(item)) {
        const index = this.frequency_selecteds.indexOf(item);
        if (index !== -1) {
          this.frequency_selecteds.splice(index, 1);
        }
      } else {
        this.frequency_selecteds.push(item)
      }
    },
    validIssuerFixed() {
      if (! this.contract_proposal.issuer_fixed) {
        this.contract_proposal.company_plant_issuer_id_fixed = null;
      } else {
        const default_plant_issuer = this.company_plant_issuers.find((item) => item.default);
        if (default_plant_issuer) {
          return this.contract_proposal.company_plant_issuer_id_fixed = default_plant_issuer.id
        }
      }
    },
    validPermissionCanIncludeReidi() {
      if (!this.$_financial_limit.invoice_can_include_reidi) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para realizar alteração'
        });
      }
    },
    setAll() {
      if (!this.contract_proposal.automatic_billing) {
        return
      }
      const companyPlant = this.plants.find(item => item.id === this.contract_proposal.company_plant_id)
      if (companyPlant) {
        this.contract_proposal.billing.bank_account_id = companyPlant.bank_account_id
      }
      if (this.Payment_methods.length === 1) {
        this.contract_proposal.billing.payment_method_id = this.Payment_methods[0].id
        this.getPaymentTerms()
        this.getPaymentIntermediaries()
      }
    },
    unsetIssRetain() {
      if (!this.contract_proposal.automatic_nfse) {
        this.contract_proposal.iss_retain = false
      }
    },
    rangeOffTypes(range_off, type) {
      if (range_off > 1) {
        if (type === 0) {
          return range_off + ' dias'
        } else {
          return range_off + ' horas'
        }
      }
      if (type === 0) {
        return range_off + ' dia'
      } else {
        return range_off + ' hora'
      }
    },
    setISS() {
      const plant = this.plants.find((item) => item.id === this.contract_proposal.company_plant_id);
      if (plant) {
        this.checkPlantGeneralSettings(plant.uuid);
        const default_plant_issuer = plant.company_issuers.find((item) => item.default);
        if (!default_plant_issuer || !this.entity) {
          return this.contract_proposal.iss_retain = false
        }
        this.$store.dispatch("FeesAndTaxes/getActiveRetainedRatesBetweenEntityAndIssuer", {
          entityId: this.entity.id, issuerId: default_plant_issuer.id
        }).then(response => {
          if (!this.block_iss) {
            if (!response.data.length) {
              this.contract_proposal.iss_retain = false
            } else {
              const has_issqn = response.data.some((item) => item.name = 'ISSQN')
              if (has_issqn) {
                this.contract_proposal.iss_retain = true
              }
            }
          }
        })
      }
    },
    setRange() {
      const term = this.payment_terms_by_method.find((item) => item.id === this.contract_proposal.billing.payment_term_id)
      this.range_frequency_options[3] = {
        id: 4,
        name: this.rangeOffTypes(term.range_off, term.range_off_type)
      }
      this.contract_proposal.billing.range = term.range
      this.contract_proposal.billing.range_off = term.range_off
      this.contract_proposal.billing.range_off_type = term.range_off_type
    },
    setPaymentTermsByMethod() {
      this.contract_proposal.billing.range = null
      this.contract_proposal.payment_terms_uuids = []
      this.contract_proposal.billing.payment_intermediary_id = null
      let payment_terms = []
      if (this.entity_is_company) {
        payment_terms = this.payment_terms.filter((item) => item.legal_person)
      } else {
        payment_terms = this.payment_terms.filter((item) => item.physical_person)
      }
      this.payment_terms_by_principal = payment_terms.filter((item) => {
        return item.allow_in_proposal && item.payment_methods.some(r => this.contract_proposal.payment_methods_ids.includes(r.id))
      })
    },
    setMethods() {
      let Payment_methods = []
      for (var i of this.contract_proposal.payment_terms_uuids) {
        const payment_methods = this.payment_terms.find(item => item.uuid === i).payment_methods
        Payment_methods = [...Payment_methods, ...payment_methods]
      }
      const objectMap = Payment_methods.reduce((map, object) => {
        map.set(object.id, object);
        return map
      }, new Map())
      this.Payment_methods = Array.from(objectMap, ([_, value]) => value)
      this.contract_proposal.billing.payment_method_id = null
      this.Payment_methods = this.Payment_methods.filter((item) => this.contract_proposal.payment_methods_ids.includes(item.id))
      this.getPaymentTerms()
    },
    setNfse() {
      if (!this.contract_proposal.automatic_nfe) {
        this.contract_proposal.automatic_nfse = false
        this.unsetIssRetain()
      }
    },
    updateConstruction() {
      this.loadingConstruction = true
      this.$store.dispatch('customerConstructionConstructions/show', this.construction.uuid).then(response => {
        this.construction.contact = response.data.contact
        this.loadingConstruction = false
      })
    },
    formatAccountLabel(item) {
      return item.name
    },
    getPaymentIntermediaries() {
      this.contract_proposal.billing.payment_intermediary_id = null
      this.payment_intemerdiaries_selected = []
    },
    getPaymentTerms() {
      this.contract_proposal.billing.range = null
      this.contract_proposal.billing.payment_term_id = null
      let paymentMethod = this.payment_methods.find(item => item.id === this.contract_proposal.billing.payment_method_id)
      if (!paymentMethod) {
        return
      }
      this.payment_terms_by_method = this.payment_terms.filter(function (item) {
        let has_method = item.payment_methods.find(method => method.uuid === paymentMethod.uuid)
        if (has_method) {
          return item
        }
      })
      if (this.filteredPaymentTermsByMethod.length === 1 && this.contract_proposal.automatic_billing) {
        this.contract_proposal.billing.payment_term_id = this.filteredPaymentTermsByMethod[0].id
        this.setRange()
      }
    },
    handleListContacts() {
      this.$refs.listContacts.openModal(this.construction.uuid);
    },
    handleStoreContacts(construction_uuid) {
      this.$refs.storeContact.handleCreateModal(construction_uuid);
    },
    getCompanyPlantIssuers() {
      this.loadingCompanyPlantIssuer = true
      this.$store.dispatch('plant/fetchCompanyPlantIssuersByPlant', {
        id: this.contract_proposal.company_plant_id
      }).then(response => {
        const default_plant_issuer = response.data.find(item => item.default)
        if (default_plant_issuer && this.contract_proposal.issuer_fixed) {
          this.contract_proposal.company_plant_issuer_id_fixed = default_plant_issuer.id
        }
        this.loadingCompanyPlantIssuer = false
      }).catch(error => {
        this.loadingCompanyPlantIssuer = false
      })
    },
    /**
     * @param {object} entity
     */
    handleShowModalCustomerConstruction(entity) {
      let address_construction = entity && entity.address || null;

      this.$refs.CreateCustomerConstruction.openModal(
        address_construction,
        this.$route.query.customerConstruction ? JSON.parse(this.$route.query.customerConstruction) : null,
      );
    },
    handleSearchCpfCnpj() {
      this.$refs.searchCpfCnpj.handleSearchCpfCnpj();
    },
    handleChangeStatus(contractProposalUuid, status) {
      this.$refs.changeStatus.handleChangeStatus(contractProposalUuid, status);
    },
    /**
     * @param {object} construction
     */
    setConstruction(construction) {
      this.contract_proposal.billing.emails = []
      this.construction = construction;
      this.isHideConstructionCard = false;
      this.contract_proposal.customer_construction_uuid = construction.uuid;
      this.updateConstruction()
      this.$store.dispatch("entity/getEntityByDocument", {document: construction.customer_construction.document})
        .then((response) => {
          this.setEntity(response.data);
        });

      //Carrega total de anexos da obra selecionada associada ao contrato.
      this.loadingConstructionAttachments = true;
      this.$store.dispatch("constructionAttachment/getTotalAttachmentByConstructionId", this.construction.id)
        .then((response) => {
          this.totalConstructionAttachments = response.data.total_construction_attachments;
          this.loadingConstructionAttachments = false;
        });
      this.$refs.modalSearchCustomerConstruction.closeModal();

      /**
       * Após realizar o cadastro, verifica se a cidade da obra salva é igual
       * a cidade do endereço padrão do emissor de central (cnpj)
       */
      this.$store.dispatch('companyPlantIssuer/getIssuerCityByCompanyPlantId', construction.plant.id)
        .then(response => {
          if (response.data.company_plant_issuer_addres_city === construction.default_address.city) {
            /**
             * Se regra específica em tela (configuração de nfs-e - migrate):
             * 'informar código da obra' estiver ativo.
             */
            if (response.data.nfse_load_city_hall_construction_code) {
              /**
               * Preenche com o 'Cód. de obra da prefeitura' com o
               * 'Código padrão quando município da obra igual do emissor'
               */
              this.contract_proposal.city_hall_construction_code =
                response.data.standard_code_for_matching_municipalities;
            }
          } else {
            this.contract_proposal.city_hall_construction_code = '';
          }
        });
    },
    handleSave() {
      this.contract_proposal.status = 4
      this.loadingSave = true
      this.$Progress.start()
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let payment_terms = []
      for (let uuid of this.contract_proposal.payment_terms_uuids) {
        const item = this.payment_terms.find((item) => item.uuid === uuid)
        payment_terms.push(item)
      }
      this.contract_proposal.necessary_approvation = payment_terms.some((item) => item.financial_approval === true)
      this.$store.dispatch("contractProposal/add", this.contract_proposal).then(response => {
        if (this.entity) {
          $_totvs.store({...this.construction, ...{entity_id: this.entity.id}});
        }
        this.loadingSave = false
        this.$Progress.finish()
        this.$notify({type: response.error_type, message: response.message})
        this.$router.push('/commercial/contract-proposal/formulation/create/' + response.data.uuid)
      }).catch(error => {
        if (error.response && error.response.status && error.response.status === 422) {
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({type: 'danger', message: message})
        } else {
          this.$notify({type: error.data.error_type, message: error.data.message})
        }
        this.$Progress.finish()
        this.loadingSave = false
      })
    },
    handleSaveAndActive() {
      if (this.contract_proposal.billing.range && this.contract_proposal.billing.range !== 1 && !this.frequency_selecteds.length) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Informe a frequência de faturamento para continuar',
        });
      }
      if (this.entity) {
        if (this.customerConstructionParams && this.customerConstructionParams.document !== this.entity.document) {
          this.$notify({
            type: 'warning',
            message: 'Os documentos fornecidos para tomador e cliente/obra não são iguais. Por favor, verifique e tente novamente.”'
          });
          return;
        }

        if (this.constructionParams && this.constructionParams.customer_construction_document !== this.entity.document) {
          this.$notify({
            type: 'warning',
            message: 'Os documentos fornecidos para tomador e cliente/obra não são iguais. Por favor, verifique e tente novamente.”'
          });
          return;
        }
      }

      if (this.isRequiredConstructionAttachment && this.totalConstructionAttachments === 0) {
        this.$notify({
          type: 'warning',
          message: 'Anexe pelo menos um arquivo ao contrato/obra.'
        });
        return;
      }

      if (
        this.entity && (
          !this.entity.address ||
          !this.entity.address.postal_code ||
          !this.entity.address.state ||
          !this.entity.address.city ||
          !this.entity.address.address ||
          !this.entity.address.number ||
          !this.entity.address.district
        )
      ) {
        this.$notify({
          type: 'warning',
          message: 'Cadastre endereço para o tomador.'
        });
        return;
      }

      if (this.customerConstructionParams && this.customerConstructionParams.byCreateTemporaryCustomer) {
        this.contract_proposal.status = ContractProposalEnum.PROPOSAL;
      } else {
        this.contract_proposal.status = ContractProposalEnum.ACTIVE;
      }

      this.loadingSave = true
      this.$Progress.start()
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let payment_terms = []
      for (let uuid of this.contract_proposal.payment_terms_uuids) {
        const item = this.payment_terms.find((item) => item.uuid === uuid)
        payment_terms.push(item)
      }
      this.contract_proposal.necessary_approvation = payment_terms.some((item) => item.financial_approval === true)
      this.contract_proposal.invoice_frequency = {...this.frequency_selecteds}
      this.$store.dispatch("contractProposal/add", this.contract_proposal).then(response => {
        if (this.entity) {
          $_totvs.store({...this.construction, ...{entity_id: this.entity.id}});
        }
        this.loadingSave = false
        this.$Progress.finish()
        this.$notify({type: response.error_type, message: response.message})
        this.$router.push('/commercial/contract-proposal/formulation/create/' + response.data.uuid)
      }).catch(error => {
        if (error.response && error.response.status && error.response.status === 422) {
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({type: 'danger', message: message})
        } else {
          this.$notify({type: error.data.error_type, message: error.data.message})
        }
        this.$Progress.finish()
        this.loadingSave = false
      })
    },
    /**
     * @param {object} entity
     */
    setEntity(entity) {
      this.entity = entity;
      if (this.entity) {
        this.isHideEntityCard = false;
        const document_length = onlyNumbers(entity.document).length
        if (document_length <= 11) {
          this.contract_proposal.iss_retain = false
          this.block_iss = true
        } else {
          this.block_iss = false
        }
        this.contract_proposal.entity_uuid = entity.uuid;
        this.contract_proposal.entity_id = entity.id;
        this.$store.dispatch("customerConstruction/getCustomerByDocument", {document: entity.document}).then((response) => {
          if (this.entityProps) {
            this.entityProps = response.data;
          } else {
            this.entityProps = entity;
          }
        });

        //Carrega total de anexos de clientes/fornecedores, conforme a obra selecionada associada ao contrato.
        this.loadingEntityAttachments = true;
        this.$store.dispatch("entity/getTotalAttachmentsByEntityId", this.entity.id)
          .then((response) => {
            this.totalEntityAttachments = response.data.total_entity_attachments;
            this.loadingEntityAttachments = false;
          });
      }
    },
    SetServiceInvoiceNotes() {
      this.contract_proposal.observation = this.entity.default_text;
    },
    /**
     * Atualiza o nome do cliente de uma construção após o cadastro de um tomador,
     * caso o CPF/CNPJ do tomador já esteja cadastrado em cliente/obra.
     * @param {string} entityName
     */
    updateCustomerNameAfterEntityRegistration(entityName) {
      if (this.construction) {
        this.construction.customer_construction.customer_name = entityName;
      }
      if (this.$route.query.customerConstruction) {
        this.customerName = entityName;
      }
    },
    setTicket(val) {
      if (val) {
        this.contract_proposal.billing.payment_method_id = 5
        this.getPaymentTerms()
      }
    },
    getValidityPriceByPlant() {
      if (this.contract_proposal.company_plant_id) {
        this.$Progress.start()
        this.disablePriceValidity = true
        this.$store.dispatch("contractProposal/getPriceValidityByPlantId", this.contract_proposal.company_plant_id).then(response => {
          this.$Progress.finish()
          this.disablePriceValidity = false
          if (!this.contract_proposal.price_validity) {
            this.contract_proposal.price_validity = this.price_validity
          }
        })
      }
    },
    /**
     * Verifica as configurações gerais da central selecionada para deixar os campos
     * 'cno' e 'Cód. de obra da prefeitura' obrigatórios.
     * @param {string} company_plant_uuid
     */
    checkPlantGeneralSettings(company_plant_uuid) {
      this.$store.dispatch("plant/getGeneralSettings", company_plant_uuid).then((response) => {
        this.is_cno_required_prop_contracts = response.data.is_cno_required_prop_contracts;
        this.is_municipal_work_code_required_prop_contracts = response.data.is_municipal_work_code_required_prop_contracts;
      });
    },
    /**
     * verifica se exige código de obra da prefeitura na central selecionada.
     */
    checksIfRequiresCityHallCodeInCentral() {
      this.plant_requires_municipal_building_code =
        this.plants.find((item) => item.id === this.contract_proposal.company_plant_id).requires_municipal_building_code;
    },
    /**
     * Abre modal de listagem de anexos da obra.
     * @param {number} constructionId
     * @param {string} constructionName
     */
    handleShowConstructionAttachments(constructionId, constructionName) {
      this.$refs.modalConstructionAttachmentList.handleCreateModal(constructionId, constructionName);
    },
    /**
     * Atualiza total de anexos de obras após realizar o upload.
     * @param {number} constructionId
     */
    updatedTotalConstructionAttachmentAfterUpload(constructionId) {
      this.$store.dispatch('constructionAttachment/getTotalAttachmentByConstructionId', constructionId)
        .then(response => {
          this.totalConstructionAttachments = response.data.total_construction_attachments;
        });
    },
    /**
     * Atualiza total de anexos após deletar um anexo.
     */
    updateTotalConstructionAttachmentAfterDeletion() {
      this.totalConstructionAttachments--;
    },
    /**
     * Abre modal de listagem de anexos de clientes/fornecedores.
     * @param {number} entityId
     * @param {string} name
     * @param {string} document
     */
    handleShowEntityAttachments(entityId, name, document) {
      this.$refs.modalEntityAttachmentList.handleCreateModal(entityId, name, document);
    },
    /**
     * Atualiza total de anexos de clientes/fornecedores após realizar o upload.
     * @param {number} entityId
     */
    updatedTotalEntityAttachmentAfterUpload(entityId) {
      this.$store.dispatch('entity/getTotalAttachmentsByEntityId', entityId).then(response => {
        this.totalEntityAttachments = response.data.total_entity_attachments;
      });
    },
    /**
     * Após excluir um anexo de clientes/fornecedores, é atualizado o total de arquivos anexados.
     */
    updateTotalEntityAttachmentAfterDeletion() {
      this.totalEntityAttachments--;
    },
    /**
     * @param {object} construction
     */
    openModalCreateEntity(construction) {
      const open = true;
      const customer = false;
      const params = null;
      const isDocumentDisabled = null;
      const customerConstruction = this.$route.query.customerConstruction ?
        JSON.parse(this.$route.query.customerConstruction) :
        JSON.parse(this.$route.query.construction);
      const construnctionAddress = construction ? construction.addresses[0] : null;

      this.$refs.createEntity.openModal(
        open,
        customer,
        params,
        isDocumentDisabled,
        customerConstruction,
        construnctionAddress
      );
    },
    /**
     * Abre modal de edição de cliente/fornecedor.
     * @param {string} entityUuid
     */
    openModalEditEntity(entityUuid) {
      this.$refs.modalEditEntity.openModal(true, entityUuid);
    },
    /**
     * Atualiza dados do cliente/fornecedor após edição na modal.
     * @param {object} entity
     */
    updateEntityAfterEdit(entity) {
      this.setEntity(entity);
      if (this.$route.query.customerConstruction) {
        this.customerName = entity.entity_name;
      }
    },
    /**
     * Abre modal de edição de obra.
     * @param {string} constructionUuid
     */
    openModalEditConstruction(constructionUuid) {
      this.$refs.modalEditConstruction.handleEditModal(constructionUuid)
    },
    /**
     * Atualiza dados do obra após edição na modal.
     * @param {object} construction
     */
    updateConstructionAfterEdit(construction) {
      this.setConstruction(construction);
    },
    /**
     * Abre modal incial do cadastro de propostas/contratos.
     * @param {object} customerConstruction
     */
    openModalSearchEntityAndConstruction(customerConstruction) {
      this.$refs.modalSearchCustomerConstruction.openModal(customerConstruction);
    },
    /**
     * Carrega dados do cliente/fornecedor e obra conforme os parâmetros da rota,
     * de acordo com a obra selecionada pela pesquisa do documento do cliente/obra.
     */
    loadEntityAndConstructionData() {
      let loader = this.$loading.show();
      this.constructionParams = JSON.parse(this.$route.query.construction);
      Promise.all([
        this.$store.dispatch("entity/getEntityByDocument", {document: this.constructionParams.customer_construction_document})
          .then((response) => {
            if (response.data) {
              this.setEntity(response.data);
            }
          }),
        this.$store.dispatch("customerConstructionConstructions/show", this.constructionParams.uuid)
          .then(response => {
            if (response.data) {
              this.setConstruction(response.data);
            }
          }),
      ]).then(() => {
        loader.hide();
      });
    },
    /**
     * Se o usuário não selecionou uma obra,
     * o sistema vai tentar preencher automaticamente os dados do tomador,
     * conforme o documento do cliente/obra recém cadastrado na tela anterior.
     */
    loadEntityForCustomerConstructionRoute() {
      let loader = this.$loading.show();
      this.loadingCustomerName = true;
      this.customerConstructionParams = JSON.parse(this.$route.query.customerConstruction);
      this.$store.dispatch("entity/getEntityByDocument", {
        document: this.customerConstructionParams.document
      }).then((response) => {
        if (response.data) {
          this.setEntity(response.data);
          this.customerName = response.data.entity_name;
        }
      }).finally(() => {
        loader.hide();
        this.loadingCustomerName = false;
      });
    },
    hideCommonElements() {
      this.isHideEntityCard = true;
      this.isHideConstructionCard = true;
      this.loadingEntityAttachments = false;
      this.loadingConstructionAttachments = false;
    },
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
  },
  async mounted() {
    // Quando obra for selecionada.
    if (this.$route.query.construction) {
      this.loadEntityAndConstructionData();
    } else {
      this.hideCommonElements();
    }
    // Quando for cadastrado um novo cliente/obra na tela anterior.
    if (this.$route.query.customerConstruction) {
      this.loadEntityForCustomerConstructionRoute();
    } else {
      this.hideCommonElements();
    }
    await this.fetchFinancialLimits(this.$user.id)
    this.loadingPaymentIntermediaries = true
    this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
      this.loadingPaymentIntermediaries = false
    })
    this.contract_proposal.billing.range = null
    this.$store.dispatch("paymentMethod/fetchItems")
      .then(() => {
      }).catch(() => {
    })
    this.$store.dispatch("plant/fetchItemsActive").then(() => {
      this.loadingPlant = false;
    });
    this.loadingBankAccounts = true
    this.$store.dispatch('bankAccount/fetchItems', {})
      .then(response => {
        this.loadingBankAccounts = false
      })
      .catch(error => {
        this.loadingBankAccounts = false
      })
    this.$store.dispatch("contractContracts/fetchItems").then(() => {
      this.loadingContract = false;
      let [contract] = this.contracts.filter(item => item.default === true) || null
      this.contract_proposal.contract_uuid = contract.uuid
    });
    this.$store.dispatch("paymentTerm/fetchItems").then(() => {
      this.loadingPaymentTerm = false;
    });
    this.$store.dispatch("user/getSellersSimplified").then(() => {
      this.loadingUsers = false;
      const is_seller = this.users.find(item => item.uuid === this.$store.state.auth.user.uuid)
      if (is_seller) {
        this.contract_proposal.seller = this.$store.state.auth.user.uuid
      }
    });
    this.$store.dispatch("contractProposal/fetchCode").then(() => {
      this.loadingCode = false;
    });
    this.loadingGeneralSettings = true
    this.$store.dispatch('generalSetting/show').then((response) => {
      this.loadingGeneralSettings = false
      this.contract_proposal.automatic_billing = response.data['contract_proposal']
      this.isRequiredConstructionAttachment = response.data['is_attachment_required_for_new_proposal_contract'];
    });
    this.getCompanyPlantIssuers()
    this.$watch('entity', function (entity) {
      this.entity_is_company = false
      if (entity.document.replace(/[^0-9]/g, '') > 11) {
        this.entity_is_company = true
      }
      this.setISS()
    })
    this.$watch('code', function (code) {
      this.contract_proposal.code = code
    })
    this.$watch('construction', function (value) {
      this.handleStoreContacts(this.construction.uuid)
      if (value && value.plant && !this.contract_proposal.company_plant_id) {
        this.contract_proposal.company_plant_id = value.plant.id
        this.contract_proposal.seller = value.seller.uuid
        this.setISS()
      }
    })
  }
};
</script>
<style scoped>
.frequency:not(.frequency-selected) {
  font-family: system-ui;
  font-size: 12px;
  min-width: 25px;
  //padding: 6px;
}

.frequency:hover {
  background-color: #dbd9d9;
}

.frequency-selected {
  font-family: system-ui;
  font-size: 12px !important;
  //padding: 6px !important;
  color: white;
  min-width: 25px;
  background-color: #e65119;
}

/* Botão anexos */
.custom-button {
  background-color: white;
  border: 2px solid #198754;
  border-radius: 50px;
  padding: 0px -1px;
  width: max-content;
}

.custom-button .col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button .vertical-line {
  border-left: 2px solid #198754;
  height: 100%;
  margin-left: -2px;
  margin-right: -2px;
}

.custom-button {
  height: 30px;
}

/* editar cliente e obra */
.invert-color:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
</style>
