<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div v-if="additional">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div class="border-left border-3 border-primary m-2 mb-4">
                <div class="col-md-12 h4">
                  {{additional.short_description}}
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-0 pt-1 col-form-label form-control-label">
                  Valor padrão
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="additional.standard_value"
                      class="form-control form-control-sm"
                      inputmode="numeric"
                    />
                  </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-6 pt-1 pb-0 col-form-label form-control-label"
                >
                  Unidade de Cobrança
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control form-control-sm">
                      <el-select
                        :disabled="true"
                        v-model="additional.billing_unit"
                        filterable
                        placeholder="Selecione"
                        size="mini"
                      >
                        <el-option
                          v-for="itemUnit in billingUnits"
                          :key="itemUnit.id"
                          :label="itemUnit.label"
                          :value="itemUnit.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="border-header-warning-bottom mt-2 mb-2 p-2">
                <h5 class="mb-0" slot="title">Regras</h5>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-8 pb-1 pb-0 col-form-label form-control-label"
                >
                  Cobrança automática
                  <el-popover
                    title="Bloquear qualquer tipo de ajuste."
                    placement="right"
                    class="p-0"
                  >
                    <span>Cobrança automática.</span>
                    <base-button
                      v-model="additional.automatic_payment"
                      outiline
                      slot="reference"
                      size="sm"
                      type="secundary"
                      class="p-0 m-0 btn-rounded"
                    >
                      <i class="fas fa-info-circle"></i>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-4 pt-1">
                  <base-switch
                    type="primary"
                    offText="não"
                    onText="sim"
                  ></base-switch>
                </div>
              </div>
              <div v-if="additional.params" class="border-header-danger-bottom mt-2 mb-2 p-2">
                <h5 class="mb-0" slot="title">Prazos e Limites</h5>
              </div>
              <div v-if="additional.params && additional.params.a" class="form-group row m-0 p-0 mb-1 mt-2">
                <label class="col-md-6 pb-0 pt-1 col-form-label form-control-label">
                  Parâmetro A
                </label>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :disabled="true"
                      v-model="additional.params.a.param"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>
              <div v-if="additional.params && additional.params.a" class="form-group row m-0 p-0 mb-1">
                <label class="col-md-6 pb-0 pt-1 col-form-label form-control-label">
                  Valor A
                </label>
                <div class="col-md-6">
                  <validation-provider rules="required">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="additional.params.a.values"
                      class="form-control form-control-sm"
                      inputmode="numeric"
                    />
                  </base-input>
                  </validation-provider>
                </div>
              </div>
              <template v-if="additional.params && additional.params.b">
                <div class="form-group row m-0 p-0 mb-1 mt-2">
                  <label class="col-md-6 pb-0 pt-1 col-form-label form-control-label">
                    Parâmetro B
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        :disabled="true"
                        v-model="additional.params.b.param"
                        class="form-control form-control-sm"
                        inputmode="numeric"
                      />
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pb-0 pt-1 col-form-label form-control-label">
                    Valor B
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-model="additional.params.b.values"
                        class="form-control form-control-sm"
                        inputmode="numeric"
                      />
                    </base-input>
                    </validation-provider>
                  </div>
                </div>
              </template>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
              <base-button
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                :loading="loadingSave"
              >
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  components: {},
  name: "ModalNewValidity",
  computed: {
    ...mapGetters({
      services: 'service/activeItems',
      billingUnits: 'additional/billingUnits',
      params: 'additional/params',
      dates: 'additional/dates',
    }),
  },
  data() {
    return {
      modal: false,
      title: 'Nova vigência',
      loadingService: true,
      validated: false,
      loadingSave: false,
      additional : null
    }
  },
  methods: {
    ...mapActions({
      fetchServices: 'service/fetchServices',
      addAdditional: 'additional/addAdditional',
    }),
    closeModal () {
      this.modal = false
    },
    openModal (additional) {
      this.modal = true
      this.additional = {...additional}
    },
    handleSearch(){
    },
    save(){
      this.loading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      this.$store.dispatch('contractAdditional/createNewValidity', this.additional)
        .then(response => {
          this.$Progress.finish();
          this.loading = false
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.modal = false
          this.$emit('refresh');
        }).catch(error => {
        if (error.response && error.response.status === 422) {
          let errors = Object.values(error.response.data.errors)
          errors = errors.flat().toString().split(',').join("<br />")
          this.$notify({type: 'danger', message: errors})
        }
        else {
          this.$notify({type: 'danger', message: error.data.message})
        }
        this.$Progress.finish()
        this.loading = false
      })
    },
  },
  created() {},
  mounted() {
    this.fetchServices().then(() => this.loadingService = false)
  }
};
</script>

<style></style>
